import React from 'react'
import classnames from 'classnames';

const FleetBox = ({ name, value, active, click, isDealer }) => {
    var fleet_image = active === name ?
        "/images/fleet_alerts/" + name + "_active.png" :
        "/images/fleet_alerts/" + name + ".png";

    return (<div className={isDealer ? 'fleet-col-dealer' : 'fleet-col'} onClick={click}>
        <div className={classnames("fleet-box",
            active === name ? 'fleet-box-active' : null)}>
            <div className="fleet-image-div">
                <img src={fleet_image} alt="" className="fleet-image"></img>
            </div>
            <div className="fleet-value">
                <div className={value <= 10000 ? "fleet-data" : 'fleet-customized-data'}>
                    <p>{value !== undefined && value !== null ? value : '0'}</p>
                </div>
                <div className="fleet-head">
                    <p>{name}</p>
                </div>
            </div>
        </div>
    </div>)
}

export default FleetBox;