import React from 'react'
import { convertohhmmss } from '../../../../Util/commonConverter';
import { useSelector } from "react-redux";

const Table = ({data, a_data, header_1, header_2, header_dis, header_model, column, tofixed, time}) => {

    const ctr = useSelector((state) => state.userdata.id);
  return (
    <table className='table table-bordered table-top-bottom'>
        <thead>
            <tr style={{height: '15px'}}>
                <th>{header_1}</th>
                {header_dis && 
                <th>{header_dis}</th>
                }
                {header_model && 
                <th>{header_model}</th>
                }
                <th>{header_2}</th>
            </tr>
        </thead>
        <tbody className='top-vehicles'>
            {data.map((value, i) => {
                return(
                    <tr key={i}>
                        <td>{(ctr.userroleid === 6 || ctr.userroleid === 8) ? (value.regn_number || value.reg_number) : (value.vin || value.vin_number)}</td>
                        {header_dis && 
                            <td>
                                {value.dis_cov || value.distance_cov}
                            </td>
                        }
                        {header_model && 
                            <td>
                                {value.vehcile_model || value.vehcile_model}
                            </td>
                        }
                        <td style={{ color: (data.length > 0 && a_data.length > 0 && column !== 'fuel_cons' ? 'green' : '#000'), fontWeight: (data.length > 0 && a_data.length > 0 && column !== 'fuel_cons' ? 'normal' : '600') }}>
                            {
                                tofixed ? (value[column] && typeof value[column].toFixed === 'function' ? value[column].toFixed(2) : value[column]) :
                                time ? convertohhmmss(value[column]) :
                                column === 'run_over_racing_band_per' ? (value[column] !== 0 ? value[column] : 'No over racing') :
                                (value[column] !== undefined ? value[column] : '')
                            }
                        </td>
                    </tr>
                )
            })}
        </tbody>
        <tbody className='bottom-vehicles'>
            {a_data.map((value, i) => {
                return(
                    <tr key={i}>
                        <td>{(ctr.userroleid === 6 || ctr.userroleid === 8) ? (value.regn_number || value.reg_number) : (value.vin || value.vin_number)}</td>
                        {header_dis && 
                            <td>
                                {value.dis_cov || value.distance_cov}
                            </td>
                        }
                        {header_model && 
                            <td>
                                {value.vehcile_model || value.vehcile_model}
                            </td>
                        }
                        <td style={{ color: (data.length > 0 && a_data.length > 0 && column !== 'fuel_cons' ? 'red' : '#000') }}>
                            {tofixed ? (value[column] && typeof value[column].toFixed === 'function' ? value[column].toFixed(2) : value[column]) : 
                                time ? convertohhmmss(value[column]) : 
                                column === 'run_over_racing_band_per' ? value[column] !== 0 ? value[column] : 'No over racing' : 
                                (value[column] !== undefined ? value[column] : '')
                            }
                        </td>
                    </tr>
                )
            })}
        </tbody>
    </table>
  )
}

export default Table