import React, { Component } from 'react';
import "../../Components/BottomRibbon/ribbonStyle.scss";
import moment from 'moment';
import './vehicledashcss.scss';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { postData } from '../../Util';
import * as apilist from '../../apiList';
import { connect } from 'react-redux';
import { alert_types } from '../../Components/GoogleMap/alertTypes'
import { exhaustCalculationDashboard, getFuelLabel, showAirPressure, showDEF, showExhaust, showGearUI, showOilPressure, vehicleDashboardFuelTextClass } from '../../Util/VehicleDashUtils';
import { getFuelLevelUnit, getFuelLevelValue } from '../../Components/NewListView/Utils';

let uservehicledate = {};
class vhdashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ttldatetcompare: '', loadedVins: []
    }
  }
  componentDidMount() {
    var currentdatecheck = new Date();
    var currentdateyesterday = currentdatecheck.setDate(currentdatecheck.getDate() - 1);
    var findmilliseconds = new Date(currentdateyesterday);
    this.setState({ ttldatetcompare: findmilliseconds })
    if (this.props.receiveProps.uD) {
      let uData = this.props.receiveProps.uD;
      this.loadSelectVehicle(uData);
      this.setState({ selectedvehicles: this.props.receiveProps.ctr.selectedVehicle.currentInfo })

    }
    this.settimeoutfns();
  }
  settimeoutfns = () => {
    // this.settimerscnt=0;
    // this.settimerscnt = setInterval(() => {
    //  this.updateState(this.state.selectedvehicles)
    // }, 10000)
    this.updateState(this.state.selectedvehicles)
  }
  updatelatestvins = (newvals) => {
    this.setState({ selectedvehicles: newvals }, () => { this.updateState(newvals, '') },)
  }
  componentWillUnmount() {
    clearInterval(this.settimerscnt)
  }
  loadSelectVehicle = (uD) => {
    let newObj = {};
    let vehtokens = uD.token;
    newObj.platform = 'w';
    newObj.userId = uD.userid;

    const datas = postData(apilist.vehicleInfo, newObj, vehtokens);
    //const datas = postData('http://10.18.1.128:5000/api/FMS/vehicleInfo', newObj);
    datas.then((dataVals) => {
      if (dataVals.statuscode === 200) {
        dataVals.data.map((data) => {
          data.title = data.regn_number;
          return data;
        });
        this.setState({
          loadedVins: {
            options: dataVals?.data,
            getOptionLabel: option => option.title,
          }
        });
        if (this.props.receiveProps.ctr.selectedVehicle === undefined) {
          let vehlist = {};
          vehlist.userId = this.props.uD.userid;
          vehlist.userRole = this.props.uD.userrole;
          vehlist.obuIdList = [];
          let vehtoken = this.props.uD.token;
          vehlist.platform = "w";
          let urls;
          if (this.props.uD.userrole === "DEALER OUTLET ADMIN") {
            urls = apilist.allvehicledatadealer
          } else {
            urls = apilist.allvehicledata
          }
          const response = postData(urls, vehlist, vehtoken); //"http://10.18.1.128:7000/api/map/ownVehicleList"
          response.then((datas) => {
            if (datas.statuscode === 200) {
              var obulist = {
                obu_id: datas.data[0].obuId
              }

              this.updateState(obulist)
            }
          });


        }

      } else {
        alert('Failed to load Change Vehicle Data..')
      }
    });
  }
  updateState = (newVal, event) => {
    let uDet = this.props.userDetails || this.props.receiveProps.uD;
    if (newVal && uDet) {

      let newObj = {};
      newObj.platform = 'w';
      newObj.userId = uDet.userid;
      newObj.userRole = uDet.userrole;
      let tokensvalue = uDet.token;
      if (newVal.obu_id === undefined) {
        newObj.obuIdList = [newVal.obuId];
      } else {
        newObj.obuIdList = [newVal.obu_id];
      }

      let urls;
      if (uDet.userrole === "DEALER OUTLET ADMIN") {
        urls = apilist.allvehicledatadealer
      } else {
        urls = apilist.allvehicledata
      }
      const datas = postData(urls, newObj, tokensvalue);
      //const datas = postData('http://10.18.1.113:8000/api/map/ownVehicleList', newObj);
      datas.then((dataVals) => {
        if (dataVals.statuscode === 200) {
          let mVal = {};
          mVal.selectedVehicle = dataVals.data[0];
          uservehicledate = mVal;
          this.props.ctrs();
          // this.setState({selectedvehicles:newVal},()=>)
          this.props.updateChanges()
          let selectedVehl = mVal.selectedVehicle.currentInfo;
          if (selectedVehl && Object.keys(selectedVehl).length > 0) {
            let propDate = new Date(selectedVehl.localTimeStamp);
            let toDate = new Date(propDate);
            let fromDate = new Date(selectedVehl.localTimeStamp).setHours(propDate.getHours() - 6);
            let vehStatus = selectedVehl.status;
            //let vehStatus = "";
            this.setState({ startDate: fromDate, endDate: toDate, startTime: fromDate, endTime: toDate, vstatus: vehStatus, endVehicle: '', selectedVeh: uservehicledate }, this.callTrace);

            //this.setState({ selectedVeh: uservehicledate });
          } else {
            alert("No Data Available for selected Vehilce...");
            this.setState({
              datas: ''
            });
          }
        } else {
          alert("Please check...");
          this.setState({
            datas: ''
          });
          return false;
        }
      });
    } else {
      console.log('Redux user Data or selected Val Error');
      return false;
    }
  };
  render() {
    const { loadedVins } = this.state;
    let marker = this.props.receiveProps.ctr.selectedVehicle;
    var title = '';
    var fuelevel;
    var fuelimg;
    var tankpercent;
    var tankpercentbs6;
    var batterypercent;
    var batlevel;
    var batimg;
    var gearvalue;
    var speedlevels;
    var gpsspeed;
    var temperaturecool;
    var temperatureclass;
    var airpressuract1;
    var airpressuract2;
    var airpressure1;
    var airpressure2;
    var oilpressurelevels;
    var oilpressuract;
    var airpress2iconimgs;
    var airpress1iconimgs;
    var oilpress2iconimgs;
    var fuelevel_bs6;
    var fuelimg_bs6;
    var tankpercentaddblue;
    var fuelevel_addblue;
    var fuelimg_addblue;
    var tantpercentadbluelevel;
    var extemperaturecool;
    var exhtemperatureclass;

    let showDEFUI = showDEF(marker);
    const fuelTextClass = vehicleDashboardFuelTextClass(marker.isBS6);
    const fuelLabel = getFuelLabel(marker.isBS6);
    title = title + "Reg.No : " + marker.regnNumber + "\n" +
      "Model : " + marker.vehicleModel + "\n" +
      "Speed : " + marker.currentInfo.gpsSpeed + "KMPH \n" +
      "TimeStamp : " + moment(marker.currentInfo.localTimeStamp).format("DD-MM-YYYY h:mm:Ss A") + "\n" + //MMM DD, YYYY, h:mm:Ss A
      "Location : " + marker.currentInfo.location;
    //Fuel color fill Logics
    if (marker.isBS6 === 2) {
      tankpercent = Math.round(marker.currentInfo.cng_level_percentage);
    } else {
      tankpercent = Math.round((marker.currentInfo.fuelLevel / marker.fuelTankCap) * 100);
    }
    //tankpercent = Math.round((marker.currentInfo.fuelLevel / marker.fuelTankCap) * 100);
    gearvalue = marker.currentInfo.gearnumber === "<null>" ? "N" : (marker.currentInfo.gearnumber === null || marker.currentInfo.gearnumber === "N") ? "N" : marker.currentInfo.gearnumber <= 0 ? "N" : parseInt(marker.currentInfo.gearnumber) >= 15 ? "N" : (marker.currentInfo.gearnumber === "N" || marker.currentInfo.gearnumber === "C" || marker.currentInfo.gearnumber === "R") ? marker.currentInfo.gearnumber : parseInt(marker.currentInfo.gearnumber);
    //gearvalue =5;
    if (tankpercent <= 9) {
      fuelevel = "fuelfill9";
      fuelimg = "fuelfillRed_ST";
    } else if (tankpercent <= 19) {
      fuelevel = "fuelfill19";
      fuelimg = "fuelfillimg_ST";
    }
    else if (tankpercent <= 29) {
      fuelevel = "fuelfill20";
      fuelimg = "fuelfillimg_ST";
    }
    else if (tankpercent <= 39) {
      fuelevel = "fuelfill30";
      fuelimg = "fuelfillimg_ST";
    }
    else if (tankpercent <= 49) {
      fuelevel = "fuelfill40";
      fuelimg = "fuelfillimg_ST";
    }
    else if (tankpercent <= 59) {
      fuelevel = "fuelfill50";
      fuelimg = "fuelfillimg_ST";
    }
    else if (tankpercent <= 69) {
      fuelevel = "fuelfill60";
      fuelimg = "fuelfillimg_ST";
    }
    else if (tankpercent <= 79) {
      fuelevel = "fuelfill70";
      fuelimg = "fuelfillimg_ST";
    }
    else if (tankpercent <= 89) {
      fuelevel = "fuelfill80";
      fuelimg = "fuelfillimg_ST";
    }
    else if (tankpercent <= 98) {
      fuelevel = "fuelfill90";
      fuelimg = "fuelfillimg_ST";
    }
    else if (tankpercent >= 99) {
      fuelevel = "fuelfill95";
      fuelimg = "fuelfillimg_ST";
    }
    else {
      fuelevel = "fuelfill90";
      fuelimg = "fuelfillimg_ST";
    }

    //End of Fuel color fill Logics
    //BS6 fuel//

    tankpercentbs6 = Math.round((marker.currentInfo.fuelLevel / marker.fuelTankCap) * 100);
    //gearvalue = 5;
    if (tankpercentbs6 <= 9) {
      fuelevel_bs6 = "fuelfill_bs69";
      fuelimg_bs6 = "fuelfill_ST_Red_bs6";
    } else if (tankpercentbs6 <= 19) {
      fuelevel_bs6 = "fuelfill_bs619";
      fuelimg_bs6 = "fuelfillimg_ST_bs6";
    }
    else if (tankpercentbs6 <= 29) {
      fuelevel_bs6 = "fuelfill_bs620";
      fuelimg_bs6 = "fuelfillimg_ST_bs6";
    }
    else if (tankpercentbs6 <= 39) {
      fuelevel_bs6 = "fuelfill_bs630";
      fuelimg_bs6 = "fuelfillimg_ST_bs6";
    }
    else if (tankpercentbs6 <= 49) {
      fuelevel_bs6 = "fuelfill_bs640";
      fuelimg_bs6 = "fuelfillimg_ST_bs6";
    }
    else if (tankpercentbs6 <= 59) {
      fuelevel_bs6 = "fuelfill_bs650";
      fuelimg_bs6 = "fuelfillimg_ST_bs6";
    }
    else if (tankpercentbs6 <= 69) {
      fuelevel_bs6 = "fuelfill_bs660";
      fuelimg_bs6 = "fuelfillimg_ST_bs6";
    }
    else if (tankpercentbs6 <= 79) {
      fuelevel_bs6 = "fuelfill_bs670";
      fuelimg_bs6 = "fuelfillimg_ST_bs6";
    }
    else if (tankpercentbs6 <= 89) {
      fuelevel_bs6 = "fuelfill_bs680";
      fuelimg_bs6 = "fuelfillimg_ST_bs6";
    }
    else if (tankpercentbs6 <= 98) {
      fuelevel_bs6 = "fuelfill_bs690";
      fuelimg_bs6 = "fuelfillimg_ST_bs6";
    }
    else if (tankpercentbs6 >= 99) {
      fuelevel_bs6 = "fuelfill_bs695";
      fuelimg_bs6 = "fuelfillimg_ST_bs6";
    }
    else {
      fuelevel_bs6 = "fuelfill_bs690";
      fuelimg_bs6 = "fuelfillimg_ST_bs6";
    }

    //bs6 fuel end//
    // Add Blue//
    // tankpercentaddblue = Math.round(marker.currentInfo.catalyst_tank_level);
    // tantpercentadbluelevel = Math.round(marker.currentInfo.catalyst_tank_level/100 * marker.adBlueTankCap);
    // if(tankpercentaddblue >100){
    //   tankpercentaddblue=100
    // }
    if (marker.vehicleType === 'EDC_LCV' || marker.vehicleType === 'EEA_LCV') {
      tankpercentaddblue = 80
      tantpercentadbluelevel = Math.round((marker.adBlueTankCap / 100) * 80)
    } else {
      tankpercentaddblue = Math.round(marker.currentInfo.catalyst_tank_level);
      tantpercentadbluelevel = Math.round(marker.currentInfo.catalyst_tank_level / 100 * marker.adBlueTankCap);
    }
    if (tankpercentaddblue > 100) {
      tankpercentaddblue = 100
      tantpercentadbluelevel = marker.adBlueTankCap;
    }
    tantpercentadbluelevel = tantpercentadbluelevel.toFixed(1)
    //gearvalue = 5;
    if (tankpercentaddblue <= 9) {
      fuelevel_addblue = "fuelfill_addb9";
      fuelimg_addblue = "fuelfill_ST_Red_addb";
    } else if (tankpercentaddblue <= 19) {
      fuelevel_addblue = "fuelfill_addb19";
      fuelimg_addblue = "fuelfillimg_ST_addb";
    }
    else if (tankpercentaddblue <= 29) {
      fuelevel_addblue = "fuelfill_addb20";
      fuelimg_addblue = "fuelfillimg_ST_addb";
    }
    else if (tankpercentaddblue <= 39) {
      fuelevel_addblue = "fuelfill_addb30";
      fuelimg_addblue = "fuelfillimg_ST_addb";
    }
    else if (tankpercentaddblue <= 49) {
      fuelevel_addblue = "fuelfill_addb40";
      fuelimg_addblue = "fuelfillimg_ST_addb";
    }
    else if (tankpercentaddblue <= 59) {
      fuelevel_addblue = "fuelfill_addb50";
      fuelimg_addblue = "fuelfillimg_ST_addb";
    }
    else if (tankpercentaddblue <= 69) {
      fuelevel_addblue = "fuelfill_addb60";
      fuelimg_addblue = "fuelfillimg_ST_addb";
    }
    else if (tankpercentaddblue <= 79) {
      fuelevel_addblue = "fuelfill_addb70";
      fuelimg_addblue = "fuelfillimg_ST_addb";
    }
    else if (tankpercentaddblue <= 89) {
      fuelevel_addblue = "fuelfill_addb80";
      fuelimg_addblue = "fuelfillimg_ST_addb";
    }
    else if (tankpercentaddblue <= 98) {
      fuelevel_addblue = "fuelfill_addb90";
      fuelimg_addblue = "fuelfillimg_ST_addb";
    }
    else if (tankpercentaddblue >= 99) {
      fuelevel_addblue = "fuelfill_addb95";
      fuelimg_addblue = "fuelfillimg_ST_addb";
    }
    else {
      fuelevel_addblue = "fuelfill_addb90";
      fuelimg_addblue = "fuelfillimg_ST_addb";
    }
    //End of Add Blue//

    //Battery Logic//
    var batterythreshold;
    if (marker.batteryType === "12V Battery") {
      batterythreshold = 18
    } else {
      batterythreshold = 32
    }
    batterypercent = (marker.currentInfo.battPotential / batterythreshold) * 100;
    if (batterypercent <= 9) {
      batlevel = "batteryfill9";
      batimg = "batteryfillSTred";
    } else if (batterypercent <= 19) {
      batlevel = "batteryfill19";
      batimg = "batteryfillSTred";
    }
    else if (batterypercent <= 29) {
      batlevel = "batteryfill20";
      batimg = "batteryfillSTred";
    }
    else if (batterypercent <= 39) {
      batlevel = "batteryfill30";
      batimg = "batteryfillSTred";
    }
    else if (batterypercent <= 49) {
      batlevel = "batteryfill40";
      batimg = "batteryfillSTred";
    }
    else if (batterypercent < 56) {
      batlevel = "batteryfill40";
      batimg = "batteryfillSTred";
    }
    else if (batterypercent <= 59) {
      batlevel = "batteryfill50";
      batimg = "batteryfill_ST";
    }
    else if (batterypercent <= 69) {
      batlevel = "batteryfill60";
      batimg = "batteryfill_ST";
    }
    else if (batterypercent <= 79) {
      batlevel = "batteryfill70";
      batimg = "batteryfill_ST";
    }
    else if (batterypercent <= 89) {
      batlevel = "batteryfill80";
      batimg = "batteryfill_ST";
    }
    else if (batterypercent >= 90) {
      batlevel = "batteryfill90";
      batimg = "batteryfill_ST";
    }
    else {
      batlevel = "batteryfill90";
      batimg = "batteryfill_ST";
    }
    //End of battery logic//
    gpsspeed = Math.round(marker.currentInfo.gpsSpeed);
    if (gpsspeed === 0) {
      speedlevels = "speedfill";
    } else if (gpsspeed <= 5) {
      speedlevels = "speedfill5";
    }
    else if (gpsspeed <= 10) {
      speedlevels = "speedfill10";
    }
    else if (gpsspeed <= 15) {
      speedlevels = "speedfill15";
    }
    else if (gpsspeed <= 20) {
      speedlevels = "speedfill20";
    }
    else if (gpsspeed <= 25) {
      speedlevels = "speedfill25";
    }
    else if (gpsspeed <= 30) {
      speedlevels = "speedfill30";
    }
    else if (gpsspeed <= 35) {
      speedlevels = "speedfill35";
    }
    else if (gpsspeed <= 40) {
      speedlevels = "speedfill40";
    }
    else if (gpsspeed <= 45) {
      speedlevels = "speedfill45";
    }
    else if (gpsspeed <= 50) {
      speedlevels = "speedfill50";
    }
    else if (gpsspeed <= 55) {
      speedlevels = "speedfill55";
    }
    else if (gpsspeed <= 60) {
      speedlevels = "speedfill60";
    }
    else if (gpsspeed <= 65) {
      speedlevels = "speedfill65";
    }
    else if (gpsspeed <= 70) {
      speedlevels = "speedfill70";
    }
    else if (gpsspeed <= 80) {
      speedlevels = "speedfill80";
    }
    else if (gpsspeed <= 89) {
      speedlevels = "speedfill90";
    }
    else if (gpsspeed >= 90) {
      speedlevels = "speedfill95";
    }
    //speed color changes//
    //End of speed color //
    //Temperature//
    temperaturecool = Math.round((marker.currentInfo.eng_coolant_temp / 120) * 100);
    if (temperaturecool === 0) {
      temperatureclass = "tempfill"
    } else if (temperaturecool <= 5) {
      temperatureclass = "tempfill5"
    } else if (temperaturecool <= 10) {
      temperatureclass = "tempfill10"
    } else if (temperaturecool <= 15) {
      temperatureclass = "tempfill15"
    } else if (temperaturecool <= 20) {
      temperatureclass = "tempfill20"
    } else if (temperaturecool <= 25) {
      temperatureclass = "tempfill25"
    } else if (temperaturecool <= 30) {
      temperatureclass = "tempfill30"
    } else if (temperaturecool <= 35) {
      temperatureclass = "tempfill35"
    } else if (temperaturecool <= 40) {
      temperatureclass = "tempfill40"
    } else if (temperaturecool <= 45) {
      temperatureclass = "tempfill45"
    } else if (temperaturecool <= 50) {
      temperatureclass = "tempfill50"
    } else if (temperaturecool <= 55) {
      temperatureclass = "tempfill55"
    } else if (temperaturecool <= 60) {
      temperatureclass = "tempfill60"
    } else if (temperaturecool <= 65) {
      temperatureclass = "tempfill65"
    } else if (temperaturecool <= 70) {
      temperatureclass = "tempfill70"
    } else if (temperaturecool <= 75) {
      temperatureclass = "tempfill75"
    } else if (temperaturecool <= 80) {
      temperatureclass = "tempfill80"
    } else if (temperaturecool <= 85) {
      temperatureclass = "tempfill85"
    } else if (temperaturecool <= 90) {
      temperatureclass = "tempfill90"
    } else if (temperaturecool >= 91) {
      temperatureclass = "tempfill95"
    }
    //End of Temperature
    //Exhault //
    extemperaturecool = Math.round(marker.currentInfo.aft1_exhaust_temp_1);
    if (extemperaturecool === 0) {
      exhtemperatureclass = "extempfill"
    } else if (extemperaturecool <= 45) {
      exhtemperatureclass = "extempfill5"
    } else if (extemperaturecool <= 90) {
      exhtemperatureclass = "extempfill10"
    } else if (extemperaturecool <= 135) {
      exhtemperatureclass = "extempfill15"
    } else if (extemperaturecool <= 180) {
      exhtemperatureclass = "extempfill20"
    } else if (extemperaturecool <= 225) {
      exhtemperatureclass = "extempfill25"
    } else if (extemperaturecool <= 270) {
      exhtemperatureclass = "extempfill30"
    } else if (extemperaturecool <= 315) {
      exhtemperatureclass = "extempfill35"
    } else if (extemperaturecool <= 360) {
      exhtemperatureclass = "extempfill40"
    } else if (extemperaturecool <= 405) {
      exhtemperatureclass = "extempfill45"
    } else if (extemperaturecool <= 450) {
      exhtemperatureclass = "extempfill50"
    } else if (extemperaturecool <= 495) {
      exhtemperatureclass = "extempfill55"
    } else if (extemperaturecool <= 540) {
      exhtemperatureclass = "extempfill60"
    } else if (extemperaturecool <= 585) {
      exhtemperatureclass = "extempfill65"
    } else if (extemperaturecool <= 630) {
      exhtemperatureclass = "extempfill70"
    } else if (extemperaturecool <= 675) {
      exhtemperatureclass = "extempfill75"
    } else if (extemperaturecool <= 720) {
      exhtemperatureclass = "extempfill80"
    } else if (extemperaturecool <= 765) {
      exhtemperatureclass = "extempfill85"
    } else if (extemperaturecool <= 810) {
      exhtemperatureclass = "extempfill90"
    } else if (extemperaturecool >= 855) {
      exhtemperatureclass = "extempfill95"
    } else {
      exhtemperatureclass = "extempfill"
    }
    //end of Exhault//
    //Air Pressure logic//
    if (marker.isBS6 === 1 || marker.isBS6 === 2) {
      airpressuract1 = Math.round(marker.currentInfo.airpressure_actual1);
      airpressuract2 = Math.round(marker.currentInfo.airpressure_actual2);
      if (airpressuract1 === 0) {
        airpress1iconimgs = "airpress1iconimgred";
        airpressure1 = "airpress"
      } else if (airpressuract1 === 1) {
        airpress1iconimgs = "airpress1iconimgred";
        airpressure1 = "airpress1"
      } else if (airpressuract1 === 2) {
        airpress1iconimgs = "airpress1iconimgred";
        airpressure1 = "airpress2"
      } else if (airpressuract1 === 3) {
        airpress1iconimgs = "airpress1iconimgred";
        airpressure1 = "airpress3"
      } else if (airpressuract1 === 4) {
        airpress1iconimgs = "airpress1iconimgred";
        airpressure1 = "airpress4"
      } else if (airpressuract1 === 5) {
        airpress1iconimgs = "airpress1iconimg";
        airpressure1 = "airpress5"
      } else if (airpressuract1 === 6) {
        airpress1iconimgs = "airpress1iconimg";
        airpressure1 = "airpress6"
      } else if (airpressuract1 === 7) {
        airpress1iconimgs = "airpress1iconimg";
        airpressure1 = "airpress7"
      } else if (airpressuract1 === 8) {
        airpress1iconimgs = "airpress1iconimg";
        airpressure1 = "airpress8"
      } else if (airpressuract1 === 9) {
        airpress1iconimgs = "airpress1iconimg";
        airpressure1 = "airpress9"
      } else if (airpressuract1 === 10) {
        airpress1iconimgs = "airpress1iconimg";
        airpressure1 = "airpress10"
      } else if (airpressuract1 === 11) {
        airpress1iconimgs = "airpress1iconimg";
        airpressure1 = "airpress11"
      } else if (airpressuract1 === 12) {
        airpress1iconimgs = "airpress1iconimg";
        airpressure1 = "airpress12"
      }
      if (airpressuract2 === 0) {
        airpress2iconimgs = "airpress1iconimg2red_ST";
        airpressure2 = "airpresssec"
      } else if (airpressuract2 === 1) {
        airpress2iconimgs = "airpress1iconimg2red_ST";
        airpressure2 = "airpresssec1"
      } else if (airpressuract2 === 2) {
        airpress2iconimgs = "airpress1iconimg2red_ST";
        airpressure2 = "airpresssec2"
      } else if (airpressuract2 === 3) {
        airpress2iconimgs = "airpress1iconimg2red_ST";
        airpressure2 = "airpresssec3"
      } else if (airpressuract2 === 4) {
        airpress2iconimgs = "airpress1iconimg2red_ST";
        airpressure2 = "airpresssec4"
      } else if (airpressuract2 === 5) {
        airpress2iconimgs = "airpress1iconimg2_ST";
        airpressure2 = "airpresssec5"
      } else if (airpressuract2 === 6) {
        airpress2iconimgs = "airpress1iconimg2_ST";
        airpressure2 = "airpresssec6"
      } else if (airpressuract2 === 7) {
        airpress2iconimgs = "airpress1iconimg2_ST";
        airpressure2 = "airpresssec7"
      } else if (airpressuract2 === 8) {
        airpress2iconimgs = "airpress1iconimg2_ST";
        airpressure2 = "airpresssec8"
      } else if (airpressuract2 === 9) {
        airpress2iconimgs = "airpress1iconimg2_ST";
        airpressure2 = "airpresssec9"
      } else if (airpressuract2 === 10) {
        airpress2iconimgs = "airpress1iconimg2_ST";
        airpressure2 = "airpresssec10"
      } else if (airpressuract2 === 11) {
        airpress2iconimgs = "airpress1iconimg2_ST";
        airpressure2 = "airpresssec11"
      } else if (airpressuract2 === 12) {
        airpress2iconimgs = "airpress1iconimg2_ST";
        airpressure2 = "airpresssec12"
      }
    } else {
      if (marker.vehicleType === "EEA") {
        airpressuract1 = Math.round(marker.currentInfo.airpressure_actual1);
        airpressuract2 = Math.round(marker.currentInfo.airpressure_actual2);
        if (airpressuract1 === 0) {
          airpress1iconimgs = "airpress1iconimgred";
          airpressure1 = "airpress"
        } else if (airpressuract1 === 1) {
          airpress1iconimgs = "airpress1iconimgred";
          airpressure1 = "airpress1"
        } else if (airpressuract1 === 2) {
          airpress1iconimgs = "airpress1iconimgred";
          airpressure1 = "airpress2"
        } else if (airpressuract1 === 3) {
          airpress1iconimgs = "airpress1iconimgred";
          airpressure1 = "airpress3"
        } else if (airpressuract1 === 4) {
          airpress1iconimgs = "airpress1iconimgred";
          airpressure1 = "airpress4"
        } else if (airpressuract1 === 5) {
          airpress1iconimgs = "airpress1iconimg";
          airpressure1 = "airpress5"
        } else if (airpressuract1 === 6) {
          airpress1iconimgs = "airpress1iconimg";
          airpressure1 = "airpress6"
        } else if (airpressuract1 === 7) {
          airpress1iconimgs = "airpress1iconimg";
          airpressure1 = "airpress7"
        } else if (airpressuract1 === 8) {
          airpress1iconimgs = "airpress1iconimg";
          airpressure1 = "airpress8"
        } else if (airpressuract1 === 9) {
          airpress1iconimgs = "airpress1iconimg";
          airpressure1 = "airpress9"
        } else if (airpressuract1 === 10) {
          airpress1iconimgs = "airpress1iconimg";
          airpressure1 = "airpress10"
        } else if (airpressuract1 === 11) {
          airpress1iconimgs = "airpress1iconimg";
          airpressure1 = "airpress11"
        } else if (airpressuract1 === 12) {
          airpress1iconimgs = "airpress1iconimg";
          airpressure1 = "airpress12"
        }
        if (airpressuract2 === 0) {
          airpress2iconimgs = "airpress1iconimg2red";
          airpressure2 = "airpresssec"
        } else if (airpressuract2 === 1) {
          airpress2iconimgs = "airpress1iconimg2red";
          airpressure2 = "airpresssec1"
        } else if (airpressuract2 === 2) {
          airpress2iconimgs = "airpress1iconimg2red";
          airpressure2 = "airpresssec2"
        } else if (airpressuract2 === 3) {
          airpress2iconimgs = "airpress1iconimg2red";
          airpressure2 = "airpresssec3"
        } else if (airpressuract2 === 4) {
          airpress2iconimgs = "airpress1iconimg2red";
          airpressure2 = "airpresssec4"
        } else if (airpressuract2 === 5) {
          airpress2iconimgs = "airpress1iconimg2";
          airpressure2 = "airpresssec5"
        } else if (airpressuract2 === 6) {
          airpress2iconimgs = "airpress1iconimg2";
          airpressure2 = "airpresssec6"
        } else if (airpressuract2 === 7) {
          airpress2iconimgs = "airpress1iconimg2";
          airpressure2 = "airpresssec7"
        } else if (airpressuract2 === 8) {
          airpress2iconimgs = "airpress1iconimg2";
          airpressure2 = "airpresssec8"
        } else if (airpressuract2 === 9) {
          airpress2iconimgs = "airpress1iconimg2";
          airpressure2 = "airpresssec9"
        } else if (airpressuract2 === 10) {
          airpress2iconimgs = "airpress1iconimg2";
          airpressure2 = "airpresssec10"
        } else if (airpressuract2 === 11) {
          airpress2iconimgs = "airpress1iconimg2";
          airpressure2 = "airpresssec11"
        } else if (airpressuract2 === 12) {
          airpress2iconimgs = "airpress1iconimg2";
          airpressure2 = "airpresssec12"
        }

      } else {
        if (marker.currentInfo.air_pressure_digital !== 0) {
          airpress2iconimgs = "airpress1iconimg2";
          airpressure1 = "airpress12";
          airpressure2 = "airpresssec12";
        } else {
          airpress2iconimgs = "airpress1iconimg2red";
          airpressure1 = "airpress";
          airpressure2 = "airpresssec";
        }

      }
    }

    //End of Air Pressure logic//
    //oil Pressure//
    if (marker.vehicleType === "IL") {
      if (marker.currentInfo.engineOilDig !== 0) {
        oilpress2iconimgs = "oilpressiconimg_ST";
        oilpressurelevels = "oilfillslvl";
      } else {
        oilpress2iconimgs = "oilpressiconimgred_ST";
        oilpressurelevels = "oilfillszero";
      }

    } else {
      oilpressuract = Math.round(marker.currentInfo.engine_oil_act);
      if (oilpressuract === 0) {
        oilpress2iconimgs = "oilpressiconimgred_ST";
        oilpressurelevels = "oilfillszero"
      } else if (oilpressuract === 1) {
        oilpress2iconimgs = "oilpressiconimgred_ST";
        oilpressurelevels = "oilfillslvl1"
      }
      else if (oilpressuract === 2) {
        oilpress2iconimgs = "oilpressiconimg_ST";
        oilpressurelevels = "oilfillslvl2"
      }
      else if (oilpressuract === 3) {
        oilpress2iconimgs = "oilpressiconimg_ST";
        oilpressurelevels = "oilfillslvl3"
      }
      else if (oilpressuract === 4) {
        oilpress2iconimgs = "oilpressiconimg_ST";
        oilpressurelevels = "oilfillslvl4"
      }
      else if (oilpressuract === 5) {
        oilpress2iconimgs = "oilpressiconimg_ST";
        oilpressurelevels = "oilfillslvl5"
      }
      else if (oilpressuract === 6) {
        oilpress2iconimgs = "oilpressiconimg_ST";
        oilpressurelevels = "oilfillslvl6"
      }
      else if (oilpressuract === 7) {
        oilpress2iconimgs = "oilpressiconimg_ST";
        oilpressurelevels = "oilfillslvl7"
      }
      else if (oilpressuract === 8) {
        oilpress2iconimgs = "oilpressiconimg_ST";
        oilpressurelevels = "oilfillslvl8"
      }
      else if (oilpressuract === 9) {
        oilpress2iconimgs = "oilpressiconimg_ST";
        oilpressurelevels = "oilfillslvl9"
      }
      else if (oilpressuract === 10) {
        oilpress2iconimgs = "oilpressiconimg_ST";
        oilpressurelevels = "oilfillslvl"
      }
    }
    let isHideAirPressure = marker.isBS6 === 0 && marker.vehicleType === "EDC" || marker.vehicleType === "IL"
    return (
      <div className="datacomponent">
        <div className="vincombox">
          {loadedVins.options ?
            <div style={{ width: "209px" }} className="customAutoComp">
              <Autocomplete
                options={loadedVins.options} // Ensure options are passed correctly
                getOptionLabel={loadedVins.getOptionLabel}
                id="debug"
                disableOpenOnFocus
                onChange={(event, newval) => this.updatelatestvins(newval)}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Reg Number"
                    margin="normal"
                    fullWidth
                  />
                )}
              />
            </div>
            : null
          }

        </div><div className="layoutdesign layoutdesign_ST" >
          <div className="ign_icon_ST dash_titles"><img className={marker.currentInfo.ignitionStatus !== 1 ? "imggray" : ""} src="/images/Vehicle_Dashboard/Ignition.png" alt="dash" /> IGNITION</div>
          <div className="dash_titles">
            <div className="dash_vehnumber_ST">REG.NUMBER <br />{marker.regnNumber}</div>
            <div className="dash_uptime_ST">LAST UPDATED ON<br />{moment(marker.currentInfo.localTimeStamp).format("DD-MM-YYYY h:mm:ss A")}</div> {/*MMM DD, YYYY, h:mm:ss A*/}
            <div className="dash_drivernm_ST">DRIVER<br />{marker.driverName}</div>
            <div className="dash_location_ST">LOCATION<br /><span style={{ width: '263px', display: 'inline-block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', margin: '-6px 0px' }} className="ffu13" title={marker.currentInfo.location} >{marker.currentInfo.location}</span></div>
          </div>
          {/* {marker.alert_type_id===9 ?<div className="edc_icon_ST"><img src="/images/Vehicle_Dashboard/ambur-color/2.png"  alt="dash"/></div>:<div className="edc_icon_ST"><img src="/images/Vehicle_Dashboard/grey-color/2.png"  alt="dash"/></div>} */}
          {new Date(marker.currentInfo.localTimeStamp) > this.state.ttldatetcompare ? marker.currentInfo.eng_coolant_temp >= 105 ? <div className="temp_icon_ST"><img src="/images/Vehicle_Dashboard/ambur-color/6.png" alt="dash" title={alert_types.temp_warning} /></div>
            : <div className="temp_icon_ST"><img src="/images/Vehicle_Dashboard/grey-color/6.png" alt="dash" title={alert_types.temp_warning} /></div> : <div className="temp_icon_ST"><img src="/images/Vehicle_Dashboard/grey-color/6.png" alt="dash" title={alert_types.temp_warning} /></div>}
          {new Date(marker.currentInfo.localTimeStamp) > this.state.ttldatetcompare ? marker.currentInfo.ignitionStatus === 1 ? marker.batteryType === "12V Battery" ? marker.currentInfo.battPotential <= 9 ? <div className="battery_icon_ST"><img src="/images/Vehicle_Dashboard/ambur-color/10.png" alt="dash" title={alert_types.low_battery_voltage} /></div> : <div className="battery_icon_ST"><img src="/images/Vehicle_Dashboard/grey-color/10.png" alt="dash" title={alert_types.low_battery_voltage} /></div> : marker.batteryType === "24V Battery" ? marker.currentInfo.battPotential <= 17 ? <div className="battery_icon_ST"><img src="/images/Vehicle_Dashboard/ambur-color/10.png" alt="dash" title={alert_types.low_battery_voltage} /></div> : <div className="battery_icon_ST"><img src="/images/Vehicle_Dashboard/grey-color/10.png" alt="dash" title={alert_types.low_battery_voltage} /></div> : <div className="battery_icon_ST"><img src="/images/Vehicle_Dashboard/grey-color/10.png" alt="dash" title={alert_types.low_battery_voltage} /></div> : <div className="battery_icon_ST"><img src="/images/Vehicle_Dashboard/grey-color/10.png" alt="dash" title={alert_types.low_battery_voltage} /></div> : <div className="battery_icon_ST"><img src="/images/Vehicle_Dashboard/grey-color/10.png" alt="dash" title={alert_types.low_battery_voltage} /></div>}
          {marker.isBS6 === 1 || marker.isBS6 === 2 ? new Date(marker.currentInfo.localTimeStamp) > this.state.ttldatetcompare ? marker.currentInfo.edc_lamp_status === 1 ? <div className="edc_icon_ST"><img src="/images/Vehicle_Dashboard/ambur-color/2.png" alt="dash" title={alert_types.edc_warning} /></div> : <div className="edc_icon_ST"><img src="/images/Vehicle_Dashboard/grey-color/2.png" alt="dash" title={alert_types.edc_warning} /></div> : <div className="edc_icon_ST"><img src="/images/Vehicle_Dashboard/grey-color/2.png" alt="dash" title={alert_types.edc_warning} /></div> : ''}
          {marker.isBS6 === 1 || marker.isBS6 === 2 ? new Date(marker.currentInfo.localTimeStamp) > this.state.ttldatetcompare ? (marker.currentInfo.ecu_mil_status === 4) ? <div className="mil_icon_ST"><img src="/images/Vehicle_Dashboard/ambur-color/1.png" alt="dash" title={alert_types.mil_status} /></div> : <div className="mil_icon_ST"><img src="/images/Vehicle_Dashboard/grey-color/1.png" alt="dash" title={alert_types.mil_status} /></div> : <div className="mil_icon_ST"><img src="/images/Vehicle_Dashboard/grey-color/1.png" alt="dash" title={alert_types.mil_status} /></div> : ''}
          {marker.isBS6 === 1 || marker.isBS6 === 2 ? new Date(marker.currentInfo.localTimeStamp) > this.state.ttldatetcompare ? marker.currentInfo.stop_status === 1 ? <div className="stp_icon_ST"><img src="/images/Vehicle_Dashboard/ambur-color/3.png" alt="dash" title={alert_types.stop_lamp} /></div> : <div className="stp_icon_ST"><img src="/images/Vehicle_Dashboard/grey-color/3.png" alt="dash" title={alert_types.stop_lamp} /></div> : <div className="stp_icon_ST"><img src="/images/Vehicle_Dashboard/grey-color/3.png" alt="dash" title={alert_types.stop_lamp} /></div> : ''}
          {marker.isBS6 === 1 ? new Date(marker.currentInfo.localTimeStamp) > this.state.ttldatetcompare ? marker.currentInfo.exhaust_system_high_temp_lamp_command === 1 ? <div className="hext_icon_ST"><img src="/images/Vehicle_Dashboard/ambur-color/4.png" alt="dash" title={alert_types.high_exa_temp} /></div> : <div className="hext_icon_ST"><img src="/images/Vehicle_Dashboard/grey-color/4.png" alt="dash" title={alert_types.high_exa_temp} /></div> : <div className="hext_icon_ST"><img src="/images/Vehicle_Dashboard/grey-color/4.png" alt="dash" title={alert_types.high_exa_temp} /></div> : ''}
          {marker.isBS6 === 1 ? new Date(marker.currentInfo.localTimeStamp) > this.state.ttldatetcompare ? (marker.currentInfo.dpf1_regen_needed >= 1 && marker.currentInfo.dpf1_regen_needed <= 3) ? <div className="dpf_icon_ST"><img src="/images/Vehicle_Dashboard/ambur-color/5.png" alt="dash" title={alert_types.regeneration} /></div> : <div className="dpf_icon_ST"><img src="/images/Vehicle_Dashboard/grey-color/5.png" alt="dash" title={alert_types.regeneration} /></div> : <div className="dpf_icon_ST"><img src="/images/Vehicle_Dashboard/grey-color/5.png" alt="dash" title={alert_types.regeneration} /></div> : ''}
          {marker.isBS6 === 1 ? new Date(marker.currentInfo.localTimeStamp) > this.state.ttldatetcompare ? (marker.currentInfo.nox_error >= 1 && marker.currentInfo.nox_error <= 2) ? <div className="nox_icon_ST"><img src="/images/Vehicle_Dashboard/ambur-color/7.png" alt="dash" title={alert_types.nox_error} /></div> : <div className="nox_icon_ST"><img src="/images/Vehicle_Dashboard/grey-color/7.png" alt="dash" title={alert_types.nox_error} /></div> : <div className="nox_icon_ST"><img src="/images/Vehicle_Dashboard/grey-color/7.png" alt="dash" title={alert_types.nox_error} /></div> : ''}
          {marker.isBS6 === 1 || marker.isBS6 === 2 ? new Date(marker.currentInfo.localTimeStamp) > this.state.ttldatetcompare ? marker.currentInfo.air_filter_blocked === 1 ? <div className="airfilbck_icon_ST"><img src="/images/Vehicle_Dashboard/ambur-color/8.png" alt="dash" title={alert_types.air_filter_blocked} /></div> : <div className="airfilbck_icon_ST"><img src="/images/Vehicle_Dashboard/grey-color/8.png" alt="dash" title={alert_types.air_filter_blocked} /></div> : <div className="airfilbck_icon_ST"><img src="/images/Vehicle_Dashboard/grey-color/8.png" alt="dash" title={alert_types.air_filter_blocked} /></div> : ''}
          {marker.isBS6 === 1 || marker.isBS6 === 2 ? new Date(marker.currentInfo.localTimeStamp) > this.state.ttldatetcompare ? marker.currentInfo.coolant_level_warning === 1 ? <div className="lowcoollvl_icon_ST"><img src="/images/Vehicle_Dashboard/ambur-color/9.png" alt="dash" title={alert_types.low_coolant_level} /></div> : <div className="lowcoollvl_icon_ST"><img src="/images/Vehicle_Dashboard/grey-color/9.png" alt="dash" title={alert_types.low_coolant_level} /></div> : <div className="lowcoollvl_icon_ST"><img src="/images/Vehicle_Dashboard/grey-color/9.png" alt="dash" title={alert_types.low_coolant_level} /></div> : ''}
          {marker.isBS6 === 1 ? new Date(marker.currentInfo.localTimeStamp) > this.state.ttldatetcompare ? (marker.currentInfo.low_adblue_level_warning >= 1 && marker.currentInfo.low_adblue_level_warning <= 7) ? <div className="lowadblue_icon_ST"><img src="/images/Vehicle_Dashboard/ambur-color/11.png" alt="dash" title={alert_types.low_def_level} /></div> : <div className="lowadblue_icon_ST"><img src="/images/Vehicle_Dashboard/grey-color/11.png" alt="dash" title={alert_types.low_def_level} /></div> : <div className="lowadblue_icon_ST"><img src="/images/Vehicle_Dashboard/grey-color/11.png" alt="dash" title={alert_types.low_def_level} /></div> : ''}
          {marker.isBS6 === 1 || marker.isBS6 === 2 ? new Date(marker.currentInfo.localTimeStamp) > this.state.ttldatetcompare ? marker.currentInfo.particulate_trap_regeneration_inhibit_switch === 1 ? <div className={marker.isBS6 === 1 ? 'particulate_icon_ST_bs6' : 'particulate_icon_ST'}><img src="/images/Vehicle_Dashboard/ambur-color/12.png" alt="dash" title={alert_types.particulate_trap} /></div> : <div className={marker.isBS6 === 1 ? 'particulate_icon_ST_bs6' : 'particulate_icon_ST'}><img src="/images/Vehicle_Dashboard/grey-color/12.png" alt="dash" title={alert_types.particulate_trap} /></div> : <div className={marker.isBS6 === 1 ? 'particulate_icon_ST_bs6' : 'particulate_icon_ST'}><img src="/images/Vehicle_Dashboard/grey-color/12.png" alt="dash" title={alert_types.particulate_trap} /></div> : ''}
          <div className="battery_img_ST"><img src="/images/Vehicle_Dashboard/Battery_Status.png" alt="dash" /><div className="battery_txt">BATTERY</div><br /><div className="battert_val_ST">{marker.currentInfo.battPotential === null ? '' : marker.currentInfo.battPotential.toFixed(2)}</div><div className="batteryunit_ST">{marker.currentInfo.battPotential !== null ? "v" : ''}</div>
            <div className={marker.currentInfo.ignitionStatus === 1 ? batimg : "batteryfillgrey"} >
              {marker.currentInfo.ignitionStatus === 1 ? <img className={batlevel} src="/images/Vehicle_Dashboard/Battery_Status.png" alt="dash" /> : ''}
            </div>
          </div>
          {marker.currentInfo.ignitionStatus === 1 ? marker.currentInfo.gsas_comp > 95 && marker.currentInfo.gsas_comp <= 100 ? <div >
            <div className="thumbsup_cls_ST"><img className="thumbsupimg" src="/images/Vehicle_Dashboard/thumbsup.png" alt="dash" />
            </div>
            <div className="thumbsup_cls2_ST"><img className="thumbsupimg_ST" src="/images/Vehicle_Dashboard/thumbsup.png" alt="dash" />
            </div>
            <div className="thumbsup_cls3_ST"><img className="thumbsupimg_ST" src="/images/Vehicle_Dashboard/thumbsup.png" alt="dash" />
            </div>
          </div> : marker.currentInfo.gsas_comp > 90 && marker.currentInfo.gsas_comp <= 95 ? <div >
            <div className="thumbsup_cls_ST"><img className="thumbsupimg_ST" src="/images/Vehicle_Dashboard/thumbsup.png" alt="dash" />
            </div>
            <div className="thumbsup_cls2_ST"><img className="thumbsupimg_ST" src="/images/Vehicle_Dashboard/thumbsup.png" alt="dash" />
            </div>
          </div> : marker.currentInfo.gsas_comp > 85 && marker.currentInfo.gsas_comp <= 90 ? <div >
            <div className="thumbsup_cls_ST"><img className="thumbsupimg_ST" src="/images/Vehicle_Dashboard/thumbsup.png" alt="dash" />
            </div></div> : marker.currentInfo.gsas_comp > 80 && marker.currentInfo.gsas_comp <= 85 ? <div >
              <div className="thumbsup_cls_ST"><img className="thumbsupimg_ST" src="/images/Vehicle_Dashboard/thumbsdown.png" alt="dash" />
              </div></div> : marker.currentInfo.gsas_comp > 75 && marker.currentInfo.gsas_comp <= 80 ? <div >
                <div className="thumbsup_cls_ST"><img className="thumbsupimg_ST" src="/images/Vehicle_Dashboard/thumbsdown.png" alt="dash" />
                </div>
                <div className="thumbsup_cls2_ST"><img className="thumbsupimg_ST" src="/images/Vehicle_Dashboard/thumbsdown.png" alt="dash" />
                </div>
              </div> : marker.currentInfo.gsas_comp > 0 && marker.currentInfo.gsas_comp <= 75 ? <div >
                <div className="thumbsup_cls_ST"><img className="thumbsupimg_ST" src="/images/Vehicle_Dashboard/thumbsdown.png" alt="dash" />
                </div>
                <div className="thumbsup_cls2_ST"><img className="thumbsupimg_ST" src="/images/Vehicle_Dashboard/thumbsdown.png" alt="dash" />
                </div>
                <div className="thumbsup_cls3_ST"><img className="thumbsupimg_ST" src="/images/Vehicle_Dashboard/thumbsdown.png" alt="dash" />
                </div>
              </div> : '' : ''}
          {marker.isBS6 === 1 || showDEFUI ? <div className="fuelperctext_ST_bs6">{tankpercentbs6 > 100 ? 100 : tankpercentbs6 < 0 ? 0 : tankpercentbs6}%</div> : <div className="fuelperctext_ST">{tankpercent > 100 ? 100 : tankpercent < 0 ? 0 : tankpercent}%</div>}
          {marker.isBS6 === 1 || showDEFUI ? <div className="fuelbsiv_bs6_ST"><img src="/images/Vehicle_Dashboard/Fuel_bs6.png" alt="dash" /><div className={fuelimg_bs6} ><img className={fuelevel_bs6} src="/images/Vehicle_Dashboard/Fuel_bs6.png" alt="dash" /></div></div> : <div className="fuelbsiv_ST"><img src="/images/Vehicle_Dashboard/BS_iv.png" alt="dash" /><div className={fuelimg} >
            <img className={fuelevel} src="/images/Vehicle_Dashboard/BS_iv.png" alt="dash" /></div></div>}
          <div className={fuelTextClass}>{fuelLabel}</div>
          {/* {marker.isBS6===1 ? <div className="fuel_text_bs6_ST">FUEL</div>:marker.isBS6===2 ?<div className="fuel_text_bs6_ST_cng">GAS</div>:<div className="fuel_text_ST">FUEL</div>} */}
          <div className="fuel_val_ST">{getFuelLevelValue(marker.currentInfo.fuelLevel)}<div className="fuel_units_ST">&nbsp;{getFuelLevelUnit(marker.isBS6, marker.vehicleType)}</div></div>


          {showDEFUI ? <div className="addbluetext_ST">{tankpercentaddblue}%</div> : ''}
          {showDEFUI ? <div className={marker.isBS6 === 3 ? "addblue_ST" : "addblue_ST"}>
            <img src="/images/Vehicle_Dashboard/Adblue_bs6.png" alt="dash" />
            <div className={fuelimg_addblue} >
              <img className={fuelevel_addblue} src="/images/Vehicle_Dashboard/Adblue_bs6.png" alt="dash" />
            </div>
          </div> : ''}
          {showDEFUI && <>
            <div className="addblue_text_ST">DEF</div>
            <div className="addblue_val_ST">{tantpercentadbluelevel}<div className="addblue_units vehicle_vehicle_dash_addblue_units">&nbsp;ltr</div></div>
          </>}
          <div className="roadings_ST"><img src="/images/Vehicle_Dashboard/roads.png" alt="dash" /></div>
          <div className="road_text_ST">ODO</div>
          <div className="road_val_ST"> {marker.isBS6 === 1 || marker.isBS6 === 2 ? marker.currentInfo.vehicle_distance !== null || marker.currentInfo.vehicle_distance !== '' ? Math.round(marker.currentInfo.vehicle_distance) : '' : marker.vehicleType === "EEA" ? Math.round(marker.currentInfo.vehicle_odo) : Math.round(marker.currentInfo.gps_odo)}<div className="road_units vehicle_dash">&nbsp;kms</div></div>
          {marker.isBS6 !== 4 && <div class="engHrs_ST"><img src="/images/Vehicle_Dashboard/Engine_Hours.png" alt="engHrs.icon" /></div>}
          {(marker.vehicleType !== "IL" && marker.isBS6 !== 4)  ? <div className="enginehrs_text_ST">ENG HRS</div> : ''}{(marker.vehicleType !== "IL" && marker.isBS6 !== 4) ? <div className="enginehrs_text_val_ST">{Math.round(marker.currentInfo.engineHrs)}<span className="enginehrsspan_ST"> hrs</span></div> : ''}
          <div className="cool_text_ST">COOLANT</div><div className="cool_val_ST">{marker.currentInfo.ignitionStatus === 1 ? marker.currentInfo.eng_coolant_temp < 0 ? 0 : Math.round(marker.currentInfo.eng_coolant_temp) : 0}<div className="cool_units"><sup>o</sup>C</div></div>
          <div className="tempicon_ST"><img src="/images/Vehicle_Dashboard/Temperaturegray.png" alt="dash" />
            <div className="tempfillimg_ST" >
              <img className={marker.currentInfo.ignitionStatus === 1 ? temperatureclass : 'tempfill'} src="/images/Vehicle_Dashboard/Temperature.png" alt="dash" />
            </div>
          </div>
          {showExhaust(marker.isBS6) && <>
            <div className="exhtempicon_ST"><img src="/images/Vehicle_Dashboard/Temperaturegray.png" alt="dash" />
              <div className="exhfillimg_ST" >
                <img className={marker.currentInfo.ignitionStatus === 1 ? exhtemperatureclass : 'extempfill'} src="/images/Vehicle_Dashboard/Temperature.png" alt="dash" />
              </div>
            </div>
            <div className="ext_text_ST">EXHAUST</div>
            <div className="ext_val_ST">{exhaustCalculationDashboard(marker.currentInfo)}<div className="ext_units"><sup>o</sup>C</div></div>
          </>}
          {showAirPressure(marker) && <>
            <div className="airpress1icon_ST">
              <img src="/images/Vehicle_Dashboard/PressureScale.png" alt="dash" />
              <div className={marker.vehicleType === "EEA" ? airpress1iconimgs : airpress2iconimgs} >
                <img className={marker.currentInfo.ignitionStatus === 1 ? airpressure1 : 'airpress'} src="/images/Vehicle_Dashboard/Pressure_Green_Scale.png" alt="dash" />
              </div>
            </div>
            <div className="air1_text0_ST">0</div>
            <div className="air1_text6_ST">6</div>
            <div className="air1_text12_ST">12</div>
            <div className="air2_text0_ST">0</div>
            <div className="air2_text6_ST">6</div>
            <div className="air2_text12_ST">12</div>
            <div className="air1_text_ST">
              <strong>AIR<br /> PRESSURE 1</strong>
            </div>
            <div className="air1_val_ST">{marker.currentInfo.ignitionStatus === 1 ? (marker.isBS6 === 1 || marker.isBS6 === 2 || marker.isBS6 === 4 || (marker.isBS6 === 0 && (marker.vehicleType === "EEA"))) ? airpressuract1 : '' : '' }
              <div className="air1_units">{marker.currentInfo.ignitionStatus === 1 ? (marker.isBS6 === 1 || marker.isBS6 === 2 || marker.isBS6 === 4 || (marker.isBS6 === 0 && (marker.vehicleType === "EEA"))) ? 'bar' : '' : '' }</div>
            </div>
            <div className="airpress2icon_ST">
              <img src="/images/Vehicle_Dashboard/PressureScale.png" alt="dash" />
              <div className={airpress2iconimgs} >
                <img className={marker.currentInfo.ignitionStatus === 1 ? airpressure2 : 'airpresssec'} src="/images/Vehicle_Dashboard/Pressure_Green_Scale.png" alt="dash" />
              </div>
            </div>
            <div className="air2_text_ST"><strong>AIR<br /> PRESSURE 2</strong></div>
            <div className="air2_val_ST">{marker.currentInfo.ignitionStatus === 1 ? (marker.isBS6 === 1 || marker.isBS6 === 2 || marker.isBS6 === 4 || (marker.isBS6 === 0 && (marker.vehicleType === "EEA"))) ? airpressuract2 : '' : '' }
              <div className="oil_units">{marker.currentInfo.ignitionStatus === 1 ? (marker.isBS6 === 1 || marker.isBS6 === 2 || marker.isBS6 === 4 || (marker.isBS6 === 0 && (marker.vehicleType === "EEA"))) ? 'bar' : '' : '' }</div>
            </div>
          </>}

          {showOilPressure(marker) && <>
            <div className="oil1_text0_ST">0</div>
            {marker.vehicleType !== "IL" ? <div className="oil1_text6_ST">6</div> : ''}
            {marker.vehicleType !== "IL" ? <div className="oil1_text12_ST">12</div> : <div className="oil1_text12_ST">1</div>}
            {<div className="oil_text_ST"><strong>OIL<br /> PRESSURE </strong></div>}
            {<div className="oil_val_ST">{marker.currentInfo.ignitionStatus === 1 ? marker.vehicleType === "IL" ? '' : oilpressuract : ''}<div className="air2_units">{marker.currentInfo.ignitionStatus === 1 ? marker.vehicleType === "IL" ? '' : "bar" : ''}</div></div>}
            {<div className="oilpressicon_ST">
              <img src="/images/Vehicle_Dashboard/PressureScale.png" alt="dash" />
              <div className={oilpress2iconimgs} >
                <img className={marker.currentInfo.ignitionStatus === 1 ? oilpressurelevels : 'oilfillszero'} src="/images/Vehicle_Dashboard/Pressure_Green_Scale.png" alt="dash" />
              </div>
            </div>}
          </>}
          <div className="criticaldash_ST"><img src="/images/Vehicle_Dashboard/Criticaldash.png" alt="dash" /></div>
          <div className="critic_text_ST">CRITICAL<br /> ALERT</div><div className="critic_val_ST">{marker.criccnt !== undefined ? marker.criccnt : 0}</div>
          {/*<div className="eucalt"><img src="/images/Vehicle_Dashboard/ECUdash.png"  alt="dash"/></div>
    <div className="eucalt_text">ECU<br/> ALERT</div><div className="eucalt_val">5</div> */}
          <div className="speedicon_ST"><img src="/images/Vehicle_Dashboard/Speedometer_scale.png" alt="dash" />
            <div className="speedfillimg_ST" >
              <img className={speedlevels} src="/images/Vehicle_Dashboard/Gradient.png" alt="dash" />
            </div>
          </div>
          <div className="speed_val_ST">{Math.round(marker.currentInfo.gpsSpeed)}<div className="speed_units_ST">kmph</div></div>
          <div className="rpm_val_ST">{Math.round(marker.currentInfo.engineSpeed)}<div className="rpm_units_ST">rpm</div></div>
          <div className="speed1_ST">0</div>
          <div className="speed2_ST">10</div>
          <div className="speed3_ST">20</div>
          <div className="speed4_ST">30</div>
          <div className="speed5_ST">40</div>
          <div className="speed6_ST">60</div>
          <div className="speed7_ST">80</div>
          <div className="speed8_ST">100</div>
          {showGearUI(marker.isBS6) && <>
            <div className="geartxt_ST">GEAR</div>
            <div className="gearmarker_ST"><img src="/images/Vehicle_Dashboard/Gear_marker.png" alt="dash" /></div>
            <div className="gearimg_ST">
              {gearvalue - 3 > 0 ? gearvalue - 4 === 0 ? <span className="gearn">N</span> : gearvalue - 3 <= 4 ? <span className="gearn">{gearvalue - 4}</span> : <span className="gearn">{gearvalue - 4}</span> : <span className="gearn clearvals">N</span>}
              {gearvalue - 3 >= 0 ? gearvalue - 3 === 0 ? <span className="gear1">N</span> : gearvalue <= 5 ? <span className="gear1">{gearvalue - 3}</span> : <span className="gear1">{gearvalue - 3}</span> : <span className="gear1 clearvals">N</span>}
              {gearvalue - 2 >= 0 ? gearvalue - 2 === 0 ? <span className="gear2">N</span> : gearvalue <= 5 ? <span className="gear2">{gearvalue - 2}</span> : <span className="gear2">{gearvalue - 2}</span> : <span className="gear2 clearvals">N</span>}
              {gearvalue - 1 >= 0 ? gearvalue - 1 === 0 ? <span className="gear3">N</span> : gearvalue <= 5 ? <span className="gear3">{gearvalue - 1}</span> : <span className="gear3">{gearvalue - 1}</span> : <span className="gear3 clearvals">N</span>}
              <span className="gear4">{gearvalue}</span>
              {gearvalue === "R" ? <span className="gear5 ">N</span> : gearvalue === "N" ? <span className="gear5 ">1</span> : gearvalue >= 14 ? <span className="gear5 clearvals">N</span> : <span className="gear5">{gearvalue + 1}</span>}
              {gearvalue === "R" ? <span className="gear6 ">1</span> : gearvalue === "N" ? <span className="gear6">2</span> : gearvalue >= 14 ? <span className="gear6 clearvals">N</span> : <span className="gear6 ">{gearvalue + 2}</span>}
              {gearvalue === "R" ? <span className="gear7 ">2</span> : gearvalue === "N" ? <span className="gear7">3</span> : gearvalue >= 14 ? <span className="gear7 clearvals">N</span> : <span className="gear7 ">{gearvalue + 3}</span>}
              {gearvalue === "R" ? <span className="gear8 ">3</span> : gearvalue === "N" ? <span className="gear8">4</span> : gearvalue >= 14 ? <span className="gear8 clearvals">N</span> : <span className="gear8 ">{gearvalue + 4}</span>}
            </div>
          </>}
          {/* <div className="moreinfotext" menuclickid="1,2" onClick={(e) => this.moreinfovalue("vehicleInfo/alerts",e)}>MORE INFO</div> */}
          {/* <div className="closetext" onClick={() => this.closedashboardbox()}>CLOSE</div> */}
          <div className="">
            <img src="/images/Vehicle_Dashboard/layoutdash.png" alt="dash" />
          </div>


        </div></div>
    );
  }
}
const mapStateToprops = state => {
  return {
    ctr: state.vehicledata.results,
    uD: state.userdata.id
  };
}

const mapdispatchToprops = dispatch => {
  return {
    ctrs: () => dispatch({ type: 'VehicleDatas', value: uservehicledate })
  };
}

export default connect(mapStateToprops, mapdispatchToprops)(vhdashboard);
