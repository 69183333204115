import React, { useState } from "react";
import NavLink from "../../Components/Link/Link";
import './Modal.scss';

const CustomModal = () => {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <>
      {isOpen ? 
        <div className="modal forceUpdateUserProfileModal" style={{ display: 'block' }} tabIndex="-1" role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Warning</h5>
              </div>
              <div className="modal-body">
                <p>Please update your profile details!</p>
                <NavLink link="/UserProfile">
                  <input
                    type="button"
                    className="yellow-btn userProfileBtn"
                    value="User Profile"
                  />
                </NavLink>
              </div>
            </div>
          </div>
        </div>
        : null
      }
    </>
  );
}

export default CustomModal;
