import * as actionTypes from './constants'

const initState = {
  expired15veh:0,
  expiredveh:0,
  expired30veh:0,
  searchresetval:false,
  fuelfillapi:false,
  currentlatsval:'',
  currentlongsval:'',
  tochecksubdata:false,
  searchresultdataval:[],
  selectedvrhcicount:[],
  searchvechiclelist:[], 
  showWelcomeModal: false,
  showFleetWarningModal: false,
  nogpscountvals:'',
  vehCount: {},
  fleetreport:{},
  fleetdashrep:{},
  gearfleetreport:{},
  howercss: "Total",
  selectedMarker: false,
  zoomToMarkers: "",
  todos: ["a", "1", "2", "2", "54"],
  currentPage: 1,
  todosPerPage: 50,
  listview: "",
  paginationcolor: 1,
  Vehiclestatushigh: "Total",
  refreshselecteddata:"Total",
  configureFormState: 'none',
  loader: false,
  markers: [],
  mastervehCount: {},
  searchresultdata: [],
  column: {
    vehicle_odometer: true,
    gps_speed: true,
    engine_speed: true,
    fuel_level: true,
    duration: false,
    air_pressure: false,
    oil_pressure: false,
    vehicle_battery_potentials: false,
    fuel_consum: false,
    altitude: false,
    vin_number: false,
    obu_id: false,
    vehicle_model: false,
    vehicle_type: false,
    coolant_temp: false,
    sub_valid_from: false,
    sub_valid_to: false
  },
  dashenable: false,
  dialviewshow: true,
  excel_data: [],
  sort: {
    column_name: 'time',
    order: 'desc'
  },
  totalmarkers: [],
  onswitchDropdownValue:false
};

const GmapsReducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_GMAPS_STATE:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}

export default GmapsReducer