import moment from "moment";
export const DDMMYYYY = "DD-MM-YYYY"
export const YYYYMMDD = "YYYY-MM-DD"
export const DDMMYYhhmmA = "DD-MM-YYYY hh:mm A"
export const ddmmyyyyhhmmss = "DD-MM-YYYY hh:mm:ss"
export const ddmmyyyyhhmmssA = "DD-MM-YYYY h:mm:ss A"
export const YYYY_MM_DD = "YYYY-MM-DD"

export const convertDateFormat = (value, format) => {
  return moment(value).format(format)
}
export const getcurrentTime = (format) => {
    return moment().format(format)
}

export const convertTimeToAmPm = (value) => {
    return moment(value, 'h:mm A').format('h:mm A');
}
// 2024-06-28T16:02:26.126 TO 4:00 PM 
export const convert24HrsTimeToAmPm = (value) => {
    return moment(value).format('hh:mm A');
}
export const getAlertCountDate = () => {
  const currentDate = moment();
  const previousDate = moment().subtract(144, 'hours');
  
  const formattedCurrentDate = currentDate.format(YYYYMMDD);
  const formattedPreviousDate = previousDate.format(YYYYMMDD);
  
  return {
    currentDate: formattedCurrentDate,
    previousDate: formattedPreviousDate
  };
}
