import React from 'react';
import { compose, withProps, lifecycle } from "recompose";
import {withScriptjs} from "react-google-maps";
import classnames from 'classnames';
import { StandaloneSearchBox } from "react-google-maps/lib/components/places/StandaloneSearchBox";
import { GOOGLE_SEARCH_API_KEY } from "../../apiList";

const PlacesWithStandaloneSearchBox = compose(
  withProps({
    googleMapURL: "https://maps.googleapis.com/maps/api/js?key="+GOOGLE_SEARCH_API_KEY+"&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
  }),
  lifecycle({
    componentRestrictions: {country: "ind"},
    UNSAFE_componentWillMount () {
      const refs = {}
      this.setState({
        places: [],
        placesArr:[],
        onSearchBoxMounted: ref => {
          refs.searchBox = ref;
        },
        onPlacesChanged: () => {
          const places = refs.searchBox.getPlaces();
          this.props.placeVals(places[0]);
        },
      })
    },
  }),
  withScriptjs  
)(props =>
  <div data-standalone-searchbox="" className={classnames(props.module === "S" ? null : "form-group" ,"col-lg-11")}>
    <StandaloneSearchBox ref={props.onSearchBoxMounted} bounds={props.bounds} 
      onPlacesChanged={props.onPlacesChanged} >
     <div className="mlf-3">
        <input
          type="text"
          placeholder={props.placeholder}
          disabled={props.disabled}
          onChange={props.change}
          value={props.value}
          name={props.name}
          onFocus={props.focus}
          data-item={props.item}
          className="form-control custom-control pl-lg-4"
        />
        {props.children}
      </div>
    </StandaloneSearchBox>
    {/* <ol>
      {props.places.map(({ place_id, formatted_address, geometry: { location } }) =>
        <li key={place_id}>
          {formatted_address}
          {" at "}
          ({location.lat()}, {location.lng()})
        </li>
      )}
    </ol> */}
  </div>
);

class AutoCompleteContent extends React.Component{

  constructor(props){
    super(props);
    this.handlePlaces = this.handlePlaces.bind(this);
  }

  handlePlaces=(placeVals)=>{
    //console.log(placeVals);
    this.props.placeVals(placeVals);
  }

  render(){
      return (
          <PlacesWithStandaloneSearchBox placeholder={this.props.placeholder}
            value={this.props.value} change={this.props.change} name={this.props.name} 
            placeVals={this.handlePlaces} focus={this.props.focus} item={this.props.item} 
            disabled={this.props.disabled} module={this.props.module}>
              {this.props.children}
          </PlacesWithStandaloneSearchBox>
      )
  }   
}

export default AutoCompleteContent;