import React, { useEffect, useState } from 'react'
import { useSelector } from "react-redux";
import Loader from '../Loader/Loader';
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import scroll from '../Scroll'

const ModelSelect = (props) => {
    const ctr = useSelector((state) => state.userdata.id);
    const { setState, state } = props
    const [modelValue, setmodelValue] = useState('')
    const [modelList, setmodelList] = useState(ctr.modelList || [])

    const handleSelectModel = async (value) => {
        const { yes_no_btn_clk } = state;
        props.actionProvider.inputHandler(props, value);
        if(yes_no_btn_clk){
            let obj = {'question': props.payload.question, 'title': props.payload.title, 'api': props.payload.api, 'column': props.payload.column, 'model': value}
            setState(state => ({ ...state, loader: false, result: true, q_clicked: false, yes_no_btn_clk: false, fleet_continue: true}))
            props.actionProvider.resultHandler2(obj, props.state.vinData, props.state.startDate, props.state.endDate, props.payload.title, props.payload.parent_title);
        }
        else{
            setState(state => ({ ...state, selected_model: value}))
            props.actionProvider.DateHandler(props.payload.question, props.payload.title, props.payload.api, props.payload.column, value);
        }
        scroll();
    }

    useEffect(() => {
        setmodelList(['All', ...modelList]);
    },[])

    const onChangeModel = (e, value) => {
        if (value !== null && !props.state.result) {
            setmodelValue(value)
            handleSelectModel(value)
        }
        else{
            setmodelValue('')
            setState(state => ({ ...state, expanded: props.state.index, yes_no_btn_clk: true}))
            // props.actionProvider.ButtonsHandler(props);
        }
        scroll();
    }

    var defaultModelProps = {
        options: modelList.length > 0 ? modelList : [],
        getOptionLabel: option => option
    }

    return (
        <>
            <div className='d-flex modal-div'>
                {/* <div className='col-md-12 top-bottom-title'>
                    <span>{props.payload.parent_title} - {props.payload.title}</span>
                </div> */}
                <div className='col-md-8'>
                    <Autocomplete
                        {...defaultModelProps}
                        id="loginIDAutoComplete"
                        value={modelValue || null}
                        onChange={(event, value) => { onChangeModel(event, value) }}
                        renderInput={params => (
                            <TextField {...params} label="Select Vehicle Model" placeholder="Enter Model" margin="normal" fullWidth />
                        )}
                    />
                </div>
            </div>
        </>
    )
}

export default ModelSelect