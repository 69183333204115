import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import * as apiList from '../../../apiList'
import { postData } from '../../../Util'
import Button from '@material-ui/core/Button';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import styles from './ByCustomer.module.css';

import { GmapsDispatchThunk } from '../../../store/actions/GmapsActions';

const ByCustomer = (props) => {
  const [loginID, setLoginID] = useState('')
  const [loginUserData, setLoginUserData] = useState([])
  const [customerID, setCustomerID] = useState('')
  const [customerData, setCustomerData] = useState({});

  const handleInputChange = (e) => {
    setLoginID(e.target.value)
  }

  const submitByCustomer = () => {
    props.menuData(false);
    var inputValue = document.getElementById('wlcm_select_by_customer').value;
    
    if(inputValue){
      let dataObj = {
        platform: "w",
        userId: props.ctr.userid,
        loginId: customerID,
        mappingType:"customer",
        vin: []
      };

      let vehtoken = props.ctr.token;
      const datas = postData(apiList.createVIN, dataObj, vehtoken);
      datas.then((dataVals) => {
          if(dataVals.statuscode === 200){
            props.menuData(true);
            props.callBack();
            props.gmapsDispatch({ showWelcomeModal: false })
            document.getElementsByClassName('searchBtn')[0].click();
          }
      });
    }
  }

  useEffect(() => {
    setCustomerData(props.customerData);
    if (loginID.length >= 4) {
      const userDetails = {
        loginData: loginID,
        platform: props.ctr.platform
      }
      const token = props.ctr.token
      async function fetchData() {
        const data = await postData(apiList.alUserProfileData, userDetails, token)
        const userData = data.data
        // console.log(userData)
        setLoginUserData(userData)
      }
      fetchData()
    }
  }, [loginID, props.ctr.platform, props.ctr.token]);

  var defaultProps = {
    options: loginUserData,
    getOptionLabel: option => option.user_info
  };

  return (
    <div className={styles.ByCustomerContainer}>
      <Autocomplete
        id="wlcm_select_by_customer"
        {...defaultProps}
        value={customerData ? customerData : {}}
        onChange={(event, value) => {
          if(value != null){
            setCustomerID(value.user_id);
            setCustomerData(value);
          }
        }}
        renderInput={params => (
          <TextField
            {...params}
            id="standard-basic"
            label="Login ID/Name/Transporter Name"
            style={{width: '50%'}}
            type="search"
            onChange={handleInputChange}
          />
        )}
      />
      
      <div>
        <Button
          variant="contained"
          color="secondary"
          endIcon={<ArrowForwardIosIcon />}
          children="Submit"
          onClick={submitByCustomer}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  ctr: state.userdata.id
})

const mapdispatchToprops = dispatch => {
  return {
    menuData: (data) => dispatch({ type: 'MENU', value: data }),
      gmapsDispatch: (payload) => dispatch(GmapsDispatchThunk(payload))
  };
}

export default connect(mapStateToProps, mapdispatchToprops)(ByCustomer)






