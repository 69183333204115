const scroll = () => {
    setTimeout(() => {
        // var element = document.getElementsByClassName('react-chatbot-kit-chat-message-container')[0];
        // element.scrollTop = element.scrollHeight + 10000;

        var element = document.getElementsByClassName('react-chatbot-kit-chat-message-container')[0];
        element.scrollTo({
            top: element.scrollHeight,
            behavior: 'smooth'
        });

    }, 1000);
}

export default scroll