const discount ={
    packages:[]
 }
 
 const discountReducer = (state = discount,action) => {
     if(action.type === 'PACKAGE'){
         return {
            packages:action.value
         };
     }
     return state;
 };
 
 export default discountReducer;