import React, { forwardRef } from "react";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DatePicker from "react-datepicker";
import { postData } from '../../Util';
import * as apilist from '../../apiList';
import { connect } from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";
import './DateComponent.scss';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import { convertedDateFormat } from '../../Util/commonConverter';
import { isDEFAvailable } from "../Trend/Util";

const styles = theme => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	formControl: {
		margin: theme.spacing.unit,
		minWidth: 120,
	},
	selectEmpty: {
		marginTop: theme.spacing.unit * 2,
	},
});

let uservehicledate = {};
let shownlistview = {}
class DateComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			startDate: null, endDate: null, startTime: null, endTime: null, loadedVins: [], submitbtn: '',
			startTimeText: "", endTimeText: "", showStartarrowCont: false, showEndarrowCont: false,loader : false,
		}
		// console.log(this.props);
		this.inputFocus = "";
		this.subInputFocus = "";
	}

	resetAll = () => {
		console.log(this.props.screenName);
		this.setState({
			startTimeText: "",
			endTimeText: ""
		}, () => {
			if (this.props.screenName === "trace") {
				this.props.onChangeSpeed("")
			}else{
				this.props.resetChart();
			}
			
			if(this.props.screenName !== "trend"){
				this.calltracetrendfn("reset");
			}else{
				this.calltracetrendfn("inital");
			}

			this.playRoute();
			// this.calltracetrendfn("reset");
		})
		this.inputFocus = '';
		this.subInputFocus = "";
	}

	calltracetrendfn = (calledOn) => {
		if (window.location.href.toLowerCase().indexOf('/trend') > -1) {
			if (this.props.selectedvitals.length === 0) {
				this.setState({ submitbtn: "btndisable" })
			}
		}

		if (this.props.userDetails || this.props.uD) {
			let uData = this.props.userDetails || this.props.uD;
			this.loadSelectVehicle(uData);
		}

		if (this.props.ctr.selectedVehicle) {
			//  debugger
			let selectedVeh = this.props.ctr.selectedVehicle.currentInfo;
			let localTimeStamp = convertedDateFormat(selectedVeh.localTimeStamp);
			let toDate = new Date(localTimeStamp);
			// let toDate = new Date(propDate);
			let fromDate = new Date(localTimeStamp).setHours(toDate.getHours() - 6);
			let start_time = this.formateTime(fromDate);
			let end_time = this.formateTime(toDate);
			let vehStatus = selectedVeh.status;
			//let vehStatus = "";
			if (window.location.href.toLowerCase().indexOf('/trend') > -1) {
				this.setState({
					startDate: fromDate,
					endDate: toDate,
					startTime: fromDate,
					startTimeText: start_time,
					endTime: toDate,
					endTimeText: end_time,
					vstatus: vehStatus,
					endVehicle: ''
				}, () => {
					if (calledOn === "inital") {
						this.calldatefns(fromDate, "startDate", toDate, 'endDate');
					} else {
						this.callTrace(false);
					}
				});
			} else {
				this.setState({ startDate: fromDate, endDate: toDate, startTime: fromDate, startTimeText: start_time, endTime: toDate, endTimeText: end_time, vstatus: vehStatus, endVehicle: '' }, this.callTrace);
			}
		}
	}

	componentDidMount() {
		shownlistview = "showlist";
		this.props.listviewshown();
		this.calltracetrendfn("inital");
		
		//debugger
	}
	calldatefns = (d1, d2, d3, d4) => {
		this.handlechangefromdisplay(d1, d2)
		this.handlechangetodisplay(d3, d4)
	}

	loadSelectVehicle = (uD) => {
		// console.log(uD);
		let newObj = {};
		let vehtokens = uD.token;
		newObj.platform = 'w';
		newObj.userId = uD.userid;

		const datas = postData(apilist.vehicleInfo, newObj, vehtokens);
		this.setState({loader : true})
		//const datas = postData('http://10.18.1.128:5000/api/FMS/vehicleInfo', newObj);
		datas.then((dataVals) => {
			this.setState({loader :false})
			//console.log(dataVals);
			if (dataVals && dataVals.statuscode === 200) {
				dataVals.data.map((data) => {
					data.title = data.regn_number;
					return data;
				});
				this.setState({
					loadedVins: {
						options: dataVals.data,
						getOptionLabel: option => option.title
					}
				});
				if (this.props.ctr.selectedVehicle === undefined) {
					let vehlist = {};
					vehlist.userId = this.props.uD.userid;
					vehlist.userRole = this.props.uD.userrole;
					vehlist.obuIdList = [];
					let vehtoken = this.props.uD.token;
					vehlist.platform = "w";
					let urls;
					this.setState({loader : true})
					if (this.props.uD.userrole === "DEALER OUTLET ADMIN") {
						urls = apilist.allvehicledatadealer
					} else {
						urls = apilist.allvehicledata
					}
					const response = postData(urls, vehlist, vehtoken); //"http://10.18.1.128:7000/api/map/ownVehicleList"
					response.then((datas) => {
						if (datas.statuscode === 200) {
							var obulist = {
								obu_id: datas.data[0].obuId
							}
							this.updateState(obulist)
						}
					}).finally(() => {
						this.setState({loader : false})
					});
				} else {
					if (typeof(this.props.vitalselect) === 'function') {
						this.props.vitalselect()
					}
				}
			} else {
				this.setState({
					loadedVins: {
						options: [],
						getOptionLabel: option => option?.title
					}
				});
				alert('Failed to load Change Vehicle Data..')
			}
		});
	}

	addDays = (date, days) => {
		let dateVal = date;
		dateVal.setDate(date.getDate() + days)
		return dateVal;
	}

	calcDays = (date, days) => {
		let dateVal = date;
		dateVal.setDate(date.getDate() + (days));
		return dateVal;
	}

	handlechangefromdisplay = (date, name) => {
		let sD = new Date(date)
		sD = new Date(sD);
		let mmVal = sD.getMonth() + 1
		let yy = sD.getFullYear(),
			mm = (mmVal + "").length === 1 ? "0" + mmVal : mmVal,
			dd = (sD.getDate() + "").length === 1 ? "0" + sD.getDate() : sD.getDate();
		sD = yy + '-' + mm + '-' + dd;
		var ssD;
		ssD = dd + '-' + mm + '-' + yy;
		this.setState({ displayfromdate: ssD })
		this.setState({ [name]: date });
	}

	handlechangetodisplay = (date, name) => {
		let eD = new Date(date)
		let emVal = eD.getMonth() + 1;
		eD = new Date(eD);
		let tyy = eD.getFullYear(),
			tmm = (emVal + "").length === 1 ? '0' + emVal : emVal,
			tdd = (eD.getDate() + "").length === 1 ? "0" + eD.getDate() : eD.getDate();
		eD = tyy + '-' + tmm + '-' + tdd;
		var eeD;
		eeD = tdd + '-' + tmm + '-' + tyy;
		this.setState({ displaytodate: eeD })
		this.setState({ [name]: date });
	}

	handleChange = (date, name) => {
		this.setState({ [name]: date });
	};

	getFrequency = (daycount) => {
		var freq = daycount;
		daycount.days = Math.abs(daycount.days)
		var fr = ".5";
		if (freq.days > 3 && freq.days <= 5) {
			fr = "5";
		} else if (freq.days > 5 && freq.days <= 10) {
			fr = "7";
		} else if (freq.days > 10 && freq.days <= 15) {
			fr = "10";
		} else if (freq.days > 15 && freq.days <= 30) {
			fr = "14";
		} else if (freq.days > 30 && freq.days <= 45) {
			fr = "20";
		} else if (freq.days > 45 && freq.days <= 60) {
			fr = "30";
		} else if (freq.days > 60 && freq.days <= 90) {
			fr = "45";
		} else if (freq.days > 90 && freq.days <= 120) {
			fr = "30";
		} else if (freq.days > 120 && freq.days <= 150) {
			fr = "45";
		} else if (freq.days > 150 && freq.days <= 180) {
			fr = "60";
		}
		return fr
	}

	getFrequencyunified = (daycount) => {
		var freq = daycount;
		daycount.days = Math.abs(daycount.days)
		var fr = "2.5";
		if (freq.days > 3 && freq.days <= 5) {
			fr = "15";
		} else if (freq.days > 5 && freq.days <= 10) {
			fr = "25";
		} else if (freq.days > 10 && freq.days <= 15) {
			fr = "40";
		} else if (freq.days > 15 && freq.days <= 30) {
			fr = "50";
		} else if (freq.days > 30 && freq.days <= 45) {
			fr = "75";
		} else if (freq.days > 45 && freq.days <= 60) {
			fr = "100";
		} else if (freq.days > 60 && freq.days <= 90) {
			fr = "125";
		} else if (freq.days > 90 && freq.days <= 120) {
			fr = "150";
		} else if (freq.days > 120 && freq.days <= 150) {
			fr = "225";
		} else if (freq.days > 150 && freq.days <= 180) {
			fr = "300";
		}
		return fr;
	}

	dateDiff = (earlierDate, laterDate) => {
		var oDiff = {};
		var nTotalDiff = laterDate.getTime() - earlierDate.getTime();
		oDiff.days = Math.round(nTotalDiff / 1000 / 60 / 60 / 24);
		oDiff.hours = Math.round(nTotalDiff / 1000 / 60 / 60);
		oDiff.minutes = Math.round(nTotalDiff / 1000 / 60);
		oDiff.seconds = Math.round(nTotalDiff / 1000);
		var hourtext = '00';
		if (oDiff.days > 0) { hourtext = String(oDiff.days); }
		if (hourtext.length === 1) { hourtext = '0' + hourtext };
		var mintext = '00';
		if (oDiff.minutes > 0) { mintext = String(oDiff.minutes); }
		if (mintext.length === 1) { mintext = '0' + mintext };
		var sectext = '00';
		if (oDiff.seconds > 0) { sectext = String(oDiff.seconds); }
		if (sectext.length === 1) { sectext = '0' + sectext };
		var sDuration = hourtext + ':' + mintext + ':' + sectext;
		oDiff.duration = sDuration;
		return oDiff;
	}

	componentDidUpdate(prevProps, prevState) {
		//console.log(prevProps, prevState,this.props);
		if (window.location.href.toLowerCase().indexOf('/trend') > -1) {
			if (this.props.selectedvitals.length !== 0) {
				if (prevProps.selectedvitals !== this.props.selectedvitals) {
					this.setState({ submitbtn: '' })
				}
			}
		}
	}

	callTrace = (isSubmit) => {
		//debugger
		let selectedVeh = this.props.ctr.selectedVehicle.currentInfo;
		let stat = this.state;
		this.playRoute();
		//if(selectedVeh && Object.keys(selectedVeh).length>0 && stat.startDate && stat.endDate && stat.startTime && stat.endTime) {
		if (selectedVeh && Object.keys(selectedVeh).length > 0) {
			let sD = new Date(stat.startDate), eD = new Date(stat.endDate), sT = new Date(stat.startTime), eT = new Date(stat.endTime);

			sD = new Date(sD).setHours(sT.getHours());
			sD = new Date(sD).setMinutes(sT.getMinutes());

			eD = new Date(eD).setHours(eT.getHours());
			eD = new Date(eD).setMinutes(eT.getMinutes());
			eD = new Date(eD);
			sD = new Date(sD);
			let tDiff = eD.getTime() - sD.getTime();

			let dDiff = this.dateDiff(eD, sD);
			let freq = '2.5';
			if (selectedVeh.packet_type === 6) {
				freq = this.getFrequencyunified(dDiff);
			} else {
				freq = this.getFrequency(dDiff);
			}

			let fsT = (sT.getHours() + "").length === 1 ? "0" + sT.getHours() : sT.getHours(), fsM = (sT.getMinutes() + "").length === 1 ? "0" + sT.getMinutes() : sT.getMinutes(), teT = (eT.getHours() + "").length === 1 ? "0" + eT.getHours() : eT.getHours(), teM = (eT.getMinutes() + "").length === 1 ? "0" + eT.getMinutes() : eT.getMinutes();
			sT = fsT + ":" + fsM
			eT = teT + ":" + teM;

			if (sD === null || !stat.startDate) {
				alert("Please enter the Valid Start Date.");
				return false;
			} else if (eD === null || !stat.endDate) {
				alert("Please enter the Valid End Date.");
				return false;
			} else if (sT === null || !stat.startTime) {
				alert("Please enter the Valid Start Time.");
				return false;
			} else if (eT === null || !stat.endTime) {
				alert("Please enter the Valid End Time.");
				return false;
			} else if (tDiff < 0) {
				alert("Please enter Start Time Less than End Time.");
				return false;
			} else {
				let mmVal = sD.getMonth() + 1,
					emVal = eD.getMonth() + 1;
				let yy = sD.getFullYear(),
					mm = (mmVal + "").length === 1 ? "0" + mmVal : mmVal,
					dd = (sD.getDate() + "").length === 1 ? "0" + sD.getDate() : sD.getDate();
				sD = yy + '-' + mm + '-' + dd;
				let tyy = eD.getFullYear(),
					tmm = (emVal + "").length === 1 ? '0' + emVal : emVal,
					tdd = (eD.getDate() + "").length === 1 ? "0" + eD.getDate() : eD.getDate();
				eD = tyy + '-' + tmm + '-' + tdd;
				var ssD;
				var eeD;
				ssD = dd + '-' + mm + '-' + yy;
				eeD = tdd + '-' + tmm + '-' + tyy;
				this.setState({ displayfromdate: ssD })
				this.setState({ displaytodate: eeD })
				let newObj = {
					"fromDate": sD,
					"toDate": eD,
					"fromTime": sT,
					"toTime": eT,
					"obuId": selectedVeh.obuId,
					"frequency": freq,
					"vinNumberdetails": selectedVeh.vinNumber,
				}
				this.props.goTrace(newObj, isSubmit);
			}
		} else {
			alert("No Data Found....");
			return false;
		}
	}

	playRoute = () => {
		if (this.props.screenName === 'trace') {
			this.props.playbackrouteHide();
		}
	}

	formateTime = (val) => {
		let timeVal = new Date(val);
		return this.convertTimetoString(timeVal.getHours()) + ":" + this.convertTimetoString(timeVal.getMinutes());
	}

	convertTimetoString = (i) => {
		let n = i > 9 ? "" + i : "0" + i;
		return n;
	}

	updateState = (newVal, event) => {
		//console.log(newVal);
		//console.log(this.props.userDetails);
		this.playRoute();
		let uDet = this.props.userDetails || this.props.uD;
		let previousSelectedveh = this.props.ctr.selectedVehicle;
		if (newVal && uDet) {

			let newObj = {};
			newObj.platform = 'w';
			newObj.userId = uDet.userid;
			newObj.userRole = uDet.userrole;
			let tokensvalue = uDet.token;
			newObj.obuIdList = [newVal.obu_id];
			let urls;
			if (uDet.userrole === "DEALER OUTLET ADMIN") {
				urls = apilist.allvehicledatadealer
			} else {
				urls = apilist.allvehicledata
			}
			this.setState({loader : true})
			const datas = postData(urls, newObj, tokensvalue);
			//const datas = postData('http://10.18.1.113:8000/api/map/ownVehicleList', newObj);
			datas.then((dataVals) => {
				this.setState({loader : false})
				if (dataVals.statuscode === 200) {
					let mVal = {};
					mVal.selectedVehicle = dataVals.data[0];
					mVal.previousSelected_isBs6 = previousSelectedveh != undefined ? previousSelectedveh.isBS6 : "";
					mVal.previouseSelectedAdblue = isDEFAvailable(previousSelectedveh !== undefined ? previousSelectedveh.adBlueTankCap : "");
					uservehicledate = mVal;
					this.props.ctrs();
					this.props.receiveProps.updateChanges();
					let selectedVehl = mVal.selectedVehicle.currentInfo;
					if (selectedVehl && Object.keys(selectedVehl).length > 0) {
						let localTimeStamp = convertedDateFormat(selectedVehl.localTimeStamp);
						let toDate = new Date(localTimeStamp);
						let fromDate = new Date(localTimeStamp).setHours(toDate.getHours() - 6);
						let vehStatus = selectedVehl.status;
						//let vehStatus = "";
						this.setState({
							startDate: fromDate,
							endDate: toDate,
							startTime: fromDate,
							endTime: toDate,
							vstatus: vehStatus,
							endVehicle: '',
							selectedVeh: uservehicledate
						}, () => {
							this.callTrace(false);

						}

						);
						if (typeof(this.props.vitalselect) === 'function') {
							this.props.vitalselect()
						}
						//this.setState({ selectedVeh: uservehicledate });
					} else {
						alert("No Data Available for selected Vehilce...");
						this.setState({
							datas: ''
						});
					}
				} else {
					alert("Please check...");
					this.setState({
						datas: ''
					});
					return false;
				}
			});
		} else {
			console.log('Redux user Data or selected Val Error');
			return false;
		}
	};

	handleClickOutside = (e) => {
		if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
			// alert('You clicked outside of me!');
			this.showStartArrowWrapper_up.hidden = true;
			this.showStartArrowWrapper_dn.hidden = true;
		}
		if (this.endWrapperRef && !this.endWrapperRef.contains(e.target)) {
			this.showEndArrowWrapper_up.hidden = true;
			this.showEndArrowWrapper_dn.hidden = true;
		}
	}

	render() {
		const { classes } = this.props;
		const ref = React.createRef();
		const CustomDatePicker = forwardRef(({ value, onClick, labelVal, inputname, selectedvalie }, ref) => (
			<div className="dateBlock" ref={ref}>
				<p className="inputContainer">
					<input type="text" value={selectedvalie} onChange={onClick} onClick={onClick} placeholder="Start Date" required name={inputname} />
					<i className="fa fa-calendar-o icon cs_cursor_pointer" onClick={onClick}></i>
					<label>{labelVal}</label>
				</p>
			</div>
		));

		let start_time = this.state.startTimeText.split(":");
		let end_time = this.state.endTimeText.split(":");

		return (
			<div className="row">
				{this.state.loader ?
                    <div className="Pageloading">
                        <img className="loadimg" src="/images/loader_1.gif" alt="iAlert"/>
                    </div>
                    :
                    null
                }
				<div className={this.props.showMiniToolbar ? "header-col-one-xp" : "header-col-one"}>
					<div className="dateandTimeComponent">
						<div style={{ width: "220px" }} className="customAutoComp">
							<Autocomplete
								{...this.state.loadedVins}
								id="debug"
								// disableOpenOnFocus
								onChange={
									(event, newval) => { this.updateState(newval, event) }
								}	
								renderInput={params =>
									<TextField
										{...params}
										label="Reg Number"
										margin="normal"
										fullWidth
									/> 
								}
							/>
						</div>

						<DatePicker
							selected={this.state.startDate}
							style={{ display: 'none' }}
							onChange={date => this.handlechangefromdisplay(date, "startDate")}
							minDate={this.calcDays(new Date(), -90)}
							maxDate={this.state.endDate || new Date()}
							dateFormat="dd/MM/YYY"
							customInput={<CustomDatePicker labelVal='Start Date' selectedvalie={this.state.displayfromdate} inputname="StartDate" ref={ref} />}
						/>

						<DatePicker
							selected={this.state.endDate}
							onChange={date => this.handlechangetodisplay(date, 'endDate')}
							minDate={this.state.startDate}
							maxDate={new Date()}
							dateFormat="dd/MM/YYY"
							customInput={<CustomDatePicker labelVal='End Date' selectedvalie={this.state.displaytodate} inputname="endDate" />}
						/>
						 <div className="dc_datepicker_div dc_datepicker_div_1">
							<label>Start Time</label>
							<br />
							<label className="date-picker-label">
								<i class="fa fa-clock-o form-control-feedback" aria-hidden="true"></i>
								<DatePicker
									selected={this.state.startTime}
									onChange={date => this.handleChange(date, 'startTime')}
									showTimeSelect={true}
									showTimeSelectOnly={true}
									timeFormat="HH:mm"
									timeIntervals={5}
									timeCaption="time"
									dateFormat="HH:mm "
								/>
							</label>
						</div> 

						<div className="dc_datepicker_div dc_datepicker_div_2">
							<label>End Time</label>
							<br />
							<label className="date-picker-label">
								<i class="fa fa-clock-o form-control-feedback" aria-hidden="true"></i>
								<DatePicker
									selected={this.state.endTime}
									onChange={date => this.handleChange(date, 'endTime')}
									showTimeSelect={true}
									showTimeSelectOnly={true}
									timeFormat="HH:mm"
									timeIntervals={5}
									timeCaption="time"
									dateFormat="HH:mm"
								/>
							</label>
						</div>

						<button
							className={this.props.showMiniToolbar ? "common-button ffub13 buttonBlock dc_datepicker_btn_1 btn-mg" : "common-button ffub13 buttonBlock dc_datepicker_btn_1"}
							disabled={this.state.submitbtn === "btndisable" ? true : false}
							onClick={this.callTrace}
						>Submit &#x00A0;
							<i className="fa fa-check" aria-hidden="true"></i>
						</button>

						{this.props.showMiniToolbar === true && <button className={this.props.downloadbutton === true ?
							'common-button ffub12 custDownload buttonBlock btn-mg' : 'hideBtn'}
							disabled={this.props.downloadbtn ? false : true}
							onClick={this.props.updateExcel}
						>Download &#x00A0;
							<i className="fa fa-download" aria-hidden="true"></i>
						</button>}

						<button
							className={this.props.showMiniToolbar ? "common-button ffub13 buttonBlock btn-mg" : "common-button ffub13 buttonBlock"}
							onClick={this.resetAll}
						>Reset &#x00A0;
							<i className="fa fa-repeat" aria-hidden="true"></i>
						</button>

					</div>
				</div>

				<div className={this.props.showMiniToolbar ? "header-col-two-xp" : "header-col-two"}>
					{this.props.screenName === "trace" &&
						<div className="col-flex">
							<div style={{ width: "60px" }} className="customAutoComp1">
								<label className="header-col-lb">Speed</label>
								
								<Select
									MenuProps={{
										anchorOrigin: {
											vertical: "bottom",
											horizontal: "left"
										},
										transformOrigin: {
											vertical: "top",
											horizontal: "left"
										},
										getContentAnchorEl: null,
										PaperProps: {
											style: { fontSize: "0.8rem", lineHeight: 1 }
										}
									}}
									value={this.props.selectValue}
									onChange={(e) => this.props.onChangeSpeed(e)}
									inputProps={{
										name: 'speed',
										id: 'speed-st',
									}}
								>
									<MenuItem value={"1"}>1X</MenuItem>
									<MenuItem value={"2"}>2X</MenuItem>
									<MenuItem value={"3"}>3X</MenuItem>
									<MenuItem value={"4"}>4X</MenuItem>
									<MenuItem value={"5"}>5X</MenuItem>
								</Select>
								{/* </FormControl> */}
							</div>
							<button
								className="common-button ffub13 buttonBlock header-custom-btn"
								onClick={this.props.playbackFunction}
							>{this.props.btnName} &#x00A0;
								<i className={this.props.btnName === "Play" ? "fa fa-play" : "fa fa-pause"} aria-hidden="true"></i>
							</button>
							<span className="btn-new-info">New</span>
						</div>
					}
				</div>
			</div>
		)
	}
}

const mapStateToprops = state => {
	return {
		ctr: state.vehicledata.results,
		uD: state.userdata.id
	};
}

const mapdispatchToprops = dispatch => {
	return {
		ctrs: () => dispatch({ type: 'VehicleDatas', value: uservehicledate }),
		listviewshown: () => dispatch({ type: 'listviewshow', value: shownlistview })
	};
}

export default connect(mapStateToprops, mapdispatchToprops)(withStyles(styles)(DateComponent));