const initialState = {
    allowed : false,
    message: ""
}

const sarathyReducer = (state = initialState, action) =>{
    if(action.type === 'ALLOWED'){
        return{
            ...state,
            allowed: action.value.allowed,
            message: action.value.message
        }
    }
    return state;
}

export default sarathyReducer;