import React from 'react'
import scroll from '../Scroll'
import { useEffect } from 'react';
import { useSelector } from "react-redux";
import { getVinNumbers } from '../API/useApi'

const Buttons = (props) => {
    const { setState } = props
    const ctr = useSelector((state) => state.userdata.id);

    useEffect(() => {
        if(props.state.vin_number.length <= 0){
            fetchData();
        }
    }, []);

    const fetchData = async () => {
        try {
          const response = await getVinNumbers(ctr);
          setState(state => ({ ...state, vin_number: response.data}))
        } catch (error) {
          console.error(error);
        }
    };


    const handleChange_Nav = (key, title) => {
        setState(state => ({ ...state, parent_key: key, parent_title: title, yes_no_btn_clk: false, fleet_continue: false}))
        // Skip filter for location because there is no sub qeustion in vehicle location
        if(key !== 'current_location'){
            let filterData = props.state.questions.filter(data => data.key === key)
            props.actionProvider.MenuQuestions(props, filterData, key, title);
        }
        else{
            setState(state => ({ ...state, selected_ques: key, result: false, q_clicked: true}));
            props.actionProvider.VinHandler(key, title, 'ownVehicleList', '', '', title);
        }
        scroll();
    };

    const renderMenus = () => {
        return(
            <>
                {props.state.questions.map((question, index) => {
                    return (
                        <button key={index} class="option_IVDn" onClick={()=> {handleChange_Nav(question.key, question.title)}}>{question.title}</button>
                    )
                    })
                }
            </>
        )
    }

    return (
        <div class="options_L6yW">
            {renderMenus()}
        </div>
    )
}

export default Buttons
