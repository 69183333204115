import React, { useState, useEffect } from "react";
import "./NumberPickerDropdown.scss";
const NumberPickerDropdown = ({ isHour, initialValue, getSelectedValue }) => {
  const [hours, setHours] = useState(initialValue);
  const [ddOption, setDDOption] = useState(initialValue);

  const handleHoursChange = (event) => {
    setHours(parseInt(event.target.value));
    getSelectedValue(parseInt(event.target.value));
  };
  useEffect(() => {
    if (isHour) {
      const hoursOptions = [...Array(73).keys()].slice(1).map((hour) => (
        <option key={hour} value={hour}>
          {hour}
        </option>
      ));
      setDDOption(hoursOptions);
    } else {
      const hoursOptions = [...Array(8).keys()].slice(1).map((hour) => (
        <option key={hour} value={hour}>
          {hour}
        </option>
      ));
      setDDOption(hoursOptions);
    }
  }, [isHour]);
  useEffect(() => {
    setHours(initialValue);
  }, [initialValue]);

  return (
    <div className="selectWrapper">
      <select className="selectBox" value={hours} onChange={handleHoursChange}>
        {ddOption}
      </select>
    </div>
  );
};

export default NumberPickerDropdown;
