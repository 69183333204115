export const withExecutionTime = (func) => {
    return function(...args) {
      const startTime = performance.now(); // or Date.now()
      const result = func.apply(this, args);
      const endTime = performance.now(); // or Date.now()
      const executionTime = endTime - startTime;
      console.log(`Function ${func.name} took ${executionTime} milliseconds to execute.`);
      return result;
    };
}
// to find the min and max value from list of object based on key
export const findMinMax = (objectsArray, property) => {
    if (!Array.isArray(objectsArray) || objectsArray.length === 0) {
      return { min: undefined, max: undefined };
    }
  
    objectsArray.sort((a, b) => a[property] - b[property]);
  
    const [minValue, maxValue] = [objectsArray[0][property], objectsArray[objectsArray.length - 1][property]];
  
    return { min: minValue, max: maxValue };
};