import React from 'react';
import classnames from 'classnames';

const button = (props) => {
    
    return (
        <input type="button" className={classnames('map-btn', props.btnImg)} value={props.value} 
        onClick={props.click}/>
    );
}

export default button;