import React from 'react'

const EconomyScore = ({question, data}) => {
  return (
    <div>
        {/* <span className='result-span'>{ question +' : '}</span>  */}
        {question === 'economy_score' && 
            <span className=''> {data.length > 0 ? data[0].regn_number + ': '+ data[0].performance_score : '-'} </span>
        }
        {question === 'safety_score' && 
            <span className=''> {data.length > 0 ? data[0].regn_number + ': '+ data[0].safety_score : '-'} </span>
        }
    </div>
  )
}

export default EconomyScore