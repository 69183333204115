import React from "react";
import './main.scss';
import DatePicker from "react-datepicker";
import { connect } from 'react-redux';
import { postData, alphaNumeric, postStreamData } from '../../Util';
import * as apilist from '../../apiList';
import * as alasql from 'alasql';
import XLSX from 'xlsx';
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import AlertBox from "../../Components/AlertBox/alertBox";
import subDays from "date-fns/subDays";
import classnames from 'classnames';
import { unzip } from 'unzipit';

alasql.utils.isBrowserify = false;
alasql.utils.global.XLSX = XLSX;

class rawdata extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      startDate: new Date(),
      endDate: new Date(),
      startMindate: subDays(new Date(), 90),
      startMaxdate: new Date(),
      endMindate: new Date(),
      endMaxdate: new Date(),
      userDet: this.props.uD,
      vitalarray: [],
      vitalvalues: 0,
      loader: false,
      loadedVins: [],
      optVal: [],
      vehiclesDataArr: [],
      vehoptVal: [],
      vehoptValShowcase: [],
      showMessage: "",
      showAlertBox: false,
      messageType: "e",
      searchtext: "",
      emisionControl: 0,
      vitalkeys: [],
      initialDtls: false,
      regNumbersWithNoRecords: [],
      fuelDefCapacity: []
    }
  }

  handleChangeStart = date => {
    this.setState({
      startDate: date,
      endMindate: new Date(date)
    });
  };

  handleChangeEnd = date => {
    this.setState({
      endDate: date,
      startMaxdate: date ? new Date(date) : null
    });
  };

  handleCheckboxvit = (value, e) => {
    if (e.target.checked === true) {
      this.setState({
        vitalarray: this.state.vitalarray.concat(value)
      })
    } else {
      var spliceIndex = this.state.vitalarray.indexOf(value);
      if (spliceIndex > -1) {
        this.state.vitalarray.splice(spliceIndex, 1);
      }
    }
  }

  openDatepicker = (Date) => {
    if (Date === "StartDate") {
      this._calendarstart.setOpen(true);
    }
    else {
      this._calendarend.setOpen(true);
    }
  }

  handleCheckbox = (e) => {
    var optVal = this.state.vehiclesDataArr;

    if (optVal.length < 5) {
      if (e.target.checked === true) {
        optVal.push(e.target.id);
      } else {
        optVal.splice(optVal.indexOf(e.target.id), 1);
      }
    } else {
      if (e.target.checked === true) {
        e.target.checked = false;
      } else {
        optVal.splice(optVal.indexOf(e.target.id), 1);
      }
    }
    this.setState({
      vehiclesDataArr: optVal
    })
  }

  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  toArrayBuffer(buf) {
    let ab = new ArrayBuffer(buf.length);
    let view = new Uint8Array(ab);
    for (let i = 0; i < buf.length; ++i) {
      view[i] = buf[i];
    }
    return ab;
  }

  getExcelValue = (e) => {
    this.setState({
      regNumbersWithNoRecords: []
    })
    let vehiclesArray = []
    if (this.state.startDate === null) {
      this.openModel("Please select start date.", 'e')
    }
    else if (this.state.endDate === null) {
      this.openModel("Please select end date.", 'e')
    }
    else if (new Date(this.state.startDate) > new Date(this.state.endDate)) {
      this.openModel("End Date should be equal or greater than Start Date", 'e')
    }
    else if (this.state.vitalarray.length === 0) {
      this.openModel("Please select atleast (1) Vital.", 'e')
    }
    else if (this.state.vehiclesDataArr.length === 0) {
      this.openModel("Please select atleast (1) Vehicle.", 'e')
    }
    else {
      this.setState({
        loader: true
      })
      for (var i = 0; i < this.state.vehiclesDataArr.length; i++) {
        for (var j = 0; j < this.state.loadedVins.options.length; j++) {
          if (this.state.loadedVins.options[j].regnNumber === this.state.vehiclesDataArr[i]) {
            if (this.state.emisionControl === 1) {
              vehiclesArray.push({
                obuId: this.state.loadedVins.options[j].obuId,
                vin: this.state.loadedVins.options[j].vinNumber,
                model: this.state.loadedVins.options[j].vehicleType,
                regnnum: this.state.loadedVins.options[j].regnNumber,
                fuelTankCap: this.state.loadedVins.options[j].fuelTankCap,
                isBs6: this.state.loadedVins.options[j].isBS6,
                adBlueTankCap: this.state.loadedVins.options[j].adBlueTankCap
              })
            } else {
              vehiclesArray.push({
                obuId: this.state.loadedVins.options[j].obuId,
                vin: this.state.loadedVins.options[j].vinNumber,
                model: this.state.loadedVins.options[j].vehicleType,
                fuelTankCap: this.state.loadedVins.options[j].fuelTankCap,
                isBs6: this.state.loadedVins.options[j].isBS6,
                regnnum: this.state.loadedVins.options[j].regnNumber
              })
            }
          }
        }
      }

      let newObj = {};
      var defaultStartDate = new Date(this.state.startDate);
      var defaultEndDate = new Date(this.state.endDate)

      newObj.fromDate = defaultStartDate.getFullYear() + "-" + (defaultStartDate.getMonth() + 1) + "-" + defaultStartDate.getDate();
      newObj.toDate = defaultEndDate.getFullYear() + "-" + (defaultEndDate.getMonth() + 1) + "-" + defaultEndDate.getDate();
      newObj.vehInfo = vehiclesArray;
      newObj.vitals = this.state.vitalarray;
      newObj.isBs6 = this.state.emisionControl;

      let vehtoken = this.props.uD.token;
      let vitalarray = this.state.vitalarray;
      // console.log('vitalarray', vitalarray)

      const datas = postStreamData(apilist.downloadRawdata, newObj, vehtoken);
      datas.then(async (excelData) => {
        try {
          let arrayBuffer = await excelData.arrayBuffer();
          this.setState({
            loader: false
          })

          const { zip, entries } = await unzip(new Uint8Array(arrayBuffer));
          let keys = Object.keys(entries);
          for (let i = 0; i < keys.length; i++) {
            let array_buffer = await entries[keys[i]].arrayBuffer();
            var blob = new Blob([array_buffer], { type: 'application/octet-stream' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            var fileName = keys[i];
            link.download = fileName;
            link.click();
          }
        }
        catch (e) {
          this.setState({
            loader: false
          })
          alert("No Data found")
        }
      })
    }
  }

  componentDidMount() {
    this.vitalselect();
    this.loadSelectVehicle();
  }

  resetAll = () => {
    for (let index = 0; index < this.state.vitalkeys.length; index++) {
      document.getElementsByClassName("custom-vital")[index].checked = false
    }
    for (let vehindex = 0; vehindex < this.state.vehoptValShowcase.length; vehindex++) {
      document.getElementsByClassName("custom-control-vehicles")[vehindex].checked = false
    }

    this.setState({
      vitalarray: [],
      vehiclesDataArr: [],
      regNumbersWithNoRecords: [],
      startDate: new Date(),
      endDate: new Date(),
      startMindate: subDays(new Date(), 90),
      startMaxdate: new Date(),
      endMindate: subDays(new Date(), 90),
      endMaxdate: new Date(),
    })
  }

  vitalselect = () => {
    let vitals = [
      {
        "name": "Air Pressure",
        "column_name": "AIR_PRESSURE"
      },
      {
        "name": "Altitude",
        "column_name": "ALTITUDE"
      },
      {
        "name": "Coolant Temperature",
        "column_name": "ENG_COOLANT_TEMP"
      },
      {
        "name": "Oil Pressure",
        "column_name": "OIL_PRESSURE"
      },
      {
        "name": "Battery Voltage",
        "column_name": "VEHICAL_BATTERY_POTENTIAL"
      },
      {
        "name": "Odometer",
        "column_name": "ODOMETER"
      },
      {
        "name": "Vehicle Speed",
        "column_name": "GPS_SPEED"
      },
      {
        "name": "Engine Speed",
        "column_name": "ENGINE_SPEED"
      },
      {
        "name": "Fuel / Gas Consumption",
        "column_name": "FUEL_CONSUM"
      },
      {
        "name": "Fuel / Gas Level",
        "column_name": "FUEL_LEVEL"
      }
    ]

    this.setState({
      vitalkeys: vitals
    })
  }

  searchVehicles = (e) => {
    var letters = alphaNumeric;
    if (e.target.value.match(letters) || e.target.value == "") {
      this.setState({ searchtext: e.target.value.trim() }, this.filterVehicles)
    }
  }


  filterVehicles = () => {
    let filterArray = [];
    for (let i = 0; i < this.state.vehoptVal.length; i++) {
      var keyArray = this.state.vehoptVal[i].key.split(":");
      filterArray.push({ "regnNumber": keyArray[0], "isBS6": keyArray[1] === "2" ? "1" : keyArray[1] })
    }
    let searchDatas = [];
    var updatedList = filterArray;
    let searchtext = this.state.searchtext.trim();
    let emisionControl = this.state.emisionControl;
    updatedList = updatedList.filter(function (item) {
      return item.regnNumber.toLowerCase().search(searchtext.toLowerCase()) !== -1 &&
        parseInt(item.isBS6) === emisionControl;
    });

    for (let i = 0; i < this.state.vehoptVal.length; i++) {
      for (let j = 0; j < updatedList.length; j++) {
        var keyArr = this.state.vehoptVal[i].key.split(":");
        if (keyArr[0] === updatedList[j].regnNumber) {
          if (this.state.vehiclesDataArr.length !== 0 && this.state.vehiclesDataArr.includes(this.state.loadedVins.options[i].regnNumber) === true) {
            searchDatas.unshift(
              <div className="trend-vital-row" key={this.state.loadedVins.options[i].regnNumber + ":" + this.state.loadedVins.options[i].isBS6}
              >
                <div className="custom-control custom-checkbox mb-3">
                  <input
                    type="checkbox"
                    onChange={this.handleCheckbox}
                    className="custom-control-input custom-control-vehicles"
                    id={this.state.loadedVins.options[i].regnNumber}
                    name="checkbox"
                    defaultChecked
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={this.state.loadedVins.options[i].regnNumber}
                  >{this.state.loadedVins.options[i].regnNumber}
                  </label>
                </div>
              </div>);
          } else {
            searchDatas.push(
              <div className="trend-vital-row" key={this.state.loadedVins.options[i].regnNumber + ":" + this.state.loadedVins.options[i].isBS6}>
                <div className="custom-control custom-checkbox mb-3">
                  <input
                    type="checkbox"
                    onChange={this.handleCheckbox}
                    className="custom-control-input custom-control-vehicles"
                    id={this.state.loadedVins.options[i].regnNumber}
                    name="checkbox"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={this.state.loadedVins.options[i].regnNumber}
                  >{this.state.loadedVins.options[i].regnNumber}
                  </label>
                </div>
              </div>);
          }
        }
      }
    }

    this.setState({
      vehoptValShowcase: searchDatas,
      initialDtls: searchDatas.length > 0 ? true : false,
    })
  }

  loadSelectVehicle = () => {
    this.setState({
      loader: true
    })
    let newObj = {};
    newObj.platform = 'w';
    newObj.userId = this.props.uD.userid;
    newObj.userRole = this.props.uD.userrole;
    newObj.obuIdList = [];
    let vehtoken = this.props.uD.token;
    const datas = postData(apilist.allvehicledata, newObj, vehtoken);

    datas.then((dataVals) => {
      let optVal = [];

      if (dataVals.statuscode === 200) {
        dataVals.data.map((vehicleData) => {
          optVal.push(
            <div className="trend-vital-row" key={vehicleData.regnNumber + ":" + vehicleData.isBS6}>
              <input
                type="hidden"
                value={vehicleData.isBS6}
                className="bs6Value"
              />
              <div className="custom-control custom-checkbox mb-3">
                <input
                  type="checkbox"
                  onChange={this.handleCheckbox}
                  className="custom-control-input custom-control-vehicles"
                  id={vehicleData.regnNumber}
                  name="checkbox"
                />
                <label
                  className="custom-control-label"
                  htmlFor={vehicleData.regnNumber}
                >{vehicleData.regnNumber}
                </label>
              </div>
            </div>
          )
          return vehicleData;
        });

        let fuelDefCapacity = []
        for (let j = 0; j < dataVals.data.length; j++) {
          if (dataVals.data[j].isBS6 === 1) {
            let capacity = {}
            capacity.regn = dataVals.data[j].regnNumber
            capacity.vin = dataVals.data[j].vinNumber;
            capacity.defcap = Number(dataVals.data[j].adBlueTankCap);
            capacity.fueltankcap = Number(dataVals.data[j].fuelTankCap);
            fuelDefCapacity.push(capacity)
          }
        }

        this.setState({
          loadedVins: {
            options: dataVals.data,
            getOptionLabel: option => option.title
          },
          vehoptVal: optVal,
          vehoptValShowcase: optVal,
          fuelDefCapacity: fuelDefCapacity,
          loader: false
        }, this.filterVehicles);

      } else {
        this.openModel('Failed to load Change Vehicle Data..', 'e');
      }
    });
  }

  openModel = (message, msgType) => {
    this.setState({
      showAlertBox: true,
      showMessage: message,
      messageType: msgType
    });
  };

  hideModal = () => {
    this.setState({
      showAlertBox: false
    })
  };

  handleEmisionControl = (emisionControl) => {
    if (emisionControl === 1 && this.state.vitalkeys.length === 10) {
      this.state.vitalkeys.push(
        {
          "name": "DEF Level",
          "column_name": "CATALYST_TANK_LEVEL"
        },
        {
          "name": "DEF Consumption",
          "column_name": "AFT1_TOTAL_DIESEL_EXHAUST_FLUID_USED"
        },
        {
          "name": "Exhaust Temperature",
          "column_name": "AFT1_EXHAUST_TEMP_1"
        },
        {
          "name": "DEF Tank Temperature",
          "column_name": "CATALYST_TANK_TEMP"
        },
        {
          "name": "Engine Hours",
          "column_name": "ENGINE_HOURS"
        },
        {
          "name": "Gear Number",
          "column_name": "CURRENT_GEAR"
        }
      )
    }
    else if (emisionControl === 0) {
      let vitalarray = this.state.vitalarray;
      if (vitalarray.indexOf('CATALYST_TANK_LEVEL') !== -1) {
        this.state.vitalarray.splice(vitalarray.indexOf('CATALYST_TANK_LEVEL'), 1);
      }
      if (vitalarray.indexOf('AFT1_TOTAL_DIESEL_EXHAUST_FLUID_USED') !== -1) {
        this.state.vitalarray.splice(vitalarray.indexOf('AFT1_TOTAL_DIESEL_EXHAUST_FLUID_USED'), 1);
      }
      if (vitalarray.indexOf('AFT1_EXHAUST_TEMP_1') !== -1) {
        this.state.vitalarray.splice(vitalarray.indexOf('AFT1_EXHAUST_TEMP_1'), 1);
      }
      if (vitalarray.indexOf('CATALYST_TANK_TEMP') !== -1) {
        this.state.vitalarray.splice(vitalarray.indexOf('CATALYST_TANK_TEMP'), 1);
      }
      if (vitalarray.indexOf('ENGINE_HOURS') !== -1) {
        this.state.vitalarray.splice(vitalarray.indexOf('ENGINE_HOURS'), 1);
      }
      this.state.vitalkeys.splice(10, 6)
    }

    for (let vehindex = 0; vehindex < this.state.vehoptValShowcase.length; vehindex++) {
      document.getElementsByClassName("custom-control-vehicles")[vehindex].checked = false
    }

    this.setState({
      emisionControl: emisionControl,
      vehiclesDataArr: []
    }, this.filterVehicles)
  }

  render() {

    const { showMessage, showAlertBox, messageType, startMindate, startMaxdate, endMindate, endMaxdate, vitalkeys, emisionControl, initialDtls, regNumbersWithNoRecords } = this.state;

    let norecords = regNumbersWithNoRecords.map((value, index) => {
      return (<li key={index}>{value}</li>);
    });

    let items = vitalkeys.map((item) =>
      <div className="trend-vital-rows " key={item.name}>
        <div className="custom-control custom-checkbox mb-3">
          <input
            onChange={this.handleCheckboxvit.bind(this, item.column_name)}
            type="checkbox"
            className="custom-control-input custom-vital"
            id={item.column_name}
            name={item.column_name}
          />
          <label className="custom-control-label" htmlFor={item.column_name}>{item.name}</label>
        </div>
      </div>
    );

    const CustomDatePicker = ({ value, onClick, inputname }) => (
      <div className="vas-dateBlock">
        <p className="vas-inputContainer" >
          <input id="dates" type="text" style={{ cursor: "pointer" }}
            value={value} onClick={onClick} required name={inputname} />
          <i class="fa fa-calendar-check-o rawDataCalender" onClick={onClick}></i>
        </p>
      </div>
    );

    return (
      <div>
        {this.state.loader ? <div className="Pageloading"> <img className="loadimg" src="/images/loader_1.gif" alt="iAlert" /></div> : ''}
        <div className="borderline">
          <div className="container-wrapper">
            <Link to="/home">
              <span className="fa fa-arrow-left" style={{ marginRight: "15px", color: "white", cursor: "pointer" }}></span>
            </Link>
            Raw Data Download
          </div>
        </div>
        <div className="container-wrapper">
          <div className="row ">
            <div className="col-md-3 borderright">
              <div className="row">
                <div className="col-md-3 vitalheader">Vitals <br /></div>
                <div className="col-md-8 mt-4">
                  <input
                    type="button"
                    className={classnames("btn-tab", "btn-left", emisionControl === 0 ? "btn-active" : null)} value="BS 4"
                    onClick={this.handleEmisionControl.bind(this, 0)}
                  />
                  <input
                    type="button"
                    className={classnames("btn-tab", "btn-right", emisionControl === 1 ? "btn-active" : null)}
                    value="BS 6"
                    onClick={this.handleEmisionControl.bind(this, 1)}
                  />
                </div>
              </div>
              <div style={{ paddingTop: "14px" }}>{items}</div>
            </div>
            <div className="col-md-3 borderright">
              <div className="">
                <div className="col-md-12">
                  <label style={{ fontSize: "small", fontWeight: "600" }}>Search Vehicles</label>
                  <input
                    type="text"
                    onChange={this.searchVehicles}
                    value={this.state.searchtext}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3 vitalheader">Vehicles </div>
              </div>
              {
                <>
                  {this.state.vehoptValShowcase.length > 0
                    ? <div className="col-md-12 scrolling">
                      {this.state.vehoptValShowcase}
                    </div>
                    : <div className="errorDiv">
                      <p className="errorMsg">
                        No Data Available
                      </p>
                    </div>
                  }
                </>
              }
            </div>
            <div className="col-md-6">
              <div className="row container-wrapper">
                <div className="col-md-5">
                  <br />
                  <label style={{ marginBottom: "0px", fontSize: "small", fontWeight: "bold" }}>Start date</label>
                  <div className="cal_icon">
                    <DatePicker
                      selected={this.state.startDate}
                      minDate={startMindate}
                      maxDate={startMaxdate}
                      ref={(c) => this._calendarstart = c}
                      onChange={this.handleChangeStart}
                      dateFormat="dd-MM-yyyy"
                    />
                    <span
                      onClick={() => this.openDatepicker('StartDate')}
                      className="fa fa-calendar-check-o"
                    ></span>
                    <br /> <br />
                  </div>
                </div>
                <div className="col-md-5">
                  <br />
                  <label style={{ marginBottom: "0px", fontSize: "small", fontWeight: "bold" }}>End date</label>
                  <div className="cal_icon">
                    <DatePicker
                      selected={this.state.endDate}
                      minDate={endMindate}
                      maxDate={endMaxdate}
                      ref={(c) => this._calendarend = c}
                      onChange={this.handleChangeEnd}
                      dateFormat="dd-MM-yyyy"
                    />
                    <span
                      onClick={() => this.openDatepicker('endDate')}
                      className="fa fa-calendar-check-o"
                    ></span>
                    <br />        <br />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-1"></div>
                <div className="col-md-11">
                  <button
                    className={this.state.submitbtn === "btndisable" ? "raw-common-button ffub83 buttonBlockArea buttonDisable" : "raw-common-button ffub83 buttonBlockArea"}
                    onClick={this.getExcelValue}
                  >Download Excel &#x00A0; <i className="fa fa-download" aria-hidden="true"></i>
                  </button>
                  <button
                    className="raw-common-button ffub83 buttonBlockArea"
                    onClick={this.resetAll} >Reset &#x00A0; <i className="fa fa-repeat" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
              <div className="row mt30">
                <div className="col-md-1"></div>
                <div className="col-md-11">
                  ({this.state.vehiclesDataArr.length}) Number of excel going to download.
                </div>
              </div>
              {
                regNumbersWithNoRecords.length !== 0 ?
                  <div className="row mt30">
                    <div className="col-md-1"></div>
                    <div className="col-md-8">
                      <div className="error-box">
                        <p>Following Registered Vehicle(s) has no records</p>
                        <ul class="styleul">
                          {norecords}
                        </ul>
                      </div>
                    </div>
                  </div> : null
              }
            </div>
          </div>
          <AlertBox
            show={showAlertBox}
            showMessage={showMessage}
            click={this.hideModal}
            messageType={messageType}
          />
        </div>
      </div>)
  }
}

const mapStateToprops = state => {
  return {
    ctr: state.vehicledata.results,
    uD: state.userdata.id
  };
}
export default connect(mapStateToprops, null)(rawdata)