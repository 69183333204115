import React from 'react'

const Vitals = ({ vitals, handleCheckbox }) => {
  return (
    <div className="col-md-2 borderright">
        <div className="row">
            <div className="vital_hd">Vitals </div>
        </div>
        <div>{vitals.length > 0 && vitals.map((item, index) =>
            <div className="row trend-vital-space " key={index}>
                <div className="col-md-2 ">
                    <label className="checkboxes">
                        <input
                            id={item['key']}
                            className="checkboxcheck"
                            type="checkbox"
                            value={item['key']}
                            onChange={(e) => handleCheckbox(e, index)}
                            checked={item['value']}
                        />
                        <span class="checkmark-trend "></span>
                    </label>
                </div>
                <div className="col-md-10 vital-text"> {item['key']} </div>
            </div>)}</div>
    </div>
  )
}

export default Vitals