const userids ={
   id:"0"
}

const reducer = (state = userids,action) => {
    if(action.type === 'INCREMENT'){
        return {
            id:action.value
        };
    }
    return state;
};

export default reducer;