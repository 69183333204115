import { getGASVehicleUnit } from '../../Util/VehicleDashUtils'
 
export const getFuelLevelAndImageBS6 = (tankpercentbs6) => {
    let fuelLevelBs6, fuelImageBs6;
  
    if (tankpercentbs6 <= 9) {
      fuelLevelBs6 = 'fuelfill_bs69';
      fuelImageBs6 = 'fuelfillRed_bs6';
    } else if (tankpercentbs6 <= 19) {
      fuelLevelBs6 = 'fuelfill_bs619';
      fuelImageBs6 = 'fuelfillimg_bs6';
    } else if (tankpercentbs6 <= 29) {
      fuelLevelBs6 = 'fuelfill_bs620';
      fuelImageBs6 = 'fuelfillimg_bs6';
    } else if (tankpercentbs6 <= 39) {
      fuelLevelBs6 = 'fuelfill_bs630';
      fuelImageBs6 = 'fuelfillimg_bs6';
    } else if (tankpercentbs6 <= 49) {
      fuelLevelBs6 = 'fuelfill_bs640';
      fuelImageBs6 = 'fuelfillimg_bs6';
    } else if (tankpercentbs6 <= 59) {
      fuelLevelBs6 = 'fuelfill_bs650';
      fuelImageBs6 = 'fuelfillimg_bs6';
    } else if (tankpercentbs6 <= 69) {
      fuelLevelBs6 = 'fuelfill_bs660';
      fuelImageBs6 = 'fuelfillimg_bs6';
    } else if (tankpercentbs6 <= 79) {
      fuelLevelBs6 = 'fuelfill_bs670';
      fuelImageBs6 = 'fuelfillimg_bs6';
    } else if (tankpercentbs6 <= 89) {
      fuelLevelBs6 = 'fuelfill_bs680';
      fuelImageBs6 = 'fuelfillimg_bs6';
    } else if (tankpercentbs6 <= 98) {
      fuelLevelBs6 = 'fuelfill_bs690';
      fuelImageBs6 = 'fuelfillimg_bs6';
    } else if (tankpercentbs6 >= 99) {
      fuelLevelBs6 = 'fuelfill_bs695';
      fuelImageBs6 = 'fuelfillimg_bs6';
    } else {
      fuelLevelBs6 = 'fuelfill_bs690';
      fuelImageBs6 = 'fuelfillimg_bs6';
    }
  
    return { fuelLevelBs6, fuelImageBs6 };
  }

  export const getFuelLevelAndImageAddBlue1 = (tankpercentaddblue) => {
    let fuelevel_addblue, fuelimg_addblue;
  
    if (tankpercentaddblue <= 9) {
      fuelevel_addblue = 'fuelfill_addb9';
      fuelimg_addblue = 'fuelfillRed_addb';
    } else if (tankpercentaddblue <= 98) {
      fuelevel_addblue = `fuelfill_addb${Math.floor(tankpercentaddblue / 10) * 10}`;
      fuelimg_addblue = 'fuelfillimg_addb';
    } else if (tankpercentaddblue >= 99) {
      fuelevel_addblue = 'fuelfill_addb95';
      fuelimg_addblue = 'fuelfillimg_addb';
    } else {
      fuelevel_addblue = 'fuelfill_addb90';
      fuelimg_addblue = 'fuelfillimg_addb';
    }
  
    return { fuelevel_addblue, fuelimg_addblue };
  }

  export const getFuelLevelAndImageAddBlue = (tankPercentAddBlue) => {
    let fuelLevelClass, fuelImageClass;
  
    if (tankPercentAddBlue <= 9) {
      fuelLevelClass = 'fuelfill_addb9';
      fuelImageClass = 'fuelfillRed_addb';
    } else if (tankPercentAddBlue <= 98) {
      const roundedPercentage = Math.floor(tankPercentAddBlue / 10) * 10;
      fuelLevelClass = `fuelfill_addb${roundedPercentage}`;
      fuelImageClass = 'fuelfillimg_addb';
    } else {
      fuelLevelClass = 'fuelfill_addb95';
      fuelImageClass = 'fuelfillimg_addb';
    }
  
    return { fuelLevelClass, fuelImageClass };
  }

  export const getFuelLevelValue = (fuelLevel) => {
    return (!isNaN(fuelLevel) && fuelLevel > 0) ? parseFloat(fuelLevel).toFixed(1) : '0';
  }
  export const getDistanceValue = (distance) => {
    return !isNaN(distance) ? parseFloat(distance).toFixed(1) : '0';
  }
  export const formatValue = (data) => {
    return !isNaN(data) ? parseFloat(data).toFixed(1) : 0;
  }
  export const getFuelLevelUnit = (isBs6, vehicleType) => {
    const fuelUnitMap = {
      2: getGASVehicleUnit(vehicleType).trim(),
      4: "%",
      default: "ltr"
    };
    return fuelUnitMap[isBs6] || fuelUnitMap.default;
  }
  export const calculateAdBlueLevels = (data) => {
    let tankpercentaddblue, tantpercentadbluelevel;

    if (data.vehicleType === 'EDC_LCV' || data.vehicleType === 'EEA_LCV') {
        tankpercentaddblue = 80;
        tantpercentadbluelevel = Math.round((data.adBlueTankCap / 100) * 80);
    } else {
        tankpercentaddblue = Math.round(data.currentInfo.catalyst_tank_level);
        tantpercentadbluelevel = Math.round(data.currentInfo.catalyst_tank_level / 100 * data.adBlueTankCap);
    }

    if (tankpercentaddblue > 100) {
        tankpercentaddblue = 100;
        tantpercentadbluelevel = data.adBlueTankCap;
    }

    return { tankpercentaddblue, tantpercentadbluelevel };
};

  export const getExhaustTemp = (data, currentInfo) => {
    if (data.isBS6 === 2 || data.isBS6 === 0) {
      return 'N/A';
    } else {
      if (['RUNNING', 'IDLING'].includes(currentInfo.status)) {
        return currentInfo.aft1_exhaust_temp_1 > 0
          ? Math.round(currentInfo.aft1_exhaust_temp_1)
          : 0;
      } else {
        return '-';
      }
    }
  };
  
export const getStatusColors = (status) => {
    let statusColor = 'custom-wt';
    let statusTextColor = 'normal-bl';
  
    switch (status) {
      case 'RUNNING':
        statusColor = 'custom-gn';
        break;
      case 'STOPPED':
        statusColor = 'custom-rd';
        break;
      case 'IDLING':
        statusColor = 'custom-idl';
        break;
      default:
        break;
    }
  
    if (['RUNNING', 'STOPPED', 'IDLING'].includes(status)) {
      statusTextColor = 'normal-wt';
    }
  
    return { statusColor, statusTextColor };
  };
  
  export const calculateTankPercent = (data, currentInfo) => {
    if (data.isBS6 === 2) {
      return Math.round(currentInfo.cng_level_percentage);
    } else {
      return Math.round((currentInfo.fuelLevel / data.fuelTankCap) * 100);
    }
  }
  export const calculateTankPercentBS6 = (data, currentInfo) => {
    if (data.vehicleType === 'EDC_LCV' || data.vehicleType === 'EEA_LCV') {
      return 60;
    } else {
      return Math.round((currentInfo.fuelLevel / data.fuelTankCap) * 100);
    }
  }
  export const getGearNumber = (gear) => {
    if (gear === '<null>' || gear === null || parseInt(gear) <= 0 || parseInt(gear) >= 15) {
      return 'N';
    } else if (gear === 'N' || gear === 'C' || gear === 'R') {
      return gear;
    } else {
      return parseInt(gear);
    }
  };
  export const formatPercentage = (percent) => {
    return percent > 100 ? 100 : percent < 0 ? 0 : percent;
  };

  export const formatFuelLevel = (level) => {
    return level !== 'N/A' ? `${level} ltr` : level;
  };

  export const getBatteryLevel = (batteryPercent, threshold) => {
    const levels = [
      { percent: 9, level: 'batteryfill9' },
      { percent: 19, level: 'batteryfill19' },
      { percent: 29, level: 'batteryfill20' },
      { percent: 39, level: 'batteryfill30' },
      { percent: 49, level: 'batteryfill40' },
      { percent: 59, level: 'batteryfill50' },
      { percent: 69, level: 'batteryfill60' },
      { percent: 79, level: 'batteryfill70' },
      { percent: 89, level: 'batteryfill80' },
      { percent: 100, level: 'batteryfill90' },
    ]

    const batteryLevel =
      levels.find((level) => batteryPercent <= level.percent) ||
      levels[levels.length - 1]

    const batlevel = batteryLevel.level
    const batimg = batlevel.startsWith('batteryfill')
      ? 'batteryfillred'
      : 'batteryfill'

    return { batlevel, batimg }
  }
  export const getFuelLevelAndImage = (tankPercent) => {
    let fuelLevel, fuelImage;
    if (tankPercent <= 9) {
      fuelLevel = "fuelfill9";
      fuelImage = "fuelfillRed";
    } else if (tankPercent <= 19) {
      fuelLevel = "fuelfill19";
      fuelImage = "fuelfillimg";
    }
    else if (tankPercent <= 29) {
      fuelLevel = "fuelfill20";
      fuelImage = "fuelfillimg";
    }
    else if (tankPercent <= 39) {
      fuelLevel = "fuelfill30";
      fuelImage = "fuelfillimg";
    }
    else if (tankPercent <= 49) {
      fuelLevel = "fuelfill40";
      fuelImage = "fuelfillimg";
    }
    else if (tankPercent <= 59) {
      fuelLevel = "fuelfill50";
      fuelImage = "fuelfillimg";
    }
    else if (tankPercent <= 69) {
      fuelLevel = "fuelfill60";
      fuelImage = "fuelfillimg";
    }
    else if (tankPercent <= 79) {
      fuelLevel = "fuelfill70";
      fuelImage = "fuelfillimg";
    }
    else if (tankPercent <= 89) {
      fuelLevel = "fuelfill80";
      fuelImage = "fuelfillimg";
    }
    else if (tankPercent <= 98) {
      fuelLevel = "fuelfill90";
      fuelImage = "fuelfillimg";
    }
    else if (tankPercent >= 99) {
      fuelLevel = "fuelfill95";
      fuelImage = "fuelfillimg";
    }
    else {
      fuelLevel = "fuelfill90";
      fuelImage = "fuelfillimg";
    }
    return { fuelLevel, fuelImage };
  }

  export const getAlertCSS = (criticalInfo) => {
    switch (criticalInfo) {
      case 'WARNING':
        return 'header-three-al-wr';
      case 'CRITICAL':
      case 'SERVICE DUE':
        return 'header-three-al-cr';
      default:
        return '';
    }
  };