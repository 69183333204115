import React, { useState, useCallback, forwardRef } from "react";
import AlertBox from '../../Components/AlertBox/alertBox'

const withAlertBox = (WrappedComponent) => {
  const WithAlertBox = forwardRef((props, ref) => {
    const [showMessage, setShowMessage] = useState("");
    const [showAlertBox, setShowAlertBox] = useState(false);
    const [messageType, setMessageType] = useState("");
    const [remainderMsg, setRemainderMsg] = useState("");

    const openModal = useCallback((message, msgType, cafError) => {
      setShowMessage(message);
      setMessageType(msgType);
      setShowAlertBox(true);
      setRemainderMsg(cafError)
    }, []);

    const hideModal = useCallback(() => {
      setShowMessage("");
      setShowAlertBox(false);
      if (props.onHideModal) {
        props.onHideModal();
      }
    }, [props]);

    return (
      <>
        {showAlertBox && (
          <AlertBox
            show={showAlertBox}
            showMessage={showMessage}
            click={hideModal}
            messageType={messageType}
            errorRemainder={remainderMsg}
          />
        )}
        <WrappedComponent {...props} openModal={openModal} ref={ref} />
      </>
    );
  });

  WithAlertBox.displayName = `withAlertBox(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;

  return WithAlertBox;
};

export default withAlertBox;
