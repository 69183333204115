import React from "react";
import './VehicleSearchResult.scss'
function SearchResult({ vehicle, onCheckBoxChange }) {
    const handleCheckboxChange = (event) => {
        const { value } = event.target;
        onCheckBoxChange(value)
      };
  return (
     <div className="list-item" key={vehicle.vinNumber}>
     <div className="check-css">
       <input
         type="checkbox"
         value={vehicle.vinNumber}
         checked={vehicle.checked}
         onChange={(e) =>
           handleCheckboxChange(e)
         }
       />
       <div className="search-item">
       <span className="text-css">{vehicle.regnNumber}</span>
       {/* <span className="text-css">{vehicle.vinNumber}</span> */}
     </div>
     </div>
     
</div>
  );
}

export default SearchResult;