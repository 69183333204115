export const sortByAlphabatics = (data, colName, sortDirection) => {
    const sortedData = [...data];
   
    sortedData.sort((a, b) => {
      const valueA = a[colName] ? a[colName].toLowerCase() : '';
      const valueB = b[colName] ? b[colName].toLowerCase() : '';
   
      if (sortDirection === 'asc') {
        return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
      } else {
        return valueB < valueA ? -1 : valueB > valueA ? 1 : 0;
      }
    });
   
    return sortedData;
};

export const sortByDateAndNumeric = (data, colName, sortDirection) => {
    const sortedData = [...data]; 
   
    sortedData.sort((a, b) => {
      const valueA = a[colName];
      const valueB = b[colName];
   
      if (sortDirection === 'asc') {
        return compareValues(valueA, valueB);
      } else {
        return compareValues(valueB, valueA);
      }
    });
   
    return sortedData;
  };

const compareValues = (valueA, valueB) => {
    if (typeof valueA === 'number' && typeof valueB === 'number') {
      return valueA - valueB;
    } else if (isDate(valueA) && isDate(valueB)) {
      return new Date(valueA) - new Date(valueB);
    } else {
      const stringA = String(valueA).toLowerCase();
      const stringB = String(valueB).toLowerCase();
   
      return stringA.localeCompare(stringB);
    }
};
   
const isDate = (value) => {
    return value instanceof Date && !isNaN(value);
};

export function safeLowerCase(str) {
  if (Array.isArray(str)) {
    return str.map(item => (typeof item === 'string' ? item.toLowerCase() : ''));
  } else if (typeof str === 'string') {
    return str.toLowerCase();
  } else {
    return '';
  }
}

export function createSortFunction(property, order) {
  return (a, b) => {
      const valueA = safeLowerCase(a[property]);
      const valueB = safeLowerCase(b[property]);

      if (valueA < valueB) return order === 'desc' ? 1 : -1;
      if (valueA > valueB) return order === 'desc' ? -1 : 1;
      return 0;
  };
}