import React, { useState, useEffect } from "react";
import { postData } from "../../Util";
import * as apilist from "../../apiList";
import "./VehicleLinkGeneration.scss";
import classnames from "classnames";
import { connect } from "react-redux";
import ToggleSwitchComponent from "./ToggleSwtichComponent";
import NumberPickerDropdown from "./NumberPickerDropdown";
import VehicleSearchBar from "./VehicleSearchBar";
import VehicleSearchResults from "./VehicleSearchResults";

const VehicleLinkGeneration = (props) => {
  const {
    showModelDialog,
    handleClose,
    vehicleDetails,
    showCopyLinkDialog,
    receiveAllVechicleData,
    isTrace
  } = props;
  const [selectedDurationValue, setSelectedDuration] = useState(1);
  const [isHour, setIsHour] = useState(false);
  const [isSingleVehicle, setIsSingleVehicle] = useState(true);
  const [items, setItems] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    setSelectedDuration(8);
    setIsHour(true);
    setIsSingleVehicle(true);
    setLoading(false);
    return () => {
      setSelectedDuration(8);
      setIsHour(true);
      setIsSingleVehicle(true);
      setLoading(false);
    };
  }, []);
  const fetchVehicleDetails = async () => {
    setLoading(true);
    try {
      let payload = {
        userId: props.ctr.userid,
        userRole: props.ctr.userrole,
        obuIdList: [],
        platform: "w",
      };
      const response = postData(
        apilist.allvehicledata,
        payload,
        props.ctr.token
      );
      const data = await response;
      setLoading((prev) => !prev);
      if (data.data) {
        let vehicles = [...data.data];
        vehicles.forEach(function (itm) {
          itm.checked = false;
        });
        setItems(vehicles);
        setIsSingleVehicle(true);
      }
    } catch (error) {
      setLoading((prev) => !prev);
    }
  };

  useEffect(() => {
    if (isSingleVehicle & isTrace) fetchVehicleDetails();
  }, [isSingleVehicle]);

  useEffect(()=> {
    if(isTrace === false){
      let data =  receiveAllVechicleData;
      if (data !== undefined && data !== undefined && Array.isArray(data)) {
        let vehicles = [...data];
        vehicles.forEach(function (itm) {
          itm.checked = false;
        });
        setItems(vehicles);
      } else {
        console.error("Invalid data structure. Unable to process.");
      }
    }

  },[receiveAllVechicleData])

  const getSelectedValue = (value) => {
    setSelectedDuration(() => value);
  };
  const resetPage = () => {
    if (isHour) {
      setSelectedDuration(8);
    } else {
      setSelectedDuration(1);
    }
    setSelectedVehicles([]);
    items.forEach((vehicleDetails) => (vehicleDetails.checked = false));
    setIsSingleVehicle(true);
    setIsHour(true);
    setSearchQuery('')
  };
  const closeDialog = () => {
    resetPage();
    handleClose();
  };

  const handleCheckBoxChange = (value) => {
    let selectedVehicleIndex = items.findIndex(
      (vehicle) => vehicle.vinNumber === value
    );
    if (selectedVehicleIndex > -1) {
      items[selectedVehicleIndex].checked =
        !items[selectedVehicleIndex].checked;
      setSelectedVehicles(items.filter((x) => x.checked))
    }
  }
  const filteredData = items.filter((item) => {
    let searchText = searchQuery.length >0 ? searchQuery.toLowerCase() : ""
    return (
      item.vinNumber.toLowerCase().includes(searchText) ||
      item.regnNumber.toLowerCase().includes(searchText)
    );
  });

  const handleSave = () => {
    let selectedVins = [vehicleDetails.vinNumber];
    let selectedObuIds = [vehicleDetails.obuId];
    let expireTime = 60;
    if (isHour) {
      expireTime = selectedDurationValue * 60;
    } else {
      expireTime = selectedDurationValue * 60 * 24;
    }
    if (!isSingleVehicle) {
      selectedVins = selectedVehicles.map((item) => item.vinNumber);
      selectedObuIds = selectedVehicles.map((item) => item.obuId);
    }
    let payLoad = {
      expireTime: parseInt(expireTime),
      vinNumber: selectedVins,
      obuId: selectedObuIds,
    };

    if (selectedVins.length > 0 && selectedObuIds.length > 0) {
      const result = postData(
        apilist.generateShareLink,
        payLoad,
        props.ctr.token
      );
      result.then((response) => {
        if (
          response.statuscode === 200 &&
          response.data !== undefined &&
          response.data.share_link !== null
        ) {
          var host = window.location.protocol + "//" + window.location.host;
          let url = response.data.share_link.replace("base-url", host);
          resetPage();
          showCopyLinkDialog(url);
        }
      });
    }
  };
  useEffect(() => {
    setSelectedDuration(isHour ? 8 : 1);
  }, [isHour]);

  const optionChange = (type) => {
    if (type === "vehicle") {
      setIsSingleVehicle((prev) => !prev);
    } else if (type === "duration") {
      setIsHour((prev) => !prev);
    }
  };
  return (
    <div
      className={classnames(
        "share_popup createVehicleShareLikeModal",
        showModelDialog ? "active-modal" : "close-modal"
      )}
      id="create-share-link-container-id"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="createVehicleShareLikeModal"
      aria-hidden="true"
    >
      <div className={isSingleVehicle ? 'share-modal-content single-vehcile' : 'share-modal-content multiple-vehcile'}>
        <div className="share-modal-header">
          <button
            type="button"
            className="share-close"
            onClick={closeDialog}
            aria-label="Close"
          >
            <span className="fs_20" title="Close" aria-hidden="true">
              &times;
            </span>
          </button>
          <h5 className="share-modal-title">SHARE VEHICLE LOCATION</h5>
        </div>
        <div className="share-modal-body">
          <div className={isSingleVehicle ? 'flux-column' : 'flux-row'}>
            <div className="flex-parent-element">
              <div className="label-first">
                <span>Vehicle</span>
              </div>
              <div className="input-first">
                <ToggleSwitchComponent
                  type="vehicle"
                  optionName="vehicleToggle"
                  isOptionChecked={isSingleVehicle}
                  key="vehicleOption"
                  leftLabelId="single"
                  rightLabelId="multi"
                  leftLabel="Single"
                  rightLabel="Multiple"
                  optionChange={optionChange}
                />
              </div>
            </div>
            <div className="flex-parent-element">
              <div className={!isSingleVehicle ? 'label-second':''}>Duration</div>
              <div className="input-second">
                <div className="duration-check">
                  <NumberPickerDropdown
                    getSelectedValue={getSelectedValue}
                    isHour={isHour}
                    initialValue={selectedDurationValue}
                  />
                  <ToggleSwitchComponent
                    type="duration"
                    optionName="durationToggle"
                    key="durationOption"
                    isOptionChecked={isHour}
                    leftLabelId="hrs"
                    rightLabelId=" day"
                    leftLabel="Hr (s)"
                    rightLabel="Days (s)"
                    optionChange={optionChange}
                  />
                </div>
              </div>
            </div>
            {!isSingleVehicle && <div className="flex-parent-element">
              <VehicleSearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
            </div>}
          </div>
          {!isSingleVehicle && (<VehicleSearchResults searchResults={filteredData} onCheckBoxChange={handleCheckBoxChange}/>)}
        </div>
        {!isSingleVehicle && filteredData.length > 0 ?
          <div className="share-modal-footer">
            <button
              type="button"
              className="btn save_password_btn"
              onClick={handleSave}
            >
              Share
            </button>
          </div> : 
          <>
          {isSingleVehicle && 
            <div className="share-modal-footer">
              <button
                type="button"
                className="btn save_password_btn"
                onClick={handleSave}
              >
                Share
              </button>
            </div>
          } 
          </>
        }
      </div>
    </div>
  );
};

const mapStateToProbs = (state) => {
  return {
    ctr: state.userdata.id,
  };
};

export default connect(mapStateToProbs)(React.memo(VehicleLinkGeneration));
