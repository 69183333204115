import React from "react";

const SelectInput = ({ label, id, options, value, onChange }) => {
  return (
    <div>
      <div>
        <div className="selectFeedback feedbackSelectDiv">
          <select
            id={id}
            name={id}
            onChange={onChange}
            value={value}
            className="form-control renewal"
            aria-placeholder={label}
          >
            <option value="" disabled>
              Select Time
            </option>
            {options.map((option) => (
              <option value={option} key={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default SelectInput;
