const initState = {
    ids: "",
    results: {}
}

const vehicledata = (state = initState, action) => {
    switch (action.type) {
        case 'VehicleDatas':
            var av = action.value,
                sR = state.results;
            var keyLength = Object.keys(av);
            for (let i = 0; i < keyLength.length; i++) {
                sR[keyLength[i]] = av[keyLength[i]];
            }
            return {
                ...state,
                results: state.results
            };
        default:
            return state
    }
};

export default vehicledata;