import React from "react";
import { GmapsDispatchThunk } from '../../store/actions/GmapsActions'
import { compose, withProps, lifecycle, withStateHandlers } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  OverlayView,
  Circle, Polygon
} from "react-google-maps";
import { MarkerClusterer } from "react-google-maps/lib/components/addons/MarkerClusterer";
import { postData } from "../../Util";
import "../../Components/BottomRibbon/ribbonStyle.scss";
import * as apilist from "../../apiList";
import { connect } from "react-redux";
import "./index.scss";
import moment from 'moment';
import * as alasql from 'alasql';
import XLSX from 'xlsx';
import NewListView from '../../Components/NewListView/NewListView';
import MenuItem from '@material-ui/core/MenuItem';
import { sortingColumsClassicView } from '../../Util/commonConverter';
import VehicleLinkGeneration from '../../Pages/Tracking/VehicleLinkGeneration'
import '../../Pages/Tracking/VehicleLinkGeneration.scss';
import VehicleCopyLink from '../../Pages/Tracking/VehicleCopyLink'
import AlertBox from '../../Components/AlertBox/alertBox.js';
import { alert_types } from './alertTypes'
import makeRequest from "googlemaps/lib/utils/makeRequest";
import { airPressureCalculation, exhaustCalculation, exhaustTempCalculation, fluidValueCalculation, getFuelLabel, getFuelTextClass, getStatusAndSeverity, getStatusOnConditon, oilPressureCalculation, showAirPressure, showDEF, showExhaust, showGearUI, showOilPressure, vehicleTypes } from "../../Util/VehicleDashUtils.js";
import { formatValue, getFuelLevelAndImageAddBlue, getFuelLevelUnit, getFuelLevelValue } from "../NewListView/Utils.js";
import { convertDateFormat, ddmmyyyyhhmmssA } from "../../Util/DateUtils.js";
import { createSortFunction } from "../../Util/SortingUtils.js";
import classNames from "classnames";

// import SearchField from '../../Components/DashboardHeader/index';
alasql.utils.isBrowserify = false;
alasql.utils.global.XLSX = XLSX;

let uservehicledate = {};
let shownlistview = {}
const getPixelPositionOffset = (width, height) => ({
  x: -(width / 2),
  y: -(height / 2)
});
const minZoomOut = {
  minZoom: 3
  // maxZoom: 18,
}
let globalprops = {};
const MapWithAMarkerClusterer = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=" + apilist.GOOGLE_MAP_API_KEY + "&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `80vh` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withStateHandlers(
    () => ({
      isOpen: false
    }),
    {
      onToggleOpen: ({ isOpen }) => props => ({
        isOpen: !isOpen
      })
    }
  ),

  lifecycle({

    UNSAFE_componentWillMount() {
      const refs = {}
      this.setState({
        bounds: null,
        searchcenter: {},
        onMapMounted: ref => {
          refs.map = ref;
        },
        onBoundsChanged: () => {
          this.setState({
            bounds: refs.map.getBounds(),
            searchcenter: refs.map.getCenter(),
          })

        },
        onSearchBoxMounted: ref => {
          refs.searchBox = ref;
        },
        onPlacesChanged: () => {
          const places = refs.searchBox.getPlaces();
          const bounds = new window.google.maps.LatLngBounds();

          places.forEach(place => {
            if (place.geometry.viewport) {
              bounds.union(place.geometry.viewport)
            } else {
              bounds.extend(place.geometry.location)
            }
          });
          const nextMarkers = places.map(place => ({
            position: place.geometry.location,
          }));
          //const nextCenter = nextMarkers.get(nextMarkers, '0.position', this.state.center);

          const nextCenter = { lat: nextMarkers[0].position.lat(), lng: nextMarkers[0].position.lng() } || this.state.searchcenter;
          this.setState({
            searchcenter: nextCenter,
            // markers: nextMarkers,
          });
          // this.onnewupdatechange(globalprops)
          globalprops.searchupdatedata(this.state.searchcenter)




          globalprops.gmapsDispatch({
            zoomToMarkers: map => {
              if (map) {
                map.fitBounds({
                  west: Math.min.apply(null, [this.state.searchcenter.lng]),
                  east: Math.max.apply(null, [this.state.searchcenter.lng]),
                  north: Math.min.apply(null, [this.state.searchcenter.lat]),
                  south: Math.max.apply(null, [this.state.searchcenter.lat])
                });
              }
            }
          });
        },
        onnewupdatechange: (newprops) => {
          newprops.searchupdatedata(this.state.searchcenter)
        },


      })

    },
  }),
  withScriptjs,
  withGoogleMap
)(props => {
  return props.zoomMarkers ? (

    <GoogleMap
      defaultZoom={4}
      //center = {searchlat!==""?{lat: searchlat, lng: searchlang}:{ lat: 20.5937, lng: 78.9629 }}
      defaultCenter={{ lat: 20.5937, lng: 78.9629 }}
      options={minZoomOut}
      ref={props.zoomMarkers}

    >
      {props.showGeofenceOption ?
        <div className="customPopup_map" style={{ top: "35%" }}>
          <input type="checkbox" onChange={props.geofenceCheckbox}></input>
          <label className="txt-align-poi">Show Geofence</label>
          {/* {props.showGeofence === true ?    */}
          <div className="geoContent" style={{ marginLeft: '-5%', width: '168px', display: props.display }}>
            {props.geofenceList !== undefined && props.geofenceList.length > 0 ? <div style={{ display: "flex" }}><span className="geoButtons" onClick={props.selectall.bind(this, "select")}>Select All</span><span onClick={props.selectall.bind(this, "deselect")} className="geoButtons">Deselect All</span></div> : ""}
            <div className="boxContainer" style={{ maxHeight: "280px", minHeight: "35px", background: "white", overflow: 'auto' }} >
              {props.geofenceList !== undefined && props.geofenceList.length > 0 ? props.geofenceList.map((cols, index) => {
                return <div key={index} style={{ borderBottom: '2px solid whitesmoke', marginBottom: '1%' }}>&nbsp;<input className="configure_checkbox_geo" type="checkbox" name={cols.geofence_name}
                  value={cols.geofence_type}
                  onChange={props.columnchanges}
                /> &nbsp;<label className='colsname'>{cols.geofence_name}</label>
                </div>
              }) : <div style={{ textAlign: "center", marginBottom: '1%' }}><label className='colsname'>No Geofence created</label>
              </div>
              }
            </div>
          </div>
        </div> : ""}

      {props.showGeofenceOption ?
        <div className="customPopup_map">
          <input type="checkbox" onChange={props.poicheckbox}></input>
          <label className="txt-align-poi">Show POI</label>
          {props.showselectpoi === true ?
            <select name="category"
              value={props.poigroupvalue || ''}
              className="form-control poi-select-drop"
              onChange={props.poionchange.bind(this)}
            >
              <option value='' disabled>Group</option>
              {props.poigroups.map(list => <option key={list.poi_group_id} id={list.poi_group_id}
                value={list.group_name}>{list.group_name}</option>)}
            </select> : ''
          }
        </div> : ""}
      <div className="customPopup_label">
        <input type="checkbox" onChange={props.showlabelcheckbox}></input>
        <label className="txt-align-poi">Show Label</label>

      </div>
      {props.poilatlong && props.poilatlong.map((list, index) =>
        <Marker key={index}
          label={{
            text: list.poi_name,
            color: "black",
            fontWeight: "bold",
            fontSize: "9pt",
            backgroundColor: "white"
          }}
          title={list.poi_name}
          position={{
            lat: list.poilat,
            lng: list.poilong
          }}

        />
      )}
      {
        props.polygon && props.showGeofence === true ? props.polygon.map((val, index) => {
          return <Polygon key={index} path={val.coords}
            options={{ fillColor: "#000", fillOpacity: 0.4, strokeColor: "#000", strokeOpacity: 1, strokeWeight: 1 }} />
        }) : ''
      }

      {
        props.circle && props.showGeofence === true ? props.circle.map((val, index) => {
          return <Circle key={index} ref={props.circleref} editable={false} center={{ lat: Number(val.coords[0].lat), lng: Number(val.coords[0].lng) }} radius={Number(val.radius)}
            options={{ fillColor: "#000", strokeColor: "#000", fillOpacity: 0.4, strokeOpacity: 1, strokeWeight: 1 }} />
        })
          : ''}
      {
        props.circle && props.showGeofence === true ? props.circle.map((val, index) => {
          return <Marker key={index}
            label={{
              text: val.geofence_name,
              color: "black",
              fontWeight: "bold",
              fontSize: "10pt",
              backgroundColor: "white"
            }}
            options={{ icon: "http://maps.google.com/mapfiles/ms/icons/yellow.png" }}
            title={val.geofence_name}
            position={{
              lat: val.coords[0].lat,
              lng: val.coords[0].lng
            }}

          />
        }) : ''}

      {
        props.polygon && props.showGeofence === true ? props.polygon.map((val, index) => {
          return <Marker key={index}
            label={{
              text: val.geofence_name,
              color: "black",
              fontWeight: "bold",
              fontSize: "10pt",
              backgroundColor: "white"
            }}
            options={{ icon: "http://maps.google.com/mapfiles/ms/icons/yellow.png" }}
            title={val.geofence_name}
            position={{
              lat: val.center.lat,
              lng: val.center.lng
            }}

          />
        }) : ''}


      <MarkerClusterer
        onClick={props.onMarkerClustererClick}
        averageCenter
        styles={[
          {
            textColor: "white",
            height: 58,
            url: "/images/Map_screen/blue.png",
            width: 78,
            fontWeight: "750"
          }
        ]}
      >
        {props.markers &&
          props.markers.map((marker, index) => {
            const onClick = props.onClick.bind(this, marker);
            var title = '';
            var fuelevel;
            var fuelimg;
            var tankpercent;
            var tankpercentbs6;
            var batterypercent;
            var batlevel;
            var batimg;
            var gearvalue;
            var speedlevels;
            var gpsspeed;
            var temperaturecool;
            var temperatureclass;
            var airpressuract1;
            var airpressuract2;
            var airpressure1;
            var airpressure2;
            var oilpressurelevels;
            var oilpressuract;
            var airpress2iconimgs;
            var airpress1iconimgs;
            var oilpress2iconimgs;
            var fuelevel_bs6;
            var fuelimg_bs6;
            var tankpercentaddblue;
            var fuelevel_addblue;
            var fuelimg_addblue;
            var tantpercentadbluelevel;
            var extemperaturecool;
            var exhtemperatureclass;

            const fuelTextClass = getFuelTextClass(marker.isBS6);
            const fuelLabel = getFuelLabel(marker.isBS6);

            title = title + "Reg.No : " + marker.regnNumber + "\n" +
              "Model : " + marker.vehicleModel + "\n" +
              "Speed : " + marker.currentInfo.gpsSpeed + "KMPH \n" +
              "TimeStamp : " + convertDateFormat(marker.currentInfo.localTimeStamp, ddmmyyyyhhmmssA) + "\n" + //MMM DD, YYYY, h:mm:Ss A
              "Location : " + marker.currentInfo.location;
            //Fuel color fill Logics
            if (marker.isBS6 === 2) {
              tankpercent = Math.round(marker.currentInfo.cng_level_percentage);
            } else {
              tankpercent = Math.round((marker.currentInfo.fuelLevel / marker.fuelTankCap) * 100);
            }

            gearvalue = marker.currentInfo.gearnumber === "<null>" ? "N" : (marker.currentInfo.gearnumber === null || marker.currentInfo.gearnumber === "N") ? "N" : marker.currentInfo.gearnumber <= 0 ? "N" : parseInt(marker.currentInfo.gearnumber) >= 15 ? "N" : (marker.currentInfo.gearnumber === "N" || marker.currentInfo.gearnumber === "C" || marker.currentInfo.gearnumber === "R") ? marker.currentInfo.gearnumber : parseInt(marker.currentInfo.gearnumber);
            //gearvalue =5;
            if (tankpercent <= 9) {
              fuelevel = "fuelfill9";
              fuelimg = "fuelfillRed";
            } else if (tankpercent <= 19) {
              fuelevel = "fuelfill19";
              fuelimg = "fuelfillimg";
            }
            else if (tankpercent <= 29) {
              fuelevel = "fuelfill20";
              fuelimg = "fuelfillimg";
            }
            else if (tankpercent <= 39) {
              fuelevel = "fuelfill30";
              fuelimg = "fuelfillimg";
            }
            else if (tankpercent <= 49) {
              fuelevel = "fuelfill40";
              fuelimg = "fuelfillimg";
            }
            else if (tankpercent <= 59) {
              fuelevel = "fuelfill50";
              fuelimg = "fuelfillimg";
            }
            else if (tankpercent <= 69) {
              fuelevel = "fuelfill60";
              fuelimg = "fuelfillimg";
            }
            else if (tankpercent <= 79) {
              fuelevel = "fuelfill70";
              fuelimg = "fuelfillimg";
            }
            else if (tankpercent <= 89) {
              fuelevel = "fuelfill80";
              fuelimg = "fuelfillimg";
            }
            else if (tankpercent <= 98) {
              fuelevel = "fuelfill90";
              fuelimg = "fuelfillimg";
            }
            else if (tankpercent >= 99) {
              fuelevel = "fuelfill95";
              fuelimg = "fuelfillimg";
            }
            else {
              fuelevel = "fuelfill90";
              fuelimg = "fuelfillimg";
            }

            //End of Fuel color fill Logics
            //BS6 fuel//
            if (marker.vehicleType === 'EDC_LCV' || marker.vehicleType === 'EEA_LCV') {
              tankpercentbs6 = 60
              marker.currentInfo.fuelLevel = Math.round((marker.fuelTankCap / 100) * 60)
            } else {
              tankpercentbs6 = Math.round((marker.currentInfo.fuelLevel / marker.fuelTankCap) * 100);
            }
            //gearvalue = 5;
            if (tankpercentbs6 <= 9) {
              fuelevel_bs6 = "fuelfill_bs69";
              fuelimg_bs6 = "fuelfillRed_bs6";
            } else if (tankpercentbs6 <= 19) {
              fuelevel_bs6 = "fuelfill_bs619";
              fuelimg_bs6 = "fuelfillimg_bs6";
            }
            else if (tankpercentbs6 <= 29) {
              fuelevel_bs6 = "fuelfill_bs620";
              fuelimg_bs6 = "fuelfillimg_bs6";
            }
            else if (tankpercentbs6 <= 39) {
              fuelevel_bs6 = "fuelfill_bs630";
              fuelimg_bs6 = "fuelfillimg_bs6";
            }
            else if (tankpercentbs6 <= 49) {
              fuelevel_bs6 = "fuelfill_bs640";
              fuelimg_bs6 = "fuelfillimg_bs6";
            }
            else if (tankpercentbs6 <= 59) {
              fuelevel_bs6 = "fuelfill_bs650";
              fuelimg_bs6 = "fuelfillimg_bs6";
            }
            else if (tankpercentbs6 <= 69) {
              fuelevel_bs6 = "fuelfill_bs660";
              fuelimg_bs6 = "fuelfillimg_bs6";
            }
            else if (tankpercentbs6 <= 79) {
              fuelevel_bs6 = "fuelfill_bs670";
              fuelimg_bs6 = "fuelfillimg_bs6";
            }
            else if (tankpercentbs6 <= 89) {
              fuelevel_bs6 = "fuelfill_bs680";
              fuelimg_bs6 = "fuelfillimg_bs6";
            }
            else if (tankpercentbs6 <= 98) {
              fuelevel_bs6 = "fuelfill_bs690";
              fuelimg_bs6 = "fuelfillimg_bs6";
            }
            else if (tankpercentbs6 >= 99) {
              fuelevel_bs6 = "fuelfill_bs695";
              fuelimg_bs6 = "fuelfillimg_bs6";
            }
            else {
              fuelevel_bs6 = "fuelfill_bs690";
              fuelimg_bs6 = "fuelfillimg_bs6";
            }

            //bs6 fuel end//
            // Add Blue//
            let showDEFUI = showDEF(marker);
            if (marker.vehicleType === 'EDC_LCV' || marker.vehicleType === 'EEA_LCV') {
              tankpercentaddblue = 80
              tantpercentadbluelevel = Math.round((marker.adBlueTankCap / 100) * 80)
            } else {
              tankpercentaddblue = Math.round(marker.currentInfo.catalyst_tank_level);
              tantpercentadbluelevel = Math.round(marker.currentInfo.catalyst_tank_level / 100 * marker.adBlueTankCap);
            }
            if (tankpercentaddblue > 100) {
              tankpercentaddblue = 100
              tantpercentadbluelevel = marker.adBlueTankCap;
            }
            //gearvalue = 5;
            // if (tankpercentaddblue <= 9) {
            //   fuelevel_addblue = "fuelfill_addb9";
            //   fuelimg_addblue = "fuelfillRed_addb";
            // } else if (tankpercentaddblue <= 19) {
            //   fuelevel_addblue = "fuelfill_addb19";
            //   fuelimg_addblue = "fuelfillimg_addb";
            // }
            // else if (tankpercentaddblue <= 29) {
            //   fuelevel_addblue = "fuelfill_addb20";
            //   fuelimg_addblue = "fuelfillimg_addb";
            // }
            // else if (tankpercentaddblue <= 39) {
            //   fuelevel_addblue = "fuelfill_addb30";
            //   fuelimg_addblue = "fuelfillimg_addb";
            // }
            // else if (tankpercentaddblue <= 49) {
            //   fuelevel_addblue = "fuelfill_addb40";
            //   fuelimg_addblue = "fuelfillimg_addb";
            // }
            // else if (tankpercentaddblue <= 59) {
            //   fuelevel_addblue = "fuelfill_addb50";
            //   fuelimg_addblue = "fuelfillimg_addb";
            // }
            // else if (tankpercentaddblue <= 69) {
            //   fuelevel_addblue = "fuelfill_addb60";
            //   fuelimg_addblue = "fuelfillimg_addb";
            // }
            // else if (tankpercentaddblue <= 79) {
            //   fuelevel_addblue = "fuelfill_addb70";
            //   fuelimg_addblue = "fuelfillimg_addb";
            // }
            // else if (tankpercentaddblue <= 89) {
            //   fuelevel_addblue = "fuelfill_addb80";
            //   fuelimg_addblue = "fuelfillimg_addb";
            // }
            // else if (tankpercentaddblue <= 98) {
            //   fuelevel_addblue = "fuelfill_addb90";
            //   fuelimg_addblue = "fuelfillimg_addb";
            // }
            // else if (tankpercentaddblue >= 99) {
            //   fuelevel_addblue = "fuelfill_addb95";
            //   fuelimg_addblue = "fuelfillimg_addb";
            // }
            // else {
            //   fuelevel_addblue = "fuelfill_addb90";
            //   fuelimg_addblue = "fuelfillimg_addb";
            // }
            //End of Add Blue//

            const { fuelLevelClass, fuelImageClass } = getFuelLevelAndImageAddBlue(tankpercentaddblue);
            fuelevel_addblue = fuelLevelClass;
            fuelimg_addblue = fuelImageClass            //Battery Logic//
            var batterythreshold;
            if (marker.batteryType === "12V Battery") {
              batterythreshold = 18
            } else {
              batterythreshold = 32
            }
            batterypercent = (marker.currentInfo.battPotential / batterythreshold) * 100;
            if (batterypercent <= 9) {
              batlevel = "batteryfill9";
              batimg = "batteryfillred";
            } else if (batterypercent <= 19) {
              batlevel = "batteryfill19";
              batimg = "batteryfillred";
            }
            else if (batterypercent <= 29) {
              batlevel = "batteryfill20";
              batimg = "batteryfillred";
            }
            else if (batterypercent <= 39) {
              batlevel = "batteryfill30";
              batimg = "batteryfillred";
            }
            else if (batterypercent <= 49) {
              batlevel = "batteryfill40";
              batimg = "batteryfillred";
            }
            else if (batterypercent < 56) {
              batlevel = "batteryfill40";
              batimg = "batteryfillred";
            }
            else if (batterypercent <= 59) {
              batlevel = "batteryfill50";
              batimg = "batteryfill";
            }
            else if (batterypercent <= 69) {
              batlevel = "batteryfill60";
              batimg = "batteryfill";
            }
            else if (batterypercent <= 79) {
              batlevel = "batteryfill70";
              batimg = "batteryfill";
            }
            else if (batterypercent <= 89) {
              batlevel = "batteryfill80";
              batimg = "batteryfill";
            }
            else if (batterypercent >= 90) {
              batlevel = "batteryfill90";
              batimg = "batteryfill";
            }
            else {
              batlevel = "batteryfill90";
              batimg = "batteryfill";
            }
            //End of battery logic//
            gpsspeed = Math.round(marker.currentInfo.gpsSpeed);
            if (gpsspeed === 0) {
              speedlevels = "speedfill";
            } else if (gpsspeed <= 5) {
              speedlevels = "speedfill5";
            }
            else if (gpsspeed <= 10) {
              speedlevels = "speedfill10";
            }
            else if (gpsspeed <= 15) {
              speedlevels = "speedfill15";
            }
            else if (gpsspeed <= 20) {
              speedlevels = "speedfill20";
            }
            else if (gpsspeed <= 25) {
              speedlevels = "speedfill25";
            }
            else if (gpsspeed <= 30) {
              speedlevels = "speedfill30";
            }
            else if (gpsspeed <= 35) {
              speedlevels = "speedfill35";
            }
            else if (gpsspeed <= 40) {
              speedlevels = "speedfill40";
            }
            else if (gpsspeed <= 45) {
              speedlevels = "speedfill45";
            }
            else if (gpsspeed <= 50) {
              speedlevels = "speedfill50";
            }
            else if (gpsspeed <= 55) {
              speedlevels = "speedfill55";
            }
            else if (gpsspeed <= 60) {
              speedlevels = "speedfill60";
            }
            else if (gpsspeed <= 65) {
              speedlevels = "speedfill65";
            }
            else if (gpsspeed <= 70) {
              speedlevels = "speedfill70";
            }
            else if (gpsspeed <= 80) {
              speedlevels = "speedfill80";
            }
            else if (gpsspeed <= 89) {
              speedlevels = "speedfill90";
            }
            else if (gpsspeed >= 90) {
              speedlevels = "speedfill95";
            }
            //speed color changes//
            //End of speed color //
            //Temperature//
            temperaturecool = Math.round((marker.currentInfo.eng_coolant_temp / 120) * 100);
            if (temperaturecool === 0) {
              temperatureclass = "tempfill"
            } else if (temperaturecool <= 5) {
              temperatureclass = "tempfill5"
            } else if (temperaturecool <= 10) {
              temperatureclass = "tempfill10"
            } else if (temperaturecool <= 15) {
              temperatureclass = "tempfill15"
            } else if (temperaturecool <= 20) {
              temperatureclass = "tempfill20"
            } else if (temperaturecool <= 25) {
              temperatureclass = "tempfill25"
            } else if (temperaturecool <= 30) {
              temperatureclass = "tempfill30"
            } else if (temperaturecool <= 35) {
              temperatureclass = "tempfill35"
            } else if (temperaturecool <= 40) {
              temperatureclass = "tempfill40"
            } else if (temperaturecool <= 45) {
              temperatureclass = "tempfill45"
            } else if (temperaturecool <= 50) {
              temperatureclass = "tempfill50"
            } else if (temperaturecool <= 55) {
              temperatureclass = "tempfill55"
            } else if (temperaturecool <= 60) {
              temperatureclass = "tempfill60"
            } else if (temperaturecool <= 65) {
              temperatureclass = "tempfill65"
            } else if (temperaturecool <= 70) {
              temperatureclass = "tempfill70"
            } else if (temperaturecool <= 75) {
              temperatureclass = "tempfill75"
            } else if (temperaturecool <= 80) {
              temperatureclass = "tempfill80"
            } else if (temperaturecool <= 85) {
              temperatureclass = "tempfill85"
            } else if (temperaturecool <= 90) {
              temperatureclass = "tempfill90"
            } else if (temperaturecool >= 91) {
              temperatureclass = "tempfill95"
            }
            //End of Temperature
            //Exhault //
            extemperaturecool = Math.round(marker.currentInfo.aft1_exhaust_temp_1);
            if (extemperaturecool === 0) {
              exhtemperatureclass = "extempfill"
            } else if (extemperaturecool <= 45) {
              exhtemperatureclass = "extempfill5"
            } else if (extemperaturecool <= 90) {
              exhtemperatureclass = "extempfill10"
            } else if (extemperaturecool <= 135) {
              exhtemperatureclass = "extempfill15"
            } else if (extemperaturecool <= 180) {
              exhtemperatureclass = "extempfill20"
            } else if (extemperaturecool <= 225) {
              exhtemperatureclass = "extempfill25"
            } else if (extemperaturecool <= 270) {
              exhtemperatureclass = "extempfill30"
            } else if (extemperaturecool <= 315) {
              exhtemperatureclass = "extempfill35"
            } else if (extemperaturecool <= 360) {
              exhtemperatureclass = "extempfill40"
            } else if (extemperaturecool <= 405) {
              exhtemperatureclass = "extempfill45"
            } else if (extemperaturecool <= 450) {
              exhtemperatureclass = "extempfill50"
            } else if (extemperaturecool <= 495) {
              exhtemperatureclass = "extempfill55"
            } else if (extemperaturecool <= 540) {
              exhtemperatureclass = "extempfill60"
            } else if (extemperaturecool <= 585) {
              exhtemperatureclass = "extempfill65"
            } else if (extemperaturecool <= 630) {
              exhtemperatureclass = "extempfill70"
            } else if (extemperaturecool <= 675) {
              exhtemperatureclass = "extempfill75"
            } else if (extemperaturecool <= 720) {
              exhtemperatureclass = "extempfill80"
            } else if (extemperaturecool <= 765) {
              exhtemperatureclass = "extempfill85"
            } else if (extemperaturecool <= 810) {
              exhtemperatureclass = "extempfill90"
            } else if (extemperaturecool >= 855) {
              exhtemperatureclass = "extempfill95"
            } else {
              exhtemperatureclass = "extempfill"
            }
            //end of Exhault//
            //Air Pressure logic//
            if (marker.isBS6 === 1 || marker.isBS6 === 2) {
              airpressuract1 = Math.round(marker.currentInfo.airpressure_actual1);
              airpressuract2 = Math.round(marker.currentInfo.airpressure_actual2);
              if (airpressuract1 === 0) {
                airpress1iconimgs = "airpress1iconimgred";
                airpressure1 = "airpress"
              } else if (airpressuract1 === 1) {
                airpress1iconimgs = "airpress1iconimgred";
                airpressure1 = "airpress1"
              } else if (airpressuract1 === 2) {
                airpress1iconimgs = "airpress1iconimgred";
                airpressure1 = "airpress2"
              } else if (airpressuract1 === 3) {
                airpress1iconimgs = "airpress1iconimgred";
                airpressure1 = "airpress3"
              } else if (airpressuract1 === 4) {
                airpress1iconimgs = "airpress1iconimgred";
                airpressure1 = "airpress4"
              } else if (airpressuract1 === 5) {
                airpress1iconimgs = "airpress1iconimg";
                airpressure1 = "airpress5"
              } else if (airpressuract1 === 6) {
                airpress1iconimgs = "airpress1iconimg";
                airpressure1 = "airpress6"
              } else if (airpressuract1 === 7) {
                airpress1iconimgs = "airpress1iconimg";
                airpressure1 = "airpress7"
              } else if (airpressuract1 === 8) {
                airpress1iconimgs = "airpress1iconimg";
                airpressure1 = "airpress8"
              } else if (airpressuract1 === 9) {
                airpress1iconimgs = "airpress1iconimg";
                airpressure1 = "airpress9"
              } else if (airpressuract1 === 10) {
                airpress1iconimgs = "airpress1iconimg";
                airpressure1 = "airpress10"
              } else if (airpressuract1 === 11) {
                airpress1iconimgs = "airpress1iconimg";
                airpressure1 = "airpress11"
              } else if (airpressuract1 === 12) {
                airpress1iconimgs = "airpress1iconimg";
                airpressure1 = "airpress12"
              }
              if (airpressuract2 === 0) {
                airpress2iconimgs = "airpress1iconimg2red";
                airpressure2 = "airpresssec"
              } else if (airpressuract2 === 1) {
                airpress2iconimgs = "airpress1iconimg2red";
                airpressure2 = "airpresssec1"
              } else if (airpressuract2 === 2) {
                airpress2iconimgs = "airpress1iconimg2red";
                airpressure2 = "airpresssec2"
              } else if (airpressuract2 === 3) {
                airpress2iconimgs = "airpress1iconimg2red";
                airpressure2 = "airpresssec3"
              } else if (airpressuract2 === 4) {
                airpress2iconimgs = "airpress1iconimg2red";
                airpressure2 = "airpresssec4"
              } else if (airpressuract2 === 5) {
                airpress2iconimgs = "airpress1iconimg2";
                airpressure2 = "airpresssec5"
              } else if (airpressuract2 === 6) {
                airpress2iconimgs = "airpress1iconimg2";
                airpressure2 = "airpresssec6"
              } else if (airpressuract2 === 7) {
                airpress2iconimgs = "airpress1iconimg2";
                airpressure2 = "airpresssec7"
              } else if (airpressuract2 === 8) {
                airpress2iconimgs = "airpress1iconimg2";
                airpressure2 = "airpresssec8"
              } else if (airpressuract2 === 9) {
                airpress2iconimgs = "airpress1iconimg2";
                airpressure2 = "airpresssec9"
              } else if (airpressuract2 === 10) {
                airpress2iconimgs = "airpress1iconimg2";
                airpressure2 = "airpresssec10"
              } else if (airpressuract2 === 11) {
                airpress2iconimgs = "airpress1iconimg2";
                airpressure2 = "airpresssec11"
              } else if (airpressuract2 === 12) {
                airpress2iconimgs = "airpress1iconimg2";
                airpressure2 = "airpresssec12"
              }
            } else {
              if (marker.vehicleType === "EEA") {
                airpressuract1 = Math.round(marker.currentInfo.airpressure_actual1);
                airpressuract2 = Math.round(marker.currentInfo.airpressure_actual2);
                if (airpressuract1 === 0) {
                  airpress1iconimgs = "airpress1iconimgred";
                  airpressure1 = "airpress"
                } else if (airpressuract1 === 1) {
                  airpress1iconimgs = "airpress1iconimgred";
                  airpressure1 = "airpress1"
                } else if (airpressuract1 === 2) {
                  airpress1iconimgs = "airpress1iconimgred";
                  airpressure1 = "airpress2"
                } else if (airpressuract1 === 3) {
                  airpress1iconimgs = "airpress1iconimgred";
                  airpressure1 = "airpress3"
                } else if (airpressuract1 === 4) {
                  airpress1iconimgs = "airpress1iconimgred";
                  airpressure1 = "airpress4"
                } else if (airpressuract1 === 5) {
                  airpress1iconimgs = "airpress1iconimg";
                  airpressure1 = "airpress5"
                } else if (airpressuract1 === 6) {
                  airpress1iconimgs = "airpress1iconimg";
                  airpressure1 = "airpress6"
                } else if (airpressuract1 === 7) {
                  airpress1iconimgs = "airpress1iconimg";
                  airpressure1 = "airpress7"
                } else if (airpressuract1 === 8) {
                  airpress1iconimgs = "airpress1iconimg";
                  airpressure1 = "airpress8"
                } else if (airpressuract1 === 9) {
                  airpress1iconimgs = "airpress1iconimg";
                  airpressure1 = "airpress9"
                } else if (airpressuract1 === 10) {
                  airpress1iconimgs = "airpress1iconimg";
                  airpressure1 = "airpress10"
                } else if (airpressuract1 === 11) {
                  airpress1iconimgs = "airpress1iconimg";
                  airpressure1 = "airpress11"
                } else if (airpressuract1 === 12) {
                  airpress1iconimgs = "airpress1iconimg";
                  airpressure1 = "airpress12"
                }
                if (airpressuract2 === 0) {
                  airpress2iconimgs = "airpress1iconimg2red";
                  airpressure2 = "airpresssec"
                } else if (airpressuract2 === 1) {
                  airpress2iconimgs = "airpress1iconimg2red";
                  airpressure2 = "airpresssec1"
                } else if (airpressuract2 === 2) {
                  airpress2iconimgs = "airpress1iconimg2red";
                  airpressure2 = "airpresssec2"
                } else if (airpressuract2 === 3) {
                  airpress2iconimgs = "airpress1iconimg2red";
                  airpressure2 = "airpresssec3"
                } else if (airpressuract2 === 4) {
                  airpress2iconimgs = "airpress1iconimg2red";
                  airpressure2 = "airpresssec4"
                } else if (airpressuract2 === 5) {
                  airpress2iconimgs = "airpress1iconimg2";
                  airpressure2 = "airpresssec5"
                } else if (airpressuract2 === 6) {
                  airpress2iconimgs = "airpress1iconimg2";
                  airpressure2 = "airpresssec6"
                } else if (airpressuract2 === 7) {
                  airpress2iconimgs = "airpress1iconimg2";
                  airpressure2 = "airpresssec7"
                } else if (airpressuract2 === 8) {
                  airpress2iconimgs = "airpress1iconimg2";
                  airpressure2 = "airpresssec8"
                } else if (airpressuract2 === 9) {
                  airpress2iconimgs = "airpress1iconimg2";
                  airpressure2 = "airpresssec9"
                } else if (airpressuract2 === 10) {
                  airpress2iconimgs = "airpress1iconimg2";
                  airpressure2 = "airpresssec10"
                } else if (airpressuract2 === 11) {
                  airpress2iconimgs = "airpress1iconimg2";
                  airpressure2 = "airpresssec11"
                } else if (airpressuract2 === 12) {
                  airpress2iconimgs = "airpress1iconimg2";
                  airpressure2 = "airpresssec12"
                }

              } else {
                if (marker.currentInfo.air_pressure_digital !== 0) {
                  airpress2iconimgs = "airpress1iconimg2";
                  airpressure1 = "airpress12";
                  airpressure2 = "airpresssec12";
                } else {
                  airpress2iconimgs = "airpress1iconimg2red";
                  airpressure1 = "airpress";
                  airpressure2 = "airpresssec";
                }

              }
            }

            //End of Air Pressure logic//
            //oil Pressure//
            if (marker.vehicleType === "IL") {
              if (marker.currentInfo.engineOilDig !== 0) {
                oilpress2iconimgs = "oilpressiconimg";
                oilpressurelevels = "oilfillslvl";
              } else {
                oilpress2iconimgs = "oilpressiconimgred";
                oilpressurelevels = "oilfillszero";
              }

            } else {
              oilpressuract = Math.round(marker.currentInfo.engine_oil_act);
              if (oilpressuract === 0) {
                oilpress2iconimgs = "oilpressiconimgred";
                oilpressurelevels = "oilfillszero"
              } else if (oilpressuract === 1) {
                oilpress2iconimgs = "oilpressiconimgred";
                oilpressurelevels = "oilfillslvl1"
              }
              else if (oilpressuract === 2) {
                oilpress2iconimgs = "oilpressiconimg";
                oilpressurelevels = "oilfillslvl2"
              }
              else if (oilpressuract === 3) {
                oilpress2iconimgs = "oilpressiconimg";
                oilpressurelevels = "oilfillslvl3"
              }
              else if (oilpressuract === 4) {
                oilpress2iconimgs = "oilpressiconimg";
                oilpressurelevels = "oilfillslvl4"
              }
              else if (oilpressuract === 5) {
                oilpress2iconimgs = "oilpressiconimg";
                oilpressurelevels = "oilfillslvl5"
              }
              else if (oilpressuract === 6) {
                oilpress2iconimgs = "oilpressiconimg";
                oilpressurelevels = "oilfillslvl6"
              }
              else if (oilpressuract === 7) {
                oilpress2iconimgs = "oilpressiconimg";
                oilpressurelevels = "oilfillslvl7"
              }
              else if (oilpressuract === 8) {
                oilpress2iconimgs = "oilpressiconimg";
                oilpressurelevels = "oilfillslvl8"
              }
              else if (oilpressuract === 9) {
                oilpress2iconimgs = "oilpressiconimg";
                oilpressurelevels = "oilfillslvl9"
              }
              else if (oilpressuract === 10) {
                oilpress2iconimgs = "oilpressiconimg";
                oilpressurelevels = "oilfillslvl"
              }
            }
            //End of oil pressure//

            return (


              <Marker
                label={props.showlabelvals === true ? {
                  text: marker.regnNumber,
                  color: "black",
                  fontWeight: "bold",
                  fontSize: "9pt",
                  backgroundColor: "white"
                } : ''}
                key={index}
                icon={marker.icons}
                title={title}
                position={{
                  lat: marker.currentInfo.latitude,
                  lng: marker.currentInfo.longitude
                }}
                onClick={onClick}

              >

                {props.selectedMarker.obuId === marker.obuId && props.vehicledash === true && (
                  <OverlayView
                    position={{
                      lat: marker.currentInfo.latitude,
                      lng: marker.currentInfo.longitude
                    }}
                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    getPixelPositionOffsetlay={getPixelPositionOffset}

                  >

                    <div className="layoutdesign" >
                      <div className="ign_icon dash_title"><img className={marker.currentInfo.status === "DISCONNECTED" ? "imggray" : marker.currentInfo.ignitionStatus !== 1 ? "imggray" : ""} src="images/Vehicle_Dashboard/Ignition.png" alt="dash" /> IGNITION</div>
                      <div className="dash_title">
                        <div className="dash_vehnumber">REG.NUMBER <br />{marker.regnNumber}</div>
                        <div className="dash_uptime">LAST UPDATED ON<br />{convertDateFormat(marker.currentInfo.localTimeStamp, ddmmyyyyhhmmssA)}</div> {/*MMM DD, YYYY, h:mm:ss A*/}
                        <div className="dash_drivernm">DRIVER<br />{marker.driverName}</div>
                        <div className="dash_location">LOCATION<br /><span style={{ width: '263px', display: 'inline-block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', margin: '-6px 0px' }} className="ffu13" title={marker.currentInfo.location} >{marker.currentInfo.location}</span></div>
                      </div>
                      {/* {marker.alert_type_id===9 ?<div className="edc_icon"><img src="images/Vehicle_Dashboard/ambur-color/2.png"  alt="dash"/></div>:<div className="edc_icon"><img src="images/Vehicle_Dashboard/grey-color/2.png"  alt="dash"/></div>} */}
                      {new Date(marker.currentInfo.localTimeStamp) > props.ttldatecompare ? marker.currentInfo.eng_coolant_temp >= 105 ? <div className="temp_icon"><img src="images/Vehicle_Dashboard/ambur-color/6.png" alt="dash" title={alert_types.temp_warning} /></div>
                        : <div className="temp_icon"><img src="images/Vehicle_Dashboard/grey-color/6.png" alt="dash" title={alert_types.temp_warning} /></div> : <div className="temp_icon"><img src="images/Vehicle_Dashboard/grey-color/6.png" alt="dash" title={alert_types.temp_warning} /></div>}
                      {new Date(marker.currentInfo.localTimeStamp) > props.ttldatecompare ? marker.currentInfo.ignitionStatus === 1 ? marker.batteryType === "12V Battery" ? marker.currentInfo.battPotential <= 10 ? <div className="battery_icon"><img src="images/Vehicle_Dashboard/ambur-color/10.png" alt="dash" title={alert_types.low_battery_voltage} /></div> : <div className="battery_icon"><img src="images/Vehicle_Dashboard/grey-color/10.png" alt="dash" title={alert_types.low_battery_voltage} /></div> : marker.batteryType === "24V Battery" ? marker.currentInfo.battPotential <= 18 ? <div className="battery_icon"><img src="images/Vehicle_Dashboard/ambur-color/10.png" alt="dash" title={alert_types.low_battery_voltage} /></div> : <div className="battery_icon"><img src="images/Vehicle_Dashboard/grey-color/10.png" alt="dash" title={alert_types.low_battery_voltage} /></div> : <div className="battery_icon"><img src="images/Vehicle_Dashboard/grey-color/10.png" alt="dash" title={alert_types.low_battery_voltage} /></div> : <div className="battery_icon"><img src="images/Vehicle_Dashboard/grey-color/10.png" alt="dash" title={alert_types.low_battery_voltage} /></div> : <div className="battery_icon"><img src="images/Vehicle_Dashboard/grey-color/10.png" alt="dash" title={alert_types.low_battery_voltage} /></div>}
                      {marker.isBS6 === 1 || marker.isBS6 === 2 ? new Date(marker.currentInfo.localTimeStamp) > props.ttldatecompare ? marker.currentInfo.edc_lamp_status === 1 ? <div className="edc_icon"><img src="images/Vehicle_Dashboard/ambur-color/2.png" alt="dash" title={alert_types.edc_warning} /></div> : <div className="edc_icon"><img src="images/Vehicle_Dashboard/grey-color/2.png" alt="dash" title={alert_types.edc_warning} /></div> : <div className="edc_icon"><img src="images/Vehicle_Dashboard/grey-color/2.png" alt="dash" title={alert_types.edc_warning} /></div> : ''}
                      {marker.isBS6 === 1 || marker.isBS6 === 2 ? new Date(marker.currentInfo.localTimeStamp) > props.ttldatecompare ? (marker.currentInfo.ecu_mil_status === 4) ? <div className="mil_icon"><img src="images/Vehicle_Dashboard/ambur-color/1.png" alt="dash" title={alert_types.mil_status} /></div> : <div className="mil_icon"><img src="images/Vehicle_Dashboard/grey-color/1.png" alt="dash" title={alert_types.mil_status} /></div> : <div className="mil_icon"><img src="images/Vehicle_Dashboard/grey-color/1.png" alt="dash" title={alert_types.mil_status} /></div> : ''}
                      {marker.isBS6 === 1 || marker.isBS6 === 2 ? new Date(marker.currentInfo.localTimeStamp) > props.ttldatecompare ? marker.currentInfo.stop_status === 1 ? <div className="stp_icon"><img src="images/Vehicle_Dashboard/ambur-color/3.png" alt="dash" title={alert_types.stop_lamp} /></div> : <div className="stp_icon"><img src="images/Vehicle_Dashboard/grey-color/3.png" alt="dash" title={alert_types.stop_lamp} /></div> : <div className="stp_icon"><img src="images/Vehicle_Dashboard/grey-color/3.png" alt="dash" title={alert_types.stop_lamp} /></div> : ''}
                      {marker.isBS6 === 1 ? new Date(marker.currentInfo.localTimeStamp) > props.ttldatecompare ? marker.currentInfo.exhaust_system_high_temp_lamp_command === 1 ? <div className="hext_icon"><img src="images/Vehicle_Dashboard/ambur-color/4.png" alt="dash" title={alert_types.high_exa_temp} /></div> : <div className="hext_icon"><img src="images/Vehicle_Dashboard/grey-color/4.png" alt="dash" title={alert_types.high_exa_temp} /></div> : <div className="hext_icon"><img src="images/Vehicle_Dashboard/grey-color/4.png" alt="dash" title={alert_types.high_exa_temp} /></div> : ''}
                      {marker.isBS6 === 1 ? new Date(marker.currentInfo.localTimeStamp) > props.ttldatecompare ? (marker.currentInfo.dpf1_regen_needed >= 1 && marker.currentInfo.dpf1_regen_needed <= 3) ? <div className="dpf_icon"><img src="images/Vehicle_Dashboard/ambur-color/5.png" alt="dash" title={alert_types.regeneration} /></div> : <div className="dpf_icon"><img src="images/Vehicle_Dashboard/grey-color/5.png" alt="dash" title={alert_types.regeneration} /></div> : <div className="dpf_icon"><img src="images/Vehicle_Dashboard/grey-color/5.png" alt="dash" title={alert_types.regeneration} /></div> : ''}
                      {marker.isBS6 === 1 ? new Date(marker.currentInfo.localTimeStamp) > props.ttldatecompare ? (marker.currentInfo.nox_error >= 1 && marker.currentInfo.nox_error <= 2) ? <div className="nox_icon"><img src="images/Vehicle_Dashboard/ambur-color/7.png" alt="dash" title={alert_types.nox_error} /></div> : <div className="nox_icon"><img src="images/Vehicle_Dashboard/grey-color/7.png" alt="dash" title={alert_types.nox_error} /></div> : <div className="nox_icon"><img src="images/Vehicle_Dashboard/grey-color/7.png" alt="dash" title={alert_types.nox_error} /></div> : ''}
                      {marker.isBS6 === 1 || marker.isBS6 === 2 ? new Date(marker.currentInfo.localTimeStamp) > props.ttldatecompare ? marker.currentInfo.air_filter_blocked === 1 ? <div className="airfilbck_icon"><img src="images/Vehicle_Dashboard/ambur-color/8.png" alt="dash" title={alert_types.air_filter_blocked} /></div> : <div className="airfilbck_icon"><img src="images/Vehicle_Dashboard/grey-color/8.png" alt="dash" title={alert_types.air_filter_blocked} /></div> : <div className="airfilbck_icon"><img src="images/Vehicle_Dashboard/grey-color/8.png" alt="dash" title={alert_types.air_filter_blocked} /></div> : ''}
                      {marker.isBS6 === 1 || marker.isBS6 === 2 ? new Date(marker.currentInfo.localTimeStamp) > props.ttldatecompare ? marker.currentInfo.coolant_level_warning === 1 ? <div className="lowcoollvl_icon"><img src="images/Vehicle_Dashboard/ambur-color/9.png" alt="dash" title={alert_types.low_coolant_level} /></div> : <div className="lowcoollvl_icon"><img src="images/Vehicle_Dashboard/grey-color/9.png" alt="dash" title={alert_types.low_coolant_level} /></div> : <div className="lowcoollvl_icon"><img src="images/Vehicle_Dashboard/grey-color/9.png" alt="dash" title={alert_types.low_coolant_level} /></div> : ''}
                      {marker.isBS6 === 1 ? new Date(marker.currentInfo.localTimeStamp) > props.ttldatecompare ? (marker.currentInfo.low_adblue_level_warning >= 1 && marker.currentInfo.low_adblue_level_warning <= 7) ? <div className="lowadblue_icon"><img src="images/Vehicle_Dashboard/ambur-color/11.png" alt="dash" title={alert_types.low_def_level} /></div> : <div className="lowadblue_icon"><img src="images/Vehicle_Dashboard/grey-color/11.png" alt="dash" title={alert_types.low_def_level} /></div> : <div className="lowadblue_icon"><img src="images/Vehicle_Dashboard/grey-color/11.png" alt="dash" title={alert_types.low_def_level} /></div> : ''}
                      {marker.isBS6 === 1 || marker.isBS6 === 2 ? new Date(marker.currentInfo.localTimeStamp) > props.ttldatecompare ? marker.currentInfo.particulate_trap_regeneration_inhibit_switch === 1 ? <div className="particulate_icon"><img src="images/Vehicle_Dashboard/ambur-color/12.png" alt="dash" title={alert_types.particulate_trap} /></div> : <div className="particulate_icon"><img src="images/Vehicle_Dashboard/grey-color/12.png" alt="dash" title={alert_types.particulate_trap} /></div> : <div className="particulate_icon"><img src="images/Vehicle_Dashboard/grey-color/12.png" alt="dash" title={alert_types.particulate_trap} /></div> : ''}
                      <div className="battery_img"><img src="images/Vehicle_Dashboard/Battery_Status.png" alt="dash" /><div className="battery_txt">BATTERY</div><br />
                        {marker.currentInfo.status !== "DISCONNECTED" &&
                          <>
                            <div className="battert_val">{marker.currentInfo.battPotential === null ? '' : marker.currentInfo.battPotential.toFixed(2)}</div><div className="batteryunit">{marker.currentInfo.battPotential !== null ? "v" : ''}</div>
                          </>
                        }
                        <div className={marker.currentInfo.ignitionStatus === 1 && marker.currentInfo.status !== "DISCONNECTED" ? batimg : "batteryfillgrey"} >
                          {marker.currentInfo.ignitionStatus === 1 ? <img className={batlevel} src="images/Vehicle_Dashboard/Battery_Status.png" alt="dash" /> : ''}
                        </div>
                      </div>

                      {marker.currentInfo.ignitionStatus === 1 ? marker.currentInfo.gsas_comp > 95 && marker.currentInfo.gsas_comp <= 100 ? <div >
                        <div className="thumbsup_cls"><img className="thumbsupimg" src="images/Vehicle_Dashboard/thumbsup.png" alt="dash" />
                        </div>
                        <div className="thumbsup_cls2"><img className="thumbsupimg" src="images/Vehicle_Dashboard/thumbsup.png" alt="dash" />
                        </div>
                        <div className="thumbsup_cls3"><img className="thumbsupimg" src="images/Vehicle_Dashboard/thumbsup.png" alt="dash" />
                        </div>
                      </div> : marker.currentInfo.gsas_comp > 90 && marker.currentInfo.gsas_comp <= 95 ? <div >
                        <div className="thumbsup_cls"><img className="thumbsupimg" src="images/Vehicle_Dashboard/thumbsup.png" alt="dash" />
                        </div>
                        <div className="thumbsup_cls2"><img className="thumbsupimg" src="images/Vehicle_Dashboard/thumbsup.png" alt="dash" />
                        </div>
                      </div> : marker.currentInfo.gsas_comp > 85 && marker.currentInfo.gsas_comp <= 90 ? <div >
                        <div className="thumbsup_cls"><img className="thumbsupimg" src="images/Vehicle_Dashboard/thumbsup.png" alt="dash" />
                        </div></div> : marker.currentInfo.gsas_comp > 80 && marker.currentInfo.gsas_comp <= 85 ? <div >
                          <div className="thumbsup_cls"><img className="thumbsupimg" src="images/Vehicle_Dashboard/thumbsdown.png" alt="dash" />
                          </div></div> : marker.currentInfo.gsas_comp > 75 && marker.currentInfo.gsas_comp <= 80 ? <div >
                            <div className="thumbsup_cls"><img className="thumbsupimg" src="images/Vehicle_Dashboard/thumbsdown.png" alt="dash" />
                            </div>
                            <div className="thumbsup_cls2"><img className="thumbsupimg" src="images/Vehicle_Dashboard/thumbsdown.png" alt="dash" />
                            </div>
                          </div> : marker.currentInfo.gsas_comp > 0 && marker.currentInfo.gsas_comp <= 75 ? <div >
                            <div className="thumbsup_cls"><img className="thumbsupimg" src="images/Vehicle_Dashboard/thumbsdown.png" alt="dash" />
                            </div>
                            <div className="thumbsup_cls2"><img className="thumbsupimg" src="images/Vehicle_Dashboard/thumbsdown.png" alt="dash" />
                            </div>
                            <div className="thumbsup_cls3"><img className="thumbsupimg" src="images/Vehicle_Dashboard/thumbsdown.png" alt="dash" />
                            </div>
                          </div> : '' : ''}
                      {showAirPressure(marker) &&
                        <>
                          <div className="air1_text0">0</div>
                          <div className="air1_text6">6</div>
                          <div className="air1_text12">12</div>
                          <div className="air2_text0">0</div>
                          <div className="air2_text6">6</div>
                          <div className="air2_text12">12</div>
                          <div className="air1_text"><strong>AIR<br /> PRESSURE 1</strong></div>
                          <div className="airpress1icon">
                            <img src="images/Vehicle_Dashboard/PressureScale.png" alt="dash" />
                            {marker.currentInfo.status !== "DISCONNECTED" &&
                              <div className={marker.vehicleType === "EEA" ? airpress1iconimgs : airpress2iconimgs} >
                                <img className={marker.currentInfo.ignitionStatus === 1 ? airpressure1 : 'airpress'} src="images/Vehicle_Dashboard/Pressure_Green_Scale.png" alt="dash" />
                              </div>
                            }
                          </div>
                          <div className="airpress2icon">
                            <img src="images/Vehicle_Dashboard/PressureScale.png" alt="dash" />
                            {marker.currentInfo.status !== "DISCONNECTED" &&
                              <div className={airpress2iconimgs} >
                                <img className={marker.currentInfo.ignitionStatus === 1 ? airpressure2 : 'airpresssec'} src="images/Vehicle_Dashboard/Pressure_Green_Scale.png" alt="dash" />
                              </div>
                            }
                          </div>

                          <div className="oil_text"><strong>AIR<br /> PRESSURE 2</strong></div>
                          {marker.currentInfo.status !== "DISCONNECTED" &&
                            <div className="air1_val">
                              {marker.currentInfo.ignitionStatus === 1 ? (marker.isBS6 === 1 || marker.isBS6 === 2 || marker.isBS6 === 4 || (marker.isBS6 === 0 && (marker.vehicleType === "EEA"))) ? airpressuract1 : '' : '' }
                              <div className="air1_units">
                              {marker.currentInfo.ignitionStatus === 1 ? (marker.isBS6 === 1 || marker.isBS6 === 2 || marker.isBS6 === 4 || (marker.isBS6 === 0 && (marker.vehicleType === "EEA"))) ? 'bar' : '' : '' }
                              </div>
                            </div>
                          }
                          {marker.currentInfo.status !== "DISCONNECTED" &&
                            <div className="oil_val">
                              {marker.currentInfo.ignitionStatus === 1 ? (marker.isBS6 === 1 || marker.isBS6 === 2 || marker.isBS6 === 4 || (marker.isBS6 === 0 && (marker.vehicleType === "EEA"))) ? airpressuract2 : '' : '' }
                              <div className="oil_units">
                                {marker.currentInfo.ignitionStatus === 1 ? (marker.isBS6 === 1 || marker.isBS6 === 2 || marker.isBS6 === 4 || (marker.isBS6 === 0 && (marker.vehicleType === "EEA"))) ? 'bar' : '' : '' }
                              </div>
                            </div>
                          }
                        </>}

                      {/*<div className="eucalt"><img src="images/Vehicle_Dashboard/ECUdash.png"  alt="dash"/></div>
                    <div className="eucalt_text">ECU<br/> ALERT</div><div className="eucalt_val">5</div> */}
                      <div className="speedicon"><img src="images/Vehicle_Dashboard/Speedometer_scale.png" alt="dash" />
                        {marker.currentInfo.status !== "DISCONNECTED" &&
                          <div className="speedfillimg" >
                            <img className={speedlevels} src="images/Vehicle_Dashboard/Gradient.png" alt="dash" />
                          </div>
                        }
                      </div>
                      {showExhaust(marker.isBS6) && <>
                        <div className="exhtempicon"><img src="images/Vehicle_Dashboard/Temperaturegray.png" alt="dash" />
                          <div className="exhfillimg" >
                            <img className={marker.currentInfo.ignitionStatus === 1 ? exhtemperatureclass : 'extempfill'} src="images/Vehicle_Dashboard/Temperature.png" alt="dash" />
                          </div>
                        </div>
                        <div className="ext_text">EXHAUST</div>
                        <div className="ext_val">{marker.currentInfo.ignitionStatus === 1 ? Math.round(marker.currentInfo.aft1_exhaust_temp_1) < 0 ? 0 : Math.round(marker.currentInfo.aft1_exhaust_temp_1) : 0}<div className="ext_units"><sup>o</sup>C</div></div>
                      </>}

                      <div className="criticaldash"><img src="images/Vehicle_Dashboard/Criticaldash.png" alt="dash" /></div>

                      <div className="critic_text">CRITICAL<br /> ALERT</div><div className="critic_val">{marker.criccnt !== undefined ? marker.criccnt : 0}</div>
                      {marker.isBS6 === 1 || showDEFUI ? <div className="fuelperctext_bs6">{tankpercentbs6 > 100 ? 100 : tankpercentbs6 < 0 ? 0 : tankpercentbs6}%</div> : <div className="fuelperctext">{tankpercent > 100 ? 100 : tankpercent < 0 ? 0 : tankpercent}%</div>}
                      {marker.isBS6 === 1 || showDEFUI ? <div className="fuelbsiv_bs6"><img src="images/Vehicle_Dashboard/Fuel_bs6.png" alt="dash" /><div className={fuelimg_bs6} ><img className={fuelevel_bs6} src="images/Vehicle_Dashboard/Fuel_bs6.png" alt="dash" /></div></div> : <div className="fuelbsiv"><img src="images/Vehicle_Dashboard/BS_iv.png" alt="dash" /><div className={fuelimg} >
                        <img className={fuelevel} src="images/Vehicle_Dashboard/BS_iv.png" alt="dash" /></div></div>}
                      <div className={fuelTextClass}>{fuelLabel}</div>
                      {/* {marker.isBS6 === 1 ? <div className="fuel_text_bs6">FUEL</div> : marker.isBS6 === 2 ? <div className="fuel_text_bs6_cng">GAS</div> : <div className="fuel_text">FUEL</div>} */}
                      <div className="fuel_val">{getFuelLevelValue(marker.currentInfo.fuelLevel)}<div className="fuel_units">&nbsp;{getFuelLevelUnit(marker.isBS6, marker.vehicleType)}</div></div>
                      {showDEFUI ? <div className="addbluetext">{tankpercentaddblue}%</div> : ''}
                      {showDEFUI ? <div className="addblue"><img src="images/Vehicle_Dashboard/Adblue_bs6.png" alt="dash" />
                        <div className={fuelimg_addblue} >
                          <img className={fuelevel_addblue} src="images/Vehicle_Dashboard/Adblue_bs6.png" alt="dash" />
                        </div>
                      </div> : ''}
                      {showDEFUI && <>
                        <div className="addblue_text">DEF</div>
                        <div className="addblue_val">{formatValue(tantpercentadbluelevel)}<div className="addblue_units">&nbsp;ltr</div></div>
                      </>}
                      <div className="roadings"><img src="images/Vehicle_Dashboard/roads.png" alt="dash" /></div>
                      <div className="road_text">ODO</div><div className="road_val"> {marker.isBS6 === 1 || marker.isBS6 === 2 ? marker.vehicleType === 'EDC_LCV' || marker.vehicleType === 'EEA_LCV' ? Math.round(marker.currentInfo.gps_odo) : marker.currentInfo.vehicle_distance !== null || marker.currentInfo.vehicle_distance !== '' ? Math.round(marker.currentInfo.vehicle_distance) : '' : marker.vehicleType === "EEA" ? Math.round(marker.currentInfo.vehicle_odo) : Math.round(marker.currentInfo.gps_odo)}<div className="road_units">&nbsp;kms</div></div>
                      {marker.isBS6 !== 4 && 
                        <div className="engHrs">
                          <img
                            src="images/Vehicle_Dashboard/Engine_Hours.png"
                            alt="engHrs.icon"
                          />
                        </div>
                      }
                      {(marker.vehicleType !== "IL" && marker.isBS6 !== 4) ? <div className="enginehrs_text">ENG HRS</div> : ''}{(marker.vehicleType !== "IL" && marker.isBS6 !== 4) ? <div className="enginehrs_text_val">{Math.round(marker.currentInfo.engineHrs)}<span className="enginehrsspan"> hrs</span></div> : ''}
                      <div className="cool_text">COOLANT</div><div className="cool_val">{marker.currentInfo.status !== "DISCONNECTED" ? marker.currentInfo.ignitionStatus === 1 ? marker.currentInfo.eng_coolant_temp < 0 ? 0 : Math.round(marker.currentInfo.eng_coolant_temp) : 0 : 0}<div className="cool_units"><sup>o</sup>C</div></div>
                      <div className="tempicon"><img src="images/Vehicle_Dashboard/Temperaturegray.png" alt="dash" />
                        {marker.currentInfo.status !== "DISCONNECTED" &&
                          <div className="tempfillimg" >
                            <img className={marker.currentInfo.ignitionStatus === 1 ? temperatureclass : 'tempfill'} src="images/Vehicle_Dashboard/Temperature.png" alt="dash" />
                          </div>
                        }
                      </div>
                      {showOilPressure(marker) && <>
                        <div className="oil1_text0">0</div>
                        {marker.vehicleType !== "IL" ? <div className="oil1_text6">6</div> : ''}
                        {marker.vehicleType !== "IL" ? <div className="oil1_text12">12</div> : <div className="oil1_text12">1</div>}
                        <div className="air2_text"><strong>OIL<br /> PRESSURE </strong></div>
                        {marker.currentInfo.status !== "DISCONNECTED" ? <div className="air2_val">{marker.currentInfo.ignitionStatus === 1 ? marker.vehicleType === "IL" ? '' : oilpressuract : ''}<div className="air2_units">{marker.currentInfo.ignitionStatus === 1 ? marker.vehicleType === "IL" ? '' : "bar" : ''}</div></div> : ''}
                        <div className="oilpressicon">
                          <img src="images/Vehicle_Dashboard/PressureScale.png" alt="dash" />
                          {marker.currentInfo.status !== "DISCONNECTED" &&
                            <div className={oilpress2iconimgs} >
                              <img className={marker.currentInfo.ignitionStatus === 1 ? oilpressurelevels : 'oilfillszero'} src="images/Vehicle_Dashboard/Pressure_Green_Scale.png" alt="dash" />
                            </div>
                          }
                        </div>
                      </>}
                      <div className="speed_val">{marker.currentInfo.status === "DISCONNECTED" ? '0' : Math.round(marker.currentInfo.gpsSpeed)}<div className="speed_units">kmph</div></div>
                      <div className="rpm_val">{marker.currentInfo.status === "DISCONNECTED" ? '0' : Math.round(marker.currentInfo.engineSpeed)}<div className="rpm_units">rpm</div></div>
                      <div className="speed1">0</div>
                      <div className="speed2">10</div>
                      <div className="speed3">20</div>
                      <div className="speed4">30</div>
                      <div className="speed5">40</div>
                      <div className="speed6">60</div>
                      <div className="speed7">80</div>
                      <div className="speed8">100</div>
                      {showGearUI(marker.isBS6) && <>
                        <div className="geartxt">GEAR</div>
                        <div className="gearmarker"><img src="images/Vehicle_Dashboard/Gear_marker.png" alt="dash" /></div>
                        <div className="gearimg">
                          {gearvalue - 3 > 0 ? gearvalue - 4 === 0 ? <span className="gearn">N</span> : gearvalue - 3 <= 4 ? <span className="gearn">{gearvalue - 4}</span> : <span className="gearn">{gearvalue - 4}</span> : <span className="gearn clearvals">N</span>}
                          {gearvalue - 3 >= 0 ? gearvalue - 3 === 0 ? <span className="gear1">N</span> : gearvalue <= 5 ? <span className="gear1">{gearvalue - 3}</span> : <span className="gear1">{gearvalue - 3}</span> : <span className="gear1 clearvals">N</span>}
                          {gearvalue - 2 >= 0 ? gearvalue - 2 === 0 ? <span className="gear2">N</span> : gearvalue <= 5 ? <span className="gear2">{gearvalue - 2}</span> : <span className="gear2">{gearvalue - 2}</span> : <span className="gear2 clearvals">N</span>}
                          {gearvalue - 1 >= 0 ? gearvalue - 1 === 0 ? <span className="gear3">N</span> : gearvalue <= 5 ? <span className="gear3">{gearvalue - 1}</span> : <span className="gear3">{gearvalue - 1}</span> : <span className="gear3 clearvals">N</span>}
                          <span className="gear4">{gearvalue}</span>
                          {gearvalue === "R" ? <span className="gear5 ">N</span> : gearvalue === "N" ? <span className="gear5 ">1</span> : gearvalue >= 14 ? <span className="gear5 clearvals">N</span> : <span className="gear5">{gearvalue + 1}</span>}
                          {gearvalue === "R" ? <span className="gear6 ">1</span> : gearvalue === "N" ? <span className="gear6">2</span> : gearvalue >= 14 ? <span className="gear6 clearvals">N</span> : <span className="gear6 ">{gearvalue + 2}</span>}
                          {gearvalue === "R" ? <span className="gear7 ">2</span> : gearvalue === "N" ? <span className="gear7">3</span> : gearvalue >= 14 ? <span className="gear7 clearvals">N</span> : <span className="gear7 ">{gearvalue + 3}</span>}
                          {gearvalue === "R" ? <span className="gear8 ">3</span> : gearvalue === "N" ? <span className="gear8">4</span> : gearvalue >= 14 ? <span className="gear8 clearvals">N</span> : <span className="gear8 ">{gearvalue + 4}</span>}
                        </div>
                      </>}
                      <div className="moreinfotext" menuclickid="1,2" onClick={(e) => props.moreinfovalue("vehicleInfo/trend", e)}>MORE INFO</div>
                      <div className="closetext" onClick={() => props.closedashboardbox()}>CLOSE</div>
                      <img src="images/Vehicle_Dashboard/layoutdash.png" alt="dash" />

                    </div>

                  </OverlayView>)}
                {props.selectedMarker.obuId === marker.obuId && props.dialviews === true && (
                  <OverlayView
                    position={{
                      lat: marker.currentInfo.latitude,
                      lng: marker.currentInfo.longitude
                    }}
                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    getPixelPositionOffset={getPixelPositionOffset}
                  >
                    <div>
                      <div className="selected-highlight">
                        {" "}
                        <img
                          src="images/Map_screen/Dial_icons/Vehicle_highlighter.png"
                          alt="dial"

                        />{" "}
                      </div>
                      <div className="dial-align">
                        <img
                          src="images/Map_screen/Dial.png"
                          alt="dial"

                        />
                        <img className="clsbg" onClick={props.tocheckonclickmap}
                          src="images/Map_screen/cls.png"
                          alt="dial"

                        />
                        <img className="clsicon" onClick={props.tocheckonclickmap}
                          src="images/Map_screen/close.png"
                          alt="dial"

                        />
                        {props.children}
                        <div className="innercircle"></div>
                      </div>
                    </div>
                  </OverlayView>
                )}
                {/* {props.selectedMarker === marker && <InfoWindow onCloseClick={onClick}>
          <div className="circle"><NavLink link='/vehicleInfo/trace' ><input type="button" value="Info Details" /> </NavLink> </div>
          </InfoWindow>} */}
              </Marker>

            );

          })}
      </MarkerClusterer>
    </GoogleMap>
  ) : (
    <GoogleMap defaultZoom={4}
      center={{ lat: 20.5937, lng: 78.9629 }}
    //defaultCenter={{ lat: 20.5937, lng: 78.9629 }}
    >
    </GoogleMap>
  );
});

class gmapsApp extends React.PureComponent {
  constructor(props) {
    super(props);
    globalprops = this.props;
    this.selectorRef = React.createRef("ref");
    this.state = {
      classicEditIndex: '',
      autoreload: false,
      geofencenametog: "Enable",
      listview_loader: true,
      nogpscountvals: '',
      temp_column: {
        vehicle_odometer: true,
        gps_speed: true,
        engine_speed: true,
        fuel_level: true,
        duration: true,
        air_pressure: false,
        oil_pressure: false,
        vehicle_battery_potentials: false,
        fuel_consum: false,
        altitude: false,
        vin_number: false,
        obu_id: false,
        vehicle_model: false,
        vehicle_type: false,
        coolant_temp: false,
        sub_valid_from: false,
        sub_valid_to: false,
        is_bs6: false,
        catalyst_tank_level: false,
        adblue_tank_capacity: false,
        driver_name: false,
        engine_hours: false,
        exhaust_temperature: false,
      },
      classic_view: true,
      refreshItem: true,
      aft1_exhaust_temp_1: false,
      showIconDropDown: false,
      showFilterDropDown: false,
      dropiconValue: 'desc',
      selectedSortValue: 'TimeStamp',
      classicSortColumns: [],
      showShareDialog: false,
      showCopyLinkModelDialog: false,
      link: "",
      shareVehicleDetails: "",
      editedRegNo: '',
      editIndex: '',
      changeRegNo: '',
      all_latitude: [],
      all_longtitude: [],
      showMessage: "",
      showAlertBox: false,
      messageType: "e",
      sendAllVehicleData: []
    }
    this.handleClickpage = this.handleClickpage.bind(this);
    this.classicSortingColums = sortingColumsClassicView;
    this.showModalDialog = this.showModalDialog.bind(this);
    this.hideModalDialog = this.hideModalDialog.bind(this);
    this.showCopyLinkModelDialog = this.showCopyLinkModelDialog.bind(this);
    this.hideCopyLinkModelDialog = this.hideCopyLinkModelDialog.bind(this);
    this.updateClassicEditIndex = this.updateClassicEditIndex.bind(this)
    // this.state.classicSortColumns = this.classicSortingColums;
    // this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    this.setState({ display: "none", })
    var currentdatecheck = new Date();
    var currentdateyesterday = currentdatecheck.setDate(currentdatecheck.getDate() - 1);
    var findmilliseconds = new Date(currentdateyesterday);
    this.setState({ ttldatetcompare: findmilliseconds })
    if (this.props.searchresult !== null) {
      if (this.props.searchresult.data.length === 0) {
        this.props.gmapsDispatch({ searchresultdataval: [] })
        this.callvehiclesdetails()
      } else {
        let searchobulist = []
        for (var i = 0; i < this.props.searchresult.data.length; i++) {
          searchobulist.push(this.props.searchresult.data[i].obu_id)
        }
        this.props.gmapsDispatch({ searchresultdataval: searchobulist })
        this.callvehiclessearchresult(searchobulist)
      }
    } else {
      this.props.gmapsDispatch({ searchresultdataval: [] })
      this.callvehiclesdetails()
    }
    this.props.gmapsDispatch({ dashenable: false, dialviewshow: false });
    this.props.gmapsDispatch({ selectedMarker: "" })
    this.settimeoutfn();

    this.selectedlistview = false;
    this.hmautoreset = false;
    let dataObj = {};
    dataObj.userid = this.props.ctr.userid;
    let vehtokens = this.props.ctr.token;
    const datas = postData(apilist.loadlistviewdetails, dataObj, vehtokens);
    datas.then(dataVals => {
      if (dataVals.data && dataVals.data.length) {
        this.setState({
          temp_column: {
            vehicle_odometer: dataVals.data[0].vehicle_odometer,
            gps_speed: dataVals.data[0].gps_speed,
            engine_speed: dataVals.data[0].engine_speed,
            fuel_level: dataVals.data[0].fuel_level,
            duration: dataVals.data[0].duration,
            air_pressure: dataVals.data[0].air_pressure,
            oil_pressure: dataVals.data[0].oil_pressure,
            vehicle_battery_potentials: dataVals.data[0].vehicle_battery_potentials,
            fuel_consum: dataVals.data[0].fuel_consumption,
            altitude: dataVals.data[0].altitude,
            vin_number: dataVals.data[0].vin_number,
            obu_id: dataVals.data[0].obu_id,
            vehicle_model: dataVals.data[0].vehicle_model,
            vehicle_type: dataVals.data[0].vehicle_type,
            coolant_temp: dataVals.data[0].coolant_temp,
            sub_valid_from: dataVals.data[0].sub_valid_from,
            sub_valid_to: dataVals.data[0].sub_valid_to,
            is_bs6: dataVals.data[0].is_bs6,
            catalyst_tank_level: dataVals.data[0].catalyst_tank_level,
            adblue_tank_capacity: dataVals.data[0].adblue_tank_capacity,
            driver_name: dataVals.data[0].driver_name,
            engine_hours: dataVals.data[0].engine_hours,
            exhaust_temperature: dataVals.data[0].exhaust_temperature,
          }
        });

        this.props.gmapsDispatch({
          column: {
            vehicle_odometer: dataVals.data[0].vehicle_odometer,
            gps_speed: dataVals.data[0].gps_speed,
            engine_speed: dataVals.data[0].engine_speed,
            fuel_level: dataVals.data[0].fuel_level,
            duration: dataVals.data[0].duration,
            air_pressure: dataVals.data[0].air_pressure,
            oil_pressure: dataVals.data[0].oil_pressure,
            vehicle_battery_potentials: dataVals.data[0].vehicle_battery_potentials,
            fuel_consum: dataVals.data[0].fuel_consumption,
            altitude: dataVals.data[0].altitude,
            vin_number: dataVals.data[0].vin_number,
            obu_id: dataVals.data[0].obu_id,
            vehicle_model: dataVals.data[0].vehicle_model,
            vehicle_type: dataVals.data[0].vehicle_type,
            coolant_temp: dataVals.data[0].coolant_temp,
            sub_valid_from: dataVals.data[0].sub_valid_from,
            sub_valid_to: dataVals.data[0].sub_valid_to,
            is_bs6: dataVals.data[0].is_bs6,
            catalyst_tank_level: dataVals.data[0].catalyst_tank_level,
            adblue_tank_capacity: dataVals.data[0].adblue_tank_capacity,
            driver_name: dataVals.data[0].driver_name,
            engine_hours: dataVals.data[0].engine_hours,
            exhaust_temperature: dataVals.data[0].exhaust_temperature,
          }
        });
      } else {
        this.props.gmapsDispatch({
          column: {
            vehicle_odometer: true,
            gps_speed: true,
            engine_speed: true,
            fuel_level: true,
            duration: false,
            air_pressure: false,
            oil_pressure: false,
            vehicle_battery_potentials: false,
            fuel_consum: false,
            altitude: false,
            vin_number: false,
            obu_id: false,
            vehicle_model: false,
            vehicle_type: false,
            coolant_temp: false,
            sub_valid_from: false,
            sub_valid_to: false,
            is_bs6: false,
            catalyst_tank_level: false,
            adblue_tank_capacity: false,
            driver_name: false,
            engine_hours: false,
            exhaust_temperature: false
          }
        });

        this.setState({
          temp_column: {
            vehicle_odometer: true,
            gps_speed: true,
            engine_speed: true,
            fuel_level: true,
            duration: true,
            air_pressure: false,
            oil_pressure: false,
            vehicle_battery_potentials: false,
            fuel_consum: false,
            altitude: false,
            vin_number: false,
            obu_id: false,
            vehicle_model: false,
            vehicle_type: false,
            coolant_temp: false,
            sub_valid_from: false,
            sub_valid_to: false,
            is_bs6: false,
            catalyst_tank_level: false,
            adblue_tank_capacity: false,
            driver_name: false,
            engine_hours: false,
            aft1_exhaust_temp_1: false
          }
        });
      }
    });
    document.addEventListener('mousedown', this.handleClickOutside);
  }
  updateClassicEditIndex = (newIndex) => {
    this.setState({ classicEditIndex: newIndex })
  }
  callgeofencenamefn = () => {
    this.setState({ listview_loader: true });
    if (this.state.autoreload === false) {
      let vehtokens = this.props.ctr.token;
      const callgeoname = postData(apilist.getGeofenceNameForListView, null, vehtokens);
      callgeoname.then(dataVals => {
        let temp = dataVals.data;
        this.setState({ listview_loader: false });
        let obuidlist = this.props.gmapsState.totalmarkers;
        var altresult = [];
        let objs = null;
        for (var prop in temp)
          altresult.push(temp[prop]);
        altresult.forEach(function (elam) {
          objs = obuidlist.filter(function (en) {
            return en.vinNumber === elam.vin_number;
          })
          if (objs[0] !== undefined) {
            objs[0].geofencenames = elam.geofence_name
          }
        })
        this.setState({ autoreload: true, geofencenametog: "Disable" })
        this.props.gmapsDispatch({ totalmarkers: obuidlist })
      })

    } else {
      this.setState({ listview_loader: false });
      this.setState({ autoreload: false, geofencenametog: "Enable" })
    }



  }

  listViewSwither = () => {
    this.props.gmapsDispatch({
      sort: {
        column_name: 'localTimeStamp',
        order: 'asc'
      }
    }).then(() => {
      this.sort_by_date('localTimeStamp');
      this.setState({
        classic_view: this.state.classic_view ? false : true, refreshItem: true,
        showIconDropDown: false,
        showFilterDropDown: false,
        dropiconValue: 'asc',
        selectedSortValue: 'TimeStamp'
      });
    })

  }

  callgeofencenamefnrefresh = () => {
    if (this.state.autoreload === true) {
      this.setState({ listview_loader: true });
      let vehtokens = this.props.ctr.token;
      const callgeoname = postData(apilist.getGeofenceNameForListView, null, vehtokens);
      callgeoname.then(dataVals => {
        let temp = dataVals.data;
        this.setState({ listview_loader: false });
        let obuidlist = this.props.gmapsState.totalmarkers;
        var altresult = [];
        let objs = null;
        for (var prop in temp)
          altresult.push(temp[prop]);
        altresult.forEach(function (elam) {
          objs = obuidlist.filter(function (en) {
            return en.vinNumber === elam.vin_number;
          })
          if (objs[0] !== undefined) {
            objs[0].geofencenames = elam.geofence_name
          }
        })
        //this.setState({autoreload:true,geofencenametog:"Disable"})
        this.props.gmapsDispatch({ totalmarkers: obuidlist })
      })
    }
  }
  getnogpsalert = () => {
    let obuidlist = this.props.gmapsState.totalmarkers;
    let Totime = new Date().getTime();
    let fromtime = Totime - (144 * 60 * 60 * 1000);
    let Totimeft = new Date(Totime);
    let Totimeftmonth = Totimeft.getMonth() + 1;
    let Totimeftdate = Totimeft.getDate();
    let Totimeftyear = Totimeft.getFullYear();
    let formattodate = Totimeftyear + "-" + Totimeftmonth + "-" + Totimeftdate;
    let fromtimeft = new Date(fromtime);
    let fromtimeftmonth = fromtimeft.getMonth() + 1;
    let fromtimeftdate = fromtimeft.getDate();
    let fromtimeftyear = fromtimeft.getFullYear();
    let formatfromdate =
      fromtimeftyear + "-" + fromtimeftmonth + "-" + fromtimeftdate;

    let nogpsobj = {}
    nogpsobj.platform = "w"
    nogpsobj.fromDate = formatfromdate
    nogpsobj.toDate = formattodate;
    if (this.props.gmapsState.searchresultdataval.length === 0) {
      nogpsobj.vinList = []
    } else {
      nogpsobj.vinList = this.props.gmapsState.searchvechiclelist
    }

    let vehtokens = this.props.ctr.token;
    const nogpsdatas = postData(apilist.getNoGPSAlertStatus, nogpsobj, vehtokens);
    nogpsdatas.then(dataVals => {
      this.props.gmapsDispatch({ nogpscountvals: dataVals.data.alert_count.NO_GPS })
      //this.setState({nogpscountvals:dataVals.data.alert_count.NO_GPS})
      // this.setState({nogpsdatalisy:dataVals.data.alert_status})
      let temp = {};
      let obj = null;
      let countsvas = 0;
      var gpsdata = dataVals.data.alert_status
      for (var i = 0; i < gpsdata.length; i++) {
        obj = gpsdata[i];
        if (!temp[obj.vin]) {
          temp[obj.vin] = obj;
          if (temp[obj.vin].noseverity === "Not Applicable") {
            temp[obj.vin].criticalcounts = countsvas + 1
          }
        } else {
          if (obj.noseverity === "Not Applicable") {
            temp[obj.vin].criticalcounts = temp[obj.vin].criticalcounts + 1
          }
          temp[obj.vin].nogpsdata = temp[obj.vin].noseverity;
          temp[obj.vin].nogpsalert_type_id = temp[obj.vin].alert_type_id + ',' + obj.alert_type_id;

        }
      }
      var altresult = [];
      for (var prop in temp)
        altresult.push(temp[prop]);
      altresult.forEach(function (elam) {
        let objs = obuidlist.filter(function (en) {
          return en.vinNumber === elam.vin;
        })
        if (objs[0] !== undefined) {
          objs[0].nogpsdata = elam.severity;
          objs[0].nogpsalert_type_id = elam.alert_type_id;
          if ((objs[0].currentInfo.heading >= 337.5) && (objs[0].currentInfo.heading <= 22.5)) { objs[0].icons = "/images/Map_screen/" + elam.severity + "/" + objs[0].currentInfo.status + '/01.png'; }
          else if ((objs[0].currentInfo.heading >= 22.5) && (objs[0].currentInfo.heading <= 67.5)) { objs[0].icons = "/images/Map_screen/" + elam.severity + "/" + objs[0].currentInfo.status + '/02.png'; }
          else if ((objs[0].currentInfo.heading >= 67.5) && (objs[0].currentInfo.heading <= 112.5)) { objs[0].icons = "/images/Map_screen/" + elam.severity + "/" + objs[0].currentInfo.status + '/03.png'; }
          else if ((objs[0].currentInfo.heading >= 112.5) && (objs[0].currentInfo.heading <= 157.5)) { objs[0].icons = "/images/Map_screen/" + elam.severity + "/" + objs[0].currentInfo.status + '/04.png'; }
          else if ((objs[0].currentInfo.heading >= 157.5) && (objs[0].currentInfo.heading <= 202.5)) { objs[0].icons = "/images/Map_screen/" + elam.severity + "/" + objs[0].currentInfo.status + '/05.png'; }
          else if ((objs[0].currentInfo.heading >= 202.5) && (objs[0].currentInfo.heading <= 247.5)) { objs[0].icons = "/images/Map_screen/" + elam.severity + "/" + objs[0].currentInfo.status + '/06.png'; }
          else if ((objs[0].currentInfo.heading >= 247.5) && (objs[0].currentInfo.heading <= 292.5)) { objs[0].icons = "/images/Map_screen/" + elam.severity + "/" + objs[0].currentInfo.status + '/07.png'; }
          else if ((objs[0].currentInfo.heading >= 292.5) && (objs[0].currentInfo.heading <= 337.5)) { objs[0].icons = "/images/Map_screen/" + elam.severity + "/" + objs[0].currentInfo.status + '/08.png'; }
          else { objs[0].icons = "/images/Map_screen/" + elam.severity + "/" + objs[0].currentInfo.status + '/01.png'; }
        }
      })
    })
    // if(this.props.listviewshows !== "showlist"){
    //   if(this.props.gmapsState.refreshselecteddata!==undefined && this.props.gmapsState.selectedMarker ===''){
    //     this.clickstatus(this.props.gmapsState.refreshselecteddata);
    //   }else{
    //     this.props.gmapsDispatch({ Vehiclestatushigh: this.props.gmapsState.refreshselecteddata });
    //   }
    // }else if(this.props.togglevalues === true || this.state.reseton === true){
    //   if(this.props.gmapsState.refreshselecteddata!==undefined && this.props.gmapsState.selectedMarker ===''){
    //     if(this.hmautoreset===true){
    //       this.clickstatus(this.props.gmapsState.refreshselecteddata);
    //     }else{
    //       this.clickstatus("Total");
    //     }

    //   }else{
    //     this.props.gmapsDispatch({ Vehiclestatushigh: this.props.gmapsState.refreshselecteddata });
    //   }
    //   if ((this.props.location.state !== undefined) && (this.props.location.state !== null)) {
    //     if (Object.keys(this.props.location.state)[0] === "Vehiclestatushigh") {
    //       if (this.props.location.state.Vehiclestatushigh !== "Total") {
    //         this.clickstatus(this.props.location.state.Vehiclestatushigh);
    //       }

    //     }
    //   }
    // }else{
    //   //this.clickstatus( "Total")
    //   //this.props.gmapsDispatch({ Vehiclestatushigh: "Total" });
    // }
  }
  groupChange(h, e) {

    var values = h.state.group_list.filter(function (item) {
      return item.group_name == e.target.value
    })

    this.setState({
      groupsval: values[0].group_name
    });
    this.setState({
      groupsvalid: values[0].poi_group_id
    });
    let poiindividualid = {};
    poiindividualid.poiGroupId = values[0].poi_group_id;
    let vehtoken = this.props.ctr.token;
    const datas = postData(apilist.showGeofenceGroupList, poiindividualid, vehtoken);
    datas.then((dataVals) => {
      dataVals.data.map((data) => {
        var latlonglist = data.st_astext.split("(");
        var latlonglistedval = latlonglist[1].split(" ");
        data.poilat = parseFloat(latlonglistedval[0]);
        var latlonglisedonly = latlonglistedval[1].split(")");
        data.poilong = parseFloat(latlonglisedonly[0]);

      });
      this.setState({ totalarraywithpoi: dataVals.data })

    });

  };

  geofenceChange(e) {
    let polygonArray = []
    let circleArray = []
    for (let i = 0; i < e.length; i++) {
      let val = e[i]
      let vitals = this.drawPloygonCircle(val)
      vitals.geofence_name = e[i].geofence_name
      vitals.geofence_index = i
      if (vitals.polygonCircle === "polygon") {
        var latitudes = []
        var longitudes = []
        for (let j = 0; j < vitals.coords.length; j++) {
          latitudes.push(vitals.coords[j].lat)
          longitudes.push(vitals.coords[j].lng)
        }
        var sortedlat = latitudes.sort();
        var sortedlng = longitudes.sort();
        var lowX = sortedlat[0];
        var highX = sortedlat[sortedlat.length - 1];
        var lowy = sortedlng[0];
        var highy = sortedlng[sortedlng.length - 1];
        var centerX = lowX + ((highX - lowX) / 2);
        var centerY = lowy + ((highy - lowy) / 2);
        var polyObj = {}
        polyObj.lat = centerX
        polyObj.lng = centerY
        vitals.center = polyObj

        polygonArray.push(vitals)
      }
      else {
        circleArray.push(vitals)
      }
    }
    this.setState({ polygon: polygonArray, circle: circleArray, polygonbkp: polygonArray, circlebkp: circleArray })
  }

  columnchanges = (h, val) => {
    var polygon = this.state.polygon
    var circle = this.state.circle
    var polygonbkp = this.state.polygonbkp
    var circlebkp = this.state.circlebkp
    if (val.target.checked === true) {
      if (val.target.value === "polygon") {
        let polygonVal = polygonbkp.filter(function (pol) {
          return pol.geofence_name === val.target.name
        })
        polygon.push(polygonVal[0])
      }
      else {
        let circleVal = circlebkp.filter(function (circ) {
          return circ.geofence_name === val.target.name
        })
        circle.push(circleVal[0])
      }
    }
    else {
      if (val.target.value === "polygon") {
        polygon = polygon.filter(function (poly) {
          return poly.geofence_name !== val.target.name
        })
      }
      else {
        circle = circle.filter(function (cir) {
          return cir.geofence_name !== val.target.name
        })
      }
    }
    this.setState({
      polygon: polygon,
      circle: circle,
    })
  }

  selectall = (val) => {
    if (val === "select") {
      this.setState({ polygon: this.state.polygonbkp, circle: this.state.circlebkp })
      let something = document.getElementsByClassName("configure_checkbox_geo")
      for (let i = 0; i < something.length; i++) {
        something[i].checked = true
      }
    }
    else {
      let checkboxes = document.getElementsByClassName("configure_checkbox_geo")
      for (let i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false
      }
      this.setState({ polygon: [], circle: [] })
    }
  }

  drawPloygonCircle = (selVal) => {
    let drawOpt = selVal,
      drawSel = "", getcoords = "", radiusVal = "";
    drawSel = drawOpt.geofence_type || "";
    if (drawOpt.geofence_type === "polygon") {
      let polygonStr = drawOpt.st_astext;
      polygonStr = polygonStr.substring(polygonStr.indexOf('(')).replace('((', '').replace('))', '');
      getcoords = this.getPoints(polygonStr);
    }
    else {
      let circleStr = drawOpt.st_astext, splitCircle, circleSwapCenter;
      radiusVal = drawOpt.radius;
      if (drawOpt.geofence_type === 'circle') {
        splitCircle = circleStr.substring(6, circleStr.length - 1).split(" ");
        circleSwapCenter = "POINT(" + splitCircle[1] + " " + splitCircle[0] + ")";
      }
      getcoords = this.WKTToLatLng(circleSwapCenter);
    }
    var vitals = {}
    vitals.polygonCircle = drawSel
    vitals.coords = getcoords
    vitals.radius = radiusVal
    return vitals
  }

  WKTToLatLng = (pointStr) => {
    if (pointStr != null) {
      let str = pointStr.substring(pointStr.indexOf('(')).replace('(', '').replace(')', '');
      let latLng = str.trim().split(' ');
      return [{
        lat: Number(latLng[0]),
        lng: Number(latLng[latLng.length - 1])
      }];
    }
  }

  setFitBounds = (latlngs) => {
    if (latlngs != null) {
      const bounds = new window.google.maps.LatLngBounds();
      latlngs.map((latlngVal) => {
        bounds.extend(latlngVal);
        return latlngVal;
      });
      // refs.map.fitBounds(bounds);
    }
  }

  getPoints = (str) => {
    let latlngs = str.split(','),
      planCoordinates = [],
      latLng;
    for (let i = 0; i < latlngs.length; i++) {
      let newObj = {};
      let trimedlatlong = latlngs[i].trim()
      latLng = trimedlatlong.split(' ');
      newObj.lat = Number(latLng[0]);
      newObj.lng = Number(latLng[1]);
      planCoordinates.push(newObj);
      //planCoordinates[i] = new google.maps.LatLng(latLng[1], latLng[0]);
    }
    return planCoordinates;
  }

  checkboxgroupChange = (e) => {
    if (e.target.checked === true) {
      this.setState({ showselectedpoires: true })
    } else {
      this.setState({ groupsval: '' })
      this.setState({ totalarraywithpoi: [] })
      this.setState({ showselectedpoires: false })
    }
  }

  checkboxGeofenceChange = (e) => {
    if (e.target.checked === true) {
      this.selectall("select")
      this.setState({ showGeofence: true, display: "block" })
      let something = document.getElementsByClassName("configure_checkbox_geo")
      for (let i = 0; i < something.length; i++) {
        something[i].checked = true
      }
    } else {
      this.setState({ selectedGeofence: '', coords: [], radius: 0, polygon: [], circle: [] })
      // this.setState({geofenceList:[]})
      this.setState({ showGeofence: false, display: "none" })
    }
  }

  checkboxlabelChange = (e) => {
    if (e.target.checked === true) {
      this.setState({ showlabelval: true })
    } else {
      this.setState({ showlabelval: false })
    }
  }

  getgroupData() {
    let vehtoken = this.props.ctr.token;
    const datas = postData(apilist.POIGroupList, null, vehtoken);
    datas.then((dataVals) => {
      this.setState({
        group_list: dataVals.data
      });
    });
  };
  getGeofenceData() {
    let userrole = this.props.ctr.userrole;
    if (userrole === "AL EMPLOYEE" || userrole === "AL SME") {
      this.setState({
        geofenceList: [],
        showGeofenceOption: false
      });
    }
    else {
      let data = {}
      data.platform = "w"
      data.userId = this.props.ctr.userid
      let vehtoken = this.props.ctr.token;
      const datas = postData(apilist.showGeofence, data, vehtoken);
      datas.then((dataVals) => {
        var geofencedataList = dataVals.data.filter(function (val) {
          return val.geofence_type !== "poi"
        })
        geofencedataList.sort(function (a, b) {
          a = a["geofence_name"] !== null ? a["geofence_name"].toLowerCase() : '';
          b = b["geofence_name"] !== null ? b["geofence_name"].toLowerCase() : '';
          let asc = (a < b) ? -1 : (a > b) ? 1 : 0;
          return asc;
        })
        this.geofenceChange(geofencedataList)
        this.setState({
          geofenceList: geofencedataList,
          showGeofenceOption: true
        });
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.settimers);
    document.removeEventListener('mousedown', this.handleClickOutside);
  }
  shouldComponentUpdate(nextProps) {
    if (nextProps.togglerefresh !== "NotRefresh") {
      this.props.onlyrefresh("NotRefresh");
      this.props.gmapsDispatch({ hardrefresh: false })
      this.props.gmapsDispatch({ loader: true });
      this.callvehiclesdetails();
      this.callgeofencenamefnrefresh();
      this.hmautoreset = true;
      this.setState({ reseton: true })
      if (this.props.gmapsState.selectedMarker !== '' && this.props.gmapsState.listview === true && this.props.gmapsState.selectedMarker !== false) {
        this.props.gmapsDispatch({ listview: false })
      } else if (this.props.gmapsState.selectedMarker === '' && this.props.gmapsState.listview === true) {
        this.props.gmapsDispatch({ listview: true })
      } else {
        this.props.setlistviewval(false);
        this.props.gmapsDispatch({ listview: false })
      }
    }
    if (nextProps.setlistview !== undefined) {
      //   if(nextProps.setlistview !== "listviewmenu"){
      //     if(nextProps.setlistview === true){
      //       this.props.setlistviewval("listviewmenu");
      //      this.props.gmapsDispatch({ listview: true })
      //     }else if(nextProps.listviewshows === "showlist" && nextProps.history.location.state.fromNotifications !== null){
      //       this.props.gmapsDispatch({ listview: true })
      //       shownlistview = "notshowlist"
      //       this.props.listviewshown();
      //     }else if(nextProps.history.location.state.fromNotifications === null && nextProps.listviewshows === "notshowlist"){
      //       shownlistview = "notshowlist"
      //       this.props.listviewshown();
      //       this.props.setlistviewval("listviewmenu");
      //       this.props.gmapsDispatch({ listview: false })
      //     }


      // }
      if (this.props.location.state !== undefined && this.props.location.state !== null) {
        if (this.props.location.state.fromNotifications !== undefined && this.props.location.state.fromNotifications !== null) {
          if (nextProps.setlistview !== "listviewmenu") {
            if (nextProps.setlistview === true) {
              this.props.setlistviewval("listviewmenu");
              this.props.gmapsDispatch({ listview: true, dialviewshow: false })
            } else {
              this.props.setlistviewval("listviewmenu");
              this.props.gmapsDispatch({ listview: false })
            }
          }
        }
      }
    }
    return true;
  }

  settimeoutfn = () => {
    this.settimers = 0;
    this.settimers = setInterval(() => {
      if (this.props.togglevalues === true) {
        this.hmautoreset = true;
        // if(this.props.gmapsState.selectedMarker !=''){
        //   this.props.gmapsDispatch({ listview: false })
        // }
        this.props.gmapsDispatch({ loader: true });
        this.callvehiclesdetails();
        this.callgeofencenamefnrefresh();
        if (this.props.gmapsState.selectedMarker !== '' && this.props.gmapsState.listview === true && this.props.gmapsState.selectedMarker !== false) {
          this.props.gmapsDispatch({ listview: false })
        }
      }
    }, 30000)


  }
  callvehiclessearchresult = (obulisted) => {
    this.getgroupData();
    this.getGeofenceData()
    this.props.searchresultbox(this.props.location.pathname);
    let dataObj = {};
    dataObj.userId = this.props.ctr.userid;
    let vehtokens = this.props.ctr.token;
    dataObj.platform = "w";
    dataObj.obuList = obulisted;
    const datas = postData(apilist.vehiclecount, dataObj, vehtokens);
    datas.then(dataVals => {
      this.props.gmapsDispatch({
        vehCount: dataVals.data,
        mastervehCount: dataVals.data
      });
    });
    this.props.gmapsDispatch({ markers: [] });
    if (this.props.location.state !== undefined && this.props.location.state !== null) {
      if (Object.keys(this.props.location.state)[0] === "Vehiclestatushigh") {
        this.props.gmapsDispatch({ listview: false })
        shownlistview = "showlisted"
        this.props.listviewshown();
        this.props.gmapsDispatch({ Vehiclestatushigh: this.props.location.state.Vehiclestatushigh })
      } else if (Object.keys(this.props.location.state)[0].trim() === "fromNotifications") {
        shownlistview = "showlist"
        this.props.listviewshown();
        this.props.setlistviewval(true);
        if (this.props.gmapsState.listview === "") {
          this.props.gmapsDispatch({ listview: this.props.location.state.fromNotifications });
        } else if (this.props.gmapsState.selectedMarker !== '' && this.props.gmapsState.listview === false) {
          this.props.gmapsDispatch({ listview: false })
        }
        else {
          this.props.gmapsDispatch({ listview: this.props.location.state.fromNotifications });
        }

        if (this.selectedlistview === undefined) {
          this.selectedlistview = false;
        } else {
          this.selectedlistview = true;
        }
        if (this.props.gmapsState.listview === false) {
          if (this.selectedlistview !== true) {
            this.props.gmapsDispatch({ selectedMarker: false })
          }
        }

      }

    } else {
      shownlistview = "showlist"
      this.props.listviewshown();
      this.props.gmapsDispatch({ listview: false, Vehiclestatushigh: "Total" })
    }
    if (this.props.gmapsState.selectedMarker !== '' && this.props.gmapsState.selectedMarker !== false && this.props.togglevalues !== false && this.props.togglerefresh !== "NotRefresh") {
      this.props.gmapsDispatch({ listview: false })
    } else if (this.props.location.state !== undefined && this.props.location.state !== null) {
      if (this.props.gmapsState.listview === false) {
        if (this.selectedlistview === true) {
          this.props.gmapsDispatch({ listview: false })
        }
      }

    }
    let vehlist = {};
    vehlist.userId = this.props.ctr.userid;
    if (this.props.ctr.userid === undefined) {
      vehlist.userId = "";
    }
    vehlist.userRole = this.props.ctr.userrole;
    vehlist.obuIdList = obulisted;
    let vehtoken = this.props.ctr.token;
    vehlist.platform = "w";
    this.props.gmapsDispatch({ loader: true });
    const response = postData(apilist.allvehicledata, vehlist, vehtoken); //"http://10.18.1.128:7000/api/map/ownVehicleList"
    response.then((datas) => {
      if (datas.statuscode === 200) {
        this.props.gmapsDispatch({ markers: datas.data })
        this.assigningIcons(datas);
        if (this.props.listviewshows !== "showlist") {
          if (this.props.gmapsState.refreshselecteddata !== undefined && this.props.gmapsState.selectedMarker === '') {
            this.clickstatus(this.props.gmapsState.refreshselecteddata);
          } else {
            this.props.gmapsDispatch({ Vehiclestatushigh: this.props.gmapsState.refreshselecteddata });
            this.sortingResposneData(datas.data);
          }
          if ((this.props.location.state !== undefined) && (this.props.location.state !== null)) {
            if (Object.keys(this.props.location.state)[0] === "Vehiclestatushigh") {
              if (this.props.location.state.Vehiclestatushigh !== "Total") {
                this.clickstatus(this.props.location.state.Vehiclestatushigh);
              }

            }
          }
        } else if (this.props.togglevalues === true || this.state.reseton === true) {
          if (this.props.gmapsState.refreshselecteddata !== undefined && this.props.gmapsState.selectedMarker === '') {
            if (this.hmautoreset === true) {
              this.clickstatus(this.props.gmapsState.refreshselecteddata);
            } else {
              this.clickstatus("Total");
            }
          } else {
            this.props.gmapsDispatch({ Vehiclestatushigh: this.props.gmapsState.refreshselecteddata });
            this.sortingResposneData(datas.data);
          }
          if ((this.props.location.state !== undefined) && (this.props.location.state !== null)) {
            if (Object.keys(this.props.location.state)[0] === "Vehiclestatushigh") {
              if (this.props.location.state.Vehiclestatushigh !== "Total") {
                this.clickstatus(this.props.location.state.Vehiclestatushigh);
              }

            }
          }
        }
        else {
          this.props.gmapsDispatch({ Vehiclestatushigh: "Total" });
          this.sortingResposneData(datas.data);
        }
      } else {
        alert('No Data available-2');
        this.setState({ listview_loader: false });
        this.props.gmapsDispatch({ loader: false });
        this.assigningIcons(datas);
      }
    });

  }
  callvehiclesdetails = () => {
    this.setState({ listview_loader: true });
    this.getgroupData();
    this.getGeofenceData();
    this.props.searchresultbox(this.props.location.pathname);

    let dataObj = {};
    dataObj.userId = this.props.ctr.userid;
    let vehtokens = this.props.ctr.token;
    dataObj.platform = "w";
    dataObj.obuList = this.props.gmapsState.searchresultdataval;
    const datas = postData(apilist.vehiclecount, dataObj, vehtokens);
    datas.then(dataVals => {
      this.props.gmapsDispatch({
        vehCount: dataVals.data,
        mastervehCount: dataVals.data
      });
    });
    // this.props.gmapsDispatch({ markers: [] });
    if (this.props.location.state !== undefined && this.props.location.state !== null) {
      if (Object.keys(this.props.location.state)[0] === "Vehiclestatushigh") {
        this.props.gmapsDispatch({ listview: false })
        shownlistview = "showlisted"
        this.props.listviewshown();
        this.props.gmapsDispatch({ Vehiclestatushigh: this.props.location.state.Vehiclestatushigh })
      } else if (Object.keys(this.props.location.state)[0].trim() === "fromNotifications") {
        shownlistview = "showlist"
        this.props.listviewshown();
        this.props.setlistviewval(true);
        if (this.props.gmapsState.listview === "") {
          this.props.gmapsDispatch({ listview: this.props.location.state.fromNotifications });
        } else if (this.props.gmapsState.selectedMarker !== '' && this.props.gmapsState.listview === false) {
          this.props.gmapsDispatch({ listview: false })
        }
        else {
          this.props.gmapsDispatch({ listview: this.props.location.state.fromNotifications });
        }

        if (this.selectedlistview === undefined) {
          this.selectedlistview = false;
        } else {
          this.selectedlistview = true;
        }
        if (this.props.gmapsState.listview === false) {
          if (this.selectedlistview !== true) {
            this.props.gmapsDispatch({ selectedMarker: false })
          }
        }

      }

    } else {
      shownlistview = "showlist"
      this.props.listviewshown();
      this.props.gmapsDispatch({ listview: false, Vehiclestatushigh: "Total" })
    }
    if (this.props.gmapsState.selectedMarker !== '' && this.props.gmapsState.selectedMarker !== false && this.props.togglevalues !== false && this.props.togglerefresh !== "NotRefresh") {
      this.props.gmapsDispatch({ listview: false })
    } else if (this.props.location.state !== undefined && this.props.location.state !== null) {
      if (this.props.gmapsState.listview === false) {
        if (this.selectedlistview === true) {
          this.props.gmapsDispatch({ listview: false })
        }
      }

    }
    let vehlist = {};
    vehlist.userId = this.props.ctr.userid;
    if (this.props.ctr.userid === undefined) {
      vehlist.userId = "";
    }
    vehlist.userRole = this.props.ctr.userrole;
    vehlist.obuIdList = this.props.gmapsState.searchresultdataval;
    let vehtoken = this.props.ctr.token;
    vehlist.platform = "w";
    this.props.gmapsDispatch({ loader: true });
    const response = postData(apilist.allvehicledata, vehlist, vehtoken); //"http://10.18.1.128:7000/api/map/ownVehicleList"
    response.then((datas) => {
      if (datas.statuscode === 200) {
        this.props.gmapsDispatch({ markers: datas.data })
        this.assigningIcons(datas);
        this.setState({
          sendAllVehicleData: datas.data
        })
        if (this.props.listviewshows !== "showlist") {
          if (this.props.gmapsState.refreshselecteddata !== undefined && this.props.gmapsState.selectedMarker === '') {
            this.clickstatus(this.props.gmapsState.refreshselecteddata);
          } else {
            this.props.gmapsDispatch({ Vehiclestatushigh: this.props.gmapsState.refreshselecteddata });
            this.sortingResposneData(datas.data);
          }
          if ((this.props.location.state !== undefined) && (this.props.location.state !== null)) {
            if (Object.keys(this.props.location.state)[0] === "Vehiclestatushigh") {
              if (this.props.location.state.Vehiclestatushigh !== "Total") {
                this.clickstatus(this.props.location.state.Vehiclestatushigh);
              }

            }
          }
        } else if (this.props.togglevalues === true || this.state.reseton === true) {
          if (this.props.gmapsState.refreshselecteddata !== undefined && this.props.gmapsState.selectedMarker === '') {
            if (this.hmautoreset === true) {
              this.clickstatus(this.props.gmapsState.refreshselecteddata);
            } else {
              this.clickstatus("Total");
            }
          } else {
            this.props.gmapsDispatch({ Vehiclestatushigh: this.props.gmapsState.refreshselecteddata });
            this.sortingResposneData(datas.data);
          }
          if ((this.props.location.state !== undefined) && (this.props.location.state !== null)) {
            if (Object.keys(this.props.location.state)[0] === "Vehiclestatushigh") {
              if (this.props.location.state.Vehiclestatushigh !== "Total") {
                this.clickstatus(this.props.location.state.Vehiclestatushigh);
              }

            }
          }
        }
        else {
          this.props.gmapsDispatch({ Vehiclestatushigh: "Total" });
          this.sortingResposneData(datas.data);
        }
      } else {
        alert('No Data available-2');
        this.setState({ listview_loader: false });
        this.props.gmapsDispatch({ loader: false });
        this.assigningIcons(datas);
      }
    });
  }

  // callBackFunc(datas){
  //   if(this.props.listviewshows !== "showlist"){
  //     if(this.props.gmapsState.refreshselecteddata!==undefined && this.props.gmapsState.selectedMarker ===''){
  //       this.clickstatus(this.props.gmapsState.refreshselecteddata);
  //     }else{
  //       this.props.gmapsDispatch({ Vehiclestatushigh: this.props.gmapsState.refreshselecteddata });
  //       this.sortingResposneData(datas.data);
  //     }
  //     if ((this.props.location.state !== undefined) && (this.props.location.state !== null)) {
  //       if (Object.keys(this.props.location.state)[0] === "Vehiclestatushigh") {
  //         if (this.props.location.state.Vehiclestatushigh !== "Total") {
  //           this.clickstatus(this.props.location.state.Vehiclestatushigh);
  //         }

  //       }
  //     }
  //   }else if(this.props.togglevalues === true || this.state.reseton === true){
  //     if(this.props.gmapsState.refreshselecteddata!==undefined && this.props.gmapsState.selectedMarker ===''){
  //       if(this.hmautoreset===true){
  //         this.clickstatus(this.props.gmapsState.refreshselecteddata);
  //       }else{
  //         this.clickstatus("Total");
  //       }
  //     }else{
  //       this.props.gmapsDispatch({ Vehiclestatushigh: this.props.gmapsState.refreshselecteddata });
  //       this.sortingResposneData(datas.data);
  //     }
  //     if ((this.props.location.state !== undefined) && (this.props.location.state !== null)) {
  //       if (Object.keys(this.props.location.state)[0] === "Vehiclestatushigh") {
  //         if (this.props.location.state.Vehiclestatushigh !== "Total") {
  //           this.clickstatus(this.props.location.state.Vehiclestatushigh);
  //         }

  //       }
  //     }
  //   }
  //     else{
  //     this.props.gmapsDispatch({ Vehiclestatushigh: "Total" });
  //     this.sortingResposneData(datas.data);
  //   }
  // }


  assigningIcons(datas) {
    datas.data.map((data) => {
      if (
        data.currentInfo.heading >= 337.5 &&
        data.currentInfo.heading <= 22.5
      ) {
        data.icons =
          "/images/Map_screen/" + data.currentInfo.status + "/01.png";
      } else if (
        data.currentInfo.heading >= 22.5 &&
        data.currentInfo.heading <= 67.5
      ) {
        data.icons =
          "/images/Map_screen/" + data.currentInfo.status + "/02.png";
      } else if (
        data.currentInfo.heading >= 67.5 &&
        data.currentInfo.heading <= 112.5
      ) {
        data.icons =
          "/images/Map_screen/" + data.currentInfo.status + "/03.png";
      } else if (
        data.currentInfo.heading >= 112.5 &&
        data.currentInfo.heading <= 157.5
      ) {
        data.icons =
          "/images/Map_screen/" + data.currentInfo.status + "/04.png";
      } else if (
        data.currentInfo.heading >= 157.5 &&
        data.currentInfo.heading <= 202.5
      ) {
        data.icons =
          "/images/Map_screen/" + data.currentInfo.status + "/05.png";
      } else if (
        data.currentInfo.heading >= 202.5 &&
        data.currentInfo.heading <= 247.5
      ) {
        data.icons =
          "/images/Map_screen/" + data.currentInfo.status + "/06.png";
      } else if (
        data.currentInfo.heading >= 247.5 &&
        data.currentInfo.heading <= 292.5
      ) {
        data.icons =
          "/images/Map_screen/" + data.currentInfo.status + "/07.png";
      } else if (
        data.currentInfo.heading >= 292.5 &&
        data.currentInfo.heading <= 337.5
      ) {
        data.icons =
          "/images/Map_screen/" + data.currentInfo.status + "/08.png";
      } else {
        data.icons =
          "/images/Map_screen/" + data.currentInfo.status + "/01.png";
      }
      return data;
    });

    this.props.gmapsDispatch({
      // markers: datas.data,
      sort: {
        column_name: "localTimeStamp",
        order: "desc",
      },
      totalmarkers: datas.data,
      excel_data: datas.data,
      searchresultdata: datas.data,
      previous_markers: datas.data,
    }).then(() => {
      this.setState({ listview_loader: false });
      this.props.gmapsDispatch({ loader: false });
      //this.zoomToMarkersVal(this.props.gmapsState);
      this.vehiclealerts(this.props.gmapsState, datas);
      this.getnogpsalert()
    });

  }

  sortingResposneData(datas) {
    if (this.state.classic_view == true) {
      let sortedData = datas.sort(function (a, b) {
        return (
          new Date(b.currentInfo ? b.currentInfo.localTimeStamp : b.localTimeStamp) -
          new Date(a.currentInfo ? a.currentInfo.localTimeStamp : a.localTimeStamp)
        );
      });
      this.props.gmapsDispatch({
        markers: sortedData,
      }).then(() => {
        this.zoomToMarkersVal(this.props.gmapsState);
      });
    } else {
      this.props.gmapsDispatch({
        markers: datas
      }).then(() => {
        this.zoomToMarkersVal(this.props.gmapsState);
        this.props.gmapsDispatch.markers = datas;
        let filterSortvalue = this.classicSortingColums.filter((item) => item.key == this.state.selectedSortValue);
        if (filterSortvalue.length > 0) {
          this.onSortkeySelect(filterSortvalue[0]);
        }
      });
    }
  }

  vehiclealerts = (state, data_from) => {
    let vehalert = {};
    let obuidlist = this.props.gmapsState.totalmarkers;
    let arrayobulist = [];
    obuidlist.map((obuid) => {
      if(obuid.currentInfo.vinNumber !== null){
        arrayobulist.push(obuid.currentInfo.vinNumber);
      }
      return obuidlist
    })


    vehalert.userId = this.props.ctr.userid;
    vehalert.platform = "w"
    vehalert.vinList = arrayobulist;
    let vehtoken = this.props.ctr.token;
    const vehicleAlert = postData(apilist.vehicleAlertdate, vehalert, vehtoken);
    vehicleAlert.then((datas) => {
      let alertdata = datas.data.alert_status;
      let temp = {};
      let obj = null;
      let countsvas = 0;
      //let criticalint;
      for (var i = 0; i < alertdata.length; i++) {
        obj = alertdata[i];
        if (!temp[obj.vin]) {
          temp[obj.vin] = obj;
          if (temp[obj.vin].severity === "Critical") {
            temp[obj.vin].criticalcounts = countsvas + 1
          }
        } else {
          if (obj.severity === "Critical") {
            temp[obj.vin].criticalcounts = temp[obj.vin].criticalcounts + 1
          }
          temp[obj.vin].alert_type_id = temp[obj.vin].alert_type_id + ',' + obj.alert_type_id;

        }
      }
      var altresult = [];
      for (var prop in temp)
        altresult.push(temp[prop]);
      altresult.forEach(function (elam) {
        let objs = obuidlist.filter(function (en) {
          return en.vinNumber === elam.vin;
        })
        if (objs[0] !== undefined) {
          objs[0].severity = elam.severity;
          objs[0].alert_type_id = elam.alert_type_id;
          objs[0].criccnt = elam.criticalcounts;
          if ((objs[0].currentInfo.heading >= 337.5) && (objs[0].currentInfo.heading <= 22.5)) { objs[0].icons = "/images/Map_screen/" + elam.severity + "/" + objs[0].currentInfo.status + '/01.png'; }
          else if ((objs[0].currentInfo.heading >= 22.5) && (objs[0].currentInfo.heading <= 67.5)) { objs[0].icons = "/images/Map_screen/" + elam.severity + "/" + objs[0].currentInfo.status + '/02.png'; }
          else if ((objs[0].currentInfo.heading >= 67.5) && (objs[0].currentInfo.heading <= 112.5)) { objs[0].icons = "/images/Map_screen/" + elam.severity + "/" + objs[0].currentInfo.status + '/03.png'; }
          else if ((objs[0].currentInfo.heading >= 112.5) && (objs[0].currentInfo.heading <= 157.5)) { objs[0].icons = "/images/Map_screen/" + elam.severity + "/" + objs[0].currentInfo.status + '/04.png'; }
          else if ((objs[0].currentInfo.heading >= 157.5) && (objs[0].currentInfo.heading <= 202.5)) { objs[0].icons = "/images/Map_screen/" + elam.severity + "/" + objs[0].currentInfo.status + '/05.png'; }
          else if ((objs[0].currentInfo.heading >= 202.5) && (objs[0].currentInfo.heading <= 247.5)) { objs[0].icons = "/images/Map_screen/" + elam.severity + "/" + objs[0].currentInfo.status + '/06.png'; }
          else if ((objs[0].currentInfo.heading >= 247.5) && (objs[0].currentInfo.heading <= 292.5)) { objs[0].icons = "/images/Map_screen/" + elam.severity + "/" + objs[0].currentInfo.status + '/07.png'; }
          else if ((objs[0].currentInfo.heading >= 292.5) && (objs[0].currentInfo.heading <= 337.5)) { objs[0].icons = "/images/Map_screen/" + elam.severity + "/" + objs[0].currentInfo.status + '/08.png'; }
          else { objs[0].icons = "/images/Map_screen/" + elam.severity + "/" + objs[0].currentInfo.status + '/01.png'; }
        }
      })

      // this.callBackFunc(data_from)
      if (this.props.listviewshows !== "showlist") {
        if (this.props.gmapsState.refreshselecteddata !== undefined && this.props.gmapsState.selectedMarker === '') {
          this.clickstatus(this.props.gmapsState.refreshselecteddata);
        }
        if ((this.props.location.state !== undefined) && (this.props.location.state !== null)) {
          if (Object.keys(this.props.location.state)[0] === "Vehiclestatushigh") {
            if (this.props.location.state.Vehiclestatushigh !== "Total") {
              this.clickstatus(this.props.location.state.Vehiclestatushigh);
            }

          }
        }
      } else if (this.props.togglevalues === true || this.state.reseton === true) {
        if (this.props.gmapsState.refreshselecteddata !== undefined && this.props.gmapsState.selectedMarker === '') {
          if (this.hmautoreset === true) {
            this.clickstatus(this.props.gmapsState.refreshselecteddata);
          } else {
            this.clickstatus("Total");
          }
        }
        if ((this.props.location.state !== undefined) && (this.props.location.state !== null)) {
          if (Object.keys(this.props.location.state)[0] === "Vehiclestatushigh") {
            if (this.props.location.state.Vehiclestatushigh !== "Total") {
              this.clickstatus(this.props.location.state.Vehiclestatushigh);
            }

          }
        }
      }
      else {
        this.props.gmapsDispatch({ Vehiclestatushigh: "Total" });
      }
      this.callvehialert();
    });
  }

  callvehialert = () => {
    let alertcountlist = this.props.gmapsState.vehCount;
    let Totime = new Date().getTime();
    let fromtime = Totime - (144 * 60 * 60 * 1000);
    let Totimeft = new Date(Totime);
    let Totimeftmonth = Totimeft.getMonth() + 1;
    let Totimeftdate = Totimeft.getDate();
    let Totimeftyear = Totimeft.getFullYear();
    let formattodate = Totimeftyear + "-" + Totimeftmonth + "-" + Totimeftdate;
    let fromtimeft = new Date(fromtime);
    let fromtimeftmonth = fromtimeft.getMonth() + 1;
    let fromtimeftdate = fromtimeft.getDate();
    let fromtimeftyear = fromtimeft.getFullYear();
    let formatfromdate =
      fromtimeftyear + "-" + fromtimeftmonth + "-" + fromtimeftdate;

    let dataobj_alert = {};
    dataobj_alert.userId = this.props.ctr.userid;
    let tokens = this.props.ctr.token;
    dataobj_alert.platform = "w";
    dataobj_alert.fromDate = formatfromdate;
    dataobj_alert.toDate = formattodate;
    let vinList = []
    if (this.props.searchresult && this.props.searchresult.data && this.props.searchresult.data.length !== 0) {
      this.props.searchresult && this.props.searchresult.data && this.props.searchresult.data.length &&
        this.props.searchresult.data.map((value) => {
          vinList.push(value.vin_numbers);
        });
    }
    dataobj_alert.vinList = vinList;
    dataobj_alert.isActive = true;
    const alertcnt = postData(apilist.alertCount, dataobj_alert, tokens);
    alertcnt.then(alertdataVals => {
      let countwithalert = { ...alertdataVals.data, ...alertcountlist };
      this.props.gmapsDispatch({
        vehCount: countwithalert
      });
    });
  };

  zoomToMarkersVal = (state) => {
    if (state.selectedMarker === false) {
      if (state.markers.length > 0) {
        if (
          state.selectedMarker !== false &&
          state.selectedMarker !== ""
        ) {
          var lngs = [];
          var lats = [];
          let selectedzoomin = state.selectedMarker.currentInfo;
          lngs.push(selectedzoomin.longitude);
          lats.push(selectedzoomin.latitude);
        } else {
          let markers = state.markers;
          lngs = markers.map(datas => {
            return datas.currentInfo.longitude;
          });
          lats = markers.map(datas => {
            return datas.currentInfo.latitude;
          });
        }

        this.props.gmapsDispatch({
          zoomToMarkers: map => {
            if (map) {
              map.fitBounds({
                west: Math.min.apply(null, lngs),
                east: Math.max.apply(null, lngs),
                north: Math.min.apply(null, lats),
                south: Math.max.apply(null, lats)
              });
            }
          }
        });
      }
    } else {
      this.zoomToMarkersValselected(state);
    }
  };

  zoomToMarkersValselected = (state) => {
    if (state.selectedMarker !== false) {
      for (var i = 0; i < state.markers.length; i++) {
        if (state.selectedMarker.obuId === state.markers[i].obuId) {
          var selectedzoomin = state.markers[i].currentInfo;

          //this.props.gmapsDispatch({ dialviewshow: true });
        }
      }

      if (state.markers.length > 0) {
        if (
          state.selectedMarker !== false &&
          state.selectedMarker !== ""
        ) {
          var lngs = [];
          var lats = [];

          lngs.push(selectedzoomin.longitude);
          lats.push(selectedzoomin.latitude);
        } else {
          let markers = state.markers;
          lngs = markers.map(datas => {
            return datas.currentInfo.longitude;
          });
          lats = markers.map(datas => {
            return datas.currentInfo.latitude;
          });
        }

        if (lngs.length > 0) {
          this.setState({ all_longtitude: lngs, all_latitude: lats })
        }
        this.props.gmapsDispatch({
          zoomToMarkers: map => {
            if (map) {
              if (this.props.gmapsState.dialviewshow === true || this.props.gmapsState.dashenable === true) {
                map.fitBounds({
                  west: Math.min.apply(null, lngs),
                  east: Math.max.apply(null, lngs),
                  north: Math.min.apply(null, lats),
                  south: Math.max.apply(null, lats)
                });
              }
              else {
                map.fitBounds({
                  west: Math.min.apply(null, this.state.all_longtitude),
                  east: Math.max.apply(null, this.state.all_longtitude),
                  north: Math.min.apply(null, this.state.all_latitude),
                  south: Math.max.apply(null, this.state.all_latitude)
                });
              }
            }
          }
        });
      }
    }
  };



  clickstatus = (e) => {
    this.setState({ refreshItem: false });
    this.props.gmapsDispatch({ refreshselecteddata: e });
    let first_page_btn = document.getElementsByClassName('page_symbol_listview')[0];
    if (first_page_btn) {
      first_page_btn.click();
    }

    let vehiclesstatus = [];
    let bool = false;
    // this.props.gmapsDispatch({ vehCount: this.props.gmapsState.mastervehCount });
    this.props.gmapsDispatch({
      selectedMarker: "", dashenable: false, dialviewshow: false,
      zoomToMarkers: map => {
        if (map) {
          map.fitBounds({
            west: Math.min.apply(null, this.state.all_longtitude),
            east: Math.max.apply(null, this.state.all_longtitude),
            north: Math.min.apply(null, this.state.all_latitude),
            south: Math.max.apply(null, this.state.all_latitude)
          })
        }
      }
    });
    let statusvalue = e;
    let totalvehicleslist = this.props.gmapsState.searchresultdata;
    for (var i = 0; i < totalvehicleslist.length; i++) {
      if (totalvehicleslist[i].currentInfo.status === statusvalue) {
        vehiclesstatus.push(totalvehicleslist[i]);
      } else if (statusvalue === "Total") {
        vehiclesstatus.push(totalvehicleslist[i]);
      } else if (totalvehicleslist[i].severity !== undefined) {
        if (totalvehicleslist[i].severity.includes(statusvalue) === true) {
          vehiclesstatus.push(totalvehicleslist[i]);
        }
      } else if (totalvehicleslist[i].nogpsdata !== undefined) {
        if (statusvalue === "NoGPS") {
          if (totalvehicleslist[i].nogpsdata.includes("Not Applicable") === true) {
            vehiclesstatus.push(totalvehicleslist[i]);
          }
        }
      }

    }
    if (statusvalue !== "NoGPS" && statusvalue !== "Critical" && statusvalue !== "Warning" && statusvalue !== "Service") {
      vehiclesstatus.map((data) => {
        if ((data.currentInfo.heading >= 337.5) && (data.currentInfo.heading <= 22.5)) { data.icons = '/images/Map_screen/' + data.currentInfo.status + '/01.png'; }
        else if ((data.currentInfo.heading >= 22.5) && (data.currentInfo.heading <= 67.5)) { data.icons = '/images/Map_screen/' + data.currentInfo.status + '/02.png'; }
        else if ((data.currentInfo.heading >= 67.5) && (data.currentInfo.heading <= 112.5)) { data.icons = '/images/Map_screen/' + data.currentInfo.status + '/03.png'; }
        else if ((data.currentInfo.heading >= 112.5) && (data.currentInfo.heading <= 157.5)) { data.icons = '/images/Map_screen/' + data.currentInfo.status + '/04.png'; }
        else if ((data.currentInfo.heading >= 157.5) && (data.currentInfo.heading <= 202.5)) { data.icons = '/images/Map_screen/' + data.currentInfo.status + '/05.png'; }
        else if ((data.currentInfo.heading >= 202.5) && (data.currentInfo.heading <= 247.5)) { data.icons = '/images/Map_screen/' + data.currentInfo.status + '/06.png'; }
        else if ((data.currentInfo.heading >= 247.5) && (data.currentInfo.heading <= 292.5)) { data.icons = '/images/Map_screen/' + data.currentInfo.status + '/07.png'; }
        else if ((data.currentInfo.heading >= 292.5) && (data.currentInfo.heading <= 337.5)) { data.icons = '/images/Map_screen/' + data.currentInfo.status + '/08.png'; }
        else { data.icons = '/images/Map_screen/' + data.currentInfo.status + '/01.png'; }

        if (data.severity && (data.currentInfo.status === "STOPPED" || data.currentInfo.status === "DISCONNECTED" || data.currentInfo.status === "RUNNING" || data.currentInfo.status === "IDLING")) {
          data.icons = '/images/Map_screen/' + data.severity + '/' + data.currentInfo.status + '/01.png';
        }
        return data;
      })
    } else if (statusvalue === "NoGPS") {
      vehiclesstatus.map((data) => {
        if ((data.currentInfo.heading >= 337.5) && (data.currentInfo.heading <= 22.5)) { data.icons = '/images/Map_screen/' + data.nogpsdata + '/' + data.currentInfo.status + '/01.png'; }
        else if ((data.currentInfo.heading >= 22.5) && (data.currentInfo.heading <= 67.5)) { data.icons = '/images/Map_screen/' + data.nogpsdata + '/' + data.currentInfo.status + '/02.png'; }
        else if ((data.currentInfo.heading >= 67.5) && (data.currentInfo.heading <= 112.5)) { data.icons = '/images/Map_screen/' + data.nogpsdata + '/' + data.currentInfo.status + '/03.png'; }
        else if ((data.currentInfo.heading >= 112.5) && (data.currentInfo.heading <= 157.5)) { data.icons = '/images/Map_screen/' + data.nogpsdata + '/' + data.currentInfo.status + '/04.png'; }
        else if ((data.currentInfo.heading >= 157.5) && (data.currentInfo.heading <= 202.5)) { data.icons = '/images/Map_screen/' + data.nogpsdata + '/' + data.currentInfo.status + '/05.png'; }
        else if ((data.currentInfo.heading >= 202.5) && (data.currentInfo.heading <= 247.5)) { data.icons = '/images/Map_screen/' + data.nogpsdata + '/' + data.currentInfo.status + '/06.png'; }
        else if ((data.currentInfo.heading >= 247.5) && (data.currentInfo.heading <= 292.5)) { data.icons = '/images/Map_screen/' + data.nogpsdata + '/' + data.currentInfo.status + '/07.png'; }
        else if ((data.currentInfo.heading >= 292.5) && (data.currentInfo.heading <= 337.5)) { data.icons = '/images/Map_screen/' + data.nogpsdata + '/' + data.currentInfo.status + '/08.png'; }
        else { data.icons = '/images/Map_screen/' + data.nogpsdata + '/' + data.currentInfo.status + '/01.png'; }
        return data;
      })
    } else if (statusvalue === "Critical" || statusvalue === "Warning" || statusvalue === "Service") {
      vehiclesstatus.map((data) => {
        if ((data.currentInfo.heading >= 337.5) && (data.currentInfo.heading <= 22.5)) { data.icons = '/images/Map_screen/' + data.severity + '/' + data.currentInfo.status + '/01.png'; }
        else if ((data.currentInfo.heading >= 22.5) && (data.currentInfo.heading <= 67.5)) { data.icons = '/images/Map_screen/' + data.severity + '/' + data.currentInfo.status + '/02.png'; }
        else if ((data.currentInfo.heading >= 67.5) && (data.currentInfo.heading <= 112.5)) { data.icons = '/images/Map_screen/' + data.severity + '/' + data.currentInfo.status + '/03.png'; }
        else if ((data.currentInfo.heading >= 112.5) && (data.currentInfo.heading <= 157.5)) { data.icons = '/images/Map_screen/' + data.severity + '/' + data.currentInfo.status + '/04.png'; }
        else if ((data.currentInfo.heading >= 157.5) && (data.currentInfo.heading <= 202.5)) { data.icons = '/images/Map_screen/' + data.severity + '/' + data.currentInfo.status + '/05.png'; }
        else if ((data.currentInfo.heading >= 202.5) && (data.currentInfo.heading <= 247.5)) { data.icons = '/images/Map_screen/' + data.severity + '/' + data.currentInfo.status + '/06.png'; }
        else if ((data.currentInfo.heading >= 247.5) && (data.currentInfo.heading <= 292.5)) { data.icons = '/images/Map_screen/' + data.severity + '/' + data.currentInfo.status + '/07.png'; }
        else if ((data.currentInfo.heading >= 292.5) && (data.currentInfo.heading <= 337.5)) { data.icons = '/images/Map_screen/' + data.severity + '/' + data.currentInfo.status + '/08.png'; }
        else { data.icons = '/images/Map_screen/' + data.severity + '/' + data.currentInfo.status + '/01.png'; }
        return data;
      })
    }
    this.props.gmapsDispatch({ currentPage: 1, Vehiclestatushigh: e });
    let sorted_table_data = Object.assign([], vehiclesstatus);
    this.sortingResposneData(sorted_table_data);

    if (totalvehicleslist && totalvehicleslist.length) {
      bool = true;
    }
    if (vehiclesstatus.length === 0) {
      this.selectall("deselect")
      this.setState({ display: "none" })
      if (!bool) {
        alert("No Data Available-4")
      }
      this.setState({ listview_loader: false })
    }

  };
  clicksearchresult = e => {
    let samvins = e;
    let sampleresults = []
    this.props.gmapsDispatch({
      Vehiclestatushigh: "Total",
      selectedMarker: "",
      dashenable: false,
      dialviewshow: true
    });
    this.props.gmapsState.totalmarkers.forEach(function (elam) {
      var searchvalues = samvins.data.filter(function (en) {
        if (en === elam.regnNumber) {
          sampleresults.push(elam);
        }
        return searchvalues
      })
    })

    let objlist = {};
    objlist.Disconnected = 0;
    objlist.Idling = 0;
    objlist.Running = 0;
    objlist.Stopped = 0;
    objlist.Towed = 0;
    objlist.NoGPS = 0;
    objlist.Total = 0;
    this.props.gmapsDispatch({ markers: sampleresults, searchresultdata: sampleresults });
    sampleresults.forEach(function (it) {
      if (it.currentInfo.status === "DISCONNECTED") {
        objlist.Disconnected = objlist.Disconnected + 1
      } else if (it.currentInfo.status === "IDLING") {
        objlist.Idling = objlist.Idling + 1
      }
      else if (it.currentInfo.status === "STOPPED") {
        objlist.Stopped = objlist.Stopped + 1
      }
      else if (it.currentInfo.status === "RUNNING") {
        objlist.Running = objlist.Running + 1
      }
      else if (it.currentInfo.status === "NoGPS") {
        objlist.NoGPS = objlist.NoGPS + 1
      }
      else if (it.currentInfo.status === "Towed") {
        objlist.Towed = objlist.Towed + 1
      }
      objlist.Total = sampleresults.length;
    })
    this.props.gmapsDispatch({ vehCount: objlist });
  }

  resettheresult = () => {
    this.props.gmapsDispatch({
      Vehiclestatushigh: "Total",
      vehCount: this.props.gmapsState.mastervehCount,
      totalmarkers: this.props.gmapsState.totalmarkers,
      markers: this.props.gmapsState.totalmarkers,
      searchresultdata: this.props.gmapsState.totalmarkers
    })
  }

  handleClick = (marker) => {
    localStorage.setItem("selectedVin", marker.currentInfo.vinNumber)
    if (this.props.gmapsState.dialviewshow === true) {
      this.props.gmapsDispatch({ dashenable: false, dialviewshow: true });
    } else {
      this.props.gmapsDispatch({ dashenable: false, dialviewshow: true });
    }

    this.props.gmapsDispatch({ listview: false });
    let mVal = {};
    mVal.selectedVehicle = marker;
    uservehicledate = mVal;
    this.props.ctrs();
    this.props.gmapsDispatch({
      selectedMarker: marker
    }).then(() => {
      setTimeout(
        () => this.zoomToMarkersValselected(this.props.gmapsState),
        10
      );
    });
  };

  showModalDialog = (vehicleDetails) => {
    this.setState({ showShareDialog: true, shareVehicleDetails: vehicleDetails });
  };

  showCopyLinkModelDialog = () => {
    this.setState({ showCopyLinkModelDialog: true });
  };

  showCopyLinkDialog = (shareLink) => {
    this.setState({ showShareDialog: false, shareVehicleDetails: "" });
    if (shareLink && shareLink != null) {
      this.setState({ link: shareLink });
      this.showCopyLinkModelDialog()
    }
  };
  hideModalDialog = () => {
    this.setState({ showShareDialog: false, shareVehicleDetails: "" });
  };
  hideCopyLinkModelDialog = () => {
    this.setState({ showCopyLinkModelDialog: false });
  };

  handleClickpage(event) {
    this.props.gmapsDispatch({
      currentPage: Number(event.target.id)
    });
    this.props.gmapsDispatch({ paginationcolor: Number(event.target.id) });
    this.refreshnewListView();
  };

  handleChangeforpagi(event) {
    this.props.gmapsDispatch({ currentPage: 1 });
    let pagelist = Number(event.target.value);
    this.props.gmapsDispatch({ todosPerPage: pagelist });
  };

  redirectPage = (a, e) => {
    if (a === "fleetalerts") {
      this.setState({ successmenu: true })
      return this.props.history.push("/" + a);
    }
    var ids = e.target.getAttribute('menuclickid').split(",");
    var menuClickids = []
    menuClickids.push({ mm: ids[0], sm: ids[1], tm: ids[2] });
    this.props.quicklinks(menuClickids);
    if (a === "fleetManagement") {
      this.props.history.push("/" + a, "Registration Update");
    } else if (a === "fleetinsightsrep") {
      this.props.history.push("/" + a, { Fleet: "Fleet" });
    } else {
      this.props.history.push("/" + a);
    }

  };

  callvehiclesrun = () => {
    var lngslist = [];
    var longslist = [];
    if (window.google !== undefined) {
      const DirectionsService = new window.google.maps.DirectionsService();
      this.props.gmapsDispatch({ dashenable: false, dialviewshow: false });
      if (this.props.gmapsState.selectedMarker !== "") {
        var previouslat = this.props.gmapsState.selectedMarker.currentInfo.latitude;
        var previouslong = this.props.gmapsState.selectedMarker.currentInfo.longitude;
        let selectedtrkveh = this.props.gmapsState.selectedMarker.obuId;
        let vehlisttrk = {};
        vehlisttrk.userId = this.props.ctr.userid;
        vehlisttrk.userRole = this.props.ctr.userrole;
        vehlisttrk.obuIdList = [selectedtrkveh];
        let vehtoken = this.props.ctr.token;
        vehlisttrk.platform = "w";
        const response = postData(apilist.allvehicledata, vehlisttrk, vehtoken); //"http://10.18.1.128:7000/api/map/ownVehicleList"
        response.then((datas) => {
          var currentlat = datas.data[0].currentInfo.latitude;
          var currentlong = datas.data[0].currentInfo.longitude;
          if (previouslat !== currentlat && previouslong !== currentlong) {
            DirectionsService.route({
              origin: new window.google.maps.LatLng(previouslat, previouslong),
              destination: new window.google.maps.LatLng(currentlat, currentlong),
              travelMode: window.google.maps.TravelMode.DRIVING,
              optimizeWaypoints: true,
            }, (result, status) => {
              if (status === window.google.maps.DirectionsStatus.OK) {
                var points = result.routes[0].overview_path;
                for (var i = 0; i < points.length; i++) {
                  lngslist.push(points[i].lng());
                  longslist.push(points[i].lat());
                  this.props.gmapsDispatch({
                    zoomToMarkers: map => {
                      if (map) {
                        map.fitBounds({
                          west: Math.min.apply(null, lngslist),
                          east: Math.max.apply(null, lngslist),
                          north: Math.min.apply(null, longslist),
                          south: Math.max.apply(null, longslist)
                        });
                      }
                    }
                  });
                }
              }

            })

            //var latlng1 = new google.maps.LatLng(currentlat,currentlong);
          }
        })
      }
    }
  }

  trackingveh = () => {
    this.settimersrun = 0;
    this.settimersrun = setInterval(() => {
      if (this.props.gmapsState.selectedMarker !== '') {
        this.callvehiclesrun()
      }
    }, 20000)

  }

  closedashboard = () => {
    this.props.gmapsDispatch({ dashenable: false, dialviewshow: true });
  };
  callmapclickfn = () => {
    if (this.props.gmapsState.dialviewshow === true) {
      this.props.gmapsDispatch({ dialviewshow: false });
      if (this.props.gmapsState.dashenable === true) {
        this.props.gmapsDispatch({ dashenable: false, dialviewshow: false });
      }
    }
  }
  changevaluee = () => {
    var a = "city";
    // var a = document.getElementById("cars").value;
    if (document.getElementById("cars") != null) {
      a = document.getElementById("cars").value;
    }


    if (a === "city") {
      return "city";
    } else if (a === "poi") {
      return "POI";
    } else {
      return "POI";
    }


  }

  loadvehdash = () => {
    this.props.gmapsDispatch({ dashenable: true, dialviewshow: false });
  }
  previousPage(e) {
    // let { todosPerPage } = this.props.gmapsState;
    let min = 1;
    let val = Number(this.props.gmapsState.paginationcolor) - 1;
    if (val >= min) {
      this.props.gmapsDispatch({
        currentPage: val
      });
      this.props.gmapsDispatch({ paginationcolor: val });
      this.refreshnewListView();
    }
  };

  nextPage() {
    let { todosPerPage } = this.props.gmapsState;
    let max = (Math.ceil(this.props.gmapsState.markers.length / todosPerPage));
    let val = Number(this.props.gmapsState.paginationcolor) + 1;
    if (val <= max) {
      this.props.gmapsDispatch({
        currentPage: val
      });
      this.props.gmapsDispatch({ paginationcolor: val });
      this.refreshnewListView();
    }
  };

  firstPage() {
    this.props.gmapsDispatch({
      currentPage: 1
    });
    this.props.gmapsDispatch({ paginationcolor: 1 });
    this.refreshnewListView();
  };

  lastPage() {
    let { todosPerPage } = this.props.gmapsState;
    let val = (Math.ceil(this.props.gmapsState.markers.length / todosPerPage));
    this.props.gmapsDispatch({
      currentPage: val
    });
    this.props.gmapsDispatch({ paginationcolor: val });
    this.refreshnewListView();
  };

  refreshnewListView = () => {
    if (this.state.classic_view == false) {
      this.setState({ refreshItem: false }, () => {
        this.setState({
          refreshItem: true
        })
      })
    }
  }

  configure() {
    if (this.props.gmapsState.configureFormState === 'none') {
      this.props.gmapsDispatch({
        configureFormState: 'block'
      });

      let checkboxes = document.getElementsByClassName("configure_checkbox");
      let flag = 0;
      for (let x = 0; x < checkboxes.length; x++) {
        if (checkboxes[x].value === "true") {
          checkboxes[x].checked = true;
          checkboxes[x].disabled = false;
          flag++;
        }
        else {
          checkboxes[x].checked = false;
          checkboxes[x].disabled = true;
        }
      }
      if (flag < 4) {
        for (let x = 0; x < checkboxes.length; x++) {
          checkboxes[x].disabled = false;
        }
      }
    }
  };

  toggleColumns(col_no, col_name) {
    var checkboxes = document.getElementsByClassName("configure_checkbox");
    var checked = document.querySelectorAll('.config_div input[type="checkbox"]:checked').length;
    for (let x = 0; x < checkboxes.length; x++) {
      checkboxes[x].disabled = false;
      if (checked > 3 && checkboxes[x].checked === false) {
        checkboxes[x].disabled = true;
      }
    }

    let fI = this.state.temp_column;
    if (fI[col_name] === true) {
      fI[col_name] = false;
      this.setState({
        temp_column: fI
      });
    } else {
      fI[col_name] = true;
      this.setState({
        temp_column: fI
      });
    }
  };

  close_list_configuration() {
    this.props.gmapsDispatch({
      configureFormState: 'none'
    });
  };

  save_column_config() {
    var checked = document.querySelectorAll('.config_div input[type="checkbox"]:checked').length;
    if (checked <= 0) {
      this.openModel('Please select Maximum of 4 Parameter', "e")
      return false;
    }
    var dataObj = {
      "user_id": this.props.ctr.userid.toString(),
      "location_flag": true,
      "time_stamp": true,

      "vehicle_odometer": this.state.temp_column.vehicle_odometer,
      "gps_speed": this.state.temp_column.gps_speed,
      "engine_speed": this.state.temp_column.engine_speed,
      "fuel_level": this.state.temp_column.fuel_level,
      "duration": this.state.temp_column.duration,
      "air_pressure": this.state.temp_column.air_pressure,
      "oil_pressure": this.state.temp_column.oil_pressure,
      "vehicle_battery_potentials": this.state.temp_column.vehicle_battery_potentials,
      "fuel_consum": this.state.temp_column.fuel_consum,
      "altitude": this.state.temp_column.altitude,
      "vin_number": this.state.temp_column.vin_number,
      "obu_id": this.state.temp_column.obu_id,
      "vehicle_model": this.state.temp_column.vehicle_model,
      "vehicle_type": this.state.temp_column.vehicle_type,
      "coolant_temp": this.state.temp_column.coolant_temp,
      "sub_valid_from": this.state.temp_column.sub_valid_from,
      "sub_valid_to": this.state.temp_column.sub_valid_to,
      "is_bs6": this.state.temp_column.is_bs6,
      "catalyst_tank_level": this.state.temp_column.catalyst_tank_level,
      "adblue_tank_capacity": this.state.temp_column.adblue_tank_capacity,
      "driver_name": this.state.temp_column.driver_name,
      "engine_hours": this.state.temp_column.engine_hours,
      "exhaust_temperature": this.state.temp_column.exhaust_temperature,
      "customer_name": false,
      "customer_mobile_no": false,
      "distance_to_empty": this.state.temp_column.distance_to_empty,
      "is_deleted": false,
      "created_timestamp": "2019-01-08 14:40:01",
      "updated_timestamp": "2019-01-08 14:40:01",
      "mobile_ind": false,
      "ignition_status": false,
      "gprs_signal": false,
      "gps_signal": false
    };

    let vehtoke = this.props.ctr.token;
    const datas = postData(apilist.listviewcustupdate, dataObj, vehtoke);
    datas.then(() => {
    });

    this.props.gmapsDispatch({
      column: {
        vehicle_odometer: this.state.temp_column.vehicle_odometer,
        gps_speed: this.state.temp_column.gps_speed,
        engine_speed: this.state.temp_column.engine_speed,
        fuel_level: this.state.temp_column.fuel_level,
        duration: this.state.temp_column.duration,
        air_pressure: this.state.temp_column.air_pressure,
        oil_pressure: this.state.temp_column.oil_pressure,
        vehicle_battery_potentials: this.state.temp_column.vehicle_battery_potentials,
        fuel_consum: this.state.temp_column.fuel_consum,
        altitude: this.state.temp_column.altitude,
        vin_number: this.state.temp_column.vin_number,
        obu_id: this.state.temp_column.obu_id,
        vehicle_model: this.state.temp_column.vehicle_model,
        vehicle_type: this.state.temp_column.vehicle_type,
        coolant_temp: this.state.temp_column.coolant_temp,
        sub_valid_from: this.state.temp_column.sub_valid_from,
        sub_valid_to: this.state.temp_column.sub_valid_to,
        is_bs6: this.state.temp_column.is_bs6,
        catalyst_tank_level: this.state.temp_column.catalyst_tank_level,
        adblue_tank_capacity: this.state.temp_column.adblue_tank_capacity,
        driver_name: this.state.temp_column.driver_name,
        engine_hours: this.state.temp_column.engine_hours,
        exhaust_temperature: this.state.temp_column.exhaust_temperature
      }
    });

    this.props.gmapsDispatch({
      configureFormState: 'none'
    });
  };

  download_listview_excel = () => {

    alasql.fn.moment = moment;

    alasql.fn.updateTimeStamp = function (val) {
      return convertDateFormat(val, ddmmyyyyhhmmssA);
    }

    alasql.fn.getIgnitionStatus = function (val) {
      return (val === 'RUNNING' || val === 'IDLING') ? 'ON' : 'OFF';
    }

    alasql.fn.getGearNuber = function (isBS6, val) {
      if (isBS6 === 0 || isBS6 === 3 || isBS6 === 4)
        return ""
      var gearvalue = val === "<null>" ? "N" : val === null ? "N" : val <= 0 ? "N" : parseInt(val) >= 15 ? "N" : val === "N" ? "N" : parseInt(val);
      return gearvalue
    }

    alasql.fn.getVehicleOdu = function (vehicleType, vehicle_distance, vehicle_odo, gps_odo, isBS6) {
      if (vehicleType === 'EDC_LCV' || vehicleType === 'EEA_LCV')
        return Math.round(gps_odo) + " km";
      if (isBS6 === 4)
        return Math.round(vehicle_odo);
      if (isBS6 === 0) {
        return vehicleType === 'EEA' ? Math.round(vehicle_odo) : Math.round(gps_odo) || 0;
      } else {
        return vehicle_distance ? Math.round(vehicle_distance) : '';
      }
    }

    alasql.fn.getAirPressure = function (isBS6, type, actual1, air_pressure_digital, ignitionStatus) {
      return airPressureCalculation(isBS6, type, actual1, air_pressure_digital, ignitionStatus);
    }

    alasql.fn.getOilPressure = function (isBS6, type, oilPressure, engineOilDig, engine_oil_act, ignitionStatus) {
      // Early return for non-BS6 vehicles
      return oilPressureCalculation(isBS6, type, oilPressure, engineOilDig, engine_oil_act, ignitionStatus);
    }

    alasql.fn.getENormValue = function (isBS6, vehicleType) {
      if(vehicleType === 'EDC_LNG'){
        return 'BSVI LNG'
      }
      if(vehicleType === 'LCV'){
        return 'BSVI LCV'
      }
      return vehicleTypes[isBS6] || '';
    }

    alasql.fn.getCatalystValue = function (isBS6, adBlueTankCap, adBlueTankCalcValue, catalyst_tank_level) {
      if (adBlueTankCap && !isNaN(adBlueTankCap) && parseInt(adBlueTankCap) > 0 && adBlueTankCalcValue !== null) {
        let tankpercentaddblue = Math.round(catalyst_tank_level);
        if (tankpercentaddblue > 100) {
          return !isNaN(adBlueTankCap) ? parseFloat(adBlueTankCap).toFixed(1) : '';
        } else {
          return !isNaN(adBlueTankCalcValue) ? parseFloat(adBlueTankCalcValue).toFixed(1) : '';
        }
      } else {
        return 'N/A';
      }
    }

    alasql.fn.getFluidValue = function (adBlueTankCap, aft1_total_diesel_exhaust_fluid_used) {
      return fluidValueCalculation(adBlueTankCap, aft1_total_diesel_exhaust_fluid_used);
    }

    alasql.fn.getStatusAndSeverity = function (severity, status) {
      return getStatusAndSeverity(severity, status);
    }

    alasql.fn.getEngineSpeed = function (engineSpeed) {
      let val = parseInt(engineSpeed);
      return val;
    }

    alasql.fn.geofencenamesarr = function (geofencenames) {
      if (geofencenames !== undefined) {
        let val = geofencenames.join();
        return val;
      } else {
        let val = "NA";
        return val;
      }
    }

    alasql.fn.getstatusOnConditon = function (ignitionStatus, val) {
      return getStatusOnConditon(ignitionStatus, val)
    }

    alasql.fn.getExhaustTemp = function (ignitionStatus, val, isBS6) {
      return exhaustTempCalculation(ignitionStatus, val, isBS6);
    }

    alasql.fn.getBatteryPotential = function (ignitionStatus, val) {
      return val.toFixed(2)
    }

    alasql.fn.isEvCheck = function (isBS6, value) {
      if(isBS6 === 4){
        return value ? value : 0
      }else{
        return 'N/A'
      }
    }

    alasql.fn.isFuelCheck = function (isBS6, value) {
      if(isBS6 === 4){
        return 'N/A'
      }
      else{
        return value ? value : 0
      }
    }

    alasql.fn.isFuelCons = function (value) {
      return getFuelLevelValue(value);
    }

    var ev_params;
    ev_params = "isFuelCheck(isBS6, currentInfo->fuelLevel) AS [FUEL(ltr) / GAS(bar/kg) LEVEL], isEvCheck(isBS6, currentInfo->fuelLevel)  AS [SOC(%)],"

    var questllist;
    if (this.state.autoreload === true) {
      questllist = `SELECT
      regnNumber AS [VEHICLE REG. NO.],
      updateTimeStamp(currentInfo->localTimeStamp) AS [LOCATION TIMESTAMP],
      currentInfo->location  AS [VEHICLE LOCATION],
      geofencenamesarr(geofencenames)  AS [GEOFENCE NAME],
      getStatusAndSeverity(severity, currentInfo->status) AS [VEHICLE STATUS],
      currentInfo->statusAge  AS [DURATION],
      currentInfo->gpsSpeed  AS [VEHICLE SPEED(kmph)],
      getEngineSpeed(currentInfo->engineSpeed)  AS [ENGINE SPEED / Motor Speed(rpm)],
      getAirPressure(isBS6, vehicleType, currentInfo->airpressure_actual1, currentInfo->air_pressure_digital,currentInfo->status)  AS [AIR PRESSURE(bar)],
      getOilPressure(isBS6, vehicleType, oilPressure, currentInfo->engineOilDig, currentInfo->engine_oil_act,currentInfo->status)  AS [OIL PRESSURE(bar)],
      getBatteryPotential(currentInfo->status,currentInfo->battPotential)  AS [VEHICLE BATTERY POTENTIAL(v)],`
      + ev_params + `
      getstatusOnConditon(currentInfo->status,currentInfo->eng_coolant_temp)  AS [COOLANT TEMPERATURE(℃)],
      isFuelCons(currentInfo->fuelConsumption) AS [FUEL(ltr) / GAS(kg) / Energy(kwh) CONSUMPTION],
      getBatteryPotential(currentInfo->status,currentInfo->altitude)  AS [ALTITUDE(m)],
      getIgnitionStatus(currentInfo->status) AS [IGNITION STATUS],
      getVehicleOdu(vehicleType, currentInfo->vehicle_distance, currentInfo->vehicle_odo, currentInfo->gps_odo, isBS6)  AS [VEHICLE ODOMETER(Km)],
      currentInfo->obuId  AS [OBU ID],
      vehicleModel  AS [VEHICLE MODEL],
      vehicleType AS [VEHICLE TYPE],
      vinNumber  AS [VIN NUMBER],
      driverName AS [DRIVER NAME],
      driverMobileNo AS [DRIVER MOBILE NO.],
      currentInfo->engineHrs AS [ENGINE HOURS(hrs)],
      validFrom AS [SUBSCRIPTION VALID FROM],
      validTo AS [SUBSCRIPTION VALID TO],
      isEvCheck(isBS6, currentInfo->dist_to_empty)  AS [Distance To Empty(km)],
      getENormValue(isBS6, vehicleType) AS [E-NORM],
      getCatalystValue(isBS6, adBlueTankCap, adBlueTankCalcValue, currentInfo->catalyst_tank_level) AS [DEF TANK LEVEL(ltr)],
      getFluidValue(adBlueTankCap, currentInfo->aft1_total_diesel_exhaust_fluid_used) AS [DEF CONSUMPTION(ltr)],
      getExhaustTemp(currentInfo->status,currentInfo->aft1_exhaust_temp_1,isBS6) as [Exhaust Temp(℃)],
      getGearNuber(isBS6, currentInfo->gearnumber) as [Gear Number]
      INTO XLSX("Listview_"+updateTimeStamp(new Date()) , {headers:true}) FROM ?`
    } else {
      questllist = `SELECT
      regnNumber AS [VEHICLE REG. NO.],
      updateTimeStamp(currentInfo->localTimeStamp) AS [LOCATION TIMESTAMP],
      currentInfo->location  AS [VEHICLE LOCATION],
      getStatusAndSeverity(severity, currentInfo->status) AS [VEHICLE STATUS],
      currentInfo->statusAge  AS [DURATION],
      currentInfo->gpsSpeed  AS [VEHICLE SPEED(kmph)],
      getEngineSpeed(currentInfo->engineSpeed)  AS [ENGINE SPEED / Motor Speed(rpm)],
      getAirPressure(isBS6, vehicleType, currentInfo->airpressure_actual1, currentInfo->air_pressure_digital,currentInfo->status)  AS [AIR PRESSURE(bar)],
      getOilPressure(isBS6, vehicleType, oilPressure, currentInfo->engineOilDig, currentInfo->engine_oil_act,currentInfo->status)  AS [OIL PRESSURE(bar)],
      getBatteryPotential(currentInfo->status,currentInfo->battPotential)  AS [VEHICLE BATTERY POTENTIAL(v)],`
      + ev_params + `
      getstatusOnConditon(currentInfo->status,currentInfo->eng_coolant_temp)  AS [COOLANT TEMPERATURE(℃)],
      isFuelCons(currentInfo->fuelConsumption) AS [FUEL(ltr) / GAS(kg) / Energy(kwh) CONSUMPTION],
      getBatteryPotential(currentInfo->status,currentInfo->altitude)  AS [ALTITUDE(m)],
      getIgnitionStatus(currentInfo->status) AS [IGNITION STATUS],
      getVehicleOdu(vehicleType, currentInfo->vehicle_distance, currentInfo->vehicle_odo, currentInfo->gps_odo, isBS6)  AS [VEHICLE ODOMETER(Km)],
      currentInfo->obuId  AS [OBU ID],
      vehicleModel  AS [VEHICLE MODEL],
      vehicleType AS [VEHICLE TYPE],
      vinNumber  AS [VIN NUMBER],
      driverName AS [DRIVER NAME],
      driverMobileNo AS [DRIVER MOBILE NO.],
      currentInfo->engineHrs AS [ENGINE HOURS(hrs)],
      validFrom AS [SUBSCRIPTION VALID FROM],
      validTo AS [SUBSCRIPTION VALID TO],
      isEvCheck(isBS6, currentInfo->dist_to_empty)  AS [Distance To Empty(km)],
      getENormValue(isBS6, vehicleType) AS [E-NORM],
      getCatalystValue(isBS6, adBlueTankCap, adBlueTankCalcValue, currentInfo->catalyst_tank_level) AS [DEF TANK LEVEL(ltr)],
      getFluidValue(adBlueTankCap, currentInfo->aft1_total_diesel_exhaust_fluid_used) AS [DEF CONSUMPTION(ltr)],
      getExhaustTemp(currentInfo->status,currentInfo->aft1_exhaust_temp_1,isBS6) as [Exhaust Temp(℃)],
      getGearNuber(isBS6, currentInfo->gearnumber) as [Gear Number]
      INTO XLSX("Listview_"+updateTimeStamp(new Date()) , {headers:true}) FROM ?`
    }
    alasql(questllist, [this.props.gmapsState.markers]);
  };

  reset_column_config() {
    this.props.gmapsDispatch({
      column: {
        vehicle_odometer: true,
        gps_speed: true,
        engine_speed: true,
        fuel_level: true,
        duration: false,
        air_pressure: false,
        oil_pressure: false,
        vehicle_battery_potentials: false,
        fuel_consum: false,
        altitude: false,
        vin_number: false,
        obu_id: false,
        vehicle_model: false,
        vehicle_type: false,
        coolant_temp: false,
        sub_valid_from: false,
        sub_valid_to: false,
        is_bs6: false,
        catalyst_tank_level: false,
        adblue_tank_capacity: false,
        driver_name: false,
        engine_hours: false
      }
    });

    this.setState({
      temp_column: {
        vehicle_odometer: true,
        gps_speed: true,
        engine_speed: true,
        fuel_level: true,
        duration: true,
        air_pressure: false,
        oil_pressure: false,
        vehicle_battery_potentials: false,
        fuel_consum: false,
        altitude: false,
        vin_number: false,
        obu_id: false,
        vehicle_model: false,
        vehicle_type: false,
        coolant_temp: false,
        sub_valid_from: false,
        sub_valid_to: false,
        is_bs6: false,
        catalyst_tank_level: false,
        adblue_tank_capacity: false,
        driver_name: false,
        engine_hours: false
      }
    });

    var checkboxes = document.getElementsByClassName("configure_checkbox");
    for (let x = 0; x < checkboxes.length; x++) {
      // if (x < 4) {
      if (checkboxes[x].name === "vehicle_odometer" || checkboxes[x].name === "engine_speed" || checkboxes[x].name === "fuel_level" || checkboxes[x].name === "gps_speed") {
        checkboxes[x].checked = true;
        checkboxes[x].disabled = false;
      } else {
        checkboxes[x].checked = false;
        checkboxes[x].disabled = true;
      }
    }

    var dataObj = {
      "user_id": this.props.ctr.userid.toString(),
      "location_flag": true,
      "time_stamp": true,

      "vehicle_odometer": true,
      "gps_speed": true,
      "engine_speed": true,
      "fuel_level": true,
      "duration": false,
      "air_pressure": false,
      "oil_pressure": false,
      "vehicle_battery_potentials": false,
      "fuel_consum": false,
      "altitude": false,
      "vin_number": false,
      "obu_id": false,
      "vehicle_model": false,
      "vehicle_type": false,
      "coolant_temp": false,
      "sub_valid_from": false,
      "sub_valid_to": false,
      "is_bs6": false,
      "catalyst_tank_level": false,
      "adblue_tank_capacity": false,
      "driver_name": false,
      "engine_hours": false,
      "customer_name": false,
      "customer_mobile_no": false,
      "exhaust_temperature": false,
      "gprs_signal": false,
      "gps_signal": false,
      "is_deleted": false,
      "created_timestamp": "2019-01-08 14:40:01",
      "updated_timestamp": "2019-01-08 14:40:01",
      "mobile_ind": false,
      "ignition_status": false
    };

    let vehtoke = this.props.ctr.token;
    const datas = postData(apilist.listviewcustupdate, dataObj, vehtoke);
    datas.then((dataVals) => {
    });
  };

  sort_by_date(col_name) {
    let sorted_table_data = Object.assign([], this.props.gmapsState.markers);
    if (col_name === 'localTimeStamp') {
      if (this.props.gmapsState.sort.order === 'desc') {
        let sortedData = sorted_table_data.sort((function (a, b) { return new Date(a.currentInfo.localTimeStamp) - new Date(b.currentInfo.localTimeStamp) }));

        this.props.gmapsDispatch({
          markers: sortedData,
          sort: {
            column_name: col_name,
            order: 'asc'
          }
        });
      } else {
        let sortedData = sorted_table_data.sort((function (a, b) { return new Date(b.currentInfo.localTimeStamp) - new Date(a.currentInfo.localTimeStamp) }));

        this.props.gmapsDispatch({
          markers: sortedData,
          sort: {
            column_name: col_name,
            order: 'desc'
          }
        });
      }
    } else if (col_name === 'sub_valid_to') {
      if (this.props.gmapsState.sort.order === 'desc') {
        let sortedData = sorted_table_data.sort((function (a, b) { return new Date(a.validTo) - new Date(b.validTo) }));

        this.props.gmapsDispatch({
          markers: sortedData,
          sort: {
            column_name: col_name,
            order: 'asc'
          }
        });
      } else {
        let sortedData = sorted_table_data.sort((function (a, b) { return new Date(b.validTo) - new Date(a.validTo) }));

        this.props.gmapsDispatch({
          markers: sortedData,
          sort: {
            column_name: col_name,
            order: 'desc'
          }
        });
      }
    } else if (col_name === 'sub_valid_from') {
      if (this.props.gmapsState.sort.order === 'desc') {
        let sortedData = sorted_table_data.sort((function (a, b) { return new Date(a.validFrom) - new Date(b.validFrom) }));

        this.props.gmapsDispatch({
          markers: sortedData,
          sort: {
            column_name: col_name,
            order: 'asc'
          }
        });
      } else {
        let sortedData = sorted_table_data.sort((function (a, b) { return new Date(b.validFrom) - new Date(a.validFrom) }));

        this.props.gmapsDispatch({
          markers: sortedData,
          sort: {
            column_name: col_name,
            order: 'desc'
          }
        });
      }
    }
  };



  sort_by_alphabet(col_name) {
    let sorted_table_data = Object.assign([], this.props.gmapsState.markers);

    if (col_name === "regnNumber") {
      if (this.props.gmapsState.sort.order === "desc") {
        let sortedData = sorted_table_data.sort(function (a, b) {
          if (a.regnNumber && b.regnNumber) {
            a = a.regnNumber.toLowerCase();
            b = b.regnNumber.toLowerCase();

            return b < a ? -1 : b > a ? 1 : 0;
          }
        });

        this.props.gmapsDispatch({
          markers: sortedData,
          sort: {
            column_name: col_name,
            order: "asc",
          },
        });
      } else {
        let sortedData = sorted_table_data.sort(function (a, b) {
          if (a.regnNumber && b.regnNumber) {
            a = a.regnNumber.toLowerCase();
            b = b.regnNumber.toLowerCase();

            return a < b ? -1 : a > b ? 1 : 0;
          }
        });

        this.props.gmapsDispatch({
          markers: sortedData,
          sort: {
            column_name: col_name,
            order: "desc",
          },
        });
      }
    } else if (col_name === "status") {
      // x = a.currentInfo.status.toLowerCase();
      // y = b.currentInfo.status.toLowerCase();
      if (this.props.gmapsState.sort.order === "desc") {
        let sortedData = sorted_table_data.sort(function (a, b) {
          if (a.currentInfo.status && b.currentInfo.status) {
            a = a.currentInfo.status.toLowerCase();
            b = b.currentInfo.status.toLowerCase();

            return b < a ? -1 : b > a ? 1 : 0;
          }
        });

        this.props.gmapsDispatch({
          markers: sortedData,
          sort: {
            column_name: col_name,
            order: "asc",
          },
        });
      } else {
        let sortedData = sorted_table_data.sort(function (a, b) {
          if (a.currentInfo.status && b.currentInfo.status) {
            a = a.currentInfo.status.toLowerCase();
            b = b.currentInfo.status.toLowerCase();

            return a < b ? -1 : a > b ? 1 : 0;
          }
        });

        this.props.gmapsDispatch({
          markers: sortedData,
          sort: {
            column_name: col_name,
            order: "desc",
          },
        });
      }
    }
    else if (col_name === "geofence_name") {
      this.sortMarkersByGeofencenames(col_name)
      // if (this.props.gmapsState.sort.order === "desc") {
      //   let sortedData = sorted_table_data.sort(function (a, b) {
      //     if(a.geofencenames && b.geofencenames){
      //       a = a.geofencenames.toLowerCase();
      //       b = b.geofencenames.toLowerCase();

      //       return a < b ? -1 : a > b ? 1 : 0;
      //     }
      //   });

      //   this.props.gmapsDispatch({
      //     markers: sortedData,
      //     sort: {
      //       column_name: col_name,
      //       order: "asc",
      //     },
      //   });
      // } else {
      //   let sortedData = sorted_table_data.sort(function (a, b) {
      //     if(a.geofencenames && b.geofencenames){
      //       a = a.geofencenames.toLowerCase();
      //       b = b.geofencenames.toLowerCase();

      //       return b < a ? -1 : b > a ? 1 : 0;
      //     }
      //   });

      //   this.props.gmapsDispatch({
      //     markers: sortedData,
      //     sort: {
      //       column_name: col_name,
      //       order: "desc",
      //     },
      //   });
      // }
    }
    else if (col_name === "location") {
      // x = a.currentInfo.location.toLowerCase();
      // y = b.currentInfo.location.toLowerCase();
      if (this.props.gmapsState.sort.order === "desc") {
        let sortedData = sorted_table_data.sort(function (a, b) {
          if (a.currentInfo.location && b.currentInfo.location) {
            a = a.currentInfo.location.toLowerCase();
            b = b.currentInfo.location.toLowerCase();

            return b < a ? -1 : b > a ? 1 : 0;
          }
        });

        this.props.gmapsDispatch({
          markers: sortedData,
          sort: {
            column_name: col_name,
            order: "asc",
          },
        });
      } else {
        let sortedData = sorted_table_data.sort(function (a, b) {
          if (a.currentInfo.location && b.currentInfo.location) {
            a = a.currentInfo.location.toLowerCase();
            b = b.currentInfo.location.toLowerCase();

            return a < b ? -1 : a > b ? 1 : 0;
          }
        });

        this.props.gmapsDispatch({
          markers: sortedData,
          sort: {
            column_name: col_name,
            order: "desc",
          },
        });
      }
    } else if (col_name === "vin_number") {
      // x = a.currentInfo.vinNumber.toLowerCase();
      // y = b.currentInfo.vinNumber.toLowerCase();
      if (this.props.gmapsState.sort.order === "desc") {
        let sortedData = sorted_table_data.sort(function (a, b) {
          if (a.currentInfo.vinNumber && b.currentInfo.vinNumber) {
            a = a.currentInfo.vinNumber.toLowerCase();
            b = b.currentInfo.vinNumber.toLowerCase();

            return b < a ? -1 : b > a ? 1 : 0;
          }
        });

        this.props.gmapsDispatch({
          markers: sortedData,
          sort: {
            column_name: col_name,
            order: "asc",
          },
        });
      } else {
        let sortedData = sorted_table_data.sort(function (a, b) {
          if (a.currentInfo.vinNumber && b.currentInfo.vinNumber) {
            a = a.currentInfo.vinNumber.toLowerCase();
            b = b.currentInfo.vinNumber.toLowerCase();

            return a < b ? -1 : a > b ? 1 : 0;
          }
        });

        this.props.gmapsDispatch({
          markers: sortedData,
          sort: {
            column_name: col_name,
            order: "desc",
          },
        });
      }
    } else if (col_name === "obu_id") {
      // x = a.currentInfo.obuId.toLowerCase();
      // y = b.currentInfo.obuId.toLowerCase();
      if (this.props.gmapsState.sort.order === "desc") {
        let sortedData = sorted_table_data.sort(function (a, b) {
          if (a.currentInfo.obuId && b.currentInfo.obuId) {
            a = a.currentInfo.obuId.toLowerCase();
            b = b.currentInfo.obuId.toLowerCase();

            return b < a ? -1 : b > a ? 1 : 0;
          }
        });

        this.props.gmapsDispatch({
          markers: sortedData,
          sort: {
            column_name: col_name,
            order: "asc",
          },
        });
      } else {
        let sortedData = sorted_table_data.sort(function (a, b) {
          if (a.currentInfo.obuId && b.currentInfo.obuId) {
            a = a.currentInfo.obuId.toLowerCase();
            b = b.currentInfo.obuId.toLowerCase();

            return a < b ? -1 : a > b ? 1 : 0;
          }
        });

        this.props.gmapsDispatch({
          markers: sortedData,
          sort: {
            column_name: col_name,
            order: "desc",
          },
        });
      }
    } else if (col_name === "vehicle_model") {
      // x = a.vehicleModel.toLowerCase();
      // y = b.vehicleModel.toLowerCase();
      if (this.props.gmapsState.sort.order === "desc") {
        let sortedData = sorted_table_data.sort(function (a, b) {
          if (a.vehicleModel && b.vehicleModel) {
            a = a.vehicleModel.toLowerCase();
            b = b.vehicleModel.toLowerCase();

            return b < a ? -1 : b > a ? 1 : 0;
          }
        });

        this.props.gmapsDispatch({
          markers: sortedData,
          sort: {
            column_name: col_name,
            order: "asc",
          },
        });
      } else {
        let sortedData = sorted_table_data.sort(function (a, b) {
          if (a.vehicleModel && b.vehicleModel) {
            a = a.vehicleModel.toLowerCase();
            b = b.vehicleModel.toLowerCase();

            return a < b ? -1 : a > b ? 1 : 0;
          }
        });

        this.props.gmapsDispatch({
          markers: sortedData,
          sort: {
            column_name: col_name,
            order: "desc",
          },
        });
      }
    } else if (col_name === "vehicle_type") {
      // x = a.vehicleType.toLowerCase();
      // y = b.vehicleType.toLowerCase();
      if (this.props.gmapsState.sort.order === "desc") {
        let sortedData = sorted_table_data.sort(function (a, b) {
          if (a.vehicleType && b.vehicleType) {
            a = a.vehicleType.toLowerCase();
            b = b.vehicleType.toLowerCase();

            return b < a ? -1 : b > a ? 1 : 0;
          }
        });

        this.props.gmapsDispatch({
          markers: sortedData,
          sort: {
            column_name: col_name,
            order: "asc",
          },
        });
      } else {
        let sortedData = sorted_table_data.sort(function (a, b) {
          if (a.vehicleType && b.vehicleType) {
            a = a.vehicleType.toLowerCase();
            b = b.vehicleType.toLowerCase();

            return a < b ? -1 : a > b ? 1 : 0;
          }
        });

        this.props.gmapsDispatch({
          markers: sortedData,
          sort: {
            column_name: col_name,
            order: "desc",
          },
        });
      }
    } else if (col_name === "driver_name") {
      if (this.props.gmapsState.sort.order === "desc") {
        let sortedData = sorted_table_data.sort(function (a, b) {
          if (a.driverName && b.driverName) {
            a = a.driverName.toLowerCase();
            b = b.driverName.toLowerCase();

            return b < a ? -1 : b > a ? 1 : 0;
          }
        });

        this.props.gmapsDispatch({
          markers: sortedData,
          sort: {
            column_name: col_name,
            order: "asc",
          },
        });
      } else {
        let sortedData = sorted_table_data.sort(function (a, b) {
          if (a.driverName && b.driverName) {
            a = a.driverName.toLowerCase();
            b = b.driverName.toLowerCase();

            return a < b ? -1 : a > b ? 1 : 0;
          }
        });

        this.props.gmapsDispatch({
          markers: sortedData,
          sort: {
            column_name: col_name,
            order: "desc",
          },
        });
      }
    }
  };


  sortMarkersByGeofencenames(colName) {
    const { gmapsState, gmapsDispatch } = this.props;
    const { markers, sort } = gmapsState;

    const sortedData = markers.slice().sort(createSortFunction('geofencenames', sort.order));
    const newOrder = sort.order === 'desc' ? 'asc' : 'desc';

    gmapsDispatch({
      markers: sortedData,
      sort: { column_name: colName, order: newOrder }
    });
  }
  sort_by_numeric(col_name) {
    let sorted_table_data = Object.assign([], this.props.gmapsState.markers);
    if (col_name === 'vehicle_odometer') {
      const sortOrder = this.props.gmapsState.sort.order === 'desc' ? -1 : 1;

      const sortedData = sorted_table_data.sort((a, b) => {
        const x = Math.round(a.isBS6 === 0 ? (a.vehicleType === 'EEA' ? a.currentInfo.vehicle_odo : a.currentInfo.gps_odo || 0) : a.currentInfo.vehicle_distance || 0);
        const y = Math.round(b.isBS6 === 0 ? (b.vehicleType === 'EEA' ? b.currentInfo.vehicle_odo : b.currentInfo.gps_odo || 0) : b.currentInfo.vehicle_distance || 0);

        return sortOrder * (y - x);
      });

      this.props.gmapsDispatch({
        markers: sortedData,
        sort: {
          column_name: col_name,
          order: sortOrder === -1 ? 'asc' : 'desc'
        }
      });
    } else if (col_name === 'gps_speed') {
      const sortOrder = this.props.gmapsState.sort.order === 'desc' ? -1 : 1;

      const sortedData = sorted_table_data.sort((a, b) => sortOrder * (b.currentInfo.gpsSpeed - a.currentInfo.gpsSpeed));

      this.props.gmapsDispatch({
        markers: sortedData,
        sort: {
          column_name: col_name,
          order: sortOrder === -1 ? 'asc' : 'desc'
        }
      });
    } else if (col_name === 'engine_speed') {
      let conditioned_sort_data = this.checkNullValuesForSortData(sorted_table_data, "engineSpeed");
      const sortOrder = this.props.gmapsState.sort.order === 'desc' ? -1 : 1;

      const sortedData = conditioned_sort_data.sort((a, b) => sortOrder * (b.currentInfo.engineSpeed - a.currentInfo.engineSpeed));

      this.props.gmapsDispatch({
        markers: sortedData,
        sort: {
          column_name: col_name,
          order: sortOrder === -1 ? 'asc' : 'desc'
        }
      });
    } else if (col_name === 'fuel_level') {
      const sortOrder = this.props.gmapsState.sort.order === 'desc' ? -1 : 1;

      const sortedData = sorted_table_data.sort((a, b) => sortOrder * (b.currentInfo.fuelLevel - a.currentInfo.fuelLevel));

      this.props.gmapsDispatch({
        markers: sortedData,
        sort: {
          column_name: col_name,
          order: sortOrder === -1 ? 'asc' : 'desc'
        }
      });
    } else if (col_name === 'catalyst_tank_level') {
      let conditioned_sort_data = this.checkNullValuesForSortData(sorted_table_data, "adBlueTankCalcValue");
      const sortOrder = this.props.gmapsState.sort.order === 'desc' ? -1 : 1;

      const sortedData = conditioned_sort_data.sort((a, b) => sortOrder * (b.adBlueTankCalcValue - a.adBlueTankCalcValue));

      this.props.gmapsDispatch({
        markers: sortedData,
        sort: {
          column_name: col_name,
          order: sortOrder === -1 ? 'asc' : 'desc'
        }
      });
    } else if (col_name === 'adblue_tank_capacity') {
      let conditioned_sort_data = this.checkNullValuesForSortData(sorted_table_data, "aft1_total_diesel_exhaust_fluid_used");

      if (this.props.gmapsState.sort.order === 'desc') {
        let sortedData = this.numericValueDescSort(conditioned_sort_data, "aft1_total_diesel_exhaust_fluid_used")

        this.props.gmapsDispatch({
          markers: sortedData,
          sort: {
            column_name: col_name,
            order: 'asc'
          }
        });
      } else {
        let sortedData = this.numericValueAscSort(conditioned_sort_data, "aft1_total_diesel_exhaust_fluid_used")
        this.props.gmapsDispatch({
          markers: sortedData,
          sort: {
            column_name: col_name,
            order: 'desc'
          }
        });
      }
    } else if (col_name === 'vehicle_battery_potentials') {
      //let conditioned_sort_data = this.checkNullBasedonStatus(sorted_table_data,"battPotential");
      if (this.props.gmapsState.sort.order === 'desc') {
        let sortedData = this.numericValueDescSort(sorted_table_data, "battPotential")
        this.props.gmapsDispatch({
          markers: sortedData,
          sort: {
            column_name: col_name,
            order: 'asc'
          }
        });
      } else {
        let sortedData = this.numericValueAscSort(sorted_table_data, "battPotential")
        this.props.gmapsDispatch({
          markers: sortedData,
          sort: {
            column_name: col_name,
            order: 'desc'
          }
        });
      }
    } else if (col_name === 'fuel_consum') {
      const sortOrder = this.props.gmapsState.sort.order === 'desc' ? -1 : 1;

      const sortedData = sorted_table_data.sort((a, b) => sortOrder * (b.currentInfo.fuelConsumption - a.currentInfo.fuelConsumption));

      this.props.gmapsDispatch({
        markers: sortedData,
        sort: {
          column_name: col_name,
          order: sortOrder === -1 ? 'asc' : 'desc'
        }
      });
    } else if (col_name === 'altitude') {
      const sortOrder = this.props.gmapsState.sort.order === 'desc' ? -1 : 1;

      const sortedData = sorted_table_data.sort((a, b) => sortOrder * (b.currentInfo.altitude - a.currentInfo.altitude));

      this.props.gmapsDispatch({
        markers: sortedData,
        sort: {
          column_name: col_name,
          order: sortOrder === -1 ? 'asc' : 'desc'
        }
      });
    } else if (col_name === 'coolant_temp') {
      let conditioned_sort_data = this.checkNullBasedonStatus(sorted_table_data, "eng_coolant_temp");
      const sortOrder = this.props.gmapsState.sort.order === 'desc' ? -1 : 1;

      const sortedData = conditioned_sort_data.sort((a, b) => sortOrder * (b.currentInfo.eng_coolant_temp - a.currentInfo.eng_coolant_temp));

      this.props.gmapsDispatch({
        markers: sortedData,
        sort: {
          column_name: col_name,
          order: sortOrder === -1 ? 'asc' : 'desc'
        }
      });
    } else if (col_name === 'is_bs6') {
      const sortOrder = this.props.gmapsState.sort.order === 'desc' ? -1 : 1;

      const sortedData = sorted_table_data.sort((a, b) => sortOrder * (b.isBS6 - a.isBS6));

      this.props.gmapsDispatch({
        markers: sortedData,
        sort: {
          column_name: col_name,
          order: sortOrder === -1 ? 'asc' : 'desc'
        }
      });
    } else if (col_name === 'engine_hours') {
      const sortOrder = this.props.gmapsState.sort.order === 'desc' ? -1 : 1;

      const sortedData = sorted_table_data.sort((a, b) => sortOrder * (b.currentInfo.engineHrs - a.currentInfo.engineHrs));

      this.props.gmapsDispatch({
        markers: sortedData,
        sort: {
          column_name: col_name,
          order: sortOrder === -1 ? 'asc' : 'desc'
        }
      });
    }
    else if (col_name === 'exhaust_temperature') {
      let conditioned_sort_data = this.checkNullValuesForSortData(sorted_table_data, "aft1_exhaust_temp_1");
      if (this.props.gmapsState.sort.order === 'desc') {
        let sortedData = this.numericValueDescSort(conditioned_sort_data, "aft1_exhaust_temp_1")
        this.props.gmapsDispatch({
          markers: sortedData,
          sort: {
            column_name: col_name,
            order: 'asc'
          }
        });
      } else {
        let sortedData = this.numericValueAscSort(conditioned_sort_data, "aft1_exhaust_temp_1")
        this.props.gmapsDispatch({
          markers: sortedData,
          sort: {
            column_name: col_name,
            order: 'desc'
          }
        });
      }
    }
    else if (col_name === "air_pressure") {
      const sortOrder = this.props.gmapsState.sort.order === 'desc' ? -1 : 1;

      const sortedData = sorted_table_data.sort((a, b) => sortOrder * (b.currentInfo.airpressure_actual1 - a.currentInfo.airpressure_actual1));

      this.props.gmapsDispatch({
        markers: sortedData,
        sort: {
          column_name: col_name,
          order: sortOrder === -1 ? 'asc' : 'desc'
        }
      });
    }
    else if (col_name === "oil_pressure") {
      const sortOrder = this.props.gmapsState.sort.order === 'desc' ? -1 : 1;

      const sortedData = sorted_table_data.sort((a, b) => sortOrder * (b.currentInfo.engine_oil_act - a.currentInfo.engine_oil_act));

      this.props.gmapsDispatch({
        markers: sortedData,
        sort: {
          column_name: col_name,
          order: sortOrder === -1 ? 'asc' : 'desc'
        }
      });
    }
  };


  numericValueAscSort = (conditioned_sort_data, fieldName) => {

    return conditioned_sort_data.sort((function (a, b) {
      if (a.currentInfo[fieldName] === null) {
        return -1;
      }
      else if (b.currentInfo[fieldName] === null) {
        return 1;
      } else {
        return a.currentInfo[fieldName] - b.currentInfo[fieldName];
      }
    }));

  }

  numericValueDescSort = (conditioned_sort_data, fieldName) => {

    return conditioned_sort_data.sort((function (a, b) {
      if (a.currentInfo[fieldName] === null) {
        return 1;
      }
      else if (b.currentInfo[fieldName] === null) {
        return -1;
      } else {
        return b.currentInfo[fieldName] - a.currentInfo[fieldName];
      }
    }));
  }


  checkNullBasedonStatus = (sorted_table_data, fieldName) => {
    return sorted_table_data.map((item) => {
      if (item.currentInfo != undefined) {
        if (item.currentInfo.status != 'RUNNING' && item.currentInfo.status != 'IDLING') {
          item.currentInfo[fieldName] = null;
        }

        return item;

      } else {
        if (item.status != 'RUNNING' && item.status != 'IDLING') {
          item[fieldName] = null;
        }
        return item;
      }
    });
  }

  checkNullValuesForSortData = (sorted_table_data, fieldName) => {
    if (fieldName == "adBlueTankCalcValue") {
      return sorted_table_data.map((item) => {
        if (item.isBS6 == 0 || item.isBS6 == 2) {
          item[fieldName] = null;
          return item;
        } else {
          return item
        }
      });
    } else if (fieldName == "engineSpeed") {
      return sorted_table_data.map((item) => {
        item[fieldName] = Math.round(item[fieldName]) || 0;
        return item;
      });
    } else {
      return sorted_table_data.map((item) => {
        if (item.isBS6 == 0 || item.isBS6 == 2) {
          if (item.currentInfo != undefined) {
            item.currentInfo[fieldName] = null;
          } else {
            item[fieldName] = null;
          }
          return item;
        } else {
          if (fieldName == 'aft1_exhaust_temp_1') {
            if (item.currentInfo != undefined) {
              if (showExhaust(item.isBS6)) {
                item.currentInfo[fieldName] = exhaustCalculation(item.currentInfo);
              } else {
                item.currentInfo[fieldName] = null;
              }
            } else {
              if (item.isBS6 == 1) {
                if (item.status != 'RUNNING' && item.status != 'IDLING') {
                  item[fieldName] = null;
                } else {
                  item[fieldName] = item[fieldName] < 0 || item[fieldName] == null ? 0 : item[fieldName];
                }
              }
              else {
                item.currentInfo[fieldName] = null;
              }
            }
          }
          return item
        }
      });
    }
  }

  getPressureValue(todo) {
    if (todo.isBS6 === 3) {
      return "N/A";
    }

    // if (todo.isBS6 === 4) {
    //   return '-'
    // }
    // Early return for non-running/idling (improves performance)
    if (todo.currentInfo.status !== "RUNNING" && todo.currentInfo.status !== "IDLING") {
      return "-";
    }
    // Combined pressure check (reduces redundancy)
    const pressureValue = todo.currentInfo.airpressure_actual1 || todo.currentInfo.air_pressure_digital;
    const pressureCheck = pressureValue !== undefined && pressureValue != null;

    // Logic based on pressure and type with early return (improves performance)
    if (pressureCheck) {
      return pressureValue < 4.5 ? "BAD" : "GOOD";
    } else {
      return "-";
    }
  };

  getOilPressureValue(todo) {
    // Early return for non-BS6 vehicles
    if (todo.isBS6 === 3 || todo.isBS6 === 4) {
      return "N/A";
    }

    // Check ignition status first
    if (todo.currentInfo.status !== "RUNNING" && todo.currentInfo.status !== "IDLING") {
      return "-";
    }

    // Combined oil pressure check (reduces redundancy)
    const oilPressureCheck = todo.oilPressure && todo.currentInfo.engine_oil_act;

    // Logic based on isBS6 with early return
    if (todo.isBS6 === 1 || todo.isBS6 === 2) {
      return oilPressureCheck ? (parseInt(todo.currentInfo.engine_oil_act) < 0.5 ? "BAD" : "GOOD") : "-";
    } else {
      if (todo.vehicleType === "EEA" || todo.vehicleType === "EEAS") {
        return oilPressureCheck ? (parseInt(todo.currentInfo.engine_oil_act) < 0.5 ? "BAD" : "GOOD") : "-";
      } else if (todo.vehicleType === "EDC" || todo.vehicleType === "IL") {
        return oilPressureCheck ? (todo.currentInfo.engineOilDig === 0 ? "BAD" : "GOOD") : "-";
      } else {
        return "-";
      }
    }
  };

  getSeverity(todo) {
    if (todo.severity) {
      var str = todo.severity;
      var chars = str.split(',');
      let result;
      for (let i = 0; i < chars.length; i++) {
        if (chars[i] === 'Critical') {
          result = 'CRITICAL';
          break;
        } else if (chars[i] === 'Warning') {
          result = 'WARNING';
          break;
        } else if (chars[i] === 'Service') {
          result = 'SERVICE DUE';
          break;
        }
      }
      return result;
    }
  };

  getBS6Value(todo) {
    if (todo.isBS6 === 1) {
      return <img className="" src="images/ListView/bsvi_logo.png" alt="" style={{ maxWidth: '15%' }} />;
    } else if (todo.isBS6 === 2) {
      return (
        <div>
          <img
            className=""
            src="images/ListView/bsvi_logo.png  "
            alt=""
            style={{ maxWidth: "15%" }}
          /><p>{todo.vehicleType === 'EDC_LNG' ? 'LNG' : 'CNG'}</p>
        </div>
      );
    } else if (todo.isBS6 === 3) {
      return (
        <div>
          <img
            className=""
            src="images/ListView/bsvi_logo.png  "
            alt=""
            style={{ maxWidth: "15%" }}
          /><p>LCV</p>
        </div>
      );
    } else if (todo.isBS6 === 0) {
      return 'BSIV';
    } else if (todo.isBS6 === 4) {
      return 'EV';
    }
  };

  getCatalystTanlLevelValue(todo) {
    if (todo.adBlueTankCap && !isNaN(todo.adBlueTankCap) && parseInt(todo.adBlueTankCap) > 0 && todo.adBlueTankCalcValue !== null) {
      let tankpercentaddblue = Math.round(todo.currentInfo.catalyst_tank_level);
      if (tankpercentaddblue > 100) {
        return parseFloat(todo.adBlueTankCap).toFixed(1);
      } else {
        return (parseFloat(todo.adBlueTankCalcValue)).toFixed(1);
      }
    } else {
      return 'N/A';
    }
  };

  getFluidUsedValue(todo) {
    if (todo?.adBlueTankCap && !isNaN(todo?.adBlueTankCap) && parseInt(todo.adBlueTankCap) > 0) {
      return (parseFloat(todo?.currentInfo?.aft1_total_diesel_exhaust_fluid_used)).toFixed(1);
    } else {
      return 'N/A'
    }
  };

  getGpsSpeed(todo) {
    if (todo.isBS6 === 0 || todo.isBS6 === 4) {
      return todo.vehicleType === 'EEA' || todo.isBS6 === 4 ? Math.round(todo.currentInfo.vehicle_odo) : Math.round(todo.currentInfo.gps_odo) || 0;
    } else {
      return typeof todo.currentInfo.vehicle_distance === 'number' ? Math.round(todo.currentInfo.vehicle_distance) : '';
    }
  }
  callvascreen = (e, c) => {

    if (e.currentInfo.status === "DISCONNECTED") {
      var ids = c.target.getAttribute('menuclickid').split(",");
      var menuClickids = []
      menuClickids.push({ mm: ids[0], sm: ids[1], tm: ids[2] });
      //this.props.quicklinks(menuClickids);
      //this.props.history.push("/createVas" , {vehicledata: e.regnNumber})
    }
  }
  dialhighlight = (e) => {
    let status = e.currentTarget.dataset.status
    this.props.gmapsDispatch({ howerdialcss: status });
  }

  expandIconDropDown = () => {
    this.setState({
      showIconDropDown: false,
      dropiconValue: this.state.dropiconValue == 'asc' ? 'desc' : 'asc',
      showFilterDropDown: false
    }, () => {
      let filterSortvalue = this.classicSortingColums.filter((item) => item.key == this.state.selectedSortValue);
      if (filterSortvalue.length > 0) {
        this.onSortkeySelect(filterSortvalue[0]);
      }
    });
  }

  getModifiedColumns(isCustomer) {
    return sortingColumsClassicView.map(column => {
      if (isCustomer) {
        if (column.value === "fuel_consum") {
          return { ...column, key: "Fuel / Gas Consumption / Battery Consumption" };
        }
        if (column.value === "fuel_level") {
          return { ...column, key: "Fuel / Gas Level / Battery Level" };
        }
        if (column.value === "engine_hours") {
          return { ...column, key: "Engine Hours" };
        }
        if (column.value === "engine_speed") {
          return { ...column, key: "Engine / Motor Speed" };
        }
      }
      return column;
    });
  }

  filterDropdownValueShow = () => {
    this.setState({
      showFilterDropDown: this.state.showFilterDropDown ? false : true,
      showIconDropDown: false,
      classicSortColumns: this.classicSortingColums
    })
  }

  onSortkeySelect = (sortValue) => {
    this.setState({ refreshItem: false });
    this.props.gmapsDispatch({
      // markers: sortedData,
      sort: {
        column_name: sortValue.key,
        order: this.state.dropiconValue
      }
    }).then(() => {
      if (sortValue.type == "alpha") {
        this.sort_by_alphabet(sortValue.value);

      }
      else if (sortValue.type == 'date') {
        this.sort_by_date(sortValue.value);

      }
      else if (sortValue.type == "numeric") {
        this.sort_by_numeric(sortValue.value);
      }
      this.setState({
        showFilterDropDown: false,
        refreshItem: true,
        selectedSortValue: sortValue.key
      });
    });

  }

  handleClickOutside = (e) => {
    if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
      // alert('You clicked outside of me!');
      this.setState({
        showFilterDropDown: false
      });
    }

  }

  onFilterSearch = (event) => {
    try {
      let targetValue = event.target.value;
      let filteredItems = this.classicSortingColums.filter((val) => val['key'].toLowerCase().includes(targetValue.toLowerCase()));
      this.setState({
        selectedSortValue: targetValue,
        classicSortColumns: filteredItems
      })
    }
    catch (e) {
    }
  }

  editRegno = (regId, vinNo, key) => {
    this.setState({
      selectedVinNo: vinNo,
      editedRegNo: regId,
      editIndex: key
    })
  }

  getEveReg = (e) => {
    e.target.value = e.target.value.replace(/[^\w]|_/g, '').toUpperCase();
    let typedRedNo = e.target.value;
    this.setState({
      changeRegNo: typedRedNo
    })
  }

  sendReg_VinNo = async (oldRegNo) => {
    const selectedItem = this.props.gmapsState.markers.filter((item) => item.regnNumber === this.state.changeRegNo);
    if (selectedItem.length <= 0) {
      selectedItem.regnNumber = this.state.changeRegNo; // to update reg no

      const dataObj = {
        vinNumber: this.state.selectedVinNo,
        regn_no: this.state.changeRegNo,
      };
      const vehtoken = this.props.ctr.token;
      try {
        const { statuscode } = await postData(apilist.updateRegistrationNumber, dataObj, vehtoken);
        if (statuscode === 200) {
          this.setState({
            editIndex: '',
            editedRegNo: this.state.changeRegNo,
          });
          const data = this.props.gmapsState.markers.find((item) => item.regnNumber === oldRegNo);
          data.regnNumber = this.state.changeRegNo;

          const text = 'Reg Number Updated Successfully.';
          document.querySelector('.save_modal_message').textContent = text;
          document.getElementById('saved_user_data_modal').style.display = 'block';
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      const text = 'Check Your Register No.';
      document.querySelector('.error_modal_message').textContent = text;
      document.getElementById('error_data_submit_modal').style.display = 'block';
    }



  };

  close_error_data_submit_modal() {
    let modal = document.getElementById('error_data_submit_modal');
    modal.style.display = "none";
  };
  close_saved_user_data_modal() {
    let modal = document.getElementById('saved_user_data_modal');
    modal.style.display = "none";
  };

  openModel = (message, msgType) => {
    this.setState({
      showAlertBox: true,
      showMessage: message,
      messageType: msgType
    });
  }

  hideModal = () => {
    this.setState({
      showMessage: "",
      showAlertBox: false
    });
  };

  render() {
    const isCustomer = this.props.ctr.userrole === 'OWNER ADMIN'
    const modifiedColumns = this.getModifiedColumns(isCustomer);

    const beforeEdit = {
      padding: "0",
      marginRight: "7px"
    }
    const fontSizeAlert = {
      fontSize: 14,
    }
    const { currentPage, todosPerPage } = this.props.gmapsState;
    const indexOfLastTodo = currentPage * todosPerPage;
    const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    const { showMessage, showAlertBox, messageType } = this.state;
    const currentTodos = this.props.gmapsState.markers.slice(
      indexOfFirstTodo,
      indexOfLastTodo
    );

    if (this.state.classic_view) {
      this.renderTodos = currentTodos.map((todo, index) => {
        let exhaust_temp = 'N/A';

        if (showExhaust(todo.isBS6)) {
          exhaust_temp = exhaustCalculation(todo.currentInfo)
        }
        return (
          <>
            <div id="error_data_submit_modal" className="modal">
              <div id="modal-content" className="modal-content modal-content-error">
                <div className="row">
                  <span className="col-lg-11 col-sm-11 error_modal_message" style={fontSizeAlert}></span>
                  <span className="col-lg-1 col-sm-1 close" onClick={this.close_error_data_submit_modal.bind(this)}>&times;</span>
                </div>
              </div>
            </div>
            <div id="saved_user_data_modal" className="modal">
              <div id="modal-content" className="modal-content modal-content-success">
                <div className="row">
                  <span className="col-lg-11 col-sm-11 save_modal_message" style={fontSizeAlert}></span>
                  <span className="col-lg-1 col-sm-1 close" onClick={this.close_saved_user_data_modal.bind(this)}>&times;</span>
                </div>
              </div>
            </div>

            <tr key={index} className={todo.isBS6 === 2 ? "linefortable hightlight_cng" : "linefortable"}>
              <td height="65" data-toggle="tooltip" data-placement="top" className={this.state.editedRegNo === '' ? '' : "up_regcs"}>

                <div className="share_input">
                  <span style={beforeEdit} onClick={() => { this.showModalDialog(todo); }}>
                    <i className="fa fa fa-share-alt" aria-hidden="true"></i>
                  </span>
                  <span>
                    {this.state.editIndex !== index ?
                      (<span onClick={() => { this.handleClick(todo); }} title={todo.regnNumber}>
                        {todo.regnNumber.length > 15 ?
                          todo.regnNumber.replace(todo.regnNumber.substring(2, todo.regnNumber.length - 6), "...") :
                          todo.regnNumber}</span>)
                      :
                      <input className="input_wid" maxLength={10} type="text" pattern="[0-9A-Za-z]*" onChange={(e) => this.getEveReg(e, todo.regnNumber)}
                        defaultValue={this.state.editedRegNo !== '' ? this.state.editedRegNo : todo.regnNumber.length > 15 ? todo.regnNumber.replace(todo.regnNumber.substring(2, todo.regnNumber.length - 6), "...") :
                          todo.regnNumber} />
                    }
                  </span>
                  <div className="edit_ok_icon">
                    {this.state.editIndex !== index && this.props.ctr.userrole !== "AL EMPLOYEE" ?
                      (<i class="fa fa-pencil edit_icon" id={todo.regnNumber} onClick={() => { this.editRegno(todo.regnNumber, todo.currentInfo.vinNumber, index) }} aria-hidden="true"></i>)
                      : this.state.changeRegNo.length > 6 &&
                      (<i class="fa fa-check edit_icon" onClick={() => { this.sendReg_VinNo(todo.regnNumber) }} aria-hidden="true"></i>)}
                  </div>
                </div>
                <span className="listview_severity">{this.getSeverity(todo)}</span>
              </td>

              <td className="listview_status_alert_column listview_map_icon" menuclickid="7,110,0" onClick={e => { this.callvascreen(todo, e); }}>
                {todo.currentInfo.status === 'RUNNING' || todo.currentInfo.status === 'IDLING'
                  ? <span menuclickid="7,110,1" className={todo.currentInfo.status === 'IDLING' ? "idling_on_status_icon" : "on_status_icon"} style={{ display: 'inline-block' }}>ON</span>
                  : <span menuclickid="7,110,0" className="off_status_icon" style={{ display: 'inline-block' }}>OFF</span>}
                <span menuclickid="7,110,0" style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                  {todo.currentInfo.status === "DISCONNECTED"
                    ? <u menuclickid="7,110,0">{todo.currentInfo.status}</u>
                    : todo.currentInfo.status} <br />
                  <span menuclickid="7,110,0" className="listview_duration">{this.props.gmapsState.column.duration === true ? todo.currentInfo.statusAge : ''}</span>
                </span> <br /> <span menuclickid="7,110,0" className="" style={{ color: "#6b2b27", fontSize: "11px" }}>{todo.currentInfo.status === "DISCONNECTED" && todo.currentInfo.battPotential === 0.0 || 0 ? "Physical check required at dealership for Battery / Fuse / connection" : ''}</span></td>
              <td className="listview_address_loc_column">{todo.currentInfo.location}</td>
              {this.state.autoreload ? <td className="listview_address_loc_column_gn"><div className="geofence_name_margin">{todo.geofencenames !== undefined ? todo.geofencenames : "NA"}</div></td> : ''}
              <td>{convertDateFormat(todo.currentInfo.localTimeStamp, ddmmyyyyhhmmssA)}</td> {/*MMM DD, YYYY, h:mm:ss A*/}
              <td className={this.props.gmapsState.column.vehicle_odometer === true ? 'listview_show' : 'listview_hide'}>{this.getGpsSpeed(todo)}</td>
              <td className={this.props.gmapsState.column.gps_speed === true ? 'listview_show' : 'listview_hide'}>{Math.round(todo.currentInfo.gpsSpeed)}</td>
              <td className={this.props.gmapsState.column.engine_speed === true ? 'listview_show' : 'listview_hide'}>{Math.round(todo.currentInfo.engineSpeed) || 0}</td>
              <td className={this.props.gmapsState.column.fuel_level === true ? 'listview_show' : 'listview_hide'}>{getFuelLevelValue(todo.currentInfo.fuelLevel) || 0}</td>
              {/* <td className={this.props.gmapsState.column.duration === true ? 'listview_show' : 'listview_hide'}>{todo.currentInfo.statusAge}</td> */}
              <td className={this.props.gmapsState.column.air_pressure === true ? 'listview_show' : 'listview_hide'}>{this.getPressureValue(todo)}</td>
              <td className={this.props.gmapsState.column.oil_pressure === true ? 'listview_show' : 'listview_hide'}>{this.getOilPressureValue(todo)}</td>
              <td className={this.props.gmapsState.column.vehicle_battery_potentials === true ? 'listview_show' : 'listview_hide'}>{todo.currentInfo.battPotential.toFixed(2) || 0}</td>
              <td className={this.props.gmapsState.column.fuel_consum === true ? 'listview_show' : 'listview_hide'}>{getFuelLevelValue(todo.currentInfo.fuelConsumption)}</td>
              <td className={this.props.gmapsState.column.altitude === true ? 'listview_show' : 'listview_hide'}>{(todo.currentInfo.altitude !== null || '') ? todo.currentInfo.altitude.toFixed(2) : 'N/A'}</td>
              <td className={this.props.gmapsState.column.vin_number === true ? 'listview_show' : 'listview_hide'}>{todo.vinNumber}</td>
              <td className={this.props.gmapsState.column.obu_id === true ? 'listview_show' : 'listview_hide'}>{todo.currentInfo.obuId}</td>
              <td className={this.props.gmapsState.column.vehicle_model === true ? 'listview_show' : 'listview_hide'}>{todo.vehicleModel}</td>
              <td className={this.props.gmapsState.column.vehicle_type === true ? 'listview_show' : 'listview_hide'}>{todo.vehicleType}</td>
              <td className={this.props.gmapsState.column.coolant_temp === true ? 'listview_show' : 'listview_hide'}>{todo.currentInfo.status == "RUNNING" || todo.currentInfo.status == "IDLING" ? todo.currentInfo.eng_coolant_temp : '-'}</td>
              <td className={this.props.gmapsState.column.sub_valid_from === true ? 'listview_show' : 'listview_hide'}>{moment(todo.validFrom).format('DD-MM-YYYY')}</td>
              <td className={this.props.gmapsState.column.sub_valid_to === true ? 'listview_show' : 'listview_hide'}>{moment(todo.validTo).format('DD-MM-YYYY')}</td>

              <td className={this.props.gmapsState.column.exhaust_temperature === true ? 'listview_show' : 'listview_hide'}>{exhaust_temp}</td>
              <td className={this.props.gmapsState.column.is_bs6 === true ? 'listview_show' : 'listview_hide'}>{this.getBS6Value(todo)}</td>
              <td className={this.props.gmapsState.column.catalyst_tank_level === true ? 'listview_show' : 'listview_hide'}>{this.getCatalystTanlLevelValue(todo)}</td>
              <td className={this.props.gmapsState.column.adblue_tank_capacity === true ? 'listview_show' : 'listview_hide'}>{this.getFluidUsedValue(todo)}</td>
              <td className={this.props.gmapsState.column.driver_name === true ? 'listview_show' : 'listview_hide'}>{todo.driverName ? todo.driverName : 'Default Driver'} <br /> {todo.driverMobileNo ? todo.driverMobileNo : null}</td>
              <td className={this.props.gmapsState.column.engine_hours === true ? 'listview_show' : 'listview_hide'}>{todo.vehicleType !== "IL" ? todo.isBS6 === 4 ? 'N/A' : todo.currentInfo.engineHrs : '-'} </td>
              <td style={{ padding: "0" }} onClick={() => { this.handleClick(todo); }}><i className="fa fa-map-marker listview_map_icon" aria-hidden="true"></i></td>
            </tr>
          </>
        );

      });
    } else {
      this.renderTodos = currentTodos.map((todo, index) => {

        return (
          <NewListView key={index} classicEditIndex={this.state.classicEditIndex} updateClassicEditIndex={this.updateClassicEditIndex} share_click={this.showModalDialog} userId={this.props.ctr.userroleid} data={todo} rowIndex={index} gmapsState={this.props.gmapsState} viewType="customer" gpsSpeed={this.getGpsSpeed} getSeverity={this.getSeverity} onLinkClick={this.handleClick} fluidUsed={this.getFluidUsedValue} autoreload={this.state.autoreload} catalystTankLevel={this.getCatalystTanlLevelValue} oilPressure={this.getOilPressureValue} airPressure={this.getPressureValue} classicToken={this.props.ctr.token} userRole={this.props.ctr.userrole}></NewListView>
        );

      });
    }
    // }
    // Logic for displaying page numbers
    const pageNumbers = [];
    for (
      let i = 1;
      i <= Math.ceil(this.props.gmapsState.markers.length / todosPerPage);
      i++
    ) {
      pageNumbers.push(i);
    }

    const renderPageNumbers = pageNumbers.map(number => {
      if (pageNumbers.length <= 6) {
        return (
          <td
            key={number}
            id={number}
            onClick={this.handleClickpage}
            className={
              this.props.gmapsState.paginationcolor === number
                ? "slectedbluepg pagesize"
                : "notselected pagesize"
            }
          >
            {number}
          </td>
        );
      } else {
        if (number === 1) {
          if (this.props.gmapsState.paginationcolor <= 5) {
            return (
              <td
                key={number}
                id={number}
                onClick={this.handleClickpage}
                className={
                  this.props.gmapsState.paginationcolor === number
                    ? "slectedbluepg pagesize"
                    : "notselected pagesize"
                }
              >
                {number}
              </td>
            );
          } else {
            return (
              <td
                key={number}
                id={number}
                onClick={this.handleClickpage}
                className={
                  this.props.gmapsState.paginationcolor === number
                    ? "slectedbluepg pagesize"
                    : "notselected pagesize"
                }
              >
                {number}...
              </td>
            );
          }
        } else if (number > 1 && number <= 6 && this.props.gmapsState.paginationcolor <= 6) {
          return (
            <td
              key={number}
              id={number}
              onClick={this.handleClickpage}
              className={
                this.props.gmapsState.paginationcolor === number
                  ? "slectedbluepg pagesize"
                  : "notselected pagesize"
              }
            >
              {number}
            </td>
          );
        } else if ((number >= (this.props.gmapsState.paginationcolor - 2) && number <= (this.props.gmapsState.paginationcolor + 2)) && this.props.gmapsState.paginationcolor > 6) {
          return (
            <td
              key={number}
              id={number}
              onClick={this.handleClickpage}
              className={
                this.props.gmapsState.paginationcolor === number
                  ? "slectedbluepg pagesize"
                  : "notselected pagesize"
              }
            >
              {number}
            </td>
          )
        } else if (number === pageNumbers.length) {
          return (
            <td
              key={number}
              id={number}
              onClick={this.handleClickpage}
              className={
                this.props.gmapsState.paginationcolor === number
                  ? "slectedbluepg pagesize"
                  : "notselected pagesize"
              }
            >
              <span>...</span>{number}
            </td>
          )
        }
      }
      return '';
    });

    return (
      <div className="">
        {!this.props.gmapsState.listview && this.props.gmapsState.loader ? (
          <div className="Pageloading">
            {" "}
            <img className="loadimg" src="/images/loader_1.gif" alt="iAlert" />
          </div>
        ) : (
          <div className="">
            <div className="menuStyle">
              <div className="container-wrapper">
                {/* <SearchField resetresult={this.resettheresult} searchresultprop={this.clicksearchresult} />  */}
              </div>
            </div>
          </div>
        )}
        {/* <div className="container-wrapper">
          <Header />
        </div> */}
        {/* <div className="border-row"></div> */}

        {this.props.gmapsState.listview ? (
          <div>
            {/* <body className="table-heading"></body> */}

            <div className="trip_header">
              <div className="trip_container">
                <div className={this.props.ctr.userrole === "AL SME" || this.props.ctr.userrole === "AL EMPLOYEE" ? "trip_sub" : "trip_subone"}>
                  <div className="row">
                    {/* <div className="col-xl-3">
                   <p>Show GeoFence Name</p>
                 </div> */}
                    {this.state.classic_view ? <>
                      <div className={this.props.ctr.userrole === "AL EMPLOYEE" || this.props.ctr.userrole === "AL SME" ? "col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 col-4" : "col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3"}>
                        <button type="button" className="download_btn" onClick={this.listViewSwither.bind(this)} style={{ paddingLeft: "4px", outlineStyle: "none" }} >Classic View<div className="myswitchgeo" >
                          <div style={{ marginLeft: "6px", marginTop: "6px" }}>OFF</div></div><span className="btn-nw-info">New</span></button></div>
                      <div className={this.props.ctr.userrole !== "AL EMPLOYEE" && this.props.ctr.userrole !== "AL SME" ? "col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3" : "col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 col-4"}><button variant="warning" type="button" className="download_btn" onClick={this.configure.bind(this)}>Configure<i className="fa fa-cog" aria-hidden="true" style={{ marginLeft: "10px" }}></i></button></div>
                      {this.props.ctr.userrole !== "AL EMPLOYEE" && this.props.ctr.userrole !== "AL SME" ? <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3">
                        <button type="button" className="download_btn" onClick={this.callgeofencenamefn.bind(this)} style={{ paddingLeft: "4px", outlineStyle: "none" }} >Geofence Name<div title={this.state.geofencenametog + " Geofence name"} className={this.state.autoreload ? "myswitchgeo activatedgeo " : "myswitchgeo"} >
                          {this.state.autoreload ? <div style={{ marginLeft: "6px", marginTop: "6px", color: "white" }}>ON</div> : <div style={{ marginLeft: "6px", marginTop: "6px" }}>OFF</div>}</div></button></div> : ''}
                      <div className={this.props.ctr.userrole !== "AL EMPLOYEE" ? "col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3" : "col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 col-4"}><button variant="warning" type="button" className="configure_btn" onClick={this.download_listview_excel.bind(this)}>Download<i className="fa fa-download" aria-hidden="true" style={{ marginLeft: "10px" }}></i></button></div></>
                      :
                      <>
                        <div className={this.props.ctr.userrole === "AL EMPLOYEE" || this.props.ctr.userrole === "AL SME" ? "col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 col-4" : "col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3"}>
                          <button type="button" className="download_btn" onClick={this.listViewSwither.bind(this)} style={{ paddingLeft: "4px", outlineStyle: "none" }} >Classic View<div className="myswitchgeo activatedgeo" >
                            <div style={{ marginLeft: "6px", marginTop: "6px", color: "white" }}>ON</div></div><span className="btn-nw-info">New</span></button></div>
                        {this.props.ctr.userrole !== "AL EMPLOYEE" && this.props.ctr.userrole !== "AL SME" ? <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3">
                          <button type="button" className="download_btn" onClick={this.callgeofencenamefn.bind(this)} style={{ paddingLeft: "4px", outlineStyle: "none" }} >Geofence Name<div title={this.state.geofencenametog + " Geofence name"} className={this.state.autoreload ? "myswitchgeo activatedgeo " : "myswitchgeo"} >
                            {this.state.autoreload ? <div style={{ marginLeft: "6px", marginTop: "6px", color: "white" }}>ON</div> : <div style={{ marginLeft: "6px", marginTop: "6px" }}>OFF</div>}</div></button></div> : ''}
                        <div className={this.props.ctr.userrole === "AL EMPLOYEE" || this.props.ctr.userrole === "AL SME" ? "col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 col-4" : "col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3"}><button variant="warning" type="button" className="configure_btn" onClick={this.download_listview_excel.bind(this)}>Download<i className="fa fa-download" aria-hidden="true" style={{ marginLeft: "10px" }}></i></button></div>
                        <div className={this.props.ctr.userrole !== "AL EMPLOYEE" && this.props.ctr.userrole !== "AL SME" ? "col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3" : "col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 col-4"}>
                          <div className={classNames('custom-input-base', {
                            'isCustomer__custom-input': isCustomer,
                            'custom-input': !isCustomer
                          })}>

                            <input type="text" className="form-control custom-int" placeholder="Sort By" value={this.state.selectedSortValue} onClick={this.filterDropdownValueShow} onChange={this.onFilterSearch} />


                            <div className={!this.state.showFilterDropDown ? "dropdown-menu dropdown-menu-menu" : "dropdown-menu dropdown-menu-menu showfilterDropdown"} ref={(node) => this.wrapperRef = node}>
                              {modifiedColumns.map((n) => {
                                return <><MenuItem onClick={() => {
                                  this.onSortkeySelect(n)
                                }}>{n.key}</MenuItem></>
                              })}
                            </div>
                            <button className="btn dropdown_custom custom-btn " type="button" onClick={this.expandIconDropDown}><span className={this.state.dropiconValue == "asc" ? "fa fa-sort-alpha-asc show-img" : "fa fa-sort-alpha-desc show-img"}></span></button>
                          </div>

                        </div></>}

                  </div>
                  {this?.props?.gmapsState?.markers?.length > 0 &&
                  <VehicleLinkGeneration showModelDialog={this.state.showShareDialog} handleClose={this.hideModalDialog} showCopyLinkDialog={this.showCopyLinkDialog} token={this.props.ctr.token} vehicleDetails={this.state.shareVehicleDetails} receiveAllVechicleData={this.state.sendAllVehicleData.length > 0 ? this.state.sendAllVehicleData : this?.props?.gmapsState?.markers} isTrace={false} />
                  }
                  <VehicleCopyLink showCopyLinkModelDialog={this.state.showCopyLinkModelDialog} handleCopyLinkClose={this.hideCopyLinkModelDialog} link={this.state.link} />
                  <div className="popup configureForm" style={{ display: this.props.gmapsState.configureFormState }}>
                    <div className="popup_body listview_column_popup_body">
                      <div className="popup_header">
                        <p className="listview_column_popup_heading">
                          <strong>LIST VIEW CONFIGURATION</strong>
                          <span className="close_list_configuration_btn" onClick={this.close_list_configuration.bind(this)} style={{ cursor: "pointer", float: "right" }}>✖</span>
                        </p>
                        <br />
                      </div>
                      <div className="popup_content">
                        <div className="row listview_column_config_popup_body config_div">
                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="row">
                              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                <input className="configure_checkbox" type="checkbox" name="air_pressure" value={this.props.gmapsState.column.air_pressure} onChange={this.toggleColumns.bind(this, '9', 'air_pressure')} /> <label>Air Pressure</label><br />
                                <input className="configure_checkbox" type="checkbox" name="altitude" value={this.props.gmapsState.column.altitude} onChange={this.toggleColumns.bind(this, '13', 'altitude')} /> <label>Altitude</label><br />
                                <input className="configure_checkbox" type="checkbox" name="vehicle_battery_potentials" value={this.props.gmapsState.column.vehicle_battery_potentials} onChange={this.toggleColumns.bind(this, '11', 'vehicle_battery_potentials')} /> <label>Battery Potential</label><br />
                                <input className="configure_checkbox" type="checkbox" name="coolant_temp" value={this.props.gmapsState.column.coolant_temp} onChange={this.toggleColumns.bind(this, '18', 'coolant_temp')} /> <label>Coolant Temperature</label><br />
                                <input className="configure_checkbox" type="checkbox" name="adblue_tank_capacity" value={this.props.gmapsState.column.adblue_tank_capacity} onChange={this.toggleColumns.bind(this, '23', 'adblue_tank_capacity')} /> <label>DEF Consumption</label><br />
                                <input className="configure_checkbox" type="checkbox" name="catalyst_tank_level" value={this.props.gmapsState.column.catalyst_tank_level} onChange={this.toggleColumns.bind(this, '22', 'catalyst_tank_level')} /> <label>DEF Tank Level</label><br />
                                <input className="configure_checkbox" type="checkbox" name="driver_name" value={this.props.gmapsState.column.driver_name} onChange={this.toggleColumns.bind(this, '24', 'driver_name')} /> <label>Driver Name & Contact</label><br />
                                <input className="configure_checkbox" type="checkbox" name="engine_hours" value={this.props.gmapsState.column.engine_hours} onChange={this.toggleColumns.bind(this, '25', 'engine_hours')} /> <label>Engine Hours</label><br />

                                {/* <input className="configure_checkbox" type="checkbox" name="duration" value={this.props.gmapsState.column.duration} onChange={this.toggleColumns.bind(this, '8', 'duration')} /> <label>Duration</label><br /> */}
                              </div>

                              <div className="col-xl-5 col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                <input className="configure_checkbox" type="checkbox" name="engine_speed" value={this.props.gmapsState.column.engine_speed} onChange={this.toggleColumns.bind(this, '6', 'engine_speed')} /> <label>Engine / Motor Speed</label><br />
                                <input className="configure_checkbox" type="checkbox" name="is_bs6" value={this.props.gmapsState.column.is_bs6} onChange={this.toggleColumns.bind(this, '21', 'is_bs6')} /> <label>E-Norm</label><br />
                                <input className="configure_checkbox" type="checkbox" name="exhaust_temperature" value={this.props.gmapsState.column.exhaust_temperature} onChange={this.toggleColumns.bind(this, '25', 'exhaust_temperature')} /> <label>Exhaust Temp</label><br />
                                <input className="configure_checkbox" type="checkbox" name="fuel_consum" value={this.props.gmapsState.column.fuel_consum} onChange={this.toggleColumns.bind(this, '12', 'fuel_consum')} /> <label>Fuel / Gas / Battery Consumption</label><br />
                                <input className="configure_checkbox" type="checkbox" name="fuel_level" value={this.props.gmapsState.column.fuel_level} onChange={this.toggleColumns.bind(this, '7', 'fuel_level')} /> <label>Fuel / Gas / Battery Level</label><br />
                                <input className="configure_checkbox" type="checkbox" name="vehicle_model" value={this.props.gmapsState.column.vehicle_model} onChange={this.toggleColumns.bind(this, '16', 'vehicle_model')} /> <label>Model</label><br />
                                <input className="configure_checkbox" type="checkbox" name="obu_id" value={this.props.gmapsState.column.obu_id} onChange={this.toggleColumns.bind(this, '15', 'obu_id')} /> <label>OBU ID</label><br />
                                <input className="configure_checkbox" type="checkbox" name="vehicle_odometer" value={this.props.gmapsState.column.vehicle_odometer} onChange={this.toggleColumns.bind(this, '4', 'vehicle_odometer')} /> <label>Odometer</label><br />
                              </div>

                              <div className="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                <input className="configure_checkbox" type="checkbox" name="oil_pressure" value={this.props.gmapsState.column.oil_pressure} onChange={this.toggleColumns.bind(this, '10', 'oil_pressure')} /> <label>Oil Pressure</label><br />
                                <input className="configure_checkbox" type="checkbox" name="sub_valid_from" value={this.props.gmapsState.column.sub_valid_from} onChange={this.toggleColumns.bind(this, '19', 'sub_valid_from')} /> <label>Subscription Valid From</label><br />
                                <input className="configure_checkbox" type="checkbox" name="sub_valid_to" value={this.props.gmapsState.column.sub_valid_to} onChange={this.toggleColumns.bind(this, '20', 'sub_valid_to')} /> <label>Subscription Valid To</label><br />
                                <input className="configure_checkbox" type="checkbox" name="gps_speed" value={this.props.gmapsState.column.gps_speed} onChange={this.toggleColumns.bind(this, '5', 'gps_speed')} /> <label>Vehicle Speed</label><br />
                                <input className="configure_checkbox" type="checkbox" name="vehicle_type" value={this.props.gmapsState.column.vehicle_type} onChange={this.toggleColumns.bind(this, '17', 'vehicle_type')} /> <label>Vehicle Type</label><br />
                                <input className="configure_checkbox" type="checkbox" name="vin_number" value={this.props.gmapsState.column.vin_number} onChange={this.toggleColumns.bind(this, '14', 'vin_number')} /> <label>VIN</label><br />
                                {/* <input className="configure_checkbox" type="checkbox" name="distance_to_empty" value={this.props.gmapsState.column.distance_to_empty} onChange={this.toggleColumns.bind(this, '8', 'distance_to_empty')} /> <label>Distance To Empty</label><br /> */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-5">
                          <div className="col-md-12">
                            <p className="listview_max_4_msg"><span style={{ verticalAlign: "middle" }}>*</span> Maximum of 4 columns can be selected.</p>
                            <div className="button-container">
                              <p className="pull-right">
                                <span className="w200">
                                  <input type="button" className="submit_config_btn" name="submit" onClick={this.save_column_config.bind(this)} value="Submit" />
                                </span>
                                <span className="w200">
                                  <input type="button" className="reset_config_btn" name="reset" onClick={this.reset_column_config.bind(this)} value="Reset" />
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div className="table-responsive table_align tableFixHead">
              {
                this.state.listview_loader || this.props.gmapsState.loader ? <div className="Pageloading"> <img className="loadimg" src="/images/loader_1.gif" alt="iAlert" /></div>
                  :
                  this.state.classic_view ?
                    <table className="table listview_table_design" id="ListViewTable">
                      <thead>
                        <tr className="listview_table_header">
                          <th onClick={this.sort_by_alphabet.bind(this, 'regnNumber')}>Reg.No <i className="fa fa-sort" aria-hidden="true" style={{ marginLeft: "10px" }}></i></th>
                          <th className="listview_status_alert_column" onClick={this.sort_by_alphabet.bind(this, 'status')}>Status <i className="fa fa-sort" aria-hidden="true" style={{ marginLeft: "10px" }} ></i></th>
                          <th onClick={this.sort_by_alphabet.bind(this, 'location')}>Location <i className="fa fa-sort" aria-hidden="true" style={{ marginLeft: "10px" }}></i></th>
                          {this.state.autoreload ? <th onClick={this.sort_by_alphabet.bind(this, 'geofence_name')}>Geofence Name  <i className="fa fa-sort" aria-hidden="true" style={{ marginLeft: "10px" }}></i></th> : ''}
                          <th onClick={this.sort_by_date.bind(this, 'localTimeStamp')}>TimeStamp <i className="fa fa-sort" aria-hidden="true" style={{ marginLeft: "10px" }}></i></th>
                          <th onClick={this.sort_by_numeric.bind(this, 'vehicle_odometer')} className={this.props.gmapsState.column.vehicle_odometer === true ? 'listview_show' : 'listview_hide'}>Odometer <i className="fa fa-sort" aria-hidden="true" style={{ marginLeft: "10px" }} ></i></th>
                          <th onClick={this.sort_by_numeric.bind(this, 'gps_speed')} className={this.props.gmapsState.column.gps_speed === true ? 'listview_show' : 'listview_hide'}>Vehicle Speed <i className="fa fa-sort" aria-hidden="true" style={{ marginLeft: "10px" }} ></i></th>
                          <th onClick={this.sort_by_numeric.bind(this, 'engine_speed')} className={this.props.gmapsState.column.engine_speed === true ? 'listview_show' : 'listview_hide'}>Engine / Motor Speed <i className="fa fa-sort" aria-hidden="true" style={{ marginLeft: "10px" }}></i></th>
                          <th onClick={this.sort_by_numeric.bind(this, 'fuel_level')} className={this.props.gmapsState.column.fuel_level === true ? 'listview_show' : 'listview_hide'}>Fuel / Gas / Battery Level <i className="fa fa-sort" aria-hidden="true" style={{ marginLeft: "10px" }} ></i></th>

                          {/* <th className={this.props.gmapsState.column.duration === true ? 'listview_show' : 'listview_hide'}>Duration </th> */}
                          <th onClick={this.sort_by_numeric.bind(this, 'air_pressure')} className={this.props.gmapsState.column.air_pressure === true ? 'listview_show' : 'listview_hide'}>Air Pressure <i className="fa fa-sort" aria-hidden="true" style={{ marginLeft: "10px" }}></i> </th>
                          <th onClick={this.sort_by_numeric.bind(this, 'oil_pressure')} className={this.props.gmapsState.column.oil_pressure === true ? 'listview_show' : 'listview_hide'}>Oil Pressure <i className="fa fa-sort" aria-hidden="true" style={{ marginLeft: "10px" }}></i> </th>

                          <th onClick={this.sort_by_numeric.bind(this, 'vehicle_battery_potentials')} className={this.props.gmapsState.column.vehicle_battery_potentials === true ? 'listview_show' : 'listview_hide'}>Battery Potential <i className="fa fa-sort" aria-hidden="true" style={{ marginLeft: "10px" }}></i></th>
                          <th onClick={this.sort_by_numeric.bind(this, 'fuel_consum')} className={this.props.gmapsState.column.fuel_consum === true ? 'listview_show' : 'listview_hide'}>Fuel / Gas / Battery Consumption <i className="fa fa-sort" aria-hidden="true" style={{ marginLeft: "10px" }}></i></th>
                          <th onClick={this.sort_by_numeric.bind(this, 'altitude')} className={this.props.gmapsState.column.altitude === true ? 'listview_show' : 'listview_hide'}>Altitude <i className="fa fa-sort" aria-hidden="true" style={{ marginLeft: "10px" }}></i></th>
                          <th onClick={this.sort_by_alphabet.bind(this, 'vin_number')} className={this.props.gmapsState.column.vin_number === true ? 'listview_show' : 'listview_hide'}>VIN <i className="fa fa-sort" aria-hidden="true" style={{ marginLeft: "10px" }}></i></th>

                          <th onClick={this.sort_by_alphabet.bind(this, 'obu_id')} className={this.props.gmapsState.column.obu_id === true ? 'listview_show' : 'listview_hide'}>Obu Id <i className="fa fa-sort" aria-hidden="true" style={{ marginLeft: "10px" }}></i></th>
                          <th onClick={this.sort_by_alphabet.bind(this, 'vehicle_model')} className={this.props.gmapsState.column.vehicle_model === true ? 'listview_show' : 'listview_hide'}>Model <i className="fa fa-sort" aria-hidden="true" style={{ marginLeft: "10px" }}></i></th>
                          <th onClick={this.sort_by_alphabet.bind(this, 'vehicle_type')} className={this.props.gmapsState.column.vehicle_type === true ? 'listview_show' : 'listview_hide'}>Type <i className="fa fa-sort" aria-hidden="true" style={{ marginLeft: "10px" }}></i></th>
                          <th onClick={this.sort_by_numeric.bind(this, 'coolant_temp')} className={this.props.gmapsState.column.coolant_temp === true ? 'listview_show' : 'listview_hide'}>Coolant Temperature <i className="fa fa-sort" aria-hidden="true" style={{ marginLeft: "10px" }}></i></th>
                          <th onClick={this.sort_by_date.bind(this, 'sub_valid_from')} className={this.props.gmapsState.column.sub_valid_from === true ? 'listview_show' : 'listview_hide'}>Subscription Valid From <i className="fa fa-sort" aria-hidden="true" style={{ marginLeft: "10px" }}></i></th>
                          <th onClick={this.sort_by_date.bind(this, 'sub_valid_to')} className={this.props.gmapsState.column.sub_valid_to === true ? 'listview_show' : 'listview_hide'}>Subscription Valid To <i className="fa fa-sort" aria-hidden="true" style={{ marginLeft: "10px" }}></i></th>
                          <th onClick={this.sort_by_numeric.bind(this, 'exhaust_temperature')} className={this.props.gmapsState.column.exhaust_temperature === true ? 'listview_show' : 'listview_hide'}>Exhaust Temp <i className="fa fa-sort" aria-hidden="true" style={{ marginLeft: "10px" }}></i></th>
                          <th onClick={this.sort_by_numeric.bind(this, 'is_bs6')} className={this.props.gmapsState.column.is_bs6 === true ? 'listview_show' : 'listview_hide'}>E-Norm <i className="fa fa-sort" aria-hidden="true" style={{ marginLeft: "10px" }}></i></th>
                          <th onClick={this.sort_by_numeric.bind(this, 'catalyst_tank_level')} className={this.props.gmapsState.column.catalyst_tank_level === true ? 'listview_show' : 'listview_hide'}>DEF Tank Level <i className="fa fa-sort" aria-hidden="true" style={{ marginLeft: "10px" }}></i></th>
                          <th onClick={this.sort_by_numeric.bind(this, 'adblue_tank_capacity')} className={this.props.gmapsState.column.adblue_tank_capacity === true ? 'listview_show' : 'listview_hide'}>DEF Consumption <i className="fa fa-sort" aria-hidden="true" style={{ marginLeft: "10px" }}></i></th>
                          <th onClick={this.sort_by_alphabet.bind(this, 'driver_name')} className={this.props.gmapsState.column.driver_name === true ? 'listview_show' : 'listview_hide'}>Driver Name & Contact <i className="fa fa-sort" aria-hidden="true" style={{ marginLeft: "10px" }}></i></th>
                          <th onClick={this.sort_by_numeric.bind(this, 'engine_hours')} className={this.props.gmapsState.column.engine_hours === true ? 'listview_show' : 'listview_hide'}>Engine Hours <i className="fa fa-sort" aria-hidden="true" style={{ marginLeft: "10px" }}></i></th>
                          <th onClick={this.sort_by_numeric.bind(this, 'exhaust_temp')} className={this.props.gmapsState.column.exhaust_system_high_temp_lamp_command === true ? 'listview_show' : 'listview_hide'}>Exhaust Temp <i className="fa fa-sort" aria-hidden="true" style={{ marginLeft: "10px" }}></i></th>
                          <th className="listview_map_icon"></th>
                        </tr>
                      </thead>
                      {this.props.gmapsState.markers && this.props.gmapsState.markers.length ?
                        <tbody>{this.renderTodos}</tbody>
                        : this.props.gmapsState.vehCount.Service == undefined ? <div className="Pageloading"> <img className="loadimg" src="/images/loader_1.gif" alt="iAlert" /></div> :
                        <h3 className="text-center mt-5">No Data Available</h3>
                      }
                    </table> :
                    //New Listview rendering on switch of view button
                    this.props.gmapsState.markers && this.props.gmapsState.markers.length ?
                      this.state.refreshItem == true ? <div id="newListView">{this.renderTodos}</div> : '' :
                      this.props.gmapsState.vehCount.Service == undefined ? <div className="Pageloading"> <img className="loadimg" src="/images/loader_1.gif" alt="iAlert" /></div> : <h3 className="text-center mt-5">No Data Available</h3>

              }
            </div>
            <div className="row">
              <div className="pagination-tb">
                <select
                  value={this.props.gmapsState.todosPerPage}
                  onChange={this.handleChangeforpagi.bind(this)}
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
              <div className="pagina-length">
                <table>
                  <tbody>
                    <tr id="page_numbers">
                      <td className="pagesize page_symbol_listview" onClick={this.firstPage.bind(this)}>&laquo;</td>
                      <td className="pagesize page_symbol_listview" onClick={this.previousPage.bind(this)}>&#x2039;</td>
                      {renderPageNumbers}
                      <td className="pagesize page_symbol_listview" onClick={this.nextPage.bind(this)}>&#8250;</td>
                      <td className="pagesize page_symbol_listview" onClick={this.lastPage.bind(this)}>&raquo;</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : (
          <div>
            {/* {this.props.gmapsState.markers.length ? ( */}
            <MapWithAMarkerClusterer
              selectedMarker={this.props.gmapsState.selectedMarker}
              onClick={this.handleClick}
              markers={this.props.gmapsState.markers}
              zoomMarkers={this.props.gmapsState.zoomToMarkers}
              vehicledash={this.props.gmapsState.dashenable}
              dialviews={this.props.gmapsState.dialviewshow}
              moreinfovalue={this.redirectPage}
              closedashboardbox={this.closedashboard}
              onchangevalue={() => this.changevaluee(this)}
              poigroups={this.state.group_list}
              poigroupvalue={this.state.groupsval}
              poionchange={(e) => this.groupChange(this, e)}
              poilatlong={this.state.totalarraywithpoi}
              showselectpoi={this.state.showselectedpoires}
              poicheckbox={this.checkboxgroupChange}
              showlabelcheckbox={this.checkboxlabelChange}
              showlabelvals={this.state.showlabelval}
              ttldatecompare={this.state.ttldatetcompare}
              tocheckonclickmap={this.callmapclickfn}
              geofenceCheckbox={this.checkboxGeofenceChange}
              showGeofence={this.state.showGeofence}
              display={this.state.display}
              geofenceList={this.state.geofenceList}
              showGeofenceOption={this.state.showGeofenceOption}
              geofenceOnChange={(e) => this.geofenceChange(this, e)}
              selectall={(e) => this.selectall(e)}
              columnchanges={(e) => this.columnchanges(this, e)}
              circleref={React.createRef()}
              polygon={this.state.polygon}
              circle={this.state.circle}
              polygonbkp={this.state.polygonbkp}
              circlebkp={this.state.circlebkp}
              selectedGeofence={this.state.selectedGeofence}
              polygonCircle={this.state.polygonCircle}
              geofenceLatLong={this.state.geoLatLong}
              coords={this.state.coords}
              radius={this.state.radius}
            >
              <div className="dial-design">
                <div className={this.props.ctr.userrole !== "AL EMPLOYEE" && this.props.ctr.userrole !== "TRANSPORTER" && this.props.ctr.userrole !== "AL USER" ? "img1" : "img1 mouserpointer"}
                  data-status="Geofence" onMouseEnter={this.dialhighlight}
                  menuclickid="3,14" onClick={(e) => this.redirectPage("fleetmanagement/geofence", e)}>

                  <img
                    src="images/Map_screen/Dial_icons/Geofence.png"
                    alt="test"
                    menuclickid="3,14"
                  />
                  <div className="dial-text txt9" menuclickid="3,14">{this.props.gmapsState.howerdialcss === "Geofence" ? <u className="textunderline" menuclickid="3,14">Geofence</u> : "Geofence"}</div>
                </div>
                <div
                  className={this.props.ctr.userrole !== "AL EMPLOYEE" && this.props.ctr.userrole !== "TRANSPORTER" && this.props.ctr.userrole !== "AL USER" ? "img2" : "img2 mouserpointer"}
                  onClick={(e) => this.redirectPage("createTrips", e)} data-status="Trip" onMouseEnter={this.dialhighlight} menuclickid="3,13,1"
                >
                  <img src="images/Map_screen/Dial_icons/create_trip.png" alt="test" menuclickid="3,13,1" />
                  <div className="dial-text txt1" menuclickid="3,13,1">{this.props.gmapsState.howerdialcss === "Trip" ? <u className="textunderline" menuclickid="3,13,1">Create Trip</u> : "Create Trip"}</div>
                </div>
                <div
                  className={this.props.ctr.userrole !== "AL EMPLOYEE" && this.props.ctr.userrole !== "TRANSPORTER" && this.props.ctr.userrole !== "AL USER" ? "img3" : "img3 mouserpointer"}
                  onClick={(e) => this.redirectPage("fleetManagement", e)}
                  data-status="update" onMouseEnter={this.dialhighlight}
                  menuclickid="3,10"
                >
                  <img
                    src="images/Map_screen/Dial_icons/updates.png"
                    alt="test"
                    menuclickid="3,10"
                  />
                  <div className="dial-text txt3" menuclickid="3,10">{this.props.gmapsState.howerdialcss === "update" ? <u className="textunderline" menuclickid="3,10">Update</u> : "Update"}</div>
                </div>
                <div
                  className="img4"
                  onClick={(e) => this.redirectPage("fleetinsightsrep", e)}
                  data-status="Insights" onMouseEnter={this.dialhighlight}
                  menuclickid="12,70,0"

                > <img
                    src="images/Map_screen/Dial_icons/Insights.png"
                    alt="test" menuclickid="12,70,0"
                  />
                  <div className="dial-text txt2" menuclickid="12,70,0">{this.props.gmapsState.howerdialcss === "Insights" ? <u className="textunderline" menuclickid="12,70,0">Insights</u> : "Insights"}</div>
                </div>
                <div
                  className="img5"
                  onClick={(e) => this.redirectPage("vehicleInfo/findDealer", e)}
                  data-status="Near by Places" onMouseEnter={this.dialhighlight}
                  menuclickid="2,27,2"
                >
                  <img
                    src="images/Map_screen/Dial_icons/Dealer.png"
                    alt="test"
                    menuclickid="2,27,2"
                  />
                  <div className="dial-text txt5" menuclickid="2,27,2">{this.props.gmapsState.howerdialcss === "Near by Places" ? <u className="textunderline" menuclickid="2,27,2">Near by Places</u> : "Near by Places"}</div>
                </div>
                <div className="img6"
                  onClick={(e) => this.redirectPage("fleetalerts", e)}
                  data-status="Alerts" onMouseEnter={this.dialhighlight}
                  menuclickid="2,27,2">
                  <img
                    src="images/Map_screen/Dial_icons/Alerts.png"
                    alt="test"
                    menuclickid="2,27,2"
                  />
                  <div className="dial-text txt4" menuclickid="2,27,2">{this.props.gmapsState.howerdialcss === "Alerts" ? <u className="textunderline" menuclickid="2,27,2">Alerts</u> : "Alerts"}</div>
                </div>
                <div
                  className="img7"
                  onClick={(e) => this.redirectPage("vehicleInfo/trend", e)}
                  data-status="Trend" onMouseEnter={this.dialhighlight}
                  menuclickid="2,27,2"
                >
                  <img
                    src="images/Map_screen/Dial_icons/Trends.png"
                    alt="test"
                    menuclickid="2,27,2"
                  />
                  <div className="dial-text txt8" menuclickid="2,27,2">{this.props.gmapsState.howerdialcss === "Trend" ? <u className="textunderline" menuclickid="2,27,2">Trend</u> : "Trend"}</div>
                </div>
                <div
                  className="img8"
                  onClick={(e) => this.redirectPage("vehicleInfo/trace", e)}
                  data-status="Trace" onMouseEnter={this.dialhighlight}
                  menuclickid="2,27,2"
                ><img
                    src="images/Map_screen/Dial_icons/Trace.png"
                    alt="test"
                    menuclickid="2,27,2"
                  />
                  <div className="dial-text txt7" menuclickid="2,27,2">{this.props.gmapsState.howerdialcss === "Trace" ? <u className="textunderline" menuclickid="2,27,2">Trace</u> : "Trace"}</div>

                </div>
                <div
                  className="img9"
                  onClick={() => this.loadvehdash()}

                ><img src="images/Map_screen/Dial_icons/Ddash_Board.png"
                  alt="test"
                  data-status="DashBoard" onMouseEnter={this.dialhighlight}
                  />
                  <div className="dial-text txt6" menuclickid="2,27,2">{this.props.gmapsState.howerdialcss === "DashBoard" ? <u className="textunderline" menuclickid="2,27,2">Dashboard</u> : "Dashboard"}</div>

                </div>
              </div>
            </MapWithAMarkerClusterer>
            {/* ) : (
                   <MapWithAMarkerClusterer
                    selectedMarker={this.props.gmapsState.selectedMarker}
                    onClick={this.handleClick}
                    markers={this.props.gmapsState.markers}
                  />
                )} */}
          </div>
        )}

        <div className="ribbon-box">
          <div className="row even-space">
            <div
              className={
                this.props.gmapsState.Vehiclestatushigh === "Total"
                  ? " rib-sts-tot ribbon-count active_footer"
                  : "rib-sts-tot ribbon-count"
              }
              value="Running"
              onClick={() => {
                this.clickstatus("Total");
              }}
            >
              {" "}
              <div>
                <img
                  src="images/Map_screen/Truck_Fleet.png"
                  alt="Total"

                />{" "}
              </div>
              <div className="veh_status_all">Total</div>
              <div className="veh_status_cnt_all">
                {this.props.gmapsState.vehCount ? this.props.gmapsState.vehCount.Total : null}{" "}
              </div>{" "}
            </div>
            <div
              className={
                this.props.gmapsState.Vehiclestatushigh === "RUNNING"
                  ? "rib-sts-all ribbon-count active_footer"
                  : "rib-sts-all ribbon-count"
              }
              onClick={() => {
                this.clickstatus("RUNNING");
              }}
            >
              <img
                src="images/Map_screen/Running.png"
                alt="Running"

              />{" "}
              <div className="veh_status_run">Running</div>{" "}
              <div className="veh_status_cnt_run">
                {this.props.gmapsState.vehCount ? this.props.gmapsState.vehCount.Running : null}
              </div>
            </div>
            <div
              className={
                this.props.gmapsState.Vehiclestatushigh === "IDLING"
                  ? "rib-sts-idl ribbon-count active_footer"
                  : "rib-sts-idl ribbon-count"
              }
              onClick={() => {
                this.clickstatus("IDLING");
              }}
            >
              <img
                src="images/Map_screen/Idling.png"
                alt="vehicles"

              />
              <div className="veh_status_idl">Idling </div>
              <div className="veh_status_cnt_idl">
                {" "}
                {this.props.gmapsState.vehCount ? this.props.gmapsState.vehCount.Idling : null}
              </div>{" "}
            </div>
            <div
              className={
                this.props.gmapsState.Vehiclestatushigh === "STOPPED"
                  ? "rib-sts-all ribbon-count active_footer"
                  : "rib-sts-all ribbon-count"
              }
              onClick={() => {
                this.clickstatus("STOPPED");
              }}
            >
              <img
                src="images/Map_screen/Stopped.png"
                alt="vehicles"

              />
              <div className="veh_status_stp">Stopped </div>
              <div className="veh_status_cnt_stp">
                {this.props.gmapsState.vehCount ? this.props.gmapsState.vehCount.Stopped : null}
              </div>{" "}
            </div>
            {this.props.isEVVehicelAvailable && <div
              className={
                this.props.gmapsState.Vehiclestatushigh === "CHARGING"
                  ? "rib-sts-char ribbon-count active_footer"
                  : "rib-sts-char ribbon-count"
              }
              onClick={() => {
                this.clickstatus("CHARGING");
              }}
            >
              <img
                src="images/Map_screen/Charging.png"
                alt="vehicles"

              />
              <div className="veh_status_char">Charging</div>
              <div className="veh_status_cnt_char">
                {" "}
                {this.props.gmapsState.vehCount ? this.props.gmapsState.vehCount.Charging : null}
              </div>{" "}
            </div>}
            <div
              className={
                this.props.gmapsState.Vehiclestatushigh === "DISCONNECTED"
                  ? "rib-sts-discont ribbon-count active_footer"
                  : "rib-sts-discont ribbon-count"
              }
              onClick={() => {
                this.clickstatus("DISCONNECTED");
              }}
            >
              <img
                src="images/Map_screen/Disconnected.png"
                alt="vehicles"

              />
              <div className="veh_status_dst"> Disconnected </div>
              <div className="veh_status_cnt_dst">
                {this.props.gmapsState.vehCount ? this.props.gmapsState.vehCount.Disconnected : null}
              </div>
            </div>
            <div
              className={
                this.props.gmapsState.Vehiclestatushigh === "TOWED"
                  ? "rib-sts-twd ribbon-count active_footer"
                  : "rib-sts-twd ribbon-count"
              }
              onClick={() => {
                this.clickstatus("TOWED");
              }}
            >
              <img
                src="images/Map_screen/Towed.png"
                alt="vehicles"

              />
              <div className="veh_status_gps"> Towed</div>
              <div className="veh_status_cnt_gps">
                {this.props.gmapsState.vehCount ? this.props.gmapsState.vehCount.Towed : null}
              </div>
            </div>
            <div
              className={
                this.props.gmapsState.Vehiclestatushigh === "NoGPS"
                  ? "rib-sts-twd ribbon-count  active_footer"
                  : "rib-sts-twd ribbon-count"
              }
              onClick={() => {
                this.clickstatus("NoGPS");
              }}
            >
              <img
                src="images/Map_screen/No_GPS.png"
                alt="vehicles"

              />
              <div className="veh_status_twd">No gps</div>{" "}
              <div className="veh_status_cnt_twd">
                {this.props.gmapsState.nogpscountvals !== '' ? this.props.gmapsState.nogpscountvals === undefined ? 0 : this.props.gmapsState.nogpscountvals : <img className="map-loadergif" src="images/circle_loader_blue.gif" alt="iAlert" />}
              </div>
            </div>
            <div
              className={
                this.props.gmapsState.Vehiclestatushigh === "Critical"
                  ? "rib-sts-crt ribbon-count active_footer"
                  : "rib-sts-crt ribbon-count"
              }
              onClick={() => {
                this.clickstatus("Critical");
              }}
            >
              <img
                src="images/Map_screen/Critical.png"
                alt="vehicles"

              />
              <div className="veh_status_crtl">Critical</div>{" "}
              <div className="veh_status_cnt_crtl">
                {this.props.gmapsState.vehCount
                  ? this.props.gmapsState.vehCount.Critical
                    ? this.props.gmapsState.vehCount.Critical
                    : this.props.gmapsState.vehCount.Critical === 0 ? 0 : <img className="map-loadergif" src="images/circle_loader_blue.gif" alt="iAlert" />
                  : this.props.gmapsState.vehCount.Critical === 0 ? 0 : <img className="map-loadergif" src="images/circle_loader_blue.gif" alt="iAlert" />}
              </div>
            </div>
            <div
              className={
                this.props.gmapsState.Vehiclestatushigh === "Warning"
                  ? "rib-sts-wrt ribbon-count active_footer"
                  : "rib-sts-wrt ribbon-count"
              }
              onClick={() => {
                this.clickstatus("Warning");
              }}
            >
              <img
                src="images/Map_screen/Warning.png"
                alt="vehicles"

              />
              <div className="veh_status_wrt">Warning</div>{" "}
              <div className="veh_status_cnt_wrt">
                {this.props.gmapsState.vehCount
                  ? this.props.gmapsState.vehCount.Warning
                    ? this.props.gmapsState.vehCount.Warning
                    : this.props.gmapsState.vehCount.Warning === 0 ? 0 : <img className="map-loadergif" src="images/circle_loader_blue.gif" alt="iAlert" />
                  : this.props.gmapsState.vehCount.Warning === 0 ? 0 : <img className="map-loadergif" src="images/circle_loader_blue.gif" alt="iAlert" />}
              </div>
            </div>
            <div
              className={
                this.props.gmapsState.Vehiclestatushigh === "Service"
                  ? "rib-sts-serv ribbon-count active_footer"
                  : "rib-sts-serv ribbon-count"
              }
              onClick={() => {
                this.clickstatus("Service");
              }}
            >
              <img
                src="images/Map_screen/service.png"
                alt="vehicles"

              />
              <div className="veh_status_serv"> Service Due</div>{" "}

              <div className="veh_status_cnt_serv">
                {this.props.gmapsState.vehCount
                  ? this.props.gmapsState.vehCount.Service
                    ? this.props.gmapsState.vehCount.Service
                    : this.props.gmapsState.vehCount.Service === 0 ? 0 : <img className="map-loadergif" src="images/circle_loader_blue.gif" alt="iAlert" />
                  : this.props.gmapsState.vehCount.Service === 0 ? 0 : <img className="map-loadergif" src="images/circle_loader_blue.gif" alt="iAlert" />}
              </div>
            </div>
          </div>
          {/* <div onClick={e => {this.resettheresult();}}>test</div>  */}
        </div>
        <AlertBox show={showAlertBox} showMessage={showMessage}
          click={this.hideModal} messageType={messageType}></AlertBox>
      </div>

    );
  }
}

const mapStateToprops = state => {
  return {
    ctr: state.userdata.id,
    searchresult: state.menus.globalSearch,
    gmapsState: state.GmapsReducer,
    togglevalues: state.menus.refresh,
    togglerefresh: state.menus.onlyrefresh,
    setlistview: state.menus.menulist,
    sud: state.menus.searchupdatedata,
    listviewshows: state.menus.listviewshown,
    isEVVehicelAvailable: state.menus.isEVEnormExits,
  };
};

const mapDispatchToprops = dispatch => {
  return {
    quicklinks: (data) => dispatch({ type: 'QUICKLINK', value: data }),
    ctrs: () => dispatch({ type: "VehicleDatas", value: uservehicledate }),
    gmapsDispatch: (payload) => dispatch(GmapsDispatchThunk(payload)),
    onlyrefresh: (data) => dispatch({ type: 'ONLYREFRESH', value: data }),
    setlistviewval: (data) => dispatch({ type: 'MENULIST', value: data }),
    searchresultbox: (data) => dispatch({ type: 'SEARCHBOXCNT', value: data }),
    listviewshown: () => dispatch({ type: 'listviewshow', value: shownlistview }),
    searchupdatedata: (data) => dispatch({ type: 'searchupdatedata', value: data })
  };
};

export { uservehicledate };
export default connect(mapStateToprops, mapDispatchToprops)(gmapsApp, MapWithAMarkerClusterer);




