import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import TraceScreen from '../Trace/TraceScreen';
import TrendScreen from '../Trend/TrendScreen';
import TrendCompare from '../TrendCompare/TrendCompare';
import FindDealer from '../dealer/leftmenu';
import VehicleDashboard from '../VehicleDashboard/vehicledashboard';
import { connect } from 'react-redux';
import { createBrowserHistory as createHistory } from 'history'
import './Vehicleinfo.scss';
import * as alasql from 'alasql';
import XLSX from 'xlsx';
import { exceldatetimestampformatter, excelFileNameDateTime } from './../../Util'
import moment from 'moment';
import VehicleLinkGeneration from '../Tracking/VehicleLinkGeneration'
import '../Tracking/VehicleLinkGeneration.scss';
import VehicleCopyLink from '../Tracking/VehicleCopyLink'
import { exceldateformatter } from "../../Util";

alasql.utils.isBrowserify = false;
alasql.utils.global.XLSX = XLSX;

const history = createHistory();
class Vehicleinfo extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeScreen: '',
            excelData: '',
            vehData: '',
            selecteddatetime: '',
            downloadbtn: false,
            selectedvitals: '',
            downloadbutton: true,
            showboxupdated: false,
            showminiToolar:false,
            showShareDialog: false,
            showCopyLinkModelDialog: false,
            link:"",
            odoValue: '',
        };
        this.updateactiveScreen = this.updateactiveScreen.bind(this);
        this.activeData = this.activeData.bind(this);
        this.getDataforExcel = this.getDataforExcel.bind(this);
        this.selecteddatetime = this.selecteddatetime.bind(this);
        this.selectedvitals = this.selectedvitals.bind(this);
        this.updateVehData = this.updateVehData.bind(this);
        this.showModalDialog = this.showModalDialog.bind(this);
        this.hideModalDialog = this.hideModalDialog.bind(this);
        this.showCopyLinkModelDialog = this.showCopyLinkModelDialog.bind(this);
        this.hideCopyLinkModelDialog = this.hideCopyLinkModelDialog.bind(this);
    }
    showModalDialog = () => {
        this.setState({ showShareDialog: true });
      };

    showCopyLinkModelDialog = () => {
    this.setState({ showCopyLinkModelDialog: true });
    };

    hideModalDialog = () => {
        this.setState({ showShareDialog: false });
    };

    showCopyLinkDialog = (shareLink) => {
        this.setState({ showShareDialog: false});
        if (shareLink && shareLink != null) {
            this.setState({ link: shareLink});
            this.showCopyLinkModelDialog()
        }
    };
    hideCopyLinkModelDialog = () => {
        this.setState({ showCopyLinkModelDialog: false });
    };

    getDataforExcel = (excelData) => {
        if (window.location.href.toLowerCase().indexOf('/trend') > -1) {
            let listofvitals = (Object.keys(excelData))
            for (let i = 0; i < listofvitals.length; i++) {
                if ((excelData[listofvitals[i]].length) !== 0) {
                    this.setState({
                        excelData: excelData,
                        downloadbtn: true
                    });
                } else {
                    this.setState({
                        downloadbtn: false
                    });
                }
            }
        } else {
            if (excelData.length !== 0) {
                //console.log(excelData);
                this.setState({
                    excelData: excelData,
                    downloadbtn: true
                });
            } else {
                this.setState({
                    downloadbtn: false
                });
            }
        }
    }

    disableDownBtn = () => {
        this.setState({
            downloadbtn: false
        });
    }


    selecteddatetime = (dateTime) => {
        // console.log(dateTime);
        this.setState({
            selecteddatetime: dateTime
        });
    }

    updateVehData = () => {
        this.setState({
            vehData: this.props.ctr.selectedVehicle,
            downloadbtn: true,
            showboxupdated: true,
        });
    }

    setOdoValue = (value) => {
        this.setState({
            odoValue:value
        })
    }

    selectedvitals = () => {
        //console.log(this.props)
        this.setState({
            vitalarray: []
        });
    }

    updateactiveScreen = () => {
        if (this.props.ctr.selectedVehicle === undefined) {
            this.setState({ showboxupdated: false })
        } else {
            this.setState({ showboxupdated: true })
        }
        let path = window.location.pathname;
        if (path.indexOf('vehicleInfo/trend') > -1) {
            this.setState({ activeScreen: 'trend' });
        } else if (path.indexOf('vehicleInfo/trace') > -1) {
            this.setState({ activeScreen: 'trace' });
        } else if (path.indexOf('vehicleInfo/alerts') > -1) {
            this.setState({ activeScreen: 'alerts' });
        } else if (path.indexOf('vehicleInfo/findDealer') > -1) {
            this.setState({ activeScreen: 'findDealer' });
        } else if (path.indexOf('vehicleInfo/chats') > -1) {
            this.setState({ activeScreen: 'chats' });
        } else if (path.indexOf('vehicleInfo/VehicleDash') > -1) {
            this.setState({ activeScreen: 'vehicledash' });
        } else { }
    }

    activeData = (dataVal) => {
        this.setState({
            downloadbtn: false,
            downloadbutton: true
        });
        if(dataVal === "fleetalerts"){
            this.props.history.push("/fleetalerts")
        }
        if(dataVal ==="findDealer" || dataVal ==="trendcompare" ){
            this.setState({downloadbutton:false}); 
        }
        this.setState({ activeScreen: dataVal });
    }

    updateValues = (val, value) => {
        if (value === 'ignition') {
            val.ignition = val.ignition === 1 ? "ON" : "OFF";
        }
        return val;
    }

    updateExcel = () => {
        let selectedfndate = moment(this.state.selecteddatetime.from).format('DD-MM-YYYY hh:mm:ss A');
        let selectedtndate = moment(this.state.selecteddatetime.to).format('DD-MM-YYYY hh:mm:ss A');
        let vehdetails = this.props.ctr.selectedVehicle
        let vehiclereg = vehdetails.regnNumber;
        let vehiclemodel = vehdetails.vehicleModel;
        let vehiclestatus = vehdetails.currentInfo.status;
        let vehicledriver = vehdetails.currentInfo.location;

        if (window.location.href.toLowerCase().indexOf('/trend') > -1) {
            let datalist = []
            let opts = [];
            let vehicle_Details = [
                {
                    "Registration": vehiclereg,
                    "Model": vehiclemodel,
                    "Status": vehiclestatus,
                    "Location": vehicledriver
                },
                { "Registration": "", "Model": "" },
                { "Registration": "Duration Details", "Model": "" },
                { "Registration": "From", "Model": "To" },
                { "Registration": selectedfndate, "Model": selectedtndate }]
            let content = { vehicle_Details };
            let sheetval = { ...content, ...this.state.excelData };
            let listofvitals = (Object.keys(sheetval))
            for (let i = 0; i < listofvitals.length; i++) {
                let compList = sheetval[listofvitals[i]]
                datalist[i] = compList;
                opts.push({ sheetid: listofvitals[i] === 'BATTERY_MAX_CELL_TEMPERATURE' ? 'BATTERY_MAX_TEMPERATURE': listofvitals[i] , header: true });

                const keyMapping = {
                    'FRONT_AIR_PRESSURE': 'FRONT_AIR_PRESSURE(bar)',
                    'REAR_AIR_PRESSURE': 'REAR_AIR_PRESSURE(bar)',
                    'ALTITUDE': 'ALTITUDE(m)',
                    'BATTERY_POTENTIAL': 'BATTERY_POTENTIAL(v)',
                    'COOLANT_TEMPERATURE': 'COOLANT_TEMPERATURE(°C)',
                    'DEF_CONSUMPTION': 'DEF_CONSUMPTION(ltr)',
                    'DEF_TANK_LEVEL': 'DEF_TANK_LEVEL(ltr)',
                    'DEF_TANK_TEMPERATURE': 'DEF_TANK_TEMPERATURE(°C)',
                    'ENGINE_HOURS': 'ENGINE_HOURS(hrs)',
                    'ENGINE_SPEED': 'ENGINE_SPEED(rpm)',
                    'GAS_CONSUMPTION': 'GAS_CONSUMPTION(kg)',
                    'GAS_LEVEL': 'GAS_LEVEL(bar/kg)',
                    'EXHAUST_TEMPERATURE': 'EXHAUST_TEMPERATURE(°C)',
                    'FUEL_CONSUMPTION': 'FUEL_CONSUMPTION(ltr)',
                    'FUEL_LEVEL': 'FUEL_LEVEL(ltr)',
                    'ODOMETER': 'ODOMETER(km)',
                    'ENGINE_OIL_PRESSURE': 'ENGINE_OIL_PRESSURE(bar)',
                    'VEHICLE_SPEED': 'VEHICLE_SPEED(km/hr)',
                    'BATTERY_COOLANT_OUT_TEMPERATURE' : 'BATTERY_COOLANT_OUT_TEMPERATURE(°C)',
                    'BATTERY_MAX_CELL_TEMPERATURE': 'BATTERY_MAX_TEMPERATURE(°C)',
                    'MAIN_COOLANT_TEMP': 'MAIN_COOLANT_TEMP(°C)',
                    'MOTOR_SPEED_MCU': 'MOTOR_SPEED(rpm)',
                    'PACK_VOLTAGE': 'PACK_VOLTAGE(volts)',
                    'TOTAL_DC_DC_ENERGY_CONSUMPTION': 'TOTAL_DC_DC_ENERGY_CONSUMPTION(kWh)',
                    'VEHICLE_TRANSMISSION_MODE': 'VEHICLE_TRANSMISSION_MODE',
                    'SOC': 'SOC(%)',
                  };
                  datalist[i].flat().map(obj => {
                    for (const oldKey in keyMapping) {
                      if (oldKey in obj) {
                        obj[keyMapping[oldKey]] = obj[oldKey];
                        if(oldKey !== 'VEHICLE_TRANSMISSION_MODE'){
                            delete obj[oldKey];
                        }
                      }
                    }
                    return obj;
                  });   
            }
            alasql('SELECT INTO XLSX("TREND_' + vehiclereg + ' ' + selectedfndate + ' to ' + selectedtndate + '.xlsx",?) FROM ?', [opts, datalist]);
        }
        else if (window.location.href.toLowerCase().indexOf('/trace') > -1) {

            let vD = this.state.vehData;
            alasql.fn.checkOdoVal = function (val1, val2) {
                return vD.vehicleType === 'EEA' ? val1 : val2;
            }
            alasql.fn.updateStatus = function (val) {
                return val === 0 ? 'RUNNING' : (val === 1 ? 'STOPPED' : (val === 2 ? 'IDLING' : 'TOWED'));
            }
            alasql.fn.roundVal = function (val) {
                return Math.round(val);
            }
            alasql.fn.checkIgnition = function (val) {
                return val ? 'ON' : 'OFF';
            }
            alasql.fn.isNull = function (val) {
                return val ? val.toFixed(1) : 0;
            }
            alasql.fn.updateTimeStamp = function (val) {
                var datefunctionfrt = val.split(" ");
                var datefunctionfrtval = datefunctionfrt[0];
                var timefunctionfrtval = datefunctionfrt[1];
                var dateonlyfn = datefunctionfrtval.split("-");

                let formatted_date = dateonlyfn[2] + "-" + dateonlyfn[1] + "-" + dateonlyfn[0] + " " + timefunctionfrtval;
                return formatted_date;
            }

            alasql.fn.getExcelDate = function (date) {
                return exceldatetimestampformatter(date)
            }
            alasql.fn.getFuelLevelValue = (fuelLevel) => {
                return (fuelLevel && !isNaN(fuelLevel)) ? parseFloat(fuelLevel).toFixed(1) : '0';
              }
            var bs6exceldwn;
            if (this.props.ctr.selectedVehicle.isBS6 === 1 || this.props.ctr.selectedVehicle.isBS6 === 3) {
                bs6exceldwn = "defsVal AS [DEF Consumption(ltr)], catalystTankLevel AS [DEF Level(ltr)],"
            } else {
                bs6exceldwn = "";
            }

            var ev_params;
            if(this.props.ctr.selectedVehicle.isBS6 === 4){
                ev_params = "roundVal(motorspeed) AS [Motor Speed(rpm)], battery_status AS [Battery Status(%)], getFuelLevelValue(energy_consumed) AS [Energy Consumption(kwh)], distance_to_empty AS [Distance to Empty(km)], main_coolant_temp AS [Main coolant temperature(°C)], battery_coolant_out_temp AS [Battery Coolant Out Temperature(°C)],"
            }else{
                ev_params = "roundVal(enginespeed) AS [Engine Speed(rpm)],"
            }

            let vehdetails = this.props.ctr.selectedVehicle,
                statVal = moment(this.state.selecteddatetime).format('DD-MM-YYYY hh:mm:ss A');
            let sheet1 = {}, sheetArr = [];
            sheet1.Registration = vehdetails.regnNumber;
            sheet1.VehicleModel = vehdetails.vehicleModel;
            sheet1.VehicleType = vehdetails.vehicleType;
            sheet1.Status = vehdetails.currentInfo.status;
            sheet1.Location = vehdetails.currentInfo.location;
            sheet1.StartDate = exceldateformatter(this.state.selecteddatetime.fromDate);
            sheet1.EndDate = exceldateformatter(this.state.selecteddatetime.toDate);
            sheet1.StartTime = this.state.selecteddatetime.fromTime;
            sheet1.EndTime = this.state.selecteddatetime.toTime;
            sheetArr.push(sheet1);
            let st = exceldatetimestampformatter(this.state.selecteddatetime.fromDate +' '+ this.state.selecteddatetime.fromTime), et = exceldatetimestampformatter(this.state.selecteddatetime.toDate +' '+ this.state.selecteddatetime.toTime);
            if (st && et) {
                st = st.replace(/:/g, '_');
                et = et.replace(/:/g, '_');
            }

            let nonEvFuelGasLevel;
            let nonEvFuelGasConsumption;
            let vehicleSpeed;
            if (this.props.ctr.selectedVehicle.isBS6 == 4) {
                nonEvFuelGasLevel = "";
                nonEvFuelGasConsumption = "";
                vehicleSpeed = "roundVal(vehiclespeed) AS [Vehicle Speed(km/hr)]"
            } else {
                nonEvFuelGasLevel = "isNull(fuellevel) AS [Fuel(ltr) / Gas(bar/kg) Level]"
                nonEvFuelGasConsumption = "isNull(fuelVal) AS [Fuel(ltr) / Gas Consumption(kg)]"
                vehicleSpeed = "roundVal(gpsspeed) AS [Vehicle Speed(km/hr)]"
            }
            //let vDetails = alasql("SELECT ");
            
            let bb = alasql("SELECT altitude AS [Altitude(m)], latitude AS Latitude, longitude AS Longitude, getExcelDate(localtimestamp) AS TimeStamp, checkIgnition(ignition) AS [Ignition Status], " + ev_params + "" + (vehicleSpeed ? vehicleSpeed + ", " : "") + " " + (nonEvFuelGasLevel ? nonEvFuelGasLevel + ", " : "") + " updateStatus(vehstatus) AS [Vehicle Status], isNull(distance) AS [Distance Travel(km)], " + (nonEvFuelGasConsumption ? nonEvFuelGasConsumption + ", " : "") + " "
                + bs6exceldwn + " location AS Location FROM ?", [this.state.excelData]);

            var opts = [{ sheetid: 'Trace Data', header: false }, { sheetid: 'Vehicle Details', header: true }];
            let fileName = excelFileNameDateTime("TRACE_" + sheet1.Registration, st, et );

            alasql.promise('SELECT INTO XLSX("' + fileName + '", ?) FROM ?', [opts, [bb, sheetArr]])
                .then(function (data) {
                    //debugger
                    //console.log(data);
                }).catch(function (err) {
                    //console.log('Error:', err);
                });
        }
        else {
            let vehdetails = this.props.ctr.selectedVehicle,
                statVal = moment(this.state.selecteddatetime).format('DD-MM-YYYY hh:mm:ss A');

            let sheet1 = {}, sheetArr = [];
            sheet1.Registration_Number = vehdetails.regnNumber;
            sheet1.Vehicle_Model = vehdetails.vehicleModel;
            sheet1.Vehicle_Type = vehdetails.vehicleType;
            sheet1.Status = vehdetails.currentInfo.status;
            sheet1.Location = vehdetails.currentInfo.location;
            sheetArr.push(sheet1);
            alasql.fn.updateTimeStamp = function (val) {
                var datefunctionfrt = val.split(" ");
                var datefunctionfrtval = datefunctionfrt[0];
                var timefunctionfrtval = datefunctionfrt[1];
                var dateonlyfn = datefunctionfrtval.split("-");
                let formatted_date = dateonlyfn[2] + "-" + dateonlyfn[1] + "-" + dateonlyfn[0] + " " + timefunctionfrtval;
                return formatted_date;
            }

            alasql.fn.nullCheck = function (val) {
                if (val === null || val === ' : 0') {
                    return 'N/A';
                }
                else {
                    return val;
                }
            }


            let bb = alasql("SELECT severity AS Severity, alert_type_name AS [Alert Name], vin AS Vin, regn_number as [Registration Number], obu_id as ObuId,alert_type_id as AlertTypeID ,updateTimeStamp(start_timestamp_utc) AS TimeStamp, start_location AS Location,nullCheck(alert_info_1) as alertInfo1,nullCheck(alert_info_2) as alertInfo2,nullCheck(alert_info_3) as alertInfo3 FROM ?", [this.state.excelData]);

            var opts = [{ sheetid: 'Alerts Data', header: false }, { sheetid: 'Vehicle Details', header: true }];
            let fileName = "Alerts_" + sheet1.Registration_Number;

            alasql.promise('SELECT INTO XLSX("' + fileName + '", ?) FROM ?', [opts, [bb, sheetArr]])
                .then(function (data) {
                    //debugger
                    //console.log(data);
                }).catch(function (err) {
                    console.log('Error:', err);
                });
        }
    }

    toogleToolBar = () =>{
        this.setState({ 
            // showboxupdated: this.state.showboxupdated?false:true,
            showminiToolar:this.state.showminiToolar?false:true
        })
    }
    

    componentDidMount() {
        if (window.location.href.toLowerCase().indexOf('/finddealer') > -1) {
            this.setState({ downloadbutton: false })
        }

        if (this.props.ctr.selectedVehicle) {
            this.setState({
                vehData: this.props.ctr.selectedVehicle
            });
        }
        this.updateactiveScreen();
    }

    componentDidUpdate(prevProps, prevState) {
        if(window.location.pathname === '/vehicleInfo/VehicleDash'){
            history.replace({ pathname: '/vehicleInfo/VehicleDash' })
            history.replace({ ...history.location, state: {} });
        }
        if(window.location.pathname === '/vehicleInfo/findDealer'){
            history.replace({ pathname: '/vehicleInfo/findDealer' })
            history.replace({ ...history.location, state: {vin_no: this.props.location.state ? this.props.location.state.vin_no : ''} });
        }
    }

    render() {
        const vD = this.state.vehData;
        const currentInfo = vD.currentInfo !== undefined ? vD.currentInfo : vD;
        let formattedLocalTimeStamp = moment(currentInfo.localTimeStamp).format('DD-MM-YYYY hh:mm:ss A')
        let shareButton;
        if (this.state.activeScreen === 'trace' ) {
            shareButton = <><button className={this.state.downloadbutton === true ?
                'common-button ffub12 custDownload' : 'hideBtn'}
                onClick={this.showModalDialog}>Share &#x00A0;
                <i className="fa fa-share-alt" aria-hidden="true"></i>
            </button></> 
        }
        return (<div className="vehicleInfocss">
            <div className="vehicleInfo">
                <Router history={history}>
                    <div className="infoBlock">
                        <div className={this.state.showminiToolar == false?"contentBlock":"contentBlock contBlockmin"}>
                            <div className={this.state.showminiToolar == false?"container1":"container_1"}>
                            {this.state.showminiToolar == false ? <span onClick={this.toogleToolBar} className="fa fa-minus-square min-icon" ></span>:<sapn onClick={this.toogleToolBar}><img className="ar-down-img" src="/images/Trace/arrow-down.png" alt="arrow-down" /></sapn>}
                                <div className="firstCont">
                                    {
                                        vD ? <p>
                                            <span className="ffum21 vehNum">{vD.regnNumber}</span>
                                            {currentInfo.ignitionStatus === 1 ?
                                                <span className={currentInfo.status == "IDLING"?"status_idl ffu10 ml25":"status ffu10 ml25"}> ON </span> :
                                                <span className="statuss ffu10 ml25"> OFF </span>}
                                            <span className="runstatus ffu13">{currentInfo.status}</span> | 
                                            <span className="ffub13"> Model </span>
                                            <span className="ffu13">{vD.vehicleModel}</span> | 
                                            <span className="ffub13"> Reporting Time </span>
                                            <span className="ffu13">{formattedLocalTimeStamp}</span> | 
                                            <span className="ffub13"> Current Location </span>
                                            <span style={{ width: '431px', display: 'inline-block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', margin: '-6px 0px' }}
                                                className="ffu13" title={currentInfo.location} >
                                                {currentInfo.location} </span></p> : <p>
                                            <span className="ffum21 vehNum"> XX00XX0000 </span>
                                            <span className="statuss ffu10 ml25"> XX </span>
                                            <span className="runstatus ffu13"> XXXXXXX </span> |  
                                            <span className="ffub13"> XXXXX </span>
                                            <span className="ffu13"> 0000/0 XX0 X </span> |  
                                            <span className="ffub13"> Reporting Time </span>
                                            <span className="ffu13"> 00/00/0000 00:00:00 </span> | 
                                            <span className="ffub13"> Currents Location </span>
                                            <span className="ffu13"> Xxxxxxxx, XX </span>
                                            {this.state.showminiToolar?<div>hi
                                            </div>:null}
                                            </p>
                                            
                                    }
                                    {/* <p><i className="fa fa-arrow-left" aria-hidden="true"></i> &#x00A0; &#x00A0; <span className="ffum21 vehNum">TN12AB6782</span> <span className="status ffu10 ml25">ON</span> <span className="runstatus ffu13">Running</span> | <span className="ffub13">Model</span> <span className="ffu13">1618/4 BS4 H</span> | <span className="ffub13">Current Location</span> <span className="ffu13">Thiruvanthapuram, KL</span> | <span className="ffub13">Index Time</span> <span className="ffu13">07/10/2019 12:15:57</span> 
                                    <span className="ffub12 color-black common-button ml25 vehicle_but">Change Vehicle</span></p> */}
                                </div>
                                {this.state.showboxupdated === true && this.state.showminiToolar == false ?
                                    <div className="secondCont">
                                        <ul className="listNone dispin ulClass ffum15">
                                            {this.props.uD.userrole === "DEALER OUTLET ADMIN" ? '' :
                                                <Link to="/vehicleInfo/trace">
                                                    <li className={this.state.activeScreen === 'trace' ? 'active' : ''} onClick={() => this.activeData('trace')}>
                                                        <i className="fa fa-map-marker" aria-hidden="true"></i> &#x00A0; Trace
                                                    </li>
                                                </Link>}

                                            <Link to="/vehicleInfo/trend">
                                                <li className={this.state.activeScreen === 'trend' ? 'active' : ''}
                                                    onClick={() => this.activeData('trend')}>
                                                    <i className="fa fa-line-chart" aria-hidden="true"></i>&#x00A0; Trend
                                                </li>
                                            </Link>
                                            <Link to="/vehicleInfo/trendcompare">
                                                <li className={this.state.activeScreen==='trendcompare' ? 'active' : '' } 
                                                    onClick={()=>this.activeData('trendcompare')}>
                                                    <i className="fa fa-bar-chart" aria-hidden="true">
                                                    </i>
                                                     &#x00A0; KPI Compare</li>
                                                     {/* <span className="btn-new-info">New</span> */}
                                            </Link> 
                                        
                                            <Link to="/vehicleInfo/VehicleDash">
                                                <li className={this.state.activeScreen === 'vehicledash' ? 'active' : ''}
                                                    onClick={() => this.activeData('vehicledash')}>
                                                    <i className="fa fa-exclamation" aria-hidden="true"></i> &#x00A0;Vehicle Dashboard
                                                </li>
                                            </Link>
                                            {this.props.uD.userrole === "DEALER OUTLET ADMIN" ? '' :
                                                <li className={this.state.activeScreen === 'findDealer' ? 'active' : ''}
                                                    onClick={() => this.activeData('findDealer')}>
                                                    <Link to="/vehicleInfo/findDealer">
                                                    <span
                                                        className={this.state.activeScreen === 'findDealer' ? "fa near_by_image near_by_image_active" : "fa near_by_image near_by_image_in_active"}
                                                        /> &#x00A0; Near by Places
                                                        {/* <i className="fa fa-wrench" aria-hidden="true"></i> &#x00A0; Near by Places */}
                                                        </Link>
                                                </li>}
                                        </ul>
                                        {shareButton}
                                        {this.state.activeScreen !== "vehicledash" ?
                                            <button className={this.state.downloadbutton === true ?
                                                'common-button ffub12 custDownload' : 'hideBtn'}
                                                disabled={this.state.downloadbtn ? false : true}
                                                onClick={this.updateExcel}>Download &#x00A0;
                                                <i className="fa fa-download" aria-hidden="true"></i>
                                            </button> : ''}
                                        {/* <button className="common-button ffub12 custDownload"  disabled={ this.state.downloadbtn ? false : true } onClick={this.updateExcel}>Download &#x00A0; <i className="fa fa-download" aria-hidden="true"></i></button> */}
                                    </div> : ''
                                }
                                {this.state.activeScreen === 'findDealer' &&
                                    <span className='odo-value'>Odometer : {Math.round(this.state.odoValue)} km </span>
                                }
                            </div>
                            <div></div>
                        </div>
                    </div>
                    <VehicleLinkGeneration showModelDialog={this.state.showShareDialog} handleClose={this.hideModalDialog} showCopyLinkDialog={this.showCopyLinkDialog} token={this.props.ctr.token} vehicleDetails = {this.props.ctr.selectedVehicle} isTrace ={true} />
                    <VehicleCopyLink showCopyLinkModelDialog={this.state.showCopyLinkModelDialog} handleCopyLinkClose={this.hideCopyLinkModelDialog} link={this.state.link}/>
                    <div className="pages">
                        <Switch>
                            <Route path="/vehicleInfo/trace">
                                <div className="">
                                    <TraceScreen excelDownload={this.getDataforExcel} updateChanges={this.updateVehData} showminimizedToolbar={this.state.showminiToolar} downloadbtn={this.state.downloadbtn} downloadbutton={this.state.downloadbutton} updateExcel={this.updateExcel} selecteddate={this.selecteddatetime} receiveProps={this.props}  />
                                </div>
                            </Route>
                            <Route path="/vehicleInfo/trend">
                                <div className="">
                                    <TrendScreen disableDownBtn={this.disableDownBtn} updateChanges={this.updateVehData} excelDownload={this.getDataforExcel} vitallistarray={this.selectedvitals} showminimizedToolbar={this.state.showminiToolar} downloadbtn={this.state.downloadbtn} updateExcel={this.updateExcel} downloadbutton={this.state.downloadbutton} selecteddate={this.selecteddatetime} receiveProps={this.props} />
                                </div>
                            </Route>
                            <Route path="/vehicleInfo/trendcompare">
                                <div className="">
                                    <TrendCompare  updateChanges={ this.updateVehData }  excelDownload={ this.getDataforExcel } vitallistarray ={ this.selectedvitals } selecteddate={ this.selecteddatetime } receiveProps={ this.props }/>
                                </div>
                            </Route>
                            <Route path="/vehicleinfo/findDealer">
                                <div>
                                    <FindDealer vin_no={history.location.state ? history.location.state.vin_no : ''} updateChanges={this.updateVehData} OdoValue={this.setOdoValue} receiveProps={this.props} showminimizedToolbar={this.state.showminiToolar} />
                                </div>
                            </Route>

                            <Route path="/vehicleinfo/VehicleDash">
                                <div>
                                    <VehicleDashboard updateChanges={this.updateVehData} receiveProps={this.props} />
                                </div>
                            </Route>
                        </Switch>
                    </div>
                </Router>
            </div>
        </div>)
    }
}
const mapStateToprops = state => {
    return {
        ctr: state.vehicledata.results,
        uD: state.userdata.id

    };
}
export default connect(mapStateToprops)(Vehicleinfo);
