export const convertTime12to24 = (time12h) => {
    const [time, modifier] = time12h.split(' ');
  
    let [hours, minutes,seconds] = time.split(':');
  
    if (hours === '12') {
      hours = '00';
    }
  
    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }
  
    return `${hours}:${minutes}:${seconds}`;
  }


export const convertedDateFormat = (dateGiven) =>{
    let splittedValue = dateGiven.match(/^(\S+)\s(.*)/).slice(1);            
    let localTimeStamp = splittedValue[0]+ " "+convertTime12to24(splittedValue[1]);
    return localTimeStamp;
}


export const diffTimeCalculator = (fromDate) =>{
    var date1 = new Date(fromDate);
    var date2 = new Date();
    var diffInSeconds = Math.abs(date1 - date2) / 1000;
    var days = Math.floor(diffInSeconds / 60 / 60 / 24);
    var hours = Math.floor(diffInSeconds / 60 / 60 % 24);
    var minutes = Math.floor(diffInSeconds / 60 % 60);
    var seconds = Math.floor(diffInSeconds % 60);
    // var milliseconds = Math.round((diffInSeconds - Math.floor(diffInSeconds)) * 1000);

    // console.log('days', days);
    // console.log('hours', ('0' + hours).slice(-2));
    // console.log('minutes', ('0' + minutes).slice(-2));
    // console.log('seconds', ('0' + seconds).slice(-2));
    // console.log('milliseconds', ('00' + milliseconds).slice(-3));
    if(days>0){
        return `${days}d:${hours}h:${minutes}m`;
    }else{
        return `${hours}h:${minutes}m:${seconds}s`;
    }
    
}

export const sortingColumsClassicView = [
  {key: "Altitude",value: "altitude",type: "numeric"},
  {key: "Battery Potential",value: "vehicle_battery_potentials",type: "numeric"},
  {key: "Coolant Temp",value: "coolant_temp",type: "numeric"},
  {key: "DEF Consumption",value: "adblue_tank_capacity",type: "numeric"},
  {key: "DEF Tank Level",value: "catalyst_tank_level",type: "numeric"},
  {key: "E-Norm",value: "is_bs6",type: "numeric"},
  {key: "Engine Hours",value: "engine_hours",type: "numeric"},
  {key: "Engine Speed",value: "engine_speed",type: "numeric"},
  {key:"Exhaust Temp",value: "exhaust_temperature",type: "numeric"},
  {key: "Fuel / Gas Consumption",value: "fuel_consum",type: "numeric"},
  {key: "Fuel / Gas Level",value: "fuel_level",type: "numeric"},
  {key: "Location", value: "location",type: "alpha" },
  { key: "Model",value: "vehicle_model",type: "alpha"},
  {key: "Obu Id",value: "obu_id",type: "alpha"},
  {key: "Odometer",value: "vehicle_odometer",type: "numeric"},
  {key: "Reg.No",value: "regnNumber",type: "alpha"},
  {key: "Status",value: "status",type: "alpha"},
  {key: "Subscription Valid From",value: "sub_valid_from",type: "date"},
  {key: "Subscription Valid To", value: "sub_valid_to",type: "date"},
  {key: "TimeStamp",value: "localTimeStamp",type: "date"},
  {key: "Vehicle Speed ",value: "gps_speed",type: "numeric"},
  {key: "Vehicle Type",value: "vehicle_type",type: "alpha"},
  {key: "VIN",value: "vin_number",type: "alpha"}
]

export const convertohhmmss = (numm) => {
  var decimalTimeString = numm;
  var decimalTime = parseFloat(decimalTimeString);
  decimalTime = decimalTime * 60 * 60;
  var hours = Math.floor((decimalTime / (60 * 60)));
  decimalTime = decimalTime - (hours * 60 * 60);
  var minutes = Math.floor((decimalTime / 60));
  decimalTime = decimalTime - (minutes * 60);
  var seconds = Math.round(decimalTime);
  if (hours < 10) {
     hours = "0" + hours;
  }
  if (minutes < 10) {
     minutes = "0" + minutes;
  }
  if (seconds < 10) {
     seconds = "0" + seconds;
  }
  if(seconds >=60){
     minutes = parseInt(minutes) + 1
     seconds = "00"
     if (minutes < 10) {
        minutes = "0" + minutes;
     }
  }
  if(minutes >=60){
     hours = parseInt(hours) + 1
     minutes = "00"
     if (hours < 10) {
        hours = "0" + hours;
     }
  }
  // console.log("" + hours + ":" + minutes + ":" + seconds);
  var aa = "" + hours + ":" + minutes + ":" + seconds;
  return aa;
}