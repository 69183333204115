import React from 'react'
import './Questions.scss'
import scroll from '../Scroll'
import { getVinData } from '../API/useApi'
import { useSelector } from "react-redux";
import Loader from '../Loader/Loader';

const SubQuestions = (props) => {
    const { setState } = props
    const ctr = useSelector((state) => state.userdata.id);

    const handleClick = (question, title, api, column) => {
        if(api === 'f_report' && !props.state.yes_no_btn_clk){
            setState(state => ({ ...state, selected_ques: question, title: title, result: false, q_clicked: true}))
            props.actionProvider.DateHandler(question, title, api, column);
        }
        else if(api === 'f_report' && props.state.yes_no_btn_clk){
            getVINData(question, title, api, column);
        }
        else if(api !== 'f_report' && props.state.yes_no_btn_clk){
            setState(state => ({ ...state, selected_ques: question, title: title, result: false, q_clicked: true}))
            props.actionProvider.DateHandler(question, title, api, column);
        }
        else{
            setState(state => ({ ...state, selected_ques: question, title: title, result: false, q_clicked: true}))
            props.actionProvider.VinHandler(question, title, api, column);
        }
        scroll();
        
    }

    const getVINData = async (question, title, api, column) => {
        let obj = {'question': question, 'title': title, 'api': api, 'column': column}
        try {
            setState(state => ({ ...state, loader: true, vinData: []}))
            const response = await getVinData(ctr, props, props.state.startDate, props.state.endDate, api);
            if(response.statuscode === 200 ){
                setState(state => ({ ...state, vinData: response.data, loader: false, result: true, q_clicked: false, yes_no_btn_clk: false, fleet_continue: true}))
                props.actionProvider.resultHandler2(obj, response.data, props.state.startDate, props.state.endDate);

            }
            else{
                setState(state => ({ ...state, vinData: [], loader: false, result: true, q_clicked: false, yes_no_btn_clk: false, fleet_continue: true}))
                props.actionProvider.resultHandler2(obj, response.data, props.state.startDate, props.state.endDate);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const renderQuestions = () => {
        return props.payload.sub_question.map((question, index) => {
            return(
                <li key={index} onClick={(e)=> {handleClick(question.key, question.title, question.api, question.column)}}>
                    {question.title}
                </li>
            )
        })
    }

    return (
        <>
            <div className='todos-widget'>
                <ul className='questions-div'>
                    {renderQuestions()}
                </ul>
            </div>
        </>
    )
}

export default SubQuestions