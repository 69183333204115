import React from 'react';
import classnames from 'classnames';

import './alertBox.scss';

const AlertBox = ({ click, show, showMessage, messageType, errorRemainder }) => {
    return (
        <div className={classnames("modal", show ? "active-modal" : null)}>
            <div className={classnames("modal-content",
                messageType === "s" ? "modal-content-success" : messageType === "a" ? "modal-content-alreadyExist" : "modal-content-error")}>
                <div className="row">
                    <span className="col-lg-11 col-sm-11">{showMessage}</span>
                    <span className="col-lg-1 col-sm-1 close" onClick={click}>&times;</span>
                </div>
            </div>
            {errorRemainder && <div className='errorRemainder'>
                    <span className="col-lg-11 col-sm-11 text-red">Note: {errorRemainder}</span>
            </div>}
            
        </div>
    )
}

export default AlertBox;