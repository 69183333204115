import React from 'react';
import classnames from 'classnames';
import '../AlertBox/alertBox.scss';

const checkPassValue = ({ click, show, showMessage }) => {
    return (
        <div className={classnames("modal", show ? "active-modal" : null)} style={{zIndex:'1'}}>
            <div className='modal-content' style={{ width: '40%' }}>
                <div className='row'>
                    <span className="close" style={{ position: 'absolute', right: '14px', top: '4px' }} onClick={click}>&times;</span>
                </div>

                <div className='row'>
                    <span className="col-lg-12 col-sm-12 text-justify font-weight-bold mt-2">{showMessage}</span>
                </div>
            </div>
        </div>
    )
}

export default checkPassValue;