import React from 'react';
import { compose, withProps, lifecycle, withHandlers } from 'recompose';
import { GoogleMap, withScriptjs, withGoogleMap, Marker, Circle,DirectionsRenderer } from 'react-google-maps';
import { MarkerClusterer } from "react-google-maps/lib/components/addons/MarkerClusterer";
import { InfoBox } from "react-google-maps/lib/components/addons/InfoBox";
import './common.css';
import apilist from '../../apiList';
import { connect } from 'react-redux';
import { GOOGLE_MAP_API_KEY, GOOGLE_API_KEY } from '../../apiList';





const MyMapComponent = compose(

    withProps({
        googleMapURL: "https://maps.googleapis.com/maps/api/js?key="+GOOGLE_MAP_API_KEY+"&v=3.exp&libraries=geometry,drawing,places",
        loadingElement: <div style={{ height: `70.8vh` }} />,
        containerElement: <div style={{ width: `100%`,height: `100%` }} />,
        mapElement: <div style={{ width: `100%`, height: `100%` }} />
    }),
    withHandlers({

    }),
    lifecycle({
      callinpiufunbction() {
        var statevalue= this;
        var iframe,iframe_google;   
    iframe = document.createElement('iframe');
    iframe.id = "map_frame";
    iframe.frameBorder = 0;
    iframe.src = "/dummy.html";
    iframe.sandbox = "allow-same-origin allow-scripts allow-top-navigation";
    
    iframe.onload = function () {
        
      var script = iframe.contentWindow.document.createElement('script');
  
      var input = iframe.contentWindow.document.createElement('input');
      input.type= 'text';
      input.id='searchByLocationBoxes';
      input.className = 'location_search_box';
      iframe.contentWindow.document.getElementsByTagName('body')[0].appendChild(input);
        
      iframe.contentWindow.insertPlacesApi = function() {
            //google.maps.places = this.google.maps.places;
            var placed
            iframe_google = this.google;
            var searchLocationBox = document.getElementById("searchByLocationBoxes");
            var autocomplete = new iframe_google.maps.places.Autocomplete(searchLocationBox);
            autocomplete.addListener("place_changed", () => {
            placed = autocomplete.getPlace();
            // this.onPlacesChangedcustom(place);
            const place = placed;
            const bounds = new iframe_google.maps.LatLngBounds();
          
                if (place.geometry.viewport) {
                    bounds.union(place.geometry.viewport)
                    
                } else {
                    bounds.extend(place.geometry.location)
                }
            // const nextMarkers = place.map(placea => ({
            //     position: placea.geometry.location,
            // }));
            var center=  {
                lat: 20.5937,
                lng: 78.9629
            }
            const nextMarkers ={position: place.geometry.location}
            //const nextCenter = _.get(nextMarkers, '0.position', this.state.center);
            const nextCenter = nextMarkers.position || center;
    
            let setCenter = nextCenter;
            setCenter.lat = nextCenter.lat();
            setCenter.lng = nextCenter.lng();
            const markervalue =[nextMarkers]
            statevalue.setState({searchcenter: nextCenter,
                    markers: markervalue});
            statevalue.props.searchupdatedata(nextCenter)
            })
            
      };
  
      script.type = 'text/javascript';
      script.src = 'https://maps.googleapis.com/maps/api/js?key='+GOOGLE_API_KEY+'&sensor=false&libraries=geometry,visualization,drawing,places&callback=insertPlacesApi';
  
      iframe.contentDocument.getElementsByTagName('head')[0].appendChild(script);
    };
    document.body.append(iframe);                                       
      },
        
      UNSAFE_componentWillMount () {
          this.callinpiufunbction();
          const refs = {}

          this.setState({
            bounds: null,
            searchcenter: {},
            markers: [],
            onMapMounted: ref => {
              refs.map = ref;
            },
            onBoundsChanged: () => {
              this.setState({
                bounds: refs.map.getBounds(),
                searchcenter: refs.map.getCenter(),
              })
              
              
            },
            onSearchBoxMounted: ref => {
              refs.searchBox = ref;
            },
            onPlacesChanged: () => {
              const places = refs.searchBox.getPlaces();
              const bounds = new window.google.maps.LatLngBounds();
    
              places.forEach(place => {
                if (place.geometry.viewport) {
                  bounds.union(place.geometry.viewport)
                } else {
                  bounds.extend(place.geometry.location)
                }
              });
              const nextMarkers = places.map(place => ({
                position: place.geometry.location,
              }));
              //const nextCenter = nextMarkers.get(nextMarkers, '0.position', this.state.center);
              
              const nextCenter = {lat:nextMarkers[0].position.lat(), lng:nextMarkers[0].position.lng()} || this.state.searchcenter;
              this.setState({
                searchcenter: nextCenter,
                markers: nextMarkers,
              });
              this.props.searchupdatedata(this.state.searchcenter)
              // refs.map.fitBounds(bounds);
            },
          })
        
        },
      }),
    withScriptjs,
    withGoogleMap,
)(props =>
    <GoogleMap
        
        zoom={4}
        center={props.sud}
        options={{minZoom: 4,maxZoom: 18}}
    >
       
        {
            props.directionsed.length===0?<InfoBox
                position={new window.google.maps.LatLng(props.center)}
                options={{ closeBoxURL: ``, enableEventPropagation: true }}
            >
                <div style={{ backgroundColor: `#427ae6`, padding: `5px`, borderRadius: `25px`, position: `relative` }}>
                    <div style={{ fontSize: `14px`, fontWeight: `bold`, color: `white` }}>
                        {(props.displaytexted===undefined || props.dropdown_value === true) ? props.kmsradiusval:props.displaytexted} kms
                    </div>
                </div>
            </InfoBox>:''
        }

        { <Marker
            key={"0000"}
            position={props.center}
            options={{ icon: props.truck }}
          />
        }

      <MarkerClusterer
        // onClick={props.onMarkerClustererClick}
        averageCenter
        styles={[
          {
            textColor: "white",
            height: 58,
            url: "/images/Map_screen/blue.png",
            width: 78,
            fontWeight:"750"
          }
        ]}
      >
        {props.mydata &&
          props.mydata && props.mydata.map((mapData) => {
    
            return (
              <Marker
                key={mapData.dealer_outlet_id}
                options={{ icon: mapData.icon }}
                position={{ lat: parseFloat(mapData.lattitude), lng: parseFloat(mapData.longitude) }}
                onClick={() => props.getDatas(mapData)}
              >
              </Marker>
 
            );

          })}
      </MarkerClusterer>


       {props.directionsed.length!==0?<DirectionsRenderer directions={props.directionsed} 
       options={{suppressMarkers: true}} routeIndex={0}/>:''}
       
          
        {props.kmsradiusval === undefined ?
          <Circle
            key={props.sud.lat} 
            defaultCenter={props.center}
            radius={199999}
            editable={true}
            ref={props.CircleRef}
            defaultOptions={props.options}
            onRadiusChanged={props.onRadiusChanged}
            />:
          <Circle
            key={props.sud.lat} 
            defaultCenter={props.center}
            radius={props.kmsradiusval*1000}
            editable={true}
            ref={props.CircleRef}
            defaultOptions={props.options}
            onRadiusChanged={props.onRadiusChanged}
          />
        }
        
        {
          <p className="googleMapSearch" style={{position:"absolute",marginTop:"-33%",right:"24%","i_searchIcon":{"position":"absolute","top":"28px","left":"35px"}}}>
          <input type="text" 
          style={{"boxSizing":"border-box","border":"1px solid transparent","width":"235px","height":"32px","padding":"0 12px 0 30px","borderRadius":"25px","boxShadow":"0 2px 6px rgba(0, 0, 0, 0.3)","fontSize":"14px","outline":"none","textOverflow":"ellipses","top":"25px","position":"absolute"}}
          placeholder="&#128269; Search Location" id='searchByLocationBoxes' className = 'location_search_box' />
      </p>
        }
        
    </GoogleMap>
);
const mapStateToprops = state => {
    return {
        sud:state.menus.searchupdatedata
    };
}
const mapdispatchToprops = dispatch => {
    return {
        searchupdatedata: (data) => dispatch({ type: 'searchupdatedata', value: data })
    };
}
//export default MyMapComponent;
export default connect(mapStateToprops, mapdispatchToprops)(MyMapComponent);