import React, { useMemo, useRef, useEffect, useState } from 'react'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api'
import { GOOGLE_API_KEY } from '../../../apiList'

const MapComponent = ({ zoom, center, traceData }) => {
  const [isMapLoaded, setIsMapLoaded] = useState(false)

  const { isLoaded } = useJsApiLoader({
    id: 'chatbot-google-map-script',
    googleMapsApiKey: GOOGLE_API_KEY,
  })

  useEffect(() => {
    if (isLoaded && !isMapLoaded) {
      setIsMapLoaded(true)
    }
  }, [isLoaded, isMapLoaded])

  const containerStyle = {
    height: '400px',
    width: '100%',
    position: 'fixed',
    overflow: 'hidden',
  }
  const mapOptions = {
    gestureHandling: 'cooperative',
  }

  var MarkerOptions = {
    fillOpacity: 3,
    scale: 3,
    strokeColor: '#009900', // ,#006622 dots
    strokeWeight: 3,
  }
  const markers = useMemo(() => {
    if (!traceData) return []

    return (
      traceData &&
      traceData.map((data, index) => {
        const title = `Reg No : ${data.regn_number}\nLocation : ${data.location}\nTimeStamp : ${data.fleetTime}`
        return (
          <Marker
            icon={MarkerOptions}
            key={index}
            position={{ lat: data.lat, lng: data.lng }}
            title={title}
          />
        )
      })
    )
  }, [traceData])

  useEffect(() => {
    const handleTouchMove = (event) => {
      event.preventDefault() // Prevent default scrolling behavior
    }

    const handleTouchStart = (event) => {
      event.preventDefault() // Prevent default scrolling behavior
    }

    document.addEventListener('touchmove', handleTouchMove, { passive: true })
    document.addEventListener('touchstart', handleTouchStart, { passive: true })

    return () => {
      document.removeEventListener('touchmove', handleTouchMove)
      document.removeEventListener('touchstart', handleTouchStart)
    }
  }, [])
  return isLoaded && isMapLoaded ? (
    <GoogleMap
      zoom={zoom}
      center={center}
      options={mapOptions}
      mapContainerStyle={containerStyle}
    >
      {markers}
    </GoogleMap>
  ) : (
    <></>
  )
}

const ChatBotMapComponent = React.memo(MapComponent)

export default ChatBotMapComponent
