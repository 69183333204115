import React from 'react'

const ShowList = ({ regList }) => {
  return (
    <>
    {regList.length > 0 ? 
         <table className='table'>
         <thead>
             <tr>
                <td>Reg No / VIN</td>
                <td className='text-center'>Alert Type Name</td>
                <td className='text-center'>Alert Value</td>
                <td>Status</td>
             </tr>
         </thead>
         <tbody>
             {regList.map((data, index) => (
                <>
                    {data.alert_value && 
                        <tr key={index}>
                            <td>{data.regn_number ? data.regn_number : '-'}</td>
                            <td className='text-center'>{data.alert_type_name ? data.alert_type_name : '-'}</td>
                            <td className='text-center'>{data.alert_value ? data.alert_value  : '-'}</td>
                            <td>Success</td>
                        </tr>
                    }
                </>
            ))}
         </tbody>
     </table> : 
     <div className='text-center'>
            <span className='no-data2'>No Data Available</span>
     </div>
    }
   
    </>
  )
}

export default ShowList