import React from "react";
import "./FleetInsightDataWarning.scss";
const FleetInsightDataWarning = ({ onClose }) => {
  return (
    <div className="popup FleetWarningForm">
      <div className="warning-fleet-popup-body">
        <div className="popup_header">
          <p className="warning-title">
            <strong>Important Notice</strong>
            <span className="warning_close" onClick={onClose}>
              ✖
            </span>
          </p>
        </div>
        <div className="warning-dialog-body">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="warning-dialog-message">
              Fleet insights reports are not available from 3rd May to 6th May
              2023. We are working round the clock to resume the report
              availability, and the same will be available by Tuesday
              (09-05-2023) End of the day. Sorry for the inconvenience caused.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FleetInsightDataWarning;
