import React from 'react';
import classnames from 'classnames';

const TripBox = (props) => {
    return (
    <div className="max-width-14" onClick={props.click}>
        <div className={classnames('sarathy-filter-box', props.filterclass === props.name ? 'sarathy-filter-box-active' : null)}>
            <div className={classnames('sarathy-filter-head', props.filterclass === props.name ? 'sarathy-filter-active': null)}>{props.name}</div>
            <div className={classnames('sarathy-filter-data', props.filterclass === props.name ? 'sarathy-filter-active': null)}>{props.value}</div>
        </div>
    </div>);
}

export default TripBox;