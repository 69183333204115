import React from 'react'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import './idloutBox.scss'
export const IdleTimeOutModal = ({
  showModal,
  handleClose,
  handleLogout,
}) => {
  return (
    <div className="idloutbox">
      <div className={classnames('modal', showModal ? 'active-modal' : null)}>
        <div className="modal-content">
          <div className="logoutbox_body_div">
            <div className="logoutbox_textshow_div">
              <span className="logoutbox_textshow">
                You Will Get Timed Out in 1 Minute. You want to stay?{' '}
              </span>
            </div>
            <div className="logoutbox_btn_div">
              <Link>
                <button
                  className="confirmbutton"
                  onClick={handleClose}
                  type="button"
                >
                  Yes
                </button>
              </Link>
              <button
                className="confirmbutton"
                onClick={handleLogout}
                type="button"
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
