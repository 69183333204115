import React from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

class CustomDatePicker extends React.Component {

    render() {
        return (
            <div className= "mb-2 datepicker form-group col-lg-5 mr-4">
                {
                    <label className="trip-point-label">{this.props.placeholderText}
                    {this.props.mandatory ? null : <span className="red">*</span>}</label>
                }
                <DatePicker
                    disabled={this.props.disabled}
                    selected={this.props.value}
                    onChange={date=>this.props.change(date)}
                    className="form-control"
                    showTimeSelect = {this.props.showTimeSelect}
                    showTimeSelectOnly = {this.props.showTimeSelectOnly}
                    timeFormat={this.props.timeFormat}
                    dateFormat={this.props.dateFormat}
                    placeholderText={this.props.placeholderText}
                    minDate={this.props.minDate}
                    maxDate={this.props.maxDate}
                    minTime={this.props.minTime}
                    excludeDates = {this.props.excludeDates}
                    onBlur={this.props.blur}
                    id={this.props.id}
                    autoComplete="off"
                />
                {
                    this.props.datepicker?
                    <span onClick={this.props.spanClick} className="fa fa-calendar-check-o form-control-feedback"></span>:
                    <span onClick={this.props.spanClick} className="fa fa-clock-o form-control-feedback"></span>
                }
            </div>
        );
    }
}

export default CustomDatePicker