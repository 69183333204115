exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".ByVehicle_ByVehicleContainer__2ae-j {\r\n  display: grid;\r\n  grid-template-rows: auto auto auto auto;\r\n  grid-gap: 1rem;\r\n  flex-grow: 1;\r\n}\r\n\r\n.ByVehicle_ByVehicleContainer__2ae-j button {\r\n  background-color: #427be6;\r\n}\r\n\r\n.ByVehicle_VinNumberAdd__2Zx2K, .ByVehicle_SearchVehicle__1ANoz {\r\n  display: grid;\r\n  grid-template-columns: 2fr 1fr;\r\n  align-items: end;\r\n}\r\n\r\n.ByVehicle_containerbulkupload__p454j {\r\n  display: grid;\r\n  grid-template-columns: 2fr 1fr;\r\n  align-items: top;\r\n}\r\n\r\n.ByVehicle_ListContainer__2p03V {\r\n  height: 10rem;\r\n  overflow: auto;\r\n  border: 1px solid #adb3ad;\r\n  width: 50%;\r\n}\r\n\r\n.ByVehicle_close__1sCfB{\r\n  font-size: 21px !important;\r\n  color: black;\r\n  font-weight: 600;\r\n}\r\n\r\n.ByVehicle_ResetSubmit__2Q-J- {\r\n  display: inline-block;\r\n  margin-right: 2rem;\r\n}\r\n", ""]);

// Exports
exports.locals = {
	"ByVehicleContainer": "ByVehicle_ByVehicleContainer__2ae-j",
	"VinNumberAdd": "ByVehicle_VinNumberAdd__2Zx2K",
	"SearchVehicle": "ByVehicle_SearchVehicle__1ANoz",
	"containerbulkupload": "ByVehicle_containerbulkupload__p454j",
	"ListContainer": "ByVehicle_ListContainer__2p03V",
	"close": "ByVehicle_close__1sCfB",
	"ResetSubmit": "ByVehicle_ResetSubmit__2Q-J-"
};