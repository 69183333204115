import React from "react";
import VehicleSearchResult from "./VehicleSearchResult";
import './VehicleSearchResult.scss'

function VehicleSearchResults({ searchResults, onCheckBoxChange }) {
  return (
    <>
    {searchResults.length > 0 ? 
      <div className="list-container">{searchResults.map((vehicle) => (
        <VehicleSearchResult key={vehicle.vinNumber} vehicle={vehicle} onCheckBoxChange={onCheckBoxChange} />
      ))}</div> : 
      <div className="text-center">
        <p className="no-data-avil">No Data Available</p>
      </div>
    }
    </>

  );
}

export default VehicleSearchResults;
