import React from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { getGASVehicleUnit } from "../../Util/VehicleDashUtils";

export const ChartConfig = (listofvitals, data, chartval, vitalslistvalue, SetVitalNull, vehicleType) =>{
    let chartlist = {};
    for (var i = 0; i < listofvitals.length; i++) {
        let countValue = data.count_value
        let compList = data.TREND_RESPONSE[listofvitals[i]]
        if (compList.length !== 0) {
            chartval[i] = am4core.create("chart" + i, am4charts.XYChart);
            chartlist["chart" + [i]] = chartval[i]
            chartlist["chart" + [i]].data = compList;

            var categoryAxis = chartlist["chart" + [i]].xAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = "LOCAL_TIMESTAMP";
            categoryAxis.renderer.minGridDistance = 200;
            categoryAxis.title.text = "Timestamp";
            categoryAxis.lineColor = "#fff"
            categoryAxis.fontSize = "12px";

            let columnvalue = "";
            let columnlabel = "";
            let columngreen = "";
            let columnred = "";
            let columnunits = '';
            let columnmin = '';
            let columnmax = '';
            let columndiff = '';
            switch (listofvitals[i] && countValue[i]?.vital_name) {
                case "BATTERY_POTENTIAL":
                    columnlabel = "Battery Potential"
                    columnunits = "Battery Potential (v)"
                    columnvalue = listofvitals[i]
                    columngreen = "MAX_GREEN"
                    columnred = "MAX_RED"
                    columnmin = countValue[i]?.label_1 ==='' ? '' : countValue[i]?.label_1 + ': ' + countValue[i]?.min_value
                    columnmax = countValue[i]?.label_2 === '' ? '' : countValue[i]?.label_2 + ': ' + countValue[i]?.max_value
                    break;
                case "ENGINE_SPEED":
                    columnlabel = "Engine Speed"
                    columnunits = "Engine Speed (rpm)"
                    columnvalue = listofvitals[i]
                    columngreen = "MAX_GREEN"
                    columnred = "MAX_RED"
                    columnmin = countValue[i]?.label_1 ==='' ? '' : countValue[i]?.label_1 + ': ' + countValue[i]?.min_value
                    columnmax = countValue[i]?.label_2 === '' ? '' : countValue[i]?.label_2 + ': ' + countValue[i]?.max_value
                    break;
                case "FUEL_LEVEL":
                    columnlabel = "Fuel level"
                    columnunits = "Fuel level (ltr)"
                    columnvalue = listofvitals[i]
                    columngreen = "MAX_GREEN"
                    columnred = "MAX_RED"
                    columnmin = countValue[i]?.label_1 ==='' ? '' : countValue[i]?.label_1 + ': ' + countValue[i]?.min_value
                    columnmax = countValue[i]?.label_2 === '' ? '' : countValue[i]?.label_2 + ': ' + countValue[i]?.max_value
                    break;
                case "COOLANT_TEMPERTURE":
                    columnlabel = "Engine Coolant"
                    columnunits = "Engine Coolant ( °C )"
                    columnvalue = listofvitals[i]
                    columngreen = "MAX_GREEN"
                    columnred = "MAX_RED"
                    columnmin = countValue[i]?.label_1 ==='' ? '' : countValue[i]?.label_1 + ': ' + countValue[i]?.min_value
                    columnmax = countValue[i]?.label_2 === '' ? '' : countValue[i]?.label_2 + ': ' + countValue[i]?.max_value
                    break;
                case "AIR_PRESSURE":
                    columnlabel = "Air Pressure Digital"
                    columnunits = "Air Pressure Digital (state)"
                    columnvalue = listofvitals[i]
                    columngreen = "MAX_GREEN"
                    columnred = "MAX_RED"
                    columnmin = countValue[i]?.label_1 ==='' ? '' : countValue[i]?.label_1 + ': ' + countValue[i]?.min_value
                    columnmax = countValue[i]?.label_2 === '' ? '' : countValue[i]?.label_2 + ': ' + countValue[i]?.max_value
                    break;
                case "ALTITUDE":
                    columnlabel = "Altitude"
                    columnunits = "Altitude (m)"
                    columnvalue = listofvitals[i]
                    columnmin = countValue[i]?.label_1 ==='' ? '' : countValue[i]?.label_1 + ': ' + countValue[i]?.min_value
                    columnmax = countValue[i]?.label_2 === '' ? '' : countValue[i]?.label_2 + ': ' + countValue[i]?.max_value
                    break;
                case "ENGINE_OIL_PRESSURE":
                    columnlabel = "Oil Pressure"
                    columnunits = "Oil Pressure (bar)"
                    columnvalue = listofvitals[i]
                    columngreen = "MAX_GREEN"
                    columnred = "MAX_RED"
                    columnmin = countValue[i]?.label_1 ==='' ? '' : countValue[i]?.label_1 + ': ' + countValue[i]?.min_value
                    columnmax = countValue[i]?.label_2 === '' ? '' : countValue[i]?.label_2 + ': ' + countValue[i]?.max_value
                    break;
                case "ODOMETER":
                    columnlabel = "Odometer"
                    columnunits = "Odometer (km)"
                    columnvalue = listofvitals[i]
                    columnmin = countValue[i]?.label_1 ==='' ? '' : countValue[i]?.label_1 + ': ' + countValue[i]?.min_value
                    columnmax = countValue[i]?.label_2 === '' ? '' : countValue[i]?.label_2 + ': ' + countValue[i]?.max_value
                    columndiff = countValue[i]?.label_3 + ': ' + countValue[i]?.diff_value
                    break;
                case "VEHICLE_SPEED":
                    columnlabel = "Vehicle Speed"
                    columnunits = "Vehicle Speed (km/hr)"
                    columnvalue = listofvitals[i]
                    columngreen = "MAX_GREEN"
                    columnred = "MAX_RED"
                    columnmin = countValue[i]?.label_1 ==='' ? '' : countValue[i]?.label_1 + ': ' + countValue[i]?.min_value
                    columnmax = countValue[i]?.label_2 === '' ? '' : countValue[i]?.label_2 + ': ' + countValue[i]?.max_value
                    break;
                case "OIL_PRESSURE":
                    columnlabel = "Oil Pressure"
                    columnunits = "Oil Pressure (state)"
                    columnvalue = listofvitals[i]
                    columngreen = "MAX_GREEN"
                    columnred = "MAX_RED"
                    columnmin = countValue[i]?.label_1 ==='' ? '' : countValue[i]?.label_1 + ': ' + countValue[i]?.min_value
                    columnmax = countValue[i]?.label_2 === '' ? '' : countValue[i]?.label_2 + ': ' + countValue[i]?.max_value
                    break;
                case "REAR_AIR_PRESSURE":
                    columnlabel = "Air Pressure Rear"
                    columnunits = "Air Pressure Rear (bar)"
                    columnvalue = listofvitals[i]
                    columngreen = "MAX_GREEN"
                    columnred = "MAX_RED"
                    columnmin = countValue[i]?.label_1 ==='' ? '' : countValue[i]?.label_1 + ': ' + countValue[i]?.min_value
                    columnmax = countValue[i]?.label_2 === '' ? '' : countValue[i]?.label_2 + ': ' + countValue[i]?.max_value
                    break;
                case "FRONT_AIR_PRESSURE":
                    columnlabel = "Air Pressure Front"
                    columnunits = "Air Pressure Front (bar)"
                    columnvalue = listofvitals[i]
                    columngreen = "MAX_GREEN"
                    columnred = "MAX_RED"
                    columnmin = countValue[i]?.label_1 ==='' ? '' : countValue[i]?.label_1 + ': ' + countValue[i]?.min_value
                    columnmax = countValue[i]?.label_2 === '' ? '' : countValue[i]?.label_2 + ': ' + countValue[i]?.max_value
                    break;
                case "FUEL_CONSUMPTION":
                    columnlabel = "Fuel Consumption"
                    columnunits = "Fuel Consumption (ltr)"
                    columnvalue = listofvitals[i]
                    columnmin = countValue[i]?.label_1 ==='' ? '' : countValue[i]?.label_1 + ': ' + countValue[i]?.min_value
                    columnmax = countValue[i]?.label_2 === '' ? '' : countValue[i]?.label_2 + ': ' + countValue[i]?.max_value
                    columndiff = countValue[i]?.label_3 + ': ' + countValue[i]?.diff_value
                    break;
                case "DEF_TANK_LEVEL":
                    columnlabel = "DEF Tank Level"
                    columnunits = "DEF Tank Level (ltr)"
                    columnvalue = listofvitals[i]
                    columnmin = countValue[i]?.label_1 ==='' ? '' : countValue[i]?.label_1 + ': ' + countValue[i]?.min_value
                    columnmax = countValue[i]?.label_2 === '' ? '' : countValue[i]?.label_2 + ': ' + countValue[i]?.max_value
                    break;
                case "DEF_TANK_TEMPERATURE":
                    columnlabel = "DEF Tank Temperature"
                    columnunits = "DEF Tank Temperature ( °C )"
                    columnvalue = listofvitals[i]
                    columnmin = countValue[i]?.label_1 ==='' ? '' : countValue[i]?.label_1 + ': ' + countValue[i]?.min_value
                    columnmax = countValue[i]?.label_2 === '' ? '' : countValue[i]?.label_2 + ': ' + countValue[i]?.max_value
                    break;
                case "EXHAUST_TEMPERATURE":
                    columnlabel = "Exhaust Temperature"
                    columnunits = "Exhaust Temperature ( °C )"
                    columnvalue = listofvitals[i]
                    columnmin = countValue[i]?.label_1 ==='' ? '' : countValue[i]?.label_1 + ': ' + countValue[i]?.min_value
                    columnmax = countValue[i]?.label_2 === '' ? '' : countValue[i]?.label_2 + ': ' + countValue[i]?.max_value
                    break;
                case "ENGINE_HOURS":
                    columnlabel = "Engine Hours"
                    columnunits = "Engine Hours (hrs)"
                    columnvalue = listofvitals[i]
                    columnmin = countValue[i]?.label_1 ==='' ? '' : countValue[i]?.label_1 + ': ' + countValue[i]?.min_value
                    columnmax = countValue[i]?.label_2 === '' ? '' : countValue[i]?.label_2 + ': ' + countValue[i]?.max_value
                    columndiff = countValue[i]?.label_3 + ': ' + countValue[i]?.diff_value
                    
                    break;
                case "DEF_CONSUMPTION":
                    columnlabel = "DEF Consumption"
                    columnunits = "DEF Consumption(ltr)"
                    columnvalue = listofvitals[i]
                    columnmin = countValue[i]?.label_1 ==='' ? '' : countValue[i]?.label_1 + ': ' + countValue[i]?.min_value
                    columnmax = countValue[i]?.label_2 === '' ? '' : countValue[i]?.label_2 + ': ' + countValue[i]?.max_value
                    columndiff = countValue[i]?.label_3 + ': ' + countValue[i]?.diff_value
                    break;
                case "COOLANT_TEMPERATURE":
                    columnlabel = "Coolant Temperature"
                    columnunits = "Coolant Temperature ( °C )"
                    columngreen = "MAX_GREEN"
                    columnred = "MAX_RED"
                    columnvalue = listofvitals[i]
                    columnmin = countValue[i]?.label_1 ==='' ? '' : countValue[i]?.label_1 + ': ' + countValue[i]?.min_value
                    columnmax = countValue[i]?.label_2 === '' ? '' : countValue[i]?.label_2 + ': ' + countValue[i]?.max_value
                    break;
                case "GAS_CONSUMPTION":
                    columnlabel = "Gas Consumption"
                    columnunits = "Gas Consumption (kg)"
                    columngreen = "MAX_GREEN"
                    columnred = "MAX_RED"
                    columnvalue = listofvitals[i]
                    columnmin = countValue[i]?.label_1 ==='' ? '' : countValue[i]?.label_1 + ': ' + countValue[i]?.min_value
                    columnmax = countValue[i]?.label_2 === '' ? '' : countValue[i]?.label_2 + ': ' + countValue[i]?.max_value
                    columndiff = countValue[i]?.label_3 + ': ' + countValue[i]?.diff_value
                    break;
                case "GAS_LEVEL":
                    columnlabel = "Gas Level"
                    columnunits = "Gas Level" +'('+ getGASVehicleUnit(vehicleType) +')'
                    columngreen = "MAX_GREEN"
                    columnred = "MAX_RED"
                    columnvalue = listofvitals[i]
                    columnmin = countValue[i]?.label_1 ==='' ? '' : countValue[i]?.label_1 + ': ' + countValue[i]?.min_value
                    columnmax = countValue[i]?.label_2 === '' ? '' : countValue[i]?.label_2 + ': ' + countValue[i]?.max_value
                    break;
                case "CURRENT_GEAR":
                    columnlabel = "Gear Number"
                    columnunits = "Gear Number"
                    columngreen = "MAX_GREEN"
                    columnred = "MAX_RED"
                    columnvalue = listofvitals[i]
                    columnmin = countValue[i]?.label_1 ==='' ? '' : countValue[i]?.label_1 + ': ' + countValue[i]?.min_value
                    columnmax = countValue[i]?.label_2 === '' ? '' : countValue[i]?.label_2 + ': ' + countValue[i]?.max_value
                    break;
                case "SOC":
                    columnlabel = "SOC"
                    columnunits = "SOC(%)"
                    columngreen = "MAX_GREEN"
                    columnred = "MAX_RED"
                    columnvalue = listofvitals[i]
                    columnmin = countValue[i]?.label_1 ==='' ? '' : countValue[i]?.label_1 + ': ' + countValue[i]?.min_value
                    columnmax = countValue[i]?.label_2 === '' ? '' : countValue[i]?.label_2 + ': ' + countValue[i]?.max_value
                    break
                case "MOTOR_SPEED_MCU":
                    columnlabel = "Motor Speed"
                    columnunits = "Motor Speed (rpm)"
                    columngreen = "MAX_GREEN"
                    columnred = "MAX_RED"
                    columnvalue = listofvitals[i]
                    columnmin = countValue[i]?.label_1 ==='' ? '' : countValue[i]?.label_1 + ': ' + countValue[i]?.min_value
                    columnmax = countValue[i]?.label_2 === '' ? '' : countValue[i]?.label_2 + ': ' + countValue[i]?.max_value
                    break
                case "PACK_VOLTAGE": 
                    columnlabel = "Pack Voltage"
                    columnunits = "Pack Voltage (volts)"
                    columngreen = "MAX_GREEN"
                    columnred = "MAX_RED"
                    columnvalue = listofvitals[i]
                    columnmin = countValue[i]?.label_1 ==='' ? '' : countValue[i]?.label_1 + ': ' + countValue[i]?.min_value
                    columnmax = countValue[i]?.label_2 === '' ? '' : countValue[i]?.label_2 + ': ' + countValue[i]?.max_value
                    break
                case "TOTAL_DC_DC_ENERGY_CONSUMPTION": 
                    columnlabel = "Total DC-DC Energy consumption"
                    columnunits = "Total DC-DC Energy consumption (kWh)"
                    columngreen = "MAX_GREEN"
                    columnred = "MAX_RED"
                    columnvalue = listofvitals[i]
                    columnmin = countValue[i]?.label_1 ==='' ? '' : countValue[i]?.label_1 + ': ' + countValue[i]?.min_value
                    columnmax = countValue[i]?.label_2 === '' ? '' : countValue[i]?.label_2 + ': ' + countValue[i]?.max_value
                    break;
                    
                case "MAIN_COOLANT_TEMPERATURE": 
                    columnlabel = "Main Coolant Temperature"
                    columnunits = "Main Coolant Temperature (°C)"
                    columngreen = "MAX_GREEN"
                    columnred = "MAX_RED"
                    columnvalue = listofvitals[i]
                    columnmin = countValue[i]?.label_1 ==='' ? '' : countValue[i]?.label_1 + ': ' + countValue[i]?.min_value
                    columnmax = countValue[i]?.label_2 === '' ? '' : countValue[i]?.label_2 + ': ' + countValue[i]?.max_value
                    break
                case "BATTERY_MAX_CELL_TEMPERATURE": 
                    columnlabel = "Battery max temperature"
                    columnunits = "Battery max temperature (°C)"
                    columngreen = "MAX_GREEN"
                    columnred = "MAX_RED"
                    columnvalue = listofvitals[i]
                    columnmin = countValue[i]?.label_1 ==='' ? '' : countValue[i]?.label_1 + ': ' + countValue[i]?.min_value
                    columnmax = countValue[i]?.label_2 === '' ? '' : countValue[i]?.label_2 + ': ' + countValue[i]?.max_value
                    break
                case "BATTERY_COOLANT_OUT_TEMPERATURE": 
                    columnlabel = "Battery coolant out temperature"
                    columnunits = "Battery coolant out temperature (°C)"
                    columngreen = "MAX_GREEN"
                    columnred = "MAX_RED"
                    columnvalue = listofvitals[i]
                    columnmin = countValue[i]?.label_1 ==='' ? '' : countValue[i]?.label_1 + ': ' + countValue[i]?.min_value
                    columnmax = countValue[i]?.label_2 === '' ? '' : countValue[i]?.label_2 + ': ' + countValue[i]?.max_value
                    break
                case "MAIN_COOLANT_TEMP":
                    columnlabel = "Main Coolant Temperature"
                    columnunits = "Main Coolant Temperature (°C)"
                    columngreen = "MAX_GREEN"
                    columnred = "MAX_RED"
                    columnvalue = listofvitals[i]
                    columnmin = countValue[i]?.label_1 ==='' ? '' : countValue[i]?.label_1 + ': ' + countValue[i]?.min_value
                    columnmax = countValue[i]?.label_2 === '' ? '' : countValue[i]?.label_2 + ': ' + countValue[i]?.max_value
                    break
                case "VEHICLE_TRANSMISSION_MODE":
                    columnlabel = "Vehicle Transmission Mode"
                    columnunits = "Vehicle Transmission Mode"
                    columngreen = "MAX_GREEN"
                    columnred = "MAX_RED"
                    columnvalue = listofvitals[i]
                    columnmin = countValue[i]?.label_1 ==='' ? '' : countValue[i]?.label_1 + ': ' + countValue[i]?.min_value
                    columnmax = countValue[i]?.label_2 === '' ? '' : countValue[i]?.label_2 + ': ' + countValue[i]?.max_value
                    break
                default:
                    columnlabel = "Battery Potential"
                    columnunits = "Battery Potential (volts)"
                    columnvalue = listofvitals[i]
                    columngreen = "MAX_GREEN"
                    columnred = "MAX_RED"
            }

            var topContainer = chartlist["chart" + [i]].chartContainer.createChild(am4core.Container);
            topContainer.layout = "absolute";
            topContainer.toBack();
            topContainer.paddingBottom = 15;
            topContainer.width = am4core.percent(100);

            var dateTitle = topContainer.createChild(am4core.Label);
            dateTitle.text = columnlabel;
            dateTitle.fontWeight = 600;
            dateTitle.align = "center";
            dateTitle.layout = "absolute";
            dateTitle.x = -50;
            dateTitle.y = -30;

            var dateTitle = topContainer.createChild(am4core.Label);
            dateTitle.text = columndiff;
            dateTitle.align = "center";
            dateTitle.fontSize = "12px";
            dateTitle.fontWeight = 600;
            dateTitle.fill = am4core.color("#1045ad");

            var dateTitle = topContainer.createChild(am4core.Label);
            dateTitle.text = columnmin;
            dateTitle.fontWeight = 600;
            dateTitle.marginLeft = 25;
            dateTitle.align = "left";
            dateTitle.fontSize = "12px";
            dateTitle.fill = am4core.color("#1045ad");

            var dateTitle = topContainer.createChild(am4core.Label);
            dateTitle.text = columnmax;
            dateTitle.fontWeight = 600;
            dateTitle.align = "right";
            dateTitle.fontSize = "12px";
            dateTitle.fill = am4core.color("#1045ad");

            var valueAxis = chartlist["chart" + [i]].yAxes.push(new am4charts.ValueAxis());
            valueAxis.title.text = columnunits;
            valueAxis.fontSize = "12px";

            var series = chartlist["chart" + [i]].series.push(new am4charts.LineSeries());
            series.dataFields.valueY = columnvalue;
            series.dataFields.categoryX = "LOCAL_TIMESTAMP";
            series.name = columnlabel;
            series.tooltipText = "{name}: [bold]{valueY}[/]";
            series.minBulletDistance = 15;
            series.strokeWidth = 2;
            series.stroke = am4core.color("#1045ad");

            var series1 = chartlist["chart" + [i]].series.push(new am4charts.LineSeries());
            //series1.dataFields.dateX = columngreen;
            series1.dataFields.openValueY = columnred;
            series1.dataFields.valueY = columngreen;
            series1.dataFields.categoryX = "LOCAL_TIMESTAMP";
            series1.name = columngreen;
            series1.sequencedInterpolation = true;
            //series1.defaultState.transitionDuration = 1000;
            series1.tooltipText = "Max-Threshold: [bold]{valueY}[/]";
            series1.minBulletDistance = 15;
            series1.strokeWidth = 1;
            series1.stroke = am4core.color("#beedb3");
            series1.stroke = chartlist["chart" + [i]].colors.getIndex(15);
            series1.fill = series1.stroke;
            series1.fillOpacity = 0.6;
            var colorSet = new am4core.ColorSet();
            colorSet.saturation = 0.4;

            var series2 = chartlist["chart" + [i]].series.push(new am4charts.LineSeries());
            //series2.dataFields.dateX = columngreen;
            series2.dataFields.valueY = columnred;
            series2.dataFields.categoryX = "LOCAL_TIMESTAMP";
            series2.sequencedInterpolation = true;
            series2.defaultState.transitionDuration = 1;
            series2.name = columnred;
            series2.tooltipText = "Min-Threshold: [bold]{valueY}[/]";
            series2.tooltip.background.fill = am4core.color("#beedb2");
            series2.minBulletDistance = 15;
            series2.strokeWidth = 1;
            series2.stroke = am4core.color("#beedb3");
            // Add cursor
            chartlist["chart" + [i]].cursor = new am4charts.XYCursor();

            // Add simple vertical scrollbar
            //chart.scrollbarY = new am4core.Scrollbar();

            // Add horizotal scrollbar with preview
            var scrollbarX = new am4charts.XYChartScrollbar();
            scrollbarX.series.push(series);
            chartlist["chart" + [i]].scrollbarX = scrollbarX;
            chartlist["chart" + [i]].scrollbarX.parent = chartlist["chart" + [i]].bottomAxesContainer;
            chartlist["chart" + [i]].scrollbarX.background.fill = am4core.color("#add3ef");
            chartlist["chart" + [i]].scrollbarX.thumb.background.fill = am4core.color("#017acd");
            chartlist["chart" + [i]].scrollbarX.startGrip.background.fill = am4core.color("#017acd");
            chartlist["chart" + [i]].scrollbarX.endGrip.background.fill = am4core.color("#017acd");
            chartlist["chart" + [i]].scrollbarX.stroke = am4core.color("#017acd");
            chartlist["chart" + [i]].scrollbarX.minWidth = 50;

            let bullet = series.bullets.push(new am4charts.CircleBullet());
            bullet.circle.strokeWidth = 2;
            bullet.circle.radius = 4;
            bullet.circle.fill = am4core.color("#fff");

            let bullethover = bullet.states.create("hover");
            bullethover.properties.scale = 1.3;

        } else {
            SetVitalNull()
            var vitalkeyss = (Object.keys(vitalslistvalue))
            for (let i = 0; i < vitalkeyss.length; i++) {
                document.getElementById(vitalkeyss[i]).checked = false;
            }
        }
    }
}


export const Divelement = ({ data, resertopt, resetonly}) => {
    let divcontentdy = [];
    //var divclasstrends;
    let vitallist = data;
    if (vitallist !== 0 && vitallist !== "") {
        vitallist.map((val, index) => {
            var idvalue = "chart" + index;
            if (vitallist.length === 1) {
                divcontentdy.push(<div className="col-md-10 mt-4"><div id={idvalue} style={{ width: "100%", height: "400px" }}></div></div>)
            }
            divcontentdy.push(<div className="col-md-6 col-ct-css mt-4"><div id={idvalue} style={{ width: "100%", height: "400px" }}></div></div>)
            return divcontentdy;
        });
        return divcontentdy;
    } else if (vitallist === "" && resertopt === "notreset") {
        return <div className="errorDivtrend"><p className="errorMsg">No Data Available</p></div>
    } else if (resetonly === "active") {
        return <div className="errorDivtrend"></div>
    }
    else {
        return <div className="col-md-6 col-ct-css"><div className='removeReset' style={{ width: "100%", height: "400px" }}></div></div>
    }
}
export const isDEFAvailable = (adBlueTankCap) => {
	return !isNaN(adBlueTankCap) && parseInt(adBlueTankCap) > 0 ? true : false;
}