import React from "react";
import {compose, withProps, lifecycle } from "recompose";
import {
  withScriptjs,
  withGoogleMap,StreetViewPanorama,
  GoogleMap,
  Marker,
} from "react-google-maps";
import { GOOGLE_MAP_API_KEY } from "../../apiList";


const MapBox = compose(
    withProps({
      googleMapURL: "https://maps.googleapis.com/maps/api/js?libraries=places,geometry,visualization,drawing&key="+GOOGLE_MAP_API_KEY+"&v=3.exp&sensor=false",
      loadingElement: <div style={{ height: `100%` }} />,
      containerElement: <div style={{ height: `70vh` }} />,
      mapElement: <div style={{ height: `100%` }} />,
    }),
    withScriptjs,
    withGoogleMap,
    
    lifecycle({
      componentDidMount() {
        // this.interval =  setInterval(() =>  countdown() ,10000)
      },
    })
  )(props =>
   
    <GoogleMap  
      zoom={props.zoom}
      center={props.center}
      ref={ props.zoomToMarkers }  
    >
    
    <StreetViewPanorama position={props.center} visible={props.visible}/>
        {
            props.traceData ?
            props.traceData.map((mark, index) => {
               var MarkerOptions = {
                    // path: window.google.maps.SymbolPath.CIRCLE,
                    fillOpacity: 3,
                    scale: 3,
                    strokeColor: '#009900', // ,#006622 dots
                    strokeWeight: 3
                };
                var title = "Reg No : " +  props.traceData[0].regn_number + "\n" +
                "Location : " +  props.traceData[0].location + "\n" +
                "TimeStamp : " +  props.traceData[0].fleetTime; 
                // "Engine RPM : " + Math.round(mark.enginespeed) + "\n" +
                // "GPS Speed : " + Math.round(mark.gpsspeed) + " km/hr \n" +
                // isbs6cng + Math.round(fL) + isbs6unit + "\n" +
                // "Ignition Status : " + iS + "\n" +
                // "Location : " + mark.location + "\n" +
                // "Distance Travelled : " + Math.round(mark.  distance) + " KM \n" +
                // isbs6cng_cons + Math.round(fC) + isbs6unitkg + "  \n" + isbs6at;
                return <Marker  icon={MarkerOptions} key={index} position={{ lat: props.traceData[0].lat, lng: props.traceData[0].lng }} title={title} >
                </Marker>
            }) : ''
        }
  
      {props.showMarker && <Marker position={props.markerPosition} />}
    </GoogleMap>
  );
  

class FleetMap extends React.Component{

    constructor(props){
      super(props);
    }

  
    render(){
      return <div>
            <MapBox zoom={this.props.zoom}  visible={this.props.visible}   mapTypeControl={true}
            fullscreenControl={false}
            streetViewControl={false}
            center={this.props.center}  markerPosition={this.props.markerPosition} 
            height={this.props.height}showMarker={this.props.showMarker}
            traceData={this.props.traceData}  zoomToMarkers={ this.props.zoomToMarkers }
            >
            </MapBox>
        </div>
              
   }
  }
  
  export default FleetMap;