import * as actionTypes from './constants'

const initState = {
  submenulist: [],
  showLoginMenu: false
};

const HeaderReducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.TOGGLE_HEADER:
      return {
        submenulist: action.payload
      }
    case actionTypes.TOGGLE_LOGIN:
      return {
        ...state,
        showLoginMenu: action.payload
      }
    default:
      return state
  }
}

export default HeaderReducer