import React, { useState } from "react";
import "./VehicleCopyLink.scss";
import classnames from "classnames";

const VehicleCopyLink = (props) => {
  const { showCopyLinkModelDialog, handleCopyLinkClose, link } = props;
  const [isCopied, setIsCopied] = useState(false);

  // This is the function we wrote earlier
  async function copyTextToClipboard(text) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  }

  // onClick handler function for the copy button
  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(link)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div
      className={classnames(
        "copy-link-share-popup copy-link-popup-model",
        showCopyLinkModelDialog ? "copy-link-active-modal" : "copy-link-close-modal"
      )}
      id="copy-link-container-id"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="copy-link-popup-model"
      aria-hidden="true"
    >
      <div className="copy-link-share-modal-content">
        <div className="copy-link-header">
          <button
            type="button"
            className="copy-link-close"
            onClick={handleCopyLinkClose}
            aria-label="Close"
          >
            <span className="fs_20" title="Close" aria-hidden="true">
              &times;
            </span>
          </button>
          <h5 className="copy-link-share-modal-title">Vehicle share link information</h5>
        </div>
        <div className="copy-link-share-modal-body">
          <div className="copy-link-clip">
            <h2 className="copy-link-share-modal-title">{link}</h2>
            <button type="button" className={isCopied ? "btn copied_btn" : "btn copy_btn"} onClick={handleCopyClick}>
            <span className={isCopied ? "text_copied_btn" : "text_copy_btn"}>{isCopied ? "Copied!" : "Copy"}</span>
          </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VehicleCopyLink;
