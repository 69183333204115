export const questions = [
    {
        'title': 'My Vehicles',
        'id': '009',
        'key': 'top_bottom_three_performance',
        'questions': [
            {
                'title': 'Distance Travelled',
                'id': '009_1',
                'key': 'top_bottom_distance',
                'api': 'f_report',
                'column': 'dis_cov'
            },
            {
                'title': 'Fuel Consumption',
                'id': '009_2',
                'key': 'top_bottom_fuel_cons',
                'api': 'f_report',
                'column': 'fuel_cons'
            },
            {
                'title': 'Fuel Mileage (kmpl)',
                'id': '009_3',
                'key': 'top_bottom_mileage',
                'api': 'f_report',
                'column': 'avg_kmpl'
            },
            {
                'title': 'Driving Economy Score',
                'id': '009_4',
                'key': 'top_bottom_driving_economy_score',
                'api': 'f_report',
                'column': 'performance_score'
            },
            {
                'title': 'Vehicle Utilization (%)',
                'id': '009_11',
                'key': 'top_bottom_utilization',
                'api': 'f_report',
                'column': 'utilization'
            },
            {
                'title': 'Vehicle Running',
                'id': '009_12',
                'key': 'top_bottom_runtime',
                'api': 'f_report',
                'column': 'run_time'
            },
            {
                'title': 'Idling Duration',
                'id': '009_6',
                'key': 'top_bottom_idling',
                'api': 'f_report',
                'column': 'idle_time'
            },
            {
                'title': 'Night Driving Duration',
                'id': '009_7',
                'key': 'top_bottom_night_driving',
                'api': 'f_report',
                'column': 'night_driving_duration'
            },
            // {
            //     'title': 'Continuous Driving Duration',
            //     'id': '009_8',
            //     'key': 'top_bottom_continuous_driving',
            //     'api': 'f_report',
            //     'column': 'max_continuous_hr_run'
            // },
            {
                'title': 'Neutral Driving Duration',
                'id': '009_9',
                'key': 'top_bottom_neutral_driving',
                'api': 'f_report',
                'column': 'neutral_gear_utlzn'
            },
            {
                'title': 'High RPM Driving (%)',
                'id': '009_5',
                'key': 'top_bottom_redband_percentage',
                'api': 'f_report',
                'column': 'red_band_percentage'
            },
            // {
            //     'title': 'Over Racing',
            //     'id': '009_10',
            //     'key': 'top_three_over_racing',
            //     'api': 'gearutilreport',
            //     'column': 'run_over_racing_band_per'
            // }
        ]
    },
    {
        'title': 'Fleet Insights',
        'id': '008',
        'key': 'fleet_insights',
        'questions': [
            {
                'title': 'Performance',
                'id': '008_1',
                'key': 'insights_performance',
                'api': 'report',
                'column': ''
            },
            {
                'title': 'Productivity',
                'id': '008_2',
                'key': 'insights_productivity',
                'api': 'report',
                'column': ''
            },
            {
                'title': 'Safety',
                'id': '008_1',
                'key': 'insights_safety',
                'api': 'safetyalerts',
                'column': ''
            }
        ]
    },
    {
        'title': 'Vehicle Location',
        'id': '005',
        'key': 'current_location',
        'api': 'ownVehicleList',
        'column': ''
    }
  
]

export const top_bottom_keys = {
    top_bottom_distance: 'dis_cov',
    top_bottom_fuel_cons: 'fuel_cons',
    top_bottom_mileage: 'avg_kmpl',
    top_bottom_driving_economy_score: 'performance_score',
    top_bottom_idling: 'idle_time',

}
