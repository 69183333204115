import React from 'react'
import classnames from 'classnames'

const EditHeader = (props) => {
  return (
    <div className="col-lg-5 edit-header">
      <div className="row">
        <div>
          <span className={classnames('statusIcon', props.statusColor)}></span>
        </div>
        <div className="tripEditName">{props.trip.trip_name}</div>
      </div>
      <div className="tripHeader">{props.trip.trip_status_state}</div>
    </div>
  );
}

export default EditHeader
