import React from 'react';
import { getDistanceValue, getFuelLevelValue } from '../../Components/NewListView/Utils';

const TracePopup = ({ checkVal, distance, fuelVal, kmpl, defsVal, showDEF, runningTime, idlingTime, haltTime, chargingTime, evVehicleParam, selectedVehicle }) => {
  if (!checkVal) return null;
  const {isBS6} = selectedVehicle;
  const isEVVehicle = isBS6 === 4;
  const isBS6CNG = isBS6 === 2;
  const fuelUnit = isBS6CNG ? "kg" : isEVVehicle ? " KWh" : "ltr";
  const fuelConsumedLabel = isBS6CNG ? "Gas Consumed" : "Fuel Consumed";
  const distanceValue = getDistanceValue(distance);
  const {energyConsumed, dte, batteryStatus } = evVehicleParam;
  const defConsumedRow = showDEF(selectedVehicle) && (
    <tr>
      <td>DEF Consumed</td>
      <td>{defsVal ? defsVal.toFixed(1) + ` ${fuelUnit}` : defsVal + ` ${fuelUnit}`}</td>
    </tr>
  );

  return (
    <div className="customPopup">
      <table>
        <tbody>
          <tr>
            <td>Distance</td>
            <td>{distance ? distanceValue + " km" : distance + " km"}</td>
          </tr>
          {isEVVehicle && <tr>
            <td>Energy Consumed</td>
            <td>{energyConsumed ? energyConsumed +" kWh" : " - "}</td>
          </tr>}
          {isEVVehicle && <tr>
            <td>Efficiency</td>
            <td>{distanceValue && energyConsumed ? energyConsumed > 0 ? (distanceValue / energyConsumed).toFixed(1) : energyConsumed +" kwh/km" : " - "}</td>
          </tr>}
          {isEVVehicle && <tr>
            <td>Distance to Empty </td>
            <td>{dte + " km"}</td>
          </tr>}
          {isEVVehicle && <tr>
            <td>Battery Status </td>
            <td>{batteryStatus + " %"}</td>
          </tr>}
          {!isEVVehicle && <tr>
            <td>{fuelConsumedLabel}</td>
            <td>{fuelVal ? getFuelLevelValue(fuelVal) : fuelVal}{fuelUnit}</td>
          </tr>}
          {!isEVVehicle && <tr>
            <td>{isBS6CNG ? "km/kg" : "kmpl"}</td>
            <td>{kmpl}</td>
          </tr>}
          {defConsumedRow}
          <tr><span className="hhmmss_legend">Duration(hh:mm:ss)</span></tr>
          <tr><td>Running Time</td><td>{runningTime}</td></tr>
          <tr><td>Idling Time</td><td>{idlingTime}</td></tr>
          <tr><td>Halt Time</td><td>{haltTime}</td></tr>
          {isEVVehicle && <tr><td>Charging Time</td><td>{chargingTime}</td></tr>}
        </tbody>
      </table>
    </div>
  );
};

export default TracePopup;
