import React, { Component } from 'react'
import Dealermaps from './FindDealer'

export default class dealersmap extends Component {
    constructor(props){
        super(props);
        this.CircleRef = React.createRef();
        this.state={

        }
    }

    onRadiusChanged=()=>{
    var rawDistance = this.CircleRef.current.getRadius();
    var kms = parseInt(rawDistance / 1000);
    var dsikms = parseInt(rawDistance / 1000)
    if(kms>200){
        if(this.state.kms===200){
        kms=201 
        }else{ kms=200 }     
        
    }if(kms>=200){
        this.setState({displaytext:200})
    }else{
        this.setState({displaytext:dsikms})
    }
   
   
    this.setState({
        kms:kms
    })
    this.props.dropdownChangedFunc()
    }
    render() {
        return (
            <>
               <Dealermaps {...this.props} CircleRef={this.CircleRef}  directions={this.state.directiontofuel} displaytexted={this.state.displaytext} kms={this.state.kms} onRadiusChanged={this.onRadiusChanged} dropdown_value={this.props.dropdown_value}/> 
            </>
        )
    }
}
