import React from "react";
import { Route, Switch } from "react-router-dom";
import { Suspense, lazy } from "react";
import ErrorBoundary from "../Components/ErrorBoundry/ErrorBoundry";
import { Loader } from "../Pages/Tracking/Loader";
import Dashboard from "../Components/Dashboard";
import GoogleMap from "./../Components/GoogleMap";
import vehicleinfo from "./../Pages/Vehicleinfo/Vehicleinfo";
import DealerMaps from './../Pages/Dealermap/dealermapview';

// Imported lazy-loaded components...
const Loginpage = lazy(()=>import('./../Pages/Login/Loginpage'))
const MyPeople = lazy(()=>import('./../Pages/MyPeople/People'))
// const GoogleMap = lazy(()=>import('./../Components/GoogleMap'))
const trace = lazy(()=>import('./../Pages/Trace/TraceScreen'))
const vehiclTracking = lazy(()=>import('./../Pages/Tracking/VehicleTracking'))
// const vehicleinfo = lazy(()=>import('./../Pages/Vehicleinfo/Vehicleinfo'))
// const Dashboard = lazy(()=>import('./../Components/Dashboard'))
const FleetInsightsDashboard = lazy(()=>import('./../Pages/FleetInsights/fleetinsightsdashboard'))
const FleetInsightsDash = lazy(()=>import('./../Pages/FleetInsights/other files/fleetinsightsDash'))
const FleetInsightsReports = lazy(()=>import('./../Pages/FleetInsights/FIreport'))
const FleetInsightsRepnew = lazy(()=>import('./../Pages/FleetInsights/newreport'))
const FleetInsightsRep = lazy(()=>import('./../Pages/FleetInsights/fleetinsightsreport'))
const gearutilrep = lazy(()=>import('./../Pages/FleetInsights/gearutilreport'))
const FleetInsightsGearUtil = lazy(()=>import('./../Pages/FleetInsights/other files/fidashgearutil'))
const Utilization = lazy(()=>import('./../Pages/FleetInsights/other files/utilizationdash'))
const fleetManagement = lazy(()=>import('./../Pages/AddVehicle/fleetManagement'))
const UserProfile = lazy(()=>import('./../Pages/Settings/UserProfile'))
const geofence = lazy(()=>import('./../Pages/GeoFence/geofence'))
// const DealerMaps = lazy(()=>import('./../Pages/Dealermap/dealermapview'))
const FleetDrivers = lazy(()=>import('./../Pages/FleetDrivers/FleetDrivers'))
const CustomerSupport = lazy(()=>import('./../Pages/CustomerSupport/CustomerSupport'))
const Remapping = lazy(()=>import('./../Pages/Remapping/Remapping'))
const CreateTraining = lazy(() =>import('./../Pages/UserTraning/UserTraningCreate'))
const CustomerTraining = lazy(() =>import('./../Pages/CustomerTraining/CutomerTrainingPage'))
const VehicleDetails = lazy(()=>import('./../Pages/VehicleDetails/VehicleDetails'))
const tabdashboad = lazy(()=>import('./../Pages/Tableau_dashboard/tab_dashboard'))
const AIS140ActivationRequest = lazy(()=>import('./../Pages/AIS140/AIS140Activation'))
const logoutfunctions = lazy(()=>import('./../Pages/logout/logout'))
const successfunctions = lazy(()=>import('./../Pages/logout/logout'))
const SarathyDashboard = lazy(()=>import('./../Components/SarathyDashboard/SarathyDashboard'))
const Approval = lazy(()=>import('./../Pages/Approval/Approval'))
const Billingmoduleapp = lazy(()=>import('./../Pages/billingmodule/billingmodule'))
const BillingsubscribedUser = lazy(()=>import('./../Pages/billingmodule/billingsubscribedUser'))
const BillingDealerSMEApproval = lazy(() =>import('./../Pages/billingmodule/BillingCVG/HOApprovel'))
const Discount = lazy(()=>import('./../Pages/Discount/discount'))
const AlertsSafety = lazy(()=>import('./../Pages/FleetInsights/other files/AlertsSafety'))
const Fuel = lazy(()=>import('./../Pages/FleetInsights/other files/Fuel'))
const Bs6alerts = lazy(()=>import('./../Pages/FleetInsights/other files/Bs6Alerts'))
const Billingmodulepacks = lazy(()=>import('./../Pages/billingmodule/billingmodulePacks'))
const redirectpageslist = lazy(()=>import('./../Components/directpage/redirectpages'))
const PackageCreation = lazy(()=>import('./../Pages/Packagecreation/packagecreation'))
const dseMaster = lazy(()=>import('./../Pages/Dsemaster/dseMaster.js'))
const dealersales = lazy(()=>import('./../Pages/dealersales/dealersalesdash'))
const dealerdashboad = lazy(()=>import('./../Pages/dealersales/dealerdash'))
const AppPage = lazy(()=>import('./../Pages/appspage/appspage'))
const UserManual = lazy(()=>import('./../Pages/Usermanual/usermanual'))
const showVas = lazy(()=>import('./../Components/ShowVas/showVas'))
const createVas = lazy(()=>import('./../Components/CreateVas/createVas'))
const alapprovallist = lazy(()=>import('./../Pages/ALapproval/alapprovallist'))
const allusertileview = lazy(()=>import('./../Pages/allusertileview/allusertileview'))
const allusertiledash = lazy(()=>import('./../Pages/allusertileview/allusertiledash'))
const monthlyreportview = lazy(()=>import('./../Pages/monthlyReport/monthlyreportview'))
const GeoFenceReports = lazy(()=>import('./../Pages/GeoFence/GeoFenceReports'))
const vehicledashctrl = lazy(()=>import('./../Pages/VehicleDashboard/vehicledashboard'))
const SalesAdminExcel = lazy(()=>import('./../Pages/ALSalesAdmin/SalesAdminExcel'))
const CommunicationSettings = lazy(()=>import('./../Pages/CustomerSettings/CustomerSettings'))
const DealerPOI = lazy(()=>import('./../Pages/DealerPOI/DealerPOI'))
const notification = lazy(()=>import('./../Pages/NotificationAlert/notification'))
const funcRawData = lazy(() =>import('./../Pages/Rawdata/Functional_Component/RawDataDownload'))
const PSBAlerts = lazy(()=>import('./../Pages/PSBAlerts/PsbAlerts'))
const TripList = lazy(()=>import('./../Components/TripList'))
const CreateTrips = lazy(()=>import('./../Components/CreateTrips'))
const FleetAlerts = lazy(()=>import('./../Components/FleetAlerts/fleetAlerts'))
const SarathyShowTrip = lazy(()=>import('./../Components/SarathyShowTrip/SarathyShowTrip'))
const SarathyCreateTrip = lazy(()=>import('./../Components/SarathyCreateTrip/SarathyCreateTrip'))
// const Rawdata = lazy(()=>import('./../Pages/Rawdata/rawdataScreen'))
const TripDeviationSummary = lazy(()=>import('./../Pages/TripDeviation/TripDeviationSummary'))
const CreateMap = lazy(()=>import('./../Pages/TripDeviation/CreateTripDeviation'))
const FuelReport = lazy(()=>import('./../Pages/FuelReport/FuelReport'))
const ReleaseNotes = lazy(()=>import('./../Components/ReleaseNotes/ReleaseNotes'))
const MailerTemplate = lazy(()=>import('./../Components/MailerTemplate/Template'))
const TrainingApproval =  lazy(()=>import('./../Pages/TrainingRequest/RequestList'))

const Routes = () =>(
         <Switch>
              <Suspense fallback={<Loader />}>
                <Route path="/" exact component={Loginpage} />
                <Route path="/login" exact component={Loginpage} />
                <Route path="/map" exact component={GoogleMap} />
                <Route path="/home" exact component={Dashboard} />
                <Route path="/tripList" exact component={TripList} />
                <Route path="/fleetinsightsdashboard" component={FleetInsightsDashboard} />
                <Route path="/fleetinsightsdash" component={FleetInsightsDash} />
                <Route path="/fleetinsightsrep" component={FleetInsightsRep} />
                <Route path="/FleetKPI" component={FleetInsightsRepnew} />
                <Route path="/gearutilrep" component={gearutilrep} />
                <Route path="/fleetinsightsreport" component={FleetInsightsReports} />
                <Route path="/utilizationdash" component={Utilization} />
                {/* <Route path="/addVehicle" component={AddVehicle} /> */}
                <Route path="/fleetManagement" exact component={fleetManagement} />
                <Route path="/createTrips" exact component={CreateTrips} />
                <Route path="/trace" exact component={trace} />
                <Route path="/tracking/:id" exact component={vehiclTracking} />
                <Route path="/vehicleInfo/trace" component={vehicleinfo} />
                <Route path="/vehicleInfo/trend" component={vehicleinfo} />
                <Route path="/vehicleInfo/alerts" component={vehicleinfo} />
                <Route path="/vehicleInfo/findDealer" component={vehicleinfo} />
                <Route path="/MyPeople" component={MyPeople} />
                {/* <Route path="/ListView" component={ListView} /> */}
                <Route path="/fleetalerts" component={FleetAlerts} />
                <Route path="/fuelReport" component={FuelReport} />
                <Route path="/UserProfile" component={UserProfile} />
                <Route path="/SarathyCreateTrip" component={SarathyCreateTrip} />
                <Route path="/SarathyShowTrip" component={SarathyShowTrip} />
                <Route path="/SarathyDashboard" component={SarathyDashboard} />
                <Route path="/Rawdata" component={funcRawData} />
                <Route path="/fleetDrivers" component={FleetDrivers} />
                <Route path="/CustomerSupport" component={CustomerSupport} />
                <Route path="/TrainingRequest" component={CustomerTraining} />
                <Route path="/CreateTraining" component={CreateTraining} />
                <Route path="/Remapping" component={Remapping} />
                <Route path="/vehicleDetails" component={VehicleDetails} />
                <Route path="/AIS140" component={AIS140ActivationRequest} />
                <ErrorBoundary>
                  <Route path="/Dealermap" component={DealerMaps} />
                </ErrorBoundary>
                {/* <Route path="/sva" exact component={SingleVehicleAlerts} /> */}
                <Route path="/Dashboad" exact component={tabdashboad} />
                <Route path="/fleetmanagement/geofence" exact component={geofence} />
                <Route path="/figearutil" component={FleetInsightsGearUtil} />
                <Route path="/logout" exact component={logoutfunctions} />
                <Route path="/billingmodule/packages" exact component={Billingmoduleapp} />
                <Route path="/billingmodule/orderhistory" exact component={BillingsubscribedUser} />
                {/* <Route path="/billing" exact component={logoutfunctions} /> */}
                <Route path="/success" exact component={successfunctions} />
                <Route path="/discount" component={Discount} />
                <Route path="/Approval" component={Approval} />
                <Route path="/un_approval_subscription_data_dlr_sme" component={BillingDealerSMEApproval} />
                <Route path="/alertssafety" exact component={AlertsSafety} />
                <Route path="/fuel" exact component={Fuel} />
                <Route path="/bs6alerts" exact component={Bs6alerts} />
                <Route path="/billingmodulepacks" exact component={Billingmodulepacks} />
                <Route path="/dealersales" exact component={dealersales} />
                <Route path="/dealerdash" exact component={dealerdashboad} />
                <Route path="/apps" exact component={AppPage} />
                <Route path="/camportal" exact component={redirectpageslist} />
                <Route path="/packagecreation" exact component={PackageCreation} />
                <Route path="/dsemaster" exact component={dseMaster} />
                <Route path="/usermanual" exact component={UserManual} />
                <Route path="/showVas" exact component={showVas} />
                <Route path="/createVas" exact component={createVas} />
                <Route path="/alapproval" exact component={alapprovallist} />
                <Route path="/allusertiledash" exact component={allusertiledash} />
                <Route path="/allusertileview" exact component={allusertileview} />
                <Route path="/monthlyreport" exact component={monthlyreportview} />
                <Route path="/GeoFenceReports" component={GeoFenceReports} />
                <Route path="/vehicleInfo/VehicleDash" component={vehicledashctrl} />
                <Route path="/SalesAdminExcel" component={SalesAdminExcel} />
                <Route path="/communicationsettings" component={CommunicationSettings} />
                <Route path="/DealerPOI" component={DealerPOI} />
                <Route path="/notification" component={notification} />
                {/* <Route path="/Rawdata" component={funcRawData} /> */}
                <Route path="/PSBAlert" component={PSBAlerts} />
                <Route path="/trip_deviation_list" component={TripDeviationSummary} />
                <Route path="/create_trip" component={CreateMap} />
                <Route path="/ReleaseNotes" component={ReleaseNotes} />
                <Route path="/monthlymailer" component={MailerTemplate} />
                <Route path="/TrainingApproval" component={TrainingApproval} />
              </Suspense>
            </Switch>
  )
export default Routes;



