import React from 'react'
import './Loader.scss'

const Loader = (props) => {
  return (
    <>
      <div class="loading loading-chatbot">
          <span class="loading__dot"></span>
          <span class="loading__dot"></span>
          <span class="loading__dot"></span>
      </div>
      
    </>
    
  )
}

export default Loader