import * as apilist from "../../../apiList";
import { postData } from "../../../Util"; 
import moment from 'moment';

export const getVinNumbers = async (ctr) => {
    let newObj = {};
    let vehtokens = ctr.token;
    newObj.platform = "w";
    newObj.userId = ctr.userid;
    const response = await postData(apilist.vehicleInfo, newObj, vehtokens);
    return response;
};

export const getVinData = async (ctr, props, startDate, endDate, api) => {
    let result = [];
    if(props?.payload?.api === 'f_report' || api === 'f_report' || api === 'gearutilreport'){
        result = props.state.vin_number.map(data => data.vin_number);
    }
    else{
        result.push(props.state.selected_vin);
    }
    
    if(props?.payload?.api === 'safetyalerts'){
        const response = getSafetyData(ctr, props, startDate, endDate, result)
        return response;
    }
    else if(props?.payload?.api === 'gearutilreport' || api === 'gearutilreport'){
        const response = getBandData(ctr, props, startDate, endDate)
        return response;
    }
    else{
        var s_date = moment(startDate).format('YYYY-MM-DD');
        var e_date = moment(endDate).format('YYYY-MM-DD');
        let vehtokens = ctr.token;
        let newObj = {};
        newObj.platform = 'w';
        newObj.login_id = ctr.loginid;
        newObj.fdate = s_date
        newObj.tdate = e_date
        newObj.regn = result;
        const response = await postData(apilist.getfleetinsightreport + '/report', newObj, vehtokens);
        return response;
    }
   
};

export const getSafetyData = async (ctr, props, startDate, endDate, regn) => {
    let data = {};
    data.platform = "w";

    data.fdate = startDate;
    data.tdate = endDate;
    data.regn = "'" + regn + "'";

    const response = await postData(apilist.safetyalerts, data, ctr.token);
    return response;
}

export const getLocationData = async (ctr, props, filter_data) => {
    let vehlist = {};
    vehlist.userId = ctr.userid;
    if (ctr.userid === undefined) {
      vehlist.userId = "";
    }
    vehlist.userRole = ctr.userrole;
    vehlist.obuIdList = [filter_data];
    let vehtoken = ctr.token;
    vehlist.platform = "w";
    const response = await postData(apilist.allvehicledata, vehlist, vehtoken);
    return response;
};

export const getBandData = async (ctr, props, startDate, endDate) => {
    let result = [];
    result = props.state.vin_number.map(data => data.vin_number);
    var s_date = moment(startDate).format('YYYY-MM-DD');
    var e_date = moment(endDate).format('YYYY-MM-DD');
    let vehtoken = ctr.token;
    let newObj = {};
    newObj.platform = 'w';
    newObj.login_id = ctr.loginid;
    newObj.fdate = s_date
    newObj.tdate = e_date
    newObj.regn = result;
    const response = await postData(apilist.getfleetinsightreport + '/gearutilreport', newObj, vehtoken);
    return response;
};

export const getVehicleDetails = async (vinNumber, ctr) => {
    let newObj = {};
    let vehtokens = ctr.token;
    newObj.vin_no = vinNumber;
    const response = await postData(apilist.getVehicleDetails, newObj, vehtokens)
    return response;
};

export const formatAMPM = (date) => {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }


