import React, { useEffect } from 'react'
import Loader from '../Loader/Loader';
import CurrentLocation from './Answers/CurrentLocation';
import EconomyScore from './Answers/EconomyScore';
import Report from './Answers/Report';
import TopVehicles from './Answers/TopVehicles';
import './Answers/Answer.scss'
import scroll from '../Scroll'

const ResultComp = (props) => {
    const { setState } = props

    useEffect(() => {
        const elements = document.getElementsByClassName('btn-act');
        hideElements(elements)
    },[props])

    let distance, kmpl, fuel, def, e_score, enorm;
    let running, idling, halt, utilization;
    let harshacc = [], harshbrake = [], overspeed = [];
    let harse_acc, harse_break, over_speed;
    let night_driving, neutral_driving;
    let info = ''
    let AsortedData = []
    let DsortedData = []

        const vinData = props.payload.data;
        const selectedQuestion = props.payload.question;
        if (vinData.overallAvgData !== undefined && vinData.overallAvgData.length > 0) {
            if(props.payload.api === 'f_report'){

                let keys = []

                vinData.summaryData.map(data => {
                    keys.push(data.vin)
                })

                let uniqueKeys = [...new Set(keys)];

                uniqueKeys.map(key => {
                    
                    let cont_driving_count = 0;
                    let long_cont_driving = [];
                    let night_time_driving_dis = 0;
                    let night_driving_dur = 0;
                    let nue_driving_dur = 0;
                
                    vinData.summaryData.map(data => {
                        if(key === data.vin){
                            cont_driving_count += data.no_of_events;
                            long_cont_driving.push(data.max_continuous_hr_run);
                            night_time_driving_dis += data.night_driving_distance;
                            night_driving_dur += data.night_driving_duration;
                            nue_driving_dur += data.neutral_gear_utlzn;

                            data['no_of_events'] = data.no_of_events ? data.no_of_events : 0;
                            data['max_continuous_hr_run'] = data.max_continuous_hr_run ? data.max_continuous_hr_run : 0;
                            data['night_driving_distance'] = data.night_driving_distance ? data.night_driving_distance : 0;
                            data['night_driving_duration'] = data.night_driving_duration ? data.night_driving_duration : 0;
                            data['neutral_gear_utlzn'] = data.neutral_gear_utlzn ? data.neutral_gear_utlzn : 0;

                        }
                    });
                    vinData.overallAvgData.map(overall_data => {
                        if(key === overall_data.vin){
                            overall_data.no_of_events = cont_driving_count;
                            overall_data.max_continuous_hr_run = Math.max(...long_cont_driving);
                            overall_data.night_driving_distance = night_time_driving_dis;
                            overall_data.night_driving_duration = night_driving_dur;
                            overall_data.neutral_gear_utlzn = nue_driving_dur;

                        }
                    });
                });

                let filterCondition = data => data[props.payload.column] > 0;
                if (props.payload.column !== 'dis_cov') {
                    filterCondition = data => data[props.payload.column] > 0 && data.dis_cov >= 100;
                }

                if (props.payload.column === 'fuel_cons' || props.payload.column === 'avg_kmpl') {
                    if(props.payload.model === 'All'){
                        filterCondition = data => data[props.payload.column] > 0 && data.dis_cov >= 100;
                    }
                    else{
                        filterCondition = data => data[props.payload.column] > 0 && data.dis_cov >= 100 && data.vehcile_model === props.payload.model;
                    }
                }

                const sortData = (data, order) => {
                    return [...data].filter(filterCondition).sort((a, b) => order * (a[props.payload.column] - b[props.payload.column])).slice(0, 3);
                };

                AsortedData = sortData([...vinData.overallAvgData], 1);
                DsortedData = sortData([...vinData.overallAvgData], -1);

                if(props.payload.column === 'red_band_percentage' || props.payload.column === 'idle_time' || props.payload.column === 'night_driving_duration' || props.payload.column === 'max_continuous_hr_run' || props.payload.column === 'neutral_gear_utlzn' || props.payload.column === 'run_over_racing_band_per' ){
                    DsortedData = DsortedData.filter(item2 => !AsortedData.some(item1 => (item1.vin || item1.vin_number) === (item2.vin || item2.vin_number)));
                }else{
                    AsortedData = AsortedData.filter(item2 => !DsortedData.some(item1 => (item1.vin || item1.vin_number) === (item2.vin || item2.vin_number)));
                }

            }
            else{
                switch (selectedQuestion) {
                    case 'insights_performance':
                        distance = vinData.overallAvgData[0].dis_cov;
                        kmpl = vinData.overallAvgData[0].avg_kmpl;
                        fuel = vinData.overallAvgData[0].fuel_cons;
                        def = vinData.overallAvgData[0].adblue_cons;
                        e_score = vinData.overallAvgData[0].performance_score;
                        enorm = vinData.overallAvgData[0].enorm;
                        break;
                    case 'insights_productivity':
                        running = vinData.overallAvgData[0].run_time;
                        idling = vinData.overallAvgData[0].idle_time;
                        halt = vinData.overallAvgData[0].tot_hlt_tme;
                        utilization = vinData.overallAvgData[0].utilization;
                        break;
                }
            }
          
        }
        else{
            if(selectedQuestion === 'top_three_over_racing'){

                AsortedData = [...vinData].sort((a, b) => a[props.payload.column] - b[props.payload.column]);
                AsortedData = AsortedData.filter(data => data[props.payload.column] > 0 && data.distance_cov >= 100)
                AsortedData = AsortedData.slice(0, 3);
                DsortedData = [...vinData].sort((a, b) => b[props.payload.column] - a[props.payload.column]);
                DsortedData = DsortedData.filter(data => data[props.payload.column] > 0 && data.distance_cov >= 100)
                DsortedData = DsortedData.slice(0, 3);

                DsortedData = DsortedData.filter(item2 => !AsortedData.some(item1 => (item1.vin || item1.vin_number) === (item2.vin || item2.vin_number)));

            }
            else{
                if(selectedQuestion === 'insights_safety'){
                    if(vinData[1].safety !== undefined){
                        for (let i = 0; i < vinData[1].safety.length; i++) {
                            harshacc.push(vinData[1].safety[i].harsh_acc);
                            harshbrake.push(vinData[1].safety[i].harsh_brake);
                            overspeed.push(vinData[1].safety[i].over_speed_alert);
                        }
                    }
                    harse_acc = harshacc.reduce((partial_sum, a) => partial_sum + a, 0);
                    harse_break = harshbrake.reduce((partial_sum, a) => partial_sum + a, 0);
                    over_speed = overspeed.reduce((partial_sum, a) => partial_sum + a, 0);
                }
                switch (selectedQuestion) {
                    case 'current_location':
                        info = vinData;
                        break;
                    default:
                        info = [];
                }
            }
           
        }

    const ClickSuggetions = () => {
        setState(state => ({ ...state, q_clicked: false}))
        props.actionProvider.QuestionHandler(props);
        scroll();
    }   
    
    const handleButton = (type) =>{
        if(type === 'yes'){
            // props.actionProvider.SubQuestionHandler(props.state.sub_questions, (props.payload.api === 'report' || props.payload.api === 'safetyalerts') ? 'Fleet Insights' : 'Top / Bottom 3 Vehicles', props.payload.api, props.payload.column );
            setState(state => ({ ...state, expanded: props.state.index, yes_no_btn_clk: true, selected_model: ''}))

            if(props.payload.question !== "current_location"){
                let filterData = props.state.questions.filter(data => data.key === props.state.parent_key)
                // props.actionProvider.MenuQuestions(props, filterData, props.state.parent_key, props.state.parent_title);
            }
            else{
                setState(state => ({ ...state, expanded: false, selected_ques: props.payload.question, result: false, q_clicked: true}));
                props.actionProvider.VinHandler(props.payload.question, props.payload.title, 'ownVehicleList', '', '', props.payload.title);
            }

        }else{
            setState(state => ({ ...state, q_clicked: false, yes_no_btn_clk: true}))
            // props.actionProvider.ButtonsHandler(props);

        }
        scroll();
    }

    const hideElements = (elements) => {
        // Create a set to store class names with more than one occurrence

        // Iterate through each element
        for (let i = 0; i < elements.length; i++) {
            const currentElement = elements[i];
            const currentClassName = currentElement.className;

            if (i !== (elements.length - 1)) {
                currentElement.style.display = 'none';
            }
        }
    }

    const questionMappings = {
        insights_performance: {
          data: { 'distance': distance, 'kmpl': kmpl, 'fuel': fuel, 'def': def, 'e_score': e_score, 'enorm': enorm },
        },
        insights_productivity: {
          data: { 'running': running, 'idling': idling, 'halt': halt, 'utilization': utilization },
        },
        insights_safety: {
          data: { 'harse_acc': harse_acc, 'harse_break': harse_break, 'over_speed': over_speed },
        },
    };

    const questionType = props.payload.question;

    var divs = document.querySelectorAll('.loading-chatbot');
    if(divs.length > 0){
        for (var i = 0; i < divs.length - 1; i++) {
            divs[i].style.display = 'none'; // Hide all except last div with class "loading-chatbot"
        }
    }

    return (
        <>
        <div className='chatbot-result-div'>
            {/* <div className='d-flex' style={{"justifyContent": 'end', 'position': 'relative'}}>
                {(props.payload?.type && props.payload.type ==='direct' && (props.payload.column !== 'fuel_cons' && props.payload.column !== 'avg_kmpl')) && 
                    <div className='col-md-12 top-bottom-title-rst'>
                        <span>{props.state.parent_title} - {props.payload.title}</span>
                    </div>
                }
            </div> */}
            {props.payload.question === "current_location" && 
                <CurrentLocation question = {props.payload.question} data = {info} />
            }
            {(props.payload.question === "insights_performance" || props.payload.question === "insights_productivity" || props.payload.question === "insights_safety") &&
                <Report
                    props={props}
                    question={questionType}
                    startDate={props.payload.startDate}
                    endDate={props.payload.endDate}
                    data={questionMappings[questionType].data}
                    vinData={props.payload.data}
                />
            }
            {(props.payload.api === 'f_report' || props.payload.api === 'gearutilreport') && 
                <TopVehicles props={props} question = {props.payload.question} AsortedData = {AsortedData} DsortedData = {DsortedData} column = {props.payload.column} />
            }
            {(props.state.fleet_continue && !props.state.yes_no_btn_clk) &&
                <>
                    <div className='btn-act'>
                    {props.payload.api === 'f_report' ?
                        <p className='want-txt'>Do you want to continue Top / Bottom 3 Vehicles with same Date?</p> :
                        (props.payload.api === 'report' || props.payload.api === 'safetyalerts') ?
                            <p className='want-txt'>Do you want to continue Fleet Insights with same vehicle?</p> :
                            <p className='want-txt'>Do you want to continue with another vehicle?</p>
                    }
                        <button className='btn btn-info button-w' onClick={() => {handleButton('yes')}}>Yes</button>
                        <button className='btn btn-danger button-w' onClick={() => {handleButton('no')}}>No</button>
                    </div>
                </>
            }
        </div>
        </>
    )
}

export default ResultComp