import React from 'react';
import './tripList.scss';
import Filterbox from './filterbox';
import { postData, changeformatDate2, changeTimestampFormat } from '../../Util';
//import NavLink from '../../Components/Link/Link';
import CreateTrips from '../CreateTrips';
import { Link } from 'react-router-dom';
//import Pagination from './tablepagination'
import { connect } from 'react-redux';
import * as apilist from '../../apiList';
import "../../Components/BottomRibbon/ribbonStyle.scss";
import "../../Components/GoogleMap/index.scss";
import AlertBox from '../../Components/AlertBox/alertBox.js';
import SelectionBox from '../../Components/SelectionBox/SelectionBox.js';
import moment from 'moment';
import Tooltip from "@material-ui/core/Tooltip";

import * as alasql from 'alasql';
import XLSX from 'xlsx';
import { withStyles } from "@material-ui/core/styles";
import Geocode from "react-geocode";

Geocode.setApiKey(apilist.GOOGLE_MAP_API_KEY);
Geocode.enableDebug();

alasql.utils.isBrowserify = false;
alasql.utils.global.XLSX = XLSX;

const styles = {
    tooltip: {
      width: "250px",
      height: "120px",
      borderRadius: "0px",
      border: "1px solid #0d459a",
      backgroundColor: "#fff",
      marginLeft: "-20px",
    },
    arrow: {
       fontSize: 30,
       "&::before": {
         backgroundColor: "white",
         border: "1px solid #0d459a"
       }
     }
  };

  const CustomTooltip = withStyles(styles)(Tooltip);

class TripList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            total: 0,
            completed: 0,
            progress: 0,
            start: 0,
            tripDetails: [],
            filterData: [],
            filterclass: "Total",
            currentPage: 1,
            tripsPerPage: 7,
            loader: false,
            showTripDtls : false,
            sortTrip: true,
            sortRoute: true,
            tripSearch: "",
            addressArr:[],
            showMessage: "",
            showAlertBox: false,
            messageType: "e",
            showSelectionBox:false,
            showSelectionMessage:"",
            messageSelectionType:"r",
            trip_id:""
        }
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        var tripData = {};
        tripData.platform = "w";
        tripData.userId = this.props.ctr.userid;
        let tokens = this.props.ctr.token;
        this.setState({loader: true});
        const tripValueList = postData(apilist.getTrip+'/tripList', tripData,tokens);
        tripValueList.then((tripValue) => {
            this.setState({
                tripDetails: tripValue.data,
                filterData: tripValue.data,
                showTripDtls : true,
                loader:false,
                trip_id:""
            },this.getAddress)
        });

        const tripCount = postData(apilist.getTrip+'/tripCount', tripData,tokens);
        tripCount.then((counts) => {
            const count = counts.data;
            this.setState({
                total: count.total,
                completed: count.completed,
                progress: count.in_progress,
                start: count.yet_to_start
                //cancelled: count.cancelled
            })
        })

    }

    filterTrip = (status,active,event) => {
        let filterclass = this.state.filterclass;
        var filterTripDetails = "";

        if(status !== "search"){
            filterclass = status;
            this.setState({
                tripSearch:""
            })
        }

        if (status === "total") {
            filterTripDetails = this.state.tripDetails;
        }
        else if(status === "search" && active === "Total"){
            filterTripDetails = this.state.tripDetails;
        }
        else {
            filterTripDetails = this.state.tripDetails.filter((name) => {
                return (name.trip_status === filterclass);
            });
        }

        if(status === "search"){
            filterTripDetails = filterTripDetails.filter((name) =>{
                var tripType = name.trip_type === null ? "" : name.trip_type.toLowerCase();
                return (name.trip_name.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1 ||
                    tripType.indexOf(event.target.value.toLowerCase()) !== -1 ||
                    name.route_name.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1 ||
                    name.regn_number.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1 ||
                    name.driver_name.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1 ||
                    name.created_by.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1)
            });
        }

        this.setState({
            filterData: filterTripDetails,
            filterclass: active,
            currentPage: 1,
        });
    }

    handleClick(event) {
        this.setState({
            currentPage: Number(event.target.id)
        });
    }


    statuscolor = (status) => {
        switch (status) {
            case "Cancelled":
                return <span className="statusIcon statusCancelled"></span>;
            case "In progress":
                return<span className="statusIcon statusProgress"></span>;
            case "Completed":
                return <span className="statusIcon statusCompleted"></span>;
            default:
                return <span className="statusIcon statusYetstart"></span>;
        }
    }

    changePage = (pagenumber) => {
        let pageno;
        let { currentPage } = this.state;
        let lastVal = (Math.ceil(this.state.filterData.length / this.state.tripsPerPage));
        if (pagenumber === "<") {
            pageno = currentPage - 1 !== 0 ? currentPage - 1 : 1;
        } else if (pagenumber === ">") {
            pageno = currentPage + 1 !== lastVal+1 ? currentPage + 1 : lastVal;
        } else if (pagenumber === "<<"){
            pageno = 1;
        } else if (pagenumber === ">>"){
            pageno = lastVal;
        }
         else {
            pageno = pagenumber;
        }
        this.setState({
            currentPage: pageno
        })
    }

    sortAcending = (val) =>{
        var sortedfilterData = this.state.filterData.sort(function(initial, secondary){
            var nameA = initial[val].toLowerCase();
            var nameB = secondary[val].toLowerCase();
            if (nameA < nameB){
                return -1; //sort string ascending
            }
            else if (nameA > nameB){
                return 1;
            }
            else{
                return 0; //default return value (no sorting)
            }
        });
        this.setState({
            filterData : sortedfilterData
        })
    }

    sortDescending = (val) =>{
        var sortedfilterData = this.state.filterData.sort(function(initial, secondary){
            var nameA = initial[val].toLowerCase();
            var nameB = secondary[val].toLowerCase();
            if (nameB < nameA){
                return -1; //sort string ascending
            }
            else if (nameB > nameA){
                return 1;
            }
            else{
                return 0; //default return value (no sorting)
            }
        });
        this.setState({
            filterData : sortedfilterData
        })
    }

    sortColumnTrip = (val) =>{
        if(this.state.sortTrip){
            this.sortAcending(val);
        }else{
            this.sortDescending(val);
        }
        this.setState({
            sortTrip: !this.state.sortTrip
        })
    }

    sortColumnRoute = (val) =>{
        if(this.state.sortRoute){
            this.sortAcending(val);
        }else{
            this.sortDescending(val);
        }
        this.setState({
            sortRoute: !this.state.sortRoute
        })
    }

    getAddress = () =>{
        let addressArr = [];
        for(let i=0; this.state.filterData.length > i; i++){
            let wayPoints = this.state.filterData[i].way_points;
            let tripId = this.state.filterData[i].trip_id;
            if(wayPoints!=="[]"){
                let address = [];
                var waypointsArr = JSON.parse(wayPoints);
                for(let index = 0; waypointsArr.length > index; index++){
                    var waypointArr = waypointsArr[index].split(',');
                    Geocode.fromLatLng(waypointArr[0], waypointArr[1]).then(
                        response => {
                            address.push(response.results[0].formatted_address);
                            addressArr[tripId]=address;
                        },
                        error => {
                            console.error(error);
                        }
                    );
                }
            }
            else{
                addressArr[tripId]=[];
            }
        }
        this.setState({
            addressArr: addressArr
        })
    }

    downloadExcel = () =>{

        let addressArr = this.state.addressArr;
        alasql.fn.moment = moment;

        alasql.fn.updateTimeStamp = function (val) {
            if(val === "" || val === undefined || val === null){
                return '';
            }

            // const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            // let current_datetime = new Date(val);
            // let hours = current_datetime.getHours();
            // let minutes = current_datetime.getMinutes();
            // let ampm = hours >= 12 ? 'PM' : 'AM';
            // hours = hours % 12;
            // hours = hours ? hours : 12; // the hour '0' should be '12'
            // minutes = minutes < 10 ? '0' + minutes : minutes;
            // var strTime = hours + ':' + minutes + ' ' + ampm;

            // let formatted_date = months[current_datetime.getMonth()] + " " + current_datetime.getDate() + ", " + current_datetime.getFullYear() + " " + strTime;
            // return formatted_date;

            return moment(val).format("DD-MM-YYYY h:mm:ss A");
        }

        alasql.fn.nullCheck = function (val){
            if(val === null){
                return "";
            }
            else{
                return val;
            }
        }

        alasql.fn.scoreCheck = function (val){
            if(val === null || val === -99){
                return "-";
            }
            else{
                return val;
            }
        }

        alasql.fn.getWayPoints = function (tripId){
            if(!addressArr[tripId]){
                return "-";
            }
            else{
                return addressArr[tripId].join();
            }
        }

        alasql('SELECT nullCheck(trip_name) AS [Trip Name], trip_status AS [Trip Status], nullCheck(trip_type) AS [Trip Type],'+
            'nullCheck(route_name) AS [Route Name], nullCheck(regn_number) AS [Registration Number], nullCheck(driver_name) AS [Driver Name],'+
            'nullCheck(origin_location) AS [Route Start Location], nullCheck(destination_location) AS [Route End Location],'+
            'getWayPoints(trip_id) AS [Way  Points],nullCheck(distance_covered) AS [Distance Travelled (km)],'+
            'nullCheck(fuel_consumed) AS [Fuel Consumption (ltr/ kg)],nullCheck(kmpl) AS [KMPL / KMPKG],nullCheck(adblue_consumed) AS [DEF Consumption (ltr)], nullCheck(adblue_kmpl) AS [DEF Percentage (%)],'+
            'nullCheck(run_time) AS [Running Hours (hh:mm:ss)],nullCheck(idling_time) AS [Idling Hours (hh:mm:ss)],'+
            'nullCheck(halt_time) AS [Halt Hours (hh:mm:ss)],nullCheck(total_alerts) AS [Total Alerts (Count)],'+
            'nullCheck(route_deviation) AS [Deviation Limit], updateTimeStamp(plnnd_start_date) AS [Planned Start Date],'+
            'updateTimeStamp(plnnd_end_date) AS [Planned End Date],updateTimeStamp(actl_start_date) AS [Actual Start Date],'+
            'updateTimeStamp(actl_end_date) AS [Actual End Date], nullCheck(created_by) AS [Trip Created By],scoreCheck(performance_score) AS [Driving Economy Score],scoreCheck(safety_score) AS [Driving Safety Score]' +
            'INTO XLSX("Trip_Summary_As_on_'+changeformatDate2(new Date())+'", {headers:true}) FROM ?', [this.state.filterData]);
    }

    passingVal=()=>{
        this.props.quicklinks([{mm:"3", sm:"13", tm:"1"}])
    }

    changeShowTrip = (event) =>{
        this.setState({
          [event.target.name]: event.target.value
        })
    }

    deleteTrip=()=>{
        this.setState({loader:true})
        let data={}
        data.trip_id=this.state.trip_id
        let tokens = this.props.ctr.token;
        const delTrip=postData(apilist.deleteTrip,data,tokens)
        delTrip.then((dataVals)=>{
            if(dataVals.statuscode===200){
                this.hideSelectionModal()
                this.openModel(dataVals.message,"s")
                this.componentDidMount()
            }
            else{
                this.hideSelectionModal()
                this.openModel(dataVals.message,"e")
            }
        })
    }

    openModel = (message, msgType) => {
        this.setState({
            showAlertBox: true,
            showMessage: message,
            messageType: msgType
        });
    }

    hideModal = () => {
        this.setState({
            showMessage: "",
            showAlertBox: false
        });
    };

    openSelectionModel = (trip_id) => {
        this.setState({
            showSelectionBox:true,
            showSelectionMessage:"Are you sure you want to delete Trip- "+trip_id +" ?",
            messageSelectionType:"r",
            trip_id:trip_id
        });
    }

    hideSelectionModal = () => {
        this.setState({
            showSelectionBox:false,
            showSelectionMessage:"",
            messageSelectionType:"r",
            trip_id:""
        });
    };

    render() {
        const { filterData, currentPage, tripsPerPage, showTripDtls, filterclass } = this.state;
        const { showMessage, showAlertBox, messageType, showSelectionBox, showSelectionMessage, messageSelectionType} = this.state;
        const indexOfLastTrip = currentPage * tripsPerPage;
        const indexOfFirstTrip = indexOfLastTrip - tripsPerPage;
        const currentTrips = filterData.slice(indexOfFirstTrip, indexOfLastTrip);

        const tripDetails = currentTrips.map((name) => {
            return <tr key={name.trip_id}>
                <td className="nav-creatTrip">
                    {this.statuscolor(name.trip_status)}
                    <Link onClick={this.passingVal} to={{ pathname: '/createTrips',
                        component: CreateTrips, state: { tripId: name.trip_id,trip_status: name.trip_status} }}>
                        {name.trip_name}
                    </Link>
                </td>
                <td>{name.trip_type}</td>
                <td>{name.route_name}</td>
                <td>{name.regn_number}</td>
                <td>{name.driver_name}</td>
                <td>{name.distance_covered || "-"}</td>
                <td>{name.fuel_consumed || "-"}</td>
                <td>{name.kmpl || "-"}</td>
                <td>{name.adblue_consumed || "-"}</td>
                <td>{name.adblue_kmpl || "-"}</td>
                <td>{name.run_time || "-"}</td>
                <td>{name.idling_time || "-"}</td>
                <td>{name.halt_time || "-"}</td>
                <td>{name.total_alerts || "-"}</td>
                <td>{changeTimestampFormat(name.plnnd_start_date)}</td>
                <td>{changeTimestampFormat(name.plnnd_end_date)}</td>
                <td>{changeTimestampFormat(name.actl_start_date)}</td>
                <td>{changeTimestampFormat(name.actl_end_date)}</td>
                {/* {this.statuscolor(name.trip_status)} */}
                <td>{name.created_by}</td>
                <td className='numeric-col'>
                    {name.performance_score !== -99 &&  name.performance_score !== null ?
                     <CustomTooltip  title={
                        <React.Fragment>
                           <ul style={{color:'#383838',fontSize:'13px',fontWeight:'600'}}>
                              <li style={{marginBottom:'6px',marginTop:'5px'}}>Idling - {name.idling_percentage ? name.idling_percentage.toFixed(2) : '0.00' }%</li>
                              <li style={{marginBottom:'6px'}}>Red Band - {name.red_band_percentage ? name.red_band_percentage.toFixed(2) : '0.00'}%</li>
                              <li style={{marginBottom:'6px'}}>Over Raising- {name.over_raising_percentage ? name.over_raising_percentage.toFixed(2) : '0.00'}%</li>
                              <li>Top Gear- {name.top_gear_percentage ? name.top_gear_percentage.toFixed(2) : '0.00'}%</li>
                           </ul>
                        </React.Fragment>
                        }
                        interactive
                        arrow
                        placement="right" >

                        {name.score_rank ? name.score_rank === "Top" ?
                           <h6 className="des" style={{color:"green"}}>{name.performance_score ? name.performance_score : '0.00'}</h6>   :
                           <h6 className="des" style={{color:"red"}}>{name.performance_score ? name.performance_score : '0.00'}</h6>  :
                           <h6 className="des">{name.performance_score ? name.performance_score : '0.00'}</h6>
                        }
                    </CustomTooltip> :
                     <h6>-</h6>
                    }
                </td>
                <td className='numeric-col'>
                    {name.safety_score !== -99 &&  name.safety_score !== null ?
                     <CustomTooltip  title={
                        <React.Fragment>
                           <ul style={{color:'#383838',fontSize:'13px',fontWeight:'600'}}>
                           <li style={{marginBottom:'6px',marginTop:'5px'}}>Harsh Acceleration - {name.harsh_acceleration_percentage ? name.harsh_acceleration_percentage.toFixed(2) : '0.00' }%</li>
                            <li style={{marginBottom:'6px'}}>Harsh Braking - {name.harsh_breaking_percentage ? name.harsh_breaking_percentage.toFixed(2) : '0.00'}%</li>
                            <li style={{marginBottom:'6px'}}>Over Speed - {name.overspeed_percentage ? name.overspeed_percentage.toFixed(2) : '0.00'}%</li>                           </ul>
                        </React.Fragment>
                        }
                        interactive
                        arrow
                        placement="right" >

                        {name.score_rank ? name.score_rank === "Top" ?
                           <h6 className="des" style={{color:"green"}}>{name.safety_score ? name.safety_score : '0.00'}</h6>   :
                           <h6 className="des" style={{color:"red"}}>{name.safety_score ? name.safety_score : '0.00'}</h6>  :
                           <h6 className="des">{name.safety_score ? name.safety_score : '0.00'}</h6>
                        }
                    </CustomTooltip> :
                     <h6>-</h6>
                    }
                </td>
                <td><i className="fa fa-trash" style={{cursor:"pointer"}} onClick={()=>this.openSelectionModel(name.trip_id)}></i></td>
            </tr>
        })

        let length = filterData.length;
        let pageNumbers = [];
        if(Math.ceil(length/tripsPerPage) > 1){
            for(let i=1;i<=Math.ceil(length/tripsPerPage);i++){
                pageNumbers.push(i);
            }
        }

        const renderPageNumbers = pageNumbers.map(number => {
            if(pageNumbers.length <= 6){
              return (
                <td
                  key={number}
                  id={number}
                  onClick={this.changePage.bind(this,number)}
                  className={
                    this.state.currentPage === number
                      ? "slectedbluepg pagesize"
                      : "notselected pagesize"
                  }
                >
                  {number}
                </td>
              );
            }else{
              if(number === 1){
                if(this.state.currentPage <= 5){
                  return (
                    <td
                      key={number}
                      id={number}
                      onClick={this.changePage.bind(this,number)}
                      className={
                        this.state.currentPage === number
                          ? "slectedbluepg pagesize"
                          : "notselected pagesize"
                      }
                    >
                    {number}
                    </td>
                  );
                }else{
                  return (
                    <td
                      key={number}
                      id={number}
                      onClick={this.changePage.bind(this,number)}
                      className={
                        this.state.currentPage === number
                          ? "slectedbluepg pagesize"
                          : "notselected pagesize"
                      }
                    >
                    {number}...
                    </td>
                  );
                }
              }else if(number >1 && number <= 6 && this.state.currentPage <= 6){
                return (
                  <td
                    key={number}
                    id={number}
                    onClick={this.changePage.bind(this,number)}
                    className={
                      this.state.currentPage === number
                        ? "slectedbluepg pagesize"
                        : "notselected pagesize"
                    }
                  >
                  {number}
                  </td>
                );
              }else if((number >= (this.state.currentPage-2) && number <= (this.state.currentPage+2)) && this.state.currentPage > 6){
                return (
                  <td
                    key={number}
                    id={number}
                    onClick={this.changePage.bind(this,number)}
                    className={
                      this.state.currentPage === number
                        ? "slectedbluepg pagesize"
                        : "notselected pagesize"
                    }
                  >
                  {number}
                  </td>
                )
              }else if(number === pageNumbers.length){
                return (
                  <td
                    key={number}
                    id={number}
                    onClick={this.changePage.bind(this,number)}
                    className={
                      this.state.currentPage === number
                        ? "slectedbluepg pagesize"
                        : "notselected pagesize"
                    }
                  >
                  <span>...</span>{number}
                  </td>
                )
              }else{
                  return "";
              }
            }
          });

        return (
            <div>
                {this.state.loader ?<div className="Pageloading"> <img className="loadimg" src="/images/loader_1.gif" alt="iAlert" /></div> :''}
                <div className="tripheader">
                    <div className="tripcontainer">
                        <div className="row">
                            <Filterbox name="Total"
                                click={this.filterTrip.bind(this, "total" , "Total")}
                                value={this.state.total}
                                filterclass={this.state.filterclass}/>
                            <Filterbox name="Completed"
                                status={this.statuscolor("Completed")}
                                click={this.filterTrip.bind(this, "Completed","Completed")}
                                value={this.state.completed}
                                filterclass={this.state.filterclass}/>
                            <Filterbox name="In progress"
                                status={this.statuscolor("In progress")}
                                click={this.filterTrip.bind(this, "In progress","In progress")}
                                value={this.state.progress}
                                filterclass={this.state.filterclass}/>
                            <Filterbox name="Yet to start"
                                status={this.statuscolor("Yet to Start")}
                                click={this.filterTrip.bind(this, "Yet to start","Yet to start")}
                                value={this.state.start}
                                filterclass={this.state.filterclass}/>
                            <div className="mt-1">
                                <button type="button" className="excel_btn"
                                    onClick={this.downloadExcel}
                                    disabled={!filterData.length}>
                                    <span className="fa fa-download"></span> Download
                                </button>
                                <div className="mt-3">
                                    <input type="text" className="trip-search-text" id="tripSearch"
                                        placeholder="Search" value={this.state.tripSearch}
                                        onKeyUp={this.filterTrip.bind(this,"search",filterclass)}
                                        onChange={this.changeShowTrip}
                                        name="tripSearch" autoComplete="off">
                                    </input>
                                    <span className="input-group-addon fa-input">
                                        <span className="fa fa-search fa-white-search"></span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tripTable-responsive">
                    <table id="tripTable" className="tripTable">
                        <thead>
                            <tr>
                                <th>Trip Name <i onClick={this.sortColumnTrip.bind(this,"trip_name")} className="fa fa-fw fa-sort"></i></th>
                                <th>Trip Type</th>
                                <th>Route Name <i onClick={this.sortColumnRoute.bind(this,"route_name")} className="fa fa-fw fa-sort"></i></th>
                                <th>Registration Number</th>
                                <th>Driver Name</th>
                                <th>Distance Travelled (km)<span className="trip-span">*</span></th>
                                <th>Fuel / Gas Consumption (ltr/ kg)<span className="trip-span">*</span></th>
                                <th>KMPL / KMPKG<span className="trip-span">*</span></th>
                                <th>DEF Consumption (ltr)<span className="trip-span">*</span></th>
                                <th>DEF Percentage (%)<span className="trip-span">*</span></th>
                                <th>Running Hours (hh:mm:ss)<span className="trip-span">*</span></th>
                                <th>Idling Hours (hh:mm:ss)<span className="trip-span">*</span></th>
                                <th>Halt Hours (hh:mm:ss)<span className="trip-span">*</span></th>
                                <th>Total Alerts (Count)<span className="trip-span">*</span></th>
                                <th>Planned Start Date</th>
                                <th>Planned End Date</th>
                                <th>Actual Start Date</th>
                                <th>Actual End Date</th>
                                {/* <th>Trip Status <i onClick={this.sortAscending.bind(this,"trip_status")} className="fa fa-fw fa-sort"></i></th> */}
                                <th>Trip Created By</th>
                                <th>Driving Economy Score <br />(out of 10)</th>
                                <th>Driving Safety Score <br />(out of 10)</th>

                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                tripDetails.length ? tripDetails :
                                showTripDtls ?
                                <tr>
                                    <td colSpan="8">
                                        <div className="errorDiv">
                                            <p className="errorMsg">
                                                No Data Available
                                            </p>
                                        </div>
                                    </td>
                                </tr> : null
                            }
                        </tbody>
                    </table>
                    {/* <Pagination pageNumbers={pageNumbers} paginate={this.changePage}
                        currentPage={currentPage}/> */}
                </div>
                <div className="trip-notes row">
                    <div className="trip-notes-row mt-3 ml-4">
                        <span className="trip-notes"><span className="trip-span trip-notes-span">*</span>
                            Value will be displayed after 10 hr from trip end time</span>
                    </div>
                    {pageNumbers.length ?
                    <div className="pagination-row mt-3">
                        <div className="pagina-length">
                            <table>
                                <tbody>
                                    <tr id="page-numbers">
                                        <td className="pagesize page_symbol" onClick={this.changePage.bind(this,"<<")}>&laquo;</td>
                                        <td className="pagesize page_symbol" onClick={this.changePage.bind(this,"<")}>&#x2039;</td>
                                        {renderPageNumbers}
                                        <td className="pagesize page_symbol" onClick={this.changePage.bind(this,">")}>&#8250;</td>
                                        <td className="pagesize page_symbol" onClick={this.changePage.bind(this,">>")}>&raquo;</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div> : null
                    }
                </div>
                <AlertBox show={showAlertBox} showMessage={showMessage}
                    click={this.hideModal} messageType={messageType}></AlertBox>
                <SelectionBox show={showSelectionBox} showMessage={showSelectionMessage}
                    click={this.hideSelectionModal} messageType={messageSelectionType} func={this.deleteTrip}></SelectionBox>
            </div>
        );
    }
}

const mapStateToprops = state =>{
    return{
        ctr:state.userdata.id
        //ctr:{userid: 655}
    };
}
const mapdispatchToprops = dispatch => {
    return {
        quicklinks:(data) => dispatch({type:'QUICKLINK', value:data})
    };
}
export default connect(mapStateToprops, mapdispatchToprops)(TripList);
