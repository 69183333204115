import React, { Component } from 'react';
import { GmapsDispatchThunk } from "../../store/actions/GmapsActions";
import './Dheades.scss';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { postData } from '../../Util';
import * as apiList from '../../apiList';
import LogoutBox from '../../Components/LogoutBox/LogoutBox';
import NavLink from "../../Components/Link/Link";
import { ACTION_EV_ENORM, GLOBAL_FILTER_DATA } from '../../store/constants';
import ExpiryAlertBox from './ExpiryAlertBox';
// import ReleaseNotes from "../Dashboard/ReleaseNotes";
const billingToken = localStorage.getItem('billingtoken');

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            opened: false,
            successmenu: false,
            menuJson: '',
            menuid: 0,
            menudata: '',
            thirdLevel: '',
            dropdownShow: false,
            buttonText: 'Vehicle',
            searchShow: true,
            searchText: '',
            vehicleDropdown: false,
            searchData: null,
            searchDataedit: null,
            initialItems: null,
            fleetVehicles: null,
            fleetGroups: null,
            submenulist: [],
            filterArray: [],
            selectedVehicleDropDown: [],
            autoreload: false,
            onlyRefresh: false,
            vehicleDropdownshow: false,
            hideshowdd: true,
            hideshowar: true,
            showNotify: true,
            defaultHeaderShow: true,
            globalData: [],

            /* For Alert Modal */
            showMessage: "",
            showAlertBox: false,
            messageType: "e",
            
            showExpiryAlertBox:false,
            showExpiryMessage:''


        };

        this.toggleBox = this.toggleBox.bind(this);
        this.changemenu = this.changemenu.bind(this);
        this.Thirdmenuchange = this.Thirdmenuchange.bind(this);
        this.selectionMenu = this.selectionMenu.bind(this);
    }

    openExpiryModel = (message) => {
        this.setState({
            showExpiryAlertBox: true,
            showExpiryMessage: message
        });
    };
    expiryHideModal = () => {
        this.setState({
            showExpiryAlertBox: false,
            showExpiryMessage: ''
        });
    };
    openModel = (message, msgType) => {
        this.setState({
            showAlertBox: true,
            showMessage: message,
            messageType: msgType
        });
    };

    hideModal = () => {
        this.setState({
            showMessage: "",
            showAlertBox: false
        });
    };

    selectionMenu = (event) => {
        if (event.target.getAttribute('menudata') === "1") {
            this.props.menulist(true);
        } else {
            this.props.menulist(false);
        }

        for (var i = 0; i < event.target.parentElement.parentElement.children.length; i++) {
            event.target.parentElement.parentElement.children[i].firstElementChild.classList.remove('activemenu');
            event.target.parentElement.parentElement.children[i].lastElementChild.classList.remove('showbar');
        }
        event.target.classList.add('activemenu');
        event.target.nextSibling.classList.add('showbar');
    }

    Thirdmenuchange = (j, event) => {
        let myselection = j;
        if (event.target.getAttribute('menudata') === "1") {
            this.props.menulist(true);
        } else {
            this.props.menulist(false);
        }
        var thirdLevelMenu = this.state.menuJson[this.state.menuid].submenu[j].submenu.map((sm, i) =>

            <li key={sm.id} className="nav-item">
                <Link menudata={sm.menu_state !== null ? "1" : "0"} to={{ pathname: sm.url, state: { fromNotifications: sm.menu_state } }} onClick={this.selectionMenu.bind(this)} className="nav-link">{sm.screen_name}</Link>
                <div className={sm.submenu_state !== true ? myselection = 0 : myselection = j, i === myselection ? "selectedMenu showbar" : "selectedMenu"}></div>
            </li>

        );

        this.setState({
            thirdLevel: thirdLevelMenu,
            opened: false,
            menuid: this.state.menuid,
            submenulist: []
        });
    }

    closeMenu = (event) => {
        if (event.target.href.indexOf("logout") > -1) {

            this.setState({
                showAlertBox: true,
                showMessage: "Are you sure you want to logout ?"
            });

            event.preventDefault();
            return;
        }

        if (event.target.href.indexOf("login") > -1) {
            this.props.menuData(false);
        }
        this.setState({
            opened: false
        });
    }

    goHome = () => {
        let path = window.location.pathname;
        this.setState({
            opened: false,
            showNotify: true,
            thirdLevel: ''
        }, () => {
            if (path !== "/home") {
                this.initOptions();
            }
        });
    }

    changemenu = (j, event) => {
        var parent = event.target.parentElement.parentElement.children;

        for (var i = 0; i < parent.length; i++) {
            parent[i].firstElementChild.classList.remove('activemenu');
            parent[i].lastElementChild.classList.remove('showbar');
        }
        event.target.classList.add('activemenu');
        event.target.nextSibling.classList.add('showbar');

        var subMenu = this.state.menuJson[j].submenu.map((sm, i) => <li key={sm.id} className="nav-item"><Link menudata={sm.menu_state !== null ? "1" : "0"} to={{ pathname: sm.url, state: sm.menu_state }} data={i} onClick={this.Thirdmenuchange.bind(this, i)} className="nav-link">{sm.screen_name}</Link><div className="selectedMenu"></div></li>);

        this.setState({
            submenulist: subMenu,
            opened: true,
            showNotify: true,
            thirdLevel: '',
            menuid: j
        });


    }

    toggleBox() {
        if (this.state.opened === true) {
            if (this.state.thirdLevel) {
                this.setState({
                    thirdLevel: this.state.thirdLevel,
                    opened: false,
                    menuid: this.state.menuid,
                    submenulist: []
                });
            } else {
                this.setState({
                    opened: false,
                    submenulist: []
                });
            }


        }
        else {
            this.setState({
                opened: true,

            }, () => {
                this.initOptions();
            });

        }

    }

    optionValueChange = (e) => {
        this.setState({
            dropdownShow: false,
            selectedVehicleDropDown: e.target.text === this.state.buttonText ? this.state.selectedVehicleDropDown : [],
            buttonText: e.target.text,
        }, this.settimeforsearch())
    }

    settimeforsearch = () => {
        this.settimerslist = 0;
        this.settimerslist = setInterval(() => {
            this.filterDropdownValueShow();
        }, 1000)
    }

    checkStatus = () => {
        let filterArray = [];
        let filterArrayvin = [];
        if (this.state.searchVehicleData !== undefined && this.state.searchVehicleData !== null) {
            if (this.state.buttonText.trim().toLowerCase() === "vehicle") {
                for (var i = 0; i < this.state.searchVehicleData.length; i++) {
                    filterArray.push(this.state.searchVehicleData[i].props.children[2])
                    filterArrayvin.push(this.state.searchVehicleData[i].props.children[4])
                }
            } else if (this.state.buttonText.trim().toLowerCase() === "model") {
                for (var a = 0; a < this.state.searchVehiclemodelData.length; a++) {
                    filterArray.push(this.state.searchVehiclemodelData[a].props.children[2])
                }
            } else if (this.state.buttonText.trim().toLowerCase() === "fleet manager") {
                for (var a = 0; a < this.state.searchVehiclemanagerData.length; a++) {
                    filterArray.push(this.state.searchVehiclemanagerData[a].props.children[2])
                }
            } else if (this.state.buttonText.trim().toLowerCase() === "enorm") {
                for (var a = 0; a < this.state.searchEnormData.length; a++) {
                    filterArray.push(this.state.searchEnormData[a].props.children[1])
                }
            }
            else {
                for (var a = 0; a < this.state.searchData.length; a++) {
                    filterArray.push(this.state.searchData[a].props.children[2])
                }
            }
        }
        return [filterArray, filterArrayvin];
    }

    optionValueSelect = (e) => {
        if (e.target.getAttribute('class') !== "dropdown-item") {
            if (e.target.checked === false) {
                e.target.checked = true;
                this.state.selectedVehicleDropDown.splice(this.state.selectedVehicleDropDown.indexOf(e.target.value), 1);
                this.setState({
                    selectedVehicleDropDown: this.state.selectedVehicleDropDown
                }, () => {
                    this.checkedGlobalVal();
                })

            } else {
                e.target.checked = false;
                this.state.selectedVehicleDropDown.push(e.target.value);
                this.setState({
                    selectedVehicleDropDown: this.state.selectedVehicleDropDown
                }, () => {
                    this.checkedGlobalVal();
                })
            }
        } else {
            if (e.target.children[0].checked === false) {
                e.target.children[0].checked = true;
                this.state.selectedVehicleDropDown.push(e.target.children[0].value);
                this.setState({
                    selectedVehicleDropDown: this.state.selectedVehicleDropDown
                })

            } else {
                this.state.selectedVehicleDropDown.splice(this.state.selectedVehicleDropDown.indexOf(e.target.children[0].value), 1);
                this.setState({
                    selectedVehicleDropDown: this.state.selectedVehicleDropDown
                })
                e.target.children[0].checked = false;

            }
        }

    }

    checkedGlobalVal = () => {
        var checkduplicatevehlist = [];
        var showdropvaluevehicle = [];
        if (this.state.globalData.globalObject !== null && this.state.globalData.globalObject !== undefined) {

            var arr1 = [];
            for (var i = 0; i < this.state.globalData.globalObject.length; i++) {
                if (!arr1.includes(this.state.globalData.globalObject[i].nomenclature_desc)) {
                    arr1.push(this.state.globalData.globalObject[i].nomenclature_desc);
                }
            }
            // console.log(arr1);
            for (var m = 0; m < this.state.globalData.globalObject.length; m++) {
                if (!checkduplicatevehlist.includes(this.state.globalData.globalObject[m].regn_number)) {
                    showdropvaluevehicle.push(this.state.globalData.globalObject[m])
                    checkduplicatevehlist.push(this.state.globalData.globalObject[m].regn_number)
                }
            }
            let sortedvalues = this.state.globalData.fleetGrpData.sort(function (a, b) {
                var nameA = a.group_name.toLowerCase(), nameB = b.group_name.toLowerCase()
                if (nameA < nameB) //sort string ascending
                    return -1
                if (nameA > nameB)
                    return 1
                return 0 //default return value (no sorting)
            })
            let sortedvaluesmanager = this.state.globalData.fleetMrgData.sort(function (a, b) {
                var nameA = a.fleet_manager_name.toLowerCase(), nameB = b.fleet_manager_name.toLowerCase()
                if (nameA < nameB) //sort string ascending
                    return -1
                if (nameA > nameB)
                    return 1
                return 0 //default return value (no sorting)
            })
            let sortedvaluesmodel = arr1.sort(function (a, b) {
                var nameA = a.nomenclature_desc, nameB = b.nomenclature_desc
                if (nameA < nameB) //sort string ascending
                    return -1
                if (nameA > nameB)
                    return 1
                return 0 //default return value (no sorting)
            })
            let sortedvaluesenorm = this.state.globalData.enorm.sort(function (a, b) {
                var nameA = a.e_norm.toLowerCase(), nameB = b.e_norm.toLowerCase()
                if (nameA < nameB) //sort string ascending
                    return -1
                if (nameA > nameB)
                    return 1
                return 0 //default return value (no sorting)
            })

            let option;
            if(this.state.buttonText === 'Vehicle'){
                option = showdropvaluevehicle.map((fv) => <button onClick={this.optionValueSelect} key={fv.obu_id} className="dropdown-item"><input className="dropdown-item-checkbox" style={{ float: "left", marginTop: "3px", marginRight: "5px" }} type="checkbox" value={fv.regn_number} checked={this.state.selectedVehicleDropDown.includes(fv.regn_number) ? true : false} /> {fv.regn_number} - {fv.vin_number}</button>)
            }
            else if(this.state.buttonText === 'Group'){
                option = sortedvalues.map((fg) => <button onClick={this.optionValueSelect} key={fg.fleet_group_id} className="dropdown-item"><input className="dropdown-item-checkbox" style={{ float: "left", marginTop: "3px", marginRight: "5px" }} type="checkbox" value={fg.fleet_group_id} checked={this.state.selectedVehicleDropDown.includes(fg.fleet_group_id.toString()) ? true : false} /> {fg.group_name.toUpperCase()}</button>)
            }
            else if(this.state.buttonText === 'Model'){
                option = sortedvaluesmodel.map((fm) => <button onClick={this.optionValueSelect} key={fm} className="dropdown-item"><input className="dropdown-item-checkbox" style={{ float: "left", marginTop: "3px", marginRight: "5px" }} type="checkbox" value={fm} checked={this.state.selectedVehicleDropDown.includes(fm) ? true : false} /> {fm.toUpperCase()}</button>)
            }
            else if(this.state.buttonText === 'Fleet Manager'){
                option = sortedvaluesmanager.map((ffm) => <button onClick={this.optionValueSelect} key={ffm.fleet_manager_name} className="dropdown-item"><input className="dropdown-item-checkbox" style={{ float: "left", marginTop: "3px", marginRight: "5px" }} type="checkbox" value={ffm.fleet_manager_id} checked={this.state.selectedVehicleDropDown.includes(ffm.fleet_manager_id.toString()) ? true : false} /> {ffm.fleet_manager_name.toUpperCase()}</button>)
            }
            else if(this.state.buttonText === 'ENorm'){
                option = sortedvaluesenorm.map((em) => <button onClick={this.optionValueSelect} key={em.is_bs6} className="dropdown-item"><input className="dropdown-item-checkbox" style={{ float: "left", marginTop: "3px", marginRight: "5px" }} type="checkbox" value={em.is_bs6} checked={this.state.selectedVehicleDropDown.includes(em.is_bs6.toString()) ? true : false} />{em.e_norm}</button>)
            }

            let searchFleetDatas = sortedvalues.map((fg) => <button onClick={this.optionValueSelect} key={fg.fleet_group_id} className="dropdown-item"><input className="dropdown-item-checkbox" style={{ float: "left", marginTop: "3px", marginRight: "5px" }} type="checkbox" value={fg.fleet_group_id} checked={this.state.selectedVehicleDropDown.includes(fg.fleet_group_id.toString()) ? true : false} /> {fg.group_name.toUpperCase()}</button>)
            let searchVehicleDatas = showdropvaluevehicle.map((fv) => <button onClick={this.optionValueSelect} key={fv.obu_id} className="dropdown-item"><input className="dropdown-item-checkbox" style={{ float: "left", marginTop: "3px", marginRight: "5px" }} type="checkbox" value={fv.regn_number} checked={this.state.selectedVehicleDropDown.includes(fv.regn_number) ? true : false} /> {fv.regn_number} - {fv.vin_number}</button>)
            let searchVehiclemodelDatas = sortedvaluesmodel.map((fm) => <button onClick={this.optionValueSelect} key={fm} className="dropdown-item"><input className="dropdown-item-checkbox" style={{ float: "left", marginTop: "3px", marginRight: "5px" }} type="checkbox" value={fm} checked={this.state.selectedVehicleDropDown.includes(fm) ? true : false} /> {fm.toUpperCase()}</button>)
            let searchVehiclemanagerDatas = sortedvaluesmanager.map((ffm) => <button onClick={this.optionValueSelect} key={ffm.fleet_manager_name} className="dropdown-item"><input className="dropdown-item-checkbox" style={{ float: "left", marginTop: "3px", marginRight: "5px" }} type="checkbox" value={ffm.fleet_manager_id} checked={this.state.selectedVehicleDropDown.includes(ffm.fleet_manager_id.toString()) ? true : false} /> {ffm.fleet_manager_name.toUpperCase()}</button>)
            let searchEnormDatas = sortedvaluesenorm.map((em) => <button onClick={this.optionValueSelect} key={em.is_bs6} className="dropdown-item"><input className="dropdown-item-checkbox" style={{ float: "left", marginTop: "3px", marginRight: "5px" }} type="checkbox" value={em.is_bs6} checked={this.state.selectedVehicleDropDown.includes(em.is_bs6.toString()) ? true : false} />{em.e_norm}</button>)
            this.setState({
                vehicleDropdown: true,
                searchData: searchFleetDatas,
                searchVehicleData: searchVehicleDatas,
                searchDataedit: option,
                searchVehiclemodelData: searchVehiclemodelDatas,
                searchVehiclemanagerData: searchVehiclemanagerDatas,
                searchEnormData: searchEnormDatas,
            })
        }
    }

    filterDropdownValueShow = () => {
        clearInterval(this.settimerslist);
        if (this.state.buttonText.trim().toLowerCase() === "vehicle") {
            this.setState({
                vehicleDropdown: true,
                dropdownShow: false,
                searchDataedit: this.state.searchVehicleData
            })
        } else if (this.state.buttonText.trim().toLowerCase() === "model") {
            this.setState({
                vehicleDropdown: true,
                dropdownShow: false,
                searchDataedit: this.state.searchVehiclemodelData
            })
        }
        else if (this.state.buttonText.trim().toLowerCase() === "fleet manager") {
            this.setState({
                vehicleDropdown: true,
                dropdownShow: false,
                searchDataedit: this.state.searchVehiclemanagerData
            })
        }
        else if (this.state.buttonText.trim().toLowerCase() === "enorm") {
            this.setState({
                vehicleDropdown: true,
                dropdownShow: false,
                searchDataedit: this.state.searchEnormData
            })
        }
        else {
            this.setState({
                vehicleDropdown: true,
                dropdownShow: false,
                searchDataedit: this.state.searchData
            })
        }
    }

    filterDropdownValue = (e) => {
        let filterArray = [];
        let searchDatas = [];
        filterArray = this.checkStatus();
        var updatedList = filterArray[0];
        updatedList = updatedList.filter(function (item) {
            return item.toString().toLowerCase().search(
                e.target.value.toLowerCase()) !== -1;
        });
        if (updatedList.length == 0) {
            var updatedList = filterArray[1];
            updatedList = updatedList.filter(function (item) {
                return item.toString().toLowerCase().search(
                    e.target.value.toLowerCase()) !== -1;
            });
        }

        if (this.state.searchVehicleData !== undefined && this.state.searchVehicleData !== null) {
            if (this.state.buttonText.trim().toLowerCase() === "vehicle") {
                if (updatedList.length !== 0) {
                    for (let i = 0; i < this.state.fleetVehicles.length; i++) {

                        for (let j = 0; j < updatedList.length; j++) {
                            if (this.state.fleetVehicles[i].regn_number === updatedList[j] || this.state.fleetVehicles[i].vin_number === updatedList[j]) {

                                if (this.state.selectedVehicleDropDown.length !== 0 && this.state.selectedVehicleDropDown.includes(this.state.fleetVehicles[i].regn_number) === true) {
                                    searchDatas.unshift(<button onClick={this.optionValueSelect} key={this.state.fleetVehicles[i].obu_id} className="dropdown-item"><input className="dropdown-item-checkbox" style={{ float: "left", marginTop: "3px", marginRight: "5px" }} type="checkbox" value={this.state.fleetVehicles[i].regn_number} checked={true} defaultChecked={true} /> {this.state.fleetVehicles[i].regn_number} - {this.state.fleetVehicles[i].vin_number}</button>)
                                } else {
                                    searchDatas.push(<button onClick={this.optionValueSelect} key={this.state.fleetVehicles[i].obu_id} className="dropdown-item"><input className="dropdown-item-checkbox" style={{ float: "left", marginTop: "3px", marginRight: "5px" }} type="checkbox" value={this.state.fleetVehicles[i].regn_number} /> {this.state.fleetVehicles[i].regn_number} - {this.state.fleetVehicles[i].vin_number}</button>)
                                }
                            }
                        }

                    }
                } else {
                    searchDatas.push("No Data Available")
                }
            } else if (this.state.buttonText.trim().toLowerCase() === "model") {
                if (updatedList.length !== 0) {
                    for (let i = 0; i < this.state.searchmodeldata.length; i++) {
                        for (let j = 0; j < updatedList.length; j++) {
                            if (this.state.searchmodeldata[i] === updatedList[j]) {

                                if (this.state.selectedVehicleDropDown.length !== 0 && this.state.selectedVehicleDropDown.includes(this.state.searchmodeldata[i]) === true) {
                                    searchDatas.unshift(<button onClick={this.optionValueSelect} key={this.state.searchmodeldata[i]} className="dropdown-item"><input className="dropdown-item-checkbox" style={{ float: "left", marginTop: "3px", marginRight: "5px" }} type="checkbox" value={this.state.searchmodeldata[i]} defaultChecked /> {this.state.searchmodeldata[i]}</button>)
                                } else {
                                    searchDatas.push(<button onClick={this.optionValueSelect} key={this.state.searchmodeldata[i]} className="dropdown-item"><input className="dropdown-item-checkbox" style={{ float: "left", marginTop: "3px", marginRight: "5px" }} type="checkbox" value={this.state.searchmodeldata[i]} /> {this.state.searchmodeldata[i]}</button>)
                                }
                            }
                        }
                    }
                } else {
                    searchDatas.push("No Data Available")
                }
            } else if (this.state.buttonText.trim().toLowerCase() === "fleet manager") {
                if (updatedList.length !== 0) {
                    for (let i = 0; i < this.state.searchfmsdata.length; i++) {
                        for (let j = 0; j < updatedList.length; j++) {
                            if (this.state.searchfmsdata[i].fleet_manager_name === updatedList[j]) {

                                if (this.state.selectedVehicleDropDown.length !== 0 && this.state.selectedVehicleDropDown.includes(this.state.searchfmsdata[i].fleet_manager_name) === true) {
                                    searchDatas.unshift(<button onClick={this.optionValueSelect} key={this.state.searchfmsdata[i].fleet_manager_name} className="dropdown-item"><input className="dropdown-item-checkbox" style={{ float: "left", marginTop: "3px", marginRight: "5px" }} type="checkbox" value={this.state.searchfmsdata[i].fleet_manager_name} defaultChecked /> {this.state.searchfmsdata[i].fleet_manager_name}</button>)
                                } else {
                                    searchDatas.push(<button onClick={this.optionValueSelect} key={this.state.searchfmsdata[i].fleet_manager_name} className="dropdown-item"><input className="dropdown-item-checkbox" style={{ float: "left", marginTop: "3px", marginRight: "5px" }} type="checkbox" value={this.state.searchfmsdata[i].fleet_manager_name} /> {this.state.searchfmsdata[i].fleet_manager_name}</button>)
                                }
                            }
                        }
                    }
                } else {
                    searchDatas.push("No Data Available")
                }
            } else if (this.state.buttonText.trim().toLowerCase() === "enorm") {
                if (updatedList.length !== 0) {
                    for (let i = 0; i < this.state.searchEnormData.length; i++) {
                        let enormData = this.state.searchEnormData[i].props.children[1]
                        for (let j = 0; j < updatedList.length; j++) {
                            if (enormData === updatedList[j]) {
                                if (this.state.selectedVehicleDropDown.length !== 0 && this.state.selectedVehicleDropDown.includes(enormData) === true) {
                                    searchDatas.unshift(<button onClick={this.optionValueSelect} key={enormData} className="dropdown-item"><input className="dropdown-item-checkbox" style={{ float: "left", marginTop: "3px", marginRight: "5px" }} type="checkbox" value={enormData} defaultChecked /> {enormData}</button>)
                                } else {
                                    searchDatas.push(<button onClick={this.optionValueSelect} key={enormData} className="dropdown-item"><input className="dropdown-item-checkbox" style={{ float: "left", marginTop: "3px", marginRight: "5px" }} type="checkbox" value={enormData} /> {enormData}</button>)
                                }
                            }
                        }
                    }
                } else {
                    searchDatas.push("No Data Available")
                }
            }
            else {
                if (updatedList.length !== 0) {
                    for (let i = 0; i < this.state.fleetGroups.length; i++) {
                        for (let j = 0; j < updatedList.length; j++) {
                            if (this.state.fleetGroups[i].group_name === updatedList[j]) {

                                if (this.state.selectedVehicleDropDown.length !== 0 && this.state.selectedVehicleDropDown.includes(this.state.fleetGroups[i].fleet_group_id.toString()) === true) {
                                    searchDatas.unshift(<button onClick={this.optionValueSelect} key={this.state.fleetGroups[i].fleet_group_id} className="dropdown-item"><input className="dropdown-item-checkbox" style={{ float: "left", marginTop: "3px", marginRight: "5px" }} type="checkbox" value={this.state.fleetGroups[i].fleet_group_id} defaultChecked /> {this.state.fleetGroups[i].group_name}</button>)
                                } else {
                                    searchDatas.push(<button onClick={this.optionValueSelect} key={this.state.fleetGroups[i].fleet_group_id} className="dropdown-item"><input className="dropdown-item-checkbox" style={{ float: "left", marginTop: "3px", marginRight: "5px" }} type="checkbox" value={this.state.fleetGroups[i].fleet_group_id} /> {this.state.fleetGroups[i].group_name}</button>)
                                }
                            }
                        }
                    }
                } else {
                    searchDatas.push("No Data Available")
                }
            }
        }
        this.setState({
            vehicleDropdown: true,
            dropdownShow: false,
            searchDataedit: searchDatas
        })

    }

    passValue = async() => {
        this.props.gmapsDispatch({ loader: true, refreshselecteddata : 'Total' });
        await this.sleep(1);
        this.checkPassValue();
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms))
    }



    checkPassValue() {
        return new Promise((resolve, reject) => {
            this.setState({
                vehicleDropdown: false,
                dropdownShow: false
            })
            var groupToVehicle = [];
            var checkduplicateveh = [];
            // console.log(this.state.buttonText.trim().toLowerCase(), this.state.fleetVehicles);
            if (this.state.buttonText.trim().toLowerCase() === "group") {
                for (var l = 0; l < this.state.selectedVehicleDropDown.length; l++) {

                    for (var m = 0; m < this.state.fleetVehicles.length; m++) {
                        if (parseInt(this.state.selectedVehicleDropDown[l]) === this.state.fleetVehicles[m].group_id) {
                            if (!checkduplicateveh.includes(this.state.fleetVehicles[m].regn_number)) {
                                groupToVehicle.push({ regn_number: this.state.fleetVehicles[m].regn_number, obu_id: this.state.fleetVehicles[m].obu_id, vin_numbers: this.state.fleetVehicles[m].vin_number });
                                checkduplicateveh.push(this.state.fleetVehicles[m].regn_number)
                            }
                        }
                    }
                }
                let pushdata = { "type": this.state.buttonText.trim().toLowerCase(), "data": groupToVehicle }
                this.props.searchData(pushdata);
                this.props.searchcount(pushdata);
                this.props.searchresultprop(pushdata).then(() => {
                    resolve(true);
                });
            } else if (this.state.buttonText.trim().toLowerCase() === "fleet manager") {

                for (var l = 0; l < this.state.selectedVehicleDropDown.length; l++) {

                    for (var m = 0; m < this.state.allglobledata.length; m++) {
                        if (parseInt(this.state.selectedVehicleDropDown[l]) === this.state.allglobledata[m].fleet_manager_id) {
                            if (!checkduplicateveh.includes(this.state.allglobledata[m].regn_number)) {
                                groupToVehicle.push({ regn_number: this.state.allglobledata[m].regn_number, obu_id: this.state.allglobledata[m].obu_id, vin_numbers: this.state.allglobledata[m].vin_number });
                                checkduplicateveh.push(this.state.allglobledata[m].regn_number)

                            }
                        }
                    }
                }
                let pushdata = { "type": this.state.buttonText.trim().toLowerCase(), "data": groupToVehicle }
                this.props.searchData(pushdata);
                this.props.searchcount(pushdata);
                this.props.searchresultprop(pushdata).then(() => {
                    resolve(true);
                });
            }
            else if (this.state.buttonText.trim().toLowerCase() === "model") {
                for (var l = 0; l < this.state.selectedVehicleDropDown.length; l++) {

                    for (var m = 0; m < this.state.fleetVehicles.length; m++) {
                        let nomenclature_desc = this.props.gmapsState.dropdownValue == "myTerritory" ? this.state.fleetVehicles[m].vehicleModel : this.state.fleetVehicles[m].nomenclature_desc;
                        if (this.state.selectedVehicleDropDown[l] === nomenclature_desc) {
                            if (!checkduplicateveh.includes(this.state.fleetVehicles[m].regn_number)) {
                                groupToVehicle.push({ regn_number: this.state.fleetVehicles[m].regn_number, obu_id: this.state.fleetVehicles[m].obu_id, vin_numbers: this.state.fleetVehicles[m].vin_number });
                                checkduplicateveh.push(this.state.fleetVehicles[m].regn_number)
                            }
                        }
                    }
                }
                let pushdata = { "type": this.state.buttonText.trim().toLowerCase(), "data": groupToVehicle }
                this.props.searchData(pushdata);
                this.props.searchcount(pushdata);
                this.props.searchresultprop(pushdata).then(() => {
                    resolve(true);
                });
            }

            else if (this.state.buttonText.trim().toLowerCase() === "enorm") {
                for (var l = 0; l < this.state.selectedVehicleDropDown.length; l++) {

                    for (var m = 0; m < this.state.fleetVehicles.length; m++) {
                        let Isbs6 = this.props.gmapsState.dropdownValue == "myTerritory" ? this.state.fleetVehicles[m].isBS6 : this.state.fleetVehicles[m].is_bs6;
                        if (parseInt(this.state.selectedVehicleDropDown[l]) === Isbs6) {
                            if (!checkduplicateveh.includes(this.state.fleetVehicles[m].regn_number)) {
                                groupToVehicle.push({ regn_number: this.state.fleetVehicles[m].regn_number, obu_id: this.state.fleetVehicles[m].obu_id, vin_numbers: this.state.fleetVehicles[m].vin_number });
                                checkduplicateveh.push(this.state.fleetVehicles[m].regn_number)
                            }
                        }
                    }
                }
                let pushdata = { "type": this.state.buttonText.trim().toLowerCase(), "data": groupToVehicle }
                this.props.searchData(pushdata);
                this.props.searchcount(pushdata);
                this.props.searchresultprop(pushdata).then(() => {
                    resolve(true);
                });
            }
            //Duplicated condition
            // else if (this.state.buttonText.trim().toLowerCase() === "enorm") {
            //     for (var l = 0; l < this.state.selectedVehicleDropDown.length; l++) {

            //         for (var m = 0; m < this.state.fleetVehicles.length; m++) {
            //             let Isbs6 = this.props.gmapsState.dropdownValue == "myTerritory" ? this.state.fleetVehicles[m].isBS6 : this.state.fleetVehicles[m].is_bs6;
            //             if (parseInt(this.state.selectedVehicleDropDown[l]) === Isbs6) {
            //                 if (!checkduplicateveh.includes(this.state.fleetVehicles[m].regn_number)) {
            //                     groupToVehicle.push({ regn_number: this.state.fleetVehicles[m].regn_number, obu_id: this.state.fleetVehicles[m].obu_id, vin_numbers: this.state.fleetVehicles[m].vin_number });
            //                     checkduplicateveh.push(this.state.fleetVehicles[m].regn_number)
            //                 }
            //             }
            //         }
            //     }
            //     let pushdata = { "type": this.state.buttonText.trim().toLowerCase(), "data": groupToVehicle }
            //     this.props.searchData(pushdata);
            //     this.props.searchcount(pushdata);
            //     this.props.searchresultprop(pushdata).then(() => {
            //         resolve(true);
            //     });
            // }

            else {
                var vehicleWithObu = []
                for (var l = 0; l < this.state.selectedVehicleDropDown.length; l++) {
                    for (var m = 0; m < this.state.fleetVehicles.length; m++) {
                        if (this.state.selectedVehicleDropDown[l] === this.state.fleetVehicles[m].regn_number) {
                            // console.log('pData')
                            if (!checkduplicateveh.includes(this.state.fleetVehicles[m].regn_number)) {
                                vehicleWithObu.push({ regn_number: this.state.fleetVehicles[m].regn_number, obu_id: this.state.fleetVehicles[m].obu_id, vin_numbers: this.state.fleetVehicles[m].vin_number });
                                checkduplicateveh.push(this.state.fleetVehicles[m].regn_number)
                            }
                        }
                    }
                }
                // console.log(vehicleWithObu)
                let pushdata = { "type": this.state.buttonText.trim().toLowerCase(), "data": vehicleWithObu }
                this.props.searchData(pushdata);
                this.props.searchcount(pushdata);
                this.props.searchresultprop(pushdata).then((data) => {
                    resolve(true);
                });
            }
        });
    }

    clearvalues = () => {
        this.setState({
            searchData: null,
            searchDataedit: null,
            fleetVehicles: null,
            fleetGroups: null,
            searchVehicleData: null,
            selectedVehicleDropDown: [],
            searchText: ''
        }, async () => {
            await this.checkPassValue().then((data) => {
                this.props.resetresult();
                if (this.props.gmapsState.dropdownValue == "myTerritory") {
                    this.searhOptionInit(this.props.gmapsState.totalmarkers);
                }
            });
            this.props.gmapsDispatch({ refreshselecteddata : 'Total' });
        })

        if (this.props.gmapsState.dropdownValue != "myTerritory") {
            this.initOptions();
        }
        // else{
        //     this.searhOptionInit(this.props.gmapsState.totalmarkers)
        // }

    }

    expandSet = () => {
        this.setState({
            dropdownShow: true,
            vehicleDropdown: false
        })
    }

    myHandler = (event) => {
        let ClassName = event.target.getAttribute('class');
        if ((ClassName !== "usertexts") || (ClassName !== "btn dropdown_custom dropdown-toggle")) {
            if (ClassName !== "dropdown-item") {
                if (ClassName !== "dropdown-item-checkbox") {
                    this.setState({
                        dropdownShow: false,
                        vehicleDropdown: false,
                    })
                }
            }
            if (ClassName !== "nav-link") {
                this.setState({
                    submenulist: []
                })
            }
        }
    }

    componentWillUnmount() {
        document.body.removeEventListener('click', this.myHandler);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.sData.HOMEPAGE !== null) {
            this.goHome();
            this.props.navigateToHome(null)
        }
        if (
            this.props.ctr.userrole === "OWNER ADMIN" ||
            this.props.ctr.userrole === "FLEET MANAGER" ||
            this.props.ctr.userrole === "AL EMPLOYEE" ||
            this.props.ctr.userrole === "DEALER OUTLET ADMIN" || this.props.ctr.userrole === "AL SME" ||
            this.props.ctr.userrole === "AL_SALES_ADMIN") {
            if (
                (window.location.href.indexOf("/home") !== -1) ||
                (window.location.href.indexOf("/map") !== -1) ||
                (window.location.href.indexOf("/fleetinsightsdash") !== -1) ||
                (window.location.href.indexOf("/figearutil") !== -1) ||
                (window.location.href.indexOf("/alertssafety") !== -1) ||
                (window.location.href.indexOf("/bs6alerts") !== -1) ||
                (window.location.href.indexOf("/fuel") !== -1) ||
                (window.location.href.indexOf("/FleetKPI") !== -1) ||
                (window.location.href.indexOf("/fleetinsightsrep") !== -1) ||
                (window.location.href.indexOf("/gearutilrep") !== -1) ||
                (window.location.href.indexOf("/camportal") !== -1) ||
                (window.location.href.indexOf("/Dealermap") !== -1) ||
                (window.location.href.indexOf("/fleetalerts") !== -1)) {
                if (this.state.hideshowar === false) {
                    this.setState({
                        hideshowar: true,
                        hideshowdd: true
                    })
                }
            } else {
                if (this.state.hideshowar === true) {
                    this.setState({
                        hideshowar: false,
                        hideshowdd: false
                    })
                }
            }
        } else {
            if (this.state.hideshowar === true) {
                this.setState({
                    hideshowar: false,
                    hideshowdd: false
                })
            }
        }
        if (nextProps.sData.qcdata !== null) {
            for (var j = 0; j < this.state.menuJson.length; j++) {
                if (this.state.menuJson[j].id === parseInt(nextProps.sData.qcdata[0].mm)) {
                    for (var k = 0; k < this.state.menuJson[j].submenu.length; k++) {
                        if (this.state.menuJson[j].submenu[k].id === parseInt(nextProps.sData.qcdata[0].sm)) {
                            var thirdLevelMenu = this.state.menuJson[j].submenu[k].submenu.map((sm, i) => <li key={sm.id} className="nav-item">
                                <Link menudata={sm.menu_state && sm.menu_state.fromNotifications ? "1" : "0"} to={{ pathname: sm.url, state: { fromNotifications: sm.menu_state } }} onClick={this.selectionMenu.bind(this)} className="nav-link">{sm.screen_name}</Link>
                                <div className={i === parseInt(nextProps.sData.qcdata[0].tm) ? "selectedMenu showbar" : "selectedMenu"}></div></li>);
                            this.setState({
                                thirdLevel: thirdLevelMenu,
                                opened: false,
                                menuid: this.state.menuid
                            });
                            this.props.quicklinks(null)
                        }
                    }
                }
            }
        }

        // console.log(this.props.gmapsState.callGlobalApi, nextProps.gmapsState.callGlobalApi)
        if (this.props.ctr.userrole === "DEALER OUTLET ADMIN" && this.props.gmapsState.dropdownValue === 'myTerritory' && nextProps.gmapsState.callGlobalApi == "territory") {
            this.props.gmapsDispatch({ callGlobalApi: false }).then(() => {
                this.searhOptionInit(this.props.gmapsState.totalmarkers);
            });
        } else if (this.props.ctr.userrole === "DEALER OUTLET ADMIN" && this.props.gmapsState.dropdownValue === 'mySoldVehicles' && nextProps.gmapsState.callGlobalApi == "sold") {
            this.props.gmapsDispatch({ callGlobalApi: false }).then(() => {
                this.initOptions();
            })
        }

        return true;
    }


    searhOptionInit = (resSearchData) => {
        if (resSearchData) {
            // console.log('4', resSearchData);
            // if (resSearchData.statuscode === 200) {
            resSearchData.map((data, i) => {
                data.regn_number = data.regnNumber;
                data.obu_id = data.obuId;
                data.vin_number = data.vinNumber;
            });


            var checkduplicatevehlist = [];
            var showdropvaluevehicle = [];

            if (resSearchData !== null && resSearchData !== undefined) {
                var arr1 = [];
                var enormslist = ['BSIV', 'BSVI', 'BSVI_CNG', 'LCV', 'EV'];
                var enorms = [];
                for (var i = 0; i < resSearchData.length; i++) {
                    if (!arr1.includes(resSearchData[i].vehicleModel)) {
                        arr1.push(resSearchData[i].vehicleModel);
                    }
                    if (!enorms.includes(enormslist[resSearchData[i].isBS6])) {
                        enorms.push(enormslist[resSearchData[i].isBS6]);
                    }
                    if (!checkduplicatevehlist.includes(resSearchData[i].regn_number)) {
                        showdropvaluevehicle.push(resSearchData[i])
                        checkduplicatevehlist.push(resSearchData[i].regn_number)
                    }
                }
                // let sortedvalues = resSearchData.data.fleetGrpData.sort(function(a, b){
                //     var nameA=a.group_name.toLowerCase(), nameB=b.group_name.toLowerCase()
                //     if (nameA < nameB) //sort string ascending
                //         return -1
                //     if (nameA > nameB)
                //         return 1
                //     return 0 //default return value (no sorting)
                // })
                // let sortedvaluesmanager = resSearchData.data.fleetMrgData.sort(function(a, b){
                //     var nameA=a.fleet_manager_name.toLowerCase(), nameB=b.fleet_manager_name.toLowerCase()
                //     if (nameA < nameB) //sort string ascending
                //         return -1
                //     if (nameA > nameB)
                //         return 1
                //     return 0 //default return value (no sorting)
                // })
                let sortedvaluesmodel = arr1.sort(function (a, b) {
                    var nameA = a, nameB = b
                    if (nameA < nameB) //sort string ascending
                        return -1
                    if (nameA > nameB)
                        return 1
                    return 0 //default return value (no sorting)
                })

                let sortedvaluesenorm = arr1.sort(function (a, b) {
                    var nameA = a, nameB = b
                    if (nameA < nameB) //sort string ascending
                        return -1
                    if (nameA > nameB)
                        return 1
                    return 0 //default return value (no sorting)
                })

                let searchVehicleDatas = showdropvaluevehicle.map((fv) => <button onClick={this.optionValueSelect} key={fv.obuId} className="dropdown-item"><input className="dropdown-item-checkbox" style={{ float: "left", marginTop: "3px", marginRight: "5px" }} type="checkbox" value={fv.regn_number} /> {fv.regn_number} - {fv.vin_number}</button>)
                let searchVehiclemodelDatas = sortedvaluesmodel.map((fm) => <button onClick={this.optionValueSelect} key={fm} className="dropdown-item"><input className="dropdown-item-checkbox" style={{ float: "left", marginTop: "3px", marginRight: "5px" }} type="checkbox" value={fm} /> {fm.toUpperCase()}</button>)
                let searchEnormDatas = enorms.map((em, index) => <button onClick={this.optionValueSelect} key={em} className="dropdown-item"><input className="dropdown-item-checkbox" style={{ float: "left", marginTop: "3px", marginRight: "5px" }} type="checkbox" value={index} />{em}</button>)

                this.setState({
                    searchVehicleData: searchVehicleDatas,
                    fleetGroups: resSearchData.fleetGrpData,
                    searchmodeldata: sortedvaluesmodel,
                    fleetVehicles: showdropvaluevehicle,
                    searchDataedit: searchVehicleDatas,
                    searchVehiclemodelData: searchVehiclemodelDatas,
                    searchEnormData: searchEnormDatas
                })
            }
        }

    }
    setGlobalFilterData = (resSearchData) => {
        var checkduplicatevehlist = [];
        var showdropvaluevehicle = [];
        if (resSearchData.data.globalObject !== null && resSearchData.data.globalObject !== undefined) {

            var arr1 = [];
            for (var i = 0; i < resSearchData.data.globalObject.length; i++) {
                if (!arr1.includes(resSearchData.data.globalObject[i].nomenclature_desc)) {
                    arr1.push(resSearchData.data.globalObject[i].nomenclature_desc);
                }
            }
            // console.log(arr1);
            for (var m = 0; m < resSearchData.data.globalObject.length; m++) {
                if (!checkduplicatevehlist.includes(resSearchData.data.globalObject[m].regn_number)) {
                    showdropvaluevehicle.push(resSearchData.data.globalObject[m])
                    checkduplicatevehlist.push(resSearchData.data.globalObject[m].regn_number)
                }
            }
            let sortedvalues = resSearchData.data.fleetGrpData.sort(function (a, b) {
                var nameA = a.group_name.toLowerCase(), nameB = b.group_name.toLowerCase()
                if (nameA < nameB) //sort string ascending
                    return -1
                if (nameA > nameB)
                    return 1
                return 0 //default return value (no sorting)
            })
            let sortedvaluesmanager = resSearchData.data.fleetMrgData.sort(function (a, b) {
                var nameA = a.fleet_manager_name.toLowerCase(), nameB = b.fleet_manager_name.toLowerCase()
                if (nameA < nameB) //sort string ascending
                    return -1
                if (nameA > nameB)
                    return 1
                return 0 //default return value (no sorting)
            })
            let sortedvaluesmodel = arr1.sort(function (a, b) {
                var nameA = a.nomenclature_desc, nameB = b.nomenclature_desc
                if (nameA < nameB) //sort string ascending
                    return -1
                if (nameA > nameB)
                    return 1
                return 0 //default return value (no sorting)
            })
            let sortedvaluesenorm = resSearchData.data.enorm.sort(function (a, b) {
                var nameA = a.e_norm?.toLowerCase(), nameB = b.e_norm?.toLowerCase()
                if (nameA < nameB) //sort string ascending
                    return -1
                if (nameA > nameB)
                    return 1
                return 0 //default return value (no sorting)
            })

            let searchFleetDatas = sortedvalues.map((fg) => <button onClick={this.optionValueSelect} key={fg.fleet_group_id} className="dropdown-item"><input className="dropdown-item-checkbox" style={{ float: "left", marginTop: "3px", marginRight: "5px" }} type="checkbox" value={fg.fleet_group_id} checked={this.state.selectedVehicleDropDown.includes(fg.fleet_group_id) ? true : false} /> {fg.group_name.toUpperCase()}</button>)
            let searchVehicleDatas = showdropvaluevehicle.map((fv) => <button onClick={this.optionValueSelect} key={fv.obu_id} className="dropdown-item"><input className="dropdown-item-checkbox ajith" style={{ float: "left", marginTop: "3px", marginRight: "5px" }} type="checkbox" value={fv.regn_number} checked={this.state.selectedVehicleDropDown.includes(fv.regn_number) ? true : false} /> {fv.regn_number} - {fv.vin_number}</button>)
            let searchVehiclemodelDatas = sortedvaluesmodel.map((fm) => <button onClick={this.optionValueSelect} key={fm} className="dropdown-item"><input className="dropdown-item-checkbox" style={{ float: "left", marginTop: "3px", marginRight: "5px" }} type="checkbox" value={fm} /> {fm.toUpperCase()}</button>)
            let searchVehiclemanagerDatas = sortedvaluesmanager.map((ffm) => <button onClick={this.optionValueSelect} key={ffm.fleet_manager_name} className="dropdown-item"><input className="dropdown-item-checkbox" style={{ float: "left", marginTop: "3px", marginRight: "5px" }} type="checkbox" value={ffm.fleet_manager_id} /> {ffm.fleet_manager_name.toUpperCase()}</button>)
            let searchEnormDatas = sortedvaluesenorm.map((em) => <button onClick={this.optionValueSelect} key={em.is_bs6} className="dropdown-item"><input className="dropdown-item-checkbox" style={{ float: "left", marginTop: "3px", marginRight: "5px" }} type="checkbox" value={em.is_bs6} />{em.e_norm}</button>)
            this.setState({
                searchData: searchFleetDatas,
                searchVehicleData: searchVehicleDatas,
                fleetGroups: resSearchData.data.fleetGrpData,
                searchmodeldata: sortedvaluesmodel,
                searchfmsdata: sortedvaluesmanager,
                fleetVehicles: showdropvaluevehicle,
                searchDataedit: searchVehicleDatas,
                searchVehiclemodelData: searchVehiclemodelDatas,
                searchVehiclemanagerData: searchVehiclemanagerDatas,
                searchEnormData: searchEnormDatas,
                allglobledata: resSearchData.data.globalObject,
                globalData: resSearchData.data
            })

            let userDet = {};
            userDet.userInfo = this.props.ctr;
            userDet.userInfo.modelList = sortedvaluesmodel;
            this.props.userData({ ...userDet.userInfo });
        }
    }
    initOptions = () => {
        var apitokens = this.props.ctr.token;
        var pData = { "platform": "w", "userId": this.props.userId };
        if (this.props.ctr.loginid !== "CVG") {
            if (!apitokens) {
                apitokens = billingToken;
            }
            
            // Fetch global filter data from API
            var searchData = postData(apiList.globalFilter, pData, apitokens);
            searchData.then(resSearchData => {
                if (resSearchData?.statuscode === 200) {
                    // Update Redux store with fetched data
                    this.props.setGlobalFilterData(resSearchData);
                    // update ev norm vehicle exist or not into redux
                    let evData = resSearchData?.data?.enorm?.filter(element => element?.is_bs6 === 4)
                    console.log("evData ", evData && evData.length > 0)
                    this.props.evEnorm(evData && evData.length > 0);
                    this.setGlobalFilterData(resSearchData);
                }
            });
        }
    }
    renderMenu = (menuData) => {
        return menuData.map((menu, i) => (
            <>
            <li data={menu.id} key={menu.id} className="nav-item">
                <Link to={menu.url} onClick={this.closeMenu} onMouseOver={this.changemenu.bind(this, i)} className="nav-link">{menu.screen_name}</Link>
                <div className="selectedMenu"></div>
            </li>
        </>
        ));
    };
    getMenus = async (mdata, apitokens) =>  {
        try {
            if (this.props.menuAPIData && this.props.menuAPIData.length > 0) {
                this.setState({
                    menudata: this.renderMenu(this.props.menuAPIData),
                    menuJson: this.props.menuAPIData
                })
            } else {
                var menuData = postData(apiList.getMenu, mdata, apitokens);
                menuData.then(resMenuData => {
                    if (resMenuData?.statuscode === 200) {
                        this.setState({
                            menudata: this.renderMenu(resMenuData.data),
                            menuJson: resMenuData.data
                        })
                    }
                });
            }
        } catch (error) {
            console.error('Error fetching menu data:', error);
        }
    }

    componentDidMount() {
        if(this.props.ctr.loginid !== "CVG"){
            this.initOptions()
            this.setsubscritionsdetails()
        }

        if (window.location.href.indexOf("/success") !== -1) {
            this.setState({ successmenu: true })
        } else {
            this.setState({ successmenu: false })
        }
        this.props.refresh(this.state.autoreload);
        this.props.onlyrefresh(this.state.onlyRefresh);
        document.body.addEventListener('click', this.myHandler);

        var apitokens = this.props.ctr.token;
        var pData = { "platform": "w", "userId": this.props.userId };
        var mdata = { "platform": "w", "user_id": this.props.userId };
        if (!apitokens) {
            apitokens = billingToken;
        }
        if (this.props.ctr.loginid !== "CVG") {
            var profileData = postData(apiList.userProfileData, pData, apitokens);
            profileData.then(resProfile => {
                if (resProfile?.statuscode === 200) {
                    this.props.profilePhoto(resProfile.data.photo);

                    let userDet = {};
                    userDet.userInfo = this.props.ctr;

                    userDet.userInfo.phone_update = resProfile.data.phone_update;     // Do not force user to update user profile
                    userDet.userInfo.mobile = resProfile.data.mobile;
                    userDet.userInfo.renewal_popup = resProfile.data.renewal_popup;
                    userDet.userInfo.birthday_popup = false;
                    userDet.userInfo.release_notes = false;
                    userDet.userInfo.globalFilter = false;
                    userDet.userInfo.states = resProfile.data.states;
                    userDet.userInfo.districts = resProfile.data.districts;
                    userDet.userInfo.fuel_report = resProfile.data.is_fuel_report_allow;
                    userDet.userInfo.gst_proof = resProfile.data.gst_proof;
                    userDet.userInfo.pan_proof = resProfile.data.pan_proof;
                    userDet.userInfo.gst_in = resProfile.data.gst_in;
                    userDet.userInfo.pan_no = resProfile.data.pan_no;

                    var c_dt = new Date();
                    var c_date = c_dt.getDate();
                    var c_month = c_dt.getMonth() + 1;

                    let temp_last_visited = this.props.ctr.lastlogin;
                    let lastVisited;
                    if (temp_last_visited) {
                        lastVisited = new Date((temp_last_visited - 19800) * 1000);
                    } else {
                        lastVisited = null;
                    }

                    if (resProfile.data.date_of_birth !== null && !this.props.ctr.unipass) {
                        var birthday_date = new Date(resProfile.data.date_of_birth);
                        var b_date = birthday_date.getDate()
                        var b_month = birthday_date.getMonth() + 1;

                        if (((c_date === b_date) && (c_month === b_month)) && (c_dt.toDateString() !== lastVisited.toDateString())) {
                            userDet.userInfo.birthday_popup = true;
                        }
                    }
                    // if(!this.props.ctr.unipass){
                    //     userDet.userInfo.release_notes = true;
                    // }
                    this.props.userData({ ...userDet.userInfo });
                    this.setState({ defaultHeaderShow: false });
                }
            });
        }
        this.getMenus(mdata, apitokens)
    }

    componentDidUpdate() {
        if(this.props.ctr.globalFilter){
            let userDet = {};
            userDet.userInfo = this.props.ctr;
            userDet.userInfo.globalFilter = false;
            this.props.userData({ ...userDet.userInfo });
            this.initOptions();
        }
    }

    setsubscritionsdetails = () => {
        var expiredlist = [];
        var expired15list = [];
        var expired30list = [];
        let packagedata = {};
        let expiryFifteenToMinSevanFive = []
        packagedata.userId = this.props.ctr.userid;
        packagedata.packageId = 1;
        let tokens = this.props.ctr.token;
        if (!tokens) {
            tokens = localStorage.getItem('billingtoken');
        }
        const subsciptionapi = postData(
            apiList.billingmodule + "/vehicleVinInfo",
            packagedata,
            tokens
        );
        subsciptionapi.then((response) => {
            if (response.statuscode !== 401) {
                // console.log(response.data);
                if (response.data.length > 0) {
                    this.props.gmapsDispatch({ tochecksubdata: true });
                }
                var c_dt = new Date();
                var currentDate = c_dt.getDate() + "-" + (c_dt.getMonth() + 1) + "-" + c_dt.getFullYear();
                
                let temp_last_visited = this.props.ctr.lastlogin;
                
                let lastVisited;
                if (temp_last_visited) {
                    lastVisited = new Date((temp_last_visited - 19800) * 1000);
                } else {
                    lastVisited = null;
                }
                var b_dt = new Date(lastVisited);
                var lastDate = b_dt.getDate() + "-" + (b_dt.getMonth() + 1) + "-" + b_dt.getFullYear();

                let chekDateandMonth = currentDate !== lastDate

                for (var i = 0; i < response.data.length; i++) {
                    if(response.data[i].days_for_expiry <= 15 && response.data[i].days_for_expiry >= -75){
                        if(this.props.ctr.userroleid == 6 || this.props.ctr.userroleid == 8){
                            if(!this.props.ctr.unipass && chekDateandMonth){
                                expiryFifteenToMinSevanFive.push(response.data[i]);
                            }
                        }
                    }
                    if (
                        response.data[i].days_for_expiry >= 16 &&
                        response.data[i].days_for_expiry <= 30
                    ) {
                        expired30list.push(response.data[i]);
                    } else if (
                        response.data[i].days_for_expiry > 0 &&
                        response.data[i].days_for_expiry <= 15
                    ) {
                        expired15list.push(response.data[i]);
                    } else if (response.data[i].days_for_expiry <= 0) {
                        expiredlist.push(response.data[i]);
                    }
                }
                this.props.gmapsDispatch({ expiredveh: expiredlist.length });
                this.props.gmapsDispatch({ expired15veh: expired15list.length });
                this.props.gmapsDispatch({ expired30veh: expired30list.length });
                let message = '';
                if (expiryFifteenToMinSevanFive.length > 0) {
                    message = expiryFifteenToMinSevanFive.length === 1
                        ? `Dear Customer, \n iALERT subscription for ${expiryFifteenToMinSevanFive.length} vehicle expires / expired on ${expiryFifteenToMinSevanFive[0].vin}. Use SMART PAY for renewal.\nContact Helpline: 0806-953-5555 for any support.`
                        : `Dear Customer, \n iALERT subscription for ${expiryFifteenToMinSevanFive.length} vehicles is going to expire / expired. Use SMART PAY for renewal.\nContact Helpline: 0806-953-5555 for any support.`;
                }
                

                if (message) {
                    this.openExpiryModel(message);
                }
            } else {
                if (this.props.history) {
                    this.props.history.push("/");
                }
            }
        });
    };

    onlyRefresh = () => {
        var stateRefresh = this.state.onlyRefresh;
        if (stateRefresh === false) {
            stateRefresh = true;
            this.setState({
                onlyRefresh: stateRefresh
            });
            this.props.onlyrefresh(stateRefresh);
        } else {
            stateRefresh = false;
            this.setState({
                onlyRefresh: stateRefresh
            });
            this.props.onlyrefresh(stateRefresh);
        }
    }

    handleChange = () => {
        var checkSwitch = this.state.autoreload
        if (checkSwitch === false) {
            checkSwitch = true
            this.setState({
                autoreload: true
            })
            this.props.refresh(checkSwitch);
        } else {
            checkSwitch = false
            this.setState({
                autoreload: false
            })
            this.props.refresh(checkSwitch);
        }

    }
    handleNotifyChange = (e) => {
        this.setState({
            showNotify: (this.state.showNotify ? false : true),
            thirdLevel: '',
        })
        this.props.refresh(this.state.showNotify);
    }

    _hideModal = (e) => {

        if (e.target.textContent === 'Yes') {
            let vehtoken = this.props.ctr.token;
            if (!vehtoken) {
                vehtoken = billingToken;
            }
            let dataObj = {};
            const datas = postData(apiList.userLogout, dataObj, vehtoken);
            datas.then((dataVals) => {
                // console.log('Succes', dataVals);
            });
            window.location.reload();
        }

        this.setState({
            showAlertBox: false,
            opened: false
        });
    }


    render() {
        const { opened, thirdLevel, submenulist, showAlertBox, showMessage, messageType, showExpiryAlertBox, showExpiryMessage } = this.state;

        const { phone_update, unReadMessageCount } = this.props.ctr;

        var submenushow = false;
        var thirdmenu = false;
        if (submenulist.length === 0 && thirdLevel.length === 0) {
            submenushow = false;
            thirdmenu = false;

        } else if (thirdLevel.length !== 0) {
            submenushow = false;
            thirdmenu = true;
        } else {
            submenushow = true;
        }

        var content;
        var submenuItems;
        if (opened) {
            content =
                <div className="col bannerspace">
                    <div className="row ">
                        <nav className="navbar navbar-expand-sm">
                            <ul id="mainMenu" className="navbar-nav">
                                {this.state.menudata}
                            </ul>
                        </nav>
                        <div className="col-md-1 navButton">
                            <div className="ham float-right">
                                <img onClick={this.toggleBox} src="/images/Dashboadimgs/close.png" alt="iAlert" title="Menu" />
                            </div>
                        </div>
                    </div>
                    <LogoutBox show={showAlertBox} showMessage={showMessage}
                        click={this._hideModal} messageType={messageType} redirect={'login'}>
                    </LogoutBox>
                </div>
            if (submenushow === true) {
                submenuItems = <div className="col submenuspace">
                    <div className="container-wrapper">
                        <div className="row ">
                            <nav className="navbar navbar-expand-sm">
                                <ul id="subMenu" className="navbar-nav">
                                    {this.state.submenulist}
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            }

        } else if (thirdmenu === true) {
            content = content = <div className="col bannerspace">
                <div className="row ">
                    <div className="col-md-2">
                        <div className="logo">
                            <Link to={this.props.ctr.userrole === 'DEALER_SALES' ? '/dealersales' : this.props.ctr.userrole === "AL ADMIN" ? '/alapproval' : this.props.ctr.userrole === "AL SUPER ADMIN" ? '/enquires' : this.props.ctr.userrole === "FINANCE_ADMIN" ? '/Approval' :this.props.ctr.loginid === apiList.PSB_USER_LOGINID ? "/PSBAlert" :this.props.ctr.loginid === apiList.PSB_USER_LOGINID ? "/PSBAlert" : "/home"} onClick={this.goHome}><img src="/images/login/ialertlogo.png" alt="iAlert" title="HOME" />
                                {/* <span className="homelog_ch">Elite</span> */}
                                <i className="fa fa-home homeicons" aria-hidden="true" title="HOME"></i>
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <nav className="navbar navbar-expand-sm">
                            <ul id="thirdMenu" className="navbar-nav">
                                {this.state.thirdLevel}
                            </ul>
                        </nav>
                    </div>
                    <div className="col-md-3">
                        {
                            this.state.hideshowdd ?
                                <div className={!this.state.searchShow ? "input-group mt-3 dh_input_group searchShow" : "input-group mt-3 dh_input_group"} style={this.state.selectedVehicleDropDown.length !== 0 ? { width: "95%" } : { width: "95%" }}>
                                    <div className='input-group-prepend'>
                                        <button className="btn dropdown_custom dropdown-toggle" type="button" onClick={this.expandSet}>{this.state.buttonText}</button>
                                        <div className={!this.state.dropdownShow ? "dropdown-menu dropdown-menu-menu" : "dropdown-menu dropdown-menu-menu showDropdown"}>
                                            <a onClick={this.optionValueChange} className="dropdown-item">Vehicle </a>
                                            {this.props.ctr.userrole !== "DEALER OUTLET ADMIN" ? <a onClick={this.optionValueChange} className="dropdown-item">Group</a> : ''}
                                            <a onClick={this.optionValueChange} className="dropdown-item">Model</a>
                                            {this.props.ctr.userrole !== "DEALER OUTLET ADMIN" && this.props.ctr.userrole !== "FLEET MANAGER" && this.props.ctr.userrole !== "AL EMPLOYEE" && this.props.ctr.userrole !== "AL SME" ? <a onClick={this.optionValueChange} className="dropdown-item">Fleet Manager</a> : ''}
                                            <a onClick={this.optionValueChange} className="dropdown-item">ENorm</a>
                                        </div>
                                    </div>
                                    <input type="text" value={this.state.searchText} className="form-control" placeholder={"(" + this.state.selectedVehicleDropDown.length + ") Selected"} onClick={this.filterDropdownValueShow} onKeyUp={this.filterDropdownValue} onChange={e => this.setState({ searchText: e.target.value })} />{this.state.selectedVehicleDropDown.length !== 0 ? <div className="Cls_btns"><i className="fa fa-close" onClick={this.clearvalues}></i></div> : ''}
                                    {/* {this.state.selectedVehicleDropDown.length !== 0 ? <button onClick={this.passValue} className="searchBtn"> <span className="fa fa-search"></span></button> : ''} */}
                                    <button onClick={this.state.selectedVehicleDropDown.length !== 0 ? this.passValue : this.clearvalues} className="searchBtn"> <span className="fa fa-search"></span></button>
                                    <div className={!this.state.vehicleDropdown ? "dropdown-menu dropdown-menu-menu" : "dropdown-menu dropdown-menu-menu showfilterDropdown"}>
                                        {this.state.searchDataedit}
                                    </div>
                                </div> : ''
                        }

                    </div>
                    <div className="col-md-2 ar toggle-align">
                        {
                            this.state.hideshowar ? <span>
                                {this.props.ctr.userrole !== "DEALER OUTLET ADMIN" ?
                                    (window.location.pathname !== "/alertssafety" && window.location.pathname !== "/bs6alerts" && window.location.pathname !== "/fuel" ?
                                        <div className="toggle-ctrl" title="Auto Refresh">
                                            <div className="toggle-swt">OFF</div><div className={this.state.autoreload ? "myswitch activated" : "myswitch"} onClick={this.handleChange}>
                                                <div className="button"></div>
                                            </div>
                                            <div className="toggle-swt">ON</div>
                                        </div>
                                        :
                                        null
                                    ) : ''
                                }
                                {window.location.pathname !== "/alertssafety" && window.location.pathname !== "/bs6alerts" && window.location.pathname !== "/fuel" ?
                                    <i onClick={this.onlyRefresh} className="fa fa-refresh refresh-tog"></i> : ''
                                }
                            </span> : ''
                        }
                    </div>
                    <NavLink link={this.props.ctr.loginid === apiList.PSB_USER_LOGINID ? "/PSBAlert" : "/notification"}>
                        <div className={this.state.showNotify ? "notification_activated" : "notification_deactivated"} onClick={this.handleNotifyChange}>
                            <i className="fa fa-bell" aria-hidden="true"></i>
                            {unReadMessageCount > 0 ? <span className="notification__badge">{unReadMessageCount}</span> : ''}
                        </div>
                    </NavLink>
                    <div className="col-md-1 navButton">
                        <div className="ham float-right">
                            <img onClick={this.toggleBox} src="/images/Dashboadimgs/Ham.png" alt="iAlert" title="Menu" />
                        </div>
                    </div>
                </div>
            </div>
        } else if (this.state.successmenu === true) {
            content = '';
        }
        else {
            content = <div className="col bannerspace">
                <div className="row ">
                    <div className="col-md-2">
                        <div className="logo">
                            <Link to={this.props.ctr.userrole === 'DEALER_SALES' ? '/dealersales' : this.props.ctr.userrole === "AL ADMIN" ? '/alapproval' : this.props.ctr.userrole === "AL SUPER ADMIN" ? '/enquires' : this.props.ctr.userrole === "FINANCE_ADMIN" ? '/Approval' :this.props.ctr.loginid === apiList.PSB_USER_LOGINID? "/PSBAlert" : "/home"} onClick={this.goHome}><img src="/images/login/ialertlogo.png" alt="iAlert" title="HOME" />
                                {/* <span className="homelog_ch">Elite</span> */}
                                <i className="fa fa-home homeicons" aria-hidden="true" title="HOME"></i>
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <img className="menuPofileimg" src={this.props.Photo ? "data:image/png;base64," + this.props.Photo : '/images/Dashboadimgs/user_photo.png'} alt="user profile" />
                        <div className="usertexts">WELCOME <br /><span className="customername">{this.props.ctr.firstname}</span></div>
                    </div>
                    <div className="col-md-3 groupAndvehicledd">
                        {
                            this.state.hideshowdd ? <div className={!this.state.searchShow ? "input-group mt-3 dh_input_group searchShow" : "input-group mt-3 dh_input_group"} title="Global Search" style={this.state.selectedVehicleDropDown.length !== 0 ? { width: "95%" } : { width: "95%" }}>
                                <div className='input-group-prepend'>
                                    <button className="btn dropdown_custom dropdown-toggle" type="button" onClick={this.expandSet}>{this.state.buttonText}</button>
                                    <div className={!this.state.dropdownShow ? "dropdown-menu dropdown-menu-menu" : "dropdown-menu dropdown-menu-menu showDropdown"}>
                                        <a onClick={this.optionValueChange} className="dropdown-item">Vehicle </a>
                                        {this.props.ctr.userrole !== "DEALER OUTLET ADMIN" ? <a onClick={this.optionValueChange} className="dropdown-item">Group</a> : ''}
                                        <a onClick={this.optionValueChange} className="dropdown-item">Model</a>
                                        {this.props.ctr.userrole !== "DEALER OUTLET ADMIN" && this.props.ctr.userrole !== "FLEET MANAGER" && this.props.ctr.userrole !== "AL EMPLOYEE" && this.props.ctr.userrole !== "AL SME" ? <a onClick={this.optionValueChange} className="dropdown-item">Fleet Manager</a> : ''}
                                        <a onClick={this.optionValueChange} className="dropdown-item">ENorm</a>                                    </div>
                                </div>
                                <input type="text" value={this.state.searchText} className="form-control" placeholder={"(" + this.state.selectedVehicleDropDown.length + ") Selected"} onClick={this.filterDropdownValueShow} onKeyUp={this.filterDropdownValue} onChange={e => this.setState({ searchText: e.target.value })} />{this.state.selectedVehicleDropDown.length !== 0 ? <div className="Cls_btns"><i className="fa fa-close" onClick={this.clearvalues}></i></div> : ''}
                                <button onClick={this.state.selectedVehicleDropDown.length !== 0 ? this.passValue : this.clearvalues} className="searchBtn"> <span className="fa fa-search"></span></button>
                                <div className={!this.state.vehicleDropdown ? "dropdown-menu dropdown-menu-menu" : "dropdown-menu dropdown-menu-menu showfilterDropdown"}>
                                    {this.state.searchDataedit}
                                </div>
                            </div> : ''
                        }
                    </div>
                    <div className="col-md-2 ar toggle-align">
                        {
                            this.state.hideshowar? <span>
                                {this.props.ctr.userrole !== "DEALER OUTLET ADMIN" ?
                                <div className="toggle-ctrl" title="Auto Refresh">
                                    <div className="toggle-swt">OFF</div><div className={this.state.autoreload ? "myswitch activated" : "myswitch"} onClick={this.handleChange}>
                                        <div className="button"></div>
                                    </div>
                                    <div className="toggle-swt">ON</div>
                                </div>
                                :null}
                                <i onClick={this.onlyRefresh} className="fa fa-refresh refresh-tog"></i>
                            </span> : ''
                        }
                    </div>
                    <div >
                        <NavLink link={this.props.ctr.userid === apiList.PSB_USER_LOGINID ? "/PSBAlert" : "/notification"}>
                            <div className={this.state.showNotify ? "notification_activated" : "notification_deactivated"} onClick={this.handleNotifyChange}>
                                <i className="fa fa-bell" aria-hidden="true"></i>
                                {unReadMessageCount > 0 ? <span className="notification__badge">{unReadMessageCount}</span> : ''}
                            </div>
                        </NavLink>
                    </div>
                    <div className="col-md-1">
                        <div className="ham float-right">
                            <img onClick={this.toggleBox} src="/images/Dashboadimgs/Ham.png" alt="iAlert" title="Menu" />
                        </div>

                    </div>
                </div>
            </div>
        }
        // const isShowMenu = phone_update === 0 || this.props.ctr.loginid === apiList.PSB_USER_LOGINID|| (this.props.ctr.userrole === "FINANCE_ADMIN" || this.props.ctr.userrole === "AL_SME")
        return (
            <div className="AppNav">
                <div>
                    {content}
                    {submenuItems}
                </div>
                {/* <ReleaseNotes/> */}
                <ExpiryAlertBox show={showExpiryAlertBox} showMessage={showExpiryMessage}
                    click={this.expiryHideModal}></ExpiryAlertBox>
            </div>
        )
    }

}
const mapStateToprops = state => {

    return {
        ctr: state.userdata.id,
        userId: state.userdata.id.userid,
        Photo: state.profileimg.photo,
        sData: state.menus,
        gmapsState: state.GmapsReducer,
        globalFilterData: state.menus.globalFilterData,
        menuAPIData: state.menus.menuAPIData
    };
}
const mapdispatchToprops = dispatch => {
    return {
        menuData: (data) => dispatch({ type: 'MENU', value: data }),
        profilePhoto: (data) => dispatch({ type: 'PROFILE', value: data }),
        searchData: (data) => dispatch({ type: 'SEARCH', value: data }),
        quicklinks: (data) => dispatch({ type: 'QUICKLINK', value: data }),
        refresh: (data) => dispatch({ type: 'REFRESH', value: data }),
        evEnorm: (data) => dispatch({type: ACTION_EV_ENORM, value: data}),
        setGlobalFilterData: (data) => dispatch({ type: GLOBAL_FILTER_DATA, value: data }),
        onlyrefresh: (data) => dispatch({ type: 'ONLYREFRESH', value: data }),
        menulist: (data) => dispatch({ type: 'MENULIST', value: data }),
        navigateToHome: (data) => dispatch({ type: 'HOMEPAGE', value: data }),
        gmapsDispatch: (payload) => dispatch(GmapsDispatchThunk(payload)),
        userData: (data) => dispatch({ type: 'INCREMENT', value: data }),
    };
}
export default connect(mapStateToprops, mapdispatchToprops)(Header);
