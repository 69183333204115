import React from 'react'
import { convertohhmmss } from '../../../../Util/commonConverter';

const Report = ({props, question, data, vinData, startDate, endDate}) => {
  
  const renderPerformanceTable = () => (
    <table className='table table-bordered table-insights'>
      <tbody>
        <tr>
          <td>Distance (km)</td>
          <td>{data.distance !== undefined ? data.distance.toFixed(2) : '-'}</td>
        </tr>
        <tr>
          <td>Fuel / Gas consumption (ltr / kg)</td>
          <td>{data.fuel !== undefined ? data.fuel.toFixed(2) : '-'}</td>
        </tr>
        <tr>
          <td>DEF Consumption (%)</td>
          <td>
            {data.def !== undefined
              ? data.enorm !== 'BSVI CNG'
                ? ((data.def / data.fuel) * 100) >= 2 && ((data.def / data.fuel) * 100) <= 8
                  ? ((data.def / data.fuel) * 100).toFixed(2)
                  : 'No Data'
                : 'NA'
              : '-'}
          </td>
        </tr>
        <tr>
          <td>KMPL</td>
          <td>{data.kmpl !== undefined ? Math.round(data.kmpl) : '-'}</td>
        </tr>
        <tr>
          <td>Driving Economy Score (out of 10)</td>
          <td>{(data.e_score !== undefined && data.e_score !== -99) ? data.e_score : '-'}</td>
        </tr>
      </tbody>
    </table>
  );

  const renderProductivityTable = () => (
    <table className='table table-bordered'>
      <tbody>
        <tr>
          <td>Running Time (hh:mm:ss)</td>
          <td>{data.running !== undefined ? convertohhmmss(data.running) : '-'}</td>
        </tr>
        <tr>
          <td>Idling Time (hh:mm:ss)</td>
          <td>{data.idling !== undefined ? convertohhmmss(data.idling) : '-'}</td>
        </tr>
        <tr>
          <td>Halt Time (hh:mm:ss)</td>
          <td>{data.halt !== undefined ? convertohhmmss(data.halt) : '-'}</td>
        </tr>
        <tr>
          <td>Utilization (%)</td>
          <td>{data.utilization !== undefined ? data.utilization : '-'}</td>
        </tr>
      </tbody>
    </table>
  );

  const renderSafetyTable = () => (
    <table className='table table-bordered'>
      <tbody>
        <tr>
          <td>Harsh Acceleration Count</td>
          <td>{data.harse_acc !== undefined ? data.harse_acc : '-'}</td>
        </tr>
        <tr>
          <td>Harsh Braking Count</td>
          <td>{data.harse_break !== undefined ? data.harse_break : '-'}</td>
        </tr>
        <tr>
          <td>Over Speed Count</td>
          <td>{data.over_speed !== undefined ? data.over_speed : '-'}</td>
        </tr>
      </tbody>
    </table>
  );


  const today = new Date
  const yesterday = new Date; yesterday.setDate(today.getDate() - 1)

  return (
    <div>
      {(vinData.overallAvgData !== undefined && vinData.overallAvgData.length > 0 ) || (vinData.length > 0 && vinData[1].safety.length > 0) ?
      <>
        {question === 'insights_performance' && renderPerformanceTable()}
        {question === 'insights_productivity' && renderProductivityTable()}
        {question === 'insights_safety' && renderSafetyTable()}
      </>: 
        (!props.state.loader) && <p className="font-weight-bold">No data..</p>
      }
      
    </div>
  )
}

export default Report