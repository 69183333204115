import React from "react";
import { Link } from "react-router-dom";

const LoaderImage = ({isDealer}) => (
  <img
    className={isDealer ? 'dealer-alert-loadergif' : 'alert-loadergif'}
    src="images/fleetAlertLoader.gif"
    alt="iAlert"
  />
);

const AlertBox = ({
  alertCount,
  userrole,
  alertType,
  imageSrc,
  fleetAlerts,
  loader,
}) => {
  const showCount =
    alertCount &&
    alertCount !== 0 &&
    (userrole !== "AL_SALES_ADMIN" || alertType !== "TellTale");
  return (
    <div className={`alert-img-width ${alertType.toLowerCase()}-alert`}>
      {loader && <LoaderImage isDealer={userrole === 'DEALER OUTLET ADMIN'} />}
      <Link
        to={{
          pathname: "/fleetalerts",
          component: fleetAlerts,
          state: { alertActive: alertType },
        }}
      >
        <img
          className="quck-img-alert"
          src={`images/Dashboadimgs/${imageSrc}.png`}
          alt="iAlert"
        />
        {showCount && (
          <img
            className="quck-img-bg"
            src="images/Dashboadimgs/Blue-bg.png"
            alt="iAlert"
          />
        )}
        {alertCount && alertCount > 0 ? (
          <div className="alert-cnt-txt">
            {alertCount === 0 ? "" : alertCount}
          </div>
        ) : (
          ""
        )}
      </Link>
    </div>
  );
};

const FleetAlertBox = ({ userrole, vehalertCount, fleetAlerts }) => {
  return (
    <div className="alert-boxs-dash col-xl-6 col-lg-9 col-md-14 col-sm-12 col-12">
      <div className="guick-link-alert-left">
        <div className="row">
          <div className="col-md-12 fleet-alerts-text">
            <div className="fleet-stat fleet-get-sup">Fleet Alerts</div>
          </div>
          <div className={userrole === 'DEALER OUTLET ADMIN'? 'row alert-icon-dealer' :'row alert-icon'}>
            <AlertBox
              loader={!vehalertCount ? true : false}
              userrole={userrole}
              alertCount={
                vehalertCount?.Critical > 0 ? vehalertCount?.Critical : ""
              }
              alertType="Critical"
              imageSrc="Critical"
              fleetAlerts={fleetAlerts}
            />
            <span className="alert-text-icon">Critical</span>
            <AlertBox
              loader={!vehalertCount ? true : false}
              userrole={userrole}
              alertCount={
                vehalertCount?.TellTale > 0 ? vehalertCount?.TellTale : ""
              }
              alertType="Telltale"
              imageSrc="telltale"
              fleetAlerts={fleetAlerts}
            />
            <span className="alert-text-icon">Telltale</span>
            <AlertBox
              loader={!vehalertCount ? true : false}
              userrole={userrole}
              alertCount={
                vehalertCount?.Warning > 0 ? vehalertCount?.Warning : ""
              }
              alertType="Warning"
              imageSrc="Warning"
              fleetAlerts={fleetAlerts}
            />
            <span className="alert-text-icon">Warning</span>
            {userrole !== "DEALER OUTLET ADMIN" && (
              <>
                <AlertBox
                  loader={!vehalertCount ? true : false}
                  userrole={userrole}
                  alertCount={
                    vehalertCount?.Fuel > 0 ? vehalertCount?.Fuel : ""
                  }
                  alertType="Fuel"
                  imageSrc="Fuel"
                  fleetAlerts={fleetAlerts}
                />
                <span className="alert-text-icon">Fuel</span>
              </>
            )}
            <AlertBox
              loader={!vehalertCount ? true : false}
              userrole={userrole}
              alertCount={
                vehalertCount?.Service > 0 ? vehalertCount?.Service : ""
              }
              alertType="Service"
              imageSrc="service"
              fleetAlerts={fleetAlerts}
            />
            <span className="alert-text-icon">Service</span>
            <AlertBox
              loader={!vehalertCount ? true : false}
              userrole={userrole}
              alertCount={
                vehalertCount?.Geofence > 0 ? vehalertCount?.Geofence : ""
              }
              alertType="Geofence"
              imageSrc="Geofence"
              fleetAlerts={fleetAlerts}
            />
            <span className="alert-text-icon">Geofence</span>
            <AlertBox
              loader={!vehalertCount ? true : false}
              userrole={userrole}
              alertCount={
                vehalertCount?.Events > 0 ? vehalertCount?.Events : ""
              }
              alertType="Events"
              imageSrc="Fleet"
              fleetAlerts={fleetAlerts}
            />
            <span className="alert-text-icon">Events</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FleetAlertBox;
