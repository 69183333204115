import firebase from 'firebase';
import 'firebase/messaging';
import { FIREBASE_API_KEY, REACT_APP_AUTH_DOMAIN, REACT_APP_DATABASE_URL, 
  REACT_APP_PROJECT_ID, REACT_APP_STORAGE_BUCKET, REACT_APP_MESSAGING_SENDER_ID, REACT_APP_APP_ID } from './apiList';

var firebaseConfig = {
    apiKey: FIREBASE_API_KEY,
    authDomain: REACT_APP_AUTH_DOMAIN,
    databaseURL: REACT_APP_DATABASE_URL,
    projectId: REACT_APP_PROJECT_ID,
    storageBucket: REACT_APP_STORAGE_BUCKET,
    messagingSenderId: REACT_APP_MESSAGING_SENDER_ID,
    appId: REACT_APP_APP_ID
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  // firebase.analytics();

  var messaging;

  if (firebase.messaging.isSupported()) {
    messaging = firebase.messaging();
  }
  else{
    console.log("Firebase is not supported");
  }


export const getToken = async () => {
  if (!messaging) {
    return null;
  }

  try {
    const currentToken = await messaging.getToken({ vapidKey: 'BJaiV6pqSso56ZhpegiEnTbQnxIAQ7ZbZbUfSbkFTZOwxDRuxx3fsqtzJNWbumK3C3tl7FVIKZWp0J1rPdFyIR8' });
    if (currentToken) {
      // console.log('current token for client: ', currentToken);
      localStorage.setItem('pushToken', currentToken);
      // setTokenFound(true);
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      console.log('No registration token available. Request permission to generate one.');
      localStorage.setItem('pushToken', '');
      // setTokenFound(false);
      // shows on the UI that permission is required
    }
  } catch (err) {
    console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    if (messaging) {
      messaging.onMessage(resolve);
    } else {
      resolve(false);
    }
  });

// export const onBackgroundListener = () =>{
//   new Promise((resolve) => {
//   messaging.onBackgroundMessage(function(payload) {
//   console.log('Received background message ', payload);

//   const notificationTitle = payload.notification.title;
//   const notificationOptions = {
//     body: payload.notification.body,
//     tag: "notification-1"
//   };

//   self.registration.showNotification(notificationTitle,
//     notificationOptions);
// });
// resolve(payload);
// });
// }