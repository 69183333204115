import React, { useState } from 'react';
import './ShowToast.scss';
import Toast from 'react-bootstrap/Toast';

function ShowToast(props) {
  const [open, setOpen] = useState(props.open);

  const handleClose = () => {
    setOpen(false);
    props.close();
  };

  return (
    <div className="notification-container bottom-left">
      <Toast onClose={handleClose} show={open}>
        <Toast.Header>
          <img
            src="holder.js/20x20?text=%20"
            className="rounded mr-2"
            alt=""
          />
          <strong className="mr-auto">{props.title}</strong>
        </Toast.Header>
        <Toast.Body>{props.body}</Toast.Body>
      </Toast>
    </div>
  );
}

export default ShowToast;
