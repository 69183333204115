import React from 'react';
import classnames from 'classnames';

const filterbox = (props) => {
    return (<div className="trip-col-3" onClick={props.click}>
        <div className={classnames('filter-box', props.filterclass === props.name ? 'active' : null)}>
            <div className={classnames('filter-head', props.filterclass === props.name ? 'filter-active': null) }>{props.status}{props.name}</div>
            <div className={classnames('filter-data', props.filterclass === props.name ? 'filter-active': null) }>{props.value}</div>
        </div>
    </div>);
}

export default filterbox;