import React from 'react'
import Table from './Table';

const TopVehicles = ({props, question, AsortedData, DsortedData, column}) => {
    
    const renderTopBottomTable = (title, d_Data, a_Data, header_2, column, time = false, tofixed = false) => (
        <>
          {(d_Data.length > 0 || a_Data.length > 0) &&
          <>
            <Table 
              data={d_Data} 
              a_data={a_Data} 
              header_1='Reg No / VIN' 
              header_dis={props.payload.column !== 'dis_cov' ? 'Distance (km)*' : null} 
              header_model={(props.payload.column === 'fuel_cons' || props.payload.column === 'avg_kmpl') ? 'Model' : null} 
              header_2={header_2} column={column} time={time} tofixed={tofixed} 
            />
            <p style={{ color: 'gray', fontSize: '14px', marginBottom: '10px' }}>* Total Distance travelled for given duration</p>
            {column === 'night_driving_duration' &&
              <p style={{ color: 'gray', fontSize: '14px', marginBottom: '10px' }}>Note: Timing considered for night driving - 10pm to 4am</p>
            }
          </>
          }
          {/* {(AsortedData.length > 0) &&
          <>
            <p style={{ color: '#4279e0' }}>{`Bottom 3 ${title}`}</p>
            <Table data={AsortedData} header_1='VIN / Reg No' header_dis={props.payload.column !== 'dis_cov' ? 'Distance' : null} header_2={header_2} column={column} time={time} tofixed={tofixed} />
          </>
          } */}
        </>
    );

  return (
    <div>
        
        <>
            {(DsortedData.length > 0 || AsortedData.length > 0) ?
                <>
                    {question === 'top_bottom_distance' && renderTopBottomTable('Distance Vehicles', DsortedData, AsortedData, 'Distance (km)*', column, false, true)}
                    {question === 'top_bottom_fuel_cons' && renderTopBottomTable('Fuel Consumption', DsortedData, AsortedData, 'Fuel Consumption (ltr)', column)}
                    {question === 'top_bottom_mileage' && renderTopBottomTable('Fuel Mileage', DsortedData, AsortedData, 'Fuel Mileage (kmpl)', column, false, true)}
                    {question === 'top_bottom_driving_economy_score' && renderTopBottomTable('Driving Economy Score', DsortedData, AsortedData, 'Driving Economy Score (out of 10)', column)}
                    {question === 'top_bottom_redband_percentage' && renderTopBottomTable('High RPM Driving (%)', AsortedData, DsortedData, 'High RPM Driving (%)', column)}
                    {question === 'top_bottom_idling' && renderTopBottomTable('Idling Duration', AsortedData, DsortedData, 'Idling Duration (hh:mm:ss)', column, true)}
                    {question === 'top_bottom_night_driving' && renderTopBottomTable('Night Driving Duration', AsortedData, DsortedData, 'Night Driving Duration (hh:mm:ss)', column, true)}
                    {question === 'top_bottom_continuous_driving' && renderTopBottomTable('Continuous Driving Duration', AsortedData, DsortedData, 'Continuous Driving Duration (hh:mm:ss)', column, true)}
                    {question === 'top_bottom_neutral_driving' && renderTopBottomTable('Neutral Driving Duration', AsortedData, DsortedData, 'Neutral Driving Duration (hh:mm:ss)', column, true)}
                    {question === 'top_three_over_racing' && renderTopBottomTable('Overacing %', AsortedData, DsortedData, 'Overacing(%)', column)}
                    {question === 'top_bottom_utilization' && renderTopBottomTable('Utilization %', DsortedData, AsortedData, 'Utilization(%)', column)}
                    {question === 'top_bottom_runtime' && renderTopBottomTable('Run Time', DsortedData, AsortedData, 'Run Time (hh:mm:ss)', column, true)}
                </>
                : 
                (!props.state.loader) && <p className="font-weight-bold">No data..</p>
            }
        </>
        
    </div>
  )
}

export default TopVehicles