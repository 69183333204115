import { getGASVehicleUnit } from "../../Util/VehicleDashUtils";

export const getChartLabel = (vital, vehicleType) => {
    const chartLabelMap = {
        "BATTERY_POTENTIAL": { label: "Battery Potential", units: "Battery Potential (v)" },
        "ENGINE_SPEED": { label: "Engine Speed", units: "Engine Speed (rpm)" },
        "FUEL_LEVEL": { label: "Fuel level", units: "Fuel level (ltr)" },
        "COOLANT_TEMPERTURE": { label: "Engine Coolant", units: "Engine Coolant ( °C )" },
        "AIR_PRESSURE": { label: "Air Pressure Digital", units: "Air Pressure Digital (state)" },
        "ALTITUDE": { label: "Altitude", units: "Altitude (m)" },
        "ENGINE_OIL_PRESSURE": { label: "Oil Pressure", units: "Oil Pressure (bar)" },
        "ODOMETER": { label: "Odometer", units: "Odometer (km)" },
        "VEHICLE_SPEED": { label: "Vehicle Speed", units: "Vehicle Speed (km/hr)" },
        "OIL_PRESSURE": { label: "Oil Pressure", units: "Oil Pressure (state)" },
        "REAR_AIR_PRESSURE": { label: "Air Pressure Rear", units: "Air Pressure Rear (bar)" },
        "FRONT_AIR_PRESSURE": { label: "Air Pressure Front", units: "Air Pressure Front (bar)" },
        "FUEL_CONSUMPTION": { label: "Fuel Consumption", units: "Fuel Consumption (ltr)" },
        "DEF_TANK_LEVEL": { label: "DEF Tank Level", units: "DEF Tank Level (ltr)" },
        "DEF_TANK_TEMPERATURE": { label: "DEF Tank Temperature", units: "DEF Tank Temperature ( °C )" },
        "EXHAUST_TEMPERATURE": { label: "Exhaust Temperature", units: "Exhaust Temperature ( °C )" },
        "ENGINE_HOURS": { label: "Engine Hours", units: "Engine Hours (hrs)" },
        "DEF_CONSUMPTION": { label: "DEF Consumption", units: "DEF Consumption(ltr)" },
        "COOLANT_TEMPERATURE": { label: "Coolant Temperature", units: "Coolant Temperature ( °C )" },
        "GAS_CONSUMPTION": { label: "Gas Consumption", units: "Gas Consumption (kg)" },
        "GAS_LEVEL": { label: "Gas Level", units: "Gas Level " +"(" +getGASVehicleUnit(vehicleType) +")" },
        "CURRENT_GEAR": { label: "Gear Number", units: "Gear Number" },
        "SOC": { label: "SOC", units: "SOC (%)" },
        "MOTOR_SPEED_MCU": { label: "Motor Speed", units: "Motor Speed (rpm)" },
        "PACK_VOLTAGE": { label: "Pack Voltage", units: "Pack Voltage (volts)" },
        "TOTAL_DC_DC_ENERGY_CONSUMPTION": { label: "Total DC-DC Energy consumption", units: "Total DC-DC Energy consumption (kWh)" },
        "MAIN_COOLANT_TEMP": { label: "Main Coolant Temperature", units: "Main Coolant Temperature (°C)" },
        "BATTERY_MAX_CELL_TEMPERATURE": { label: "Battery max temperature", units: "Battery max temperature (°C)" },
        "BATTERY_COOLANT_OUT_TEMPERATURE": { label: "Battery coolant out temperature", units: "Battery coolant out temperature (°C)" },
        "VEHICLE_TRANSMISSION_MODE": { label: "Vehicle Transmission Mode", units: "Vehicle Transmission Mode" }
    };

    return {
        columnlabel: chartLabelMap[vital]?.label || "Battery Potential",
        columnunits: chartLabelMap[vital]?.units || "Battery Potential (v)",
        columnvalue: vital
    };
}
