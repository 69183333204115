import React from 'react'
import Loader from '../Loader/Loader';
import scroll from '../Scroll'

const InputTxt = (props) => {

    var divs = document.querySelectorAll('.loading-chatbot');
    if(divs.length > 0){
        for (var i = 0; i < divs.length - 1; i++) {
            divs[i].style.display = 'none'; // Hide all except last div with class "loading-chatbot"
        }
    }

  return (
    <>
        {props.state.loader &&
            <Loader />
        }
    </>
  )
}

export default InputTxt