export const alert_types = {
    temp_warning: 'Temperature warning',
    low_battery_voltage: 'Low Battery Voltage',
    edc_warning: 'EDC Warning Lamp',
    mil_status: 'MIL status',
    stop_lamp: 'STOP Lamp',
    high_exa_temp: 'High Exhaust Temperature',
    regeneration: 'Regeneration needed',
    nox_error: 'NOx Error Lamp',
    air_filter_blocked: 'Air Filter Blocked',
    low_coolant_level: 'Low Coolant Level',
    low_def_level: 'Low DEF level',
    particulate_trap: 'Particulate Trap'
}