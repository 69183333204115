import React from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { GOOGLE_API_KEY } from '../../apiList';
const containerStyle = {
  width: '100%',
  height: '230px'
};

const StaticMap = ({position}) => {
  const { isLoaded } = useJsApiLoader({
    id: 'ibot-google-map-script',
    googleMapsApiKey: GOOGLE_API_KEY
  });

  const center = {
    lat: position.lat, 
    lng: position.lng
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={6}
    >
      <Marker position={{ lat: position.lat, lng: position.lng }}/>
    </GoogleMap>
  ) : <div>Loading...</div>;
};

export default StaticMap