import { combineReducers } from 'redux'
import userdata from './reducer'
import vehicledata from './vehicledata'
import menus from './menu'
import profileimg from './profile'
import GmapsReducer from './GmapsReducer'
import HeaderReducer from './HeaderReducer'
import sarathyReducer from './sarathyReducer'
import discountReducer from './discountReducer'
import vasReducer from './vasReducer'
import DashReducer from './DashReducer'

export default combineReducers({
  userdata,
  vehicledata,
  menus,
  profileimg,
  GmapsReducer,
  HeaderReducer,
  sarathyReducer,
  discountReducer,
  vasReducer,
  DashReducer
})