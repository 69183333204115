import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import * as apiList from '../../../apiList'
import { postData } from '../../../Util'
import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import styles from './ByModel.module.css'
import TransferList from './TransferList/TransferList';

import { GmapsDispatchThunk } from '../../../store/actions/GmapsActions';

const ByModel = (props) => {
  const [modelData, setModelData] = useState([])
  const [searchModel, setSearchModel] = useState('')
  const [vPartData, setvPartData] = useState([])
  const [resetButtonFired, setResetButtonFired] = useState(false)
  const [loader, setLoader] = useState(false)

  useEffect(() => {
    fetchData()
  }, [props.ctr.platform, props.ctr.token])

  async function fetchData () {
    const modelDetails = {
      platform: props.ctr.platform
    }
    const token = props.ctr.token
    setLoader(true)
    const data = await postData(apiList.searchByModel, modelDetails, token);
    setLoader(true)
    if (data && data?.data?.length > 0) {
      if (data?.statuscode === 200) {
        const modelData = data.data
        setModelData(modelData, props.ctr.platform, props.ctr.token);
        setSearchModel(props.modelData.model);
        setLoader(false);
      } else {
        console.log('Status code is not 200:', data.statuscode);
        setLoader(false);
      }
    } else {
      console.log('Status code is not 200:', data.statuscode);
    }
  }

  const handleDropdownChange = (e, v) => {
    setSearchModel(v)
  }

  const handleGoButtonClick = async (e) => {
    if(searchModel){
      const modelDetails = {
        platform: props.ctr.platform,
        vpartdesc: searchModel
      }
      const token = props.ctr.token
      setLoader(true)
      const data = await postData(apiList.searchByVpart, modelDetails, token);  //'http://172.16.11.161:8000/api/aluserlogin/getVinbasedOnVpartdesc'
      if(data.statuscode === 200){
        const vPartData = data.data
        setvPartData(vPartData)
        setResetButtonFired(false)
        setLoader(false)
      }
      
    }
  }

  const submitByModel = () => {
    let el = document.getElementsByClassName('MuiList-root MuiList-dense MuiList-padding')[1];
    let el_2 = el.getElementsByClassName('MuiTypography-root MuiListItemText-primary MuiTypography-body2');
    let selectedVin = [];
    
    if(el_2.length){
      for(let i = 0; i < el_2.length; i++){
        selectedVin.push(el_2[i].innerText);
      }
      if(selectedVin.length < 200){
        let dataObj = {
          platform: "w",
          userId: props.ctr.userid,
          loginId: '',
          mappingType:"model",
          vin: selectedVin
        };

        let vehtoken = props.ctr.token;
        const datas = postData(apiList.createVIN, dataObj, vehtoken);  //'http://172.16.11.161:8000/api/aluserlogin/createVIN'
        datas.then((dataVals) => {
            if(dataVals.statuscode === 200){
              props.callBack();
              props.gmapsDispatch({ showWelcomeModal: false })
            }
        });
      }else{
        alert('You have exceeded the maximum selection. Please select less than 200 vehicles.')
      }
    }
  }

  const handleResetButtonClick = () => {
    setSearchModel('')
    setvPartData([])
    setResetButtonFired(true)
  }

  const someArray = []
  vPartData.filter(obj => someArray.push(obj.vin_number))

  return (
    <>
    {loader ?<div className="Pageloading"> <img className="loadimg" src="/images/loader_1.gif" alt="iAlert" /></div> :''}
    <div className={styles.ByModelContainer}>
      <div className={styles.LoginId}>
        <Autocomplete
          id="free-solo-demo"
          freeSolo
          value={searchModel}
          onChange={handleDropdownChange}
          options={modelData.map(model => model.vpart_desc)}
          renderInput={params => (
            <TextField
              {...params}
              id="standard-basic" 
              label="Select Model" 
              type="search" 
              style={{width: '50%'}}
            />
          )}
          disabled={modelData.length > 0 ? false : true}
        />

        <div>
          <Button
            variant="contained"
            color="secondary"
            endIcon={<SendIcon />}
            onClick={handleGoButtonClick}
            disabled={searchModel ? false : true}
          >
            Go
          </Button>
        </div>
      </div>

      <div className={styles.TransferListContainer}>
        <TransferList dataArray={someArray} resetButtonFired={resetButtonFired} />
      </div>

      <div className={styles.ResetSubmit}>
        <div className={styles.ResetSubmit}>
          <Button
            variant="contained"
            color="secondary"
            endIcon={<ArrowForwardIosIcon />}
            onClick={submitByModel}
          >
            Submit
          </Button>
        </div>
        <div className={styles.ResetSubmit}>
          <Button
            variant="contained"
            color="secondary"
            endIcon={<RotateLeftIcon />}
            onClick={handleResetButtonClick}
          >
            Reset
          </Button>
        </div>
      </div>
    </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  ctr: state.userdata.id
})

const mapdispatchToprops = dispatch => {
  return {
      gmapsDispatch: (payload) => dispatch(GmapsDispatchThunk(payload))
  };
}

export default connect(mapStateToProps, mapdispatchToprops)(ByModel)
