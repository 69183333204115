const profilePhoto = {
     photo:null
 }
 const reducer = (state = profilePhoto,action) => {
    if(action.type === 'PROFILE'){
        return{
            ...state,
            photo:action.value
        }
     }
     return {...state};
 };
 
 export default reducer;