import React from "react"
import { compose, withProps, lifecycle } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker, Polyline, Circle, InfoWindow, Polygon } from "react-google-maps";
import { postData, postDataTrace } from '../../Util';
import * as apilist from '../../apiList';
import { connect } from 'react-redux';
import DateComponent from '../Vehicleinfo/DateComponent';
import './TraceScreen.scss';
import AlertBox from '../../Components/AlertBox/alertBox';
import { showDEF, getGASVehicleUnit } from "../../Util/VehicleDashUtils";
import { getDistanceValue, getFuelLevelValue } from "../../Components/NewListView/Utils";
import TracePopup from "./TracePopup";
import { formatChargingTime } from "./TraceUtils";

let uservehicledate = {};
const options = {
  minZoom: 3,
};
const MyMapComponent = compose(withProps({
  googleMapURL: "https://maps.googleapis.com/maps/api/js?libraries=places,geometry,visualization,drawing&key="+apilist.GOOGLE_MAP_API_KEY+"&v=3.exp&sensor=false",
  loadingElement: <div style={{ height: `100%` }} />,
  containerElement: <div style={{ height: `100%` }} />,
  mapElement: <div style={{ height: `100%` }} />
}), withScriptjs, withGoogleMap, lifecycle({
  componentDidMount() {
    this.setState({
      updateMarkers: (a, b) => {
        console.log(a);
        console.log(b);
      }
    })

  }

})
)(props =>
(
  <div>
    {
      
      props.dataVals ?
        <GoogleMap zoom={props.mapZoom} options={options} center={props.mapCenter} ref={props.zoomToMarkers} >
          {
            props.dataVals ?
              props.dataVals.map((mark, index) => {
                var MarkerOptions = {
                  path: window.google.maps.SymbolPath.CIRCLE,
                  fillOpacity: 3,
                  scale: 3,
                  strokeColor: '#009900', // ,#006622 dots
                  strokeWeight: 3
                };

                let latVal = mark.latitude,
                lngVal = mark.longitude;
                var title = "";

                if (mark.vehstatus === 0) {
                  MarkerOptions.strokeColor = '#009900';
                } else if (mark.vehstatus === 1 || mark.vehstatus === 3) {
                  MarkerOptions.strokeColor = '#ff3300';
                } else if (mark.vehstatus === 2) {
                  MarkerOptions.strokeColor = '#ffff00';
                }
                let fL;
                let DL;   
                let DEFS;           
                if (props.selectedVehicle.vehicleType === 'EDC_LCV' || props.selectedVehicle.vehicleType ==='EEA_LCV') {
                  fL = 30
                  DL = 13
                  DEFS = mark.fuelVal !== undefined && mark.fuelVal !== null ? ((mark.fuelVal/100)*4).toFixed(1) : "0";
                } else {
                  fL = mark.fuellevel ? mark.fuellevel : "0"
                  DL = mark.catalystTankLevel !== undefined && mark.catalystTankLevel !== null ? mark.catalystTankLevel : "0";
                  DEFS = mark.defsVal ? mark.defsVal : 0;
                }
                if (index === 0) {
                  MarkerOptions = '';
                  title = title + "Start Point \n";
                } else if (index === props.dataVals.length - 1) {
                  title = title + "End Point \n";
                  MarkerOptions = props.endVehicle;
                } else {
                  title = "";
                }
                let iS = mark.ignition ? 'ON' : 'OFF',
                  fC = mark.fuelVal ? mark.fuelVal : 0;

                let formatted_date = props.getFormatedData(mark, 'timestamp');
                var defDetails
                var fuel_level
                
                
                var isbs6cng;
                var isbs6cng_cons;
                var isbs6unit;
                var isbs6unitkg;
                let fuel_con;
                let dis_travl;
                const vehicleEnorm = props.currentMarker.isBS6;
                if (props.selectedVehicle.vehicleType === 'EDC_LCV' || props.selectedVehicle.vehicleType ==='EEA_LCV') {
                  defDetails = "DEF Level : " + DL + " ltr";
                  isbs6cng = "Fuel Level : " 
                  isbs6unit = " ltr"
                  isbs6unitkg = " ltr" 
                  fuel_con = ''  
                  dis_travl = ''                
                }else { 
                  dis_travl = "Distance Travelled : " + getDistanceValue(mark.distance_travelled) + " km \n"                  
                  if (showDEF(props.currentMarker)) {
                    if(mark.ignition){
                      defDetails = "DEF Consumption : " + DEFS + " ltr \n" +
                      "DEF Level : " + DL + " ltr";
                    }
                    else{
                      defDetails = "DEF Consumption : " + DEFS + " ltr \n";
                    }
                  } else {
                    defDetails = '';
                  }
                  
                  if (vehicleEnorm === 2) {
                    isbs6cng = "Gas Level : "
                    isbs6cng_cons = "Gas Consumption : "
                    isbs6unit = getGASVehicleUnit(props.selectedVehicle.vehicleType)
                    isbs6unitkg = " kg"
                  } else if (vehicleEnorm === 4) {
                    isbs6cng = "Battery Status : "
                    isbs6cng_cons = "Energy Consumption : "
                    isbs6unit = " %"
                    isbs6unitkg = " "
                  } else {
                    isbs6cng_cons = "Fuel Consumption : "
                    isbs6cng = "Fuel Level : "
                    isbs6unit = " ltr"
                    isbs6unitkg = " ltr"
                  }
                 fuel_con =  isbs6cng_cons + getFuelLevelValue(fC) + isbs6unitkg + " \n"
                }                

                fuel_level = mark.ignition ? isbs6cng + (vehicleEnorm === 4 ?  mark.battery_status : getFuelLevelValue(fL)) + isbs6unit + "\n" : "";
                const engineSpeed = (vehicleEnorm === 4 ? Math.round(mark.motorspeed) : Math.round(mark.enginespeed)) + (' rpm');
                const vehicleSpeed = (vehicleEnorm === 4 ? Math.round(mark.vehiclespeed)  : Math.round(mark.gpsspeed)) + (' km/hr');
                let location = mark.location ? mark.location : '-';
                title = title + "Vehicle Status : " + props.getFormatedData(mark, 'status') + "\n" +
                  fuel_level +  fuel_con + "Ignition Status : " + iS + "\n" + dis_travl;
                  if (vehicleEnorm === 4) {
                    const distanceToEmpty = mark.distance_to_empty !== undefined && mark.distance_to_empty !== null ? Math.round(mark.distance_to_empty) : 0;
                    title = title + "Vehicle Speed : " + vehicleSpeed + "\n";
                    title = title + "Motor Speed : " + engineSpeed + "\n";
                    title = title + "Distance To Empty : " + distanceToEmpty + (' km') + "\n";
                    title = title + "Main Coolant Temperature : " + mark.main_coolant_temp +(' °C') + "\n";
                    title = title + "Battery Coolant Out Temperature : " + mark.battery_coolant_out_temp+(' °C') + "\n";
                  } else {
                    title = title + "Vehicle Speed : " + vehicleSpeed + "\n";
                    title = title + "Engine Speed : " + engineSpeed + "\n";
                  }
                  if (defDetails !== '') {
                    title = title + defDetails + "\n";
                  }
                  title = title +"TimeStamp : " + formatted_date + "\n";
                  title = title +"Location : " + location ;
                return <Marker icon={MarkerOptions} position={{ lat: latVal, lng: lngVal }} key={index} title={title} >
                </Marker>
              }) : '' 
           
          }
          <Polyline path={props.dataVals} options={{
            strokeColor: "#009900",
            strokeOpacity: 1.0,
            strokeWeight: 2,
            geodesic: true
          }} />
          {props.geofencehideandshow === true ? props.circledata.map((marker, index) =>
            <Circle key={index} defaultCenter={{ lat: marker.latlongs.lng, lng: marker.latlongs.lat }} radius={Number(marker.radius)}
              options={{ fillColor: "#000", strokeColor: "#000", fillOpacity: 0.4, strokeOpacity: 1, strokeWeight: 1 }} />

          ) : ''}

          {props.geofencehideandshow === true ? props.circledata.map((marker, index) =>
            //  <Marker position={{ lat: marker.latlongs.lng, lng: marker.latlongs.lat }} title= {marker.geofence_name} />
            <InfoWindow key={index}
              position={{ lat: marker.latlongs.lng, lng: marker.latlongs.lat }}
              options={{ closeBoxURL: ``, enableEventPropagation: true }}
            >
              <div style={{ backgroundColor: `#fff`, padding: `5px`, borderRadius: `25px`, width: `100%`, position: `relative` }}>
                <div style={{ fontSize: `13px`, fontWeight: `bold`, color: `black` }}>
                  {marker.geofence_name}
                </div>
              </div>
            </InfoWindow>

          ) : ''}
          {props.geofencehideandshow === true ? props.polygondata.map((marker, index) =>
            <Polygon key={index} path={marker.latlongs}
              options={{ fillColor: "#000", fillOpacity: 0.4, strokeColor: "#000", strokeOpacity: 1, strokeWeight: 1 }} />

          ) : ''}

          {props.geofencehideandshow === true ? props.polygondata.map((marker, index) =>
            //  <Marker position={{ lat: marker.latlongs.lng, lng: marker.latlongs.lat }} title= {marker.geofence_name} />
            <InfoWindow key={index}
              position={{ lat: marker.latlongs[0].lat, lng: marker.latlongs[0].lng }}
              options={{ closeBoxURL: ``, enableEventPropagation: true }}
            >
              <div style={{ backgroundColor: `#fff`, padding: `5px`, borderRadius: `25px`, width: `100%`, position: `relative` }}>
                <div style={{ fontSize: `13px`, fontWeight: `bold`, color: `black` }}>
                  {marker.geofence_name}
                </div>
              </div>
            </InfoWindow>

          ) : ''}

          {props.progress && (
            <>
              <Polyline path={props.progress} options={{
                strokeColor: "#007bff",
                strokeOpacity: 1.0,
                strokeWeight: 4,
                geodesic: true
              }} />
              {/* <Marker position={props.progress[0]} icon={{url:"https://www.google.com/intl/en_us/mapfiles/ms/micons/blue-dot.png",scale:3}} /> */}
              {props.onProgressCompleted == false && <Marker icon={'/images/Trace/green-dot.png'} position={props.progress[props.progress.length - 1]} style={{
                backgroundColor: "#0000ff",
                fillColor: "#0000ff",
                strokeColor: "0000ff",
              }} >
                {props.progress.length > 0 &&
                  <InfoWindow position={props.progress[props.progress.length - 1]} options={{

                    boxStyle: {
                      boxShadow: `3px 3px 10px rgba(0,0,0,0.6)`,
                      border: `1px solid #333`
                    },

                  }}>
                    <div className="info-content">
                      <div>
                        <div className="custom-row">
                          <div className="col-26">
                            <div className="col-aln-cn">
                              {props.getFormatedData(props.dataVals[props.progress.length - 1], 'getTime')}
                              <div class="col-custom-one">{props.getFormatedData(props.dataVals[props.progress.length - 1], 'getDate')}</div>
                            </div>
                          </div>
                          <div className="col-26 col-border">
                            <div className="col-aln-cn ">
                              <div className="txt-cl1">{props.currentMarker?.isBS6 === 4 ? props.getFormatedData(props.dataVals[props.progress.length - 1], 'vehiclespeed') :
props.getFormatedData(props.dataVals[props.progress.length - 1], 'gpsSpeed')}<span className="inter-css">kmph</span></div>
                              <div className="col-txt-mg">Vehicle Speed</div>
                              {/* <div class="col-unt">(in km/hr)</div> */}
                            </div>
                          </div>


                          <div className="col-28 col-border">
                            <div className="col-aln-cn">
                              <div className={props.finalDistance > 1000 ? "txt-cl2" : "txt-cl1"}><span>{ props.getFormatedData(props.dataVals[props.progress.length - 1], 'distance')}</span><span className="inter-css">km</span></div>
                              <div className="col-txt-mg">Distance Travelled</div>
                              {/* <div class="col-unt">(in km)</div> */}
                            </div>
                          </div>
                          {props.currentMarker?.isBS6 === 4 ? props.getFormatedData(props.dataVals[props.progress.length - 1], 'energy_consumed')
                          : props.getFormatedData(props.dataVals[props.progress.length - 1], 'fuelLevel', )}

                        </div>
                        <div className="custom-row row-mg">
                          <div className="col-100">
                            <div><img className="col-img" src="/images/NewListView/pin.png" alt="pin" /></div>
                            <div className="col-span-lc">{props.getFormatedData(props.dataVals[props.progress.length - 1], 'location')}</div>
                          </div>
                        </div>
                      </div>
                      <div className="custom-row">
                        <div className="col-progress">
                          <progress id="file" value={props.progressVl} max="100"></progress>
                        </div>
                      </div>
                    </div>
                  </InfoWindow>
                }
              </Marker>}
              {/* {props.onProgressCompleted == true &&<Marker icon={props.endVehicle} position={props.progress[props.progress.length - 1]} style={{
          backgroundColor: "#0000ff",
          fillColor: "#0000ff",
          strokeColor: "0000ff",
        }}
        
      />} */}

            </>
          )}
        </GoogleMap>
        : <GoogleMap defaultZoom={5} defaultCenter={{ lat: 20.5937, lng: 78.9629 }}>
          <Polyline path={props.dataVals} options={{
            strokeColor: "#009900",
            strokeOpacity: 1.0,
            strokeWeight: 2,
            geodesic: true
          }} />
          {props.geofencehideandshow === true ? props.circledata.map((marker, index) =>
            <Circle key={index} defaultCenter={{ lat: marker.latlongs.lng, lng: marker.latlongs.lat }} radius={Number(marker.radius)}
              options={{ fillColor: "#000", strokeColor: "#000", fillOpacity: 0.4, strokeOpacity: 1, strokeWeight: 1 }} />

          ) : ''}

          {props.geofencehideandshow === true ? props.circledata.map((marker, index) =>
            //  <Marker position={{ lat: marker.latlongs.lng, lng: marker.latlongs.lat }} title= {marker.geofence_name} />
            <InfoWindow key={index}
              position={{ lat: marker.latlongs.lng, lng: marker.latlongs.lat }}
              options={{ closeBoxURL: ``, enableEventPropagation: true }}
            >
              <div style={{ backgroundColor: `#fff`, padding: `5px`, borderRadius: `25px`, width: `100%`, position: `relative` }}>
                <div style={{ fontSize: `13px`, fontWeight: `bold`, color: `black` }}>
                  {marker.geofence_name}
                </div>
              </div>
            </InfoWindow>

          ) : ''}
          {props.geofencehideandshow === true ? props.polygondata.map((marker, index) =>
            <Polygon key={index} path={marker.latlongs}
              options={{ fillColor: "#000", fillOpacity: 0.4, strokeColor: "#000", strokeOpacity: 1, strokeWeight: 1 }} />

          ) : ''}

          {props.geofencehideandshow === true ? props.polygondata.map((marker, index) =>
            //  <Marker position={{ lat: marker.latlongs.lng, lng: marker.latlongs.lat }} title= {marker.geofence_name} />
            <InfoWindow key={index}
              position={{ lat: marker.latlongs[0].lat, lng: marker.latlongs[0].lng }}
              options={{ closeBoxURL: ``, enableEventPropagation: true }}
            >
              <div style={{ backgroundColor: `#fff`, padding: `5px`, borderRadius: `25px`, width: `100%`, position: `relative` }}>
                <div style={{ fontSize: `13px`, fontWeight: `bold`, color: `black` }}>
                  {marker.geofence_name}
                </div>
              </div>
            </InfoWindow>

          ) : ''}
        </GoogleMap>
    }
  </div>
));


class TraceScreen extends React.PureComponent {
  constructor(props) {
    super(props);
    this.circle = React.createRef();
    this.state = {
      isMarkerShown: false, startDate: null, endDate: null, startTime: null, endTime: null, datas: '', isOpen: false, intervalId: '',
      selectedMarker: false, enablegeofence: false, zoomToMarkers: '', enablegeofencetoggle: false, loadedVins: [], userDet: this.props.uD, selectedVeh: '', totalPropVals: '', circlegeofencedata: [], polygeofencedata: [], loader: false, showNormalPolyline: true, progress: [],
      mapCenter: { lat: 20.5937, lng: 78.9629 }, mapZoom: 5, btnName: 'Play', onPauseClicked: false, onProgressCompleted: false, disableSpeed: false, onSpeedSelectValue: '2', finalDistance: 0, progressValue: 0, 
      howMessage:"", showAlertBox: false, messageType: "e",
    }
    this.traceView = this.traceView.bind(this);
    this.updatedData = this.updatedData.bind(this);
    this.velocity = 0.15;
    this.last_distance = 0;
    this.intervalTime = 100;
    this.path = [];
    this.markerMoving = false;
    // this.interval;
  }

  handleToggleOpen = () => {
    this.setState({ isOpen: true });
  }

  handleToggleClose = () => {
    this.setState({ isOpen: false });
  }

  componentDidMount() {
    this.delayedShowMarker();
  }
  
  WKTToLatLng = (pointStr) => {
    if (pointStr != null) {
      let str = pointStr.substring(pointStr.indexOf('(')).replace('(', '').replace(')', '');
      let latLng = str.trim().split(' ');
      //return new google.maps.LatLng(latLng[0], latLng[latLng.length - 1]);
      return {
        lat: Number(latLng[0]),
        lng: Number(latLng[latLng.length - 1])
      };
    }
  }

  animatedTrace = () => {
    if (this.state.btnName == "Play") {
      try {
        this.markerMoving = true;
        this.setState({
          showNormalPolyline: false,
          btnName: "Pause",
          onProgressCompleted: false,
        }, () => {
          if (this.state.onPauseClicked == false) {
            let dataVal = Object.assign([], this.state.datas.
              trace_points);
            if(dataVal.length >0){
              this.path = dataVal;
              let value = this.path[0]
              let finalvalue = this.path[this.path.length - 1];
              let finalDistance = finalvalue.distance != undefined ? Number(finalvalue.distance.toFixed(1)) : 0;
              let center = "";
              let zoom = 12;
              if (this.state.onSpeedSelectValue == "3") {
                zoom = 11;
              } else if (this.state.onSpeedSelectValue == "4") {
                zoom = 10;
              }
              else if (this.state.onSpeedSelectValue == "5") {
                zoom = 10;
              }
              if (this.state.finalDistance >= 1000) {
                zoom = zoom - 2;
              }
              center = { lat: value['lat'], lng: value['lng'] }
              this.initialDate = new Date();
              this.setState({
                mapZoom: zoom,
                mapCenter: center,
                progress: [],
                finalDistance: finalDistance,
                progressValue: 0
              }, () => {
                setTimeout(() => {
                  let intervalId = setInterval(this.moveObject, this.intervalTime)
                  this.setState({ intervalId: intervalId })
                }, 500);
              })
            }
            else{
              alert('No Data available');
              this.setState({
                btnName: "Play",
              });
            }
          }
          else {
            this.moveObject();
            let intervalId = setInterval(this.moveObject, this.intervalTime)
            this.setState({ intervalId: intervalId })
          }


        });
      }
      catch (e) {
        console.log(e);
      }
    } else {
      this.markerMoving = false;
      this.onClearInterval();
      this.setState({
        btnName: "Play",
        onPauseClicked: true,
        disableSpeed: false
      });
    }
  }

  getFormatedData = (data, forField) => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    try {

      if (forField == 'status') {
        let status = "";
        if (data.vehstatus === 0) {
          status = 'RUNNING';
        } else if (data.vehstatus === 1 || data.vehstatus === 3) {
          status = data.vehstatus === 1 ? 'STOPPED' : 'TOWED';
        } else if (data.vehstatus === 2) {
          status = 'IDLING';
        }
        return status;
      }
      else if (forField == "timestamp") {
        let current_datetime = new Date(data.localtimestamp);
        let hours = current_datetime.getHours();
        let minutes = current_datetime.getMinutes();
        let seconds = current_datetime.getSeconds();
        let ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ':' + seconds +' ' + ampm;

        return months[current_datetime.getMonth()] + " " + current_datetime.getDate() + ", " + current_datetime.getFullYear() + " " + strTime;
      }
      else if (forField == "getTime") {
        let current_datetime = new Date(data.localtimestamp);
        let hours = current_datetime.getHours();
        let minutes = current_datetime.getMinutes();
        let ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        return <><div className="txt-cl1">{hours + ':' + minutes}<span className="inter-css">{ampm}</span></div></>
      }
      else if (forField === "getDate") {
        let current_datetime = new Date(data.localtimestamp);
        return months[current_datetime.getMonth()] + " " + current_datetime.getDate() + ", " + current_datetime.getFullYear();
      }
      else if (forField === "engineRpm") {
        return Math.round(data.enginespeed)
      }
      else if (forField === "gpsSpeed") {
        return Math.round(data.gpsspeed);
      }
      else if (forField === "vehiclespeed") {
        return Math.round(data.vehiclespeed);
      }
      else if (forField === "fuelLevel" || forField === "battery_status" || forField === "fuelConsumption" || forField === 'energy_consumed') {
        let fC = data.fuelVal ? data.fuelVal : 0;
        let isbs6cng, isbs6unit;
        if (data.isBS6 === 2) {
          isbs6cng = "Gas Used"
          isbs6unit = "bar"
        } else if (data.isBS6 === 4) {
          isbs6cng = "Energy Consumed"
          isbs6unit = "kWh"
          fC = data.energy_consumed ? data.energy_consumed : 0;
        } else {
          isbs6cng = "Fuel Used"
          isbs6unit = "ltr"
        }
        return <><div className="col-20 col-border" ><div className="col-aln-cn"><div className="txt-cl1">{Math.round(fC)}<span className="inter-css">{isbs6unit}</span></div><div className="col-txt-mg">{isbs6cng}</div></div></div></>
      }
      else if (forField == "ignition") {
        return data.ignition ? 'ON' : 'OFF';
      }
      else if (forField == "distance") {
        return data.distance != undefined ? data.distance.toFixed(1) : 0;
      }
      else if (forField == "location") {
        return data.location;
      }
    }
    catch (e) {
      return "None"
    }

  }

  moveObject = () => {
    let distance = this.getDistance();
    if (!distance) {
      return
    }

    let progress = this.path.filter(coordinates => coordinates.distance < distance)    
    if (progress.length > 0) {
      let value = progress.slice((progress.length - 1), progress.length);
      let center = "";
      if (value.length > 0) {
        center = { lat: value[0]['lat'], lng: value[0]['lng'] }
        // this.setState({
        //   mapCenter:center,
        // });
      }
      let progressVl = (value[0]['distance'] / this.state.finalDistance) * 100;
      const nextLine = this.path.find(coordinates => coordinates.distance > distance)
      if (!nextLine) {
        if(isNaN(progressVl)){
          this.openModel("Playback not shown due to vehicle distance is 0 km","e");
        }
        this.setState({
          mapCenter: center,
          progressValue: isNaN(progressVl) ? 0 : Math.round(progressVl) // progressVl !== NaN ? Math.round(progressVl) : 0
        }, () => {
          this.setState({ progress: progress }, () => {
          this.onEndingplayRoute();
           
          })
        });

        return // it's the end!
      }
      const lastLine = progress[progress.length - 1]

      const lastLineLatLng = new window.google.maps.LatLng(
        lastLine.lat,
        lastLine.lng
      )

      const nextLineLatLng = new window.google.maps.LatLng(
        nextLine.lat,
        nextLine.lng
      )
      
      // distance of this line 
      const totalDistance = nextLine.distance - lastLine.distance
      const percentage = (distance - lastLine.distance) / totalDistance

      const position = window.google.maps.geometry.spherical.interpolate(
        lastLineLatLng,
        nextLineLatLng,
        percentage
      )

      progress = progress.concat(position)
      this.setState({
        mapCenter: position,
        progressValue: isNaN(progressVl) ? 0 : Math.round(progressVl) //progressVl !== NaN ? Math.round(progressVl) : 0
      }, () => {
        this.setState({ progress: progress }, () => {
          this.onEndingplayRoute();
        })
      })

    }
  }

  onEndingplayRoute = () => {
    if (this.state.progress.length == this.state.datas.length) {
      this.setState({
        onPauseClicked: false,
        btnName: "Play",
        onProgressCompleted: true,
        disableSpeed: false,
      })
      this.last_distance = 0;
      this.markerMoving = false;
      this.onClearInterval();
    }
  }

  getDistance = () => {
    let velocity = this.velocity;
    if (this.state.finalDistance >= 1000 && this.state.finalDistance < 5000) {
      velocity = velocity + 0.5;
    } else if (this.state.finalDistance > 5000) {
      velocity = velocity + 1;
    }
    this.last_distance = this.last_distance + velocity;
    return this.last_distance;

  }

  openModel = (message,msgType) => {
    this.setState({
        showAlertBox: true,
        showMessage:message,
        messageType:msgType
    });
  };

  hideModal = () => {
    this.setState({
        showMessage:"",
        showAlertBox: false 
    });
  };

  playbackrouteHide = () => {
    this.setState({
      showNormalPolyline: true,
      progress: [],
      mapCenter: { lat: 20.5937, lng: 78.9629 },
      mapZoom: 5,
      btnName: "Play",
      onPauseClicked: false,
      disableSpeed: false,
      finalDistance: 0,
      progressValue: 0
    });
    this.path = [];
    this.last_distance = 0;
    this.markerMoving = false;
    this.onClearInterval();

  }

  onClearInterval = () => {
    if (this.state.intervalId != "") {
      clearInterval(this.state.intervalId);
    }
  }

  componentWillUnmount = () => {
    this.onClearInterval();
  }

  getPoints = (str) => {
    let latlngs = str.split(','),
      planCoordinates = [],
      latLng;
    for (let i = 0; i < latlngs.length; i++) {
      let newObj = {};
      let trimedlatlong = latlngs[i].trim()
      latLng = trimedlatlong.split(' ');
      newObj.lat = Number(latLng[0]);
      newObj.lng = Number(latLng[1]);
      planCoordinates.push(newObj);
    }
    return planCoordinates;
  }
  traceView = (statVals) => {
    let vehtokens = this.props.uD.token;

    let geostatVals = {}
    geostatVals.vin_number = statVals.vinNumberdetails
    const goefecedatas = postData(apilist.getgeofencefortrace, geostatVals, vehtokens);
    goefecedatas.then((geodataval) => {
      if (geodataval.statuscode === 200 && geodataval.data.length !== 0) {
        // this.setState({enablegeofencetoggle:true})
        var circleobjs = geodataval.data.geofenceData.CirclegeofenceData
        for (let i = 0; i < circleobjs.length; i++) {
          circleobjs[i].latlongs = this.WKTToLatLng(circleobjs[i].geofence_center);

        }
        this.setState({ circlegeofencedata: circleobjs, enablegeofencetoggle: true })
        var polyobjs = geodataval.data.geofenceData.PolygongeofenceData
        for (let i = 0; i < polyobjs.length; i++) {
          let polygonStr = polyobjs[i].geofence_center;
          polygonStr = polygonStr.substring(polygonStr.indexOf('(')).replace('((', '').replace('))', '');
          polyobjs[i].latlongs = this.getPoints(polygonStr);


        }

        this.setState({ polygeofencedata: polyobjs, enablegeofencetoggle: true })
      } else {
        this.setState({ polygeofencedata: [], circlegeofencedata: [] })
        this.setState({ enablegeofencetoggle: false })
      }
    })

    this.setState({ loader: true });
    const vehProp = this.props.ctr.selectedVehicle;
    if (statVals) {
      statVals.is_bs6 = this.props.ctr.selectedVehicle.isBS6;
      statVals.ad_blue_cap = this.props.ctr.selectedVehicle.adBlueTankCap;
      statVals.fuel_tank_cap = this.props.ctr.selectedVehicle.fuelTankCap;
      if (vehProp?.isBS6 === 4) {
        statVals.frequency = "4";
      }
      this.setState({ datas: '' });
      this.props.selecteddate(statVals);
      const datas = postDataTrace(apilist.getTrace, statVals, vehtokens);
      datas.then((dataVals) => {
        if (!dataVals) {
          console.error('504 Gateway Timeout')
          this.setState({ loader: false, datas: '' });
        } else if (dataVals?.statuscode === 200) {
          this.updateState(statVals);
          this.setState({ loader: false });
          dataVals.data.trace_points.map((datas, index) => {
            datas.lat = datas.latitude;
            datas.lng = datas.longitude;
            datas.isBS6 = this.props.ctr.selectedVehicle.isBS6
            datas.distance = this.getDistance2(dataVals.data.trace_points, index, vehProp);
            datas.fuelVal = this.calcFuel(dataVals.data.trace_points, index);
            datas.defsVal = this.calcdefs(dataVals.data.trace_points, index);
            return datas;
          });
          this.getEndveh(dataVals);
          this.updatedData(dataVals);

          this.setState({ datas: dataVals.data});
          this.props.excelDownload(dataVals.data.trace_points);
        } else {
          this.setState({ loader: false, datas: '' });
          this.props.excelDownload('');
          alert('No Data available');
        }
      });
      const getRunningIdlilng = postDataTrace(apilist.getRunningIdlilng, statVals, vehtokens);
      getRunningIdlilng.then((getrunningval) => {
        if(!getrunningval){
          console.error('504 Gateway Timeout')
          this.setState({ loader: false});
        }
        if (getrunningval?.statuscode === 200 && getrunningval.data) {
          var frmt = new Date(statVals.fromDate + " " + statVals.fromTime).getTime();
          var tot = new Date(statVals.toDate + " " + statVals.toTime).getTime();
          // Hours, minutes and seconds
          var differencetime = tot - frmt;
          var differenceseconds = Math.floor((differencetime / 1000));
          var totaltime = getrunningval.data.running_time_sec + getrunningval.data.idiling_time_sec;
          var haltsecons = Math.abs(totaltime - differenceseconds);
          const formattedRunningTime = formatChargingTime(getrunningval?.data?.running_time_sec);
          const formattedIdelingTime = formatChargingTime(getrunningval?.data?.idiling_time_sec);
          const formattedTime = formatChargingTime(getrunningval?.data?.charging_time_sec);
          const formattedHaltTime = formatChargingTime(haltsecons);
          this.setState({ idlingtimecnt: formattedIdelingTime, chargingTime: formattedTime, runningtimecnt:  formattedRunningTime, alttimecnt: formattedHaltTime });
        } else {
          this.setState({ loader: false});
          // alert('No Data available');
        }
      })
    } else {
      this.setState({ loader: false });
      this.setState({ datas: '' });
      this.props.excelDownload('');
      return false;
    }
  }

  calcFuel = (resourceArray, endpoint) => {
    let fuelVal = 0;
    let firstVal = resourceArray.filter(obj => obj.fuelcons > 0);
    firstVal = firstVal.length > 0 ? firstVal[0].fuelcons : 0;
    if (firstVal && resourceArray.length > 1 && endpoint > 0) {
      if (resourceArray[endpoint].fuelcons) {
        fuelVal = resourceArray[endpoint].fuelcons - firstVal;
      } else {
        fuelVal = resourceArray[endpoint - 1].fuelVal;
      }
    } else {
      fuelVal = 0;
    }
    return fuelVal;
  }
  calcdefs = (resourceArray, endpoint) => {
    let defsVal = 0;
    let firstVal = resourceArray.filter(obj => obj.adBlueConsumption > 0);
    firstVal = firstVal.length > 0 ? firstVal[0].adBlueConsumption : 0;
    if (firstVal && resourceArray.length > 1 && endpoint > 0) {
      if (resourceArray[endpoint].adBlueConsumption) {
        defsVal = resourceArray[endpoint].adBlueConsumption - firstVal;
      } else {
        defsVal = resourceArray[endpoint - 1].defsVal;
      }
    } else {
      defsVal = 0;
    }
    return defsVal;
  }

  getDistance2 = (resourceArray, endpoint, vProp) => {
    let distanceVal = 0;
    if (resourceArray.length > 1 && endpoint > 0) {
      //EDC, IL => GPS ODO
      //EEA	=> Vehicle ODO     
      let initialOdo = 0;
      if (vProp.isBS6 !== 0) {
        let firstVal = resourceArray.filter(obj => obj.vehdistance > 0);
        initialOdo = firstVal.length > 0 ? firstVal[0].vehdistance : 0;
      } else {
        if (vProp.vehicleType.toUpperCase() === "EEA") {
          let firstVal = resourceArray.filter(obj => obj.vehicleodo > 0);
          initialOdo = firstVal.length > 0 ? firstVal[0].vehicleodo : 0;
        } else {
          let firstVal = resourceArray.filter(obj => obj.gpsodo > 0);
          initialOdo = firstVal.length > 0 ? firstVal[0].gpsodo : 0;
        }
      }

      let OdoVal = vProp.isBS6 !==0 ? resourceArray[endpoint].vehdistance : vProp.vehicleType.toUpperCase() === "EEA" ? resourceArray[endpoint].vehicleodo : resourceArray[endpoint].gpsodo;

      OdoVal = OdoVal || 0;
      let prevOdoVal = vProp.isBS6 !== 0  ? resourceArray[endpoint - 1].vehdistance : vProp.vehicleType.toUpperCase() === "EEA" ? resourceArray[endpoint - 1].vehicleodo : resourceArray[endpoint - 1].gpsodo;

      let currObuId = resourceArray[endpoint].obuid;

      let prevDistVal = resourceArray[endpoint - 1].distance;
      if (!OdoVal) {
        distanceVal = 0;
        if (prevOdoVal) {
          OdoVal = prevOdoVal;
        } else {
          OdoVal = 0;
        }
      }
      if (OdoVal < initialOdo) {
        distanceVal = 0;
        let indVal = resourceArray.map(function (obj) { return obj.obuid; }).indexOf(currObuId);
        if (indVal === endpoint) {
          distanceVal = 0 + prevDistVal;
        } else {
          distanceVal = prevDistVal;
        }
      } else {
        distanceVal = OdoVal - initialOdo;
        if (distanceVal <= 0) {
          distanceVal = prevDistVal;
        }
      }
    }
    return distanceVal;
  }

  getEndveh = (dV) => {
    //debugger
    let propsData = dV.data.trace_points;

    if (propsData.length > 2) {
      let prevtracepoint = new window.google.maps.LatLng(propsData[propsData.length - 2].latitude, propsData[propsData.length - 2].longitude),
        lasttracepoint = new window.google.maps.LatLng(propsData[propsData.length - 1].latitude, propsData[propsData.length - 1].longitude);
      let heading = window.google.maps.geometry.spherical.computeHeading(prevtracepoint, lasttracepoint);

      if (heading === 0) {
        heading = 1;
      }

      let st = propsData[propsData.length - 1].vehstatus;

      let status = st === 0 ? 'RUNNING' : (st === 1 ? 'STOPPED' : (st === 2) ? 'IDLING' : 'TOWED');
      var vehStatus = status;

      vehStatus = ((vehStatus === null) || (vehStatus === undefined) || (vehStatus === "")) ? "STOPPED" : vehStatus;
      heading = ((heading === null) || (heading === undefined) || (heading === "")) ? 0 : heading;
      if (heading < 0)
        heading = heading + 360;

      var iconsVal = "";
      if ((heading >= 337.5) && (heading <= 22.5))
        iconsVal = '/images/Trace/Map_screen/' + vehStatus + '/01.png';
      else if ((heading >= 22.5) && (heading <= 67.5))
        iconsVal = '/images/Trace/Map_screen/' + vehStatus + '/02.png';
      else if ((heading >= 67.5) && (heading <= 112.5))
        iconsVal = '/images/Trace/Map_screen/' + vehStatus + '/03.png';
      else if ((heading >= 112.5) && (heading <= 157.5))
        iconsVal = '/images/Trace/Map_screen/' + vehStatus + '/04.png';
      else if ((heading >= 157.5) && (heading <= 202.5))
        iconsVal = '/images/Trace/Map_screen/' + vehStatus + '/05.png';
      else if ((heading >= 202.5) && (heading <= 247.5))
        iconsVal = '/images/Trace/Map_screen/' + vehStatus + '/06.png';
      else if ((heading >= 247.5) && (heading <= 292.5))
        iconsVal = '/images/Trace/Map_screen/' + vehStatus + '/07.png';
      else if ((heading >= 292.5) && (heading <= 337.5))
        iconsVal = '/images/Trace/Map_screen/' + vehStatus + '/08.png';
      else
        iconsVal = '/images/Trace/Map_screen/' + vehStatus + '/01.png';

      this.setState({
        endVehicle: iconsVal
      });
    }
  }

  updatedData = (dV) => {

    let propsData = dV.data.trace_points;
    var lngs = propsData.map((datas) => {
      return datas.longitude;
    });
    var lats = propsData.map((datas) => {
      return datas.latitude;
    });

    this.setState({
      zoomToMarkers: map => {
        //debugger
        if (map && window.location.href.toLowerCase().indexOf('/trace') > -1 && lngs && lats) {
          map.fitBounds({
            west: Math.min.apply(null, lngs),
            east: Math.max.apply(null, lngs),
            north: Math.min.apply(null, lats),
            south: Math.max.apply(null, lats),
          });
        }
      }
    });
  }

  delayedShowMarker = () => {
    setTimeout(() => { this.setState({ isMarkerShown: true }) }, 3000);
  }

  handleMarkerClick = () => {
    this.setState({ isMarkerShown: false });
    this.delayedShowMarker();
  }

  addDays = (date, days) => {
    let dateVal = date;
    dateVal.setDate(date.getDate() + days)
    return dateVal;
  }

  onChangeSpeed = (event) => {
    let zoom = 12;
    let speedValue = "2";
    if (event != "") {
      speedValue = event.target.value;
      if (speedValue == "1") {
        this.velocity = 0.05;
        this.intervalTime = 100;
      }
      else if (speedValue == "2") {
        this.velocity = 0.15;
        this.intervalTime = 100;
      }
      else if (speedValue == "4") {
        this.velocity = 2;
        this.intervalTime = 100;
        zoom = 10;
      }
      else if (speedValue == "5") {
        this.velocity = 3.5;
        this.intervalTime = 100;
        zoom = 10;
      }
      else {
        this.velocity = 0.75;
        this.intervalTime = 100;
        zoom = 12;

      }
    } else {
      this.velocity = 0.15;
      this.intervalTime = 100;
      speedValue = 2;

    }
    if (this.markerMoving == false) {
      this.setState({
        onSpeedSelectValue: speedValue,
      });
    }
    else {
      if (this.state.finalDistance >= 1000) {
        zoom = zoom - 2;
        
      }
      this.onClearInterval();
      this.setState({
        onSpeedSelectValue: speedValue,
        mapZoom: zoom
      }, () => {
        // this.moveObject();
        if (event != "") {
          let intervalId = setInterval(this.moveObject, this.intervalTime)
          this.setState({ intervalId: intervalId })
        }
      });
    }
  }

  calcDays = (date, days) => {
    let dateVal = date;
    dateVal.setDate(date.getDate() + (days));
    return dateVal;
  }

  handleChange = (date, name) => {
    this.setState({ [name]: date });
  };

  updateState = (newVal) => {
    let uDet = this.props.uD;
    if (newVal && uDet) {

      let newObj = {};
      newObj.platform = 'w';
      newObj.userId = uDet.userid;
      let tokensvalue = uDet.token;
      newObj.userRole = uDet.userrole;
      newObj.obuIdList = [newVal.obuId];
      const datas = postData(apilist.allvehicledata, newObj, tokensvalue);
      //const datas = postData('http://10.18.1.113:8000/api/map/ownVehicleList', newObj);
      datas.then((dataVals) => {
        if (dataVals.statuscode === 200) {
          let mVal = {};
          mVal.selectedVehicle = dataVals.data[0];
          uservehicledate = mVal;
          this.props.ctrs();

        } else {
          alert("Please check...");
          this.setState({
            datas: ''
          });
          return false;
        }
      });
    } else {
      console.log('Redux user Data or selected Val Error');
      return false;
    }
  };


  handleClick = (marker) => {
    this.setState({ selectedMarker: marker })
  }
  showgeofencbox = () => {
    if (this.state.enablegeofence === true) {
      this.setState({ enablegeofence: false })
    } else {
      this.setState({ enablegeofence: true })
    }

  }
  resetAll = () => {
    this.setState({
      startDate: null, endDate: null, startTime: null, endTime: null
    });
    this.onEndingplayRoute();
  }

  render() {
    let stateVal = this.state, checkVal = false, distance, fuelVal, kmpl, distance1, fuel1, defsVal;
    const {showMessage, showAlertBox, messageType} = this.state;
    let evVehicleParam = {}

    if (stateVal.datas.trace_points && stateVal.datas.trace_points.length > 1) {
      const lastData = stateVal.datas.trace_points[stateVal.datas.trace_points.length - 1];
      distance = lastData.distance_travelled.toFixed(1);
      fuelVal = lastData.fuelVal.toFixed(2);
      defsVal = lastData.defsVal.toFixed(2);

      if (this.props.ctr?.selectedVehicle?.isBS6 === 4) {
        // Check if lastData is defined and the required properties are numeric
         const isSocValid = typeof lastData?.battery_status === 'number';
         const isDteValid = typeof lastData?.distance_to_empty === 'number';
        
        //  let filter_max = stateVal.datas.filter(data => data.battery_out !== null)
        //  let filter_min = stateVal.datas.filter(data => data.battery_in !== null && data.ignition !== 3)
         
        //  // Find the object with the maximum battery_out value
        // let maxBatteryOutObject = filter_max.length >0 && filter_max.reduce((max, item) => 
        //   item.battery_out > max.battery_out ? item : max
        // );
        // let minBatteryOutObject = filter_max.length >0 && filter_max.reduce((max, item) => 
        //   item.battery_out < max.battery_out ? item : max
        // );

        // // Find the object with the minimum battery_in value
        // let maxBatteryInObject = filter_min.length >0 && filter_min.reduce((max, item) => 
        //   item.battery_in > max.battery_in ? item : max
        // );
        // let minBatteryInObject = filter_min.length >0 && filter_min.reduce((max, item) => 
        //   item.battery_in < max.battery_in ? item : max
        // );
        // let energyConsumed = 0
        // let BatteryOut = maxBatteryOutObject.battery_out - minBatteryOutObject.battery_out
        // let BatteryIn = maxBatteryInObject.battery_in - minBatteryInObject.battery_in
        // if(!isNaN(BatteryOut) && !isNaN(BatteryIn)){
        //   energyConsumed = BatteryOut - BatteryIn
        // }
        // evVehicleParam.energyConsumed = energyConsumed;
        evVehicleParam.energyConsumed = lastData.battery_consumed;
         
         // Assign values with toFixed(2) or 'N/A' if properties are not valid
        evVehicleParam.batteryStatus = isSocValid ? lastData.battery_status : '';
        evVehicleParam.dte = isDteValid ? lastData.distance_to_empty : '';
      }
      
      defsVal = parseFloat(defsVal)
      distance1 = parseFloat(distance)
      distance = parseFloat(distance)
      kmpl = 0;
      if (distance === 0 && fuelVal === 0) {
        kmpl = 0
      } else if (fuelVal > 0) {
        fuel1 = parseFloat(fuelVal)
        kmpl = distance1 / fuel1;
        kmpl = kmpl.toFixed(2);
      }
      (kmpl > 0 && kmpl <= 20) || this.props.ctr?.selectedVehicle?.isBS6 === 4 || (distance >= 0 && distance <= 0.5 && kmpl === 0) ? checkVal = true : checkVal = false;
    }

    return (
      <div className="">
        {this.state.loader ? <div className="Pageloading"> <img className="loadimg" src="/images/loader_1.gif" alt="iAlert" /></div> : ''}
        <div className="datacomponent">
          <div className="content">
            <DateComponent userDetails={stateVal.userDet} playbackFunction={this.animatedTrace} disableSpeed={this.state.disableSpeed} playbackrouteHide={this.playbackrouteHide} receiveProps={this.props} goTrace={this.traceView} screenName="trace" onChangeSpeed={this.onChangeSpeed} selectValue={this.state.onSpeedSelectValue} btnName={this.state.btnName} showMiniToolbar={this.props.showminimizedToolbar} downloadbtn={this.props.downloadbtn} updateExcel={this.props.updateExcel} downloadbutton={this.props.downloadbutton} />
          </div>
        </div>
        <div className={this.props.showminimizedToolbar ? "mapCont map-exp-ht" : "mapCont"}>
          <TracePopup
            checkVal={checkVal}
            distance={distance}
            fuelVal={fuelVal}
            kmpl={kmpl}
            defsVal={defsVal}
            showDEF={showDEF}
            runningTime={this.state.runningtimecnt}
            idlingTime={this.state.idlingtimecnt}
            haltTime={this.state.alttimecnt}
            chargingTime={this.state.chargingTime}
            evVehicleParam={evVehicleParam}
            selectedVehicle={this.props.ctr.selectedVehicle}
          />
          {stateVal.userDet !== "AL EMPLOYEE" && stateVal.userDet !== "AL USER" && stateVal.userDet !== "AL SME" ? stateVal.enablegeofencetoggle === true ? <div className="customPopup_label_trace">
            <input className="cursorpointer" type="checkbox" onChange={() => this.showgeofencbox()}></input>
            <label className="txt-align-poi">Show Geofence</label>

          </div> : '' : ''}

          {
            stateVal.datas !== '' ? <MyMapComponent isMarkerShown={stateVal.isMarkerShown} mapCenter={stateVal.mapCenter} mapZoom={stateVal.mapZoom} showNormalPolyline={stateVal.showNormalPolyline} progress={stateVal.progress} finalDst={this.state.finalDistance} progressVl={this.state.progressValue} getFormatedData={this.getFormatedData} onMarkerClick={this.handleMarkerClick} dataVals={stateVal.datas.trace_points} infoWindowClose={this.handleToggleClose} infoWindowOpen={this.handleToggleOpen} infoWindowState={stateVal.isOpen} selectedMarker={stateVal.selectedMarker} onClick={this.handleClick} onProgressCompleted={this.state.onProgressCompleted} currentMarker={this.props.ctr.selectedVehicle} vehStatus={stateVal.vehStatus} zoomToMarkers={stateVal.zoomToMarkers} endVehicle={stateVal.endVehicle} circledata={stateVal.circlegeofencedata} polygondata={stateVal.polygeofencedata} geofencehideandshow={stateVal.enablegeofence} refcircle={this.circle} selectedVehicle = {this.props.ctr.selectedVehicle} />
              : <MyMapComponent circledata={stateVal.circlegeofencedata} mapCenter={stateVal.mapCenter} zoom={stateVal.zoom} polygondata={stateVal.polygeofencedata} geofencehideandshow={stateVal.enablegeofence} />
          }
        </div>
        <AlertBox show={showAlertBox} showMessage={showMessage} 
                    click={this.hideModal} messageType={messageType}></AlertBox>
      </div>
    )
  }
}
const mapStateToprops = state => {
  return {
    ctr: state.vehicledata.results,
    uD: state.userdata.id
  };
}

const mapdispatchToprops = dispatch => {
  return {
    ctrs: () => dispatch({ type: 'VehicleDatas', value: uservehicledate })
  };
}

export { uservehicledate };

export default connect(mapStateToprops, mapdispatchToprops)(TraceScreen);
