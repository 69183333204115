import React, { useState } from "react";
import { useEffect } from "react";
import "./ToggleSwitch.scss";
const ToggleSwitchComponent = ({
  isOptionChecked,
  optionChange,
  rightLabelId,
  rightLabel,
  leftLabelId,
  leftLabel,
  optionName,
  type,
}) => {
  const [isFirstSelected, setIsFirstSelected] = useState(true);
  useEffect(() => {
    setIsFirstSelected(isOptionChecked);
  }, [isOptionChecked]);

  function handleChange() {
    setIsFirstSelected((prev) => !prev);
    if (optionChange) {
      optionChange(type, !isOptionChecked);
    }
  }

  return (
    <div className="switch-field">
      <input
        type="radio"
        id={leftLabelId}
        name={optionName}
        value={isFirstSelected}
        onChange={() => handleChange()}
        checked={isFirstSelected}
      />
      <label htmlFor={leftLabelId}>{leftLabel}</label>

      <input
        type="radio"
        id={rightLabelId}
        name={optionName}
        onChange={() => handleChange()}
        value={!isFirstSelected}
        checked={!isFirstSelected}
      />
      <label htmlFor={rightLabelId}>{rightLabel}</label>
    </div>
  );
};

export default ToggleSwitchComponent;
