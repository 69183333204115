import React from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import './TrendCompare.scss';
import { connect } from 'react-redux';
import { postData } from '../../Util';
import * as apilist from '../../apiList';
import CompDataComponent from './CompDataComponent';
import { findMinMax } from '../../Util/CommonUtils';
import { getChartLabel } from './TrendCompareUtils';
import { getGASVehicleUnit } from "../../Util/VehicleDashUtils";

var resertopt = '';
var resetonly = '';
function Divelement(propes) {
	return (
		<div className="col-md-12">
			<div class="col" id="chartLegend" style={{ width: "100%", height: "30px" }}></div>
			<div id="chart_div" style={{ width: "100%", height: "400px" }}></div>
		</div>)
}

class trendcompare extends React.Component {

	constructor(props) {
		super(props);
		// console.log(this.props);
		this.child = React.createRef();

		this.state = {
			startDate: null,
			endDate: null,
			startTime: null,
			endTime: null,
			userDet: this.props.uD,
			trends: [],
			vitalarray: [],
			vitalvalues: 0,
			loader: false,
		}
		this.chart = "";
		this.root = "";
		this.xAxes = "";
	}
	componentDidMount() {
		am4core.useTheme(am4themes_animated);
		am4core.addLicense("ch-custom-attribution");
	}

	UNSAFE_componentWillMount () {
		this.vitalselect();
		// this.vitalselect('callFromParent');
	}

	// vitalselect = (callFrom) => {
	vitalselect = () => {
		this.props.vitallistarray(this.state.vitalarray);
		if (this.props.ctr.selectedVehicle) {

			let selectedVeh = this.props.ctr.selectedVehicle.currentInfo;
			let propDate = new Date(selectedVeh.eventUTC);
			let toDate = new Date(propDate);
			let fromDate = new Date(selectedVeh.eventUTC).setHours(propDate.getHours() - 1);

			this.setState({
				startDate: fromDate,
				endDate: toDate,
				startTime: fromDate,
				endTime: toDate
			});

			let vitalkeys;
			let vitalobj = {}
			vitalobj.model = this.props.ctr.selectedVehicle.vehicleType;
			vitalobj.is_bs6 = this.props.ctr.selectedVehicle.isBS6;
			const adBlueTankCap = this.props.ctr.selectedVehicle.adBlueTankCap;
			const adBlueTankCapParsed = !isNaN(adBlueTankCap) && parseInt(adBlueTankCap) > 0 ? adBlueTankCap : "";
			vitalobj.ad_blue_cap = adBlueTankCapParsed;
			vitalobj.platform = "w";

			let vehtoken = this.props.uD.token;
			const vitaldatas = postData(apilist.getVitals, vitalobj, vehtoken);
			vitaldatas.then((vitals) => {
				vitalkeys = (Object.keys(vitals.data))
				let vitalArray = [];
				this.items = vitalkeys.forEach((item) => {
					let vitalObj = {};
					if (vitalobj.is_bs6) {
						if (item === "Air Pressure") {
							vitalObj['label'] = 'Air Pressure Front';
							vitalObj['vitalVal'] = vitals.data[item][0];
							vitalArray.push(vitalObj);
							let vitalObj1 = {};
							vitalObj1['label'] = 'Air Pressure Rear';
							vitalObj1['vitalVal'] = vitals.data[item][1];
							vitalArray.push(vitalObj1)
						}
						else {
							vitalObj['label'] = item;
							vitalObj['vitalVal'] = vitals.data[item][0];
							vitalArray.push(vitalObj)
						}
					}
					else {
						vitalObj['label'] = item;
						vitalObj['vitalVal'] = vitals.data[item][0];
						vitalArray.push(vitalObj)
					}

				});
				// console.log(vitalArray);
				this.setState({
					vitalslistvalue: vitals.data,
					vitalarray: vitalArray
				}
				)
				// console.log(vitalkeys);
			});
		}
	}

	trendCompareView = (statVals) => {
		this.setState({ loader: true },
			() => {
				if (statVals !== null) {
					this.vitalselect();
					let selectedVeh = this.props.ctr.selectedVehicle.currentInfo;
					let sD = statVals.fromDate,
						eD = statVals.toDate,
						sT = statVals.fromTime,
						eT = statVals.toTime;
					let freq = statVals.frequency;

					let dataObj = {};
					dataObj.freq = freq;
					dataObj.obu_id = selectedVeh.obuId;
					dataObj.from = sD + " " + sT;
					dataObj.to = eD + " " + eT;
					dataObj.vitals = statVals.vitals;
					dataObj.vehicleType = this.props.ctr.selectedVehicle.vehicleType;
					dataObj.is_bs6 = this.props.ctr.selectedVehicle.isBS6;
					dataObj.ad_blue_cap = this.props.ctr.selectedVehicle.adBlueTankCap;
					dataObj.fuel_tank_cap = this.props.ctr.selectedVehicle.fuelTankCap;
					dataObj.vin_number = selectedVeh.vinNumber;
					let vehtoken = this.props.uD.token;
					dataObj.platform = "w"
					const datas = postData(apilist.compTrendFn, dataObj, vehtoken);
					datas.then((dataVals) => {

						if (dataVals.data !== null && dataVals.data.length > 0) {
							let listofvitals = (Object.keys(dataVals.data[0]))
							if ((dataVals.data.length) !== 0) {
								this.setState({
									vitalvalues: listofvitals
								})
							} else {
								resertopt = "notreset";
								this.setState({
									vitalvalues: ""
								})
							}

							this.props.selecteddate(dataObj);
							let chartlist = {};

							let compList = dataVals.data
							if (compList.length !== 0) {

								// console.log(compList);
								document.getElementById('chartLegend').style.visibility = "visible";
								this.chart = am4core.create("chart_div", am4charts.XYChart);
								chartlist["chart"] = this.chart
								chartlist["chart"].data = compList;

								var categoryAxis = chartlist["chart"].xAxes.push(new am4charts.CategoryAxis());
								categoryAxis.dataFields.category = "LOCAL_TIMESTAMP";
								categoryAxis.renderer.minGridDistance = 200;
								categoryAxis.title.text = "Timestamp";
								categoryAxis.lineColor = "#fff"
								categoryAxis.fontSize = "12px";

								let leftColumn = getChartLabel(listofvitals[1], this.props.ctr.selectedVehicle.vehicleType);
								let rightColumn = getChartLabel(listofvitals[2], this.props.ctr.selectedVehicle.vehicleType);

								var topContainer = chartlist["chart"].chartContainer.createChild(am4core.Container);
								topContainer.layout = "absolute";
								topContainer.toBack();
								topContainer.paddingBottom = 15;
								topContainer.width = am4core.percent(100);

								var dateTitle = topContainer.createChild(am4core.Label);
								dateTitle.text = rightColumn['columnlabel'];
								dateTitle.fontWeight = 600;
								dateTitle.align = "left";

								dateTitle = topContainer.createChild(am4core.Label);
								dateTitle.text = leftColumn['columnlabel'];
								dateTitle.fontWeight = 600;
								dateTitle.align = "right";
								const { minY } = findMinMax([...compList], leftColumn['columnvalue']);
								var valueAxis = chartlist["chart"].yAxes.push(new am4charts.ValueAxis());
								valueAxis.title.text = leftColumn['columnunits'];
								valueAxis.fontSize = "12px";
								valueAxis.renderer.opposite = true
								valueAxis.min = minY;
								const { minX} = findMinMax([...compList], rightColumn['columnvalue']);
								var valueAxis2 = chartlist["chart"].yAxes.push(new am4charts.ValueAxis());
								valueAxis2.title.text = rightColumn['columnunits'];
								valueAxis2.fontSize = "12px";
								valueAxis2.min = minX;

								var series = chartlist["chart"].series.push(new am4charts.LineSeries());
								series.dataFields.valueY = leftColumn['columnvalue'];
								series.dataFields.categoryX = "LOCAL_TIMESTAMP";
								series.name = leftColumn['columnlabel'];
								series.tooltipText = "{name}: [bold]{valueY}[/]";
								series.minBulletDistance = 15;
								series.strokeWidth = 2;
								series.stroke = am4core.color("#1045ad");


								var series1 = chartlist["chart"].series.push(new am4charts.LineSeries());
								series1.dataFields.valueY = rightColumn['columnvalue'];
								series1.dataFields.categoryX = "LOCAL_TIMESTAMP";
								series1.name = rightColumn['columnlabel'];
								series1.tooltipText = "{name}: [bold]{valueY}[/]";
								series1.minBulletDistance = 15;
								series1.strokeWidth = 2;
								series1.stroke = am4core.color("#10ad12");
								series1.yAxis = valueAxis2;
								series1.sequencedInterpolation = true;
								series1.defaultState.transitionDuration = 1000;
								series1.stroke = chartlist["chart"].colors.getIndex(9);
								series1.fill = series1.stroke;
								series1.fillOpacity = 0.0;
								var colorSet = new am4core.ColorSet();
								colorSet.saturation = 0.4;

								// Add cursor
								chartlist["chart"].cursor = new am4charts.XYCursor();

								// Add simple vertical scrollbar
								//chart.scrollbarY = new am4core.Scrollbar();

								// Add horizotal scrollbar with preview
								var scrollbarX = new am4charts.XYChartScrollbar();
								scrollbarX.series.push(series);
								scrollbarX.series.push(series1);

								chartlist["chart"].scrollbarX = scrollbarX;
								chartlist["chart"].scrollbarX.parent = chartlist["chart"].bottomAxesContainer;
								chartlist["chart"].scrollbarX.background.fill = am4core.color("#add3ef");
								chartlist["chart"].scrollbarX.thumb.background.fill = am4core.color("#017acd");
								chartlist["chart"].scrollbarX.startGrip.background.fill = am4core.color("#017acd");
								chartlist["chart"].scrollbarX.endGrip.background.fill = am4core.color("#017acd");
								chartlist["chart"].scrollbarX.stroke = am4core.color("#017acd");
								chartlist["chart"].scrollbarX.minWidth = 50;

								let bullet = series.bullets.push(new am4charts.CircleBullet());
								bullet.circle.strokeWidth = 2;
								bullet.circle.radius = 2;
								bullet.circle.fill = am4core.color("#fff");

								let bullet1 = series1.bullets.push(new am4charts.CircleBullet());
								bullet1.circle.strokeWidth = 2;
								bullet1.circle.radius = 2;
								bullet1.circle.fill = am4core.color("#fff");

								let bullethover = bullet.states.create("hover");
								bullethover.properties.scale = 1.3;

								let bullethover1 = bullet1.states.create("hover");
								bullethover1.properties.scale = 1.3;

								/* Create legend */
								this.chart.legend = new am4charts.Legend();

								/* Create a separate container to put legend in */
								var legendContainer = am4core.create("chartLegend", am4core.Container);
								legendContainer.width = am4core.percent(100);
								legendContainer.height = am4core.percent(100);
								this.chart.legend.parent = legendContainer;
								this.chart.legend.layout = "horizontal";
								this.chart.legend.contentAlign = "center";

							} else {
								console.log(chartlist)
							}
							this.setState({ loader: false });
							// console.log(chartlist);
						} else {
							alert("No Vital Data are Available for the selected Date Range")
							const remove_element = document.getElementById("chart_div");
							remove_element.innerHTML = '';
							document.getElementById('chartLegend').style.visibility = "hidden";
							const el = document.createElement('h2');
							el.setAttribute('id', 'my-id');
							el.setAttribute('class', 'text-center');
							el.textContent = 'No Data Available';
							const box = document.getElementById('chart_div');
							box.appendChild(el);
							this.setState({ loader: false });
						}
					});
				} else {
					resetonly = "active";
					this.setState({ vitalvalues: "", vitalarray: [] })
					var vitalkeyss = (Object.keys(this.state.vitalslistvalue))
					for (let i = 0; i < vitalkeyss.length; i++) {
						document.getElementById(vitalkeyss[i]).checked = false;
					}
				}
			});
	}

	dateDiff = (earlierDate, laterDate) => {
		var oDiff = {};

		//  Calculate Differences
		//  -------------------------------------------------------------------  //
		var nTotalDiff = laterDate.getTime() - earlierDate.getTime();

		oDiff.days = Math.round(nTotalDiff / 1000 / 60 / 60 / 24);
		//		    nTotalDiff -= oDiff.days * 1000 * 60 * 60 * 24;

		oDiff.hours = Math.round(nTotalDiff / 1000 / 60 / 60);
		//		    nTotalDiff -= oDiff.hours * 1000 * 60 * 60;

		oDiff.minutes = Math.round(nTotalDiff / 1000 / 60);
		//		    nTotalDiff -= oDiff.minutes * 1000 * 60;

		oDiff.seconds = Math.round(nTotalDiff / 1000);
		//  -------------------------------------------------------------------  //

		//  Format Duration
		//  -------------------------------------------------------------------  //
		//  Format Hours
		var hourtext = '00';
		if (oDiff.days > 0) { hourtext = String(oDiff.days); }
		if (hourtext.length === 1) { hourtext = '0' + hourtext };

		//  Format Minutes
		var mintext = '00';
		if (oDiff.minutes > 0) { mintext = String(oDiff.minutes); }
		if (mintext.length === 1) { mintext = '0' + mintext };

		//  Format Seconds
		var sectext = '00';
		if (oDiff.seconds > 0) { sectext = String(oDiff.seconds); }
		if (sectext.length === 1) { sectext = '0' + sectext };

		//  Set Duration
		var sDuration = hourtext + ':' + mintext + ':' + sectext;
		oDiff.duration = sDuration;
		//  -------------------------------------------------------------------  //

		return oDiff;
	}

	getFrequencyunified = (daycount) => {
		var freq = daycount;
		daycount.days = Math.abs(daycount.days)
		var fr = "2.5";
		if (freq.days > 3 && freq.days <= 5) {
			fr = "15";
		} else if (freq.days > 5 && freq.days <= 10) {
			fr = "25";
		} else if (freq.days > 10 && freq.days <= 15) {
			fr = "40";
		} else if (freq.days > 15 && freq.days <= 30) {
			fr = "50";
		} else if (freq.days > 30 && freq.days <= 45) {
			fr = "75";
		} else if (freq.days > 45 && freq.days <= 60) {
			fr = "100";
		} else if (freq.days > 60 && freq.days <= 90) {
			fr = "125";
		} else if (freq.days > 90 && freq.days <= 120) {
			fr = "150";
		} else if (freq.days > 120 && freq.days <= 150) {
			fr = "225";
		} else if (freq.days > 150 && freq.days <= 180) {
			fr = "300";
		}
		return fr;
	}

	handleMarkerClick = () => {
		this.setState({ isMarkerShown: false })
		this.delayedShowMarker()
	}

	addDays = (date, days) => {
		let dateVal = date;
		dateVal.setDate(date.getDate() + days)
		return dateVal;
	}

	calcDays = (date, days) => {
		let dateVal = date;
		dateVal.setDate(date.getDate() + (days));
		return dateVal;
	}

	handleChange = (date, name) => {
		this.setState({
			[name]: date
		});
	};

	resetChart = () => {
		if (this.chart) {
			for (let i = 0; i <= 1; i++) {
				this.chart.dispose();
			}
		}
	}

	render() {
		return (
			<div>
				{this.state.loader ? <div className="Pageloading"> <img className="loadimg" src="/images/loader_1.gif" alt="iAlert" /></div> : ''}
				<div className="datacomponent">
					<div className="content">
						<CompDataComponent
							vitals={this.state.vitalarray}
							userDetails={this.state.userDet}
							receiveProps={this.props}
							vitalselect={this.vitalselect}
							selectedvitals={this.state.vitalarray}
							resetChart={this.resetChart}
							goTrace={this.trendCompareView}
							onRef={ref => (this.child = ref)}
						/>
					</div>
				</div>
				<div className="borderline_tnd"></div>
				<div className="container-wrapper">
					<div className="row ">
						<Divelement val={this.state.vitalvalues} />
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToprops = state => {
	return {
		ctr: state.vehicledata.results,
		uD: state.userdata.id
	};
}

export default connect(mapStateToprops, null)(trendcompare)