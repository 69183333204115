import React from 'react'
import './Questions.scss'
import { useSelector } from "react-redux";
import { useEffect } from 'react';
import { getVinNumbers, getVinData } from '../API/useApi'
import scroll from '../Scroll'
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import BarChartIcon from '@material-ui/icons/BarChart';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import FavoriteIcon from "@material-ui/icons/Favorite";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-force-tabpanel-${index}`}
        aria-labelledby={`scrollable-force-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}

function a11yProps(index) {
    return {
      id: `scrollable-force-tab-${index}`,
      "aria-controls": `scrollable-force-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      width: "100%",
      backgroundColor: theme.palette.background.paper,
    },
}));

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const Questions = (props) => {
    const { setState } = props

    const classes = useStyles();
    const [value, setValue] = React.useState(props.state.expanded);
    const ctr = useSelector((state) => state.userdata.id);

    const handleChange_Nav = (event, new_val) => {
        if(value === new_val){
            setValue(false);
        }else{
            setValue(new_val);
        }
        if(new_val >=0){
            setState(state => ({ ...state, index: new_val, yes_no_btn_clk: false, fleet_continue: false}))
        }
        setState(state => ({ ...state, result: false}))
      };


    useEffect(() => {
        if(props.state.vin_number.length <= 0){
            fetchData();
        }
    }, []);

    useEffect(() => {
        if(value === false){
            setState(state => ({ ...state, expanded: false}))
        }
    }, [value]);

    useEffect(() => {
        setValue(props.state.expanded)
    }, [props.state.expanded]);

    const fetchData = async () => {
        try {
          const response = await getVinNumbers(ctr);
          setState(state => ({ ...state, vin_number: response.data}))
        } catch (error) {
          console.error(error);
        }
    };

    const handleClick = (question, title, api, column) => {
        setValue(false);
        const { state, actionProvider } = props;
        const { yes_no_btn_clk } = state;

        if(title === 'Vehicle Running' || title === 'Idling' || title === 'Night Driving' || title === 'Neutral Driving' ){
            title = title + ' Duration '
        }
        let parent_title = props.state.questions[props.state.index].title
        props.actionProvider.titleHandler(props, title, parent_title);
    
        const setStateAndAction = (question, title, api, column, handler) => {
            setState(state => ({ ...state, selected_ques: question, title, result: false, q_clicked: true }));
            handler(question, title, api, column, '', parent_title);
        };
    
        if (yes_no_btn_clk) {
            if (api !== 'f_report' && api !== 'gearutilreport' && api !== 'ownVehicleList') {
                setStateAndAction(question, title, api, column, actionProvider.DateHandler);
            } else if (api === 'ownVehicleList'){
                setStateAndAction(question, title, api, column, actionProvider.VinHandler);
            }else {
                if (question === 'top_bottom_fuel_cons' || question === 'top_bottom_mileage') {
                    setStateAndAction(question, title, api, column, actionProvider.ModelHandler);
                } else {
                    // setStateAndAction(question, title, api, column, getVINData);
                    let obj = {'question': question, 'title': title, 'api': api, 'column': column, 'model': undefined}
                    setState(state => ({ ...state, loader: false, result: true, q_clicked: false, yes_no_btn_clk: false, fleet_continue: true}))
                    props.actionProvider.resultHandler2(obj, props.state.vinData, props.state.startDate, props.state.endDate, title, parent_title);
                }
            }
        } else {
            if ((api === 'gearutilreport' || api === 'f_report') && !(question === 'top_bottom_fuel_cons' || question === 'top_bottom_mileage')) {
                setStateAndAction(question, title, api, column, actionProvider.DateHandler);
            } else if (question === 'top_bottom_fuel_cons' || question === 'top_bottom_mileage') {
                setStateAndAction(question, title, api, column, actionProvider.ModelHandler);
            } else {
                setStateAndAction(question, title, api, column, actionProvider.VinHandler);
            }
        }
        // setState(state => ({ ...state, expanded: false}))
        scroll();
    };
    

    const getVINData = async (question, title, api, column, title_empty='', parent_title) => {
        let obj = {'question': question, 'title': title, 'api': api, 'column': column, 'model': undefined}
        try {
            setState(state => ({ ...state, loader: true, vinData: []}))
            const response = await getVinData(ctr, props, props.state.startDate, props.state.endDate, api);
            if(response.statuscode === 200 ){
                setState(state => ({ ...state, vinData: response.data, loader: false, result: true, q_clicked: false, yes_no_btn_clk: false, fleet_continue: true}))
                props.actionProvider.resultHandler2(obj, response.data, props.state.startDate, props.state.endDate, title, parent_title);

            }
            else{
                setState(state => ({ ...state, vinData: [], loader: false, result: true, q_clicked: false, yes_no_btn_clk: false, fleet_continue: true}))
                props.actionProvider.resultHandler2(obj, response.data, props.state.startDate, props.state.endDate, title, parent_title);
            }
        } catch (error) {
            console.error(error);
            setState(state => ({ ...state, vinData: [], loader: false, result: true, q_clicked: false, yes_no_btn_clk: false, fleet_continue: true}))
            props.actionProvider.resultHandler2(obj, [], props.state.startDate, props.state.endDate, title, parent_title);
        }
        scroll();
    };

    const iconRender = (key) => {
        if(key === 'fleet_insights'){
            return(
                // <BarChartIcon/>
                <>
                    <div className='d-flex'>
                        <img src={value === 1 ? '../images/bar-gr.png' : '../images/bar-gr.png'}></img>
                    </div>
                </>
            )
        }else if(key === 'current_location'){
            return(
                // <LocationOnIcon/>
                <>
                    <div className='d-flex'>
                        <img src={value === 2 ? '../images/location-gr.png' : '../images/location-gr.png'}></img>
                    </div>
                </>
            )
        }
        else if(key === 'top_bottom_three_performance'){
            return(
                <>
                    <div className='d-flex'>
                        <img src={value === 0 ? '../images/top_bottom.png' : '../images/top_bottom.png'}></img>
                    </div>
                </>
            )
        }
    }

    const titleRender = (key, title) => {
        if(key === 'fleet_insights'){
            return(
                <div>
                    <p>Fleet</p>
                    <p>Insights</p>
                </div>
            )
        }else{
            return(
                <span>{title}</span>
            )
        }
    }

    const renderQuestions = () => {
            return(
                <>

                    <AppBar position="static" color="default">
                        <Tabs
                            value={value}
                            onChange={handleChange_Nav}
                            variant="fullWidth"
                            indicatorColor="primary"
                            textColor="primary"
                            aria-label="scrollable force tabs example"
                        >
                        
                        {props.questions.map((question, index) => {
                                return (
                                    <Tab key={index} label={titleRender(question.key, question.title)} icon={ iconRender(question.key) } {...a11yProps(index)} />
                                )
                            })
                        }
                        
                        </Tabs>
                    </AppBar>
                    <></>
                    {props.questions.map((question, index) => {
                         if (question.key === 'top_bottom_three_performance') {
                            return (
                                <div key={index}>
                                    <TabPanel value={value} index={index}>
                                        <span className='top-bottom-veh-note'>Top 3 & Bottom 3 Vehicles</span>
                                        {question.questions.map((data, i) => (
                                            <li key={i} onClick={(e) => handleClick(data.key, data.title, data.api, data.column)}>{data.title}{data.column !=='dis_cov' ? <span className='bot-astrick'>*</span> : ''}</li>
                                        ))}
                                        <span className='bot-astrick-note'>* Vehicles travelled for more than 100 kms in a given duration considered</span>
                                    </TabPanel>
                                </div>
                            );
                        } 
                        else {
                            if (question.key === "fleet_insights") {
                                return (
                                    <TabPanel key={index} value={value} index={index}>
                                        {question.questions.map((data, i) => (
                                            <li key={i} onClick={(e) => handleClick(data.key, data.title, data.api, data.column)}>{data.title}</li>
                                        ))}
                                    </TabPanel>
                                );
                            }
                            else{
                                return (
                                    <TabPanel key={index} value={value} index={index}>
                                        <li key={index} onClick={(e) => handleClick(question.key, question.title, question.api, question.column)}>{question.title}</li>
                                    </TabPanel>
                                );
                            }
                            
                        }
                        })
                    }
                </>
            )
 
    }

    return (
        <div className='question-dv'>
            <div className={classes.root}>
                {renderQuestions()}
            </div>
        </div>
    )
}

export default Questions