//API
export const apiurl = process.env.REACT_APP_API_URL;
export const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
export const GOOGLE_SEARCH_API_KEY = process.env.REACT_APP_GOOGLE_SEARCH_API_KEY;
export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;
export const REACT_APP_DATABASE_URL = process.env.REACT_APP_DATABASE_URL;
export const REACT_APP_APP_ID = process.env.REACT_APP_APP_ID;
export const REACT_APP_AUTH_DOMAIN = process.env.REACT_APP_AUTH_DOMAIN;
export const REACT_APP_PROJECT_ID = process.env.REACT_APP_PROJECT_ID;
export const REACT_APP_STORAGE_BUCKET = process.env.REACT_APP_STORAGE_BUCKET;
export const REACT_APP_MESSAGING_SENDER_ID = process.env.REACT_APP_MESSAGING_SENDER_ID;
export const PSB_USER_LOGINID = process.env.REACT_APP_PSB_LOGIN_ID;
export const capchaimg =apiurl+'/map/generateCaptchaEntry';
export const loginApi = apiurl+'/map/userInformation';
export const alEmpLoginApi = apiurl+'/aluserlogin/alemplogin';
export const vehiclecount = apiurl+'/landing/vehicleInfo';
export const alertCount =  apiurl+'/alert/getSeverityCount';
export const getUserVinDetails = apiurl+'/userRemap/getUserVinDetails';
export const getUserMobileDetails = apiurl+'/userRemap/getUserMobileDetails';
export const createUserRemap = apiurl+'/userRemap/createUserRemap';
export const getCustomerRemapDetails = apiurl+'/userRemap/getCustomerRemapDetails';
export const downloadUserRemapFile = apiurl+'/userRemap/downloadUserRemapFile';
export const alertCountdealer =  apiurl+'/alert/getSeverityCountDealer';
export const alertCountdealeribbon =  apiurl+'/alert/getSeverityRibbonCountDealer';
export const allvehicledata =  apiurl+'/map/ownVehicleList';
export const allvehicledatadealer =  apiurl+'/map/ownVehicleListDealer';
export const getVitals =  apiurl+'/trends/getVitals';
export const getTrends =  apiurl+'/trends/getTrend';
export const getFMlist = apiurl+'/fleetmanlist/fleetmanagerlist';// To Load Manager List
export const getFMgrouplist = apiurl+'/fleetmanlist/fleetManagerVehicleList';// To load Managers vehicles
export const getvalidateform = apiurl+'/fleetmanagerapp/fleetmanager';
export const getassignVehicle = apiurl+'/fleetmanlist/assignFMVehicles'; //To Assign the Unmapped vehicles
export const getunmappedveh = apiurl+'/fleetmanlist/getFleetMangerUnmappedVehicle';//To load the unmapped vehicles
export const getUnassignVehicle = apiurl+'/fleetmanlist/unassignFMVehicles';
export const userProfileData =  apiurl+'/userprofile/getuserdetails';
export const getFeedbackList =  apiurl+'/landing/getPackagePopup';
export const insertFeedback = apiurl+'/landing/insertUserPakageFeedback';
export const alUserProfileData =  apiurl+'/aluserlogin/getuserdata';

export const getUserProfileData =  apiurl+'/userprofile/getuserdetails';

// export const userPOADetails = apiurl+'/userprofile/getuserdetails';

export const updateUserProfileData =  apiurl+'/userprofile/edituserDetails';
export const updateRegistrationNumber =  apiurl+'/vehiclemaint/updateRegistrationNumber';
export const userAddressData = apiurl+'/userprofile/getStateandDistrict';
export const getTrip = apiurl + "/FMS";
export const getFence = apiurl+'/alert';
export const verifyemail = apiurl+'/userprofile/verifyemail';
export const otpTest = apiurl+'/landing/otpTest';
export const otpPost = apiurl+'/landing/postotp';
export const forgotpass = apiurl+'/forgotpass/userid';
export const forgotpasstest = apiurl+'/forgotpass/otp';
export const userInfo = apiurl+'/landing/userInfo';
export const vehicleInfo = apiurl+'/FMS/vehicleInfo';
export const getTrace = apiurl+'/trends/getTrace';
export const TripDeviation = apiurl+'/FMS/trip_deviation_data';
export const getDealer = apiurl+'/findDealer/getFindDealer'; //find dealer
export const getDealerupdated = apiurl+'/findDealer/getDealershipList'; //find dealer
export const finddealerkms = apiurl+'/findDealer/getFindDealerDistance'; //find dealer
export const getMenu = apiurl+'/menuapp/menu'; // menu
export const downloadRawdata = apiurl+'/rawdatadownloadapp/rawdatadownload'; // raw data
export const vehicleAlertdate = apiurl+'/alert/vehicleAlertStatus';
export const vehicleAlertdatedealer = apiurl+'/alert/vehicleAlertStatusDealer';
export const disclaimerupdate = apiurl+'/forgotpass/disclaimerupdate';  // updating disclaimer for firttime login
export const userpassreset = apiurl+'/forgotpass/userpassreset';
export const sarathyTripList = apiurl + '/sarathitrip/sarathiTripList';
export const sarathyTripCount = apiurl + '/sarathitrip/tripCount';
export const sarathySingleTrip = apiurl + '/sarathitrip/sarathiSingleTrip';
export const sarathyTripCreate = apiurl + '/sarathitrip/tripCreation';
export const sarathyTripRegnNo = apiurl + '/sarathitrip/getRegnNo';
export const sarathyTripDriversList = apiurl + '/sarathitrip/sarathiDriversList';
export const sarathyTripDelete = apiurl + '/sarathitrip/tripDelete';
export const sarathyTripUpdate = apiurl + '/sarathitrip/tripUpdate';
export const sarathyTripStart = apiurl + '/sarathitrip/startTrip';
export const sarathyTripEnd = apiurl + '/sarathitrip/startEnd';
export const sarathyTripCancel = apiurl + '/sarathitrip/cancelTrip';
export const sarathyTripFavouriteLocation = apiurl + '/sarathitrip/favouriteLocation';
export const sarathyTripFavouriteLocationList = apiurl + '/sarathitrip/favouriteLocationList';
export const sarathyTripGetTopDrivers = apiurl + '/sarathitrip/getTopDriversRouteBy';
export const sararthiTripCreationTemplate = apiurl + '/sarathitrip/SarthiTripCreationTemplate';
export const sarathiTripBulkUpload= apiurl + '/sarathitrip/SarathiTripCreationBulkUpload';
export const showVehiclemaint = apiurl+'/vehiclemaint/showVehiclemaint';
export const unassignvehicles = apiurl+'/fleetgroup/updateAssignedfleetGroup';
export const registrationupdate = apiurl+'/registration/update';
export const assignvehicles = apiurl+'/fleetgroup/updateAssignedfleetGroup';
export const creategroup = apiurl+'/fleetgroup/createFleetGroup';
export const odoupdate = apiurl+'/odo/odoupdate';
export const unassignedVinList = apiurl+'/fleetgroup/fleetGroupAssignedVehicles';
export const myfleetform = apiurl+'/vehiclemaint/createfleetvehicles';
export const assignedvinlist = apiurl+'/fleetgroup/unAssignfleetGroupVehiclelist';
export const myfleetvindata = apiurl+'/vehiclemaint/viewfleetvehbyvin';
export const loadlistviewdetails = apiurl+'/listviewapp/loadlistviewdetails';
export const listviewcustupdate = apiurl+'/listviewapp/listviewcustupdate';
export const fleetdriverlist = apiurl+'/FMS/driverList';
export const driverValidateform = apiurl+'/FMS/driverCreate';
export const showGeofence = apiurl+'/geofence/showGeofence';
export const showAssignedVehicles = apiurl+'/geofence/showAssignedVehicles';
//export const createGeofence = apiurl+'/geofence/create';
export const getRegn = apiurl+'/geofence/getRegn';
export const assignGeofence = apiurl+'/geofence/assignGeofence';
export const unassignGeofence = apiurl+'/geofence/unassignGeofence';
export const singleVehicleAlerts =apiurl+'/alert/vehicleAlertDetailsForVin';
export const ticketSupportUserDetails =apiurl+'/supportticket/getuserdetails';
export const ticketSupportCategory =apiurl+'/supportticket/showcategory';
export const statusList =apiurl+'/supportticket/ticketStatusList';
export const ticketSupportSubCategory =apiurl+'/supportticket/subcategoryname';
export const ticketSupportSubmitData =apiurl+'/supportticket/savecallsupport';
export const ticketSupportListTicket =apiurl+'/supportticket/listcustomerticketstatus';
export const updatesupportticket =apiurl+'/supportticket/updatesupportticket';
export const listallcomments =apiurl+'/supportticket/listallcomments';
export const downloadsupptktfile =apiurl+'/supportticket/downloadsupptktfile';
export const supportTicketGetVin = apiurl + '/supportticket/getvin';
// export const territoryVehiclesData = apiurl + '/map/getterritoryvehiclelist'
export const territoryVehiclesData = apiurl + '/map/getterritoryvehiclesListPD';
export const driverdata = apiurl+'/FMS/getSingleDriver';
export const driverupdate = apiurl+'/FMS/driverUpdate';
export const searchByVin = apiurl+'/aluserlogin/getvin';
export const searchByModel = apiurl+'/aluserlogin/getvpartdesc';
export const searchByVpart = apiurl+'/aluserlogin/getVinbasedOnVpartdesc';
export const tab_masters = apiurl+'/dashboard/masterscreens';
export const tab_screen = apiurl+'/dashboard/mappedscreens ';
export const getfleetinsightreport = apiurl+'/fleetdash';
export const billingmodule = apiurl+'/billingmodule';
export const validateDsecode = apiurl+'/billingmodule/validateDsecode';
export const createpackage = apiurl+'/billingmodule/createpackagedetails';
export const viewverticalname = apiurl+'/billingmodule/viewverticaldesc';
export const editpackagedetails = apiurl+'/billingmodule/editPackage';
export const cartdetails = apiurl+'/billingmodule/getActivecarts';
export const deleteCart = apiurl+'/billingmodule/deleteinactivecarts';
//Billing module dealer
export const loginDetails = apiurl+'/billingmodule/get_login_details'
export const vehicleList = apiurl+'/billingmodule/get_vehicle_vin_and_login_info'
export const SubmitPaymentDetails = apiurl+'/billingmodule/payment_gateway_transaction_dlr_nd_sme'
export const DealerSMEHistory = apiurl +'/billingmodule/get_dealer_and_sme_trans_hst'
export const getUnApprovedDatalist = apiurl + '/billingmodule/un_approval_subscription_data_dlr_sme'
export const getApprovedDatalist = apiurl + '/billingmodule/get_approved_subcription_list_dlr_sme'
export const getRowData = apiurl + '/billingmodule/viewTransactionDatacvg'
export const SubmitHOApprovelFields = apiurl + '/billingmodule/cvg_renewal_update_status'
export const ResubmitRejectedDetails = apiurl + '/billingmodule/update_dealer_and_payment_details'
export const CVGApprovedExcelDownloadData =  apiurl + '/billingmodule/cvg_approved_payment_list_excel'

export const userLogout = apiurl+'/map/userLogout';
export const createVIN = apiurl + '/aluserlogin/createVIN';
export const getMappedVehicles = apiurl + '/aluserlogin/getMappedVehicles';
export const odohistory= apiurl + '/odo/odohistory';
export const fmdelete= apiurl + '/fleetmanagerapp/deletefleetManger';
export const singlefmdata= apiurl + '/fleetmanagerapp/fleetmanagerdata';
export const fidashperf= apiurl + '/fleetdash/performance';
export const updatefleetmanager= apiurl +'/fleetmanagerapp/updatefleetManger';
export const fleetinsightsdashboard= apiurl + '/fleetdash/dashboard';
export const getPackageList = apiurl + '/billingmodule/getPackageList';
export const getVehcileList = apiurl + '/billingmodule/getVehcileList';
export const createDiscount = apiurl + '/billingmodule/createDiscount';
export const updateDiscount = apiurl + '/billingmodule/updateDiscountDetails';
export const getUserData = apiurl + '/billingmodule/getUserData';
export const getPackageDiscountData = apiurl + '/billingmodule/getPackageDiscountData';
export const getSubscribedDatalist = apiurl + '/billingmodule/getsubscribeduser';
export const safetyalerts=apiurl + '/fleetdash/alertssafety';
export const CreateGeofenceGroup=   apiurl +'/geofence/CreateGeofenceGroup';
export const POIGroupList=   apiurl +'/geofence/POIGroupList';
export const createGeofence =  apiurl +'/geofence/createGeofence';
export const driverDelete =  apiurl +'/FMS/driverDelete';
export const showGeofenceGroupList =   apiurl +'/geofence/showGeofenceGroupList';
export const globalFilter =    apiurl +'/map/globalFilter';
// export const getterritoryvehiclelist = apiurl + '/map/getterritoryvehiclelist';
export const showallpkg = apiurl + '/billingmodule/allpacks';
export const changePassword =  apiurl + '/map/changePassword';
export const driverbulkupload= apiurl + '/FMS/driverBulkUpload';
export const ownVehicleListDealer = apiurl + '/map/ownVehicleListDealer';
export const getVASTicketList= apiurl + '/callSupportApp/getVASTicketList';
export const getStatusList = apiurl + "/callSupportApp/VASTicketStatusList";
export const vasTicketSave = apiurl + "/callSupportApp/vasTicketSave";
export const getCustomerDetails = apiurl + "/callSupportApp/getCustomerDetials";
export const getSingleVASTicket = apiurl + "/callSupportApp/getSingleVASTicket";
export const fmssummary= apiurl + '/vehiclemaint/fmssummary';
export const updateStatus = apiurl + '/billingmodule/updateStatus';
export const approvalSubscrtionList = apiurl + '/billingmodule/approvalSubscrtionList';
export const approvalGstNo = apiurl + '/billingmodule/approvegst';
export const exceldata = apiurl + '/billingmodule/getexceldatacvg';
export const unApprovalSubscrtionData = apiurl + '/billingmodule/unApprovalSubscrtiondata';
export const downloadBillingProofFiles =  apiurl + '/billingmodule/downloadBillingProofFiles';
export const downloadPaymentInvoiceProof = apiurl + '/billingmodule/download_payment_proof'
export const deletepoi =apiurl+'/geofence/deleteGeofence';
export const updateGeofence =apiurl+'/geofence/updateGeofence';
export const viewTransactionDatacvg = apiurl + '/billingmodule/viewTransactionDatacvg';
export const deleteVehicles = apiurl + '/aluserlogin/deleteVehicles';
export const getapprovallist = apiurl + '/aluserlogin/alEmpData';
export const getapprovalrequest = apiurl + '/aluserlogin/approvalRequest'
// export const deleteVehicles = apiurl + '/aluserlogin/deleteVehicles';
// export const enquierylist= apiurl + '/landing/landingPageEnquiryList';
// export const updateenquierylist= apiurl + '/landing/updateEnquiryList';
// export const updateclosedenquierylist= apiurl + '/landing/EnquiryListClosedTickets';
// export const gettingcatogrylist= apiurl + '/landing/EnquiryStatusList';
export const getapprovedlist= apiurl + '/aluserlogin/getALUserApprovedList';
export const getmonthlyapi= apiurl + '/rawdatadownloadapp/getMonthlyReports';
export const singlemonthlyapi= apiurl + '/rawdatadownloadapp/getSingleMonthReport';
export const showfleetgroup= apiurl + '/fleetgroup/showFleetGroupWeb';
export const showfleetgroupunassign= apiurl + '/fleetgroup/getAssignedFleetGroup';
export const alUserBulkUpload= apiurl + '/aluserlogin/ALUserAssignVehiclesBulkUpload';
export const fleetgroupdelete= apiurl + '/fleetgroup/deleteFleetGroup';
export const geofenceSummeryReport= apiurl + '/geofence/geofenceSummeryReport';
export const refundrequest= apiurl + '/billingmodule/paymentrefund';
export const getAdbannerData = apiurl + '/dashboard/getAdbannerData';
export const getDsehistoryData = apiurl + '/billingmodule/getDealerMasterData';
export const getRegionaldata = apiurl + '/billingmodule/getRegionMasterData';
export const deleteDsemasterdata = apiurl + '/billingmodule/deleteDealerSalesMaster';
export const createDsemaster = apiurl + '/billingmodule/createorUpdateDealerSalesMasterdata';
export const getNoGPSAlertStatus = apiurl + '/alert/getNoGPSAlertStatus';
export const getgeofencefortrace = apiurl + '/geofence/getVehicleMappedGeofence';
export const getUnAssignedGeofenceMappedFleetGroupList = apiurl + '/geofence/getUnAssignedGeofenceMappedFleetGroupList';
export const getBs6Alerts = apiurl + '/fleetdash/getTelltaleAlerts';
export const getfuleAlerts = apiurl + '/fleetdash/get_alerts_fuel_summary';
export const geofenceAlerts = apiurl + '/alert/geofenceAlerts';
export const deleteTrip = apiurl + '/FMS/deleteTrip';
export const customerSettings = apiurl + '/customerSettings/getCommNotificationSettings';
export const updateCustomerSettings = apiurl + '/customerSettings/commSettingCreateOrUpdate';
export const getVehCustomerSettings = apiurl + '/customerSettings/getMappedAndUnmappedVehicleList';
export const getCustomerUserDetails = apiurl + '/customerSettings/getCommSettingsUserDetails';
export const updateCustomerUserDetails = apiurl + '/customerSettings/updateUserData';
export const getGeofenceNameForListView = apiurl + '/geofence/getGeofenceNameForListView';
export const getRunningIdlilng = apiurl + '/trends/getRunningIdlilng';
export const getPOIPoints = apiurl + '/findDealer/getPOIPoints';
export const getPOIPointsOnTrace = apiurl + '/findDealer/getPOIPointsOnTrace';
export const getSubDealerTypes = apiurl + '/findDealer/getSubDealerTypes';
export const getNotification = apiurl+'/mobileapp/notifications';
export const getNotificationUnreadCount = apiurl+'/mobileapp/notificationUnreadCount';

export const deleteNotification = apiurl + '/mobileapp/deleteNotification';
export const readNotification = apiurl + '/mobileapp/updateNotificationRead';

export const compTrendFn = apiurl + '/trends/getTrendComparision';
export const updateGST = apiurl + '/billingmodule/updateGST';

export const getMappedVehicleListExcel = apiurl +  '/aluserlogin/getMappedVehicleListExcel';

export const getGearutilreport = apiurl +  '/fleetdash/gearutilreport';
export const getDriverComparator = apiurl +  '/fleetdash/get_driver_comparator';
export const generateShareLink = apiurl +  '/map/generate-share-link';
export const getShareToken = apiurl +  '/map/get-share-link-token';
export const getShareLinks = apiurl +  '/map/get-share-links';
export const deleteShareLink = apiurl +  '/map/delete-share-link';
export const trackVehicle = apiurl +  '/map/track-vehicle';
export const trackVehicles = apiurl +  '/map/track-vehicles';

export const getVehicleDetails = apiurl +  '/vehicleDetails/getVehicleDetails';

//AIS140 Activation
export const getVehicleDetailsInfo = apiurl + '/ais140/getAis140VehicleDetails'
export const getRTODetails = apiurl + '/ais140/getRto'
export const CreateDealerRequestDetails = apiurl+'/ais140/createAis140'
export const UpdateRjectedData = apiurl +'/ais140/updateAis140Request'
export const getRequestList = apiurl + '/ais140/getRequestList'
export const getRowDetails = apiurl + '/ais140/getAis140RequestDetails'
export const UpdateHOPaymentDetails = apiurl+'/ais140/updateHoPaymentDetails'
export const UpdateDealerConfirmation = apiurl+'/ais140/updateDealerConfirmation'
export const DownloadActivationCertificate = apiurl +'/ais140/downloadAis140Certificate'
export const DownloadSOSForm = apiurl + '/ais140/downloadsoscertificate'

//PSBAlert

export const GensetAlertListData = apiurl+'/psb_app/get_genset_list'
export const GensetAlertDetailVeiw = apiurl+'/psb_app/get_genset_details'

//Alert
export const alerTypeList = apiurl+'/alert/alert_type_list'
export const alertConfiguration = apiurl+'/alert/alert_configuration'
export const alertConfVehicleList = apiurl+'/alert/alert_config_vehicle_list'

//Fuel Report
export const downloadFuelTemplate = apiurl+'/alert/download_fuel_report_template'
export const uploadFuelReport = apiurl+'/alert/upload_fuel_report_data'
export const fuelReportData = apiurl+'/alert/fuel_report_data'

//Release Notes
export const ReleaseNotes = apiurl+'/landing/get_user_notification'
export const getReleaseNotification = apiurl+'/landing/get_notification_history'

//Monthly Report
export const monthly_fleet_report = apiurl+'/reportapp/monthly_mailer_report'

//Create Traning Submit
export const create_tranning = apiurl+'/userRemap/trainingUsers'
export const getTranningModule = apiurl+'/userRemap/getTrainingModules'
//Training Request
export const get_training_request = apiurl+'/userRemap/getTrainingRequest'
export const update_training_request = apiurl+'/userRemap/updateTrainingRequest'
export const get_training_customer_request = apiurl+'/userRemap/getTrainingRequestCustomer'
export const update_training_customer_request = apiurl+'/userRemap/updateTrainingRequestCustomer'


