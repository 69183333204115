import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { calculateDifferenceInDays } from '../../Util'
import withAlertBox from '../AlertHooks/withAlertBox'
import './FuelAlertDatePicker.scss'

const FuelAlertDatePicker = ({ minFuelDate, handleDateChangePickerDate, fuelAlertStartDate, fuelAlertEndDate, resetDateFields, closeFuelAlertsDatePicker, activeHandler, openModal }) => {
  const [showDatepickerPopup, setShowDatePickerPopup] = useState('block')

  const CustomInput = ({ value, onClick, onChange, inputname, flag }) => (
    <div className='ts-dateTimeBlock'>
      <p className='ts-inputContainer'>
        <input
          type='text'
          className='ts-dataInput'
          name={inputname}
          style={{ cursor: 'pointer' }}
          value={value}
          onClick={onClick}
          onChange={onChange}
        />
        {flag === 'date'
          ? <i className="fa fa-calendar-o icon cs_cursor_pointer" onClick={onClick}></i>
          : <i className="fa fa-calendar-o icon cs_cursor_pointer" onClick={onClick}></i>
        }
      </p>
    </div>
  )

  const handleDateChange = (date, flag) => {
    if (flag === 'start') {
      handleDateChangePickerDate(date, fuelAlertEndDate)
    } else if (flag === 'end') {
      handleDateChangePickerDate(fuelAlertStartDate, date)
    }
  }

  const fetchSelectedFuelAlerts = () => {
    if (fuelAlertStartDate && fuelAlertEndDate) {
      if (fuelAlertStartDate > fuelAlertEndDate) {
        openModal('Start date should not be greater than End date.', 'e')
        return
      }

      const differenceInDays = calculateDifferenceInDays(fuelAlertStartDate, fuelAlertEndDate)
      if (differenceInDays > 30) {
        openModal('The difference between Start and End date cannot be more than 30 days.', 'e')
        return
      }

      const date = { 'f_date': fuelAlertStartDate, 't_date': fuelAlertEndDate }
      activeHandler(true, 0, date)
    } else {
      activeHandler(true, 0, null) // Passing null when no date is selected
    }
  }

  return (
    <>
      <div className='datePicker-modal' style={{ display: showDatepickerPopup }} >
        <div className='addModalBody'>
          <div className='modal-header'>
            <label className='col-lg-11 col-sm-11 modal-heading'>Select Date Range </label>
            <span
              className="col-lg-1 col-sm-1 close"
              onClick={() => {
                setShowDatePickerPopup('none')
                closeFuelAlertsDatePicker()
              }}
            >&times;</span>
          </div>
          <div className='modal-body'>
            <div className='container-fluid'>
              <div className='datePickerBlock'>
                <div className='row dateTime-input'>
                  <div className='col-5' >
                    <label className='datePickerLbl'>Start date</label>
                    <br />
                    <div className='datePickerContainer'>
                      <DatePicker
                        popperClassName='ts-dateTimePicker'
                        selected={fuelAlertStartDate}
                        onChange={(date) => handleDateChange(date, 'start')}
                        className='dataInput'
                        value={fuelAlertStartDate}
                        dateFormat='dd/MM/yyyy'
                        minDate={minFuelDate}
                        maxDate={new Date()}
                        autoComplete='off'
                        onKeyDown={(e) => {
                          e.preventDefault()
                        }}
                        customInput={
                          <CustomInput
                            flag='date'
                            inputname='StartDate'
                          />}
                      />
                    </div>
                  </div>

                  <div className='col-5' >
                    <label className='datePickerLbl'>End date</label>
                    <br />
                    <div className='datePickerContainer'>
                      <DatePicker
                        selected={fuelAlertEndDate}
                        onChange={(date) => handleDateChange(date, 'end')}
                        className='dataInput'
                        value={fuelAlertEndDate}
                        dateFormat='dd/MM/yyyy'
                        autoComplete='off'
                        onKeyDown={(e) => {
                          e.preventDefault()
                        }}
                        minDate={fuelAlertStartDate}
                        maxDate={new Date()}
                        customInput={
                          <CustomInput
                            flag='date'
                            inputname='EndDate'
                          />}
                        popperClassName='ts-dateTimePicker'
                      />
                    </div>
                  </div>
                </div>

                <div className='row modal-footer'>
                  <div className='row '>
                    <button
                      className='raw-common-button '
                      type='button'
                      onClick={() => fetchSelectedFuelAlerts(fuelAlertStartDate, fuelAlertEndDate)}
                    >Submit
                    </button>
                    <button
                      className='raw-common-button '
                      onClick={resetDateFields}
                    >Reset &#x00A0;   <i className='fa fa-repeat' aria-hidden='true'></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  )
}

export default withAlertBox(FuelAlertDatePicker)
