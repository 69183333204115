
/**
 * Formats charging time in seconds to a string of the form "H:MM:SS".
 * @param {number|null|undefined} chargingTimeSec - The charging time in seconds.
 * @returns {string} The formatted time string.
 * @throws {Error} If the input is not a valid number.
 */
export const formatChargingTime = (chargingTimeSec) => {
    // Check if the input is a valid number
    if (typeof chargingTimeSec !== 'number' || isNaN(chargingTimeSec) || chargingTimeSec < 0 || chargingTimeSec === null || chargingTimeSec === undefined) {
        return '00:00:00';
    }

    // Calculate hours, minutes, and seconds
    const hours = Math.floor(chargingTimeSec / 3600) || 0;
    const minutes = Math.floor((chargingTimeSec % 3600) / 60) || 0;
    const seconds = chargingTimeSec % 60 || 0;

    // Helper function to format units with leading zero
    const formatTimeUnit = (unit) => unit < 10 ? `0${unit}` : unit;

    // Construct and return the time string
    return `${formatTimeUnit(hours)}:${formatTimeUnit(minutes)}:${formatTimeUnit(seconds)}`;
}
