import React from 'react';
import classnames from 'classnames';
import './SelectionBox.scss';

const SelectionBox = ({ click, show, showMessage, messageType,func}) =>{
    
    return (
        <div className="SelectionBox">
            <div className={classnames("modal",show ? "active-modal" : null)}>
                <div className={classnames("modal-content",
                    messageType === "r" ? "modal-content-success" : "modal-content-error")}>
                    <div className="row">
                        <div className="col-lg-8">
                            <span className="textshow">{showMessage}</span>
                        </div>
                        <div className="col-lg-4 col-sm-4">
                            <button className="confirmbutton" onClick={func} type="button">Yes</button>
                            <button className="confirmbutton" onClick={click} type="button">No</button>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SelectionBox;