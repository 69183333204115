import React, { useEffect, useState } from 'react'
import { useSelector } from "react-redux";
import { getLocationData, getVehicleDetails } from '../API/useApi'
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import scroll from '../Scroll'

const VinNumber = (props) => {
    const ctr = useSelector((state) => state.userdata.id);
    const { setState } = props
    const [VINRegnValue, setVINRegnValue] = useState('')
    const [userInput, setuserInput] = useState('')
    const [VINList, setVINList] = useState([])

    useEffect(() => {
        setVINList(props.state.vin_number)
    }, [props.state.vin_number]);

    const handleSelect = async (value) => {
        setState(state => ({ ...state, selected_vin: value.vin_number}))
        props.actionProvider.inputHandler(props, value.regn_number);
        if(props.payload.question === 'current_location'){
            setState(state => ({ ...state, loader: true, vinData: []}))
            let filter_data = props.vin_number.filter((data) => data.vin_number === value.vin_number)
            const response = await getLocationData(ctr, props, value.mapped_obu ? value.mapped_obu : value.obu_id);
            if(response.statuscode === 200){
                if(response.data.length > 0){
                    setState(state => ({ ...state, vinData: response.data[0], loader: false, result: true, q_clicked: false, yes_no_btn_clk: false, fleet_continue: true}))
                    props.actionProvider.resultHandler(props, response.data[0]);
                }
            }
            else{
                setState(state => ({ ...state, vinData: [], loader: false, result: true, q_clicked: false}))
                props.actionProvider.resultHandler(props, response.data);
            }
        }
        else{
            props.actionProvider.DateHandler(props.payload.question, props.payload.title, props.payload.api, props.payload.column, props.payload.parent_title);
        }
        scroll();
    }

    const onChangeVin = (e, value) => {
        if (value !== null && !props.state.result) {
            setVINRegnValue(value)
            handleSelect(value)
        }
        else{
            setVINRegnValue('')
            setState(state => ({ ...state, expanded: props.state.index, yes_no_btn_clk: true}))
            // props.actionProvider.ButtonsHandler(props);
        }
        scroll();
    }

    const selectVIN = (e) => {
        setuserInput(e.target.value)
    }

    const FindVin = async () => {
        if (userInput && userInput.length >= 4) {
            const response = await getVehicleDetails(userInput, ctr);
            if(response.statuscode === 200){
                if(response.data.length > 0){
                    setVINList(response.data)
                }
                else{
                    setVINList([])
                }
            }
            else{
                setVINList([])
                alert('No details available for this VIN number');
            }
            var allSelects = document.getElementsByClassName("MuiInputBase-input");
            var lastSelect = allSelects[allSelects.length-1];
            lastSelect.focus();

        }
        else{
            alert('Please Enter VIN Number');
        }
    }

    var defaultVINProps = {
        options: VINList.length > 0 ? VINList : [],
        getOptionLabel: option => option.regn_number +' - '+ option.vin_number
    }

  return (
    <>
        <div className='d-flex vin-div'>
            {/* {(props.payload.api === 'report' || props.payload.api === 'safetyalerts' || props.payload.api === 'ownVehicleList') && 
                <div className='col-md-12 top-bottom-title-vin'>
                    <span>{props.payload.parent_title} - {props.payload.title}</span>
                </div>
            } */}
            <div className='col-md-8'>
                <Autocomplete
                    {...defaultVINProps}
                    id="loginIDAutoComplete"
                    value={VINRegnValue || null}
                    onChange={(event, value) => { onChangeVin(event, value) }}
                    renderInput={params => (
                        <TextField {...params} onChange={selectVIN} label="Reg.No / VIN" placeholder="Enter Reg.No / VIN" margin="normal" fullWidth />
                    )}
                />
            </div>
            {/* <button class="srh-btn" onClick={(e) => {FindVin()}}><i class="fa fa-search" title="Search" aria-hidden="true"></i></button>         */}
        </div>
    </>
  )
}

export default VinNumber