import React from 'react';
import { GmapsDispatchThunk } from '../../store/actions/GmapsActions';
import './fleetAlerts.scss';
import { connect } from 'react-redux';
import FleetBox from './fleetbox';
import { postData, exceldateformatter, exceldatetimestampformatter, changeDateTimeFormateAMPM } from '../../Util';
import NavLink from "../Link/Link";
import * as apilist from '../../apiList';
import classnames from 'classnames';
import "../../Components/BottomRibbon/ribbonStyle.scss";
import "../../Components/GoogleMap/index.scss";
import FleetMap from './fleetMap.js';
import { Link } from 'react-router-dom';
import * as alasql from 'alasql';
import XLSX from 'xlsx';
import moment from 'moment';
import Configuration from './Configuration';
import FuelAlertDatePicker from './FuelAlertDatePicker';
import { toDate } from 'date-fns';

alasql.utils.isBrowserify = false;
alasql.utils.global.XLSX = XLSX;

class FleetAlerts extends React.Component {
    constructor(props){
        super(props)
        const currentDate = new Date()
        const sevenDaysAgo = new Date(currentDate)
        sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 6)
        const minFuelDate = new Date(currentDate)
        minFuelDate.setDate(minFuelDate.getDate() - 365)
        this.inputRef = React.createRef(null);
        this.state = {
            fleetactiveAlerts: [],
            filterFleetactiveAlerts: [],
            exceltempFleetdetails: [], searchVal: '',
            geocount: 0,
            info1: 'Info 1',
            info2: 'Info 2',
            fleetdetails: [], //{regn_number:"GAHAGAHA", vin: "djgshdsdsd",alertTypeName:"Critical",
            //alertInfo1:"warning",startTimestamp:"16-11-2019 12:14:35"}
            detailFleetDetail: {},
            selectedFleetDetail: [],
            tempFleetdetails: [],
            severityCount: {},
            geoactive: true,
            activetype: '',
            currentPage: 1,
            FleetsPerPage: 7,
            geofenceAlertsdata: [],
            remainderCurrentPage: 1,
            remainderPerPage: 7,
            searchInput: '',
            geoeventscount: 0,
            registrationCurrentPage: 1,
            registrationPerPage: 6,
            geoalertTypeIds: [],
            fleetdetailssearch: [],
            viewlocation: false,
            alertActive: '',
            sidebarActive: '',
            sidebargeofenceActive: '',
            active: true,
            activeFilterdata: 6,
            activegeoFilterdata: 6,
            isShowFilter: false,
            loader: false,
            initialDtls: false,
            excelData: [],
            fleetEvents: false,
            geofencealerts: false,
            sidebarEventsInd: true,
            siderbarEventsList: [],
            eventAlertInfo: [],
            eventSidebarActive: "",
            eventReminderHeader: "Vin Number",
            alertTypeIds: [],
            fleetEventSidebarList: [],
            fleetEventsActive: [],
            sortedFleetEvents: [],
            remainderCount: 0,
            OccassionsCount: 0,
            traceData: [], showMap: "none",
            center: { lat: 20.5937, lng: 78.9629 }, zoom: 4,
            telltale: false,
            telltaletotalCount: 0,
            telltalesidebarlist: [],
            telltaleactive: '',
            telltaleactivedataAll: [],
            telltaleactivedata: [],
            viewdetails: false,
            showdetail: [],
            TelltaleCurrentPage: 1,
            TelltalePerPage: 5,
            vehicleviewPerPage: 5,
            telltaleexceldata: [],
            vehicleRegBasedView: false,
            telltaleseveritycnt: 0,
            sortedTelltale: [],
            globalVINList: [],
            searchData: [],
            fleetVechicleBasedList: [],
            sortedFleetAlerts: [],
            fleetActiveRegno: "",
            severityVal: "",
            sort: 'asc', position: [{ lat: "", lng: "" }],
            vinList: [],
            regList: [],
            /* Geofence Alerts Sort */
            alerts_sort: {
                column_name: 'geofence_name',
                order: "desc"
            },
            dialog: false,
            alertType: [],
            alertVehicleList: [],
            showDatePicker: false,
            fuelAlertStartDate: sevenDaysAgo,
            fuelAlertEndDate: currentDate,
            sevenDaysAgo: sevenDaysAgo,
            toDayDate: currentDate,
            minFuelDateOpt: minFuelDate,
        }
    }
    

    componentWillUnmount() {
        localStorage.setItem('selectedVin', [])
    }

    componentDidMount() {
        this.loadSelectVehicle();
        if (this.props.ctr.userroleid === 6 || this.props.ctr.userroleid === 8) {
            this.alertTypeList();
            this.AlertConfigVehicleList()
        }
        if(this.props.location?.state?.alertActive === 'Events'){
            let vinList = [];
            let searchData = [];
            if (this.props.searchresult && this.props.searchresult.data && this.props.searchresult.data.length !== 0) {
                this.props.searchresult && this.props.searchresult.data && this.props.searchresult.data.length &&
                    this.props.searchresult.data.map((value) => {
                        vinList.push(value.vin_numbers);
                        searchData.push(value);
                    });
            }
            if(vinList.length <= 0){
                this.callEventsSidebar('Events');
            }
        }
        let activeName = "Critical";
        if (this.props.location.state) {
            activeName = this.props.location.state.alertActive
        }
        if (this.props.searchresult && this.props.searchresult.data && this.props.searchresult.data.length !== 0) {
            let vinList = [];
            let searchData = [];

            this.props.searchresult && this.props.searchresult.data && this.props.searchresult.data.length &&
                this.props.searchresult.data.map((value) => {
                    vinList.push(value.vin_numbers);
                    searchData.push(value);
                });
            this.setState({
                globalVINList: vinList,
                searchData: searchData,
                alertActive: activeName,
                geofencealerts: activeName == 'Geofence' ? true : false,
                fleetEvents: activeName == 'Events' ? true : false,
                telltale: activeName == 'Telltale' ? true : false,
                loader: true
            }, () => {
                this.activeHandler(true, 6);
                this.activegeofenceHandler(true, 6, 'onLoad')
            });
        } else {
            let vinList = []
            if (localStorage.getItem('selectedVin')) {
                vinList.push(localStorage.getItem('selectedVin'))
                //this.setState({globalVINList: vinList})
            }
            this.setState({
                globalVINList: vinList,
                alertActive: activeName,
                geofencealerts: activeName == 'Geofence' ? true : false,
                fleetEvents: activeName == 'Events' ? true : false,
                telltale: activeName == 'Telltale' ? true : false,
                loader: true
            }, () => {
                this.activeHandler(true, 6);
                this.activegeofenceHandler(true, 6, 'onLoad')
            });
        }
    }

    shouldComponentUpdate = (nextProps, nextState) => {
        let vinList = [];
        let searchData = [];
        if (nextProps.searchresult &&
            nextProps.searchresult.data &&
            JSON.stringify(nextProps.searchresult.data) !== JSON.stringify(nextState.searchData)
        ) {
            nextProps.searchresult.data.map((value) => {
                vinList.push(value.vin_numbers);
                searchData.push(value);
            })
            this.setState({
                globalVINList: vinList,
                searchData: searchData
            }, () => {
                if (this.props.location.state) {
                    this.setState({
                        alertActive: this.state.alertActive == "" ? this.props.location.state.alertActive : this.state.alertActive
                    })
                }
                this.activeHandler(true, 6);
                this.activegeofenceHandler(true, 6, 'onLoad')
            });
        }
        return true;
    }

    loadSelectVehicle = () => {
        let tokens = this.props.ctr.token;
        let newObj = {};
        newObj.platform = 'w';
        newObj.userId = this.props.ctr.userid;
        const datas = postData(apilist.vehicleInfo, newObj, tokens);
        //const datas = postData('http://10.18.1.113:8000/FMS/vehicleInfo', newObj);
        datas.then((dataVals) => {
            if (dataVals.statuscode === 200) {
                let vins = dataVals.data.map((data) => {
                    return data.vin_number;
                });
                this.setState({
                    vinList: vins,
                    regList: dataVals.data
                });
            } else {
                console.log('Failed to load Vehicle Data..')
            }
        });
    }

    alertTypeList = () => {
        let tokens = this.props.ctr.token;
        let newObj = {};
        const datas = postData(apilist.alerTypeList, newObj, tokens);
        datas.then((dataVals) => {
            if (dataVals.statuscode === 200) {
                this.setState({ alertType: dataVals.data })
            } else {
                alert('Failed to load Alert Types..')
            }
        });
    }

    AlertConfigVehicleList = () => {
        let tokens = this.props.ctr.token;
        let newObj = {};
        const datas = postData(apilist.alertConfVehicleList, newObj, tokens);
        datas.then((dataVals) => {
            if (dataVals.statuscode === 200) {
                this.setState({ alertVehicleList: dataVals.data })
            } else {
                alert('Failed to load Alert Types..')
            }
        });
    }

    activegeofenceHandler = (isActive, daysDifference, fromWhere) => {
        this.setState({ initialDtls: false });
        let fleetData = {}
        var startDate = new Date();
        var endDate = new Date();
        startDate.setDate(startDate.getDate() - daysDifference);
        let tokens = this.props.ctr.token;
        fleetData.fromDate = this.changeformatDate(startDate);
        fleetData.toDate = this.changeformatDate(endDate);
        fleetData.vins = this.state.globalVINList;
        let alertsCount = "";
        this.setState({ loader: true })
        alertsCount = postData(apilist.geofenceAlerts, fleetData, tokens);
        alertsCount.then((response) => {
            let data = response.data;
            if (response.statuscode === 200) {
                if (isActive) {
                    this.setState({ geofenceAlertsdata: data.geo_entryexit, geoalertTypeIds: ['108'], initialDtls: true, loader: fromWhere == 'onLoad' ? true : false, geoactive: isActive, geocount: data.geoentryexit_count, activegeoFilterdata: daysDifference, isShowFilter: false }, () => {
                        if (this.state.alertActive == "Geofence") {
                            this.changeFleetAlerts(this.state.alertActive);
                            this.setState({ loader: false });
                        } else {
                            this.setState({ loader: false });
                        }

                    });
                }
                else {
                    var alertIds = data.geo_events.map((list) => {
                        return list.alert_type_id.toString();
                    })
                    this.setState({ geofenceAlertsdata: data.geo_events, loader: fromWhere == 'onLoad' ? true : false, initialDtls: true, geoalertTypeIds: alertIds, geoactive: isActive, geocount: data.geoentryevents_count, activegeoFilterdata: daysDifference, isShowFilter: false }, () => {
                        if (this.state.alertActive == "Geofence") {
                            // this.setState({geoactive : true})
                            this.changeFleetAlerts(this.state.alertActive);
                        } else {
                            this.setState({ loader: false });
                        }
                    });
                }
            }
            else {
                if (fromWhere != 'onLoad') {
                    this.setState({ loader: false });
                }

            }
        })
    }
    pad = (num) => {
        return ("0" + num).slice(-2);
    }

    converthhmmss = (secs) => {
        if (secs !== ' : 0') {
            var mins = secs.split(': ')[1]
            var MINUTES = mins.split(' ')[0]
            var m = MINUTES % 60;
            var h = (MINUTES - m) / 60;
            var HHMM = h.toString() + ":" + (m < 10 ? "0" : "") + m.toString() + ":" + "00" + " " + mins.split(' ')[1];
            return HHMM
        }
        else {
            return 'NA';
        }
    }

    calcDate = (startDate, endDate) => {  //hhmmss diff between start and end timestamp
        var diffInHours = Math.abs(new Date(endDate) - new Date(startDate)) //milisecs
        var seconds = diffInHours / 1000;
        var hours = parseInt(seconds / 3600); // 3,600 seconds in 1 hour
        hours = hours < 10 ? "0" + hours : hours;
        seconds = seconds % 3600; // seconds remaining after extracting hours
        var minutes = parseInt(seconds / 60); // 60 seconds in 1 minute
        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = Math.round(seconds % 60);
        seconds = seconds < 10 ? "0" + seconds : seconds;
        return hours + ":" + minutes + ":" + seconds + " " + 'hh:mm:ss';
    }

    activeHandler = (isActive, daysDifference, dates) => {
        this.setState({ loader: true, initialDtls: false, alertTypeIds: [] });
        let fleetData = {};

        var startDate = new Date();
        var endDate = new Date();
        let tokens = this.props.ctr.token;
        fleetData.userId = this.props.ctr.userid;
        fleetData.platform = "w";
        fleetData.vins = this.state.globalVINList;
       
        if (dates && dates.f_date && dates.t_date) {
            startDate = dates.f_date;
            endDate = dates.t_date;
            fleetData.isActive = true;
        }
        else {
            startDate.setDate(startDate.getDate() - daysDifference);
            endDate = new Date();
            fleetData.isActive = isActive;
        }
        if (this.state.alertActive === 'Fuel') {
            if (this.state.fuelAlertStartDate && this.state.fuelAlertEndDate) {
                startDate = this.state.fuelAlertStartDate;
                endDate = this.state.fuelAlertEndDate;
            }
        }
        fleetData.fromDate = this.changeformatDate(startDate);
        fleetData.toDate = this.changeformatDate(endDate);


        let alertsCount = "";
        if (isActive) {
            alertsCount = postData(
                this.props.ctr.userrole === "DEALER OUTLET ADMIN" ? apilist.getFence + '/deviceActiveAlertsCountDealer'
                    : apilist.getFence + '/deviceActiveAlertsCount',
                fleetData, tokens);
        }
        else {
            alertsCount = postData(
                this.props.ctr.userrole === "DEALER OUTLET ADMIN" ? apilist.getFence + '/deviceInActiveAlertsCountDealer'
                    : apilist.getFence + '/deviceInActiveAlertsCount',
                fleetData, tokens);
        }

        alertsCount.then((response) => {
            let data = response.data;
            var alertIds = data.alertList.map((list) => {
                return list.alert_type_id.toString();
            })

            if (!isActive) {
                if (this.state.severityCount) {
                    data.alertCount.Service = this.state.severityCount.Service
                    data.alertCount.Events = this.state.severityCount.Events
                }
            }
            let filterBySeverity = data.alertList.filter(data => data.severity === this.state.alertActive)
            if (this.state.alertActive === 'Service') {
                filterBySeverity = filterBySeverity.sort((function (a, b) { return a.alert_type_id - b.alert_type_id }));
            }
            this.setState({
                fleetactiveAlerts: data.alertList,
                filterFleetactiveAlerts: filterBySeverity,
                severityCount: data.alertCount,
                active: isActive,
                activeFilterdata: daysDifference,
                isShowFilter: false,
                initialDtls: true,
                alertTypeIds: alertIds,
                loader: true,
                sidebarActive: data.alertList[0] ? data.alertList[0].alert_type_name : null,
            })
            if (this.state.alertActive != "Geofence") {
                this.changeFleetAlerts(this.state.alertActive, dates);
            } else {
                this.setState({
                    loader: false
                })
            }
        }).catch((error) => {
            console.log(error);
            this.setState({
                loader: false
            })
        })
        if (this.state.alertActive != "Telltale") {
            let telltaleData = {};
            telltaleData.userId = this.props.ctr.userid;
            telltaleData.platform = "w";
            telltaleData.alerttype = "Remainder";
            telltaleData.vins = this.state.globalVINList;
            const telltaledetails = postData(apilist.getFence + '/telltaleAlerts', telltaleData, tokens)
            telltaledetails.then((telltaledresponse) => {
                if (telltaledresponse.statuscode === 200) {
                    this.setState({ telltaleseveritycnt: telltaledresponse.data.totalCount })
                }
                else {
                    this.setState({ telltaleseveritycnt: 0 })
                }
            })
        }
    }

    getFleetAlertDetails = (fleetMenu) => {
        let fleetData = {};
        var startDate = new Date();
        var endDate = new Date();
        let tokens = this.props.ctr.token;
        fleetData.userId = this.props.ctr.userid;
        fleetData.platform = "web";

        if(fleetMenu.length <=0){
            fleetMenu = this.state.filterFleetactiveAlerts[0]
        }

        if (fleetMenu.severity === "Geofence") {
            this.setState({ isActive: false })
            fleetData.isActive = false;
            startDate.setDate(startDate.getDate() - this.state.activegeoFilterdata);
        }
        else if (fleetMenu.severity === "Fuel") {
            if (this.state.fuelAlertStartDate && this.state.fuelAlertEndDate) {
                startDate = this.state.fuelAlertStartDate;
                endDate = this.state.fuelAlertEndDate;
                this.setState({ showDatePicker: false });
            }
            else {
                startDate.setDate(startDate.getDate() - 6);
                endDate = new Date();
            }
            this.setState({ isActive: true })
            fleetData.isActive = this.state.active;
        } 
        else {
            if(fleetMenu.severity === "Fuel"){
                startDate.setDate(startDate.getDate() - 6);
            }
            else{
                startDate.setDate(startDate.getDate() - this.state.activeFilterdata);
            }
            this.setState({ isActive: true })
            fleetData.isActive = this.state.active;
        }

        fleetData.fromDate = this.changeformatDate(startDate);
        fleetData.toDate = this.changeformatDate(endDate);
        // fleetData.isActive = fleetMenu.severity === "Geofence"?false:this.state.active;
        fleetData.vins = this.state.globalVINList;
        
        let alert_id = [];
        let filterBySeverity = this.state.fleetactiveAlerts.filter(data => data.severity === this.state.alertActive)
        if (fleetMenu.severity != "Geofence") {
            filterBySeverity.forEach((item) => {
                let index = alert_id.findIndex((id) => id === item["alert_type_id"]);
                if (index < 0) {
                    alert_id.push(item["alert_type_id"]);
                }
            })
        } else if (this.state.geofenceAlertsdata.length > 0) {

            this.state.geofenceAlertsdata.forEach((item) => {
                let index = alert_id.findIndex((id) => id === item["alert_type_id"]);
                if (index < 0) {
                    alert_id.push(item["alert_type_id"]);
                }
            })
        }
        fleetData.alertTypeId = alert_id;

        // fleetData.alertTypeId.push(fleetMenu.alert_type_id.toString());
        
        // fleetData.alertTypeName = fleetMenu.severity == "Geofence" ? "" : fleetMenu.alert_type_name;
        if (fleetMenu.severity === "Fuel") {
            fleetData.alertTypeName = this.state.filterFleetactiveAlerts[0].alert_type_name 
        }
        else {
            fleetData.alertTypeName = fleetMenu.severity == "Geofence" ? "" : fleetMenu.alert_type_name;
        }
        this.setState({ loader: true, initialDtls: false });
        const fleetdetails = postData(
            this.props.ctr.userrole === "DEALER OUTLET ADMIN"
                ? apilist.getFence + '/viewActiveFleetAlertsDetailsDealer'
                : apilist.getFence + '/viewActiveFleetAlertsDetails',
            fleetData, tokens);
        fleetdetails.then((response) => {
            if (response.statuscode === 200) {
                this.formattingFleetResponseData(fleetMenu, response.data)
            }
            else if (response.statuscode === 400) {
                this.setState({
                    loader: false,
                    filterFleetactiveAlerts: [],
                    // initialDtls: true,
                    // fleetdetails: []
                })
            }
            else {
                this.setState({
                    loader: false
                })
            }
        }).catch((error) => {
            console.log(error);
            this.setState({
                loader: false
            })
        })
    }

    formattingFleetResponseData = (fleetMenu, response_data) => {
        //let fleetdetails = response.data;
        let formattedFleetDetails = [];
        let fleetdetails = [];
        if (response_data.length > 0) {
            if (fleetMenu.severity != "Geofence") {
                fleetdetails = this.fleetFilterBasedonId(fleetMenu.alert_type_id, response_data)
            } else {
                fleetdetails = this.fleetFilterBasedonAlertName(fleetMenu.alert_type_name, response_data)
            }
        }
        formattedFleetDetails = this.mappingFleetAlerts(fleetMenu, fleetdetails)

        //let filtered_FormattedFleetData = [];

        //console.log(formattedFleetDetails);sidebargeofenceActive
        const shortedData = [...formattedFleetDetails].sort((a, b) => new Date(b.start_timestamp_utc) - new Date(a.start_timestamp_utc));
        this.setState({
            fleetdetails: shortedData,
            info1: formattedFleetDetails.length ? formattedFleetDetails[0].alert_info_1 : '',
            info2: formattedFleetDetails.length ? formattedFleetDetails[0].alert_info_2 : '',
            fleetdetailssearch: formattedFleetDetails,
            viewlocation: false,
            vsidebarActive: fleetMenu.alert_type_name,
            currentPage: 1,
            registrationCurrentPage: 1,
            loader: false,
            initialDtls: true,
            excelData: response_data,
            sidebarActive: fleetMenu.alert_type_name,
            sidebargeofenceActive: fleetMenu.alert_type_name,
            exceltempFleetdetails: formattedFleetDetails,
            // vehicleRegBasedView:false,
            remainderCurrentPage: 1,
            fleetVechicleBasedList: [],
            sortedFleetAlerts: [],
            fleetActiveRegno: ''
        }, () => {
            if (this.state.vehicleRegBasedView == true) {
                this.setState({
                    vehicleRegBasedView: false
                }, () => {
                    this.vehicleviewSwitch();
                })
            }
        })
    }

    mappingFleetAlerts = (fleetMenu, fleetdetails) => {
        let fleetIndex;
        let formattedFleetDetails = [];
        fleetdetails.map((fleetdetail) => {
            if (fleetMenu.severity === "Geofence") {
                fleetIndex = formattedFleetDetails.findIndex(
                    formatfleet => {
                        return formatfleet.geofence_name === fleetdetail.geofence_name && formatfleet.regn_number === fleetdetail.regn_number
                    }
                );
            }
            else {
                fleetIndex = formattedFleetDetails.findIndex(
                    formatfleet => {
                        return formatfleet.regn_number === fleetdetail.regn_number
                    }
                );
            }

            if (fleetIndex >= 0) {
                formattedFleetDetails[fleetIndex].Occurance =
                    formattedFleetDetails[fleetIndex].Occurance + 1;

                //formatting array of Location,Date and Alert Info.
                formattedFleetDetails[fleetIndex].selectFleet.push(
                    this.composeSelectFleet(fleetdetail));
            } else {
                fleetdetail.Occurance = 1;

                //initialize the array of Location,Date and Alert Info.
                var selectFleetArr = [];

                selectFleetArr.push(this.composeSelectFleet(fleetdetail));
                fleetdetail.selectFleet = selectFleetArr;
                formattedFleetDetails.push(fleetdetail);
            }
            return null;
        });
        return formattedFleetDetails;
    }

    onClickonFleetSidebar = (fleetMenu) => {
        let allFleetData = this.state.excelData;
        this.formattingFleetResponseData(fleetMenu, allFleetData);
        this.setState({ sidebarActive: fleetMenu.alert_type_name })

    }

    fleetFilterBasedonId = (id, fleetArray) => {
        return fleetArray.filter((item => item.alert_type_id === id));
    }

    fleetFilterBasedonAlertName = (alertName, fleetArray) => {
        return fleetArray.filter((item) => item.alert_type_name == alertName);
    }

    composeSelectFleet = ((fleetdetail) => {
        var selectFleet = {};
        selectFleet.fleetLocation = fleetdetail.start_location;
        selectFleet.fleetTime = fleetdetail.start_timestamp_utc;
        selectFleet.fleetEndLocation = fleetdetail.end_location === undefined ? '' : fleetdetail.end_location;
        selectFleet.fleetEndTime = fleetdetail.end_timestamp === undefined ? '' : fleetdetail.end_timestamp;
        selectFleet.fleetInfo1 = fleetdetail.alert_info_1; //? parseInt(fleetdetail.alert_info_1).toFixed() : null;
        selectFleet.fleetInfo2 = fleetdetail.alert_info_2; //? parseInt(fleetdetail.alert_info_2).toFixed() : null;
        selectFleet.lat = fleetdetail.start_lat;
        selectFleet.lng = fleetdetail.start_long;
        if (fleetdetail.ignition_status)
            selectFleet.ignition_status = fleetdetail.ignition_status
        if (fleetdetail.alert_type_id)
            selectFleet.alert_type_id = fleetdetail.alert_type_id
        selectFleet.endLat = fleetdetail.end_lat;
        selectFleet.endLong = fleetdetail.end_long;
        selectFleet.regn_number = fleetdetail.regn_number;
        selectFleet.timestamp = fleetdetail.start_timestamp_utc;
        return selectFleet;
    });

    changeformatDate = (date) => {
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let dateinMonth = date.getDate();
        let dateString = year + "-" + month + "-" + dateinMonth;
        return dateString;
    }

    changePage = (pagenumber) => {
        let pageno;
        let { currentPage } = this.state;
        let lastVal = (Math.ceil(this.state.fleetdetails.length / this.state.FleetsPerPage));
        if (pagenumber === "<") {
            pageno = currentPage - 1 !== 0 ? currentPage - 1 : 1;
        } else if (pagenumber === ">") {
            pageno = currentPage + 1 !== lastVal + 1 ? currentPage + 1 : lastVal;
        } else if (pagenumber === "<<") {
            pageno = 1;
        } else if (pagenumber === ">>") {
            pageno = lastVal;
        }
        else {
            pageno = pagenumber;
        }
        this.setState({
            currentPage: pageno
        })
    }

    changeRemainderPage = (pagenumber) => {
        let pageno;
        let { remainderCurrentPage } = this.state;
        let lastVal = (Math.ceil(this.state.eventAlertInfo.length / this.state.remainderPerPage));
        if (pagenumber === "<") {
            pageno = remainderCurrentPage - 1 !== 0 ? remainderCurrentPage - 1 : 1;
        } else if (pagenumber === ">") {
            pageno = remainderCurrentPage + 1 !== lastVal + 1 ? remainderCurrentPage + 1 : lastVal;
        } else if (pagenumber === "<<") {
            pageno = 1;
        } else if (pagenumber === ">>") {
            pageno = lastVal;
        }
        else {
            pageno = pagenumber;
        }
        this.setState({
            remainderCurrentPage: pageno
        })
    }

    changeRegistrationPage = (pagenumber) => {
        let pageno;
        let { registrationCurrentPage } = this.state;
        let lastVal = (Math.ceil(this.state.selectedFleetDetail.length / this.state.registrationPerPage));
        if (pagenumber === "<") {
            pageno = registrationCurrentPage - 1 !== 0 ? registrationCurrentPage - 1 : 1;
        } else if (pagenumber === ">") {
            pageno = registrationCurrentPage + 1 !== lastVal + 1 ? registrationCurrentPage + 1 : lastVal;
        } else if (pagenumber === "<<") {
            pageno = 1;
        } else if (pagenumber === ">>") {
            pageno = lastVal;
        }
        else {
            pageno = pagenumber;
        }
        this.setState({
            registrationCurrentPage: pageno
        })
    }

    changetelltalePage = (pagenumber) => {
        let pageno;
        let { TelltaleCurrentPage, fleetEvents, geofencealerts, telltale } = this.state;
        let activeData = 0;
        if (fleetEvents == false && geofencealerts == false && telltale == false) {
            activeData = this.state.fleetVechicleBasedList.length;
        } else if (telltale == true) {
            activeData = this.state.vehicleRegBasedView == false ? this.state.telltaleactivedata.length : this.state.telltalesidebarlist.length;
        } else if (fleetEvents == true) {
            activeData = this.state.fleetEventSidebarList.length;
        }
        let lastVal = (Math.ceil(activeData / this.state.TelltalePerPage));
        if (pagenumber === "<") {
            pageno = TelltaleCurrentPage - 1 !== 0 ? TelltaleCurrentPage - 1 : 1;
        } else if (pagenumber === ">") {
            pageno = TelltaleCurrentPage + 1 !== lastVal + 1 ? TelltaleCurrentPage + 1 : lastVal;
        } else if (pagenumber === "<<") {
            pageno = 1;
        } else if (pagenumber === ">>") {
            pageno = lastVal;
        }
        else {
            pageno = pagenumber;
        }
        this.setState({
            TelltaleCurrentPage: pageno
        })
    }

    changeview = (fleetDetail) => {
        fleetDetail.selectFleet.sort((a, b) => {
            const aDate = new Date(a.fleetTime)
            const bDate = new Date(b.fleetTime)
            return bDate.getTime() - aDate.getTime()
        })
        this.setState({
            viewlocation: true,
            detailFleetDetail: fleetDetail,
            selectedFleetDetail: fleetDetail.selectFleet
        })
    }

    changetelltaleview = (detail) => {
        let showdetail = []
        showdetail.time_stamp = detail.time_stamp
        showdetail.location = detail.location
        showdetail.value = Number(detail.value).toFixed(2)
        showdetail.reg_no = detail.reg_no
        this.setState({
            viewdetails: true,
            showdetail: showdetail
        })
    }

    changeFleetAlerts = (activename, dates) => {
        let filterAlerts = []
        if (activename === "Geofence") {
            filterAlerts = this.state.geofenceAlertsdata.filter((alerts) => {
                return (alerts.severity === activename);
            });
        }
        else {
            if (activename === "Service" && this.state.active === false) {
                let fleetData = {};
                var startDate = new Date();
                var endDate = new Date();
                startDate.setDate(startDate.getDate() - 6);
                let tokens = this.props.ctr.token;
                fleetData.userId = this.props.ctr.userid;
                fleetData.platform = "w";
                fleetData.vins = this.state.globalVINList;
                fleetData.fromDate = this.changeformatDate(startDate);
                fleetData.toDate = this.changeformatDate(endDate);
                fleetData.isActive = true;
                let alertsCount = postData(
                    this.props.ctr.userrole === "DEALER OUTLET ADMIN" ? apilist.getFence + '/deviceActiveAlertsCountDealer'
                        : apilist.getFence + '/deviceActiveAlertsCount',
                    fleetData, tokens);
                this.setState({ loader: true, active: true, })
                alertsCount.then((response) => {
                    if (response.statuscode === 200) {
                        response.data.alertList.map((list) => {
                            return list.alert_type_id.toString();
                        })

                        filterAlerts = response.data.alertList.filter((alerts) => {
                            return (alerts.severity === activename);
                        });

                        if (activename === 'Service') {
                            filterAlerts = filterAlerts.sort((function (a, b) { return a.alert_type_id - b.alert_type_id }));
                        }

                        if (filterAlerts.length) {
                            this.setState({
                                fleetactiveAlerts: response.data.alertList,
                                geofencealerts: false,
                                fleetEvents: false,
                                telltale: false,
                                filterFleetactiveAlerts: filterAlerts,
                                alertActive: activename,
                                activetype: activename
                            }, () => {
                                this.getFleetAlertDetails(filterAlerts[0]);
                            })
                        } else {
                            this.setState({
                                fleetdetails: [],
                                excelData: [],
                                exceltempFleetdetails: [],
                                viewlocation: false,
                                sidebarActive: "",
                                sidebargeofenceActive: "",
                                eventSidebarActive: "",
                                loader: false,
                                telltaleactive: '',
                                fleetVechicleBasedList: [],
                                //vehicleRegBasedView:false,
                            })
                        }
                        return false;
                    }
                    else {
                        this.setState({ loader: false })
                    }
                });

            }
            else {
                filterAlerts = this.state.fleetactiveAlerts.filter((alerts) => {
                    return (alerts.severity === activename);
                });
                if (activename === 'Service' || activename === 'Fuel') {
                    filterAlerts = filterAlerts.sort((function (a, b) { return a.alert_type_id - b.alert_type_id }));
                }
            }
        }
        if (filterAlerts.length) {
            this.setState({
                alertActive: activename,
                activetype: activename,
                filterFleetactiveAlerts: filterAlerts,
            }, () => {
                this.getFleetAlertDetails(filterAlerts[0]);
            });
        } else {
            this.setState({
                fleetdetails: [],
                excelData: [],
                exceltempFleetdetails: [],
                viewlocation: false,
                sidebarActive: "",
                sidebargeofenceActive: "",
                eventSidebarActive: "",
                //loader: false,
                telltaleactive: '',
                fleetVechicleBasedList: [],
                showDatePicker:false
                //vehicleRegBasedView:false,
            })
        }

        if (activename === 'Events') {
            this.setState({
                geofencealerts: false,
                fleetEvents: true,
                telltale: false
            }, () => {
                this.callEventsSidebar('Remainder');
            })
        }
        else if (activename === "Telltale") {

            this.setState({
                geofencealerts: false,
                // telltale: true,
                fleetEvents: false,
                telltaleactivedataregn: false
            }, () => {
                this.callTelltaleSidebar(activename);
            });
        }
        else if (activename === "Geofence") {
            this.setState({
                telltale: false,
                fleetEvents: false,
                geofencealerts: true,

            })
        }
        else {
            this.setState({
                geofencealerts: false,
                fleetEvents: false,
                telltale: false,
            })
        }

        this.setState({
            loader: false,
            filterFleetactiveAlerts: filterAlerts,
            alertActive: activename, activetype: activename
        })
    }

    updatedData = (a, b) => {
        var finallat = []
        var finallngs
        if (a && b) {
            finallat = a
            finallngs = b
            let val = {}
            val['lat'] = a
            val['lng'] = b
            this.setState({
                zoomToMarkers: map => {
                    //debugger
                    if (map && finallngs && finallat) {
                        map.fitBounds({
                            west: Math.min.apply(null, finallngs),
                            east: Math.max.apply(null, finallngs),
                            north: Math.min.apply(null, finallat),
                            south: Math.max.apply(null, finallat),
                        });
                    }
                }, zoom: 14, center: val
            });
        }
    }

    callMap = (fleet) => {
        let listval = []
        let val = {}
        this.updatedData(fleet.lat, fleet.lng)
        val['lat'] = fleet.lat
        val['lng'] = fleet.lng
        val['location'] = fleet.fleetLocation
        val['regn_number'] = fleet.regn_number
        val['fleetTime'] = moment(fleet.fleetTime).format("DD-MM-YYYY h:mm:ss A")
        listval.push(val)
        this.setState({ showMap: "block", traceData: listval })
    }

    callInactive = (fleet) => {
        let listval = []
        let val = {}
        this.updatedData(fleet.endLat, fleet.endLong)
        val['lat'] = fleet.endLat
        val['lng'] = fleet.endLong
        val['location'] = fleet.fleetEndLocation
        val['regn_number'] = fleet.regn_number
        val['fleetTime'] = moment(fleet.fleetEndTime).format("DD-MM-YYYY h:mm:ss A")
        listval.push(val)
        this.setState({ showMap: "block", traceData: listval })
    }
    callTelltaleMap = (fleet) => {
        let listval = []
        let val = {}
        this.updatedData(fleet.lat, fleet.lng)
        val['lat'] = fleet.lat
        val['lng'] = fleet.lng
        val['location'] = fleet.location
        val['regn_number'] = fleet.reg_no
        val['fleetTime'] = moment(fleet.fleetTime).format("DD-MM-YYYY h:mm:ss A")
        listval.push(val)
        this.setState({ showMap: "block", traceData: listval })
    }
    backToFleetTable = () => {
        this.setState({
            viewlocation: false, currentPage: 1, remainderCurrentPage: 1, TelltaleCurrentPage: 1, registrationCurrentPage: 1,
        })
    }

    backToTelltaleTable = () => {
        this.setState({
            viewdetails: false, currentPage: 1, remainderCurrentPage: 1, TelltaleCurrentPage: 1, registrationCurrentPage: 1,
        })
    }

    dateFilter = (dateVal, timeorDate) => {
        if (dateVal) {
            return timeorDate ?
                dateVal.substring(8, 10) + "/" + dateVal.substring(5, 7) + "/" + dateVal.substring(2, 4) :
                dateVal.substring(11, 20);
        }
        else {
            return '';
        }
    }

    showFilterHandler = () => {
        this.setState({
            isShowFilter: !this.state.isShowFilter
        })
    }

    callEventsSidebar = (severityVal) => {
        let tokens = this.props.ctr.token;
        let severity = severityVal === 'Events' ? 'Remainder' : severityVal
        let fleetData = {};
        fleetData.userId = this.props.ctr.userid;
        fleetData.platform = "web";
        fleetData.vins = this.state.globalVINList;
        fleetData.alerttype = severity
        const fleetdetails = postData(
            this.props.ctr.userrole === "DEALER OUTLET ADMIN" ? apilist.getFence + '/eventalertsDealer'
                : apilist.getFence + '/eventalerts',
            fleetData, tokens);
        this.setState({
            loader: true
        })
        fleetdetails.then((response) => {
            if (response.statuscode === 200) {
                this.formatFleetEventsresponse(response.data[0], severity);
                //this.showEventCount(alertDtls[0].alertinfo);
            }
            else if (response.statuscode === 400) {
                this.setState({
                    siderbarEventsList: [],
                    eventAlertInfo: [],
                    eventSidebarActive: "",
                    sidebarEventsInd: severityVal === 'Remainder' ? true : false,
                    loader: false
                })
            }
            else {
                alert("failure");
            }
        });
    }

    formatFleetEventsresponse = (response, severityVal) => {
        let alertDtls = response;
        let sidebarEventsInd = false;
        let siderbarEventsList = []
        if (severityVal === 'Remainder') {
            sidebarEventsInd = true;
            siderbarEventsList = alertDtls.reminderData;
        }
        else {
            sidebarEventsInd = false;
            siderbarEventsList = alertDtls.occassionData;
        }

        siderbarEventsList.length > 0
            ? this.showEvents(siderbarEventsList[0].alertinfo, siderbarEventsList[0].alert_type_name) :
            this.showEvents([], "");

        this.setState({
            OccassionsCount: alertDtls.occassionCount,
            remainderCount: alertDtls.reminderCount,
            siderbarEventsList: siderbarEventsList,
            sidebarEventsInd: sidebarEventsInd,
            //vehicleRegBasedView:false,
            fleetEventSidebarList: [],
            loader: false,
            severityVal: severityVal
        }, () => {
            if (this.state.vehicleRegBasedView == true) {
                this.setState({
                    vehicleRegBasedView: false
                }, () => {
                    this.vehicleviewSwitch();
                })
            }
        })

    }
    passingVal = () => {
        this.setState({ showMap: "none", zoom: 0 })
    }

    // showEventCount = (alertDtls) =>{
    //    let remainderCount = alertDtls.filter(alert => alert.alert_type === "Remainder").length;
    //    let OccassionsCount = alertDtls.filter(alert => alert.alert_type === "Occassions").length;
    //    this.setState({
    //         remainderCount:remainderCount,
    //         OccassionsCount:OccassionsCount
    //    })
    // }

    showEvents = (alertInfo, alertType) => {
        let headerValue = "Vin Number"
        if (alertType === "Medical FC Expiry" || alertType === "Driving Licence Expiry") {
            headerValue = "Driver Name";
        } else {
            headerValue = "Vin Number";
        }
        this.setState({
            eventAlertInfo: alertInfo,
            eventSidebarActive: alertType,
            remainderCurrentPage: 1,
            eventReminderHeader: headerValue
        })
    }

    downloadExcel = () => {

        // let fleetData = {};

        // var startDate = new Date();
        // var endDate = new Date();
        // let tokens = this.props.ctr.token;
        // fleetData.userId = this.props.ctr.userid;
        // fleetData.platform = "web";
        // if(this.state.activetype === "Geofence"){
        //     fleetData.isActive = false;
        //     fleetData.alertTypeId = this.state.geoalertTypeIds
        //     startDate.setDate(startDate.getDate() - this.state.activegeoFilterdata);
        // }
        // else{
        //     fleetData.isActive = this.state.active;
        //     fleetData.alertTypeId = this.state.alertTypeIds
        //     startDate.setDate(startDate.getDate() - this.state.activeFilterdata);
        // }
        // fleetData.fromDate = this.changeformatDate(startDate);
        // fleetData.toDate = this.changeformatDate(endDate);
        // fleetData.vins = this.state.globalVINList;
        // fleetData.alertTypeName = ""
        // this.setState({ loader: true, initialDtls: false });
        // const fleetdetails = postData(
        //     this.props.ctr.userrole === "DEALER OUTLET ADMIN" ? apilist.getFence + '/viewActiveFleetAlertsDetailsDealer'
        //         : apilist.getFence + '/viewActiveFleetAlertsDetails',
        //     fleetData, tokens);
        // fleetdetails.then((response) => {
        let excelData = this.state.excelData;
        // if(this.state.alertActive == "Geofence"){
        //     excelData = this.state.exceltempFleetdetails;
        // }
        var downloadData = excelData.filter((alerts) => {
            return (alerts.severity === this.state.activetype);
        });
        alasql.fn.updateTimeStamp = function (val) {

            if (val === undefined) {
                return '';
            }

            const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            let current_datetime = new Date(val);
            let hours = current_datetime.getHours();
            let minutes = current_datetime.getMinutes();
            let ampm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            minutes = minutes < 10 ? '0' + minutes : minutes;
            var strTime = hours + ':' + minutes + ' ' + ampm;

            let formatted_date = months[current_datetime.getMonth()] + " " + current_datetime.getDate() + ", " + current_datetime.getFullYear() + " " + strTime;
            return formatted_date;
        }

        alasql.fn.nullCheck = function (val, name) {
            if (val === null || val === ' : 0') {
                return 'N/A';
            }
            else {
                if (name === 'due_km') {
                    let header = val.split(':')[1].trim();
                    return header;
                }
                return val;
            }
        }

        alasql.fn.pad = (num) => {
            return ("0" + num).slice(-2);
        }

        alasql.fn.alertinfo1forGeofence = function (val, startDate, endDate, alertName) {
            if (val === null || val === ' : 0') {
                return 'NA';
            }
            else {
                if (alertName === "Vehicle Exit from Geofence") {
                    var diffInHours = Math.abs(new Date(endDate) - new Date(startDate)) //milisecs
                    var seconds = diffInHours / 1000;
                    var hours = parseInt(seconds / 3600); // 3,600 seconds in 1 hour
                    seconds = seconds % 3600; // seconds remaining after extracting hours
                    var minutes = parseInt(seconds / 60); // 60 seconds in 1 minute
                    seconds = Math.round(seconds % 60);
                    return val.split(": ")[0] + " : " + hours + ":" + minutes + ":" + seconds + " " + 'hh:mm:ss';
                }
                else if (alertName === "Over Stoppage Inside Geofence") {
                    let secs = val
                    if (secs != ' : 0') {
                        var mins = secs.split(': ')[1]
                        var MINUTES = mins.split(' ')[0]
                        var m = MINUTES % 60;
                        var h = (MINUTES - m) / 60;
                        var HHMM = secs.split(': ')[0] + " : " + h.toString() + ":" + (m < 10 ? "0" : "") + m.toString() + ":" + "00" + " " + mins.split(' ')[1];
                        return HHMM
                    }
                    else {
                        return 'NA';
                    }
                }
                else {
                    return val;
                }
            }
        }

        alasql.fn.nullCheckforGeofence = function (val, alertName) {
            if (val === null || val === ' : 0') {
                return 'NA';
            }
            else {
                if (alertName === "Over Stoppage Inside Geofence") {
                    let secs = val
                    if (secs != ' : 0') {
                        var mins = secs.split(': ')[1]
                        var MINUTES = mins.split(' ')[0]
                        var m = MINUTES % 60;
                        var h = (MINUTES - m) / 60;
                        var HHMM = secs.split(': ')[0] + " : " + h.toString() + ":" + (m < 10 ? "0" : "") + m.toString() + ":" + "00" + " " + mins.split(' ')[1];
                        return HHMM
                    }
                    else {
                        return 'NA';
                    }
                }
                else {
                    return val;
                }
            }
        }

        alasql.fn.getExcelDate = (date) => {
            return (date === '' || date === null) ? '' : exceldateformatter(date)
        }

        alasql.fn.getExcelDatetime = (date) => {
            return (date === '' || date === null) ? '' : exceldatetimestampformatter(date);
        }

        alasql.fn.gethoursfromDate = () => {

        }
        const servicesAlerts = downloadData[0].alert_type_name;
        if (this.state.alertActive !== "Geofence") {
            if (this.state.alertActive === "Service") {
                alasql('SELECT regn_number AS [Registration Number], vin AS [Vin Number], obu_id AS [OBU ID], nullCheck(alert_type_name) AS [Alert Type Name],' +
                    'nullCheck(severity) AS [Severity], nullCheck(ignition_status) AS [Ignition Status], nullCheck(start_location) AS [Start Location], getExcelDatetime(start_timestamp_utc) AS [Start Timestamp],' +
                    'nullCheck(end_location) AS [End Location], getExcelDatetime(end_timestamp) AS [End Timestamp], nullCheck(alert_info_1, "due_km") AS [Due kms/hrs],' +
                    'nullCheck(alert_info_2) AS [Alert Info 2], nullCheck(alert_info_3) AS [Alert Info 3] ' +
                    `INTO XLSX("${servicesAlerts}", {headers:true}) FROM ?`, [downloadData]);
            }
            else {
                if(this.state.activetype==="Fuel"){
                    alasql('SELECT regn_number AS [Registration Number], vin AS [Vin Number], obu_id AS [OBU ID], nullCheck(alert_type_name) AS [Alert Type Name],' +
                    'nullCheck(severity) AS [Severity],nullCheck(ignition_status) AS [Ignition Status], nullCheck(start_location) AS [Start Location], getExcelDatetime(start_timestamp_utc) AS [Start Timestamp],' +
                    'nullCheck(end_location) AS [End Location], getExcelDatetime(end_timestamp) AS [End Timestamp], nullCheck(alert_info_1) AS [Alert Info 1],' +
                    'nullCheck(alert_info_2) AS [Alert Info 2], nullCheck(alert_info_3) AS [Alert Info 3] ' +
                    'INTO XLSX("Fuel", {headers:true}) FROM ?', [downloadData]);
                } else{
                    alasql('SELECT regn_number AS [Registration Number], vin AS [Vin Number], obu_id AS [OBU ID], nullCheck(alert_type_name) AS [Alert Type Name],' +
                    'nullCheck(severity) AS [Severity], nullCheck(start_location) AS [Start Location], getExcelDatetime(start_timestamp_utc) AS [Start Timestamp],' +
                    'nullCheck(end_location) AS [End Location], getExcelDatetime(end_timestamp) AS [End Timestamp], nullCheck(alert_info_1) AS [Alert Info 1],' +
                    'nullCheck(alert_info_2) AS [Alert Info 2], nullCheck(alert_info_3) AS [Alert Info 3] ' +
                    'INTO XLSX("Warning", {headers:true}) FROM ?', [downloadData]);
                }
            }
        }

        else {
            alasql('SELECT regn_number AS [Registration Number], vin AS [Vin Number], obu_id AS [OBU ID], nullCheck(alert_type_name) AS [Alert Type Name],' +
                'nullCheck(severity) AS [Severity] ,nullCheck(geofence_name) AS [Geofence Name], nullCheck(start_location) AS [Start Location], getExcelDatetime(start_timestamp_utc) AS [Start Timestamp],' +
                'nullCheck(end_location) AS [End Location], getExcelDatetime(end_timestamp) AS [End Timestamp], alertinfo1forGeofence(alert_info_1,start_timestamp_utc,end_timestamp,alert_type_name) AS [Alert Info 1],' +
                'nullCheckforGeofence(alert_info_2,alert_type_name) AS [Alert Info 2], nullCheckforGeofence(alert_info_3,alert_type_name) AS [Alert Info 3] ' +
                'INTO XLSX("Fleet_alerts", {headers:true}) FROM ?', [downloadData]);
        }

        this.setState({ loader: false })
        // });
    }

    downloadExcelEvents = () => {
        alasql.fn.moment = moment;
        alasql.fn.dateonly = (date) => {
            let formatdate = moment(date).format('DD/MM/YYYY');
            return formatdate
        }

        alasql.fn.getExcelDate = (date) => {
            return (date === '' || date === null) ? '' : exceldateformatter(date)
        }

        alasql.fn.getExcelDatetime = (date) => {
            return (date === '' || date === null) ? '' : exceldatetimestampformatter(date)
        }

        if (this.state.sidebarEventsInd) {
            if (this.state.eventSidebarActive !== 'Driving Licence Expiry') {
                if (this.state.eventSidebarActive !== "iALERT Subscription Expiry") {
                    alasql('SELECT regn_number AS [Registration Number], vin_number AS [Vin Number],' +
                        'document_number AS [Document Number],alert_type_name AS [Alert Type Name],' +
                        'getExcelDate(insurance_expiry_date) AS [Expiry date]' +
                        'INTO XLSX("Fleet_Events_' + this.state.eventSidebarActive + '", {headers:true}) FROM ?', [this.state.eventAlertInfo]);
                }
                else {
                    alasql('SELECT regn_number AS [Registration Number], vin_number AS [Vin Number],' +
                        'obu_id AS [Device ID],alert_type_name AS [Alert Type Name],' +
                        'getExcelDate(insurance_expiry_date) AS [Expiry date]' +
                        'INTO XLSX("Fleet_Events_' + this.state.eventSidebarActive + '", {headers:true}) FROM ?', [this.state.eventAlertInfo]);
                }

            }
            else {
                alasql('SELECT vin_number AS [Driver Name], document_number AS [Document Number],' +
                    'alert_type_name AS [Alert Type Name],getExcelDate(insurance_expiry_date) AS [Expiry date]' +
                    'INTO XLSX("Fleet_Events_' + this.state.eventSidebarActive + '", {headers:true}) FROM ?', [this.state.eventAlertInfo]);
            }
        }
        else {
            alasql('SELECT emp_name AS [Name], document_number AS [Designation],' +
                'alert_type_name AS [Alert Type Name],getExcelDate(insurance_expiry_date) AS [Date of Occasion]' +
                'INTO XLSX("Fleet_Events_' + this.state.eventSidebarActive + '", {headers:true}) FROM ?', [this.state.eventAlertInfo]);
        }
    }

    selectFuelAlertsDateRange = () => {
        this.setState({ showDatePicker: true });
    }
    changeDatePickerDate = (fromDate, toDate) => {
        this.setState({fuelAlertStartDate: fromDate, fuelAlertEndDate: toDate})
    }
    resetDateFields = () => {
        this.setState({fuelAlertStartDate: this.state.sevenDaysAgo, fuelAlertEndDate: this.state.toDayDate})
    }
    closeFuelAlertsDatePicker = () => {
        this.setState({ showDatePicker: false });
    }

    downloadExceltelltale = () => {
        alasql.fn.moment = moment;

        alasql.fn.datetime = (date) => {
            let formatdate = moment(date).format('DD/MM/YYYY HH:mm:ss');
            return formatdate
        }

        alasql.fn.dateonly = (date) => {
            let formatdate = moment(date).format('DD/MM/YYYY');
            return formatdate
        }
        alasql.fn.getExcelDate = (date) => {
            return date === '' || date === null ? '' : exceldateformatter(date)
        }

        alasql.fn.getExcelDatetime = (date) => {
            return date === '' || date === null ? '' : exceldatetimestampformatter(date);
        }

        let activedata = this.state.telltaleexceldata;

        for (let i = 0; i < activedata.length; i++) {
            if (activedata[i].alert_name === 'Low Coolant Level' || activedata[i].alert_name === 'EDC Warning Lamp' || activedata[i].alert_name === 'STOP Lamp' || activedata[i].alert_name === 'Air Filter Blocked') {
                if (activedata[i].alertvalue === 1) {
                    activedata[i].alertvalue = 'ON'
                }
                else {
                    activedata[i].alertvalue = 'OFF'
                }
            }
            else if (activedata[i].alert_name === 'MIL status') {
                if (activedata[i].alertvalue === 1) {
                    activedata[i].alertvalue = 'ON'
                }
                else {
                    activedata[i].alertvalue = 'OFF'
                }
            }
            else if (activedata[i].alert_name === 'Regeneration needed') {
                if (activedata[i].alertvalue === 1 || activedata[i].alertvalue === 2 || activedata[i].alertvalue === 3) {
                    activedata[i].alertvalue = 'ON'
                }
                else {
                    activedata[i].alertvalue = 'OFF'
                }
            }
            else if (activedata[i].alert_name === 'NOx Error Lamp') {
                if (activedata[i].alertvalue === 1 || activedata[i].alertvalue === 2) {
                    activedata[i].alertvalue = 'ON'
                }
                else {
                    activedata[i].alertvalue = 'OFF'
                }
            }
            else if (activedata[i].alert_name === "Particulate Trap Regeneration Inhibit Switch") {
                activedata[i].alertvalue = activedata[i].value
            }
            else {
                activedata[i].alertvalue = Number(activedata[i].alertvalue).toFixed(2)
            }
        }
        alasql(`SELECT
        reg_no AS [Registration Number],
        vin_no AS [Vin Number],
        obu_id AS [Device ID],
        alert_name AS [Alert Name],
        getExcelDatetime(time_stamp) AS [Timestamp],
        location AS [Location],
        alertvalue AS [Value]
        INTO XLSX("Telltale", {headers:true}) FROM ?`, [activedata]);
    }

    alertImageName = (imageName) => {
        if (imageName) {
            let arr = imageName.split(" ");
            if (arr.length > 1) {
                return arr[0].substr(0, 1) + "" + arr[1].substr(0, 1);
            }
        }
    }

    callTelltaleSidebar = () => {
        this.setState({ loader: true })
        let tokens = this.props.ctr.token;
        let fleetData = {};
        fleetData.userId = this.props.ctr.userid;
        fleetData.platform = "w";
        fleetData.alerttype = "Remainder";
        fleetData.vins = this.state.globalVINList;
        const fleedetails = postData(apilist.getFence + '/telltaleAlerts', fleetData, tokens)
        fleedetails.then((response) => {
            if (response.statuscode === 200 && response.data.totalCount > 0) {
                let sidebarkeyobj = response.data.count_list
                let activealert = []
                for (let i = 0; i < sidebarkeyobj.length; i++) {
                    if (sidebarkeyobj[i].count > 0) {
                        activealert.push(sidebarkeyobj[i])
                    }
                }
                let sidebarkeyobjdata = response.data.vehicle_list;
                this.formatTelltaleAlerts(sidebarkeyobjdata, activealert, response.data.totalCount);

            }
            else {
                this.formatTelltaleAlerts([], [], response.data.totalCount);
                this.setState({ loader: false })
            }
        })
    }

    formatTelltaleAlerts = (sidebarkeyobjdata, activealert, dataCount) => {
        let activealertdata = []
        if (activealert.length === 0) {
            for (var item in sidebarkeyobjdata) {
                let obj = {};
                if (sidebarkeyobjdata[item].length > 0) {
                    obj['alert_type_id'] = item;
                    obj['alert_type_name'] = sidebarkeyobjdata[item][0]['alert_name'];
                    obj['count'] = sidebarkeyobjdata[item].length;
                    activealert.push(obj);
                }
            }
        }
        activealertdata = activealert.length > 0 ? sidebarkeyobjdata[activealert[0].alert_type_id] : [];

        for (let i = 0; i < activealertdata.length; i++) {
            if (activealertdata[i].alert_name === 'Low Coolant Level' || activealertdata[i].alert_name === 'EDC Warning Lamp' || activealertdata[i].alert_name === 'STOP Lamp' || activealertdata[i].alert_name === 'Air Filter Blocked') {
                if (activealertdata[i].value === 1) {
                    activealertdata[i].val = 'ON'
                }
                else {
                    activealertdata[i].val = 'OFF'
                }
            }
            else if (activealertdata[i].alert_name === 'MIL status') {
                if (activealertdata[i].value === 4) {
                    activealertdata[i].val = 'ON'
                }
                else {
                    activealertdata[i].val = 'OFF'
                }
            }
            else if (activealertdata[i].alert_name === 'Regeneration needed') {
                if (activealertdata[i].value === 1 || activealertdata[i].value === 2 || activealertdata[i].value === 3) {
                    activealertdata[i].val = 'ON'
                }
                else {
                    activealertdata[i].val = 'OFF'
                }
            }
            else if (activealertdata[i].alert_name === 'NOx Error Lamp') {
                if (activealertdata[i].value === 1 || activealertdata[i].value === 2) {
                    activealertdata[i].val = 'ON'
                }
                else {
                    activealertdata[i].val = 'OFF'
                }
            }
            else {
                activealertdata[i].val = Number(activealertdata[i].value).toFixed(2)
            }
        }

        let alldata = []
        for (let i = 0; i < activealert.length; i++) {
            for (let j = 0; j < sidebarkeyobjdata[activealert[i].alert_type_id].length; j++) {
                alldata.push(sidebarkeyobjdata[activealert[i].alert_type_id][j])
            }
        }
        for (let v = 0; v < alldata.length; v++) {
            alldata[v].alertvalue = alldata[v]['value']
        }

        this.setState({
            telltale: true,
            telltaletotalCount: dataCount,
            telltalesidebarlist: this.state.vehicleRegBasedView ? [] : activealert,
            telltaleactive: activealert.length > 0 ? activealert[0]['alert_type_name'] : [],
            telltaleactivedataAll: sidebarkeyobjdata,
            telltaleactivedata: activealertdata,
            TelltaleCurrentPage: 1,
            telltaleexceldata: alldata,
            telltaleseveritycnt: dataCount,
            loader: false,
            sortedTelltale: []
            //vehicleRegBasedView:false
        }, () => {
            if (this.state.vehicleRegBasedView == true) {
                this.setState({
                    vehicleRegBasedView: false,
                    telltalesidebarlist: []
                }, () => {
                    this.vehicleviewSwitch();
                })
            }
        });
    }

    showActiveTelltale = (alertname) => {
        let activealertdata = []
        activealertdata = this.state.telltaleactivedataAll[alertname.alert_type_id]
        for (let i = 0; i < activealertdata.length; i++) {
            if (activealertdata[i].alert_name === 'Low Coolant Level' || activealertdata[i].alert_name === 'EDC Warning Lamp' || activealertdata[i].alert_name === 'STOP Lamp' || activealertdata[i].alert_name === 'Air Filter Blocked') {
                if (activealertdata[i].value === 1) {
                    activealertdata[i].val = 'ON'
                }
                else {
                    activealertdata[i].val = 'OFF'
                }
            }
            else if (activealertdata[i].alert_name === 'MIL status') {
                if (activealertdata[i].value === 4) {
                    activealertdata[i].val = 'ON'
                }
                else {
                    activealertdata[i].val = 'OFF'
                }
            }
            else if (activealertdata[i].alert_name === 'Regeneration needed') {
                if (activealertdata[i].value === 1 || activealertdata[i].value === 2 || activealertdata[i].value === 3) {
                    activealertdata[i].val = 'ON'
                }
                else {
                    activealertdata[i].val = 'OFF'
                }
            }
            else if (activealertdata[i].alert_name === 'NOx Error Lamp') {
                if (activealertdata[i].value === 1 || activealertdata[i].value === 2) {
                    activealertdata[i].val = 'ON'
                }
                else {
                    activealertdata[i].val = 'OFF'
                }
            }
            else {
                activealertdata[i].val = Number(activealertdata[i].value).toFixed(2)
            }
        }

        this.setState({
            telltaleactive: alertname.alert_type_name,
            telltaleactivedata: activealertdata,
            TelltaleCurrentPage: 1
        })
    }

    infocheck = (val) => {
        if (val === ' : 0' || val === null) {
            return 'NA'
        }
        else {
            return val.split(': ')[1]
        }
    }

    headerinfocheck = (val, title) => {
        if (val === ' : 0' || val === null) {
            return title
        }
        else {
            let header = val.split(':')[0].trim();
            if (header === 'ODO Reading') {
                return val.split(':')[0] + '(km)'
            }
            if (header === 'Engine Hrs') {
                return val.split(':')[0] + '(hrs)'
            }
            if (header === 'due_km' || header === 'Due km') {
                return 'Due kms/hrs'
            }
            if (header === 'due_date') {
                return 'Due Date'
            }
            return val.split(':')[0]
        }
    }

    tabsort = () => {
        let vars = 'insurance_expiry_date';
        let sort = this.state.sort;
        let sortorder = ''
        let sortedData1 = this.state.eventAlertInfo
        let tabchk = sortedData1
        tabchk.sort(function (a, b) {
            a = a[vars];
            b = b[vars];
            sortorder = sort === 'asc' ? 'desc' : 'asc';
            if (sort === 'asc') {
                return new Date(b) - new Date(a);
            }
            else {
                return new Date(a) - new Date(b);
            }
        })
        this.setState({
            sort: sortorder
        });
    };

    searchInputFilterGeo = (e, n) => {
        let searchTable = e.target.value;
        this.setState({ searchVal: n })
        if (n != "sideregister_number") {
            if (e.target.value !== "") {
                let fleetdetailsval = []
                fleetdetailsval = this.state.fleetdetails.filter((name) => {
                    if (n == 'regn_number') {
                        return (name.regn_number.toLowerCase().indexOf(searchTable.toLowerCase()) !== -1)
                    }
                    else if (n == 'obu_id') {
                        return (name.obu_id.toLowerCase().indexOf(searchTable.toLowerCase()) !== -1)
                    }
                    else if (n == 'geofence_name') {
                        return (name.geofence_name.toLowerCase().indexOf(searchTable.toLowerCase()) !== -1)
                    }
                    else if (n == 'alert_name') {
                        return (name.alert_type_name.toLowerCase().indexOf(searchTable.toLowerCase()) !== -1)
                    }
                    else {
                        return (name.Occurance.toString().indexOf(searchTable.toLowerCase()) !== -1)
                    }
                });
                if (n == 'regn_number') {
                    this.setState({ searchInputreg: searchTable })
                }
                this.setState({ fleetdetails: fleetdetailsval, searchInput: searchTable })
            }
            else {
                this.setState({ fleetdetails: this.state.fleetdetailssearch, searchInput: "", searchInputreg: "" })
            }
        } else {
            if (this.state.geofencealerts == false && this.state.fleetEvents == false && this.state.telltale == false || this.state.geofencealerts == true) {
                let fleetAlerts = this.state.sortedFleetAlerts;
                let sidebarRegnoList = [];
                if (searchTable.length >= 3) {
                    let sorted_value = this.sortAlerts(fleetAlerts);
                    sidebarRegnoList = sorted_value.filter((item) => item.toLowerCase().indexOf(searchTable.toLowerCase()) >= 0);

                } else {
                    sidebarRegnoList = this.sortAlerts(fleetAlerts);
                }
                this.setState({
                    fleetVechicleBasedList: sidebarRegnoList,
                    fleetdetails: fleetAlerts[sidebarRegnoList[0]] !== undefined ? fleetAlerts[sidebarRegnoList[0]] : [],
                    //telltaleactive: sidebarRegnoList[0],
                    TelltaleCurrentPage: 1,
                    vehicleRegBasedView: true,
                    fleetActiveRegno: sidebarRegnoList.length > 0 ? sidebarRegnoList[0] : ""
                    //telltaleactivedata: activealertdata,
                });
            }
            else if (this.state.telltale == true) {
                let telltaleAlerts = this.state.sortedTelltale;
                let sidebarRegnoList = [];
                let activealertdata = [];
                if (searchTable.length >= 3) {
                    let sorted_value = this.sortAlerts(telltaleAlerts);
                    sidebarRegnoList = sorted_value.filter((item) => item.toLowerCase().indexOf(searchTable.toLowerCase()) >= 0);
                    activealertdata = this.filterTelltaleAlert(telltaleAlerts, sidebarRegnoList[0], this.state.telltaleexceldata);


                } else {
                    sidebarRegnoList = this.sortAlerts(telltaleAlerts);
                    activealertdata = this.filterTelltaleAlert(telltaleAlerts, sidebarRegnoList[0], this.state.telltaleexceldata);
                }
                this.setState({
                    telltalesidebarlist: sidebarRegnoList,
                    telltaleactivedata: activealertdata,
                    TelltaleCurrentPage: 1,
                    telltaleactive: sidebarRegnoList.length > 0 ? sidebarRegnoList[0] : ""
                });

            }
            else if (this.state.fleetEvents == true) {
                let fleetAlerts = this.state.sortedFleetEvents;
                let sidebarRegnoList = [];
                if (searchTable.length >= 3) {
                    let sorted_value = this.sortAlerts(fleetAlerts);
                    sidebarRegnoList = sorted_value.filter((item) => item.toLowerCase().indexOf(searchTable.toLowerCase()) >= 0);

                } else {
                    sidebarRegnoList = this.sortAlerts(fleetAlerts);
                }
                this.setState({
                    fleetEventSidebarList: sidebarRegnoList,
                    eventAlertInfo: fleetAlerts[sidebarRegnoList[0]] !== undefined ? fleetAlerts[sidebarRegnoList[0]] : [],
                    //telltaleactive: sidebarRegnoList[0],
                    TelltaleCurrentPage: 1,
                    fleetActiveRegno: sidebarRegnoList.length > 0 ? sidebarRegnoList[0] : ""
                    //telltaleactivedata: activealertdata,
                });
            }


        }
    }

    sortAlerts = (alertstowardsreg_no) => {
        var entries = Object.entries(alertstowardsreg_no);
        var sorted = entries.sort((a, b) => b[1].length - a[1].length);
        let sidebarRegnoList = Object.keys(Object.fromEntries(sorted));
        return sidebarRegnoList;
    }

    sort_alerts_by_alphabet(e, col_name) {
        let sorted_table_data = this.state.fleetdetails;
        if (col_name === "geofence_name") {
            if (this.state.alerts_sort.order === "desc") {
                let sortedData = sorted_table_data.sort(function (a, b) {
                    if (a.geofence_name && b.geofence_name) {
                        a = a.geofence_name.toLowerCase();
                        b = b.geofence_name.toLowerCase();

                        return a < b ? -1 : a > b ? 1 : 0;
                    }
                });

                this.setState({
                    fleetdetails: sortedData,
                    alerts_sort: {
                        column_name: col_name,
                        order: "asc"
                    }
                });
            } else {
                let sortedData = sorted_table_data.sort(function (a, b) {
                    if (a.geofence_name && b.geofence_name) {
                        a = a.geofence_name.toLowerCase();
                        b = b.geofence_name.toLowerCase();

                        return b < a ? -1 : b > a ? 1 : 0;
                    }
                });

                this.setState({
                    fleetdetails: sortedData,
                    alerts_sort: {
                        column_name: col_name,
                        order: "desc"
                    }
                });
            }
        } else if (col_name === "regn_number") {
            if (this.state.alerts_sort.order === "desc") {
                let sortedData = sorted_table_data.sort(function (a, b) {
                    if (a.regn_number && b.regn_number) {
                        a = a.regn_number.toLowerCase();
                        b = b.regn_number.toLowerCase();

                        return a < b ? -1 : a > b ? 1 : 0;
                    }
                });

                this.setState({
                    fleetdetails: sortedData,
                    alerts_sort: {
                        column_name: col_name,
                        order: "asc"
                    }
                });
            } else {
                let sortedData = sorted_table_data.sort(function (a, b) {
                    if (a.regn_number && b.regn_number) {
                        a = a.regn_number.toLowerCase();
                        b = b.regn_number.toLowerCase();

                        return b < a ? -1 : b > a ? 1 : 0;
                    }
                });

                this.setState({
                    fleetdetails: sortedData,
                    alerts_sort: {
                        column_name: col_name,
                        order: "desc"
                    }
                });
            }
        } else if (col_name === "alert_info_1") {
            if (this.state.alerts_sort.order === "desc") {
                let sortedData = sorted_table_data.sort(function (a, b) {
                    if (a.alert_info_1 && b.alert_info_1) {
                        a = a.alert_info_1.toLowerCase();
                        b = b.alert_info_1.toLowerCase();

                        return a < b ? -1 : a > b ? 1 : 0;
                    }
                });

                this.setState({
                    fleetdetails: sortedData,
                    alerts_sort: {
                        column_name: col_name,
                        order: "asc"
                    }
                });
            } else {
                let sortedData = sorted_table_data.sort(function (a, b) {
                    if (a.alert_info_1 && b.alert_info_1) {
                        a = a.alert_info_1.toLowerCase();
                        b = b.alert_info_1.toLowerCase();

                        return b < a ? -1 : b > a ? 1 : 0;
                    }
                });

                this.setState({
                    fleetdetails: sortedData,
                    alerts_sort: {
                        column_name: col_name,
                        order: "desc"
                    }
                });
            }
        } else if (col_name === "alert_info_2") {
            if (this.state.alerts_sort.order === "desc") {
                let sortedData = sorted_table_data.sort(function (a, b) {
                    if (a.alert_info_2 && b.alert_info_2) {
                        a = a.alert_info_2.toLowerCase();
                        b = b.alert_info_2.toLowerCase();

                        return a < b ? -1 : a > b ? 1 : 0;
                    }
                });

                this.setState({
                    fleetdetails: sortedData,
                    alerts_sort: {
                        column_name: col_name,
                        order: "asc"
                    }
                });
            } else {
                let sortedData = sorted_table_data.sort(function (a, b) {
                    if (a.alert_info_2 && b.alert_info_2) {
                        a = a.alert_info_2.toLowerCase();
                        b = b.alert_info_2.toLowerCase();

                        return b < a ? -1 : b > a ? 1 : 0;
                    }
                });

                this.setState({
                    fleetdetails: sortedData,
                    alerts_sort: {
                        column_name: col_name,
                        order: "desc"
                    }
                });
            }
        } else if (col_name === "obu_id") {
            if (this.state.alerts_sort.order === "desc") {
                let sortedData = sorted_table_data.sort(function (a, b) {
                    if (a.obu_id && b.obu_id) {
                        a = a.obu_id.toLowerCase();
                        b = b.obu_id.toLowerCase();

                        return a < b ? -1 : a > b ? 1 : 0;
                    }
                });

                this.setState({
                    fleetdetails: sortedData,
                    alerts_sort: {
                        column_name: col_name,
                        order: "asc"
                    }
                });
            } else {
                let sortedData = sorted_table_data.sort(function (a, b) {
                    if (a.obu_id && b.obu_id) {
                        a = a.obu_id.toLowerCase();
                        b = b.obu_id.toLowerCase();

                        return b < a ? -1 : b > a ? 1 : 0;
                    }
                });

                this.setState({
                    fleetdetails: sortedData,
                    alerts_sort: {
                        column_name: col_name,
                        order: "desc"
                    }
                });
            }
        }
    }

    sort_alerts_by_numeric(e, col_name) {
        let sorted_table_data = this.state.fleetdetails;
        if (col_name === 'Occurance') {
            if (this.state.alerts_sort.order === 'desc') {
                let sortedData = sorted_table_data.sort((function (a, b) { return b.Occurance - a.Occurance }));

                this.setState({
                    fleetdetails: sortedData,
                    alerts_sort: {
                        column_name: col_name,
                        order: 'asc'
                    }
                });
            } else {
                let sortedData = sorted_table_data.sort((function (a, b) { return a.Occurance - b.Occurance }));

                this.setState({
                    fleetdetails: sortedData,
                    alerts_sort: {
                        column_name: col_name,
                        order: 'desc'
                    }
                });
            }
        }
    }

    sort_alert_by_date(e, col_name) {
        let sorted_table_data = this.state.fleetdetails;
        if (col_name === 'start_timestamp_utc') {
            if (this.state.alerts_sort.order === 'desc') {
                let sortedData = sorted_table_data.sort((function (a, b) { return new Date(a.start_timestamp_utc) - new Date(b.start_timestamp_utc) }));
                this.setState({
                    fleetdetails: sortedData,
                    alerts_sort: {
                        column_name: col_name,
                        order: 'asc'
                    }
                });
            } else {
                let sortedData = sorted_table_data.sort((function (a, b) { return new Date(b.start_timestamp_utc) - new Date(a.start_timestamp_utc) }));
                this.setState({
                    fleetdetails: sortedData,
                    alerts_sort: {
                        column_name: col_name,
                        order: 'desc'
                    }
                });
            }
        }
    }

    vehicleviewSwitch = () => {
        try {
            if (this.state.telltale === true) {
                if (this.state.vehicleRegBasedView === false) {
                    let alldata = this.state.telltaleexceldata;
                    let alertstowardsreg_no = [];
                    for (let v = 0; v < alldata.length; v++) {
                        alldata[v].alertvalue = alldata[v]['value']
                        if (alertstowardsreg_no[alldata[v]['reg_no']]) {
                            alertstowardsreg_no[alldata[v]['reg_no']] = alertstowardsreg_no[alldata[v]['reg_no']].concat(alldata[v]['alert_type_id'])
                        } else {
                            let alert_type = [];
                            alert_type.push(alldata[v]['alert_type_id']);
                            alertstowardsreg_no[alldata[v]['reg_no']] = alert_type;
                        }
                    }
                    var entries = Object.entries(alertstowardsreg_no);
                    var sorted = entries.sort((a, b) => b[1].length - a[1].length);
                    let sidebarRegnoList = Object.keys(Object.fromEntries(sorted));

                    let activealertdata = this.filterTelltaleAlert(alertstowardsreg_no, sidebarRegnoList[0], alldata);

                    this.setState({
                        telltale: true,
                        telltalesidebarlist: sidebarRegnoList,
                        telltaleactive: sidebarRegnoList.length > 0 ? sidebarRegnoList[0] : "",
                        sortedTelltale: alertstowardsreg_no,
                        vehicleRegBasedView: true,
                        telltaleactivedata: activealertdata,
                        TelltaleCurrentPage: 1,
                        remainderCurrentPage: 1
                    });
                } else {
                    this.setState({
                        vehicleRegBasedView: false
                    }, () => {
                        this.formatTelltaleAlerts(this.state.telltaleactivedataAll, [], this.state.telltaleseveritycnt);
                    });
                }
            }
            else if (this.state.geofencealerts === false && this.state.fleetEvents == false || this.state.geofencealerts === true && this.state.fleetEvents == false) {
                var filterAlerts = this.state.fleetactiveAlerts.filter((alerts) => {
                    return (alerts.severity === this.state.alertActive);
                });
                if (this.state.vehicleRegBasedView === false) {
                    let fleetMenu = this.state.geofencealerts === true ? this.state.geofenceAlertsdata : filterAlerts;
                    let allData = this.state.excelData;
                    let formattedFleetDetails = [];
                    fleetMenu.forEach((item) => {
                        let fleetdetails = [];
                        if (item.severity != "Geofence") {
                            fleetdetails = this.fleetFilterBasedonId(item.alert_type_id, allData)
                        } else {
                            fleetdetails = this.fleetFilterBasedonAlertName(item.alert_type_name, allData)
                        }
                        formattedFleetDetails = formattedFleetDetails.concat(this.mappingFleetAlerts(fleetMenu, fleetdetails));

                    })
                    let alertstowardsreg_no = {};
                    let remove_duplicates = formattedFleetDetails.filter((value, index, self) =>
                        index === self.findIndex((t) => (
                            t.regn_number === value.regn_number && t.start_timestamp_utc === value.start_timestamp_utc &&
                            t.alert_type_name === value.alert_type_name
                        ))
                    )
                    remove_duplicates.forEach((item) => {
                        let regn_number = item['regn_number'];
                        if (alertstowardsreg_no[regn_number]) {
                            alertstowardsreg_no[regn_number] = alertstowardsreg_no[regn_number].concat(item);
                        } else {
                            let array = [];
                            array.push(item)
                            alertstowardsreg_no[regn_number] = array;
                        }
                    })
                    var regEntries = Object.entries(alertstowardsreg_no);
                    var regSorted = regEntries.sort((a, b) => b[1].length - a[1].length);
                    let sidebarRegnoList = Object.keys(Object.fromEntries(regSorted));
                    this.setState({
                        fleetVechicleBasedList: sidebarRegnoList,
                        fleetdetails: alertstowardsreg_no[sidebarRegnoList[0]] !== undefined ? alertstowardsreg_no[sidebarRegnoList[0]] : [],
                        fleetdetailssearch: alertstowardsreg_no[sidebarRegnoList[0]] !== undefined ? alertstowardsreg_no[sidebarRegnoList[0]] : [],
                        TelltaleCurrentPage: 1,
                        sortedFleetAlerts: alertstowardsreg_no,
                        vehicleRegBasedView: true,
                        fleetActiveRegno: sidebarRegnoList.length > 0 ? sidebarRegnoList[0] : "",
                        currentPage: 1,
                    });
                } else {
                    let fleetMenu = this.state.geofencealerts === true ? this.state.geofenceAlertsdata[0] : this.state.filterFleetactiveAlerts[0];
                    this.setState({
                        vehicleRegBasedView: false
                    }, () => {
                        if (fleetMenu) {
                            this.getFleetAlertDetails(fleetMenu)
                        }
                    })
                }
            }
            else if (this.state.fleetEvents == true) {
                if (this.state.vehicleRegBasedView == false) {

                    let sidebarEventList = this.state.siderbarEventsList;
                    let allAlerts = [];
                    for (let i = 0; i < sidebarEventList.length; i++) {
                        allAlerts = allAlerts.concat(sidebarEventList[i].alertinfo);
                    }
                    let alertstowardsreg_no = {};
                    allAlerts.forEach((item) => {
                        let regn_number = item['regn_number'];
                        if (alertstowardsreg_no[regn_number]) {
                            alertstowardsreg_no[regn_number] = alertstowardsreg_no[regn_number].concat(item);
                        } else {
                            let array = [];
                            array.push(item)
                            alertstowardsreg_no[regn_number] = array;
                        }
                    })
                    var alertsRegEntries = Object.entries(alertstowardsreg_no);
                    var regnoListSorted = alertsRegEntries.sort((a, b) => b[1].length - a[1].length);
                    let sidebarRegnoList = Object.keys(Object.fromEntries(regnoListSorted));
                    this.setState({
                        fleetEventSidebarList: sidebarRegnoList,
                        eventAlertInfo: alertstowardsreg_no[sidebarRegnoList[0]] !== undefined ? alertstowardsreg_no[sidebarRegnoList[0]] : [],
                        vehicleRegBasedView: true,
                        sortedFleetEvents: alertstowardsreg_no,
                        TelltaleCurrentPage: 1,
                        eventReminderHeader: "Alert Name",
                        fleetActiveRegno: sidebarRegnoList.length > 0 ? sidebarRegnoList[0] : "",
                        remainderCurrentPage: 1,
                        eventSidebarActive: ""
                    })
                } else {
                    let allData = this.state.siderbarEventsList;
                    let data = {};
                    data['occassionCount'] = this.state.OccassionsCount;
                    data['reminderCount'] = this.state.remainderCount;
                    if (this.state.severityVal == "Remainder") {
                        data['reminderData'] = allData;
                    }
                    else {
                        data['occassionData'] = allData;
                    }
                    this.setState({
                        vehicleRegBasedView: false
                    }, () => {
                        this.formatFleetEventsresponse(data, this.state.severityVal);
                    });
                }
            }
        }
        catch (e) {
            console.error("failed with error");
        }
    }


    filterTelltaleAlert = (alertstowardsreg_no, reg_no, alldata) => {
        let sidebaeActiveData = alertstowardsreg_no[reg_no];
        let activealertdata = [];
        if (sidebaeActiveData && sidebaeActiveData.length > 0) {
            sidebaeActiveData.forEach(element => {
                let result = alldata.filter((item => {
                    if (item['reg_no'] === reg_no && item['alert_type_id'] === element) {
                        return item;
                    }
                }))
                activealertdata = activealertdata.concat(result);

            });
        }
        return activealertdata;
    }

    showActiveTelltaleAlerts = (alertname) => {
        let activealertdata = this.filterTelltaleAlert(this.state.sortedTelltale, alertname, this.state.telltaleexceldata);
        this.setState({
            telltaleactive: alertname,
            telltaleactivedata: activealertdata,
            // TelltaleCurrentPage: 1
        })
    }

    showActiveFleetAlerts = (alertRegNo) => {
        let sidebarRegnoList = this.state.fleetVechicleBasedList;
        let alertstowardsreg_no = this.state.sortedFleetAlerts;
        let index = sidebarRegnoList.findIndex((item) => item == alertRegNo);
        this.setState({
            fleetdetails: alertstowardsreg_no[sidebarRegnoList[index]],
            fleetActiveRegno: sidebarRegnoList[index]
        });

    }

    showActiveFleetEvents = (alertRegNo) => {
        let sidebarRegnoList = this.state.fleetEventSidebarList;
        let alertstowardsreg_no = this.state.sortedFleetEvents;
        let index = sidebarRegnoList.findIndex((item) => item == alertRegNo);
        this.setState({
            eventAlertInfo: alertstowardsreg_no[sidebarRegnoList[index]],
            fleetActiveRegno: sidebarRegnoList[index]
        });
    }

    getmenuClickValue = (e) => {
        clearInterval(this.hoverInterval);
        clearInterval(this.settimerscnt);
        var ids = e.target.getAttribute("menuclickid").split(",");
        var menuClickids = [];
        menuClickids.push({ mm: ids[0], sm: ids[1], tm: ids[2] });
        this.props.quicklinks(menuClickids);
    };

    handleDialog = () => {
        this.setState(prevState => ({
            dialog: !prevState.dialog
        }));
    }

    render() {
        const { fleetdetails, currentPage, FleetsPerPage,
            detailFleetDetail, selectedFleetDetail, viewlocation, severityCount,
            sidebarActive, filterFleetactiveAlerts, fleetVechicleBasedList, active, activeFilterdata, sidebargeofenceActive, activegeoFilterdata,
            isShowFilter, initialDtls, excelData, fleetEvents, sidebarEventsInd, geofencealerts,
            siderbarEventsList, eventAlertInfo, eventSidebarActive,
            remainderCurrentPage, remainderPerPage, registrationCurrentPage,
            registrationPerPage, eventReminderHeader, OccassionsCount, remainderCount, telltale,
            telltalesidebarlist, telltaleactive, sortedFleetEvents,
            telltaleactivedata, viewdetails, showdetail, TelltaleCurrentPage, fleetEventSidebarList, vehicleviewPerPage,
            TelltalePerPage, telltaleexceldata, telltaleseveritycnt, vehicleRegBasedView, sortedTelltale, sortedFleetAlerts, fleetActiveRegno, alerts_sort } = this.state;
        let fleetsmenu = [];
        let currentFleets = [];
        if (vehicleRegBasedView === false) {
            //for geofence lalerts and fleetalerts
            const indexOfLastFleet = currentPage * FleetsPerPage;
            const indexOfFirstFleet = indexOfLastFleet - FleetsPerPage;

            if (['regn_number', 'obu_id', 'Occurance', 'alert_info_1', 'alert_info_2', 'start_timestamp_utc', 'ignition_status', 'ODO Reading', 'Engine Hrs', 'due_km', 'Due km', 'due_date'].includes(alerts_sort.column_name)) {
                currentFleets = fleetdetails.slice(indexOfFirstFleet, indexOfLastFleet);
            } else {
                const shortedData = [...fleetdetails].sort((a, b) => new Date(b.start_timestamp_utc) - new Date(a.start_timestamp_utc));
                currentFleets = shortedData.slice(indexOfFirstFleet, indexOfLastFleet);
            }
            
            //for telltale alerts / sevice alerts / warning alerts
            fleetsmenu = filterFleetactiveAlerts.map((fleetmenu, index) => {
                return (<div key={index}
                    className={classnames('fleet-card',
                        fleetmenu.alert_type_name === sidebarActive ? 'fleet-card-active' : null)} onClick={this.getFleetAlertDetails.bind(this, fleetmenu)}>
                    <div className="alert-img-div">
                        <img src={"/images/fleet_alerts/" + fleetmenu.alert_type_name.replace('/', '_') + ".png"} alt=""
                            className="fleet-image"></img>
                    </div>
                    <div className="fleet-name-div">
                        <p className="fleet-name">{fleetmenu.alert_type_name}</p>
                    </div>
                    <div className="fleet-count-div">
                        <div className="fleet-count">
                            <p className="fleet-count-box">{fleetmenu.count}</p>
                        </div>
                    </div>
                </div>)
            });
        } else {
            //for geofence lalerts and fleetalerts
            const indexOfLastFleet = currentPage * vehicleviewPerPage;
            const indexOfFirstFleet = indexOfLastFleet - vehicleviewPerPage;
            currentFleets = fleetdetails.slice(indexOfFirstFleet, indexOfLastFleet);

            //for telltale alerts
            const indexOfLastRemainder = TelltaleCurrentPage * TelltalePerPage;
            const indexOfFirstRemainder = indexOfLastRemainder - TelltalePerPage;
            const Fleetacttivesidebarlist = fleetVechicleBasedList.slice(indexOfFirstRemainder, indexOfLastRemainder);

            fleetsmenu = Fleetacttivesidebarlist.map((value, index) => {

                if (value != undefined) {
                    let count = sortedFleetAlerts[value] ? sortedFleetAlerts[value].length : 0;
                    return (
                        <div key={index}
                            className={classnames('custom-rg-card',
                                value === fleetActiveRegno ? 'fleet-card-active custom-rg-padd-one' : null)}
                            onClick={this.showActiveFleetAlerts.bind(this, value)}>
                            <div className='custom-rg-div'>{value}</div>
                            <div className="fleet-count-div custom-mg">
                                <div className="fleet-count">
                                    <p className="fleet-count-box">{count}</p>
                                </div>
                            </div>
                        </div>
                    )
                }
            })

        }
        
        const fleetdtls = currentFleets.map((fleetDetail, index) => {
            let alldata =
                <tr key={index}>
                    {vehicleRegBasedView === false ? <td>
                        <a href="#!" onClick={this.changeview.bind(this, fleetDetail)}
                            className="fleet-edit-regn">
                            {fleetDetail.regn_number}
                        </a>
                    </td> :
                        <td>
                            <div style={{ display: 'flex' }}>
                                <div className='custom-alert-img'>
                                    <img src={"/images/fleet_alerts/" + fleetDetail.alert_type_name.replace('/', '_') + ".png"} alt=""
                                        className="fleet-image"></img>
                                </div>
                                <a className="fleet-name-div custom-fleet-name fleet-a-tg" onClick={this.changeview.bind(this, fleetDetail)}>
                                    <p className="fleet-name">{fleetDetail.alert_type_name}</p>
                                </a>
                            </div>
                        </td>
                    }
                    <td>{fleetDetail.obu_id}</td>
                    <td>{changeDateTimeFormateAMPM(fleetDetail.start_timestamp_utc)}</td>
                    {this.state.alertActive === 'Fuel' ? <td>{fleetDetail.ignition_status}</td> : ''}
                    <td>{fleetDetail.Occurance}</td>
                </tr>

            let serviceAlertsdata = 
                <tr key={index}>
                {vehicleRegBasedView === false ? <td>{fleetDetail.regn_number}</td> : <td>{fleetDetail.alert_type_name}</td>}
                {/* <td>{ exceldatetimestampformatter(fleetDetail.start_timestamp_utc) }</td> */}
                <td>{this.infocheck(fleetDetail.alert_info_1)}</td>
                <td>{this.infocheck(fleetDetail.alert_info_2)}</td>
                <td>
                    {(this.state.vinList.includes(fleetDetail.vin) && this.props.ctr.userroleid != 5) &&
                        <Link to={{
                            pathname: "/vehicleInfo/findDealer",
                            state: { vin_no: fleetDetail.vin },
                        }}
                        >
                            <u
                                menuclickid="2,27,2"
                                onClick={this.getmenuClickValue}>Near By Places
                            </u>
                            &nbsp; <i class="fa fa-map-marker" aria-hidden="true"></i>
                        </Link>
                    }

                </td>
            </tr>

            let geofencedata = 
                <tr key={index}>
                <td>{fleetDetail.geofence_name}</td>
                {vehicleRegBasedView === true &&
                    <td>
                        <div style={{ display: 'flex' }}>
                            <div className='custom-alert-img'>
                                <img src={"/images/fleet_alerts/" + fleetDetail.alert_type_name.replace('/', '_') + ".png"} alt=""
                                    className="fleet-image"></img>
                            </div>
                            <a className="fleet-name-div custom-fleet-name fleet-a-tg" onClick={this.changeview.bind(this, fleetDetail)}>
                                <p className="fleet-name">{fleetDetail.alert_type_name}</p>
                            </a>
                        </div>
                    </td>
                }

                {vehicleRegBasedView == false && <td>
                    <a href="#!" onClick={this.changeview.bind(this, fleetDetail)}
                        className="fleet-edit-regn">
                        {fleetDetail.regn_number}
                    </a>
                </td>}
                <td>{fleetDetail.obu_id}</td>
                <td>{fleetDetail.Occurance}</td>
            </tr>

            return this.state.alertActive === 'Geofence' ? geofencedata : this.state.alertActive === 'Service' ? serviceAlertsdata : alldata;
        });

        let length = fleetdetails.length;
        let pageNumbers = [];
        if (vehicleRegBasedView == false) {
            if (Math.ceil(length / FleetsPerPage) > 1) {
                for (let i = 1; i <= Math.ceil(length / FleetsPerPage); i++) {
                    pageNumbers.push(i);
                }
            }
        } else {
            if (Math.ceil(length / vehicleviewPerPage) > 1) {
                for (let i = 1; i <= Math.ceil(length / vehicleviewPerPage); i++) {
                    pageNumbers.push(i);
                }
            }
        }

        const renderPageNumbers = pageNumbers.map(number => {
            if (pageNumbers.length <= 6) {
                return (
                    <td
                        key={number}
                        id={number}
                        onClick={this.changePage.bind(this, number)}
                        className={
                            this.state.currentPage === number
                                ? "slectedbluepg pagesize"
                                : "notselected pagesize"
                        }
                    >
                        {number}
                    </td>
                );
            } else {
                if (number === 1) {
                    if (currentPage <= 5) {
                        return (
                            <td
                                key={number}
                                id={number}
                                onClick={this.changePage.bind(this, number)}
                                className={
                                    currentPage === number
                                        ? "slectedbluepg pagesize"
                                        : "notselected pagesize"
                                }
                            >
                                {number}
                            </td>
                        );
                    } else {
                        return (
                            <td
                                key={number}
                                id={number}
                                onClick={this.changePage.bind(this, number)}
                                className={
                                    currentPage === number
                                        ? "slectedbluepg pagesize"
                                        : "notselected pagesize"
                                }
                            >
                                {number}...
                            </td>
                        );
                    }
                } else if (number > 1 && number <= 6 && currentPage <= 6) {
                    return (
                        <td
                            key={number}
                            id={number}
                            onClick={this.changePage.bind(this, number)}
                            className={
                                currentPage === number
                                    ? "slectedbluepg pagesize"
                                    : "notselected pagesize"
                            }
                        >
                            {number}
                        </td>
                    );
                } else if ((number >= (currentPage - 2) && number <= (currentPage + 2)) && currentPage > 6) {
                    return (
                        <td
                            key={number}
                            id={number}
                            onClick={this.changePage.bind(this, number)}
                            className={
                                currentPage === number
                                    ? "slectedbluepg pagesize"
                                    : "notselected pagesize"
                            }
                        >
                            {number}
                        </td>
                    )
                } else if (number === pageNumbers.length) {
                    return (
                        <td
                            key={number}
                            id={number}
                            onClick={this.changePage.bind(this, number)}
                            className={
                                currentPage === number
                                    ? "slectedbluepg pagesize"
                                    : "notselected pagesize"
                            }
                        >
                            <span>...</span>{number}
                        </td>
                    )
                } else {
                    return "";
                }
            }
        });

        const indexOfLastRegistration = registrationCurrentPage * registrationPerPage;
        const indexOfFirstRegistration = indexOfLastRegistration - registrationPerPage;
        const currentRegistration = selectedFleetDetail.slice(indexOfFirstRegistration, indexOfLastRegistration);
        const detailFleetactiveTable = currentRegistration.map((fleet, index) => {
            return (
                <tr key={index}>
                    <td>{moment(fleet.fleetTime).format("DD-MM-YYYY h:mm:ss A")}</td>
                    <td><a href="#!" className="hyperLink" onClick={() => this.callMap(fleet)}><i class="fa fa-map-marker" aria-hidden="true"></i>&nbsp;<u>{fleet.fleetLocation !== null ? fleet.fleetLocation : '-'}</u></a></td>
                    <td>{this.infocheck(fleet.fleetInfo1)}</td>
                    <td>{this.infocheck(fleet.fleetInfo2)}</td>
                    {this.state.alertActive === 'Fuel' && (fleet.alert_type_id === 11 || fleet.alert_type_id === 12 || fleet.alert_type_id === 13) ? <td>{fleet.ignition_status}</td> : ''}

                </tr>
            )
        });

        const detailFleetDetailTable = currentRegistration.map((fleet, index) => {
            return (
                <tr key={index}>
                    {detailFleetDetail.alert_type_id !== 108 ? <td className="tdAlign"><p className="starticon" title="Start">S</p>{fleet.fleetEndTime !== null ? <p className="endicon" title="End">E</p> : ''}</td> :
                        <td className="tdAlign"><p className="starticon" title="Entry">En</p>
                            {detailFleetDetail.alert_type_name !== "Vehicle Entry Into Geofence" ? <p className="endicon" title="Exit">Ex</p> : ''}</td>}
                    <td><p>{moment(fleet.fleetTime).format("DD-MM-YYYY h:mm:ss A")}</p>
                        {detailFleetDetail.alert_type_name !== "Vehicle Entry Into Geofence" && fleet.fleetEndTime !== null ? <p>{moment(fleet.fleetEndTime).format("DD-MM-YYYY h:mm:ss A")}</p> : ''}</td>
                    <td><p><a href="#!" className="hyperLink" onClick={() => this.callMap(fleet)}><i class="fa fa-map-marker" aria-hidden="true"></i>&nbsp;<u>{fleet.fleetLocation !== null ? fleet.fleetLocation : '-'}</u></a></p><div className={detailFleetDetail.alert_type_name !== "Vehicle Entry Into Geofence" && fleet.fleetEndTime !== null ? "locationAlign" : ''}></div>{detailFleetDetail.alert_type_name !== "Vehicle Entry Into Geofence" ? <p><a href="#!" className="hyperLink" onClick={() => this.callInactive(fleet)}><i class="fa fa-map-marker" aria-hidden="true"></i>&nbsp;<u>{fleet.fleetEndLocation !== null ? fleet.fleetEndLocation : '-'}</u></a></p> : ''}</td>
                    {detailFleetDetail.alert_type_id === 105 ? <td>{this.converthhmmss(fleet.fleetInfo1)}</td> : detailFleetDetail.alert_type_name === "Vehicle Exit from Geofence" ? <td>{this.calcDate(fleet.fleetTime, fleet.fleetEndTime)}</td> : <td>{this.infocheck(fleet.fleetInfo1)}</td>}
                    {detailFleetDetail.alert_type_id === 105 ? <td>{this.converthhmmss(fleet.fleetInfo2)}</td> : <td>{this.infocheck(fleet.fleetInfo2)}</td>}
                    {this.state.alertActive === 'Fuel' && (detailFleetDetail.alert_type_id === 11 || detailFleetDetail.alert_type_id === 12 || detailFleetDetail.alert_type_id === 13) ? <td>{detailFleetDetail.ignition_status}</td> : ''}

                </tr>
            )
        });

        let registrationLength = selectedFleetDetail.length;
        let registrationPageNumbers = [];
        if (Math.ceil(registrationLength / registrationPerPage) > 1) {
            for (let i = 1; i <= Math.ceil(registrationLength / registrationPerPage); i++) {
                registrationPageNumbers.push(i);
            }
        }

        const renderRegistrationPageNumbers = registrationPageNumbers.map(number => {
            if (registrationPageNumbers.length <= 6) {
                return (
                    <td
                        key={number}
                        id={number}
                        onClick={this.changeRegistrationPage.bind(this, number)}
                        className={
                            registrationCurrentPage === number
                                ? "slectedbluepg pagesize"
                                : "notselected pagesize"
                        }
                    >
                        {number}
                    </td>
                );
            } else {
                if (number === 1) {
                    if (registrationCurrentPage <= 5) {
                        return (
                            <td
                                key={number}
                                id={number}
                                onClick={this.changeRegistrationPage.bind(this, number)}
                                className={
                                    registrationCurrentPage === number
                                        ? "slectedbluepg pagesize"
                                        : "notselected pagesize"
                                }
                            >
                                {number}
                            </td>
                        );
                    } else {
                        return (
                            <td
                                key={number}
                                id={number}
                                onClick={this.changeRegistrationPage.bind(this, number)}
                                className={
                                    registrationCurrentPage === number
                                        ? "slectedbluepg pagesize"
                                        : "notselected pagesize"
                                }
                            >
                                {number}...
                            </td>
                        );
                    }
                } else if (number > 1 && number <= 6 && registrationCurrentPage <= 6) {
                    return (
                        <td
                            key={number}
                            id={number}
                            onClick={this.changeRegistrationPage.bind(this, number)}
                            className={
                                registrationCurrentPage === number
                                    ? "slectedbluepg pagesize"
                                    : "notselected pagesize"
                            }
                        >
                            {number}
                        </td>
                    );
                } else if ((number >= (registrationCurrentPage - 2) && number <= (registrationCurrentPage + 2)) && registrationCurrentPage > 6) {
                    return (
                        <td
                            key={number}
                            id={number}
                            onClick={this.changeRegistrationPage.bind(this, number)}
                            className={
                                registrationCurrentPage === number
                                    ? "slectedbluepg pagesize"
                                    : "notselected pagesize"
                            }
                        >
                            {number}
                        </td>
                    )
                } else if (number === registrationPageNumbers.length) {
                    return (
                        <td
                            key={number}
                            id={number}
                            onClick={this.changeRegistrationPage.bind(this, number)}
                            className={
                                registrationCurrentPage === number
                                    ? "slectedbluepg pagesize"
                                    : "notselected pagesize"
                            }
                        >
                            <span>...</span>{number}
                        </td>
                    )
                } else {
                    return "";
                }
            }
        });
        var sideBarListEvents = null;
        if (vehicleRegBasedView == false) {
            sideBarListEvents = siderbarEventsList.map((value, index) => {
                const { userrole } = this.props.ctr;
                const isRenewNowVisible =
                    userrole !== "AL EMPLOYEE" &&
                    userrole !== 'DEALER OUTLET ADMIN' &&
                    userrole !== 'AL SME' &&
                    userrole !== 'FLEET MANAGER';
                const isSubscriptionExpiryAlert = value.alert_type_name === "iALERT Subscription Expiry"
                return (
                    <div key={index}
                        className={classnames('fleet-card',
                            value.alert_type_name === eventSidebarActive ? 'fleet-card-active' : null)}
                        onClick={this.showEvents.bind(this, value.alertinfo, value.alert_type_name)}>
                        {
                            value.alert_type_name === 'Birthday' ?
                                <div className="alert-img-div">
                                    <img src={"/images/fleet_alerts/birthday.jpeg"} alt=""
                                        className="fleet-image"></img>
                                </div> :
                                value.alert_type_name === 'Wedding Annivarsary' ?
                                    <div className="alert-img-div">
                                        <img src={"/images/fleet_alerts/wedding.jpeg"} alt=""
                                            className="fleet-image"></img>
                                    </div> :
                                    <div className="alert-img-div">
                                        <img src={"/images/fleet_alerts/fleet_frame.jpeg"} alt=""
                                            className="fleet-image"></img>
                                        <p className="alert-image-p">{this.alertImageName(value.alert_type_name)}</p>
                                    </div>
                        }
                        <div className="fleet-name-div">
                            <p className="fleet-name">{value.alert_type_name}</p>
                        </div>
                        <div className="fleet-count-div">
                            <div className="fleet-count">
                                <p className="fleet-count-box">{value.count}</p>
                                {isRenewNowVisible && isSubscriptionExpiryAlert && (
                                    <NavLink link="/billingmodule/packages">
                                        <p className="renewnow_text">Renew Now</p>
                                    </NavLink>
                                )}
                            </div>
                        </div>
                    </div>
                )
            })
        } else {
            const indexOfLastRemainder = TelltaleCurrentPage * TelltalePerPage;
            const indexOfFirstRemainder = indexOfLastRemainder - TelltalePerPage;
            const slicedFleetEvents = fleetEventSidebarList.slice(indexOfFirstRemainder, indexOfLastRemainder);
            sideBarListEvents = slicedFleetEvents.map((value, index) => {

                if (value != undefined) {
                    let count = sortedFleetEvents[value] ? sortedFleetEvents[value].length : 0;
                    return (
                        <div key={index}
                            className={classnames('custom-rg-card',
                                value === fleetActiveRegno ? 'fleet-card-active custom-rg-padd-one' : null)}
                            onClick={this.showActiveFleetEvents.bind(this, value)}>
                            <div className='custom-rg-div'>{value}</div>
                            <div className="fleet-count-div custom-mg">
                                <div className="fleet-count">
                                    <p className="fleet-count-box">{count}</p>
                                </div>
                            </div>
                        </div>
                    )
                }
            })

        }

        var eventAlertBody = null;
        const indexOfLastRemainder = remainderCurrentPage * remainderPerPage;
        const indexOfFirstRemainder = indexOfLastRemainder - remainderPerPage;
        const currentRemainder = eventAlertInfo.slice(indexOfFirstRemainder, indexOfLastRemainder);

        eventAlertBody = currentRemainder.map((value, index) => {
            return (
                <tr key={index}>
                    {vehicleRegBasedView == true &&
                        <td>
                            <div style={{ display: 'flex' }}>
                                <div className='custom-alert-img-1'>
                                    {
                                        value.alert_type_name === 'Birthday' ?
                                            <>
                                                <img src={"/images/fleet_alerts/birthday.jpeg"} alt=""
                                                    className="fleet-image"></img>
                                            </> :
                                            value.alert_type_name === 'Wedding Annivarsary' ?
                                                <>
                                                    <img src={"/images/fleet_alerts/wedding.jpeg"} alt=""
                                                        className="fleet-image"></img>
                                                </> :
                                                <>
                                                    <img src={"/images/fleet_alerts/fleet_frame.jpeg"} alt=""
                                                        className="fleet-image"></img>
                                                    <p className="alert-image-p-tg">{this.alertImageName(value.alert_type_name)}</p>
                                                </>
                                    }
                                </div>
                                <div className="fleet-name-div custom-fleet-name fleet-dsp">
                                    <p className="fleet-name">{value.alert_type_name}</p>
                                    {this.props.ctr.userrole === "OWNER ADMIN" ? value.alert_type_name === "iALERT Subscription Expiry" ? <NavLink link="/billingmodule/packages"><p className='renew-txt'>(Renew Now)</p></NavLink> : '' : ''}
                                </div>
                            </div>
                        </td>}
                    {value.vin_number === undefined ? null : <td>{value.vin_number}</td>}
                    {value.regn_number === undefined || vehicleRegBasedView == true ? null : eventSidebarActive === 'Driving Licence Expiry' ? "" : <td>{value.regn_number}</td>}
                    {value.emp_name === undefined ? null : <td>{value.emp_name}</td>}
                    <td>{eventSidebarActive === "iALERT Subscription Expiry" ? value.obu_id : value.document_number}</td>
                    <td>{moment(value.insurance_expiry_date).format("DD-MM-YYYY")}</td>
                </tr>
            );
        })


        let remainderLength = eventAlertInfo.length;
        let remainderPageNumbers = [];
        if (Math.ceil(remainderLength / remainderPerPage) > 1) {
            for (let i = 1; i <= Math.ceil(remainderLength / remainderPerPage); i++) {
                remainderPageNumbers.push(i);
            }
        }

        const renderRemainderPageNumbers = remainderPageNumbers.map(number => {
            if (remainderPageNumbers.length <= 6) {
                return (
                    <td
                        key={number}
                        id={number}
                        onClick={this.changeRemainderPage.bind(this, number)}
                        className={
                            remainderCurrentPage === number
                                ? "slectedbluepg pagesize"
                                : "notselected pagesize"
                        }
                    >
                        {number}
                    </td>
                );
            } else {
                if (number === 1) {
                    if (remainderCurrentPage <= 5) {
                        return (
                            <td
                                key={number}
                                id={number}
                                onClick={this.changeRemainderPage.bind(this, number)}
                                className={
                                    remainderCurrentPage === number
                                        ? "slectedbluepg pagesize"
                                        : "notselected pagesize"
                                }
                            >
                                {number}
                            </td>
                        );
                    } else {
                        return (
                            <td
                                key={number}
                                id={number}
                                onClick={this.changeRemainderPage.bind(this, number)}
                                className={
                                    remainderCurrentPage === number
                                        ? "slectedbluepg pagesize"
                                        : "notselected pagesize"
                                }
                            >
                                {number}...
                            </td>
                        );
                    }
                } else if (number > 1 && number <= 6 && remainderCurrentPage <= 6) {
                    return (
                        <td
                            key={number}
                            id={number}
                            onClick={this.changeRemainderPage.bind(this, number)}
                            className={
                                remainderCurrentPage === number
                                    ? "slectedbluepg pagesize"
                                    : "notselected pagesize"
                            }
                        >
                            {number}
                        </td>
                    );
                } else if ((number >= (remainderCurrentPage - 2) && number <= (remainderCurrentPage + 2)) && remainderCurrentPage > 6) {
                    return (
                        <td
                            key={number}
                            id={number}
                            onClick={this.changeRemainderPage.bind(this, number)}
                            className={
                                remainderCurrentPage === number
                                    ? "slectedbluepg pagesize"
                                    : "notselected pagesize"
                            }
                        >
                            {number}
                        </td>
                    )
                } else if (number === remainderPageNumbers.length) {
                    return (
                        <td
                            key={number}
                            id={number}
                            onClick={this.changeRemainderPage.bind(this, number)}
                            className={
                                remainderCurrentPage === number
                                    ? "slectedbluepg pagesize"
                                    : "notselected pagesize"
                            }
                        >
                            <span>...</span>{number}
                        </td>
                    )
                } else {
                    return "";
                }
            }
        });

        var sideBarListTelltale = [];
        if (vehicleRegBasedView == false) {
            sideBarListTelltale = telltalesidebarlist.map((value, index) => {
                if (value.count > 0) {
                    return (
                        <div key={index}
                            className={classnames('fleet-card',
                                value.alert_type_name === telltaleactive ? 'fleet-card-active' : null)}
                            onClick={this.showActiveTelltale.bind(this, value)}>
                            <div className="alert-img-div">
                                <img src={"/images/fleet_alerts/telltale/" + value.alert_type_name + ".png"} alt=""
                                    className="fleet-image"></img>
                            </div>
                            <div className="fleet-name-div">
                                <p className="fleet-name">{value.alert_type_name}</p>
                            </div>
                            <div className="fleet-count-div">
                                <div className="fleet-count">
                                    <p className="fleet-count-box">{value.count}</p>
                                </div>
                            </div>
                        </div>
                    )
                }
            })
        } else {
            const indexOfLastTelltale = TelltaleCurrentPage * TelltalePerPage;
            const indexOfFirstTelltale = indexOfLastTelltale - TelltalePerPage;
            const telltaleacttivesidebarlist = telltalesidebarlist.slice(indexOfFirstTelltale, indexOfLastTelltale);

            sideBarListTelltale = telltaleacttivesidebarlist.map((value, index) => {

                if (value != undefined) {
                    let count = sortedTelltale[value] ? sortedTelltale[value].length : 0;
                    return (
                        <div key={index}
                            className={classnames('custom-rg-card',
                                value === telltaleactive ? 'fleet-card-active custom-rg-padd-one' : null)}
                            onClick={this.showActiveTelltaleAlerts.bind(this, value)}>
                            <div className='custom-rg-div'>{value}</div>
                            <div className="fleet-count-div custom-mg">
                                <div className="fleet-count">
                                    <p className="fleet-count-box">{count}</p>
                                </div>
                            </div>
                        </div>
                    )
                }
            })
        }
        var sideBarGeofence = null;
        if (vehicleRegBasedView == false) {
            sideBarGeofence = this.state.geofenceAlertsdata.map((fleetmenu, index) => {
                return (<div key={index}
                    className={classnames('fleet-card',
                        fleetmenu.alert_type_name === sidebargeofenceActive ? 'fleet-card-active' : null)}
                    onClick={this.getFleetAlertDetails.bind(this, fleetmenu)}>
                    <div className="alert-img-div">
                        <img src={"/images/fleet_alerts/" + fleetmenu.alert_type_name.replace('/', '_') + ".png"} alt=""
                            className="fleet-image"></img>
                    </div>
                    <div className="fleet-name-div">
                        <p className="fleet-name">{fleetmenu.alert_type_name}</p>
                    </div>
                    <div className="fleet-count-div">
                        <div className="fleet-count">
                            <p className="fleet-count-box">{fleetmenu.count}</p>
                        </div>
                    </div>
                </div>)
            })
        } else {
            const indexOfLastRemainder = TelltaleCurrentPage * TelltalePerPage;
            const indexOfFirstRemainder = indexOfLastRemainder - TelltalePerPage;
            const slicedFleetEvents = fleetVechicleBasedList.slice(indexOfFirstRemainder, indexOfLastRemainder);
            sideBarGeofence = slicedFleetEvents.map((value, index) => {

                if (value != undefined) {
                    let count = sortedFleetAlerts[value] ? sortedFleetAlerts[value].length : 0;
                    return (
                        <div key={index}
                            className={classnames('custom-rg-card',
                                value === fleetActiveRegno ? 'fleet-card-active custom-rg-padd-one' : null)}
                            onClick={this.showActiveFleetAlerts.bind(this, value)}>
                            <div className='custom-rg-div'>{value}</div>
                            <div className="fleet-count-div custom-mg">
                                <div className="fleet-count">
                                    <p className="fleet-count-box">{count}</p>
                                </div>
                            </div>
                        </div>
                    )
                }
            })

        }


        const indexOfLastTelltale = TelltaleCurrentPage * TelltalePerPage;
        const indexOfFirstTelltale = indexOfLastTelltale - TelltalePerPage;
        const telltaleactivedataregn = vehicleRegBasedView ? telltaleactivedata : telltaleactivedata.slice(indexOfFirstTelltale, indexOfLastTelltale);

        const telltaledatarender = telltaleactivedataregn.map((detail, index) => {
            return (
                <tr key={index}>
                    {vehicleRegBasedView == false ? <td style={{ width: '150px' }}>
                        {detail.reg_no}
                    </td> :

                        <td style={{ width: '200px' }}>
                            <div style={{ width: '200px', display: 'flex' }}>
                                <div className='custom-alert-img'>
                                    <img src={"/images/fleet_alerts/telltale/" + detail.alert_name + ".png"} alt=""
                                        className="fleet-image"></img>
                                </div>
                                <div className="fleet-name-div custom-fleet-name">
                                    <p className="fleet-name">{detail.alert_name}</p>
                                </div>
                            </div>
                        </td>}
                    <td style={{ width: '150px' }}>{moment(detail.time_stamp).format('DD-MM-YYYY HH:mm:ss')}</td>
                    <td><a href="#!" className="hyperLink" onClick={() => this.callTelltaleMap(detail)}><i class="fa fa-map-marker" aria-hidden="true"></i>&nbsp;<u>{detail.location !== null ? detail.location : '-'}</u></a></td>
                    {/* <td style={{ lineHeight: '2em' }}>{detail.location !== null ? detail.location : '-'}</td> */}
                    <td style={{ width: '100px' }}>{detail.value !== null && detail.value !== 0 && detail.alert_name !== "Particulate Trap Regeneration Inhibit Switch" ? detail.value.toFixed(2) : detail.value}</td>
                </tr>
            )
        });

        const showtelltaledetails = viewdetails ? <tr>
            <td>{moment(showdetail.time_stamp).format("DD-MM-YYYY h:mm:ss A")}</td>
            <td>{showdetail.location}</td>
            <td>{showdetail.value}</td>
        </tr> : ''

        let telltaleLength = 0;
        let telltalePageNumbers = [];
        if (telltale === true) {
            telltaleLength = vehicleRegBasedView == false ? telltaleactivedata.length : telltalesidebarlist.length;
        } else if (geofencealerts == false && fleetEvents == false || geofencealerts == true) {
            telltaleLength = vehicleRegBasedView == false ? 0 : fleetVechicleBasedList.length;
        }
        else if (fleetEvents == true) {
            telltaleLength = vehicleRegBasedView == false ? 0 : fleetEventSidebarList.length;
        }

        if (Math.ceil(telltaleLength / TelltalePerPage) > 1) {
            for (let i = 1; i <= Math.ceil(telltaleLength / TelltalePerPage); i++) {
                telltalePageNumbers.push(i);
            }
        }



        const showSwitchButton = <div className="container-col-two"><button type="button" class="switch_btn" onClick={this.vehicleviewSwitch}>Vehicle View<div className={vehicleRegBasedView ? "custom-myswitchgeo custom-activatedgeo" : "custom-myswitchgeo"}><div className="custom-info">{vehicleRegBasedView ? 'ON' : 'OFF'}</div></div></button><span className="btn-new-info-flt">New</span></div>;

        const rendertelltalePageNumbers = telltalePageNumbers.map(number => {
            if (telltalePageNumbers.length <= 6) {
                return (
                    <td
                        key={number}
                        id={number}
                        onClick={this.changetelltalePage.bind(this, number)}
                        className={
                            TelltaleCurrentPage === number
                                ? "slectedbluepg pagesize"
                                : "notselected pagesize"
                        }
                    >
                        {number}
                    </td>
                );
            } else {
                if (number === 1) {
                    if (TelltaleCurrentPage <= 5) {
                        return (
                            <td
                                key={number}
                                id={number}
                                onClick={this.changetelltalePage.bind(this, number)}
                                className={
                                    TelltaleCurrentPage === number
                                        ? "slectedbluepg pagesize"
                                        : "notselected pagesize"
                                }
                            >
                                {number}
                            </td>
                        );
                    } else {
                        return (
                            <td
                                key={number}
                                id={number}
                                onClick={this.changetelltalePage.bind(this, number)}
                                className={
                                    TelltaleCurrentPage === number
                                        ? "slectedbluepg pagesize"
                                        : "notselected pagesize"
                                }
                            >
                                {number}...
                            </td>
                        );
                    }
                } else if (number > 1 && number <= 6 && TelltaleCurrentPage <= 6) {
                    return (
                        <td
                            key={number}
                            id={number}
                            onClick={this.changetelltalePage.bind(this, number)}
                            className={
                                TelltaleCurrentPage === number
                                    ? "slectedbluepg pagesize"
                                    : "notselected pagesize"
                            }
                        >
                            {number}
                        </td>
                    );
                } else if ((number >= (TelltaleCurrentPage - 2) && number <= (TelltaleCurrentPage + 2)) && TelltaleCurrentPage > 6) {
                    return (
                        <td
                            key={number}
                            id={number}
                            onClick={this.changetelltalePage.bind(this, number)}
                            className={
                                TelltaleCurrentPage === number
                                    ? "slectedbluepg pagesize"
                                    : "notselected pagesize"
                            }
                        >
                            {number}
                        </td>
                    )
                } else if (number === telltalePageNumbers.length) {
                    return (
                        <td
                            key={number}
                            id={number}
                            onClick={this.changetelltalePage.bind(this, number)}
                            className={
                                TelltaleCurrentPage === number
                                    ? "slectedbluepg pagesize"
                                    : "notselected pagesize"
                            }
                        >
                            <span>...</span>{number}
                        </td>
                    )
                } else {
                    return "";
                }
            }
        });

        let isDealer = this.props.ctr.userrole === "DEALER OUTLET ADMIN"
        return (
            <div>
                {this.state.loader ? <div className="Pageloading"> <img className="loadimg" src="/images/loader_1.gif" alt="iAlert" /></div> : ''}
                <div className="fleerHeader">
                    <div className="container-wrapper fleetContainer">
                        <div className="row">
                            <FleetBox name={"Critical"} value={severityCount.Critical}
                                click={this.changeFleetAlerts.bind(this, "Critical")}
                                active={this.state.alertActive}
                                isDealer={isDealer}>
                            </FleetBox>
                            <FleetBox name={"Telltale"} value={telltaleseveritycnt}
                                click={this.changeFleetAlerts.bind(this, "Telltale")}
                                active={this.state.alertActive}
                                isDealer={isDealer}>
                            </FleetBox>
                            <FleetBox name={"Warning"} value={severityCount.Warning}
                                click={this.changeFleetAlerts.bind(this, "Warning")}
                                active={this.state.alertActive}
                                isDealer={isDealer}>
                            </FleetBox>
                            {this.props.ctr.userrole !== "DEALER OUTLET ADMIN" ? <FleetBox name={"Fuel"} value={severityCount.Fuel}
                                click={this.changeFleetAlerts.bind(this, "Fuel")}
                                active={this.state.alertActive}
                                isDealer={isDealer}>
                            </FleetBox> : null}
                            <FleetBox name={"Service"} value={severityCount.Service}
                                click={this.changeFleetAlerts.bind(this, "Service")}
                                active={this.state.alertActive}
                                isDealer={isDealer}>
                            </FleetBox>
                            <FleetBox name={"Geofence"} value={this.state.geocount}
                                click={this.changeFleetAlerts.bind(this, "Geofence")}
                                active={this.state.alertActive}
                                isDealer={isDealer}>
                            </FleetBox>
                            <FleetBox name={"Events"} value={severityCount.Events}
                                click={this.changeFleetAlerts.bind(this, "Events")}
                                active={this.state.alertActive}
                                isDealer={isDealer}>
                            </FleetBox>
                        </div>
                    </div>
                    <div className="container-wrapper mt2">
                        <div className="container-col-one">{sidebarActive ? sidebarActive : eventSidebarActive ? eventSidebarActive : telltaleactive}</div>
                        {showSwitchButton}
                    </div>
                </div>
                <div className="fleet-body">
                    {
                        fleetEvents ?
                            <div className="fleet-SideBar">
                                <div className="container-wrapper m6p10">
                                    {/* <input type="button" className={classnames('fleet-btn', sidebarEventsInd ? 'fleet-active' : null)}
                                value="Reminder" onClick={this.callEventsSidebar.bind(this,'Remainder')}></input>
                            <input type="button" className={classnames('fleet-btn', sidebarEventsInd ? null : 'fleet-active')}
                                value="Occasion" onClick={this.callEventsSidebar.bind(this,'Occassions')}></input> */}

                                    <div className="flex">
                                        <div className={classnames("flex custom-mr-2 fleet-event-div", sidebarEventsInd ? 'fleet-event-active-div' : null)} onClick={this.callEventsSidebar.bind(this, 'Remainder')}>
                                            <div className={classnames("mr-2 fleet-event-tab", sidebarEventsInd ? 'fleet-event-active-tab' : null)}>Reminder</div>
                                            <span className="event-border">{remainderCount}</span>
                                        </div>

                                        {
                                            this.props.ctr.userrole === "DEALER OUTLET ADMIN" ? null :
                                                <div className={classnames("flex mr-3 fleet-event-div", sidebarEventsInd ? null : 'fleet-event-active-div')} onClick={this.callEventsSidebar.bind(this, 'Occassions')}>
                                                    <div className={classnames("mr-2 fleet-event-tab", sidebarEventsInd ? null : 'fleet-event-active-tab')}>Occasion</div>
                                                    <span className="event-border">{OccassionsCount}</span>
                                                </div>
                                        }
                                        <button type="button" disabled={!eventAlertInfo.length} className="excel_download_btn" onClick={this.downloadExcelEvents}><span className="fa fa-download" style={{ cursor: "pointer" }}></span></button>

                                    </div>
                                </div>
                                {this.state.vehicleRegBasedView && Object.keys(sortedFleetEvents).length > 0 ? <div className="search-box search-cont-div">
                                    <span className='fa fa-search search-icon-sd'></span>
                                    <div className="input-group">
                                        <input type="text" id="search" className="form-control geofence_alerts_input search-input-sd" onChange={(event) => { this.searchInputFilterGeo(event, 'sideregister_number') }} placeholder="Search by Registration Number" />
                                    </div>
                                </div> : null}
                                <div className={vehicleRegBasedView == false ? "fleet-sidebar-div" : "fleet-sidebar-div custom-sidebar"}>
                                    {
                                        sideBarListEvents.length ? sideBarListEvents :
                                            <div className="errorDiv">
                                                <p className="errorMsg">
                                                    No Data Available
                                                </p>
                                            </div>
                                    }
                                </div>
                                {vehicleRegBasedView ?
                                    <div className='custom-pagination'>
                                        {
                                            telltalePageNumbers.length ?
                                                <div className="row pagination-row-flt">
                                                    <div className="pagina-length">
                                                        <table>
                                                            <tbody>
                                                                <tr id="page-numbers">
                                                                    <td className="pagesize page_symbol" onClick={this.changetelltalePage.bind(this, "<<")}>&laquo;</td>
                                                                    <td className="pagesize page_symbol" onClick={this.changetelltalePage.bind(this, "<")}>&#x2039;</td>
                                                                    {rendertelltalePageNumbers}
                                                                    <td className="pagesize page_symbol" onClick={this.changetelltalePage.bind(this, ">")}>&#8250;</td>
                                                                    <td className="pagesize page_symbol" onClick={this.changetelltalePage.bind(this, ">>")}>&raquo;</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div> : null
                                        }
                                    </div> : null
                                }
                            </div> : telltale ?
                                <div className="fleet-SideBar">
                                    <div className="container-wrapper m6p10">
                                        <div className="flex">
                                            <div className={classnames("flex custom-mr-2 fleet-event-div", telltale ? 'fleet-event-active-div' : null)}>
                                                <div className={classnames("mr-2 fleet-event-tab", telltale ? 'fleet-event-active-tab' : null)} style={{ marginLeft: '0%' }}>{vehicleRegBasedView == false ? "Telltale Alerts" : "Registration Number"}</div>
                                                {/* <span className="event-border">{telltaletotalCount}</span> */}
                                            </div>
                                            <button type="button" disabled={!telltaleexceldata.length} className="excel_download_btn" onClick={this.downloadExceltelltale}><span className="fa fa-download" style={{ cursor: "pointer" }}></span></button>
                                        </div>
                                    </div>
                                    {this.state.vehicleRegBasedView && Object.keys(sortedTelltale).length > 0 ? <div className="search-box search-cont-div">
                                        <div className="input-group">
                                            <span className='fa fa-search search-icon-sd'></span>
                                            <input type="text" id="search" className="form-control geofence_alerts_input search-input-sd" onChange={(event) => { this.searchInputFilterGeo(event, 'sideregister_number') }} placeholder="Search by Registration Number" />
                                        </div>
                                    </div> : null}
                                    <div className={vehicleRegBasedView == false ? "fleet-sidebar-div" : "fleet-sidebar-div custom-sidebar"}>
                                        {
                                            sideBarListTelltale.length ? sideBarListTelltale :
                                                <div className="errorDiv">
                                                    <p className="errorMsg">
                                                        No Data Available
                                                    </p>
                                                </div>
                                        }
                                    </div>
                                    {vehicleRegBasedView ?
                                        <div className='custom-pagination'>
                                            {
                                                telltalePageNumbers.length ?
                                                    <div className="row pagination-row">
                                                        <div className="pagina-length">
                                                            <table>
                                                                <tbody>
                                                                    <tr id="page-numbers">
                                                                        <td className="pagesize page_symbol" onClick={this.changetelltalePage.bind(this, "<<")}>&laquo;</td>
                                                                        <td className="pagesize page_symbol" onClick={this.changetelltalePage.bind(this, "<")}>&#x2039;</td>
                                                                        {rendertelltalePageNumbers}
                                                                        <td className="pagesize page_symbol" onClick={this.changetelltalePage.bind(this, ">")}>&#8250;</td>
                                                                        <td className="pagesize page_symbol" onClick={this.changetelltalePage.bind(this, ">>")}>&raquo;</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div> : null
                                            }
                                        </div> : null
                                    }
                                </div> : geofencealerts ?
                                    <div className="fleet-SideBar">
                                        <div className="container-wrapper m6p10">
                                            <p className="inline">
                                                <input type="button" className={classnames('fleet-btn', this.state.geoactive ? 'fleet-active' : null)}
                                                    value="Geo-Entry/Exit" onClick={this.activegeofenceHandler.bind(this, true, activegeoFilterdata, 'onClick')}></input>
                                                <input type="button" className={classnames('fleet-btn', this.state.geoactive ? null : 'fleet-active')}
                                                    value="Geo-Events" onClick={this.activegeofenceHandler.bind(this, false, activegeoFilterdata, 'onClick')}></input>
                                            </p>
                                            <button type="button" disabled={!excelData.length} className="excel_download_btn" onClick={this.downloadExcel}><span className="fa fa-download" style={{ cursor: "pointer" }}></span></button>

                                            <div className="dropdown">
                                                <label className="fliter-label" style={{ cursor: 'pointer' }} onClick={this.showFilterHandler}>{activegeoFilterdata === 6 ? "Last 7 days" : activegeoFilterdata === 2 ? "Last 3 days" : "Today"}
                                                    <span className="fa fa-caret-down"></span>
                                                </label>
                                                <div className={classnames("dropdown-content", isShowFilter ? "dropdown-content-block" : null)}>
                                                    <p onClick={this.activegeofenceHandler.bind(this, this.state.geoactive, 6, 'onClick')}>Last 7 days</p>
                                                    <p onClick={this.activegeofenceHandler.bind(this, this.state.geoactive, 2, 'onClick')}>Last 3 days</p>
                                                    <p onClick={this.activegeofenceHandler.bind(this, this.state.geoactive, 0, 'onClick')}>Today</p>
                                                </div>
                                            </div>
                                        </div>

                                        {this.state.vehicleRegBasedView && this.state.geofenceAlertsdata.length > 0 ? <div className="search-box search-cont-div">
                                            <span className='fa fa-search search-icon-sd'></span>
                                            <div className="input-group">
                                                <input type="text" id="search" className="form-control geofence_alerts_input search-input-sd" onChange={(event) => { this.searchInputFilterGeo(event, 'sideregister_number') }} placeholder="Search by Registration Number" />
                                            </div>
                                        </div> : null}

                                        <div className={vehicleRegBasedView == false ? "fleet-sidebar-div" : "fleet-sidebar-div custom-sidebar"}>
                                            {
                                                sideBarGeofence.length ? sideBarGeofence :
                                                    <div className="errorDiv">
                                                        <p className="errorMsg">
                                                            No Data Available
                                                        </p>
                                                    </div>
                                            }
                                        </div>
                                        {vehicleRegBasedView ?
                                            <div className='custom-pagination'>
                                                {
                                                    telltalePageNumbers.length ?
                                                        <div className="row pagination-row-flt">
                                                            <div className="pagina-length">
                                                                <table>
                                                                    <tbody>
                                                                        <tr id="page-numbers">
                                                                            <td className="pagesize page_symbol" onClick={this.changetelltalePage.bind(this, "<<")}>&laquo;</td>
                                                                            <td className="pagesize page_symbol" onClick={this.changetelltalePage.bind(this, "<")}>&#x2039;</td>
                                                                            {rendertelltalePageNumbers}
                                                                            <td className="pagesize page_symbol" onClick={this.changetelltalePage.bind(this, ">")}>&#8250;</td>
                                                                            <td className="pagesize page_symbol" onClick={this.changetelltalePage.bind(this, ">>")}>&raquo;</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div> : null
                                                }
                                            </div> : null
                                        }
                                    </div>
                                    : <div className="fleet-SideBar">
                                        <div className="container-wrapper m6p7">
                                            <input type="button" className={classnames('fleet-btn', active ? 'fleet-active' : null)}
                                                value="Active" onClick={this.activeHandler.bind(this, true, activeFilterdata)}></input>
                                            {
                                                this.state.alertActive !== "Service" && this.state.alertActive !== "Fuel" ?
                                                    <input type="button" className={classnames('fleet-btn', active ? null : 'fleet-active')}
                                                        value="Inactive" onClick={this.activeHandler.bind(this, false, activeFilterdata)}></input> : ''
                                            }
                                            {(this.state.alertActive === "Fuel" && this.props.ctr.fuel_report === true) && (
                                                <button type="button" className="excel_download_btn" onClick={() => this.selectFuelAlertsDateRange()}><span className="fa fa-calendar" style={{ cursor: "pointer" }} /></button>
                                            )}

                                            {this.state.showDatePicker &&
                                                <FuelAlertDatePicker
                                                    inputRef={this.inputRef}
                                                    minFuelDate={this.state.minFuelDateOpt}
                                                    fuelAlertStartDate={this.state.fuelAlertStartDate} fuelAlertEndDate={this.state.fuelAlertEndDate}
                                                    handleDateChangePickerDate={this.changeDatePickerDate}
                                                    closeFuelAlertsDatePicker={this.closeFuelAlertsDatePicker}
                                                    resetDateFields={this.resetDateFields}
                                                    activeHandler={this.activeHandler}
                                                />
                                            }

                                            <button type="button" disabled={!excelData.length} className="excel_download_btn" onClick={this.downloadExcel}><span className="fa fa-download" style={{ cursor: "pointer" }}></span></button>
                                            {(this.state.alertActive === "Fuel" && this.props.ctr.fuel_report === true) &&
                                                <button type="button" className="fuel_reports" style={{ cursor: "pointer" }}>
                                                    <Link to={{
                                                        pathname: "/fuelReport",
                                                        state: { vin_no: this.state.regList },
                                                    }}
                                                    >
                                                        <p className='fuel_report_text'>Fuel Report</p>
                                                    </Link>
                                                </button>
                                            }
                                            {this.state.alertActive !== "Service" && this.state.alertActive !== "Fuel" ?
                                                <div className="dropdown">
                                                    <label className="fliter-label" style={{ cursor: 'pointer' }} onClick={this.showFilterHandler}>{activeFilterdata === 6 ? "Last 7 days" : activeFilterdata === 2 ? "Last 3 days" : "Today"}
                                                        <span className="fa fa-caret-down"></span>
                                                    </label>
                                                    <div className={classnames("dropdown-content", isShowFilter ? "dropdown-content-block" : null)}>
                                                        <p onClick={this.activeHandler.bind(this, active, 6)}>Last 7 days</p>
                                                        <p onClick={this.activeHandler.bind(this, active, 2)}>Last 3 days</p>
                                                        <p onClick={this.activeHandler.bind(this, active, 0)}>Today</p>
                                                    </div>
                                                </div> : ''
                                            }
                                            {/* <span className="filter-span">{activeFilterdata ? "(Last "+activeFilterdata+" Days)" : "Today"}</span> */}
                                        </div>
                                        {this.state.vehicleRegBasedView && filterFleetactiveAlerts.length > 0 ? <div className="search-box search-cont-div">
                                            <span className='fa fa-search search-icon-sd'></span>
                                            <div className="input-group">
                                                <input type="text" id="search" className="form-control geofence_alerts_input search-input-sd" onChange={(event) => { this.searchInputFilterGeo(event, 'sideregister_number') }} placeholder="Search by Registration Number" />
                                            </div>
                                        </div> : null}
                                        <div className={vehicleRegBasedView == false ? "fleet-sidebar-div" : "fleet-sidebar-div custom-sidebar"}>

                                            {
                                                fleetsmenu.length ? fleetsmenu :
                                                    initialDtls ? <div className="errorDiv">
                                                        <p className="errorMsg">
                                                            No Data Available
                                                        </p>
                                                    </div> : null
                                            }

                                        </div>
                                        {vehicleRegBasedView ?
                                            <div className='custom-pagination'>
                                                {
                                                    telltalePageNumbers.length ?
                                                        <div className="row pagination-row-flt">
                                                            <div className="pagina-length">
                                                                <table>
                                                                    <tbody>
                                                                        <tr id="page-numbers">
                                                                            <td className="pagesize page_symbol" onClick={this.changetelltalePage.bind(this, "<<")}>&laquo;</td>
                                                                            <td className="pagesize page_symbol" onClick={this.changetelltalePage.bind(this, "<")}>&#x2039;</td>
                                                                            {rendertelltalePageNumbers}
                                                                            <td className="pagesize page_symbol" onClick={this.changetelltalePage.bind(this, ">")}>&#8250;</td>
                                                                            <td className="pagesize page_symbol" onClick={this.changetelltalePage.bind(this, ">>")}>&raquo;</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div> : null
                                                }
                                            </div> : null
                                        }
                                    </div>
                    }
                    {
                        fleetEvents ?
                            <div className="fleet-content">
                                <div className="fleet-table-view">
                                    <table className="fleet-table">
                                        <thead>
                                            {
                                                sidebarEventsInd ?
                                                    <tr>
                                                        <th>{eventReminderHeader}</th>
                                                        {eventSidebarActive === 'Driving Licence Expiry' ? "" : vehicleRegBasedView ? <th>Vin Number</th> : <th>Registration Number</th>}
                                                        <th>{eventSidebarActive === 'iALERT Subscription Expiry' ? 'Device ID' : 'Document Number'}</th>
                                                        <th>Expiry Date {eventSidebarActive === 'iALERT Subscription Expiry' ? <i className="fa fa-sort" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={this.tabsort}></i> : ''}</th>
                                                    </tr> :
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Designation</th>
                                                        <th>Date of Occasion</th>
                                                    </tr>
                                            }

                                        </thead>
                                        <tbody>
                                            {
                                                eventAlertBody.length ? eventAlertBody :
                                                    <tr>
                                                        <td colSpan="3">
                                                            <div className="errorDiv">
                                                                <p className="errorMsg">
                                                                    No Data Available
                                                                </p>
                                                            </div>
                                                        </td>
                                                    </tr>
                                            }
                                        </tbody>
                                    </table>
                                    {
                                        remainderPageNumbers.length ?
                                            <div className="row pagination-row">
                                                <div className="pagina-length">
                                                    <table>
                                                        <tbody>
                                                            <tr id="page-numbers">
                                                                <td className="pagesize page_symbol" onClick={this.changeRemainderPage.bind(this, "<<")}>&laquo;</td>
                                                                <td className="pagesize page_symbol" onClick={this.changeRemainderPage.bind(this, "<")}>&#x2039;</td>
                                                                {renderRemainderPageNumbers}
                                                                <td className="pagesize page_symbol" onClick={this.changeRemainderPage.bind(this, ">")}>&#8250;</td>
                                                                <td className="pagesize page_symbol" onClick={this.changeRemainderPage.bind(this, ">>")}>&raquo;</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div> : null
                                    }
                                </div>
                            </div> :


                            telltale ?
                                <div className="fleet-content">
                                    <div className="fleet-table-view">
                                        {
                                            viewdetails ?
                                                null :
                                                <table className="fleet-table">
                                                    <thead>
                                                        <tr>
                                                            <th>{vehicleRegBasedView == false ? "Registration Number" : "Telltale Alert"}</th>
                                                            <th>Timestamp</th>
                                                            <th>Location</th>
                                                            <th>Value</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {telltaledatarender.length ? telltaledatarender :
                                                            initialDtls ? <tr>
                                                                <td colSpan="3">
                                                                    <div className="errorDiv">
                                                                        <p className="errorMsg">
                                                                            No Data Available
                                                                        </p>
                                                                    </div>
                                                                </td>
                                                            </tr> : null
                                                        }
                                                    </tbody>
                                                </table>
                                        }
                                        {
                                            viewdetails ?
                                                null :
                                                // <Pagination pageNumbers={pageNumbers} paginate={this.changePage}
                                                //     currentPage={currentPage} />
                                                telltalePageNumbers.length && vehicleRegBasedView == false ?
                                                    <div className="row pagination-row">
                                                        <div className="pagina-length">
                                                            <table>
                                                                <tbody>
                                                                    <tr id="page-numbers">
                                                                        <td className="pagesize page_symbol" onClick={this.changetelltalePage.bind(this, "<<")}>&laquo;</td>
                                                                        <td className="pagesize page_symbol" onClick={this.changetelltalePage.bind(this, "<")}>&#x2039;</td>
                                                                        {rendertelltalePageNumbers}
                                                                        <td className="pagesize page_symbol" onClick={this.changetelltalePage.bind(this, ">")}>&#8250;</td>
                                                                        <td className="pagesize page_symbol" onClick={this.changetelltalePage.bind(this, ">>")}>&raquo;</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div> : null
                                        }
                                    </div>
                                    {viewdetails ?
                                        <div>
                                            <div className="fleet-detail">
                                                <div className="fleet-row">
                                                    <div className="fleet-column">
                                                        <img src="/images/fleet_alerts/Back.png" alt=""
                                                            onClick={this.backToTelltaleTable}></img>
                                                    </div>
                                                    <div className="fleet-column">
                                                        <p>{showdetail.reg_no}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="fleet-table-scroll">
                                                <table className="location-table">
                                                    <thead>
                                                        <tr>
                                                            <th>Timestamp</th>
                                                            <th>Location</th>
                                                            <th>Value</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {showtelltaledetails}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        : null}
                                </div>


                                : <div className="fleet-content">
                                    <div className="fleet-table-view">
                                        {
                                            viewlocation ?
                                                null :
                                                <table className="fleet-table geofence_alerts_table">
                                                    <thead>
                                                        {this.state.alertActive === 'Geofence' ?
                                                            <tr>
                                                                {this.state.alertActive === 'Geofence' ?
                                                                    <>
                                                                        <th onClick={(event) => { this.sort_alerts_by_alphabet(event, 'geofence_name') }}>Geofence Name <i className="fa fa-sort" aria-hidden="true"></i></th></>
                                                                    : ''}
                                                                {vehicleRegBasedView == true ? <th onClick={(event) => { this.sort_alerts_by_alphabet(event, 'regn_number') }}>Alert Name <i className="fa fa-sort" aria-hidden="true"></i></th> :
                                                                    <th onClick={(event) => { this.sort_alerts_by_alphabet(event, 'regn_number') }}>Registration Number <i className="fa fa-sort" aria-hidden="true"></i></th>}
                                                                <th onClick={(event) => { this.sort_alerts_by_alphabet(event, 'obu_id') }}>Device Id <i className="fa fa-sort" aria-hidden="true"></i></th>
                                                                <th onClick={(event) => { this.sort_alerts_by_numeric(event, 'Occurance') }}>Occurrence <i className="fa fa-sort" aria-hidden="true"></i></th>
                                                            </tr> :
                                                            this.state.alertActive === 'Service' ?
                                                                <tr>
                                                                    <th onClick={(event) => { this.sort_alerts_by_alphabet(event, 'regn_number') }}>{vehicleRegBasedView == false ? 'Registration Number' : "Alert Name"}&nbsp;<i className="fa fa-sort" aria-hidden="true"></i></th>
                                                                    {/* <th onClick={(event, value) => { this.sort_alert_by_date(event, 'start_timestamp_utc') }}>Date &nbsp;<i className="fa fa-sort" aria-hidden="true"></i></th> */}
                                                                    <th onClick={(event) => { this.sort_alerts_by_alphabet(event, 'alert_info_1') }}>{this.headerinfocheck(this.state.info1, 'Info 1')}&nbsp;<i className="fa fa-sort" aria-hidden="true"></i></th>
                                                                    <th onClick={(event) => { this.sort_alerts_by_alphabet(event, 'alert_info_2') }}>{this.headerinfocheck(this.state.info2, 'Info 2')}&nbsp;<i className="fa fa-sort" aria-hidden="true"></i></th>
                                                                    <th></th>
                                                                </tr>
                                                                :
                                                                <tr>
                                                                    {this.state.alertActive === 'Geofence' ? <th>Geofence Name</th> : ''}
                                                                    <th onClick={(event) => { this.sort_alerts_by_alphabet(event, 'regn_number') }}>{vehicleRegBasedView == false ? 'Registration Number' : "Alert Name"}&nbsp;<i className="fa fa-sort" aria-hidden="true"></i></th>
                                                                    {/* <th>{vehicleRegBasedView == false ? 'Registration Number' : "Alert Name"}</th> */}
                                                                    {/* <th>Device Id</th> */}
                                                                    <th onClick={(event) => { this.sort_alerts_by_alphabet(event, 'obu_id') }}>Device Id <i className="fa fa-sort" aria-hidden="true"></i></th>

                                                                    {this.state.alertActive === 'Fuel' ?
                                                                        <th onClick={(event) => { this.sort_alert_by_date(event, 'start_timestamp_utc') }}
                                                                        >Last Occurrence Time <i className="fa fa-sort" aria-hidden="true"></i></th> : ''
                                                                    }
                                                                    {this.state.alertActive === 'Fuel' ?
                                                                        <th onClick={(event) => { this.sort_alerts_by_alphabet(event, 'ignition_status') }}
                                                                        >Ignition <i className="fa fa-sort" aria-hidden="true"></i></th> : ''

                                                                    }

                                                                    <th onClick={(event) => { this.sort_alerts_by_numeric(event, 'Occurance') }}>Occurrence&nbsp;<i className="fa fa-sort" aria-hidden="true"></i></th>
                                                                    {/* <th>Occurance</th> */}
                                                                </tr>
                                                        }
                                                        {this.state.alertActive === 'Geofence' ?
                                                            <tr>
                                                                <td>
                                                                    <div className="search-box">
                                                                        <div className="input-group">
                                                                            <input type="text" id="search" className="form-control geofence_alerts_input" onChange={(event) => { this.searchInputFilterGeo(event, 'geofence_name') }} placeholder="Search by Geofence Name" />
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                {vehicleRegBasedView == true && <td>
                                                                    <div className="search-box">
                                                                        <div className="input-group">
                                                                            <input type="text" id="search" className="form-control geofence_alerts_input" onChange={(event) => { this.searchInputFilterGeo(event, 'alert_name') }} placeholder="Search by Alert Name" />
                                                                        </div>
                                                                    </div>
                                                                </td>}
                                                                {vehicleRegBasedView == false && <td>
                                                                    <div className="search-box">
                                                                        <div className="input-group">
                                                                            <input type="text" id="search" className="form-control geofence_alerts_input" value={this.state.searchInputreg} onChange={(event) => { this.searchInputFilterGeo(event, 'regn_number') }} placeholder="Search by Registration Number" />
                                                                        </div>
                                                                    </div>
                                                                </td>}
                                                                <td>
                                                                    <div className="search-box">
                                                                        <div className="input-group">
                                                                            <input type="text" id="search" className="form-control geofence_alerts_input" onChange={(event) => { this.searchInputFilterGeo(event, 'obu_id') }} placeholder="Search by Device Id" />
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="search-box">
                                                                        <div className="input-group">
                                                                            <input type="text" id="search" className="form-control geofence_alerts_input" onChange={(event) => { this.searchInputFilterGeo(event, 'Occurance') }} placeholder="Search by Occurrence" />
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            : null
                                                        }
                                                    </thead>
                                                    <tbody>
                                                        {fleetdtls.length ? fleetdtls :
                                                            initialDtls ?
                                                                <tr>
                                                                    <td colSpan="3">
                                                                        <div className="errorDiv">
                                                                            <p className="errorMsg">
                                                                                No Data Available
                                                                            </p>
                                                                        </div>
                                                                    </td>
                                                                </tr> : null
                                                        }
                                                    </tbody>
                                                </table>
                                        }
                                        {
                                            viewlocation ?
                                                null :
                                                pageNumbers.length ?
                                                    <div className="row pagination-row">
                                                        <div className="pagina-length">
                                                            <table>
                                                                <tbody>
                                                                    <tr id="page-numbers">
                                                                        <td className="pagesize page_symbol" onClick={this.changePage.bind(this, "<<")}>&laquo;</td>
                                                                        <td className="pagesize page_symbol" onClick={this.changePage.bind(this, "<")}>&#x2039;</td>
                                                                        {renderPageNumbers}
                                                                        <td className="pagesize page_symbol" onClick={this.changePage.bind(this, ">")}>&#8250;</td>
                                                                        <td className="pagesize page_symbol" onClick={this.changePage.bind(this, ">>")}>&raquo;</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div> : null
                                        }
                                    </div>
                                    {viewlocation ?
                                        <div>
                                            <div className="fleet-detail">
                                                <div className="fleet-row">
                                                    <div className="fleet-column">
                                                        <img src="/images/fleet_alerts/Back.png" alt=""
                                                            onClick={this.backToFleetTable} style={{ cursor: 'pointer' }}></img>
                                                    </div>
                                                    <div className="fleet-column">
                                                        <p>{detailFleetDetail.regn_number}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="fleet-table-scroll">
                                                <table className="location-table">
                                                    <thead>
                                                        {
                                                            active && detailFleetDetail.alert_type_name !== "Vehicle Exit from Geofence" ?
                                                                <tr>
                                                                    <th>{detailFleetDetail.alert_type_id == 108 ? 'Entry Date' : 'Date'}</th>
                                                                    {/* <th>Time</th> */}
                                                                    <th>{detailFleetDetail.alert_type_id == 108 ? 'Entry Location' : 'Location'}</th>
                                                                    <th>{this.headerinfocheck(detailFleetDetail.alert_info_1, 'Info 1')}</th>
                                                                    <th>{this.headerinfocheck(detailFleetDetail.alert_info_2, 'Info 2')}</th>
                                                                    {detailFleetDetail.alert_type_id === 11 || detailFleetDetail.alert_type_id === 12 || detailFleetDetail.alert_type_id === 13  ? <th>Ignition</th> : ''}
                                                                </tr> :
                                                                <tr>
                                                                    <th></th>
                                                                    <th>Date</th>
                                                                    <th>Location</th>
                                                                    <th>{this.headerinfocheck(this.state.info1, 'Info 1')}</th>
                                                                    <th>{this.headerinfocheck(this.state.info2, 'Info 2')}</th>
                                                                </tr>
                                                        }
                                                    </thead>
                                                    <tbody>
                                                        {active && detailFleetDetail.alert_type_name !== "Vehicle Exit from Geofence" ? detailFleetactiveTable : detailFleetDetailTable}
                                                    </tbody>
                                                </table>
                                                {
                                                    renderRegistrationPageNumbers.length ?
                                                        <div className="row pagination-row">
                                                            <div className="pagina-length">
                                                                <table>
                                                                    <tbody>
                                                                        <tr id="page-numbers">
                                                                            <td className="pagesize page_symbol" onClick={this.changeRegistrationPage.bind(this, "<<")}>&laquo;</td>
                                                                            <td className="pagesize page_symbol" onClick={this.changeRegistrationPage.bind(this, "<")}>&#x2039;</td>
                                                                            {renderRegistrationPageNumbers}
                                                                            <td className="pagesize page_symbol" onClick={this.changeRegistrationPage.bind(this, ">")}>&#8250;</td>
                                                                            <td className="pagesize page_symbol" onClick={this.changeRegistrationPage.bind(this, ">>")}>&raquo;</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div> : null
                                                }
                                            </div>
                                        </div>
                                        : null}
                                </div>
                    }
                    {!this.state.dialog && (this.props.ctr.userroleid === 6 || this.props.ctr.userroleid === 8) &&
                        <div className="chatbot-container">
                            <button class="btn-dialog" onClick={(e) => { this.handleDialog(e) }}>
                                <img src="alert_config.png" alt="bot"></img>
                            </button><span class="may_help_txt">Customize Alert</span>
                        </div>
                    }
                </div>
                {
                    this.state.traceData ?
                        <div className="mapAlertsPopup">
                            <div className="popup" style={{ display: this.state.showMap }}>
                                <div className="popupbody">
                                    <div className="popupContent">
                                        <div className="col-lg backclose">
                                            <img src="/images/TripList/Close.png" onClick={this.passingVal} alt=""></img>
                                        </div>
                                        <FleetMap zoom={this.state.zoom} center={this.state.center}
                                            height="70vh" routeIndex={0} markerPosition={this.state.position}
                                            showDirection={false} showMarker={true}
                                            traceData={this.state.traceData}>
                                        </FleetMap>

                                    </div>
                                </div>
                            </div>
                        </div> : ''
                }

                {this.state.dialog &&
                    <Configuration closeFunc={this.handleDialog} regList={this.state.alertVehicleList} alertType={this.state.alertType} listApi={this.AlertConfigVehicleList} />
                }

            </div>
        )
    }

}


const mapStateToprops = state => {
    return {
        ctr: state.userdata.id,
        searchresult: state.menus.globalSearch
    };
}

const mapDispatchToprops = dispatch => {
    return {
        gmapsDispatch: (payload) => dispatch(GmapsDispatchThunk(payload)),
        searchresultbox: (data) => dispatch({ type: 'SEARCHBOXCNT', value: data }),
        searchupdatedata: (data) => dispatch({ type: 'searchupdatedata', value: data }),
        quicklinks: (data) => dispatch({ type: "QUICKLINK", value: data }),
    };
};

export default connect(mapStateToprops, mapDispatchToprops)(FleetAlerts);