import React from 'react'

const CustomAvatar = () => {
  return (
    <div className="react-chatbot-kit-chat-bot-avatar">
      <div
        className="react-chatbot-kit-chat-bot-avatar-container"
        style={{ background: "none" }}
      >
        {/* <img className='avatar-img' alt="BotAvatar" src='message.png' /> */}
        <span className='customer-avatar-txt'><i class="fa fa-user-circle fa-2x" aria-hidden="true"></i></span>
      </div>
    </div>
  )
}

export default CustomAvatar