const initialState ={
    regionList : [],
    statusList : []
}

const vasReducer = (state = initialState , action) =>{
    if(action.type === "REGION"){
        return {
            ...state,
            regionList:action.value.regionList,
            statusList:action.value.statusList
        }
    }
    return state;
}

export default vasReducer;