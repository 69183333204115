import React, { Component } from 'react';
import CreateMap from './SarathyTripMap';
import '../../Components/CreateTrips/createTrips.scss';
import './SarathyCreateTrip.scss';
import AutoCompleteContent from '../CreateTrips/mapsearchbox';
import CustomDatePicker from '../CreateTrips/datepicker';

import { withStyles } from '@material-ui/core/styles';
import { blue, grey } from '@material-ui/core/colors';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { postData, changeformatDate, changeTimeFormat,mathRound } from '../../Util';
import { connect } from 'react-redux';
import AlertBox from '../AlertBox/alertBox';
//import NavLink from '../Link/Link';
import { Link } from 'react-router-dom';
import * as apilist from '../../apiList';
import Geocode from "react-geocode";
import classnames from 'classnames';
import { Redirect } from 'react-router';
import subDays from "date-fns/subDays";

class SarathyCreateTrip extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tripid:"",
            location: { lat: 20.5937, lng: 78.9629 },
            showMarker:true,
            markerPosition: "",
            zoom: 5,
            vehicleRegNum:"",
            vinNumber:"",
            filterRegisteredVehicles:[],
            RegisteredVehicles:[],
            validRegNumber:false,
            assignDriver:"",
            drivers:[],
            appSegment:"",
            startPoint:"",
            destPoint:"",
            startPoint_places: "",
            destPoint_places: "",
            startPoint_city:"",
            destPoint_city:"",
            startPoint_zipcode:null,
            destPoint_zipcode:null,
            eventName:"",
            focusEventName:"",
            start_location_latitude: "",
            start_location_longitude: "",
            end_location_latitude: "",
            end_location_longitude: "",
            totalTripTime:"",

            tripdistance:0,
            tripDuration:0,
            plannedTripDuration:0,
            targetKmpl:"",
            payload:"",
            stoppageLimit:"",
            stoppageLimits:[],
            openStoppageLimit:false,
            stoppageLimitsArr: [],
            tempStoppageLimitsArr: [],
            productivity:33,
            performance:34,
            safety:33,
            nightDriving:false,
            nightDrivStartDate:"",
            nightDrivEndDate:"",

            contDriving:false,

            tripStartDate:"",
            tripEndDate:"",
            tripStartTime:"",
            tripEndTime:"",

            startMaxdate:"",
            startMindate: new Date(),
            endMaxdate:"",
            endMindate: new Date(),
            excludeEndDateArr:[],
            continuousDrivingHours: 0,

            route_directions:{},
            temp_route_directions:{},
            alternateRoutes:[],
            editTripInd: false,
            initialEditTripInd:false,
            redirect: false,

            showMessage:"",
            showAlertBox: false,
            nonUpdateAble:false, 
            messageType:"e",
            loader: false,

            startPoint_addFavoriteInd:false,
            destPoint_addFavoriteInd:false,
            openFavouriteInd:false,
            favouriteLocationList:[],
            disabledSelectBtn:true,
            showTopDriversPopup:false,
            disabledDriverSelectBtn:true,
            topDriversList:[],

            startPoint_topDriver:"",
            destPoint_topDriver:"",
            startPoint_locationArr:{},
            destPoint_locationArr:{},
            showAddFavourite: false,
            confirmAddFavourite:false,
            favLocName: "",
            destPoint_favLocName:"",
            startPoint_favLocName:"",
            errorfavLoc: false
        }
        this.baseState = this.state;
        this.handlePlaces = this.handlePlaces.bind(this);
        this.handleRoutes = this.handleRoutes.bind(this);
    }

    componentDidMount(){
        this.callinpiufunbction2();
        this.callinpiufunbction1();
        this.composeOverStoppageTime();
        this.getFavouriteList();

        if(this.props.location.state !== undefined && this.props.location.state !== null
            && this.props.location.state.tripId ){
            this.vehicleListApi(true);
        }
        else{
            this.vehicleListApi(false);
        }
        
    }

    getFavouriteList = () =>{
        let tokens = this.props.ctr.token;
        const favouriteList = postData(apilist.sarathyTripFavouriteLocationList, "",tokens);
        favouriteList.then((info) => {
            if (info.statuscode === 200) {
                this.setState({ 
                    favouriteLocationList: info.data
                })
            } else {
                this.setState({
                    favouriteLocationList:[],
                    loader : false
                });
            }
        });
    }

    composeOverStoppageTime = () =>{
        var stoppageLimitsArr = [];

        for(var i=0;i<=48;i+=0.50){
            var limit ={value:"", label:""};
            limit.value=i;
            var stringi = i.toString();
            if(stringi.toString().indexOf(".") !== -1){
                var integerValue = stringi.split(".")[0];
                limit.label= integerValue+".30".toString();
            }
            else{
                limit.label=i+".00".toString();
            }

            let limitArr = limit.label.split('.');

            if(limitArr[0].length === 1){
                limit = {
                    ...limit,
                    label: "0"+ limitArr[0] +"."+limitArr[1]
                }
            }

            stoppageLimitsArr.push(limit);
        }

        let stoppageLimits = stoppageLimitsArr.map((limit,index) => 
            <button key={index} onClick={this.optionValueSelect.bind(this,limit.label)} className="dropdown-item"> 
                {limit.label}
            </button>);

        this.setState({
            stoppageLimits:stoppageLimits,
            stoppageLimitsArr: stoppageLimitsArr,
            tempStoppageLimitsArr: stoppageLimitsArr
        })
    }

    driverListApi = () =>{
        var tripData = {};
        tripData.platform = "w";
        tripData.userId = this.props.ctr.userid;
        let tokens = this.props.ctr.token;
        const driverList = postData(apilist.sarathyTripDriversList, tripData,tokens);
        driverList.then((info) => {
            if (info.statuscode === 200) {
                this.setState({ 
                    drivers: info.data
                })
            } else {
                this.setState({
                    loader : false
                });
                //alert("Failure");
            }
        });
    }

    vehicleListApi = (subject) =>{
        var tripData = {};
        tripData.platform = "w";
        tripData.userId = this.props.ctr.userid;
        let tokens = this.props.ctr.token;
        const vehicleInfo = postData(apilist.sarathyTripRegnNo, tripData,tokens);
        this.setState({
            loader : true
        });
        vehicleInfo.then((response) => {
            if(response.statuscode === 204){
                this.setState({
                    loader : false
                });
                this.openModel(response.message,"e");
                this.props.changeAllowed({"allowed":false,"message":response.message});
                return false;
            }
            else{
                this.props.changeAllowed({"allowed":true,"message":""});
                this.driverListApi();
                this.setState({
                    RegisteredVehicles: response.data,
                    filterRegisteredVehicles: response.data,
                    loader : subject
                }, this.composeEditTrip)
            }
        })
    }

    composeEditTrip = () =>{
        if(this.props.location.state !== undefined && this.props.location.state !== null
            && this.props.location.state.tripId ){
           
            let tripid = this.props.location.state.tripId;
            var editData = {};
            editData.platform = "w";
            editData.tripId = tripid;
            let tokens = this.props.ctr.token;
            const tripValueList = postData( apilist.sarathySingleTrip, 
                editData,tokens);
            tripValueList.then((response) => {
                if (response.statuscode === 200) {
                    let tripdata = response.data[0];
                    if(tripdata.start_loc_latitude){

                        Geocode.fromLatLng(tripdata.start_loc_latitude.toString(),
                            tripdata.start_loc_longitude.toString()).then(
                                response => {
                                    let address = response.results[0];
                                    let startPoint_places = {};
                                    startPoint_places.formatted_address = tripdata.start_location;
                                    startPoint_places.lat = address.geometry.location.lat;
                                    startPoint_places.lng = address.geometry.location.lng;

                                    let address_components = address.address_components;
                                    let city = this.getCity(address_components);
                                    let zipCode = this.getPinCode(address_components);

                                    let locationArr = this.getComponents(address_components);
                                    let loc_topDriver = this.getTopDriverplaces(locationArr);

                                    let index = this.getIndexOfFavLocation(tripdata.start_loc_latitude,
                                        tripdata.start_loc_longitude);

                                    this.setState({
                                        startPoint: tripdata.start_location,
                                        startPoint_places: startPoint_places,
                                        startPoint_city:city,
                                        startPoint_zipcode:zipCode,
                                        startPoint_addFavoriteInd: index >= 0 ? true : false,
                                        startPoint_topDriver:loc_topDriver,
                                        startPoint_locationArr:locationArr,
                                    })
                                },
                                error => {
                                    console.error(error);
                                }
                            );
                    }
                    Geocode.fromLatLng(tripdata.end_loc_latitude.toString(),
                        tripdata.end_loc_longitude.toString()).then(
                            response => {
                                let address = response.results[0];
                                let destPoint_places = {};
                                destPoint_places.formatted_address = tripdata.end_location; //address.formatted_address;
                                destPoint_places.lat = address.geometry.location.lat;
                                destPoint_places.lng = address.geometry.location.lng;

                                let address_components = address.address_components;
                                let city = this.getCity(address_components);
                                let zipCode = this.getPinCode(address_components);

                                let locationArr = this.getComponents(address_components);
                                let loc_topDriver = this.getTopDriverplaces(locationArr);

                                let index = this.getIndexOfFavLocation(tripdata.end_loc_latitude,
                                    tripdata.end_loc_longitude);

                                this.setState({
                                    //destPoint: address.formatted_address,
                                    destPoint: tripdata.end_location,
                                    destPoint_places: destPoint_places,
                                    destPoint_city:city,
                                    destPoint_zipcode:zipCode,
                                    destPoint_addFavoriteInd: index >= 0 ? true : false,
                                    destPoint_topDriver:loc_topDriver,
                                    destPoint_locationArr:locationArr,
                                })
                            },
                            error => {
                                console.error(error);
                            }
                        );
                    let route_directions = {};
                    route_directions.startLat = tripdata.start_loc_latitude;
                    route_directions.startLng = tripdata.start_loc_longitude;
                    route_directions.destLat = tripdata.end_loc_latitude;
                    route_directions.destLng = tripdata.end_loc_longitude;

                    let tripStartDate = tripdata.planned_trip_start;
                    let tripStartTime = tripdata.planned_trip_start;
                    let tripEndDate = tripdata.planned_trip_end !== null ? tripdata.planned_trip_end : "";
                    let tripEndTime = tripdata.planned_trip_end !== null ? tripdata.planned_trip_end : "";

                    let timeinHrs = "";

                    if(tripEndDate){
                        var stateDate = new Date(tripStartDate);
                        var endDate = new Date(tripEndDate);
                
                        var diff =(endDate.getTime() - stateDate.getTime()) / 1000;
                        var travellingTime = Math.abs(Math.round(diff));
                        
                        var hours = Math.floor(travellingTime / 3600);
                        var mins = Math.floor(travellingTime % 3600 / 60);
                
                        var hDisplay = hours > 0 ? hours : "00";
                        var mDisplay = mins > 0 ? mins : "00";

                        timeinHrs = hDisplay +":"+ mDisplay;
                    }
                    
                    let nightDriving = false;
                    let contDiving = false;
                    if(tripdata.night_driving === "True"){
                        nightDriving = true;
                    }
                    if(tripdata.continuous_driving === "True"){
                        contDiving = true;
                    }

                    let todayDate = new Date();
                    let endMinDate = new Date (tripStartDate);
            
                    const diffTime = todayDate - endMinDate;

                    let excludeDateArr = [];
                    if(diffTime > 0){
                        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                        for(let i = 1; i < diffDays;i++){  
                            excludeDateArr.push(subDays(new Date(), i));
                        }
                    }

                    let nightFromDate = tripdata.no_night_driving_from === null ? "" : 
                            new Date(changeformatDate(todayDate) +" "+ tripdata.no_night_driving_from);
                    let nightToDate = tripdata.no_night_driving_to === null ? "" :
                            new Date(changeformatDate(todayDate) +" "+ tripdata.no_night_driving_to);

                    this.setState({
                        loader: false,
                        tripid: tripid,
                        assignDriver: tripdata.driver_id,
                        tempAssignDriver: tripdata.driver_id,
                        vinNumber : tripdata.vin_number,
                        vehicleRegNum: tripdata.regn_number,
                        tempVinNumber : tripdata.vin_number,
                        appSegment: tripdata.application_segment,
                        targetKmpl : tripdata.target_kmpl,
                        tripstatus : tripdata.trip_status,
                        nonUpdateAble : tripdata.trip_status === "STARTED" ? true : false, 
                        tripStartDate : new Date(tripStartDate),
                        tripStartTime : new Date(tripStartTime),
                        tripEndDate : tripEndDate ? new Date(tripEndDate) : "",
                        tripEndTime : tripEndDate ? new Date(tripEndTime) : "",
                        endMindate : new Date(tripStartDate),
                        startMaxdate : tripEndDate ? new Date(tripEndTime) : "",
                        excludeEndDateArr: excludeDateArr,
                        route_directions: route_directions,
                        temp_route_directions: route_directions,
                        performance : tripdata.performance_weightage * 100,
                        safety : tripdata.safety_weightage * 100,
                        productivity : tripdata.productivity_weightage * 100,
                        payload : tripdata.payload,
                        stoppageLimit : tripdata.over_stoppage,
                        totalTripTime: timeinHrs,
                        tripdistance: tripdata.planned_trip_dis,
                        nightDriving: nightDriving,
                        nightDrivStartDate: nightFromDate,
                        nightDrivEndDate: nightToDate,
                        contDriving: contDiving,
                        continuousDrivingHours: tripdata.continuos_driving_hours_max,
                        editTripInd : true,
                        initialEditTripInd:true,
                        validRegNumber:true
                    });
                }
            });
        }
        else{
            return false;
        }
    }

    handleRoutes = (_State) => {
        if(_State === ""){
            this.openModel("No Route Available for this Starting and Ending Point","e");
            return false;
        }
        var route = _State.directions.routes[0];
        this.setState({
            alternateRoutes: _State ? _State.directions.routes : [],
            tripdistance: this.state.initialEditTripInd ? this.state.tripdistance : route ? (Math.round(route.legs[0].distance.value/1000)) : 0,
            tripDuration: route ? (route.legs[0].duration.value /= 3600) : 0,
            showMarker: false
        })
    }

    resetHandler = () =>{
        this.setState(this.baseState);
        this.setState({
            temp_route_directions: {}
        });
        this.vehicleListApi(false);
        this.getFavouriteList();
        this.composeOverStoppageTime();
    }

    cancelHandler = () =>{
        let tripData = {};
        tripData.platform = "w";
        tripData.tripId = this.state.tripid;

        let tokens = this.props.ctr.token;
        const vehicleInfo = postData(apilist.sarathyTripCancel, tripData,tokens);
        vehicleInfo.then((response)=>{
            if(response.statuscode === 200){
                document.body.scrollTop = 0; // For Safari
                document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                this.openModel(response.message,"s");
                this.setState({
                    nonUpdateAble : true,
                    tripstatus: "CANCELLED"
                })
            }
            else{
                this.openModel(response.message,"e");
            }
        })
    }

    deleteHandler = () =>{
        let tripData = {};
        tripData.platform = "w";
        tripData.tripId = this.state.tripid;
        let tokens = this.props.ctr.token;
        const vehicleInfo = postData(apilist.sarathyTripDelete, tripData,tokens);
        vehicleInfo.then((response) => {
            if(response.statuscode === 200){
                document.body.scrollTop = 0; // For Safari
                document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                this.openModel(response.message,"s");
                this.resetHandler();
            }
            else{
                this.openModel(response.message,"e");
            }
        })
    }

    onChangeSearchMap = (event) =>{
        let eventname = event.target.name;

        this.setState({ 
            [eventname]: event.target.value,
            eventName: eventname,
            showFavouriteModel: true
        });
    }

    onChangeStoppageLimit = (event) =>{
        this.setState({ 
            [event.target.name]: event.target.value,
            openStoppageLimit:true
        });
    }

    onChangeRegNum = (event) =>{
        this.setState({ 
            [event.target.name]: event.target.value,
            openVehicleRegNumber:true
        });
    }

    onChangeTripInfo = (event) =>{
        this.setState({ 
            [event.target.name]: event.target.value
        },function (){
            this.checkValidValue();
        });
    }

    checkValidValue = () =>{
        if(this.state.targetKmpl > 6){
            this.setState({
                targetKmpl : 0
            })
            document.getElementById("targetKmpl").blur();
            this.openModel("Please enter valid value");
            return false;
        }
        // else if (parseFloat(this.state.payload) > 50){
        //     this.setState({
        //         payload : 0
        //     })
        //     document.getElementById("payload").blur();
        //     this.openModel("Please enter valid value");
        //     return false;
        // }
        // else if (this.state.payload % 1 !== 0){
        //     var with2Decimals = this.state.payload.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
        //     this.setState({
        //         payload: with2Decimals
        //     })
        // }
    }

    changeweightage = (event) =>{
        this.setState({ 
            [event.target.name]: event.target.value 
        },function (){
            this.calculateweightage();
        });
    }

    calculateweightage = () =>{
        var productivity = parseInt(this.state.productivity) || 0;
        var performance = parseInt(this.state.performance) || 0;

        if( productivity > 67 && productivity <= 100){
            this.setState({
                performance : 100 - productivity,
                safety : 0
            })
            return false;
        }
        if(performance > 67 && performance <= 100){
            this.setState({
                productivity : 100 - performance,
                safety : 0
            })
            return false;
        }
        let safety = 100 - (productivity + performance);
        if(safety < 0 || productivity < 0 || performance < 0) {
            this.setState({
                productivity : 0,
                performance : 0,
                safety : 100
            })
            document.getElementById("productivity").blur();
            document.getElementById("performance").blur();
            this.openModel("Please enter valid value");
            return false;
        }else{
            this.setState({
                safety : safety
            })
        }
    }

    onFocusSearchMap = (event) =>{
        this.setState({
            focusEventName : event.target.name,
            showFavouriteModel:true
        })
    }

    handleChange = (date, name) => {
        let mindate = "";
        let maxdate = "";
        let mindateValue = "";
        let maxdateValue = "";
        if (name === "tripStartDate") {
            mindate = "endMindate";
            mindateValue = new Date(changeformatDate(date));
        } else if (name === "tripEndDate") {
            maxdate = "startMaxdate";
            maxdateValue = new Date(changeformatDate(date));
        } else if (name === "nightDrivStartDate"){
            mindate = "nightEndMindate";
            mindateValue = new Date(changeformatDate(date));
        } else if (name === "nightDrivEndDate"){
            mindate = "nightStartMaxdate";
            mindateValue = new Date(changeformatDate(date));
        }
        this.setState({
            [name]: date,
            [mindate]: mindateValue,
            [maxdate]: maxdateValue
        },function (){
            this.getTimeDifference();
        });
    };

    changeTab = (name,event) =>{
        this.setState({
            [name]: event.target.checked
        })
    }

    getCountry = (addrComponents) => {
        for (var i = 0; i < addrComponents.length; i++) {
            if (addrComponents[i].types[0] === "country") {
                return addrComponents[i].short_name;
            }
            if (addrComponents[i].types.length === 2) {
                if (addrComponents[i].types[0] === "political") {
                    return addrComponents[i].short_name;
                }
            }
        }
        return "";
    }

    getCity = (addressComponents) =>{
        for(var i = 0; i < addressComponents.length; i++) {
            if (addressComponents[i].types[0] === "locality") {
                let city = addressComponents[i].short_name;
                return city;
            }
        }
        return "";
    }

    getPinCode = (addressComponents) =>{
        for(var i = 0; i < addressComponents.length; i++) {
            if (addressComponents[i].types[0] === "postal_code") {
                let postalCode = addressComponents[i].long_name;
                return postalCode;
            }
        }
        return null;
    }

    getComponents = (addressComponents) =>{
        var components={};
        addressComponents.forEach((v1) => {
            v1.types.forEach((v2)=>{
                components[v2]=v1.long_name
            });
        });
        return components;
    }

    getTopDriverplaces = (locationArr) =>{
        let location = locationArr.administrative_area_level_1 !== undefined ? locationArr.administrative_area_level_1 : null;
        location = locationArr.administrative_area_level_2 !== undefined ? location +","+locationArr.administrative_area_level_2 : location +","+null;
        location = locationArr.locality !== undefined ? location +","+ locationArr.locality : location +","+null;
        return location;
    }

    getIndexOfFavLocation = (routeLat, routeLng) =>{
        var index = this.state.favouriteLocationList.findIndex((list)=>{
            return (this.getFloat12Digits(list.location_latitude) === this.getFloat12Digits(routeLat) && 
                this.getFloat12Digits(list.location_longitude) === this.getFloat12Digits(routeLng))
        });
        return index
    }

    getFloat12Digits = (num) =>{
        return parseFloat(num.toString().match(/^-?\d+(?:\.\d{0,12})?/)[0]);
    }

    handlePlaces = (placeVals) =>{
        console.log(placeVals)
        let location = {};
        let zoom = 12;
        location.lat = placeVals.geometry.location.lat();
        location.lng = placeVals.geometry.location.lng();
        Geocode.fromLatLng(location.lat.toString(),
            location.lng.toString()).then(
                response => {
                    let address = response.results[0].address_components;
                    let country = this.getCountry(address);
                    let city = this.getCity(address);
                    //console.log(this.getComponents(address));
                    let locationArr = this.getComponents(address);
                    let loc_topDriver = this.getTopDriverplaces(locationArr);
                    //loc_topDriver = locationArr.administrative_area_level_2 === locationArr.locality ? loc_topDriver : loc_topDriver+","+locationArr.locality;
                    let zipCode = this.getPinCode(address);
                    if(country !== 'IN'){
                        this.setState({
                            startPoint : "",
                            destPoint: "",
                            startPoint_places:"",
                            destPoint_places:"",
                            showMarker: false,
                            route_directions: {},
                            temp_route_directions:{}
                        })
                        this.openModel("Please Select the Place Within India","e");
                        return false;
                    }
                    let route_directions = this.state.temp_route_directions;

                    if (this.state.eventName === "startPoint") {
                        route_directions.startLat = placeVals.geometry.location.lat();
                        route_directions.startLng = placeVals.geometry.location.lng();

                        let index = this.getIndexOfFavLocation(route_directions.startLat,
                            route_directions.startLng);
                        this.setState({
                            startPoint_addFavoriteInd : index>=0 ? true : false 
                        })
                    }
                    else if (this.state.eventName === "destPoint") {
                        route_directions.destLat = placeVals.geometry.location.lat();
                        route_directions.destLng = placeVals.geometry.location.lng();
                        
                        let index = this.getIndexOfFavLocation(route_directions.destLat,
                            route_directions.destLng);
                        this.setState({
                            destPoint_addFavoriteInd : index>=0 ? true : false 
                        })
                    }
                    
                    this.setState({
                        [this.state.eventName]: placeVals.formatted_address,
                        [this.state.eventName + "_places"]: placeVals,
                        [this.state.eventName + "_city"]:city,
                        [this.state.eventName + "_zipcode"]:zipCode,
                        [this.state.eventName + "_topDriver"]:loc_topDriver,
                        [this.state.eventName + "_locationArr"]:locationArr,
                        zoom: zoom,
                        location: location,
                        markerPosition: location,
                        routeIndex: 0,
                        temp_route_directions: route_directions,
                        route_directions: ('destLat' in route_directions && 'startLat' in route_directions) ? route_directions : {},
                        initialEditTripInd:false
                    });
                },
                error => {
                    console.log(error);
                    this.openModel("Error occurs in Map","e");
                }
            );
    }

    getNightTimeDifference = () =>{
        if(this.state.nightDrivEndDate && this.state.nightDrivStartDate){

            if(this.state.nightDrivStartDate >= this.state.nightDrivEndDate){
                this.openModel("Trip Night Drive End Time Should be greater than Night Drive Start Time","e");
                this.setState({
                    nightDrivEndDate: ""
                })
                return false;
            }
        }
    }

    getTimeDifference = () =>{
        if(this.state.tripStartDate && this.state.tripStartTime && 
            this.state.tripEndDate && this.state.tripEndTime){

            var stateDate = new Date(changeformatDate(this.state.tripStartDate)+" "+
                changeTimeFormat(this.state.tripStartTime));
            var endDate = new Date(changeformatDate(this.state.tripEndDate)+" "+
                changeTimeFormat(this.state.tripEndTime));

            if(stateDate >= endDate){
                this.openModel("Trip End Time Should be greater than Start Time","e");
                this.setState({
                    totalTripTime: "",
                    tripStartTime:"",
                    tripEndTime: ""
                })
                return false;
            }
           
            var diff =(endDate.getTime() - stateDate.getTime()) / 1000;
            var travellingTime = Math.abs(Math.round(diff));
            
            var plannedTripDuration = travellingTime / 3600;
            var hours = Math.floor(travellingTime / 3600);
            var mins = Math.floor(travellingTime % 3600 / 60);
        
            var hDisplay = hours > 0 ? hours : "00";
            var mDisplay = mins > 0 ? mins : "00";

            let timeinHrs = hDisplay +":"+ mDisplay;

            this.setState({
                totalTripTime: timeinHrs,
                plannedTripDuration : plannedTripDuration
            })
        }
    }

    openModel = (message,msgType) => {
        this.setState({
            showAlertBox: true,
            showMessage:message,
            messageType:msgType
        });
    };

    hideModal = () => {
        if(this.state.tripid){
            if(this.state.tripstatus !=="COMPLETED"){
                document.getElementById("updateId").disabled = false;
            }
        }
        else{
            document.getElementById("saveId").disabled = false;
        }
        if(this.state.filterRegisteredVehicles.length){
            this.setState({
                showMessage:"",
                showAlertBox: false 
            });
        }
        else{
            this.props.navigateToHome(false);
            this.setState({
                redirect: true
            })
        }
    };

    saveTripHandler = () =>{
        if(this.state.tripid){
            document.getElementById("updateId").disabled = true;
        }
        else{
            document.getElementById("saveId").disabled = true;
        }
        let tripData = {};
        var tripStatus = '{"CREATED TRIP":"N","STARTED":"S","COMPLETED":"E","REJECTED":"R","CANCELLED":"C","ACCEPTED":"A"}';
        const getTripStatus =  JSON.parse(tripStatus);
        tripData.platform = "w";
        tripData.tripCreationType="Fleet";
        if(this.state.vehicleRegNum === ""){
            this.openModel("Please Select Vehicle Registration Name","e");
            return false;
        }
        else if(this.state.assignDriver === ""){
            this.openModel("Please Select Driver Name","e");
            return false;
        }
        else if(this.state.appSegment === ""){
            this.openModel("Please Select Application Segment","e");
            return false;
        }
        else if(this.state.startPoint === "" || this.state.startPoint_places === ""){
            this.openModel("Please Select Start Location","e");
            return false;
        }
        else if(this.state.startPoint !== this.state.startPoint_places.formatted_address){
            this.openModel("Please Select Valid Start Location","e");
            return false;
        }
        else if(this.state.destPoint === "" || this.state.destPoint_places === ""){
            this.openModel("Please Select End Location","e");
            return false;
        }
        else if(this.state.destPoint !== this.state.destPoint_places.formatted_address){
            this.openModel("Please Select Valid Start Location","e");
            return false;
        }
        else if(this.state.tripdistance === ""){
            this.openModel("Please Enter Trip Distance","e");
            return false;
        }
        else if(this.state.tripdistance < 0){
            this.openModel("Please Enter Valid Trip Distance","e");
            return false;
        }
        else if(this.state.targetKmpl === ""){
            this.openModel("Please Enter Target KMPL","e");
            return false;
        }
        else if(this.state.targetKmpl < 0){
            this.openModel("Please Enter Valid Target KMPL","e");
            return false;
        }
        else if(this.state.payload === ""){
            this.openModel("Please Select Payload in tons","e");
            return false;
        }
        // else if(parseFloat(this.state.payload) < 0 || isNaN(parseFloat(this.state.payload))){
        //     this.openModel("Please Enter Valid Payload in tons","e");
        //     return false;
        // }
        else if(this.state.stoppageLimit === ""){
            this.openModel("Please Enter Stoppage Limit","e");
            return false;
        }
        else if(parseFloat(this.state.stoppageLimit) < 0 || isNaN(parseFloat(this.state.stoppageLimit)) ){
            this.openModel("Please Enter Valid Stoppage Limit","e");
            return false;
        }
        else if(this.state.productivity === ""){
            this.openModel("Please Enter Productivity Value","e");
            return false;
        }
        else if(this.state.productivity < 0){
            this.openModel("Please Enter Valid Productivity Value","e");
            return false;
        }
        else if(this.state.performance === ""){
            this.openModel("Please Enter Performance Value","e");
            return false;
        }
        else if(this.state.performance < 0){
            this.openModel("Please Enter Valid Performance Value","e");
            return false;
        }
        else if(this.state.safety === ""){
            this.openModel("Please Enter Safety Value","e");
            return false;
        }
        else if(this.state.safety < 0){
            this.openModel("Please Enter Valid Safety Value","e");
            return false;
        }
        else if(this.state.tripStartDate === ""){
            this.openModel("Please Enter Trip Start Date","e");
            return false;
        }
        else if(this.state.tripStartTime === ""){
            this.openModel("Please Enter Trip Start Time","e");
            return false;
        }
        else if(this.state.tripEndDate === ""){
            this.openModel("Please Enter Trip End Date","e");
            return false;
        }
        else if(this.state.tripEndTime === ""){
            this.openModel("Please Enter Trip End Time","e");
            return false;
        }
        
        tripData.nightDriving=this.state.nightDriving;
        
        if(this.state.nightDriving){
            if(this.state.nightDrivStartDate === ""){
                this.openModel("Please Enter Night Drive Start Date","e");
                return false;
            }
            else if(this.state.nightDrivEndDate === ""){
                this.openModel("Please Enter Night Drive End Date","e");
                return false;
            } 
            // else if (this.state.nightDrivStartDate < this.state.tripStartDate ||
            //             this.state.nightDrivStartDate > this.state.tripEndDate){
            //     this.openModel("Please Enter Valid Night Drive Start Date","e");
            //     return false;
            // } 
            // else if (this.state.nightDrivEndDate < this.state.tripStartDate ||
            //             this.state.nightDrivEndDate > this.state.tripEndDate){
            //     this.openModel("Please Enter Valid Night Drive End Date","e");
            //     return false;
            // }
            tripData.noNightDrivingFrom = changeTimeFormat(this.state.nightDrivStartDate);
            tripData.noNightDrivingTo = changeTimeFormat(this.state.nightDrivEndDate);
        }else{
            tripData.noNightDrivingFrom=null;
            tripData.noNightDrivingTo=null;
        }

        tripData.continuousDriving = this.state.contDriving;
        if(this.state.contDriving){
            if(this.state.continuousDrivingHours === ""){
                this.openModel("Please Enter Max. Continuous","e");
                return false;
            }
            tripData.continuosDrivingHoursMax = this.state.continuousDrivingHours;
        }else{
            tripData.continuosDrivingHoursMax = null;
        }

        let stoppageLimitFloat = parseFloat(this.state.stoppageLimit);
        let totalTripTimeFloat = parseFloat(this.state.totalTripTime.replace(":","."))

        // if( stoppageLimitFloat >= totalTripTimeFloat){
        //     this.openModel("Over Stoppage Limit should not exceed the Total Trip Time.","e");
        //     return false;
        // }
        if(stoppageLimitFloat > totalTripTimeFloat/2){
            this.openModel("Over Stoppage Limit should not exceed 50% of Total Trip Time.","e");
            return false;
        }

        let formatTripStartDate = changeformatDate(this.state.tripStartDate);
        let formatTripStartTime = changeTimeFormat(this.state.tripStartTime);
        let formatTripEndDate = changeformatDate(this.state.tripEndDate);
        let formatTripEndTime = changeTimeFormat(this.state.tripEndTime);
        
        let formatted_startDate = formatTripStartDate+" "+formatTripStartTime;
        let formatted_endDate = formatTripEndDate+" "+formatTripEndTime;

        let today_Date = new Date();
        let format_today_Date = changeformatDate(today_Date)+" "+changeTimeFormat(today_Date);
        if( format_today_Date > formatted_startDate && !this.state.tripid){
            this.openModel("Trip Start Time Should Not be Past Time","e");
            return false;
        }
        else if(format_today_Date > formatted_endDate && !this.state.tripid){
            this.openModel("Trip End Time Should Not be Past Time","e");
            return false;
        }
        else if(formatted_startDate >= formatted_endDate){
            this.openModel("Trip End Time Should be greater than Start Time","e");
            return false;
        }
        else{
            tripData.plannedTripStart = formatted_startDate;
            tripData.plannedTripEnd = formatted_endDate;//"2019-12-12 05:35:00";
        }

        tripData.userId = this.props.ctr.userid
        let tokens = this.props.ctr.token;

        tripData.driverId = this.state.assignDriver.toString();
        tripData.vin = this.state.vinNumber;
        tripData.regNumber = this.state.vehicleRegNum;
        tripData.applicationSegment= this.state.appSegment;
        tripData.overStoppage = this.state.stoppageLimit;
        tripData.productivityWeightage = this.state.productivity/100;
        tripData.performanceWeightage = this.state.performance/100;
        tripData.safetyWeightage = this.state.safety/100;
        tripData.payload = this.state.payload.toString();
        tripData.targetKmpl=this.state.targetKmpl;
        tripData.plannedTripDistance = this.state.tripdistance;
        tripData.plannedTripDuration = this.state.plannedTripDuration;

        // var vehicleRegNum = document.getElementById('vehicleRegNum');
        // tripData.regNumber = vehicleRegNum.selectedOptions[0].innerHTML;

        let startPoint_places = this.state.startPoint_places;
        let destPoint_places = this.state.destPoint_places;

        tripData.startLocation = startPoint_places.formatted_address;
        tripData.endLocation = destPoint_places.formatted_address;

        tripData.startCity = this.state.startPoint_city;
        tripData.endCity= this.state.destPoint_city;
        tripData.startPin = this.state.startPoint_zipcode;
        tripData.endPin= this.state.destPoint_zipcode;
        
        tripData.startLatitude = startPoint_places.lat === undefined ? 
            startPoint_places.geometry.location.lat() : startPoint_places.lat ;
        tripData.startLongitude = startPoint_places.lng === undefined ? 
            startPoint_places.geometry.location.lng() : startPoint_places.lng;
        tripData.endLatitude = destPoint_places.lat === undefined ? 
            destPoint_places.geometry.location.lat() : destPoint_places.lat;
        tripData.endLongitude = destPoint_places.lng === undefined ? 
            destPoint_places.geometry.location.lng() : destPoint_places.lng;

        tripData.createdBy = this.props.ctr.userid.toString();
        tripData.status = true;
        tripData.drivingHours = null;
        tripData.otherApplcnSgmt = null;

        let tripCreate = "";
        if(this.state.tripid){
            tripData.tripId = this.state.tripid;
            tripData.tripStatus = getTripStatus[this.state.tripstatus];
            tripCreate = postData(apilist.sarathyTripUpdate, tripData,tokens);
        }else{
            tripCreate = postData(apilist.sarathyTripCreate, tripData,tokens);
        }
        
        tripCreate.then((response)=>{
            if(response.statuscode === 200){
                this.resetHandler();
                document.body.scrollTop = 0; // For Safari
                document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                this.openModel(response.message,"s");
                this.vehicleListApi(false);
            }   
            else{
                this.openModel(response.message,"e");
                return false;
            }
        })
    }

    handleClickedMap = (e) =>{

        let lat = e.latLng.lat();
        let lng  = e.latLng.lng();
        let location = {};
        location.lat = lat;
        location.lng = lng;

        let route_directions = this.state.temp_route_directions;
        let eventName = this.state.focusEventName;
        if (eventName === "startPoint") {
            route_directions.startLat = lat;
            route_directions.startLng = lng;

            let index = this.getIndexOfFavLocation(route_directions.startLat,
                route_directions.startLng);
            this.setState({
                startPoint_addFavoriteInd : index>=0 ? true : false 
            })
        }
        else if (eventName === "destPoint") {
            route_directions.destLat = lat;
            route_directions.destLng = lng;

            let index = this.getIndexOfFavLocation(route_directions.destLat,
                route_directions.destLng);
            this.setState({
                destPoint_addFavoriteInd : index>=0 ? true : false 
            })
        }

        Geocode.fromLatLng(lat.toString(),
            lng.toString()).then(
                response => {
                    let address = response.results[0].formatted_address;
                    let address_components = response.results[0].address_components;
                    let country = this.getCountry(address_components);
                    let city = this.getCity(address_components);
                    let zipCode = this.getPinCode(address_components);

                    let locationArr = this.getComponents(address_components);
                    let loc_topDriver = this.getTopDriverplaces(locationArr);

                    if(country !== 'IN'){
                        this.setState({
                            startPoint : "",
                            destPoint: "",
                            startPoint_places:"",
                            destPoint_places:"",
                            showMarker: false,
                            route_directions: {},
                            temp_route_directions:{}
                        })
                        this.openModel("Please Select the Place Within India","e");
                        return false;
                    }

                    let placeVals = {};
                    placeVals.formatted_address = address;
                    placeVals.lat = lat;
                    placeVals.lng = lng;

                    this.setState({
                        [eventName]: address,
                        [eventName + "_places"]: placeVals,
                        [eventName + "_city"]:city,
                        [eventName + "_zipcode"]:zipCode,
                        [eventName + "_topDriver"]:loc_topDriver,
                        [eventName + "_locationArr"]:locationArr,
                        markerPosition: location,
                        temp_route_directions: route_directions,
                        route_directions: route_directions,
                        initialEditTripInd:false
                    })
                },
                error => {
                    console.error(error);
                }
            );
    }

    startTrip = () =>{
        let tokens = this.props.ctr.token;
        let tripData = {};
        tripData.platform = "w";
        tripData.tripId = this.state.tripid;
        tripData.vinNumber =  this.state.tempVinNumber;
        tripData.driverId = this.state.tempAssignDriver;
        
        const tripStart = postData(apilist.sarathyTripStart, tripData,tokens);
        tripStart.then((response)=>{
            if(response.statuscode === 200){
                this.openModel(response.message,"s");
                this.setState({
                    nonUpdateAble : true,
                    tripstatus: "STARTED"
                })
            }
            else{
                this.openModel(response.message,"e");
            }
        })
    }

    endTrip = () =>{
        let tokens = this.props.ctr.token;
        let tripData = {};
        tripData.platform = "w";
        tripData.tripId = this.state.tripid;
        tripData.vinNumber =  this.state.tempVinNumber;
        
        const tripEnd = postData(apilist.sarathyTripEnd, tripData,tokens);
        tripEnd.then((response)=>{
            if(response.statuscode === 200){
                this.openModel(response.message,"s");
                this.setState({
                    nonUpdateAble : false,
                    tripstatus: "COMPLETED"
                })
            }
            else{
                this.openModel(response.message,"e");
            }
        })
    }

    openCalender = (date) => {
        document.getElementById(date).focus();
    }

    passingVal=()=>{
        this.props.quicklinks([{mm:"82", sm:"86", tm:"1"}]);
        //this.props.quicklinks([{mm:"7", sm:"1", tm:"1"}]);
    }

    showStoppageLimit = () =>{

        let stoppageLimits = this.state.stoppageLimitsArr.map((limit,index) => 
            <button key={index} onClick={this.optionValueSelect.bind(this,limit.label)} className="dropdown-item"> 
                {limit.label}
            </button>);

        this.setState({
            stoppageLimits : stoppageLimits,
            openStoppageLimit:true
        })
    }

    hideStoppageLimit=()=>{
        this.setState({
            openStoppageLimit:false
        })
    }

    onlyNumbers = (event) =>{
        var key   = event.keyCode ? event.keyCode : event.which;

        if (!( [8, 9, 13, 27, 46, 110, 190].indexOf(key) !== -1 ||
            (key === 65 && ( event.ctrlKey || event.metaKey  ) ) || 
            (key >= 35 && key <= 40) ||
            (key >= 48 && key <= 57 && !(event.shiftKey || event.altKey)) ||
            (key >= 96 && key <= 105)
        )) {
            event.preventDefault();
        }
    }


    filterStoppageLimit = (event) =>{

        var stoppageLimit = this.state.tempStoppageLimitsArr.filter((limit)=>{
            return limit.label.toString().indexOf(
                event.target.value) > -1;
        });

        let stoppageLimits = stoppageLimit.map((limit) =>
            <button onClick={this.optionValueSelect.bind(this,limit.label)} key={limit.val} className="dropdown-item"> 
                {limit.label}
            </button>);

        this.setState({
            stoppageLimits : stoppageLimits
        })
    }

    optionValueSelect = (value) =>{
        this.hideStoppageLimit();
        this.setState({
            stoppageLimit: value
        });
    }

    hideOptions = () =>{
        if(this.state.openStoppageLimit){
            this.setState({
                openStoppageLimit: false
            })
        }
        if(this.state.openVehicleRegNumber){
            this.setState({
                openVehicleRegNumber:false
            })
        }
    }

    showVehicleRegNum = () =>{
        this.setState({
            openVehicleRegNumber:true,
            RegisteredVehicles:this.state.filterRegisteredVehicles
        })
    }

    filterVehicleRegNum = (event) =>{

        var regVehicles = this.state.filterRegisteredVehicles.filter((veh)=>{
            return veh.regn_number.toUpperCase().indexOf(
                event.target.value.toUpperCase()) > -1;
        });

        this.setState({
            RegisteredVehicles : regVehicles,
            validRegNumber : false
        })
    }

    selectRegisVehicles = (vinNumber, regNumber) =>{
        this.setState({
            vehicleRegNum: regNumber,
            vinNumber: vinNumber,
            validRegNumber:true
        })
    }

    blurVehicleRegNumHandler = () =>{
        if(!this.state.validRegNumber){
            this.setState({
                vehicleRegNum : ""
            })
        }
    }

    closeAddFavourite = () =>{
        this.setState({
            showAddFavourite : false
        })
    }

    closeConfirmFavourite = () =>{
        this.setState({
            confirmAddFavourite : false
        })
    }

    onClickShowFavourite = (place,addFavoriteInd) =>{
        if(addFavoriteInd){
            this.setState({
                confirmAddFavourite : true,
                favPlace:place
            })
        }
        else{
            this.setState({
                showAddFavourite : true,
                favPlace:place,
                favLocName:""
            })
        }
    }

    onClickFavoriteHandler = () =>{

        let place = this.state.favPlace;
        if((place === "startPoint" && !this.state.startPoint_addFavoriteInd && this.state.favLocName.length < 3) || 
            (place === "destPoint" && !this.state.destPoint_addFavoriteInd && this.state.favLocName.length < 3)){

            this.setState({
                errorfavLoc : true
            })
            return false;
        }

        let tokens = this.props.ctr.token;
        let addFavoriteInd = false;
        let tripData = {};
        tripData.platform = "w";

        if(place === "startPoint"){
            let startPoint_places = this.state.startPoint_places;
            tripData.locationName = startPoint_places.formatted_address;
            tripData.locationLatitude = startPoint_places.lat === undefined ? 
                startPoint_places.geometry.location.lat() : startPoint_places.lat;
            tripData.locationLongitude = startPoint_places.lng === undefined ? 
                startPoint_places.geometry.location.lng() : startPoint_places.lng;
            if(this.state.startPoint_addFavoriteInd){
                let index = this.getIndexOfFavLocation(tripData.locationLatitude,
                    tripData.locationLongitude);
                if(index >= 0){
                    tripData.locationId = this.state.favouriteLocationList[index].location_id;
                }
                tripData.locationType = this.state.startPoint_favLocName;
            }else{
                tripData.locationId = null;
                tripData.locationType = this.state.favLocName;
            }
            addFavoriteInd =  !this.state.startPoint_addFavoriteInd;
        }
        else{
            let destPoint_places = this.state.destPoint_places;
            tripData.locationName = destPoint_places.formatted_address;
            tripData.locationLatitude = destPoint_places.lat === undefined ? 
                destPoint_places.geometry.location.lat() : destPoint_places.lat;
            tripData.locationLongitude = destPoint_places.lng === undefined ? 
                destPoint_places.geometry.location.lng() : destPoint_places.lng;
            if(this.state.destPoint_addFavoriteInd){
                let index = this.getIndexOfFavLocation(tripData.locationLatitude,
                    tripData.locationLongitude)
                if(index >= 0){
                    tripData.locationId = this.state.favouriteLocationList[index].location_id;
                }
                tripData.locationType = this.state.destPoint_favLocName;
            }else{
                tripData.locationId = null;
                tripData.locationType = this.state.favLocName;
            }
            addFavoriteInd =  !this.state.destPoint_addFavoriteInd;
        }

        const favouriteLocation = postData(apilist.sarathyTripFavouriteLocation, tripData,tokens);
        favouriteLocation.then((response)=>{
            if(response.statuscode === 200){
                this.setState({
                    [place+"_addFavoriteInd"]:addFavoriteInd,
                    [place+"_favLocName"]:this.state.favLocName,
                    errorfavLoc: false,
                    showAddFavourite: false,
                    confirmAddFavourite: false
                },this.getFavouriteList())
            }
            else{
                this.openModel(response.message,"e");
            }
        })
    }

    openFavouriteModal = () =>{
        this.setState({
            openFavouriteInd : true
        })
    }

    closeFavouriteModal = () =>{
        this.setState({
            openFavouriteInd : false
        })
    }

    changeFavouriteRadio = () =>{
        this.setState({
            disabledSelectBtn: false
        })
    }

    selectLocation = () =>{
        var ele = document.getElementsByName('location'); 
        
        let locIndex ={};
        for(let i = 0; i < ele.length; i++) { 
            if(ele[i].checked) {
                locIndex = ele[i].value;
            } 
        }
        let favLocation = this.state.favouriteLocationList[locIndex];
        let zoom = 12;

        let route_directions = this.state.temp_route_directions;
        let eventName = this.state.focusEventName;
        let addFavoriteInd = false;
        let placeVals = {};

        placeVals.formatted_address = favLocation.location_name;
        placeVals.lat = favLocation.location_latitude;
        placeVals.lng = favLocation.location_longitude;

        this.setTopDriversPlaces(eventName,favLocation);

        if (eventName === "startPoint") {
            route_directions.startLat = favLocation.location_latitude;
            route_directions.startLng = favLocation.location_longitude;
            addFavoriteInd = true;
        }
        else if (eventName === "destPoint") {
            route_directions.destLat = favLocation.location_latitude;
            route_directions.destLng = favLocation.location_longitude;
            addFavoriteInd = true;
        }
        this.setState({
            openFavouriteInd : false,
            [eventName]: favLocation.location_name,
            [eventName + "_places"]: placeVals,
            zoom: zoom,
            location: placeVals,
            markerPosition: placeVals,
            temp_route_directions: route_directions,
            route_directions: route_directions,
            [eventName+"_addFavoriteInd"]:addFavoriteInd,
            [eventName + "_favLocName"]:favLocation.location_type
        })
    }

    setTopDriversPlaces = (eventName,favLocation) =>{
        Geocode.fromLatLng(favLocation.location_latitude.toString(),
            favLocation.location_longitude.toString()).then(
                response => {
                    let address = response.results[0].address_components;
                    let locationArr = this.getComponents(address);
                    let loc_topDriver = this.getTopDriverplaces(locationArr);

                    this.setState({
                        [eventName +"_topDriver"]:loc_topDriver,
                        [eventName +"_locationArr"]:locationArr,
                    })
                },
                error => {
                    console.error(error);
                }
            );
    }

    showTopDrivers = () =>{
        
        let tokens = this.props.ctr.token;
        let tripDrivers = {};

        var stateDate = changeformatDate(this.state.tripStartDate)+" "+
            changeTimeFormat(this.state.tripStartTime);
        var endDate = changeformatDate(this.state.tripEndDate)+" "+
            changeTimeFormat(this.state.tripEndTime);

        tripDrivers.fromDate= stateDate;
        tripDrivers.toDate = endDate;
        tripDrivers.fromLocation = this.state.startPoint_topDriver;
        tripDrivers.toLocation = this.state.destPoint_topDriver;
        tripDrivers.fromAddress = this.state.startPoint_locationArr;
        tripDrivers.toAddress = this.state.destPoint_locationArr;

        this.setState({
            loader:true
        })
        const favouriteLocation = postData(apilist.sarathyTripGetTopDrivers, tripDrivers,tokens);
        favouriteLocation.then((response)=>{
            if(response.statuscode === 200){
                this.setState({
                    showTopDriversPopup: true,
                    topDriversList:response.data,
                    loader:false
                })
            }
            else{
                this.setState({
                    showTopDriversPopup: true,
                    topDriversList:[],
                    loader:false
                })
            }
        })
    }

    closeTopDrivers = () =>{
        this.setState({
            showTopDriversPopup:false
        })
    }

    changeTopDriverRadio =()=>{
        this.setState({
            disabledDriverSelectBtn: false
        })
    }

    selectTopDriver = () =>{
        var ele = document.getElementsByName('topDriver'); 
        
        let drivIndex ={};
        for(let i = 0; i < ele.length; i++) { 
            if(ele[i].checked) {
                drivIndex = ele[i].value;
            } 
        }
        let topDriver = this.state.topDriversList[drivIndex];

        this.setState({
            showTopDriversPopup:false,
            assignDriver : topDriver.driverId
        })
    }

    
    callinpiufunbction1 =()=>{
        console.log(this)
        var statevalue= this;
        var iframe,iframe_google;   
    iframe = document.createElement('iframe');
    iframe.id = "map_frame";
    iframe.frameBorder = 0;
    iframe.src = "/dummy.html";
    iframe.sandbox = "allow-same-origin allow-scripts allow-top-navigation";
    
    iframe.onload = function () {
        
      var script = iframe.contentWindow.document.createElement('script');
  
      var input = iframe.contentWindow.document.createElement('input');
      input.type= 'text';
      input.id='searchByLocationBoxes1';
      input.className = 'location_search_box';
      iframe.contentWindow.document.getElementsByTagName('body')[0].appendChild(input);
        
      iframe.contentWindow.insertPlacesApi = function() {
            //google.maps.places = this.google.maps.places;
            var placed
            iframe_google = this.google;
            var searchLocationBox = document.getElementById("searchByLocationBoxes1");
            var autocomplete = new iframe_google.maps.places.Autocomplete(searchLocationBox);
            autocomplete.addListener("place_changed", () => {
            placed = autocomplete.getPlace();
            statevalue.handlePlaces(placed)                
            })
            
      };
      script.type = 'text/javascript';
      script.src = 'https://maps.googleapis.com/maps/api/js?key='+apilist.GOOGLE_API_KEY+'&sensor=false&libraries=geometry,visualization,drawing,places&callback=insertPlacesApi';
  
      iframe.contentDocument.getElementsByTagName('head')[0].appendChild(script);
    };
    document.body.append(iframe);                                      
      }


      callinpiufunbction2 =()=>{
        console.log(this)
        var statevalue= this;
        var iframe,iframe_google;   
    iframe = document.createElement('iframe');
    iframe.id = "map_frame";
    iframe.frameBorder = 0;
    iframe.src = "/dummy.html";
    iframe.sandbox = "allow-same-origin allow-scripts allow-top-navigation";
    
    iframe.onload = function () {
        
      var script = iframe.contentWindow.document.createElement('script');
  
      var input = iframe.contentWindow.document.createElement('input');
      input.type= 'text';
      input.id='searchByLocationBoxes2';
      input.className = 'location_search_box';
      iframe.contentWindow.document.getElementsByTagName('body')[0].appendChild(input);
        
      iframe.contentWindow.insertPlacesApi = function() {
            //google.maps.places = this.google.maps.places;
            var placed
            iframe_google = this.google;
            var searchLocationBox = document.getElementById("searchByLocationBoxes2");
            var autocomplete = new iframe_google.maps.places.Autocomplete(searchLocationBox);
            autocomplete.addListener("place_changed", () => {
            placed = autocomplete.getPlace();
            statevalue.handlePlaces(placed)
                console.log('Places',placed)
            })
            
      };
      script.type = 'text/javascript';
      script.src = 'https://maps.googleapis.com/maps/api/js?key='+apilist.GOOGLE_API_KEY+'&sensor=false&libraries=geometry,visualization,drawing,places&callback=insertPlacesApi';
  
      iframe.contentDocument.getElementsByTagName('head')[0].appendChild(script);
    };
    document.body.append(iframe);                                      
      }

    render() {

        const { nightDriving, contDriving, showMessage, showAlertBox, messageType,
                drivers,RegisteredVehicles,filterRegisteredVehicles,tripstatus,
                nonUpdateAble,redirect, startPoint_addFavoriteInd,destPoint_addFavoriteInd,
                openFavouriteInd,favouriteLocationList, showFavouriteModel,
                showTopDriversPopup,disabledDriverSelectBtn,topDriversList,
                showAddFavourite,errorfavLoc,confirmAddFavourite} = this.state;

        if(redirect){
            return <Redirect to='/home' />
        }

        const appSegments = ["Cargo","Corporate","HCV","LCV","Military","School","Passenger Segment","Other"];

        const payloads = ["1","2","3","4","5","6","7","8","9","10",
                        "11","12","13","14","15","16","17","18","19","20",
                        "21","22","23","24","25","26","27","28","29","30",
                        "31","32","33","34","35","36","37","38","39","40",
                        "41","42","43","44","45","46","47","48","49","50"];

        let vehicleRegNos = RegisteredVehicles.map((veh,index) => 
        <button key={index} onClick={this.selectRegisVehicles.bind(this,veh.vin_number,veh.regn_number)} className="dropdown-item"> 
            {veh.regn_number}
        </button>);

        const BlueSwitch = withStyles({
            switchBase: {
              color: grey[400],
              '&$checked': {
                color: blue[500],
              },
              '&$checked + $track': {
                backgroundColor: blue[500],
              },
            },
            checked: {},
            track: {},
          })(Switch);

        const favLocations = favouriteLocationList.map( (location,index) =>{
            return (<label class="fav-con"  key={index}>
                        {location.location_type}
                        <input type="radio" name="location" onChange={this.changeFavouriteRadio} 
                        value={index}/>
                        <span class="fav-radiomark"></span>
                    </label>)
        });
    
        const favLoctionLength = favLocations.length;

        const topDriversTable = topDriversList.map((driver,index) =>{
            return (
                <tr key={index}>
                    <td><label class="fav-div-con"><input type="radio" name="topDriver" 
                        onChange={this.changeTopDriverRadio} value={index}/>
                        <span class="fav-div-radiomark"></span>
                    </label></td>
                    <td><span className={classnames("stat-icon",driver.sameRoute ? "stat-green":"stat-red" )}></span>{ driver.driverName}</td>
                    <td>{mathRound(driver.productivity_score)}</td>
                    <td>{mathRound(driver.performance_score)}</td>
                    <td>{mathRound(driver.safety_score)}</td>
                    <td>{mathRound(driver.score)}</td>
                </tr>
            )
        })

        let showDriverIcon = false;
        if(this.state.startPoint_topDriver && this.state.destPoint_topDriver
            && this.state.tripStartDate && this.state.tripStartTime
            && this.state.tripEndDate && this.state.tripEndTime){
                showDriverIcon = true;
        }

        return (
            <div onClick={this.hideOptions}>
                {this.state.loader ?<div className="Pageloading"> <img className="loadimg" src="/images/loader_1.gif" alt="iAlert" /></div> :''}
                {this.state.editTripInd ?
                    <div className="edit-box col-lg-12">
                        <div className="createtripcontainer row">
                            <div className="back_fa">
                                <Link onClick={this.passingVal} to="/SarathyShowTrip">
                                    <img src="/images/TripList/back.png" alt=""></img>
                                </Link>
                            </div>
                            <p className="p-3">Trip Status: <span className="statusColor">{tripstatus}</span></p>
                            <div className="button-grp">
                                <div className="btn">
                                    <input type="button" disabled={tripstatus !== "CREATED TRIP" && tripstatus !== "ACCEPTED"} className='trip-btn' value="Trip Start" onClick={this.startTrip}/>
                                </div>
                                <div className="btn">
                                    <input type="button" disabled={tripstatus !== "STARTED"} className='trip-btn' value="Trip End" onClick={this.endTrip}/>
                                </div>
                            </div>
                        </div>
                    </div> : null
                }
                <div className={classnames('container-wrapper', filterRegisteredVehicles.length ? null: 'bg-blur') }>
                    <div className="row">
                        <div className="col-lg-6 col-sm-12 mb-4">
                            <div className="tripmngHeaderDiv">
                                <div className="row mb-3">
                                    <p className="sarathi-header">Route Details</p>
                                    {
                                        favouriteLocationList.length > 0 && showFavouriteModel?
                                        <p onClick={this.openFavouriteModal} className="favriteHeader col-md-3">Favourite Locations</p>:null
                                    }
                                    
                                </div>

                                <div className="form-group reg-form-group col-lg-11 col-sm-11">
                                    <input type="text" className="form-control" id="vehicleRegNum"
                                            value={this.state.vehicleRegNum} 
                                            onClick={this.showVehicleRegNum}
                                            onChange={this.onChangeRegNum}
                                            onKeyUp={this.filterVehicleRegNum}
                                            onBlur={this.blurVehicleRegNumHandler}
                                            disabled={nonUpdateAble}
                                            maxLength="17"
                                            name="vehicleRegNum" autoComplete="off" required></input>
                                    <label className="form-control-placeholder" 
                                        htmlFor="vehicleRegNum">Registration Number
                                        <span className="red">*</span></label>
                                    <div className={!this.state.openVehicleRegNumber ? "dropdown-menu dropdown-menu-menu" : "dropdown-menu dropdown-menu-menu showfilterDropdown"}>
                                        {vehicleRegNos}
                                    </div>
                                </div>

                                <div className="form-group mb-2">
                                <input
                                    type="text"
                                    id='searchByLocationBoxes1'
                                    className="form-control custom-control pl-lg-4"
                                    placeholder="Enter Start Location"
                                    disabled={nonUpdateAble}
                                    onChange={this.onChangeSearchMap}
                                    name="startPoint" 
                                    value={this.state.startPoint}
                                    className="form-control custom-control pl-lg-4"
                                />
                                {/* <label className="point-label">Start Location 
                                        <span className="red">*</span></label> */}
                                    {/* <AutoCompleteContent name="startPoint" value={this.state.startPoint} 
                                        change={this.onChangeSearchMap} focus ={this.onFocusSearchMap}
                                        className="form-control custom-control pl-lg-4" 
                                        placeholder="Choose Start Location" disabled={nonUpdateAble}
                                        placeVals={this.handlePlaces} module="S"> */}
                                            <span className="fa fa-ellipsis-v fa-custom-ellipsis"></span>
                                            {
                                                this.state.startPoint !== "" && this.state.startPoint_places !== "" &&
                                                this.state.startPoint === this.state.startPoint_places.formatted_address?
                                                <abbr className="abbrcls" title={startPoint_addFavoriteInd ?"Remove From Favourites":"Save to Favourite Location"}>
                                                    <span onClick={this.onClickShowFavourite.bind(this,'startPoint',startPoint_addFavoriteInd)} 
                                                        className={classnames("fa fa-star fa-custom-heart",startPoint_addFavoriteInd ? 'fa-custom-heart-active': null)}>
                                                    </span>
                                                </abbr> : null
                                            }
                                    {/* </AutoCompleteContent> */}
                                </div>
                                
                                <div className="form-group">
                                    {/* <label className="point-label">End Location 
                                        <span className="red">*</span></label> */}
                                    {/* <AutoCompleteContent name="destPoint" value={this.state.destPoint} 
                                        change={this.onChangeSearchMap} focus ={this.onFocusSearchMap}
                                        className="form-control custom-control pl-lg-4"
                                        placeholder="Choose End Location"
                                        placeVals={this.handlePlaces} module="S"> */}
                                    <input
                                        type="text"
                                        id='searchByLocationBoxes2'
                                        className="form-control custom-control pl-lg-4"
                                        placeholder="Enter End Location"
                                        disabled={nonUpdateAble}
                                        onChange={this.onChangeSearchMap}
                                        name="destPoint" 
                                        value={this.state.destPoint}
                                        className="form-control custom-control pl-lg-4"
                                    />
                                            <span className="fa fa-ellipsis-v fa-custom-ellipsis"></span>
                                            {
                                                this.state.destPoint !== "" && this.state.destPoint_places !== "" &&
                                                this.state.destPoint === this.state.destPoint_places.formatted_address?
                                                <abbr className="abbrcls" title={destPoint_addFavoriteInd ?"Remove From Favourites":"Add to Favourites"}>
                                                    <span onClick={this.onClickShowFavourite.bind(this,'destPoint',destPoint_addFavoriteInd)} 
                                                        className={classnames("fa fa-star fa-custom-heart",destPoint_addFavoriteInd ? 'fa-custom-heart-active': null)}>
                                                    </span>
                                                </abbr> : null
                                            }
                                    {/* </AutoCompleteContent> */}
                                </div>

                                <div className="row">
                                    <div className="form-group col-lg-5 col-sm-5 mt-2">
                                        <input type="number" className="form-control"
                                            value={this.state.tripdistance} onChange={this.onChangeTripInfo}
                                            name="tripdistance" autoComplete="off" min="0" required></input>
                                        <label className="form-control-placeholder" htmlFor="tripdistance">
                                            Trip Distance (km)<span className="red">*</span></label>
                                    </div>
                                    <div className="form-group col-lg-5 col-sm-5 mt-2">
                                        <input type="number" className="form-control" id="targetKmpl"
                                            value={this.state.targetKmpl} onChange={this.onChangeTripInfo}
                                            name="targetKmpl" autoComplete="off" min="0" max="6" required></input>
                                        <label className="form-control-placeholder" htmlFor="targetKmpl">
                                            Target KMPL<span className="red">*</span></label>
                                    </div>
                                </div>
                            </div>

                            <div className="tripmngHeaderDiv mt-2">
                                <p className="tripmngHeader mb-2">Trip Schedule</p>
                                <div className="row">
                                    <CustomDatePicker
                                        value={this.state.tripStartDate}
                                        change={date => this.handleChange(date, "tripStartDate")}
                                        datepicker={true}
                                        showTimeSelect={false}
                                        showTimeSelectOnly={false}
                                        minDate={this.state.startMindate}
                                        maxDate={this.state.startMaxdate}
                                        timeFormat="HH:mm"
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText="Start Date"
                                        showlabel={true}
                                        blur={this.getTimeDifference}
                                        disabled={nonUpdateAble}
                                        id="startdate"
                                        spanClick={this.openCalender.bind(this,'startdate')}
                                    />
                                    <CustomDatePicker
                                        value={this.state.tripEndDate}
                                        change={date => this.handleChange(date, "tripEndDate")}
                                        datepicker={true}
                                        showTimeSelect={false}
                                        showTimeSelectOnly={false}
                                        minDate={this.state.endMindate}
                                        maxDate={this.state.endMaxdate}
                                        excludeDates={this.state.excludeEndDateArr}
                                        timeFormat="HH:mm"
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText="End Date"
                                        showlabel={true}
                                        blur={this.getTimeDifference}
                                        id="endate"
                                        spanClick={this.openCalender.bind(this,'endate')}
                                    />
                                    <CustomDatePicker
                                        value={this.state.tripStartTime}
                                        change={date => this.handleChange(date, "tripStartTime")}
                                        datepicker={false}
                                        showTimeSelect={true}
                                        showTimeSelectOnly={true}
                                        timeFormat="HH:mm"
                                        dateFormat="HH:mm"
                                        showlabel={true}
                                        placeholderText="Start Time"
                                        blur={this.getTimeDifference}
                                        disabled={nonUpdateAble}
                                        id="starttime"
                                        spanClick={this.openCalender.bind(this,'starttime')}
                                    />
                                    <CustomDatePicker
                                        value={this.state.tripEndTime}
                                        change={date => this.handleChange(date, "tripEndTime")}
                                        datepicker={false}
                                        showTimeSelect={true}
                                        showTimeSelectOnly={true}
                                        timeFormat="HH:mm"
                                        dateFormat="HH:mm"
                                        placeholderText="End Time"
                                        showlabel={true}
                                        blur={this.getTimeDifference}
                                        id="endtime"
                                        spanClick={this.openCalender.bind(this,'endtime')}
                                    />
                                    <div className="form-group col-lg-4 col-sm-4 mt-4">
                                        <input type="text" className="form-control"
                                            value={this.state.totalTripTime}
                                            name="totalTripTime" disabled="disabled"></input>
                                        <label className="form-control-placeholder" htmlFor="totalTripTime">
                                            Total Trip Time (HH:MM)<span className="red">*</span></label>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-2">
                                <p className="tripmngHeader">Trip Details</p>
                                
                                <div className="row trip-dtls">

                                    <div className="form-group col-lg-5 col-sm-5">
                                        <select name="assignDriver" className="form-control select-form-control"
                                            value={this.state.assignDriver} onChange={this.onChangeTripInfo}
                                            disabled={nonUpdateAble}>
                                            <option value="">--- Select Driver ---</option>
                                            {drivers.map(driver => <option key={driver.driver_id}
                                            value={driver.driver_id}>{driver.driver_name}</option>)}
                                        </select>
                                        <label className="form-control-placeholder" 
                                        htmlFor="assignDriver">Driver Name
                                        <span className="red">*</span></label>
                                        {
                                            tripstatus !== "STARTED" && showDriverIcon?
                                            <abbr className="abbrcls" title="Click to select best driver">
                                                <span className="fa fa-address-book fa-custom-bars" onClick={this.showTopDrivers}></span> 
                                            </abbr>: null
                                        }
                                    </div>
                                    <div className="form-group col-lg-5 col-sm-5">
                                        <select name="appSegment" className="form-control select-form-control"
                                            value={this.state.appSegment} onChange={this.onChangeTripInfo}>
                                            <option value="">--- Select---</option>
                                            {appSegments.map((appSeg,index) => <option key={index}
                                            value={appSeg}>{appSeg}</option>)}
                                        </select>
                                        <label className="form-control-placeholder" 
                                        htmlFor="appSegment">Application Segment
                                        <span className="red">*</span></label>
                                    </div>
                                    
                                    <div className="form-group col-lg-5 col-sm-5">
                                        <select name="payload" className="form-control select-form-control"
                                            value={this.state.payload} onChange={this.onChangeTripInfo}>
                                            <option value="">--- Select---</option>
                                            {payloads.map((load,index) => <option key={index}
                                            value={load}>{load}</option>)}
                                        </select>
                                        {/* <input type="text" className="form-control" id="payload"
                                            value={this.state.payload} onChange={this.onChangeTripInfo}
                                            onKeyDown={this.onlyNumbers}
                                            name="payload" autoComplete="off" maxLength="5" required></input> */}
                                        <label className="form-control-placeholder" htmlFor="payload">
                                            Enter Payload (Tons)<span className="red">*</span></label>
                                    </div>
                                    <div className="form-group col-lg-5 col-sm-5">
                                        <input type="text" className="form-control" id="stoppageLimit"
                                                value={this.state.stoppageLimit} 
                                                onClick={this.showStoppageLimit}
                                                onChange={this.onChangeStoppageLimit}
                                                onKeyUp={this.filterStoppageLimit}
                                                onKeyDown={this.onlyNumbers}
                                                maxLength="5"
                                                name="stoppageLimit" autoComplete="off" required></input>
                                        <label className="form-control-placeholder" 
                                            htmlFor="stoppageLimit">Over Stoppage Limit
                                            <span className="red">*</span></label>
                                        <div className={!this.state.openStoppageLimit ? "dropdown-menu dropdown-menu-menu" : "dropdown-menu dropdown-menu-menu showfilterDropdown"}>
                                            {this.state.stoppageLimits}
                                        </div>
                                    </div>
                                    {/* <div className="form-group col-lg-5 col-sm-5">
                                        <select name="stoppageLimit" className="form-control"
                                            value={this.state.stoppageLimit} onChange={this.onChangeTripInfo}>
                                            <option value=""></option>
                                            {stoppageLimits.map((limit,index) => <option key={index}
                                            value={limit.value}>{limit.label}</option>)}
                                        </select>
                                        <label className="form-control-placeholder" 
                                            htmlFor="stoppageLimit">Over Stoppage Limit
                                            <span className="red">*</span></label>
                                    </div> */}
                                    <div className="form-group col-lg-3 col-sm-3">
                                        <input type="number" className="form-control" id="productivity"
                                            value={this.state.productivity} onChange={this.changeweightage}
                                            name="productivity" autoComplete="off" min="0" required></input>
                                        <label className="form-control-placeholder" htmlFor="productivity">
                                            Productivity<span className="red">*</span></label>
                                    </div>
                                    <div className="form-group col-lg-3 col-sm-3 mlf-35">
                                        <input type="number" className="form-control" id="performance"
                                            value={this.state.performance} onChange={this.changeweightage}
                                            name="performance" autoComplete="off" min="0" required></input>
                                        <label className="form-control-placeholder" htmlFor="performance">
                                            Performance<span className="red">*</span></label>
                                    </div>
                                    <div className="form-group col-lg-3 col-sm-3 mlf-35">
                                        <input type="number" className="form-control"
                                            value={this.state.safety} onChange={this.onChangeTripInfo}
                                            name="safety" autoComplete="off" disabled="disabled" required></input>
                                        <label className="form-control-placeholder" htmlFor="safety">
                                            Safety<span className="red">*</span></label>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="flex">
                                    <div className="flex-header">
                                        <p className="tripmngHeader">Night Driving</p>
                                    </div>
                                    <div className="col-lg-3">
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <BlueSwitch
                                                        checked={nightDriving}
                                                        onChange={this.changeTab.bind(this,'nightDriving')}
                                                    />
                                                }
                                            />
                                        </FormGroup>
                                    </div>
                                </div>
                                {
                                    nightDriving ?
                                    <div className="datepickerLabel row">
                                        <CustomDatePicker
                                            value={this.state.nightDrivStartDate}
                                            change={date => this.handleChange(date, "nightDrivStartDate")}
                                            datepicker={false}
                                            showTimeSelect={true}
                                            showTimeSelectOnly={true}
                                            timeFormat="HH:mm"
                                            dateFormat="HH:mm"
                                            placeholderText="Start Time"
                                            blur={this.getNightTimeDifference}
                                        />
                                        <CustomDatePicker
                                            value={this.state.nightDrivEndDate}
                                            change={date => this.handleChange(date, "nightDrivEndDate")}
                                            datepicker={false}
                                            showTimeSelect={true}
                                            showTimeSelectOnly={true}
                                            timeFormat="HH:mm"
                                            dateFormat="HH:mm"
                                            placeholderText="End Time"
                                            blur={this.getNightTimeDifference}
                                        />
                                    </div> : null
                                }
                            </div>
                            <div>
                                <div className="flex">
                                    <div className="flex-header">
                                        <p className="tripmngHeader">Continuous Driving</p>
                                    </div>
                                    <div className="col-lg-3">
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <BlueSwitch
                                                        checked={contDriving}
                                                        onChange={this.changeTab.bind(this,'contDriving')}
                                                    />
                                                }
                                            />
                                        </FormGroup>
                                    </div>
                                </div>
                                {
                                    contDriving ?
                                    <div className="row">
                                        <div className="form-group col-lg-5 col-sm-5 mt-2">
                                            <input type="number" className="form-control"
                                                value={this.state.continuousDrivingHours} onChange={this.onChangeTripInfo}
                                                name="continuousDrivingHours" autoComplete="off" min="0" required></input>
                                            <label className="form-control-placeholder" htmlFor="continuousDrivingHours">
                                            Continuous Driving Hours<span className="red">*</span></label>
                                        </div>
                                    </div> : null
                                }
                            </div>
                            
                            {
                                this.state.editTripInd ?
                                    <div className="row">
                                        {
                                            (tripstatus !== "COMPLETED") ?
                                            <div className="btn col-md-3">
                                                <input type="button" id="updateId" className='trip-btn' value="Update" onClick={this.saveTripHandler}/>
                                            </div> : null
                                        } 
                                        {
                                            (tripstatus !== "STARTED" && tripstatus !== "CANCELLED" && tripstatus !== "COMPLETED") ?
                                            <div className="btn col-md-3">
                                                <input type="button" className='trip-btn' value="Cancel" onClick={this.cancelHandler}/>
                                            </div> : null
                                        }
                                        {
                                            (tripstatus === "COMPLETED" || tripstatus === "REJECTED" ||  tripstatus === "CANCELLED") ?
                                            <div className="btn col-md-3">
                                                <input type="button" className='trip-btn' value="Delete" onClick={this.deleteHandler}/>
                                            </div> : null
                                        }
                                    </div>
                                    :
                                <div className="row">
                                    <div className="btn col-md-3">
                                    </div>                                    
                                    <div className="btn col-md-3">
                                        <input type="button" className='trip-btn' id="saveId" value="Save" onClick={this.saveTripHandler}/>
                                    </div>
                                    <div className="btn col-md-3">
                                        <input type="button" className='trip-btn' value="Reset" onClick={this.resetHandler}/>
                                    </div>
                                </div>
                            }
                            
                        </div>
                        <div className="col-lg-6 col-sm-12">
                            <CreateMap center={this.state.location} routeIndex={this.state.routeIndex}
                                handleRoutes={this.handleRoutes} zoom={this.state.zoom} height='100vh'
                                showMarker={this.state.showMarker} markerPosition={this.state.markerPosition}
                                route_directions={this.state.route_directions} click={this.handleClickedMap}
                            />
                        </div>
                    </div>
                </div>
                <AlertBox show={showAlertBox} showMessage={showMessage} 
                    click={this.hideModal} messageType={messageType}></AlertBox>
                
                {/* Favourite Locations POPUP starts here*/}

                <div className={classnames("fav-modal", openFavouriteInd ? "fav-active-modal" : null)}>
                    <div className="fav-modal-content">
                        <div className="fav-modal-header">
                            <span className="fav-close" onClick={this.closeFavouriteModal}>&times;</span>
                            <div className="row">
                                <p className="fav-heading">
                                    Favourite Locations { favLoctionLength > 0 ? <span>( {favLoctionLength} Records)</span>: null}
                                </p>
                            </div>
                        </div>
                        <div className="fav-modal-body">
                            <div className="location-div">
                                {
                                    favLocations.length > 0 ?
                                        favLocations : 
                                        <div className="errorDiv">
                                            <p className="errorMsg">
                                                No Data Available
                                            </p>
                                        </div>
                                }
                            </div>
                        </div>
                        <div className="fav-modal-footer">
                            <div className="row">
                                <div className="btn col-md-4">
                                </div>
                                <div className="btn col-md-2">
                                    <input type="button" className='trip-btn' value="Select"
                                    disabled={this.state.disabledSelectBtn}
                                    onClick={this.selectLocation}/>
                                </div>
                                <div className="btn col-md-2">
                                    <input type="button" className='trip-btn' value="Cancel" 
                                    onClick={this.closeFavouriteModal}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Open Add Favourite Model */}

                <div className={classnames("fav-modal", showAddFavourite ? "fav-active-modal" : null)}>
                    <div className="fav-modal-content fav-add-model-content">
                        <div className="fav-modal-header">
                            <span className="fav-close" onClick={this.closeAddFavourite}>&times;</span>
                            <div className="row">
                                <p className="fav-heading">
                                    Add To Favourites
                                </p>
                            </div>
                        </div>
                        <div className="fav-modal-body">
                            <div className="row">
                                <div className="form-group col-lg-8 mt-2">
                                    <input type="text" className={classnames("form-control",errorfavLoc ? "form-disabled-control" : null)}
                                        value={this.state.favLocName} onChange={this.onChangeTripInfo}
                                        name="favLocName" autoComplete="off" maxLength="20" required></input>
                                    <label className="form-control-placeholder" htmlFor="favLocName">
                                        Location Name<span className="red">*</span></label>
                                </div>
                                <div className="btn col-lg-2">
                                    <input type="button" className='trip-btn trip-small-btn' value="Save"
                                    onClick={this.onClickFavoriteHandler}/>
                                </div>
                            </div>
                        </div>
                        <div className="fav-modal-footer">
                            
                        </div>
                    </div>
                </div>

                {/* Show Confirm Favourite  */}

                <div className={classnames("fav-modal", confirmAddFavourite ? "fav-active-modal" : null)}>
                    <div className="fav-modal-content fav-add-model-content">
                        <div className="fav-modal-header">
                            <span className="fav-close" onClick={this.closeConfirmFavourite}>&times;</span>
                            <div className="row">
                                <p className="fav-heading">
                                    Confirmation
                                </p>
                            </div>
                        </div>
                        <div className="fav-modal-body">
                            <p>Are you sure you want to remove the Favourite Location?</p>
                        </div>
                        <div className="fav-modal-footer">
                            <div className="row">
                                <div className="btn col-lg-3">
                                </div>
                                <div className="btn col-lg-3">
                                    <input type="button" className='trip-btn trip-small-btn' value="Remove"
                                    onClick={this.onClickFavoriteHandler}/>
                                </div>
                                <div className="btn col-lg-3">
                                    <input type="button" className='trip-btn trip-small-btn' value="Cancel"
                                    onClick={this.closeConfirmFavourite}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Top Drivers POPUP starts here*/}

                <div className={classnames("fav-modal", showTopDriversPopup ? "fav-active-modal" : null)}>
                    <div className="fav-modal-content">
                        <div className="fav-modal-header">
                            <span className="fav-close" onClick={this.closeTopDrivers}>&times;</span>
                            <div className="row">
                                <p className="fav-heading">
                                    Select Best driver
                                </p>
                                <div className="col-sm-1">
                                </div>
                                <div className="col-sm-3 mt-2">
                                    <p><span className="stat-icon stat-green"></span>Known/Familiar Route</p>
                                </div>
                                <div className="col-sm-3 mt-2">
                                    <p><span className="stat-icon stat-red"></span>Unknown Route</p>
                                </div>
                            </div>
                        </div>
                        <div className="fav-modal-body">
                            <div className="location-div">
                                <table className="top-table">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Driver Name</th>
                                            <th>Productivity</th>
                                            <th>Performance</th>
                                            <th>Safety</th>
                                            <th>Total Score</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            topDriversTable.length > 0 ?
                                                topDriversTable : 
                                                <tr>
                                                    <td colSpan="6">
                                                    <div className="errorDiv p-4">
                                                        <p className="errorMsg">
                                                            No Data Available
                                                        </p>
                                                    </div>
                                                    </td>
                                                </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="fav-modal-footer">
                            <div className="row">
                                <div className="btn col-md-4">
                                </div>
                                <div className="btn col-md-2">
                                    <input type="button" className='trip-btn' value="Select"
                                    disabled={disabledDriverSelectBtn}
                                    onClick={this.selectTopDriver}/>
                                </div>
                                <div className="btn col-md-2">
                                    <input type="button" className='trip-btn' value="Cancel" 
                                    onClick={this.closeTopDrivers}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

const mapStateToprops = state => {
    return {
        ctr:state.userdata.id,
        allowed:state.sarathyReducer.allowed
        //ctr: { userid: 655 }
    };
}
const mapdispatchToprops = dispatch => {
    return {
        quicklinks:(data) => dispatch({type:'QUICKLINK', value:data}),
        navigateToHome:(data) => dispatch({type:'HOMEPAGE', value:data}),
        changeAllowed:(data) => dispatch({type:'ALLOWED', value:data})
    };
}
export default connect(mapStateToprops,mapdispatchToprops)(SarathyCreateTrip);