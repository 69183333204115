import React from 'react';
import { getcurrentTime } from '../../Util/DateUtils'
class ActionProvider {
    constructor(
     createChatBotMessage,
     setStateFunc,
     createClientMessage,
     stateRef,
     createCustomMessage,
     ...rest
   ) {
     this.createChatBotMessage = createChatBotMessage;
     this.setState = setStateFunc;
     this.createClientMessage = createClientMessage;
     this.stateRef = stateRef;
     this.createCustomMessage = createCustomMessage;
   }

  helloWorldHandler = () => {
    const message = this.createChatBotMessage("Hello, I'm here")
    this.setChatbotMessage(message)
  }

  setChatbotMessage = (message) => {
    this.setState(state => ({ ...state, messages: [...state.messages, message] }))
  }

  replyMsg = (msg) => {
    return(
      <>
        <span>{msg}</span>
        <span className='time-span'>{getcurrentTime('h:mm A')}</span>
      </>
    )
  }

  VinHandler = (question, title, api, column, modelValue = '', parent_title='') => {
    const message = this.createChatBotMessage(this.replyMsg("Select Reg.No / VIN") , {
      widget: "vin_number",
      payload: { question: question, title: title, api: api, column: column, parent_title:parent_title},
    })
    this.setChatbotMessage(message)
  }

  DateHandler = (question, title, api, column, modelValue = '', parent_title = '') => {
    const message = this.createChatBotMessage(this.replyMsg('Select any 7 consecutive days from past 90 days') , {
      widget: "date",
      payload: { question: question, title: title, api: api, column: column, model: modelValue, parent_title: parent_title},
    })
    this.setChatbotMessage(message)
  }

  ModelHandler = (question, title, api, column, modelValue = '', parent_title='') => {
    const message = this.createChatBotMessage(this.replyMsg('Select model'), {
      widget: "model",
      payload: { question: question, title: title, api: api, column: column, parent_title: parent_title},
    })
    this.setChatbotMessage(message)
  }

  resultHandler = (props, response, startDate, endDate) => {
    let txt;
    if(props.payload.api === 'f_report'){
      txt = this.replyMsg('Top 3 & Bottom 3 vehicles of '+props.payload.title)
    }else if(props.payload.api === 'report' || props.payload.api === 'safetyalerts'){
      txt = this.replyMsg(props.payload.title + ' Details')
    }else if(props.payload.api === 'ownVehicleList'){
      txt = this.replyMsg('Location details')
    }

    const message = this.createChatBotMessage(txt, {
      widget: "result",
      payload: { question: props.payload.question, title: props.payload.title, data: response, api: props.payload.api, column: props.payload.column, startDate: startDate, endDate: endDate, model: props.payload.model},
    })
    this.setChatbotMessage(message)
  }

  resultHandler2 = (props, response, startDate, endDate, title, parent_title) => {
    
    let txt;
    if(props.api === 'f_report'){
      txt = this.replyMsg('Top 3 & Bottom 3 vehicles of '+props.title)
    }else if(props.api === 'report' || props.api === 'safetyalerts'){
      txt = this.replyMsg(props.title + ' Details')
    }else if(props.api === 'ownVehicleList'){
      txt = this.replyMsg('Location details')
    }


    const message = this.createChatBotMessage(txt, {
      widget: "result",
      payload: { question: props.question, title: props.title, data: response, api: props.api, column: props.column, startDate: startDate, endDate: endDate, model: props.model, type: 'direct'},
    })
    this.setChatbotMessage(message)
  }

  titleHandler = (props, title, parent_title='') => {
    const message = this.createClientMessage(this.replyMsg(parent_title +' - '+title), {
      widget: "title"
    })
    this.setChatbotMessage(message)
  }

  loaderHandler = (props) => {
    const message = this.createChatBotMessage('', {
      withAvatar: false,
      widget: "loader"
    })
    this.setChatbotMessage(message)
  }

  QuestionHandler = (props) => {
    const message = this.createChatBotMessage("Select Queries", {
      widget: "questions"
    })
    this.setChatbotMessage(message)
  }

  inputHandler = (props, input) => {
    const message = this.createClientMessage(this.replyMsg(input), {
      widget: "inputTxt"
    })
    this.setChatbotMessage(message)
  }

  ButtonsHandler = (props) => {
    const message = this.createChatBotMessage(this.replyMsg("Select any one of following option"), {
      widget: "buttons",
      // withAvatar: false,
    })
    this.setChatbotMessage(message)
  }

  MenuQuestions = (props, filterData, key, title) => {
    const message = this.createChatBotMessage(this.replyMsg("Select any one of query of "+title), {
      widget: "menu_questions",
      payload: { filterData: filterData, key: key, parent_title: title},
    })
    this.setChatbotMessage(message)
  }

  SubQuestionHandler = (filter_data, title, api, column) => {
    const message = this.createChatBotMessage("Select querie for " + title +' ', {
      widget: "sub_questions",
      payload: { sub_question: filter_data, title: title, api: api, column: column},
    })
    this.setChatbotMessage(message)
  }

 }
 
 export default ActionProvider;