import React, { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { exceldatetimestampformatter } from '../../../../Util'
import { convertDateFormat, ddmmyyyyhhmmssA } from '../../../../Util/DateUtils'
import { connect } from 'react-redux'
import { mapInfoChange } from '../../../../store/actions/action'
import StaticMap from '../../../Dashboard/StaticMap'
const CurrentLocation = (props) => {
  const dispatch = useDispatch()
  const { data, mapInfoChange } = props

  const getTraceData = (data) => {
    let traceInfo = []
    let val = {}
    const { latitude, longitude, location, localTimeStamp, status, heading } =
      data.currentInfo || {}
    val['lat'] = latitude
    val['lng'] = longitude
    val['location'] = location
    val['regn_number'] = data.regnNumber
    val['fleetTime'] = convertDateFormat(localTimeStamp, ddmmyyyyhhmmssA)
    val['status'] = status
    val['heading'] = heading
    traceInfo.push(val)
    return traceInfo
  }

  const traceInfo = useMemo(() => getTraceData(data), [data])
  const callMap = (vehicleInfo) => {
    // dispatch(mapInfoChange(vehicleInfo))
  }
  const getVehicleIcon = (vehicleDetails) => {
    const { status, heading } = vehicleDetails || {}
    const vehStatus = status || 'STOPPED'

    let adjustedHeading = heading
    if (adjustedHeading < 0) {
      adjustedHeading += 360
    }

    const angle = Math.floor((adjustedHeading + 22.5) / 45)
    const iconsVal = `/images/Trace/Map_screen/${vehStatus}/0${angle}.png`
    return iconsVal
  }
    let position= {lat:data?.currentInfo?.latitude, lng: data?.currentInfo?.longitude}

  const title = `Reg No : ${traceInfo.regn_number}\nLocation : ${traceInfo.location}\nTimeStamp : ${traceInfo.fleetTime}`

  return (
    <div>
      {data.currentInfo ? (
        <>
          <table className="table table-bordered">
            <tr>
              <td>Status</td>
              <td>
                {data?.currentInfo?.status}
                {data?.currentInfo?.status === 'RUNNING' ? (', ' + Math.round(data?.currentInfo?.gpsSpeed) + ' kmph') : data?.currentInfo?.status === 'IDLING' ? (', Engine speed -' + Math.round(data?.currentInfo?.engineSpeed) + ' rpm') : ''}
              </td>
              
            </tr>
            <tr>
              <td>Last Reported</td>
              <td>
                {exceldatetimestampformatter(data.currentInfo.localTimeStamp)}
              </td>
            </tr>
            <tr>
              <td>Location</td>
              {/* <td><a href="#!" className="hyperLink" onClick={() => openGoogleMaps(data?.currentInfo?.latitude, data?.currentInfo?.longitude)}><i class="fa fa-map-marker" aria-hidden="true"></i>&nbsp;<u>{data.currentInfo.location !== null ? data.currentInfo.location : '-'}</u></a></td> */}
              <td>
                  {data.currentInfo.location !== null
                    ? data.currentInfo.location
                    : '-'}
              </td>
            </tr>
          </table>
          {/* <ChatBotMapComponent
            title={title}
            icon={icon}
            zoom={zoom}
            center={center}
            height="70vh"
            routeIndex={0}
            markerPosition={position}
            showDirection={false}
            showMarker={true}
            visible={true}
            traceData={traceInfo}
          /> */}
          <StaticMap position={position} />
        </>
      ) : (
        <span>No data found..</span>
      )}
    </div>
  )
}
export default connect(null, { mapInfoChange })(CurrentLocation)
