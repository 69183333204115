import React, { Component } from "react";
import moment from 'moment';
import {diffTimeCalculator} from '../../Util/commonConverter';
import { exhaustCalculation, showExhaust } from "../../Util/VehicleDashUtils";
import { formatValue } from "../NewListView/Utils";

class TableListView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data:this.props.todo,
            rowIndex:this.props.index,
        };
}

componentDidUpdate(prevProps) {
  if (prevProps.todo !== this.props.todo || prevProps.index !== this.props.index) {
      this.setState({
          data: this.props.todo,
          rowIndex: this.props.index,
      });
  }
}
  render() {
      const todo = this.state.data;
      var currentInfo = todo.currentInfo;
      let status;
      let battPotential;
      if(currentInfo !==undefined){
        status = currentInfo.status
        battPotential = currentInfo.battPotential
      }
      else{
        status = todo.status
        battPotential = todo.battPotential
      }
      var dropDownValue = this.props.gmapsState.dropdownValue;
      if(dropDownValue != undefined && dropDownValue === "myTerritory"){
         currentInfo = todo;
      }
      let exhaust_temp = 'N/A';
      if(currentInfo !==undefined){
        if(showExhaust(todo.isBS6)){
          exhaust_temp = exhaustCalculation(currentInfo);
          // if(currentInfo.status == "RUNNING" || currentInfo.status == "IDLING"){
          //   exhaust_temp = currentInfo.aft1_exhaust_temp_1>0?Math.round(currentInfo.aft1_exhaust_temp_1):0;
          // }else{
          //   exhaust_temp = "-";
          // }
        }
      }
    return (
        (currentInfo != undefined ?
        <tr key={this.state.rowIndex} className={todo.isBS6 === 2? "linefortable hightlight_cng" : "linefortable"}>
        <td
          height="65"
          data-toggle="tooltip"
          data-placement="top"
          onClick={(e) => {
            this.props.handleClick(todo,true);
          }}
          title={todo.regnNumber}
        >
          {todo.regnNumber.length > 16
            ? todo.regnNumber.replace(
              todo.regnNumber.substring(2, todo.regnNumber.length - 6),
              "..."
            )
            : todo.regnNumber}{" "}
          <br/>{" "}
          <span className="listview_severity">{this.props.getSeverity(todo,dropDownValue)}</span>{" "}
        </td>
        <td
          className="listview_status_alert_column listview_map_icon"
          menuclickid="7,110,0"
        >
          { currentInfo != undefined && currentInfo.status === "RUNNING" || currentInfo != undefined &&
            currentInfo.status === "IDLING" ? (
            <span
              menuclickid="7,110,1"
              className={currentInfo.status === 'IDLING'?"idling_on_status_icon":"on_status_icon"}
              style={{ display: "inline-block" }}
            >
              ON
            </span>
          ) : (
            <span
              menuclickid="7,110,0"
              className="off_status_icon"
              style={{ display: "inline-block" }}
            >
              OFF
            </span>
          )}{" "}
          <span
            menuclickid="7,110,0"
            style={{ display: "inline-block", verticalAlign: "middle" }}
          >
            {currentInfo != undefined && currentInfo.status === "DISCONNECTED" ? (
              <u menuclickid="7,110,0">{currentInfo != undefined && currentInfo.status}</u>
            ) : (
              currentInfo.status
            )}{" "}
            <br/>{" "}
            <span menuclickid="7,110,0" className="listview_duration">
              {this.props.gmapsState.column.duration === true
                ? dropDownValue != "myTerritory"?currentInfo.statusAge:diffTimeCalculator(currentInfo.localTimeStamp)
                : ""}
            </span>{" "}
          </span><br/>
          <span menuclickid="7,110,0" className="" style={{color:"#6b2b27",fontSize:"11px"}}>{status === "DISCONNECTED" && battPotential === 0.0 || 0 ? "Physical check required at dealership for Battery / Fuse/ connection":''}</span>
        </td>
        <td className="listview_address_loc_column">
          {currentInfo != undefined && currentInfo.location}
        </td>
        <td>
          {moment(currentInfo != undefined && currentInfo.localTimeStamp).format(
            "DD-MM-YYYY h:mm:ss A"
          )}
        </td>{" "}
        {/*MMM DD, YYYY, h:mm:ss A*/}
        <td
          className={
            this.props.gmapsState.column.vehicle_odometer === true
              ? "listview_show"
              : "listview_hide"
          }
        >
          {this.props.getGpsSpeed(todo,dropDownValue)}
        </td>
        <td
          className={
            this.props.gmapsState.column.gps_speed === true
              ? "listview_show"
              : "listview_hide"
          }
        >
          {Math.round(currentInfo != undefined && currentInfo.gpsSpeed)}
        </td>
        <td
          className={
            this.props.gmapsState.column.engine_speed === true
              ? "listview_show"
              : "listview_hide"
          }
        >
          {Math.round(currentInfo != undefined && currentInfo.engineSpeed) || 0}
        </td>
        <td
          className={
            this.props.gmapsState.column.fuel_level === true
              ? "listview_show"
              : "listview_hide"
          }
        >
          {formatValue(currentInfo !== undefined && currentInfo.fuelLevel) || 0}
        </td>
        {/* <td className={this.props.gmapsState.column.duration === true ? 'listview_show' : 'listview_hide'}>{currentInfo.statusAge}</td> */}
        <td
          className={
            this.props.gmapsState.column.air_pressure === true
              ? "listview_show"
              : "listview_hide"
          }
        >
          {this.props.getPressureValue(todo,dropDownValue)}
        </td>
        <td
          className={
            this.props.gmapsState.column.oil_pressure === true
              ? "listview_show"
              : "listview_hide"
          }
        >
          {this.props.getOilPressureValue(todo,dropDownValue)}
        </td>
        <td
          className={
            this.props.gmapsState.column.vehicle_battery_potentials === true
              ? "listview_show"
              : "listview_hide"
          }
        >
          {!isNaN(currentInfo?.battPotential) ? Math.max(parseFloat(currentInfo?.battPotential).toFixed(2), 0) : 0}
        </td>
        <td
          className={
            this.props.gmapsState.column.fuel_consum === true
              ? "listview_show"
              : "listview_hide"
          }
        >
          {formatValue(currentInfo !== undefined && currentInfo.fuelConsumption)}
        </td>
        <td
          className={
            this.props.gmapsState.column.altitude === true
              ? "listview_show"
              : "listview_hide"
          }
        >
          {currentInfo.altitude !== null || ""
            ? currentInfo.altitude != undefined? currentInfo.altitude.toFixed(2):"N/A"
            : "N/A"}
        </td>
        <td
          className={
            this.props.gmapsState.column.vin_number === true
              ? "listview_show"
              : "listview_hide"
          }
        >
          {todo.vinNumber}
        </td>
        <td
          className={
            this.props.gmapsState.column.obu_id === true
              ? "listview_show"
              : "listview_hide"
          }
        >
          {currentInfo != undefined && currentInfo.obuId}
        </td>
        <td
          className={
            this.props.gmapsState.column.vehicle_model === true
              ? "listview_show"
              : "listview_hide"
          }
        >
          {todo.vehicleModel}
        </td>
        <td
          className={
            this.props.gmapsState.column.vehicle_type === true
              ? "listview_show"
              : "listview_hide"
          }
        >
          {todo.vehicleType}
        </td>
        <td
          className={
            this.props.gmapsState.column.coolant_temp === true
              ? "listview_show"
              : "listview_hide"
          }
        >
          {currentInfo.status == "RUNNING" || currentInfo.status == "IDLING"?currentInfo.eng_coolant_temp:'-'}
        </td>
        <td
          className={
            this.props.gmapsState.column.sub_valid_from === true
              ? "listview_show"
              : "listview_hide"
          }
        >
          {moment(todo.validFrom).format("DD-MM-YYYY")}
        </td>
        <td
          className={
            this.props.gmapsState.column.sub_valid_to === true
              ? "listview_show"
              : "listview_hide"
          }
        >
          {moment(todo.validTo).format("DD-MM-YYYY")}
        </td>
        <td
          className={
            this.props.gmapsState.column.is_bs6 === true
              ? "listview_show"
              : "listview_hide"
          }
        >
          {this.props.getBS6Value(todo)}
        </td>
        <td
          className={
            this.props.gmapsState.column.catalyst_tank_level === true
              ? "listview_show"
              : "listview_hide"
          }
        >
          {this.props.getCatalystTanlLevelValue(todo,dropDownValue)}
        </td>
        <td
          className={
            this.props.gmapsState.column.adblue_tank_capacity === true
              ? "listview_show"
              : "listview_hide"
          }
        >
          {this.props.getFluidUsedValue(todo,dropDownValue)}
        </td>
        <td
          className={
            this.props.gmapsState.column.driver_name === true
              ? "listview_show"
              : "listview_hide"
          }
        >
          {todo.driverName ? todo.driverName : 'Default Driver'} <br />{" "}
          {todo.driverMobileNo ? todo.driverMobileNo : null}
        </td>
        <td
          className={
            this.props.gmapsState.column.engine_hours === true
              ? "listview_show"
              : "listview_hide"
          }
        >
          {currentInfo != undefined && currentInfo.engineHrs}{" "}
        </td>
        <td
          className={
            this.props.gmapsState.column.exhaust_temperature === true
            ? 'listview_show'
            : 'listview_hide'
            }
        >
         {exhaust_temp}
        </td>

        <td
          className={
            this.props.gmapsState.column.customer_name === true
              ? "listview_show"
              : "listview_hide"
          }
        >
          {todo.customerName ? todo.customerName : ''} <br />{" "}
          {todo.customerMobileNo ? todo.customerMobileNo : null}
        </td>
        <td
          style={{ padding: "0" }}
          onClick={(e) => {
            this.props.handleClick(todo,true);
          }}
        >
          <i
            className="fa fa-map-marker listview_map_icon"
            aria-hidden="true"
          ></i>
        </td>
      </tr>:null)
        );
    }
}

export default TableListView;