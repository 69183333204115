import React from "react";
import './VehicleSearchBar.scss'
const VehicleSearchBar = ({ searchQuery, setSearchQuery }) => {
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <div className="vehicle-search">
        <input
      type="text"
      placeholder="Search by Vehicle"
      value={searchQuery}
      onChange={handleSearch}
    />
    </div>
  );
};
export default VehicleSearchBar;
