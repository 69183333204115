import React from 'react'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import './LogoutBox.scss'

const LogoutBox = ({ click, show, showMessage, messageType }) => {
  return (
    <div className="logoutbox">
      <div className={classnames('modal', show ? 'active-modal' : null)}>
        <div
          className={classnames(
            'modal-content',
            messageType === 'r'
              ? 'modal-content-success'
              : 'modal-content-error'
          )}
        >
          <div className="logoutbox_body_div">
            <div className="logoutbox_textshow_div">
              <span className="logoutbox_textshow">{showMessage}</span>
            </div>
            <div className="logoutbox_btn_div">
              <Link>
                <button className="confirmbutton" onClick={click} type="button">
                  Yes
                </button>
              </Link>
              <button className="confirmbutton" onClick={click} type="button">
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LogoutBox
