import React from "react";
import { createChatBotMessage } from "react-chatbot-kit";
import Questions from "./Questions/Questions";
import SubQuestions from "./Questions/SubQuestions";
import VinNumber from "./VinNumber/VinNumber";
import DateSelect from './Date/DateSelect'
import ModelSelect from './Model/ModelSelect'
import ResultComp from "./Result/ResultComp";
import { questions } from "./questions"
import Loader from "./Loader/Loader";
import MyAvatar from './MyAvatar'
import CustomAvatar from './CustomAvatar'
import Buttons from "./Buttons/Buttons";
import MenuQuestions from "./Questions/MenuQuestions";
import Input from "./Input/Input";
import InputTxt from "./Input/InputTxt";
import Title from "./Title/Title";
import { getcurrentTime } from '../../Util/DateUtils'

const replyMsg = (msg) => {
  return(
    <>
      <span>{msg}</span>
      <span className='time-span'>{getcurrentTime('h:mm A')}</span>
    </>
  )
}

const config = {
    botName: '',
    initialMessages: [createChatBotMessage(replyMsg('Hello! Welcome to i-BOT')),
    createChatBotMessage(
      replyMsg("I'm happy to assist you! Please choose an option above"),
      {
        withAvatar: true,
        // delay: 1000,
        widget: "questions",
      }
    ),
    ],
    customMessages: {
      custom: (props) => <Questions {...props} />,
    },
    state:{
        questions: questions,
        sub_questions: [],
        vin_number: [],
        selected_ques : '',
        title: '',
        selected_vin : '',
        selected_model : '',
        startDate: '',
        endDate: '',
        vinData: [],
        loader: false,
        result: false,
        q_clicked: false,
        fleet_continue: false,
        yes_no_btn_clk: false,
        expanded: false,
        index: false,
        parent_title:'',
        parent_key:''
    },
    customComponents: { 
      botAvatar: (props) => <MyAvatar {...props} />,
      userAvatar: (props) => <CustomAvatar {...props} />,
    },
    customStyles: {
      // Overrides the chatbot message styles
      botMessageBox: {
        backgroundColor: "#376B7E",
      },
      // Overrides the chat button styles
      chatButton: {
        backgroundColor: "#5ccc9d",
      },
    },
    widgets: [
        {
          widgetName: 'questions',
          widgetFunc: (props) => <Questions {...props} />,
          mapStateToProps: ['questions'],
        },
        {
          widgetName: 'sub_questions',
          widgetFunc: (props) => <SubQuestions {...props} />,
          mapStateToProps: ['sub_questions'],
        },
        {
          widgetName: 'vin_number',
          widgetFunc: (props) => <VinNumber {...props} />,
          mapStateToProps: ['vin_number'],
        },
        {
          widgetName: 'date',
          widgetFunc: (props) => <DateSelect {...props} />,
          mapStateToProps: ['date'],
        },
        {
          widgetName: 'model',
          widgetFunc: (props) => <ModelSelect {...props} />,
          mapStateToProps: ['model'],
        },
        {
          widgetName: 'result',
          widgetFunc: (props) => <ResultComp {...props} />,
          mapStateToProps: ['result'],
        },
        {
          widgetName: 'loader',
          widgetFunc: (props) => <Loader {...props} />,
          mapStateToProps: ['loader'],
        },
        {
          widgetName: 'buttons',
          widgetFunc: (props) => <Buttons {...props} />,
          mapStateToProps: ['loader'],
        },
        {
          widgetName: 'menu_questions',
          widgetFunc: (props) => <MenuQuestions {...props} />,
          mapStateToProps: ['loader'],
        },
        {
          widgetName: 'input',
          widgetFunc: (props) => <Input {...props} />,
          mapStateToProps: ['loader'],
        },
        {
          widgetName: 'inputTxt',
          widgetFunc: (props) => <InputTxt {...props} />,
          mapStateToProps: ['loader'],
        },
        {
          widgetName: 'title',
          widgetFunc: (props) => <Title {...props} />,
          mapStateToProps: ['loader'],
        },
    ]
}

export default config