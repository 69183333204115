exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".WelcomeModal_Container__317Lz {\r\n  position: fixed;\r\n  background: rgba(0, 0, 0, .2);\r\n  right: 0;\r\n  left: 0;\r\n  top: 0;\r\n  right: 0;\r\n  bottom: 0;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  z-index: 500;\r\n}\r\n\r\n/* .Container button {\r\n  background-color: #427be6;\r\n} */\r\n\r\n.WelcomeModal_Modal__3OyQn {\r\n  width: 80%;\r\n  height: 32rem;\r\n  background: #fff;\r\n  padding: 1rem;\r\n  display: flex;\r\n  flex-direction: column;\r\n  border-radius: 10px;\r\n  box-shadow: 0px 0px 10px #8a8686;\r\n}\r\n\r\n.WelcomeModal_Modal__3OyQn button:focus,\r\n.WelcomeModal_Modal__3OyQn button:active  {\r\n  outline: none;\r\n}\r\n\r\n.WelcomeModal_ModalHeader__3s8jd {\r\n  display: flex;\r\n  justify-content: space-between;\r\n}\r\n\r\n.WelcomeModal_ModalHeader__3s8jd h5 {\r\n  color: #0d459a;\r\n}\r\n\r\n.WelcomeModal_Tabs__1XHJh {\r\n  display: none;\r\n}\r\n\r\n.WelcomeModal_CloseIcon__1SaD1 {\r\n  height: 24px;\r\n}\r\n\r\n.WelcomeModal_CloseIcon__1SaD1:hover {\r\n  box-shadow: 0px 0px 10px #427be6;\r\n  transition: .1s ease-in;\r\n  cursor: pointer;\r\n}", ""]);

// Exports
exports.locals = {
	"Container": "WelcomeModal_Container__317Lz",
	"Modal": "WelcomeModal_Modal__3OyQn",
	"ModalHeader": "WelcomeModal_ModalHeader__3s8jd",
	"Tabs": "WelcomeModal_Tabs__1XHJh",
	"CloseIcon": "WelcomeModal_CloseIcon__1SaD1"
};