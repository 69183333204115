import React, { useState, useEffect } from 'react';

function ErrorBoundary(props) {
  const [error, setError] = useState(null);

  useEffect(() => {
    setError(null);
  }, []);

  if (error) {
    return (
      <div className="snap">
        Something Went Wrong
      </div>
    );
  } else {
    return props.children;
  }
}

export default ErrorBoundary;
