import React from 'react';

const Searchbox = (props) => {
    return (
        <div key={props.keyvalue} onClick={props.click} className="searchdiv">
            <div className="searchbox">{props.routeName} 
            </div>
        </div>
    );
}

export default Searchbox;