import React from 'react'
import { getVinData } from '../API/useApi'
import scroll from '../Scroll'
import Loader from '../Loader/Loader';
import { useSelector } from "react-redux";

const MenuQuestions = (props) => {
    const { setState } = props
    const [value, setValue] = React.useState(props.state.expanded);
    const ctr = useSelector((state) => state.userdata.id);

    const handleClick = (question, title, api, column) => {
        setValue(false);
        const { state, actionProvider } = props;
        const { yes_no_btn_clk } = state;

        if(title === 'Vehicle Running' || title === 'Idling' || title === 'Night Driving' || title === 'Neutral Driving' ){
            title = title + ' Duration '
        }
        props.actionProvider.titleHandler(props, title, props.payload.parent_title);
    
        const setStateAndAction = (question, title, api, column, handler) => {
            setState(state => ({ ...state, selected_ques: question, title, result: false, q_clicked: true, yes_no_btn_clk: false, fleet_continue: false }));
            handler(question, title, api, column, '', props.payload.parent_title);
        };
    
        if (yes_no_btn_clk) {
            if (api !== 'f_report' && api !== 'gearutilreport') {
                setStateAndAction(question, title, api, column, actionProvider.DateHandler);
            } else {
                if (question === 'top_bottom_fuel_cons' || question === 'top_bottom_mileage') {
                    setStateAndAction(question, title, api, column, actionProvider.ModelHandler);
                } else {
                    setStateAndAction(question, title, api, column, getVINData);
                }
            }
        } else {
            if ((api === 'gearutilreport' || api === 'f_report') && !(question === 'top_bottom_fuel_cons' || question === 'top_bottom_mileage')) {
                setStateAndAction(question, title, api, column, actionProvider.DateHandler);
            } else if (question === 'top_bottom_fuel_cons' || question === 'top_bottom_mileage') {
                setStateAndAction(question, title, api, column, actionProvider.ModelHandler);
            } else {
                setStateAndAction(question, title, api, column, actionProvider.VinHandler);
            }
        }
        // setState(state => ({ ...state, expanded: false}))
        scroll();
    };

    const getVINData = async (question, title, api, column, title_empty='', parent_title) => {
        let obj = {'question': question, 'title': title, 'api': api, 'column': column, 'model': undefined}
        try {
            setState(state => ({ ...state, loader: true, vinData: []}))
            const response = await getVinData(ctr, props, props.state.startDate, props.state.endDate, api);
            if(response.statuscode === 200 ){
                setState(state => ({ ...state, vinData: response.data, loader: false, result: true, q_clicked: false, yes_no_btn_clk: false, fleet_continue: true}))
                props.actionProvider.resultHandler2(obj, response.data, props.state.startDate, props.state.endDate, title, parent_title);

            }
            else{
                setState(state => ({ ...state, vinData: [], loader: false, result: true, q_clicked: false, yes_no_btn_clk: false, fleet_continue: true}))
                props.actionProvider.resultHandler2(obj, response.data, props.state.startDate, props.state.endDate, title, parent_title);
            }
        } catch (error) {
            console.error(error);
            setState(state => ({ ...state, vinData: [], loader: false, result: true, q_clicked: false, yes_no_btn_clk: false, fleet_continue: true}))
            props.actionProvider.resultHandler2(obj, [], props.state.startDate, props.state.endDate, title, parent_title);
        }
        scroll();
    };

    const renderQuestions = () => {

        if (props.payload.key === 'top_bottom_three_performance') {
            return (
                <div className='menu-question'>
                        {props.payload.filterData[0].questions
                        .filter(data => 
                            data.key !== 'top_bottom_runtime' && 
                            data.key !== 'top_bottom_idling' && 
                            data.key !== 'top_bottom_night_driving' && 
                            data.key !== 'top_bottom_neutral_driving')
                        .map((data, i) => (
                            <button key={i} className="option_IVDn-1" onClick={(e) => handleClick(data.key, data.title, data.api, data.column)}>
                            {data.title}
                            {data.column !== 'dis_cov' ? <span className='bot-astrick'>*</span> : ''}
                            </button>
                        ))}
                        <p className='duration-p'>Duration</p>
                        {props.payload.filterData[0].questions
                        .filter(data => 
                            data.key === 'top_bottom_runtime' ||
                            data.key === 'top_bottom_idling' ||
                            data.key === 'top_bottom_night_driving' ||
                            data.key === 'top_bottom_neutral_driving')
                        .map((data, i) => (
                            <button key={i} className="option_IVDn-1" onClick={(e) => handleClick(data.key, data.title, data.api, data.column)}>
                            {data.title}
                            {data.column !== 'dis_cov' ? <span className='bot-astrick'>*</span> : ''}
                            </button>
                        ))}
                        <p className='bot-astrick-note'>* Vehicles travelled for more than 100 kms in a given duration considered</p>
                </div>
            );
        } else {
            return (
                <div className='menu-question'>
                    {props.payload.filterData[0].questions.map((data, i) => (
                        <button key={i} class="option_IVDn-1" onClick={(e) => handleClick(data.key, data.title, data.api, data.column)}>{data.title}</button>
                    ))}
                </div>
            );
        }

    }

    var divs = document.querySelectorAll('.loading-chatbot');
    if(divs.length > 0){
        for (var i = 0; i < divs.length - 1; i++) {
            divs[i].style.display = 'none'; // Hide all except last div with class "loading-chatbot"
        }
    }

  return (
    <>
        <div class="options_L6yW">
            {renderQuestions()}
        </div>
    </>
  )
}

export default MenuQuestions