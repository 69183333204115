import scroll from './Scroll'
class MessageParser {
    constructor(actionProvider, state) {
      this.actionProvider = actionProvider;
      this.state = state;
    }
  
    parse(message) {
        const lowercase = message.toLowerCase();
        this.actionProvider.ButtonsHandler()
        scroll();
        // if(lowercase.includes('hello')){
        //     this.actionProvider.helloWorldHandler()
        // }

    }
  }
  
  export default MessageParser;