import { ACTION_EV_ENORM, GLOBAL_FILTER_DATA, MAPPED_SCREEN_DATA, MENU_API_DATA, MENU_DATA, MONTHLY_REPORT_DATA, RELEASE_NOTES } from "./constants";
const toolBar ={
    HOMEPAGE:null,
    show:false,
    globalSearch:null,
    qcdata:null,
    listviewshown:null,
    searchboxcnt:false,
    globalFilterData:null,
    searchupdatedata:{
        lat: 20.5937,
        lng: 78.9629
    }
 }
 const reducer = (state = toolBar,action) => {
     if(action.type === 'MENU'){
         return {
             ...state,
             show:action.value
         };
     }else if(action.type === 'SEARCH'){
         return{
            ...state,
            globalSearch:action.value
         }
     }else if(action.type === 'QUICKLINK'){
        return{
            ...state,
            qcdata:action.value
         }
     }else if(action.type === 'REFRESH'){
        return{
            ...state,
            refresh:action.value
         }
     }
     else if(action.type === ACTION_EV_ENORM){
        return{
            ...state,
            isEVEnormExits:action.value
         }
     } else if(action.type === GLOBAL_FILTER_DATA){
        return {
            ...state,
            globalFilterData: action.value
        };
     }
     else if(action.type === MENU_API_DATA){
        return{
            ...state,
            menuAPIData: action.value
         }
     } else if (action.type === RELEASE_NOTES) {
         return {
             ...state,
             releaseNotes: action.value
         }
     } else if (action.type === MONTHLY_REPORT_DATA) {
        return {
            ...state,
            monthReportAPIData: action.value
        }
     } else if (action.type === MAPPED_SCREEN_DATA) {
        return {
            ...state,
            mappedScreenAPIData: action.value
        }
     }
     else if(action.type === 'ONLYREFRESH'){
        return{
            ...state,
            onlyrefresh:action.value
         }
     }
     else if(action.type === 'MENULIST'){
        return{
            ...state,
            menulist:action.value
         }
     }else if(action.type === 'SEARCHBOXCNT'){
        return{
            ...state,
            searchboxcnt:action.value
         }
     }
     else if(action.type === 'searchupdatedata'){
        return{
            ...state,
            searchupdatedata:action.value
         }
     }
     else if(action.type === 'searchdealerpoint'){
        return{
            ...state,
            searchdealerpoint:action.value
         }
     }    
     else if(action.type === 'HOMEPAGE'){
        return{
            ...state,
            HOMEPAGE:action.value
         }
     } 
     else if(action.type === 'listviewshow'){
        return{
            ...state,
            listviewshown:action.value
         }
     }
     
     return {...state};
 };
 
 export default reducer;