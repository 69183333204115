import React from 'react';
import classnames from 'classnames';

const RouteBox = (props) => {
    return (
        <div key={props.index} onClick={props.click} 
            className={classnames("routebox",props.activeRouteName === props.routeName ? "routeboxActive" : null)}>
            <ul>
                <li>{props.routeName}</li>
                <li>{props.travellingTime}</li>
                <li>{props.distance} Km</li>
            </ul>
        </div>
    );
}

export default RouteBox;