import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import * as apilist from '../../apiList';
import { postData } from '../../Util';
import AlertBox from '../../Components/AlertBox/alertBox.js';
import ShowList from './ShowList';

const Configuration = ({ closeFunc, regList, alertType, listApi }) => {

    const ctr = useSelector(state => state.userdata.id);

    const [tabEnable, settabEnable] = useState(true);
    const [alertName, setalertName] = useState(alertType.length > 0 ? alertType[0].alert_type_id : null);
    const [limit, setlimit] = useState('');
    const [vin_no, setvin_no] = useState({});
    const [searchVin, setsearchVin] = useState('');
    const [FilterList, setFilterList] = useState([]);
    const [showMessage, setshowMessage] = useState('');
    const [showAlertBox, setshowAlertBox] = useState(false);
    const [messageType, setmessageType] = useState('e');
    const [UomName, setUomName] = useState('');
    const [selected_alert, setselected_alert] = useState([]);
    const [FilterByAlert, setFilterByAlert] = useState([]);
    const [searchVinList, setsearchVinList] = useState([]);
    const [FilterList2, setFilterList2] = useState([]);

    useEffect(() => {
        alertChange(alertName)
    }, [regList])

    const alertChange = (e) => {
        let value = (e !== null && typeof e === 'object') ? e.target.value : e
        let alert = alertType.filter(x => x.alert_type_id === parseInt(value));
        setalertName(value)
        setUomName(alert.length >0 ? alert[0].uom : '')
        setselected_alert(alert)
        let low_alert = alert.length >0 ? alert[0].alert_type_name.toLowerCase().replace(/ /g,"_") : ''
        setFilterByAlert(low_alert !== '' && value !== 'Select' && value !== null 
            ? (regList && regList[low_alert] ? regList[low_alert] : []) 
            : (regList && typeof regList === 'object' && regList.length === 0 
                ? [] 
                : regList['over_speed_alert'] || [])
        );
        setFilterList2((low_alert !=='' && value !=='Select' && value !==null) ? regList[low_alert] : (typeof regList ==='object' && regList.length === 0) ? [] : regList['over_speed_alert'])
        resetCheckbox();
    }

    const changeLimit = (e) => {
        let value = e.target.value.slice(0, 3);
        setlimit(value)
    }

    const checkboxChange = (e) => {
        const { value, checked } = e.target;
        let split = value.split(',')
        if(checked){
            setvin_no(prevData => ({
                ...prevData,
                [split[0]]: split[1]
            }));
        }
        else{
            setvin_no(prevData => {
                const { [split[0]]: _, ...newData } = prevData;
                return newData;
            });
        }
    }

    const saveConfig = () => {
        const check = validation();
        if(check === true){
            let tokens = ctr.token;
            let newObj = {};
            newObj.vehicle_list = vin_no;
            newObj.alert_type_id = alertName.toString();
            newObj.alert_value = parseInt(limit);
            const datas = postData(apilist.alertConfiguration, newObj, tokens);
            datas.then((dataVals) => {
                if (dataVals.statuscode === 200) {
                    openModel('Data saved successfully', "s")
                    resetFields();
                    listApi();
                } else if(dataVals.statuscode === 400) {
                    openModel(dataVals.error, "e")
                }
                else{
                    openModel("Failer", "e")
                }
            });
        }
        else{
            openModel(check, "e")
        }
    }

    const resetFields = () => {
        // setalertName('Select')
        setlimit('')
        setvin_no([])
        setsearchVin('')
        setUomName('')
        resetCheckbox();
    }

    const resetCheckbox = () => {
        var uncheck=document.getElementsByClassName('checkbox-config');
        for(var i=0;i<uncheck.length;i++)
        {
            if(uncheck[i].type === 'checkbox')
            {
                uncheck[i].checked=false;
            }
        }
        setvin_no([]);
        setsearchVin('');
    }

    const validation = () => {
        if(alertName === null || alertName === 'Select'){
            return 'Please select alert name';
        }
        if(limit === 0 || limit === '' || limit === null){
            return 'Please enter limit value';
        }
        if(limit < selected_alert[0].min_limit){
            return 'Minimum limit should be '+selected_alert[0].min_limit + ' '+ UomName;
        }
        if(limit > selected_alert[0].max_limit){
            return 'Maximum limit should be '+selected_alert[0].max_limit + ' '+ UomName;
        }
        if(vin_no.length <=0){
            return 'Please select vehicle';
        }
        return true
    }

    const searchChange = (e) => {
        setsearchVin(e.target.value)
        let reg_no = e.target.value;
        const filteredData = FilterByAlert.filter(data => (data.regn_number.toLowerCase().includes(reg_no.toLowerCase())) || (data.vin_number.toLowerCase().includes(reg_no.toLowerCase())) );
        setFilterList(filteredData)
    }

    const searchChangeList = (e) => {
        setsearchVinList(e.target.value)
        let reg_no = e.target.value;
        const filteredData = FilterByAlert.filter(data => (data.regn_number.toLowerCase().includes(reg_no.toLowerCase())) || (data.vin_number.toLowerCase().includes(reg_no.toLowerCase())) );
        setFilterList2(filteredData)
    }

    const openModel = (message, msgType) => {
        setshowAlertBox(true)
        setshowMessage(message)
        setmessageType(msgType)
    }

    const hideModal = () => {
        setshowAlertBox(false)
        setshowMessage('')
    };

    
    let firstHalf = []
    if(searchVin === ""){
        firstHalf = FilterByAlert
    }
    else{
        firstHalf = FilterList
    }
    
  return ( 
    <div className='alert-config-main-div'>
        <button className="close-button" onClick={() => closeFunc()}>X</button>
        <div className='config-header'>
            <span>Alert Customization</span>
        </div>
        <div className='row sub-header'>
            <div className={tabEnable ? 'active col-md-12 first-div' : 'col-md-12 first-div in-active'}>
                <span>Configure</span>
            </div>
        </div>

        <div className='d-flex margin-10'>
            <span className='text-label first-span'>Alert Name</span>
            <select className='height-10 first-input' value={alertName} onChange={(e) => {alertChange(e)}}>
                <option value={null}>Select</option>
                {alertType.map((data) => (
                    <option value={data.alert_type_id}>{data.alert_type_name}</option>
                ))}
            </select>
            <span className='text-label'>Limit</span>
            <input className='height-10 second-input' type='number' value={limit} maxLength={2} onChange={(e) => {changeLimit(e)}}></input>
            <span className='text-label'>{UomName}</span>
        </div>
 
        <div className='row margin-10 final-div'>
            <div className='col-md-12 search-vehicle'>
                <input className='height-10' type='text' value={searchVin} placeholder='Search By Vehicle' onChange={searchChange}></input>
            </div>
            {firstHalf?.length > 0 ?
                <>
                <div className='row col-md-12 header-div'>
                    <div className='col-md-6 header'>
                        <p>Reg No / VIN</p>
                    </div>
                    <div className='col-md-3 header'>
                        <p>Value</p>
                    </div>
                    <div className='col-md-3 header'>
                        <p>Status</p>
                    </div>
                </div>
                <div className='col-md-12 first-col row'>
                    {firstHalf.toReversed().map((data, index) => (
                        <>
                            <div className={index % 2 === 0 ? 'gray col-md-6' : 'white col-md-6'}>
                                <input className='checkbox-config' type="checkbox" id={index+'_'+data.regn_number} name="checkbox" value={data.obu_id +','+ data.vin_number} onChange={checkboxChange} disabled={data.status.toUpperCase() === 'IN PROGRESS' ? true : false}></input>
                                <label className='label-txt' for={index+'_'+data.regn_number} title={'Fuel Tank Capacity : '+ data.fuel_tank_capacity + "\n" + 'Alert Type Name : '+ (data.alert_type_name ? data.alert_type_name : '-') + "\n" + 'Alert Value : '+ (data.alert_value ? data.alert_value : '-')}>{data.regn_number.toUpperCase()}</label>
                            </div>
                            <div className={index % 2 === 0 ? 'gray col-md-2' : 'white col-md-2'}>
                                <label className='label-txt'>{data.alert_value ? data.alert_value : '-'}</label>
                            </div>
                            <div className={index % 2 === 0 ? 'gray col-md-4' : 'white col-md-4'}>
                                <label className='label-txt'>{data.status ? data.status : '-'}</label>
                            </div>
                        </>
                    ))}
                </div>
                </> : 
                <div className='col-md-12 text-center'>
                    <span className='no-data'>No Data Available</span>
                </div>
            }
            
        </div>
        {firstHalf.length > 0 &&
            <button className='save-btn' onClick={saveConfig}>Save</button>
        }
            
        <AlertBox
            show={showAlertBox}
            showMessage={showMessage}
            click={hideModal}
            messageType={messageType}>
        </AlertBox>
    </div>
  )
}

export default Configuration