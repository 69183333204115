import React from 'react'

const MyAvatar = () => {
  return (
    <div className="react-chatbot-kit-chat-bot-avatar">
      <div
        className="react-chatbot-kit-chat-bot-avatar-container"
        style={{ background: "none" }}
      >
        <img className='avatar-img' alt="BotAvatar" src='message.png' />
      </div>
    </div>
  )
}

export default MyAvatar