// reducer.js
import { MAP_INFO_CHANGE } from './actions/action'

const initialState = {
  mapInfo: null,
}

const DashReducer = (state = initialState, action) => {
  switch (action.type) {
    case MAP_INFO_CHANGE:
      return {
        ...state,
        mapInfo: action.payload,
      }
    default:
      return state
  }
}

export default DashReducer
